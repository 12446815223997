import React from 'react';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import RecyclingChartSkeleton from 'containers/Application/Modules/RecyclingModule/RecyclingChartSkeleton';
import RecyclingTotalsSkeleton from 'containers/Application/Modules/RecyclingModule/RecyclingTotalsSkeleton';

export const RecyclingRatesBreakdownTabSkeleton = () => (
  <Columns justifyContent="space-between">
    <Column columnWidth={{ landscape: 9, default: 12 }}>
      <RecyclingChartSkeleton />
    </Column>
    <Column columnWidth={{ landscape: 3, default: 12 }}>
      <RecyclingTotalsSkeleton />
    </Column>
  </Columns>
);

export default RecyclingRatesBreakdownTabSkeleton;
