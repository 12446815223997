import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const sensorAlarmsForUser = () =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-alarms`,
    noCache: true,
    useAuthorization: true,
  });

export const deleteSensorAlarm = sensorAlarmId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/sensor-alarms/${sensorAlarmId}`,
    noCache: true,
    useAuthorization: true,
  });

export const upsertSensorAlarm = sensorAlarm =>
  sensorAlarm.id
    ? ApiRequest.put({
        apiPath: `${SERVICE_API_PATH}/sensor-alarms`,
        data: sensorAlarm,
        noCache: true,
        useAuthorization: true,
      })
    : ApiRequest.post({
        apiPath: `${SERVICE_API_PATH}/sensor-alarms`,
        data: sensorAlarm,
        noCache: true,
        useAuthorization: true,
      });
