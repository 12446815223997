import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import head from 'lodash/head';
import last from 'lodash/last';
import replace from 'lodash/replace';
import PropTypes from 'prop-types';

import Slider from 'components/Slider/Slider';
import { InputLabel } from 'components/index';
import MultiSelectDropdown from 'components/Form/MultiSelectDropdown';

const FilterInput = styled.div`
  margin: var(--size-sm) 0;
`;
FilterInput.displayName = 'FilterInput';

const numericInputs = ['grossArea', 'netArea'];

const sliderStep = (minValue, maxValue) => {
  const scale = maxValue - minValue;
  if (Number.isInteger(scale) && scale < 100) {
    return 1;
  }
  const valueOrder = Math.floor(Math.log10(maxValue));
  const stepOrder = Math.max(0, valueOrder - 2);
  return Math.pow(10, stepOrder);
};

const isDataNumeric = singleInput => singleInput.isNumber || includes(numericInputs, singleInput.key);

const getSortedNumberOptionValues = (singleInput, options) =>
  /* TODO: Remove fallback number conversion (from commas to points) of known meta rows
   * once we have meta typing, validation and correct number format in area meta values
   */
  (singleInput.isNumeric
    ? options.map(option => option.value)
    : options.map(option => Number(replace(option.value, ',', '.')))
  ).sort((a, b) => a - b);

export const EnergyFilterInputs = ({ t, filter, selectedFilterValues, handleSelectDropdown }) => {
  const filterOptions = useMemo(
    () =>
      filter &&
      filter.reduce((accu, singleInput) => {
        accu[singleInput.key] = orderBy(singleInput.values, 'label');
        return accu;
      }, {}),
    [filter]
  );

  const sliderDomains = useMemo(
    () =>
      filter &&
      filter.reduce((accu, singleInput) => {
        if (isDataNumeric(singleInput)) {
          const options = filterOptions[singleInput.key];
          const optionValues = getSortedNumberOptionValues(singleInput, options);
          accu[singleInput.key] = [head(optionValues), last(optionValues)];
        }
        return accu;
      }, {}),
    [filter, filterOptions]
  );

  if (isEmpty(filter)) {
    return null;
  }

  return (
    <Fragment>
      {map(filter, singleInput => {
        const options = filterOptions[singleInput.key];
        const domain = sliderDomains[singleInput.key];

        // no need to render, if there is no options to choose from
        if (options.length < 2) {
          return null;
        }

        return (
          <FilterInput key={singleInput.key}>
            <InputLabel text={t(singleInput.key)} />
            {isDataNumeric(singleInput) ? (
              <Slider
                step={sliderStep(...domain)}
                domain={domain}
                values={selectedFilterValues[singleInput.key] || domain}
                property={singleInput.key}
                onChange={handleSelectDropdown}
                showValueLabels
                showTicks
                showTickLabels
                tickCount={5}
              />
            ) : (
              <MultiSelectDropdown
                multi
                options={options}
                onChange={handleSelectDropdown}
                model={selectedFilterValues}
                property={singleInput.key}
                clearable={false}
              />
            )}
          </FilterInput>
        );
      })}
    </Fragment>
  );
};

EnergyFilterInputs.propTypes = {
  t: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
  selectedFilterValues: PropTypes.object.isRequired,
  handleSelectDropdown: PropTypes.func.isRequired,
};

export default React.memo(EnergyFilterInputs);
