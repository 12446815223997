import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';
import { useTranslations } from 'decorators/Translations/translations';
import styled, { useTheme } from 'styled-components';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import charWidth from 'utils/String/charWidth';
import { isOptionDisabled } from '../../../../components/Select/utils';
import { SelectInputV5 } from '../../../../components/Select/SelectInputV5';

const SelectWrapper = styled.div`
  width: ${props => props.cssWidth};
  color: ${props => props.theme.colors.black};
  pointer-events: auto;
  cursor: pointer;
`;
SelectWrapper.displayName = 'SelectWrapper';

const Arrow = styled(({ ...props }) => <Svg {...props} />)`
  fill: ${props => props.theme.colors.black};
`;
Arrow.displayName = 'Arrow';

const MobileLabel = styled.div`
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.theme.colors.white};
  ${props => props.theme.media.portrait`
    display: none;
  `}
`;
MobileLabel.displayName = 'MobileLabel';

const customStyles = theme => ({
  control: provided => ({
    ...provided,
    background: theme.colors.white,
    border: `1px solid ${theme.colors.lightGray}`,
    borderRadius: '4px',
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: theme.font.size.xs,
    fontWeight: theme.fontWeight.bold,
    overflow: 'visible',
  }),
  dropdownIndicator: (provided, { children }) => ({
    ...provided,
    color: theme.colors.black,
    padding: children ? 0 : provided.padding,
  }),
});

const placeholderText = (placeholder, t) => placeholder || `${t('Select')}...`;
// Make sure the selector is not overflowing from the window
const width = (options, tPlaceholder) =>
  `${Math.min(
    window.innerWidth - 65,
    Math.max(...options.concat([{ label: tPlaceholder }]).map(c => reduce(c.label?.split('') || [], charWidth, 0))) + 15
  )}px`;

const UserManualMobileSelect = props => {
  const { onChange, options, value: inputValue, placeholder: inputPlaceholder } = props;
  const [t] = useTranslations();
  const theme = useTheme();
  const onOptionChange = useCallback(selected => onChange(selected.value), [onChange]);
  const styles = useMemo(() => customStyles(theme), [theme]);
  const value = useMemo(() => find(options, option => option === inputValue), [options, inputValue]);
  const placeholder = useMemo(() => placeholderText(inputPlaceholder, t), [inputPlaceholder, t]);

  return (
    <SelectWrapper cssWidth={width(options, placeholder)}>
      <SelectInputV5
        styles={styles}
        defaultValue={value}
        value={value}
        placeholder={placeholder}
        onChange={onOptionChange}
        options={options}
        isDisabled={props.disabled}
        isOptionDisabled={isOptionDisabled}
      />
    </SelectWrapper>
  );
};

UserManualMobileSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default UserManualMobileSelect;
