import { NameCell, DateCell, SizeCell, DownloadCell, ActionCell } from './Cells';

export const getColumns = t => [
  {
    Header: t('Document'),
    id: 'name',
    accessor: 'name',
    Cell: NameCell,
    style: { flexGrow: 2, width: '30%', minWidth: '180px' },
  },
  {
    Header: t('Uploaded/Updated'),
    id: 'modified',
    accessor: 'modified',
    Cell: DateCell,
    style: { flexGrow: 1, minWidth: '180px' },
    align: 'left',
  },
  {
    Header: t('Size'),
    id: 'size',
    accessor: 'size',
    Cell: SizeCell,
    style: { flexGrow: 0, width: '140px' },
    align: 'left',
  },
  {
    id: 'download',
    accessor: 'downloadText',
    disableSortBy: true,
    Cell: DownloadCell,
    style: { flexGrow: 0, width: '140px' },
  },
  {
    id: 'actions',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ActionCell,
    width: 50,
    style: { flexGrow: 0, width: '50px' },
  },
];
