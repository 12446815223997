import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import times from 'lodash/times';
import { performanceColors } from 'utils/Data/notice';

export const StyledStatusColumn = styled.div`
  ${props => props.theme.media.landscape`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
    `}
`;

export const Status = styled.div`
  display: ${props => (props.showOnMobile ? 'block' : 'none')};
  margin-left: var(--size-xs);
  text-align: left;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xxs};
  letter-spacing: 0.3px;
  overflow: hidden;
  ${props => props.theme.media.landscape`
        display: block;
    `}
`;

const StatusText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledRating = styled.div`
  display: flex;
  flex-flow: row nowrap;
  float: left;
  padding-right: 1em;

  ${props => props.theme.media.landscape`
        float: none;
        padding-right: 0;
    `}
`;

const RatingItem = styled.span`
  width: var(--size-xs);
  height: var(--size-xs);
  margin: 1px;
  border-radius: 50%;
  background-color: ${props =>
    (props.checked && performanceColors(props)[props.status?.toLowerCase()]) || props.theme.colors.mystic};

  ${props => props.theme.media.landscape`
        width: var(--size-sm);
        height: var(--size-sm);
    `}
`;

const SubStatus = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.xxs};
  line-height: ${props => props.theme.lineHeight.text};
  margin-top: var(--size-xxs);
`;

export const Rating = ({ value, status }) => {
  return (
    <StyledRating>
      {times(3, idx => {
        return <RatingItem checked={idx < value} status={status} key={'rating' + idx} />;
      })}
    </StyledRating>
  );
};

Rating.propTypes = {
  value: PropTypes.number,
  status: PropTypes.string,
};

const StatusColumn = ({ data, noLeftMargin, t }) => {
  if (!data?.status) {
    return null;
  }

  const { value, status, title } = data.status;

  return (
    <StyledStatusColumn title={t(title)}>
      <Rating value={value} status={status} />
      <Status>
        <StatusText>{t(title)}</StatusText>
        {data.subStatus && <SubStatus>{t(data.subStatus)}</SubStatus>}
      </Status>
    </StyledStatusColumn>
  );
};

StatusColumn.propTypes = {
  data: PropTypes.object,
  noLeftMargin: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default StatusColumn;
