import styled from 'styled-components';
import PropTypes from 'prop-types';
import { VALUE_STATUS } from 'constants/common';

const performanceColors = () => ({
  ok: 'var(--success-color)',
  warning: 'var(--warning-color)',
  neutral: 'var(--neutral-color)',
  action: 'var(--btn-primary-bg)',
});

const selectedPerformanceColors = () => ({
  ok: 'var(--success-color)',
  warning: 'var(--warning-color)',
  neutral: 'var(--blue-01)',
  action: 'var(--blue-02)',
});

const CircleTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: auto;
  margin: 0 0.25rem;
  min-width: ${props => (props.small ? '2rem' : '2.5rem')};
  height: ${props => (props.small ? '2rem' : '2.5rem')};
  font-size: ${props => props.small && '1em'};
  border: 1px solid
    ${props =>
      props.selected
        ? selectedPerformanceColors(props)[props.performance || VALUE_STATUS.NEUTRAL]
        : performanceColors(props)[props.performance || VALUE_STATUS.NEUTRAL]};
  border-radius: 2.5em;
  background-color: ${props =>
    props.selected
      ? selectedPerformanceColors(props)[props.performance || VALUE_STATUS.NEUTRAL]
      : 'var(--btn-primary-fg)'};

  a,
  [role='button'] {
    padding: 0 0.5rem 0.125rem;
    color: ${props =>
      props.selected ? 'var(--btn-primary-fg)' : performanceColors(props)[props.performance || VALUE_STATUS.NEUTRAL]};
  }
`;

CircleTab.defaultProps = {
  selected: false,
};

CircleTab.propTypes = {
  selected: PropTypes.bool,
};

export default CircleTab;
