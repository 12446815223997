import styled from 'styled-components';

import { FlexGroup } from './FlexGroup';

/**
 * Pack components into a tight group.
 * @see {@link FlexGroup}
 *
 * @implements {UIv3}
 */
export const FlexTightGroup = styled(FlexGroup)`
  gap: 0;
`;
