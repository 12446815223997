import React from 'react';
import ProfileService from 'services/profile';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import Page from './Page';

export const UserTermsPage = () => {
  const { t } = useUnauthenticatedTranslations();
  return <Page title={t('User Terms')} fetchTerms={ProfileService.userTerms} />;
};

export default UserTermsPage;
