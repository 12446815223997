import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageModal from './ImageModal';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`;

const Column = styled.div`
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 4px;

  img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  @media (max-width: 800px) {
    flex: 50%;
    max-width: 50%;
  }

  @media (max-width: 600px) {
    flex: 100%;
    max-width: 100%;
  }
`;

const Clickable = styled.div`
  cursor: pointer;
`;

export const MarkdownImages = ({ imageData }) => {
  const imageColumn = image => {
    return (
      <Column key={image.src + image.alt}>
        <Clickable>
          <ImageModal src={image.src} alt={image.alt} caption={image.caption} />
        </Clickable>
      </Column>
    );
  };

  const columns = imageData.map(image => imageColumn(image));
  return <Row>{columns}</Row>;
};

MarkdownImages.propTypes = {
  imageData: PropTypes.array.isRequired,
};

export default MarkdownImages;
