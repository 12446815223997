export const icons = {
  DEL: 'trash-can',
  OK: 'check',
  PEN: 'pen',
  AREA: 'area',
  GROUP: 'fa-cubes',
  SENSOR: 'sensor',
  IMAGES: 'images',
  IMAGE: 'image',
  ANNOUNCEMENT_SUCCESS: 'announcement-success',
  ANNOUNCEMENT_WARNING: 'announcement-warning',
  ANNOUNCEMENT_RELEASE_NOTES: 'announcement-release-notes',
  ANNOUNCEMENT_INFO: 'announcement-info',
  ADMIN_ADD_USER: 'admin-add-user',
  ADMIN_MANAGE_USERS: 'admin-manage-users',
  ADMIN_MANAGE_NOTIFICATIONS: 'bell',
  ATTACHMENT: 'attachment',
  LANGUAGE: 'language',
  BELL: 'bell',
  BELL_OUTLINE: 'bell-outline',
  BELL_FILLED: 'bell-filled',
  ADMIN_PANEL: 'admin-panel',
  LINK_SOLID: 'link-solid',
  TO_COORDS: 'sensor',
  TO_FLOOR: 'floors',
  TO_BUILDING: 'fa-building-o',
  TO_PARENT_SENSOR: 'fa-cube',
  ADMIN_SET_ANALYTICS: 'admin-set-analytics',
  TECHNICAL: 'fa-cubes',
  ENERGY: 'energy',
  SERVICE_ORDERS: 'service-orders',
  PLANNED_MAINTENANCE: 'planned-maintenance',
  CONDITIONS: 'conditions',
  MORE: 'more',
  DOCUMENTS: 'documents',
  EXTERNAL_DOCUMENTS: 'external-documents',
  SETTINGS: 'pen',
  LOGOUT: 'fa-sign-out',
  CALENDAR: 'calendar-date',
  PORTFOLIO: 'portfolio',
  ADMIN_USER_MANUAL: 'admin-user-manual',
  NEW_SERVICE_REQUEST: 'new-service-request',
  DEVICE: 'device',
  LINK_ARROW: 'caret-right',
  CUSTOM_VIEW: 'customview',
  ARROW_LEFT: 'caret-left',
  STATISTICS: 'statistics',
  CONTROL_ROOM: 'control-room',
  FL_ANNOUNCEMENTS: 'bell-document',
  CLEANING: 'cleaning',
  CUSTOM_TAB: 'custom-tab',
  ADMIN_TAGS: 'tag',
  PHONE: 'phone',
  EMAIL: 'email',
  APPOINTMENT: 'chat',
  AIR_QUALITY: 'air-quality',
  INDENT: 'indent',
  EXTERNAL_LINK: 'external-link',
  CONTACT: 'envelope',
  RECYCLING: 'recycling',
  IMPORT: 'import',
  OBSERVATION: 'observation',
  OBSERVATION_ANALYTICS: 'observation-analytics',
  OBSERVATION_EXPERT: 'observation-expert',
  ROADMAP: 'roadmap',
  CUSTOMER_SERVICE: 'customer-service',
  CUSTOMER: 'customer-comment',
  TASK: 'task',
  CUSTOM_CHARTS: 'custom-charts',
  MINUS: 'minus',
  SRI: 'sri',
  DOWNLOAD: 'download',
  SUSTAINABILITY: 'sustainability',
  USAGE_TYPE_GENERAL: 'usage-type-general',
  USAGE_TYPE_HORIZONTAL_INFOSCREEN: 'usage-type-horizontal-infoscreen',
  USAGE_TYPE_VERTICAL_INFOSCREEN: 'usage-type-vertical-infoscreen',
  CAVERION_C: 'caverion-c',
  SYNTHETIC_SENSOR: 'SyntheticSensor',
};
