import ApiRequest from 'utils/Fetch/ApiRequest';
import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from './index';

export const fetchSensorMeta = ({ functionalLocation, sensorId, metaKey, value }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-meta`,
    query: { functionalLocation, sensorId, metaKey, value },
    noCache: true,
  });

export async function checkSensorMetaInUse(sensorMeta) {
  return ApiRequest.request({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/sensor-meta/check-if-used`,
    data: sensorMeta,
    noCache: true,
  });
}

export const upsertAllSensorMetaFn = (request, sensorMetaArray) =>
  request.put({
    apiPath: `${SERVICE_API_PATH}/sensor-meta`,
    data: sensorMetaArray,
    noCache: true,
  });

export const deleteSensorMetaFn = (request, sensorMetaId) =>
  request.delete({
    apiPath: `${SERVICE_API_PATH}/sensor-meta/${sensorMetaId}`,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  upsertAllSensorMeta: upsertAllSensorMetaFn,
  deleteSensorMeta: deleteSensorMetaFn,
});
