import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from './Checkbox';

export const InputCheckbox = props => {
  const value = (props.value ?? get(props.model, props.property)) || props.falseValue;
  const type = props.type || 'checkbox';
  return (
    <CheckboxContainer>
      <CheckboxInput
        disabled={props.disabled}
        type={type}
        id={props.id}
        checked={value !== props.falseValue}
        onChange={() =>
          props.onChange(
            props.property,
            value === props.falseValue ? props.trueValue : props.falseValue,
            props.override
          )
        }
        data-test-id={props['data-test-id']}
      />
      <CheckboxLabel
        htmlFor={props.id}
        className={props.className}
        disabled={props.disabled}
        useBold={props.useBold && value !== props.falseValue}
        labelNoWrap={props.labelNoWrap}
        onClick={e => e.stopPropagation()}
      >
        {props.label}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

InputCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  trueValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  falseValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  override: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  useBold: PropTypes.bool,
  labelNoWrap: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  'data-test-id': PropTypes.string,
};

export default InputCheckbox;
