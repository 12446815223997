import styled from 'styled-components';

export const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.bold};
`;

export const Description = styled.h3`
  padding: var(--size-xs) 0;
`;

export const Note = styled.p`
  font-size: ${props => props.theme.fontSize.note};
  padding: var(--size-lg) 0;
  line-height: 1.25;
`;

export const Hr = styled.hr`
  margin: var(--size-xl) 0;
`;
