import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import translations from 'decorators/Translations/translations';
import Svg from 'components/Svg/Svg';
import DocumentFileProgress from './DocumentFileProgress';
import { icons } from 'components/Icon/IconNames';
import { getHumanreadableSize } from 'utils/Data/documents';
import { calcInitStep } from './DocumentManager';
import BaseButton from 'components/Button/BaseButton/BaseButton';

const File = styled.li`
  color: var(--body-text-fg);
  border-bottom: var(--light-separator-border);
  border-width: ${props => props.noBorder && '0'};
  font-size: ${props => props.theme.font.size.xxs};
  overflow: hidden;
`;

const RelativeRow = styled.div`
  padding: var(--size-sm) 0;
  position: relative;
`;

const RelativeContent = styled.div`
  position: relative;
  padding: 0 var(--size-xxs);
`;

const FileName = styled.span`
  font-weight: ${props => props.theme.font.weight.semibold};
  word-break: break-all;
  color: ${props => props.hasErrored && 'var(--error-color)'};
`;

const FileSize = styled.span`
  white-space: nowrap;
`;

const Icon = styled(({ trash, ok, ...props }) => <Svg {...props} />)`
  position: relative;
  float: right;
  fill: ${props => (props.trash && 'var(--negative-color)') || (props.ok && 'var(--positive-color)')};
  vertical-align: text-top;
  cursor: ${props => props.trash && 'pointer'};
`;

const DownloadButton = styled(BaseButton)`
  display: inline-block;
  padding: 0;
  margin: 0 var(--size-xs);
  min-width: 0;
  color: var(--download-button-fg);
  height: auto;
`;

const DocumentFile = ({
  index,
  file,
  onFileRemoval,
  onFileDownload,
  isCreateMode,
  hasErrored,
  allLoading,
  full,
  loading,
  t,
  hideSize,
  noBorder,
}) => {
  return (
    <File noBorder={noBorder}>
      <RelativeRow>
        {isCreateMode && <DocumentFileProgress initStep={calcInitStep(file.size)} full={full} loading={loading} />}
        <RelativeContent>
          <FileName hasErrored={hasErrored}>{file.filename || file.name}</FileName>
          <FileSize>{!hideSize && !isNil(file.size) && ` (${getHumanreadableSize(file.size)})`}</FileSize>
          {isCreateMode && !loading && !allLoading && (
            <Icon trash name={icons.DEL} onClick={() => onFileRemoval(index)} />
          )}
          {typeof onFileDownload === 'function' && (
            <DownloadButton onClick={() => onFileDownload(index)}>{t('Download')}</DownloadButton>
          )}
          {full && <Icon ok name={icons.OK} />}
        </RelativeContent>
      </RelativeRow>
    </File>
  );
};

DocumentFile.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  onFileRemoval: PropTypes.func.isRequired,
  isCreateMode: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool,
  allLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func,
  full: PropTypes.bool,
  loading: PropTypes.bool,
  hideSize: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export default translations(DocumentFile);
