export const getLocaleCompare = (options = { numeric: true }) =>
  new Intl.Collator(window.__localeId__, options).compare;

const getValueGetter = key => {
  if (typeof key === 'function') {
    return obj => key(obj);
  }
  return obj => obj[key];
};

export const sortByKey = (collection, key) => {
  const compare = getLocaleCompare();
  const sortValue = getValueGetter(key);

  return collection.sort((a, b) => compare(sortValue(a), sortValue(b)));
};

export const sortByMultipleKeys = (collection, keys = []) => {
  if (keys.length === 0) {
    return collection;
  }
  if (keys.length === 1) {
    return sortByKey(collection, keys[0]);
  }

  const getters = keys.map(getValueGetter);
  const tempCollection = collection.map(obj => ({ sortValue: getters.map(getter => getter(obj)), original: obj }));

  return sortByKey(tempCollection, 'sortValue').map(tempObj => tempObj.original);
};
