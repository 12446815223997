import React from 'react';

import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputForm from 'components/Form/InputForm';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import PrimaryButton from 'components/Button/PrimaryButton';
import MultiInput from 'components/Form/MultiInput/MultiInput';
import Loader from 'components/Loader/Loader';
import { FormContainer, metaKey, metaValue, InputRowButton } from './SensorMetaDataMapping';

export const SensorForm = ({
  model,
  t,
  handleSubmit,
  handleOnChange,
  sensorTypeOptions,
  addMeta,
  handleSensorMetaAdd,
  handleMetaChange,
  handleMetaRemove,
  loading,
}) => {
  const { meta } = model;
  return (
    <FormContainer>
      <h3>Sensor</h3>
      <InputForm model={model} onPropertyChange={handleOnChange} onSubmit={handleSubmit}>
        <InputRow required>
          <InputLabel text="Sensor Type" />
          <InputSelectDropdown
            options={sensorTypeOptions}
            property={'sensorTypeId'}
            model={model}
            onChange={handleOnChange}
            t={t}
            clearable={false}
          />
        </InputRow>
        <InputRow required>
          <InputLabel text="Name" />
          <InputText id="name" type="text" property="name" />
        </InputRow>
        <InputRow>
          <InputLabel text="Equipment Number" />
          <InputText id="equipmentNumber" type="text" property="equipmentNumber" />
        </InputRow>
        <InputRow>
          <InputLabel text="Functional Location" />
          <InputText id="functionalLocation" type="text" property="functionalLocation" />
        </InputRow>
        <InputRow required>
          <InputLabel text="Sensor Hierarchy Id" />
          <InputText id="sensorHierarchyId" type="text" property="sensorHierarchyId" />
        </InputRow>
        <InputRow>
          <InputLabel text="" />
          <InputBooleanCheckbox id="sensorMeta" property={['addMeta']} label="Add sensor meta" />
        </InputRow>
        {model.addMeta && (
          <MultiInput
            style={{ width: '100%' }}
            t={t}
            model={{ meta }}
            baseProperty="meta"
            subProperties={[metaKey, metaValue]}
            subPropertyLabels={{ [metaKey]: t('Key'), [metaValue]: t('Value') }}
            onPropertyChange={handleMetaChange}
            onAddRow={handleSensorMetaAdd}
            onRemoveRow={handleMetaRemove}
          />
        )}
        <InputRowButton>
          <PrimaryButton confirm large type="submit">
            {loading ? <Loader color="WHITE" size="SMALL" /> : 'Save'}
          </PrimaryButton>
        </InputRowButton>
      </InputForm>
    </FormContainer>
  );
};
