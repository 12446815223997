import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: relative;

  ${props => props.theme.media.landscape`
    margin-bottom: calc(-1 * var(--size-md));
  `};
`;

const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.theme.media.portrait`
    flex-wrap: wrap;
  `}

  ${props => props.theme.media.landscape`
    overflow: visible;
  `}

  @media print {
    flex-wrap: wrap;
  }
`;

const StatusInfos = ({ children }) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

StatusInfos.displayName = 'StatusInfos';

StatusInfos.propTypes = {
  children: PropTypes.node,
};

export default StatusInfos;
