import React from 'react';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonSquare from 'components/Skeletons/SkeletonSquare';

const BreakdownTabSkeleton = () => (
  <Columns>
    <Column columnWidth={{ landscape: 9, default: 12 }}>
      <SkeletonChart>
        <Loader color="BLUE" size="LARGE" />
      </SkeletonChart>
    </Column>
    <Column columnWidth={{ landscape: 3, default: 12 }}>
      <SkeletonText style={{ height: '50px' }} />
      <SkeletonSquare style={{ width: '100%', height: '50%', marginTop: '1em' }} />
    </Column>
  </Columns>
);

export default BreakdownTabSkeleton;
