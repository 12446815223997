import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import ExternalDocumentTable from './ExternalDocumentTable';
import SkeletonPill from 'components/Skeletons/SkeletonPill';
import Section from 'components/Section/Section';

import { downloadDocument, filterDocumentsByKeyword, resetFilterDocumentsByKeyword } from 'redux/modules/index';

import { InputSearch } from 'components/index';

const Heading = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: var(--size-md) 0;
`;

const Title = styled.h3`
  padding-right: var(--size-xl);
`;

class ExternalDocumentModule extends Component {
  componentDidMount() {
    const { resetFilterDocumentsByKeyword } = this.props;
    resetFilterDocumentsByKeyword();
  }

  componentDidUpdate(prevProps) {
    const { equipment, functionalLocation, resetFilterDocumentsByKeyword } = this.props;

    if (!isEqual(equipment, prevProps.equipment)) {
      resetFilterDocumentsByKeyword();
    }
    if (!isEqual(functionalLocation, prevProps.functionalLocation)) {
      resetFilterDocumentsByKeyword();
    }
  }

  download = file => {
    return this.props.downloadDocument(
      this.props.functionalLocation.functionalLocation,
      undefined,
      file.documentId,
      file.id,
      `${file.name}.${file.extension}`
    );
  };

  renderDocuments(documents) {
    const showSkeletons = documents === null;

    return (
      <Fragment>
        <Heading>
          <Title>{this.props.t('Documents')}</Title>
          {showSkeletons ? (
            <SkeletonPill width="300px" />
          ) : (
            <InputSearch
              onChange={value => this.props.filterDocumentsByKeyword(value)}
              onClear={() => this.props.resetFilterDocumentsByKeyword()}
              placeholder={this.props.t('Search from documents')}
              initialValue={this.props.documentFilterKeyword}
              width="300px"
            />
          )}
        </Heading>

        <ExternalDocumentTable
          loading={showSkeletons}
          t={this.props.t}
          documents={documents}
          download={this.download}
        />
      </Fragment>
    );
  }

  renderFunctionalLocation() {
    if (!this.props.functionalLocation || this.props.equipment) {
      return null;
    }

    const documents = get(this.props.functionalLocationDocuments, [this.props.functionalLocation.functionalLocation]);

    return this.renderDocuments(documents);
  }

  renderServiceOrder() {
    if (!this.props.serviceOrder) {
      return null;
    }

    const documents = get(this.props.serviceOrderDocuments, [this.props.serviceOrder.serviceOrderNumber]);
    return this.renderDocuments(documents);
  }

  renderEquipment() {
    if (!this.props.equipment) {
      return null;
    }

    const documents = get(this.props.equipmentDocuments, [this.props.equipment.equipmentNumber]);
    return this.renderDocuments(documents);
  }

  render() {
    const {
      profile: {
        profile: { features },
      },
      serviceOrder,
      equipment,
      functionalLocation,
    } = this.props;
    if (!features || !features.documents) {
      return null;
    }

    if (!serviceOrder && !equipment && !functionalLocation) {
      return <Section>{this.renderDocuments(null)}</Section>;
    }

    return (
      <Section>
        {this.renderFunctionalLocation()}
        {this.renderServiceOrder()}
        {this.renderEquipment()}
      </Section>
    );
  }
}

ExternalDocumentModule.propTypes = {
  profile: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  functionalLocationDocuments: PropTypes.object,
  serviceOrderDocuments: PropTypes.object,
  equipmentDocuments: PropTypes.object,
  documentFilterKeyword: PropTypes.string,
  downloadDocument: PropTypes.func.isRequired,
  filterDocumentsByKeyword: PropTypes.func.isRequired,
  resetFilterDocumentsByKeyword: PropTypes.func.isRequired,
  equipment: PropTypes.object,
  functionalLocation: PropTypes.object,
  serviceOrder: PropTypes.object,
};

const mapStateToProps = state => ({
  profile: state.profile,
  functionalLocationDocuments: state.document.filteredFunctionalLocations,
  serviceOrderDocuments: state.document.filteredServiceOrders,
  equipmentDocuments: state.document.filteredEquipment,
  documentFilterKeyword: state.document.filterByKeyword,
});

const mapDispatchToProps = {
  downloadDocument,
  filterDocumentsByKeyword,
  resetFilterDocumentsByKeyword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(ExternalDocumentModule));
