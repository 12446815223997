import React from 'react';
import PropTypes from 'prop-types';

import translations from 'decorators/Translations/translations';
import UserManualContainer from '../UserManualV1/UserManualContainer';
import { CUSTOM_TYPE } from '../UserManualV1/UserManualUtils';

export const AdminUserManual = ({ t }) => (
  <UserManualContainer type={CUSTOM_TYPE.ADMIN_USER_MANUAL} title={t('Admin User Manual')} />
);

AdminUserManual.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translations(AdminUserManual);
