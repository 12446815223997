import React from 'react';

const useProgress = (loading = false, full = false, initStep = 0.15) => {
  const [progress, setProgress] = React.useState(0);
  const intervalRef = React.useRef(null);
  const timeoutRef = React.useRef(null);

  React.useEffect(() => {
    if (loading && !full) {
      /**
       * From: https://medium.com/stormix/fake-progress-bars-and-maths-80a20dc2801d
       */
      let currentProgress = 0,
        step = initStep; // the smaller this is the slower the progress bar

      intervalRef.current = setInterval(() => {
        currentProgress += step;
        const calculatedProgress = Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) / 1000;

        intervalRef.current && setProgress(calculatedProgress);

        if (calculatedProgress >= 100) {
          clearInterval(intervalRef.current);
        } else if (calculatedProgress >= 70) {
          step = 0.1;
        }
      }, 100);
    } else {
      clearInterval(intervalRef.current);
      timeoutRef.current = setTimeout(() => setProgress(0), 100);
    }

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [loading, full]);

  return progress;
};

export default useProgress;
