import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import PrimaryButton from 'components/Button/PrimaryButton';

const BackButton = ({ t, history, ...buttonProps }) => (
  <PrimaryButton large naked onClick={history.goBack} {...buttonProps}>
    {t('Back')}
  </PrimaryButton>
);

BackButton.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  t: PropTypes.func.isRequired,
};

export default withRouter(BackButton);
