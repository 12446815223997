import { OrderStatus } from 'constants/maintenance';

export const serviceOrderColors = {
  openColor: 'var(--service-order-open-color)',
  releasedColor: 'var(--service-order-released-color)',
  closedColor: 'var(--service-order-closed-color)',
  completedColor: 'var(--service-order-completed-color)',
  arrivedColor: 'var(--service-order-arrived-color)',
  startedColor: 'var(--service-order-started-color)',
  partlyCompletedColor: 'var(--service-order-partly-completed-color)',
  inProgressColor: 'var(--service-order-in-progress-color)',
  postponedColor: 'var(--service-order-postponed-color)',
  plannedColor: 'var(--service-order-planned-color)',
  lateColor: 'var(--service-order-late-color)',
  overdueColor: 'var(--service-order-overdue-color)',
  draftColor: 'var(--service-order-draft-color)',
  cancelledColor: 'var(--service-order-cancelled-color)',
  rejectedColor: 'var(--service-order-rejected-color)',
  toBeMovedColor: 'var(--service-order-to-be-moved-color)',
  delayedColor: 'var(--service-order-delayed-color)',
  requestColor: 'var(--service-order-request-color)',
  undoneColor: 'var(--service-order-undone-color)',
  acceptedColor: 'var(--service-order-accepted-color)',
  removedColor: 'transparent',
};

export const serviceOrderColorsByStatusEnum = {
  [OrderStatus.OPEN]: serviceOrderColors.openColor,
  [OrderStatus.RELEASED]: serviceOrderColors.releasedColor,
  [OrderStatus.IN_PROGRESS]: serviceOrderColors.inProgressColor,
  [OrderStatus.STARTED]: serviceOrderColors.startedColor,
  [OrderStatus.COMPLETED]: serviceOrderColors.completedColor,
  [OrderStatus.PARTLY_COMPLETED]: serviceOrderColors.partlyCompletedColor,
  [OrderStatus.ARRIVED]: serviceOrderColors.arrivedColor,
  [OrderStatus.POSTPONED]: serviceOrderColors.postponedColor,
  [OrderStatus.PLANNED]: serviceOrderColors.plannedColor,
  [OrderStatus.DRAFT]: serviceOrderColors.draftColor,
  [OrderStatus.CANCELLED]: serviceOrderColors.cancelledColor,
  [OrderStatus.OVERDUE]: serviceOrderColors.overdueColor,
};
