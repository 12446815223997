export enum Division {
  Finland = 'fi',
  Sweden = 'se',
  Norway = 'no',
  Denmark = 'dk',
  Lithuania = 'lt',
  Germany = 'de',
  Austria = 'at',
  Estonia = 'ee',
  Development = 'development',
}

export enum ConfigKey {
  /** Division specific email address to deliver new service requests to. */
  ServiceRequestEmail = 'service_request_email',
  /** Division specific email address displayed in the welcome email. */
  WelcomeEmail = 'welcome_email',
  /** Division specific email address to deliver feedback to. */
  FeedbackEmail = 'feedback_email',
  /** Division specific email address for additional sales suggestions. */
  AdditionalSalesEmail = 'additional_sales_email',
  /** Division specific email address for contacting Caverion (sales) */
  ContactEmail = 'contact_email',
  /** Division specific phone number for contacting Caverion (sales) */
  ContactPhone = 'contact_phone',
  /** Use classificationcodes in Long Term Planning as categories */
  EnableClassificationsInLongTermPlanning = 'enable_classifications_ltp',
  /** Use classificationcodes in New Service Request as categories */
  EnableClassificationsInNewServiceRequest = 'enable_classifications_sr',
  /** Hide service order operation texts */
  HideServiceOrderOperationTexts = 'hide_service_order_operation_texts',
}

export enum ConfigCategory {
  Contacts = 'contacts',
  Classifications = 'classifications',
  Maintenance = 'serviceCalendar',
}
