import React from 'react';
import { ActionMeta } from 'react-select';

import { useTranslations } from 'decorators/Translations/translations';
import { SelectInputV5 } from 'components/Select/SelectInputV5';
import usePrintingMode from 'components/FlexTable/usePrintingMode';

export type PaginationRowCountValue = number | null;

export type PaginationRowCountOption = {
  value: PaginationRowCountValue;
  label: string;
};

export const defaultPaginationRowCountOptions: PaginationRowCountOption[] = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: null, label: 'All' },
];

export const defaultPaginationRowCountValues = defaultPaginationRowCountOptions.map(option => option.value);
export const smallestAvailableDefaultPageSize = defaultPaginationRowCountValues[0] ?? Number.MAX_SAFE_INTEGER;

export type PaginationRowCountSelectorProps = {
  paginationRowCountOptions?: PaginationRowCountOption[];
  /** Initial selected value, e.g. from query parameters */
  value?: PaginationRowCountValue;
  onChange?: (value: PaginationRowCountValue) => void;
  withText?: boolean;
  width?: number;
};

/**
 * @implements UIv3
 */
export function PaginationPageSizeSelector({
  value,
  onChange,
  withText = true,
  width = 120,
  paginationRowCountOptions = defaultPaginationRowCountOptions,
}: PaginationRowCountSelectorProps): JSX.Element {
  const printing = usePrintingMode();
  const [t] = useTranslations();

  const options = paginationRowCountOptions.map(option => {
    const label = withText && option.label ? t('{0} rows', option.label) : t(option.label);
    return { ...option, label };
  });

  const option = options.find(option => option.value === value) ?? options[0];

  return (
    <SelectInputV5
      options={options}
      value={option}
      styles={{
        container: baseStyles => ({ ...baseStyles, width: `${width}px`, visibility: printing ? 'hidden' : 'visible' }),
      }}
      onChange={(option: PaginationRowCountOption | null, actionMeta: ActionMeta<PaginationRowCountOption>) => {
        if (actionMeta.action === 'select-option' && option) {
          onChange?.(option.value);
        }
      }}
      menuPlacement="auto"
      isSearchable={false}
    />
  );
}
