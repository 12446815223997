import { colors } from 'styles/definitions';

export const Type = {
  POSITIVE: 'positive',
  ALARMING: 'alarming',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

export const TYPE_COLORS = {
  [Type.POSITIVE]: colors.emerald,
  [Type.ALARMING]: colors.yellow,
  [Type.NEGATIVE]: colors.radicalRed,
  [Type.NEUTRAL]: colors.darkGray,
};

export const TYPES = Object.values(Type);

export const TYPE_DEFAULT_LABELS = {
  [Type.POSITIVE]: 'Ok',
  [Type.ALARMING]: 'Fixed',
  [Type.NEGATIVE]: 'Not ok',
  [Type.NEUTRAL]: 'N/A',
};

export const TYPE_ICONS = {
  [Type.POSITIVE]: 'check',
  [Type.ALARMING]: 'minus',
  [Type.NEGATIVE]: 'minus',
  [Type.NEUTRAL]: 'remove',
};
