import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker-v3';
import { datepickerStyles } from './styles';
import Svg from 'components/Svg/Svg';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import { DATE_FORMATS } from 'utils/Date/dateFormatter';

import 'react-datepicker-v3/dist/react-datepicker.css';

const Styling = styled.div`
  line-height: ${props => props.theme.input.lineHeight.default};
  position: relative;
  ${datepickerStyles}
`;

const Icon = styled(Svg)`
  position: absolute;
  left: 0.875rem;
  top: 0.8rem;
  fill: var(--input-supplemental-icon-fg);
  font-size: ${props => props.theme.font.size.lg};
  cursor: pointer;
  pointer-events: none;
`;

const POPPER_MODIFIERS = {
  preventOverflow: {
    enabled: true,
  },
};

const Datepicker = ({
  selected,
  onChange,
  simple,
  iconName,
  className,
  placeholder,
  highlightError,
  ...otherProps
}) => {
  const breakpoints = useBreakpoints();
  const withPortal = !breakpoints.landscape && !otherProps.showMonthDropdown && !otherProps.showYearDropdown;

  return (
    <Styling
      className={className}
      simple={simple}
      isLeft={otherProps.selectsStart}
      isRight={otherProps.selectsEnd}
      hasIcon={!!iconName}
      highlightError={highlightError}
    >
      <ReactDatePicker
        placeholderText={placeholder}
        selected={selected}
        onChange={onChange}
        timeFormat={DATE_FORMATS.timeShortLocal}
        dateFormat={otherProps.showTimeSelect ? DATE_FORMATS.dateTimeShortLocal : DATE_FORMATS.dateShortLocal}
        withPortal={withPortal}
        popperModifiers={POPPER_MODIFIERS}
        {...otherProps}
      />
      {iconName && <Icon name={iconName} />}
    </Styling>
  );
};

Datepicker.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  simple: PropTypes.bool,
  iconName: PropTypes.string,
  highlightError: PropTypes.bool,
};

export default Datepicker;
