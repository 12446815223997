import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { MASTER_DATA_PATH } from './index';

export const invalidateCustomerCategoryCaches = () =>
  invalidateCachesWithUrlMatch(`${MASTER_DATA_PATH}/customerCustomCategories`);

export const customerCategories = (partnerNumber, refreshCache) =>
  ApiRequest.get({
    apiPath: `${MASTER_DATA_PATH}/customerCustomCategories`,
    query: { partnerNumber },
    refreshCache,
  });

export const createCustomerCategory = customerCategory =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_PATH}/customerCustomCategories`,
    data: customerCategory,
  });
