import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const translations = language =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/translations/${language}`,
    useAuthorization: true,
  });

export const unauthenticatedTranslations = language =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/translations/unauthenticated/${language}`,
    useAuthorization: false,
  });

export const termsOfService = language =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/translations/${language}/termsOfService`,
    useAuthorization: false,
  });

export const userTerms = language =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/translations/${language}/userTerms`,
    useAuthorization: false,
  });

export const updateTranslations = () =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/translations/update`,
    noCache: true,
    useAuthorization: true,
  });

export const translationRowNumbers = () => translations('rowNumbers');
