import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NavBarButton from 'components/NavBarButton/NavBarButton';
import NumberBadge from 'components/NumberBadge/NumberBadge';

export const Unread = styled(NumberBadge)`
  position: absolute;
  top: 13px;
  right: -2px;
`;
Unread.displayName = 'Unread';

export const BellButton = ({ onClick, unread, label }) => (
  <NavBarButton icon="bell" onClick={onClick} label={label}>
    <Unread number={unread} />
  </NavBarButton>
);

BellButton.displayName = 'BellButton';

BellButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  unread: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
