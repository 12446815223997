import { floorTabsContent } from 'containers/Application/Modules/FloorModule/FloorModuleUtils';

export const createFloorTabsContent = ({
  t,
  floorData: { floor, latestValuesBySensorId, valuesBySensorId },
  floors,
  configuration: { OPICardPosition, sensorValueIndicatorTitle },
  meta,
}) => {
  const combinedFloors = [...floors.filter(shallowFloor => shallowFloor.id !== floor.id), floor].sort(
    (a, b) => a.order - b.order
  );
  return floorTabsContent(
    t,
    false,
    combinedFloors,
    valuesBySensorId,
    latestValuesBySensorId,
    [],
    meta,
    {},
    sensorValueIndicatorTitle
  ).map(tab => ({
    ...tab,
    opiData: OPICardPosition === 'hidden' ? undefined : tab.opiData,
  }));
};
