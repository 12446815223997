import styled, { css } from 'styled-components';

const SectionTab = styled.li.attrs({
  'data-test-id': 'SectionTab',
})`
  display: block;
  padding: 0 0 var(--size-md);
  margin: 0 var(--size-md) 0 0;
  border-bottom: 2px solid transparent;
  color: ${props => props.theme.colors.darkGray};
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.text};
  &:first-child {
    margin-left: 0;
  }

  ${props =>
    props.selected &&
    css`
      border-bottom-color: ${props => props.theme.colors.cerulean};
      color: ${props => props.theme.colors.black};
      font-weight: ${props => props.theme.fontWeight.heading};
    `}

  ${props => props.theme.media.portrait`
      font-size: ${props => props.theme.fontSize.sm};
    `}
    ${props => props.theme.media.bigDesktop`
      font-size: ${props => props.theme.fontSize.md};
    `}
`;
SectionTab.displayName = 'SectionTab';

export default SectionTab;
