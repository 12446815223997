import { createReducerFromMapping } from 'redux/utils/index';
import { getDocumentByCustomTypes, CUSTOM_TYPE } from 'containers/Application/Prismic/utils/UserManualUtils';
import {
  getDocumentByCustomType,
  CUSTOM_TYPE as CUSTOM_TYPE_V1,
} from 'containers/Application/Prismic/UserManualV1/UserManualUtils';

const initialState = {
  loading: false,
  general: null,
  admin: null,
  roadmap: null,
  manual: null,
  error: null,
};

export const PRISMIC_LOAD_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA';
export const PRISMIC_LOAD_DATA_FINISHED = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA_FINISHED';
export const PRISMIC_LOAD_DATA_FAIL = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_DATA_FAIL';

export const loadData = () => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_DATA });
  try {
    const response = await getDocumentByCustomType(CUSTOM_TYPE_V1.GENERAL_USER_MANUAL);
    const data = response.results[0].data;
    return dispatch({
      type: PRISMIC_LOAD_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_DATA_FAIL,
      error,
    });
  }
};

export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA';
export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED =
  'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED';
export const PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL =
  'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL';

export const loadAdminUserManualData = () => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA });
  try {
    const response = await getDocumentByCustomType(CUSTOM_TYPE_V1.ADMIN_USER_MANUAL);
    const data = response.results[0].data;
    return dispatch({
      type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL,
      error,
    });
  }
};

export const PRISMIC_LOAD_ROADMAP_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ROADMAP_DATA';
export const PRISMIC_LOAD_ROADMAP_DATA_FINISHED = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ROADMAP_DATA_FINISHED';
export const PRISMIC_LOAD_ROADMAP_DATA_FAIL = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_ROADMAP_DATA_FAIL';

export const loadRoadmapData = () => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_ROADMAP_DATA });

  try {
    const response = await getDocumentByCustomType(CUSTOM_TYPE_V1.ROADMAP);
    const data = response.results[0].data;

    return dispatch({
      type: PRISMIC_LOAD_ROADMAP_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_ROADMAP_DATA_FAIL,
      error,
    });
  }
};

export const PRISMIC_LOAD_USER_MANUAL_V2_DATA = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_USER_MANUAL_V2_DATA';
export const PRISMIC_LOAD_USER_MANUAL_V2_DATA_FINISHED =
  'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_USER_MANUAL_V2_DATA_FINISHED';
export const PRISMIC_LOAD_USER_MANUAL_V2_DATA_FAIL = 'CUSTOMER_PLATFORM/Prismic/PRISMIC_LOAD_USER_MANUAL_V2_DATA_FAIL';

export const loadUserManualV2Data = isAdmin => async dispatch => {
  dispatch({ type: PRISMIC_LOAD_USER_MANUAL_V2_DATA });

  try {
    const response = await getDocumentByCustomTypes([CUSTOM_TYPE.USER_MANUAL, CUSTOM_TYPE.USER_MANUAL_PAGE], isAdmin);
    const data = isAdmin
      ? response.results
      : response.results.map(page => ({
          ...page,
          data: {
            ...page.data,
            body: page.data.body.filter(slice => slice.slice_label !== 'admin'),
          },
        }));

    return dispatch({
      type: PRISMIC_LOAD_USER_MANUAL_V2_DATA_FINISHED,
      data,
    });
  } catch (error) {
    return dispatch({
      type: PRISMIC_LOAD_USER_MANUAL_V2_DATA_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [PRISMIC_LOAD_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_DATA_FINISHED]: (state, action) => ({
      ...state,
      general: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),

    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FINISHED]: (state, action) => ({
      ...state,
      admin: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_ADMIN_USERMANUAL_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),

    [PRISMIC_LOAD_ROADMAP_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_ROADMAP_DATA_FINISHED]: (state, action) => ({
      ...state,
      roadmap: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_ROADMAP_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),

    [PRISMIC_LOAD_USER_MANUAL_V2_DATA]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PRISMIC_LOAD_USER_MANUAL_V2_DATA_FINISHED]: (state, action) => ({
      ...state,
      manual: action.data,
      loading: false,
    }),
    [PRISMIC_LOAD_USER_MANUAL_V2_DATA_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
