import { Duration } from 'date-fns';
import intervalToDuration from 'date-fns/intervalToDuration';

import { formatNumber } from 'utils/Number/decimalFormat';

/**
 * Format a duration, expressed as milliseconds, to the largest natural unit (years, months, days,
 * hours, minutes, seconds). If with non-zero value, the one smaller unit is appended as well.
 *
 * @example
 *   const [t] = useTranslations();
 *   const language = useSelector(state => state.profile.profile.language);
 *
 *   console.log(formatDuration(0, language, t);
 *   // 0 s
 *
 *   const minutes = 60 * 1000;
 *   const hours = 60 * minutes;
 *   const days = 24 * hours;
 *   console.log(formatDuration(3 * days + 11 hours,  language, t);
 *   // 3 d 11 h
 *
 *   console.log(formatDuration(3 * days + 11 hours + 50 * minutes, language, t);
 *   // 3 d 11 h
 *
 * @param milliseconds
 * @param language
 * @param t
 */
export function formatDuration(milliseconds: number, language: string, t: (key: string) => string): string {
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const formattedDuration: { [key in keyof Duration]: string } = {};

  for (const key of Object.keys(duration) as (keyof Duration)[]) {
    if (duration[key]) {
      formattedDuration[key] = formatNumber(language, duration[key]);
    }
  }

  if (duration.years && duration.months) {
    return `${duration.years} ${t('a')} ${duration.months} ${t('mth')}`;
  }

  if (duration.years) {
    return `${duration.years} ${t('a')}`;
  }

  if (duration.months && duration.days) {
    return `${duration.months} ${t('mth')} ${duration.days} ${t('d')}`;
  }

  if (duration.months) {
    return `${duration.months} ${t('mth')}`;
  }

  if (duration.days && duration.hours) {
    return `${duration.days} ${t('d')} ${duration.hours} ${t('h')}`;
  }

  if (duration.days) {
    return `${duration.days} ${t('d')}`;
  }

  if (duration.hours && duration.minutes) {
    return `${duration.hours} ${t('h')} ${duration.minutes} ${t('min')}`;
  }

  if (duration.hours) {
    return `${duration.hours} ${t('h')}`;
  }

  if (duration.minutes && duration.seconds) {
    return `${duration.minutes} ${t('min')} ${duration.seconds} ${t('s')}`;
  }

  if (duration.minutes) {
    return `${duration.minutes} ${t('min')}`;
  }

  return `${duration.seconds} ${t('s')}`;
}
