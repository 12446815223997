import React from 'react';
import PropTypes from 'prop-types';
import StatusTag from 'components/Tag/StatusTag';
import { OrderStatusColors, OrderStatus } from 'constants/maintenance';
import Tag from 'components/Tag/Tag';
import { serviceOrderColors } from 'components/Calendar/serviceOrderColors';

const tagTextColor = ({ status, hover = false }) => {
  if (status === OrderStatus.CANCELLED) {
    return 'var(--order-cancelled-tag-fg)';
  }

  if (status === OrderStatus.DRAFT && hover) {
    return 'var(--order-draft-tag-fg)';
  }

  if (status === OrderStatus.OVERDUE) {
    return 'var(--order-overdue-tag-fg)';
  }

  if (status === OrderStatus.REQUEST) {
    return 'var(--order-request-tag-fg)';
  }

  if (status) {
    return 'var(--order-any-status-tag-fg)';
  }

  return 'var(--order-no-status-tag-fg)';
};

const tagBackgroundColor = ({ status, statusColors, hover = false }) => {
  if (status === OrderStatus.DRAFT && hover) {
    return 'var(--order-draft-tag-bg)';
  }

  if (status === OrderStatus.OVERDUE) {
    return '--order-overdue-tag-bg';
  }

  if (status) {
    return serviceOrderColors[statusColors?.[status] || OrderStatusColors[status]] || 'var(--order-any-status-tag-fg)';
  }

  return 'var(--order-no-status-tag-bg)';
};

const tagBorderColor = ({ status, statusColors, hover = false }) => {
  if (status === OrderStatus.CANCELLED) {
    return 'var(--order-cancelled-tag-bc)';
  }

  if ((status === OrderStatus.DRAFT && hover) || status === OrderStatus.OVERDUE) {
    return serviceOrderColors[statusColors?.[status] || OrderStatusColors[status]];
  }

  if (status) {
    return 'transparent';
  }

  return 'var(--order-no-status-tag-bc)';
};

const CalendarStatusTag = ({ status, text, onClick, small, hasCursor, statusColors, showFullText }) => {
  const sharedProps = {
    backgroundColor: tagBackgroundColor({ status, statusColors }),
    backgroundHoverColor: tagBackgroundColor({ status, statusColors, hover: true }),
    borderColor: tagBorderColor({ status, statusColors }),
    borderHoverColor: tagBorderColor({ status, statusColors, hover: true }),
    textColor: tagTextColor({ status }),
    textHoverColor: tagTextColor({ status, hover: true }),
    onClick: onClick,
    small: small,
    hasCursor: hasCursor,
  };
  if (showFullText) {
    return (
      <Tag {...sharedProps} showFullText>
        {text}
      </Tag>
    );
  }
  return <StatusTag {...sharedProps} text={text} />;
};

CalendarStatusTag.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  hasCursor: PropTypes.bool,
  statusColors: PropTypes.object,
  showFullText: PropTypes.bool,
};

export default CalendarStatusTag;
