import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { setMenuStatus, loadServiceRequestCount } from 'redux/modules/index';
import { MenuToggle } from 'components/index';
import Svg from 'components/Svg/Svg';
import GeneralAnnouncements from 'components/Announcement/GeneralAnnouncements';
import SideNavigation from 'components/SideNavigation/SideNavigation';
import Context from './Context';
import { getAnnouncements } from 'components/Announcement/utils';
import { myCustomerCustomViews } from 'redux/modules/customView/customView';
import Feedback from 'components/Feedback/Feedback';
import NavBarButton from 'components/NavBarButton/NavBarButton';
import translations from 'decorators/Translations/translations';
import { constructRelativeUrl } from 'utils/url';
import NumberBadge from 'components/NumberBadge/NumberBadge';
import { isServiceRequestToolsEnabled } from 'utils/Data/profileData';

import * as headerStyles from './Header.module.scss';

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.navigation.height};
  z-index: ${props => props.theme.zIndex('header')};

  ${props => props.theme.media.landscape`
    position: fixed;
  `};

  @media print {
    display: none;
  }
`;

const StyledHeader = styled.header`
  position: relative;
  display: flex;
  height: ${props => props.theme.navigation.height};
  width: 100%;
  pointer-events: none;
  background-color: var(--brand-color);
  z-index: var(--header-z);
`;

const CenterFillContainer = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  min-width: 0;
`;

const linkStyles = () => css`
  pointer-events: auto;
  z-index: 10;
  padding: 0 var(--size-md);
  display: none;
  align-items: center;

  ${props => props.theme.media.landscape`
    display: flex;
  `}
`;

const StyledLink = styled(Link)`
  ${props => linkStyles(props)}
`;
StyledLink.displayName = 'StyledLink';

const StyledALink = styled.a`
  ${props => linkStyles(props)}
`;
StyledALink.displayName = 'StyledALink';

const AnnouncementUnreadContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  ${props => props.theme.media.portrait`
        display: none;
    `}
`;

AnnouncementUnreadContainer.displayName = 'AnnouncementUnreadContainer';

const CaverionIcon = styled(Svg)`
  fill: var(--white);
`;

CaverionIcon.displayName = 'CaverionIcon';

export class Header extends PureComponent {
  componentDidMount() {
    const {
      match: {
        params: { partnerNumber },
      },
      selected,
      profile,
    } = this.props;

    if (selected && partnerNumber && partnerNumber !== 'all') {
      this.props.myCustomerCustomViews(partnerNumber);
      if (isServiceRequestToolsEnabled(profile.features)) {
        this.props.loadServiceRequestCount({ partnerNumber });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { partnerNumber },
      },
      selected,
      profile,
    } = this.props;

    if (
      selected &&
      partnerNumber &&
      partnerNumber !== prevProps.match.params.partnerNumber &&
      partnerNumber !== 'all'
    ) {
      this.props.myCustomerCustomViews(partnerNumber);
      if (isServiceRequestToolsEnabled(profile.features)) {
        this.props.loadServiceRequestCount({ partnerNumber });
      }
    }
  }

  handleServiceRequestClick = () => {
    const { match, history } = this.props;
    const url = constructRelativeUrl(match.params, 'ServiceRequest');
    history.push(url);
  };

  render() {
    const {
      toggleMenu,
      menuOpen,
      match,
      location,
      context,
      t,
      links,
      linksMobile,
      selected,
      showPartnerSelect,
      profile,
      generalAnnouncements,
      personalAnnouncements,
      activePartner,
      useALinkTag,
      loading,
      customViewsByCustomer,
    } = this.props;

    const hasContextualNavi = !!(links || selected);
    const shouldShowContext = !!context || showPartnerSelect;
    const { unread } = getAnnouncements(profile, generalAnnouncements, personalAnnouncements, activePartner);
    const linkTitle = 'SmartView v' + process.env.VERSION;
    const isNewServiceRequestEnabled = profile?.features?.sr && match?.params?.partnerNumber;

    return (
      <Fragment>
        <HeaderContainer data-test-id="Header">
          <StyledHeader>
            {useALinkTag ? (
              <StyledALink data-test-id="HeaderLink" href="/" title={linkTitle}>
                <div className={headerStyles.logo} />
              </StyledALink>
            ) : (
              <StyledLink data-test-id="HeaderLink" to="/" title={linkTitle} onClick={this.toggleMenu}>
                <div className={headerStyles.logo} />
              </StyledLink>
            )}
            <CenterFillContainer>
              {shouldShowContext && (
                <Context match={match} t={t} {...context} location={location} showPartnerSelect={showPartnerSelect} />
              )}
            </CenterFillContainer>
            {isNewServiceRequestEnabled && (
              <NavBarButton
                icon="new-service-request"
                label={t('New service request')}
                onClick={this.handleServiceRequestClick}
              />
            )}
            <Feedback />
            <GeneralAnnouncements />
            <MenuToggle toggleMenu={toggleMenu} menuOpen={menuOpen} />
            <AnnouncementUnreadContainer>
              <NumberBadge number={unread.length} />
            </AnnouncementUnreadContainer>
          </StyledHeader>
        </HeaderContainer>
        {hasContextualNavi && (
          <SideNavigation
            t={t}
            links={links}
            linksMobile={linksMobile}
            selected={selected}
            loading={(context && context.loading) || loading}
            customViews={customViewsByCustomer[match.params.partnerNumber]}
          />
        )}
      </Fragment>
    );
  }
}

Header.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  context: PropTypes.object,
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  links: PropTypes.array,
  linksMobile: PropTypes.array,
  selected: PropTypes.string,
  showPartnerSelect: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  generalAnnouncements: PropTypes.array.isRequired,
  personalAnnouncements: PropTypes.array.isRequired,
  activePartner: PropTypes.string,
  useALinkTag: PropTypes.bool,
  loading: PropTypes.bool,
  customViewsByCustomer: PropTypes.object.isRequired,
  myCustomerCustomViews: PropTypes.func.isRequired,
  loadServiceRequestCount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  menuOpen: state.navigation.menuOpen,
  generalAnnouncements: state.announcement.general,
  personalAnnouncements: state.announcement.personal,
  activePartner: state.profile.activePartner,
  profile: state.profile.profile,
  customViewsByCustomer: state.customView.customViewsByCustomer,
});

const mapDispatchToProps = {
  toggleMenu: setMenuStatus,
  myCustomerCustomViews,
  loadServiceRequestCount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(translations(Header)));
