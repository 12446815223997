import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { MASTER_DATA_PATH } from './index';

export const invalidateCustomerSupplierCaches = () =>
  invalidateCachesWithUrlMatch(`${MASTER_DATA_PATH}/customerSuppliers`);

export const searchCustomerSuppliers = (partnerNumber, searchTerm) =>
  ApiRequest.get({
    apiPath: `${MASTER_DATA_PATH}/customerSuppliers`,
    query: { partnerNumber, searchTerm },
    noCache: true,
  });

export const createCustomerSupplier = customerSupplier =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_PATH}/customerSuppliers`,
    data: customerSupplier,
  });

export const patchCustomerSupplier = customerSupplier =>
  ApiRequest.patch({
    apiPath: `${MASTER_DATA_PATH}/customerSuppliers/${customerSupplier.id}`,
    data: customerSupplier,
  });
