import { useEffect, useState, RefObject } from 'react';
import throttle from 'lodash/throttle';

export type ScrollingState = {
  containerWidth: number;
  contentWidth: number;
  clientWidth: number;
  containerHeight: number;
  contentHeight: number;
  clientHeight: number;
  /** Same as scrollLeft */
  horizontalScroll: number;

  /** Is scrolling */
  scrollable: boolean;
  /** Scrolled all the way to the left*/
  scrolledLeft: boolean;
  /** Scrolled all the way to the left*/
  scrolledRight: boolean;
};

export function useHorizontalScrollDimensions(
  containerRef: RefObject<HTMLElement>,
  onScrollingChange?: (scrollingState: ScrollingState) => void,
  throttleTime = 100
) {
  const [scrollingState, setScrollingState] = useState<ScrollingState | null>();

  useEffect(() => {
    const updateDimensions = throttle(() => {
      if (containerRef.current) {
        const dimensions = {
          containerWidth: containerRef.current.offsetWidth,
          contentWidth: containerRef.current.scrollWidth,
          clientWidth: containerRef.current.clientWidth,
          containerHeight: containerRef.current.offsetHeight,
          contentHeight: containerRef.current.scrollHeight,
          clientHeight: containerRef.current.clientHeight,
          horizontalScroll: containerRef.current.scrollLeft,
        };

        const scrollable = dimensions.contentWidth > dimensions.containerWidth;
        const scrolledLeft = dimensions.horizontalScroll === 0;
        const scrolledRight = dimensions.horizontalScroll === dimensions.contentWidth - dimensions.containerWidth;

        const newScrollingState = { ...dimensions, scrollable, scrolledLeft, scrolledRight };

        setScrollingState(newScrollingState);
        onScrollingChange?.(newScrollingState);
      }
    }, throttleTime);

    const resizeObserver = containerRef.current && new ResizeObserver(updateDimensions);

    if (containerRef.current) {
      updateDimensions();
      containerRef.current.addEventListener('scroll', updateDimensions);
      resizeObserver?.observe(containerRef.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [containerRef]);

  return scrollingState;
}
