import React from 'react';
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import GaugeChart from 'components/Charts/GaugeChart';
import Loader from 'components/Loader/Loader';

import { CHART_HEIGHT_MOBILE } from './StatusInfo';

const Chart = styled.div`
  position: relative;
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;

  ${props => props.theme.media.landscape`
    display: flex;
    margin: var(--size-md);
  `}
`;
Chart.displayName = 'Chart';

const ChartInner = styled.div`
  position: relative;
  z-index: 1;
`;
ChartInner.displayName = 'ChartInner';

const ChartPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.mobileSize}px;
  height: ${props => props.mobileSize}px;

  ${props => props.theme.media.landscape`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
  `}
`;
ChartPlaceholder.displayName = 'ChartPlaceholder';

export const ChartLoader = ({ size, mobileSize, hideOnMobile }) => (
  <Chart hideOnMobile={hideOnMobile}>
    <ChartPlaceholder size={size} mobileSize={mobileSize}>
      <Loader color="GRAY" />
    </ChartPlaceholder>
  </Chart>
);
ChartLoader.propTypes = {
  size: PropTypes.number,
  mobileSize: PropTypes.number,
  hideOnMobile: PropTypes.bool,
};

// Styled components has a bug with withTheme on functional components so we use normal component here
const StatusInfoChart = ({
  value,
  min,
  max,
  unit,
  axisTitle,
  valueNote,
  theme,
  minWidth,
  showValue,
  addPadding,
  loading,
}) => {
  let chartSize = minWidth;
  chartSize -= 32;
  // Limit chart size to 300px
  chartSize = chartSize < 300 ? chartSize : 300;

  if (loading) {
    return <ChartLoader size={chartSize} mobileSize={CHART_HEIGHT_MOBILE} />;
  }

  if (isNil(value)) {
    return null;
  }

  return (
    <Chart>
      <ChartInner>
        <GaugeChart
          size={chartSize}
          value={value}
          min={min}
          max={max}
          unit={unit}
          valueNote={valueNote}
          valueColor={theme.colors.black}
          background={theme.colors.alabaster}
          backgroundPadding={addPadding ? 3 : 0}
          axisTitle={axisTitle}
          lineWidth={24}
          showValue={showValue}
          hidePlus
        />
      </ChartInner>
    </Chart>
  );
};

StatusInfoChart.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  axisTitle: PropTypes.string,
  valueNote: PropTypes.string,
  theme: PropTypes.object,
  minWidth: PropTypes.number,
  showValue: PropTypes.bool,
  addPadding: PropTypes.bool,
  loading: PropTypes.bool,
};

export default withTheme(StatusInfoChart);
