import React from 'react';
import PropTypes from 'prop-types';

const formatBoolean = (t, value) => {
  if (!t || value == null) {
    return '';
  }
  if (value === true) {
    return t('Yes');
  }
  return t('No');
};

const DefaultColumn = ({ t, data, unit }) => {
  if (!data) {
    return null;
  }

  const value = typeof data === 'boolean' ? formatBoolean(t, data) : data;

  return (
    <>
      {value} {unit ? unit : ''}
    </>
  );
};

DefaultColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func,
  unit: PropTypes.string,
};

export default DefaultColumn;
