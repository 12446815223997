import { loadPartnerObservations, loadFunctionalLocationObservations } from 'redux/modules/iot/notice';

export const loadEnergyObservations = partnerNumber => {
  return async dispatch => {
    dispatch(loadPartnerObservations(partnerNumber, { energyImpact: true }));
  };
};

export const loadEnergyObservationsForFL = (partnerNumber, functionalLocationId) => {
  return async dispatch => {
    dispatch(loadFunctionalLocationObservations({ functionalLocation: functionalLocationId }, partnerNumber, true));
  };
};
