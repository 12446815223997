import { useMemo } from 'react';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';
import { ContactDetail } from 'components/Maintenance/ContactDetails';

interface OrderWithContacts {
  requestedByPerson: string;
  requestedByPhoneNumber: string;
  requestedByEmail: string;
  supplierName: string;
  supplierPhoneNumber: string;
  supplierEmail: string;
  contactName: string;
  contactEmail: string;
  contactPhoneNumber: string;
}

const useContactDetails = (order: OrderWithContacts, t: TranslationFunction): ContactDetail[] => {
  const contactDetails = useMemo(() => {
    const details = [];

    if (order?.requestedByPerson) {
      details.push({
        title: t('Contact Person'),
        name: order.requestedByPerson,
        phone: order.requestedByPhoneNumber,
        email: order.requestedByEmail,
      });
    }

    if (order?.contactName) {
      details.push({
        title: t('Contact Person'),
        name: order.contactName,
        phone: order.contactPhoneNumber,
        email: order.contactEmail,
      });
    }

    if (order?.supplierName) {
      details.push({
        title: t('Supplier'),
        name: order.supplierName,
        phone: order.supplierPhoneNumber,
        email: order.supplierEmail,
      });
    }

    return details;
  }, [order, t]);

  return contactDetails;
};

export default useContactDetails;
