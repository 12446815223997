import React from 'react';
import MasterDataService from 'services/masterData';

const usePublicMeta = publicView => {
  const [meta, setMeta] = React.useState();

  const load = () => {
    MasterDataService.getPublicViewMeta(publicView.id).then(setMeta);
  };

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return meta;
};

export default usePublicMeta;
