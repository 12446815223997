import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const getGap = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

type ContentProps = PropsWithChildren<{
  gap?: keyof typeof getGap;
}>;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layoutSpacing[getGap[props.gap ?? 'medium']]};
`;

export const SectionContent = styled(Content)`
  margin: ${props => props.theme.layoutSpacing.md} 0 0;

  ${props => props.theme.media.tablet`
    margin: ${props => props.theme.layoutSpacing.lg} 0 0;
  `}
`;

export default Content;
