import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import useMeasure from 'react-use-measure';

import { PaginationTinyPageSelector } from 'components/Pagination/PaginationTinyPageSelector';
import { rootSize } from 'styles/definitions';

import { PaginationPageCounter } from './PaginationPageCounter';
import { PaginationPageSelector } from './PaginationPageSelector';
import { FirstPageOrAllPagination } from './FirstPageOrAllPagination';

import {
  PaginationPageSizeSelector,
  PaginationRowCountValue,
  PaginationRowCountOption,
  defaultPaginationRowCountOptions,
} from './PaginationPageSizeSelector';

export type PaginationContainerProps = {
  stackVertically?: boolean;
};

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: flex;
  flex-direction: ${props => (props.stackVertically ? 'column' : 'row')};
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${props => (props.stackVertically ? 'center' : 'space-between')};
  gap: var(--size-sm) var(--size-xl);
`;

export const PaginationSecondaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--size-md);
`;

export const WidthMeasurementContainer = styled.div.attrs(props => ({ ...props, 'aria-hidden': 'true' }))`
  position: absolute;
  display: block;
  width: auto;
  height: 1px;
  visibility: hidden;
`;

export type PaginationProps = {
  currentPage: number;
  totalItems: number;
  paginationRowCountOptions?: PaginationRowCountOption[];
  pageSize?: PaginationRowCountValue;
  pageSizeSelectorWidth?: number;
  maxVisiblePages?: number;
  onPageSizeChange?: (rowCount: PaginationRowCountValue) => void;
  onPageChange?: (page: number) => void;
  /**
   * Simple first page or all mode. If enabled, make sure that there are at least row count
   * options, and that first of doesn't have `null` value, and that one of them does. E.g.
   * `paginationRowCountOptions={[{ value: 5, label: '5'}, { value: null, label: 'All' }]}`.
   */
  firstPageOrAll?: boolean;
};

/**
 * @implements UIv3
 */
export function Pagination({
  currentPage,
  totalItems,
  paginationRowCountOptions = defaultPaginationRowCountOptions,
  pageSize = paginationRowCountOptions[0].value,
  pageSizeSelectorWidth,
  maxVisiblePages = 7,
  onPageSizeChange,
  onPageChange,
  firstPageOrAll = false,
}: PaginationProps): JSX.Element | null {
  if (currentPage < 1) {
    currentPage = 1;
  }

  if (totalItems < 0) {
    totalItems = 0;
  }

  if (maxVisiblePages < 0) {
    maxVisiblePages = 7;
  }

  const paginationRowCountValues = paginationRowCountOptions.map(option => option.value);

  if (!paginationRowCountValues.includes(pageSize)) {
    pageSize = paginationRowCountValues[0];
  }

  const totalPages = pageSize ? Math.ceil(totalItems / pageSize) : 1;

  if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  const firstItem = pageSize ? (currentPage - 1) * pageSize + 1 : 1;
  let lastItem = pageSize ? Math.min(firstItem + pageSize - 1 || 1, totalItems) : totalItems;

  const showAll = firstPageOrAll && pageSize === null;

  if (showAll) {
    lastItem = totalItems;
  }

  // Use actual container width so that selection between row/column layout works anywhere. This way there
  // is no need to make any bold assumptions about how the component is positioned in the layout.

  const [containerRef, containerBounds] = useMeasure();
  const [pageCounterRef, pageCounterBounds] = useMeasure();
  const [pageSelectorRef, pageSelectorBounds] = useMeasure();
  const [pageCounterWithoutLabelRef, pageCounterWithoutLabelBounds] = useMeasure();

  const horizontalGapWidth = 2 * rootSize;

  const pageSelectorButtonWidthReservation = pageSelectorBounds.width;

  const paginationPageCounterReservation = pageCounterBounds.width;
  const paginationPageCounterWithoutLabelReservation = pageCounterWithoutLabelBounds.width;

  const displayPageSelector = totalPages > 1;
  const gapReservation = (displayPageSelector ? 2 : 1) * horizontalGapWidth;

  const basePageSizeSelectorWidth = pageSizeSelectorWidth ?? 86;

  const rowsTextWidth = 34;
  const pageSizeSelectorWidthNarrow = basePageSizeSelectorWidth;
  const pageSizeSelectorWidthWide = basePageSizeSelectorWidth + rowsTextWidth;

  // Requirement for configuration with no page counter label
  const minWidthForHorizontalLayoutNarrow =
    paginationPageCounterWithoutLabelReservation +
    pageSelectorButtonWidthReservation +
    pageSizeSelectorWidthNarrow +
    gapReservation;

  // Requirement for configuration with narrow page selector
  const minWidthForHorizontalLayoutWide =
    paginationPageCounterReservation +
    pageSelectorButtonWidthReservation +
    pageSizeSelectorWidthNarrow +
    gapReservation;

  // Requirement for configuration with full width
  const minWidthForHorizontalLayoutFull =
    paginationPageCounterReservation + pageSelectorButtonWidthReservation + pageSizeSelectorWidthWide + gapReservation;

  const minWidthForTwoRowLayout = Math.max(
    pageSelectorButtonWidthReservation,
    paginationPageCounterWithoutLabelReservation + pageSizeSelectorWidthNarrow + horizontalGapWidth
  );

  let ultraNarrow = false;
  let tinyVariant = false;
  let stackVertically = false;
  let labelHidden = false;
  let reserveTextWidth = true;
  let pageSizeSelectorWithText = false;
  let resultingPageSizeSelectorWidth = pageSizeSelectorWidthNarrow;

  switch (true) {
    case containerBounds.width < 130:
      ultraNarrow = true;
      stackVertically = true;
      tinyVariant = true;
      labelHidden = true;
      reserveTextWidth = false;
      break;

    case containerBounds.width < minWidthForTwoRowLayout:
      stackVertically = true;
      tinyVariant = true;
      labelHidden = true;
      reserveTextWidth = false;
      break;

    case containerBounds.width < minWidthForHorizontalLayoutNarrow:
      stackVertically = true;
      labelHidden = true;
      reserveTextWidth = false;
      break;

    case containerBounds.width < minWidthForHorizontalLayoutWide:
      labelHidden = true;
      break;

    case containerBounds.width < minWidthForHorizontalLayoutFull:
      break;

    default:
      resultingPageSizeSelectorWidth = pageSizeSelectorWidthWide;
      pageSizeSelectorWithText = true;
  }

  const pageCounter = (
    <PaginationPageCounter
      first={firstItem}
      last={lastItem}
      total={totalItems}
      labelHidden={labelHidden}
      reserveTextWidth={reserveTextWidth}
    />
  );

  const pageSelector = displayPageSelector && (
    <PaginationPageSelector
      totalPages={totalPages}
      maxVisiblePages={maxVisiblePages}
      selectedPage={currentPage}
      onChange={onPageChange}
    />
  );

  const pageSizeSelector = (
    <PaginationPageSizeSelector
      value={pageSize}
      onChange={onPageSizeChange}
      width={resultingPageSizeSelectorWidth}
      withText={pageSizeSelectorWithText}
      paginationRowCountOptions={paginationRowCountOptions}
    />
  );

  if (firstPageOrAll) {
    return (
      <FirstPageOrAllPagination
        first={firstItem}
        last={lastItem}
        total={totalItems}
        showAll={showAll}
        setShowAll={() => onPageSizeChange?.(showAll ? paginationRowCountOptions[0]?.value : null)}
        reserveTextWidth={reserveTextWidth}
      />
    );
  }

  return (
    <div ref={containerRef}>
      <WidthMeasurementContainer ref={pageCounterRef}>
        <PaginationPageCounter
          first={firstItem}
          last={lastItem}
          total={totalItems}
          labelHidden={false}
          reserveTextWidth
        />
      </WidthMeasurementContainer>

      <WidthMeasurementContainer ref={pageCounterWithoutLabelRef}>
        <PaginationPageCounter
          first={firstItem}
          last={lastItem}
          total={totalItems}
          labelHidden={true}
          reserveTextWidth
        />
      </WidthMeasurementContainer>

      <WidthMeasurementContainer ref={pageSelectorRef}>{pageSelector}</WidthMeasurementContainer>

      {containerBounds.width > 0 && (
        <PaginationContainer stackVertically={stackVertically}>
          {tinyVariant ? (
            <PaginationTinyPageSelector
              totalPages={totalPages}
              selectedPage={currentPage}
              onChange={onPageChange}
              ultraNarrow={ultraNarrow}
            />
          ) : stackVertically ? (
            <>
              {pageSelector}
              <PaginationSecondaryContainer>
                {pageCounter}
                {pageSizeSelector}
              </PaginationSecondaryContainer>
            </>
          ) : (
            <>
              {pageCounter}
              {pageCounterBounds.width > 0 && (
                <>
                  {pageSelector}
                  {pageSizeSelector}
                </>
              )}
            </>
          )}
        </PaginationContainer>
      )}
    </div>
  );
}
