import { createReducerFromMapping } from 'redux/utils/index';
import MasterData from 'services/masterData';
import differenceBy from 'lodash/differenceBy';

const initialState = {
  data: [],
  loading: {
    inProgress: false,
    error: false,
  },
  saving: {
    inProgress: false,
    error: false,
  },
};

export const CONFIG_GET = 'CUSTOMER_PLATFORM/Config/GET';
export const CONFIG_UPSERT = 'CUSTOMER_PLATFORM/Config/UPSERT';

export const fetchConfig = refreshCache => async dispatch => {
  dispatch({ type: CONFIG_GET });
  try {
    const payload = await MasterData.getDivisionConfig({ refreshCache });
    dispatch({ type: CONFIG_GET, payload });
  } catch (payload) {
    dispatch({ type: CONFIG_GET, error: true, payload });
  }
};

export const upsertConfig = options => async dispatch => {
  dispatch({ type: CONFIG_UPSERT });
  try {
    const payload = await MasterData.upsertDivisionConfig(options);
    dispatch({ type: CONFIG_UPSERT, payload });
  } catch (payload) {
    dispatch({ type: CONFIG_UPSERT, error: true, payload });
  }
};

export default createReducerFromMapping(
  {
    [CONFIG_GET]: (state, action) => ({
      ...state,
      data: action.payload && !action.error ? action.payload : [],
      loading: {
        inProgress: !action.payload,
        error: !!action.error,
      },
    }),
    [CONFIG_UPSERT]: (state, action) => ({
      ...state,
      data:
        action.payload && !action.error
          ? [...differenceBy(state.data, action.payload, 'id'), ...action.payload]
          : state.data,
      saving: {
        inProgress: !action.payload,
        error: !!action.error,
      },
    }),
  },
  initialState
);
