import each from 'lodash/each';
import find from 'lodash/find';
import includes from 'lodash/includes';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import some from 'lodash/some';
import inRange from 'lodash/inRange';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import omit from 'lodash/omit';

import memoizeOne from 'memoize-one';
import { isValidPartner } from 'utils/Data/partners';
import { formatValue } from 'utils/Data/values';

export const ENERGY_CONSUMPTION_SENSOR_TYPES = ['electricity_main', 'heating_main', 'cooling_main'];

export const generateFilterInputs = (buildingMetaByFl, t) => {
  if (buildingMetaByFl) {
    const metaKeys = [];

    each(buildingMetaByFl, flMeta => {
      each(flMeta, singleMeta => {
        if (!!singleMeta.filterable) {
          const { key, value } = singleMeta;
          const metaKey = find(metaKeys, _metaKey => _metaKey.key === key);
          const objetValue = { label: t(value), value };
          if (metaKey) {
            if (!find(metaKey.values, valueObject => valueObject.value === value)) {
              metaKey.values.push(objetValue);
            }
          } else {
            const object = {};
            object.key = key;
            object.values = [objetValue];
            metaKeys.push(object);
          }
        }
      });
    });
    metaKeys.forEach(meta => {
      if (meta.values.every(({ value }) => Number.isFinite(Number(value)))) {
        meta.isNumber = true;
        meta.values.forEach(item => {
          item.value = Number(item.value);
        });
      }
    });
    return metaKeys;
  }
};

/**
 * FL:s are filtered based in building meta.
 * Here we find FL:s with meta that is included in the selected filter.
 */
export const getFLsByFilterMatchedBuildingMeta = (buildingMetas, selectedFilterValues) => {
  const FlArray = [];
  // loop over building meta
  each(buildingMetas, (flMeta, fl) => {
    let found = false;
    // check if selected filters apply to current FL
    each(selectedFilterValues, (value, key) => {
      // find corresponding meta for current filter (also checks that meta value matches filtering)
      const foundMeta = find(
        flMeta,
        metaRow =>
          metaRow.key === key && isArray(value) // multi dropdown and slider values are arrays
            ? isPlainObject(value[0]) // multi dropdown individial values are option-objects
              ? some(value, { value: metaRow.value }) // check that meta value is one of multiple filter values
              : inRange(metaRow.value, value[0], value[1] + 1) // check that meta value is in filtered range
            : metaRow.value === value // or simple value check
      );
      if (foundMeta) {
        found = true;
      } else {
        // current filter didn't apply, so FL shouldn't be included in the result
        found = false;
        return false; // exit from each
      }
    });

    // all filters applied to FL:s meta => push FL to result and reset found
    if (found) {
      FlArray.push(fl);
      found = false;
    }
  });
  return FlArray;
};

export const constructAndSortEnergyValues = (energyValues, filterableFLs) => {
  const results = {};
  each(energyValues, (energyValue, key) => {
    if (includes(filterableFLs, key)) {
      mergeWith(results, energyValue, (objValue, srcValue) => {
        if (isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      });
    }
  });
  each(results, (result, key) => {
    results[key] = sortBy(result, 'timestamp');
  });
  return results;
};

export const extendEnergyRatingObjectsForBenchMarking = (energyRatingValues, functionalLocations, t) => {
  each(energyRatingValues, energyRatingObject => {
    const fl = functionalLocations[energyRatingObject.functionalLocation];
    energyRatingObject.title = fl ? fl.description : energyRatingObject.functionalLocation;
    energyRatingObject.value = energyRatingObject.latest;
    energyRatingObject.address = fl ? `${fl.address}, ${fl.postalCode} ${fl.city}` : t('No Address Found');
  });
};

export const hasPartnerChanged = (prevProps, partnerNumber) =>
  isValidPartner(partnerNumber) && !isEqual(prevProps.match.params.partnerNumber, partnerNumber);

export const hasEnergyValuesByPartnerChanged = (prevProps, partnerNumber, energyValuesByPartner) =>
  isValidPartner(partnerNumber) &&
  !isEqual(prevProps.energyValuesByPartner[partnerNumber], energyValuesByPartner[partnerNumber]);

export const filterFLsBySelectedPartner = (functionalLocations, partnerNumber) =>
  pickBy(functionalLocations, fl => includes(fl.partnerNumber, partnerNumber));

export const hasFunctionalLocationsChanged = (functionalLocations, prevProps) =>
  functionalLocations &&
  keys(functionalLocations).length > 0 &&
  !isEqual(functionalLocations, prevProps.functionalLocations);

export const hasFilters = filter =>
  !isEmpty(filter) && some(filter, option => option.values && option.values.length > 1);

const calculatePercentage = (fraction, total) => {
  return (fraction / total ?? 0) * 100;
};

export const calculateTotalConsumption = (consumptionKPIs, filterFls) =>
  calculateEnergySumsForFls(ENERGY_CONSUMPTION_SENSOR_TYPES, consumptionKPIs, filterFls).consumption;

export const calculateTotalRenewableEnergyPercentage = (consumptionKPIs, filterFls) => {
  const sums = calculateEnergySumsForFls(ENERGY_CONSUMPTION_SENSOR_TYPES, consumptionKPIs, filterFls);
  return calculatePercentage(sums.renewable, sums.consumption);
};

export const calculateTotalCo2Emission = (consumptionKPIs, filterFls) =>
  calculateEnergySumsForFls(ENERGY_CONSUMPTION_SENSOR_TYPES, consumptionKPIs, filterFls).co2Emissions;

const calculateEnergySumsForFls = memoizeOne((sensorTypes, consumptionKPIs, filterFls) =>
  sensorTypes.reduce(
    (sums, sensorType) => {
      const consumptionData = consumptionKPIs[sensorType]?.data;
      const filteredConsumptionFls =
        consumptionData?.byFL &&
        Object.keys(consumptionData.byFL).filter(fl => isEmpty(filterFls) || filterFls.includes(fl));
      if (!isEmpty(filteredConsumptionFls)) {
        filteredConsumptionFls.forEach(fl => {
          sums.consumption += consumptionData.byFL[fl];
          sums.renewable += consumptionData.renewable?.byFL?.[fl] || 0;
          sums.co2Emissions += consumptionData.co2Emissions?.byFL?.[fl] || 0;
        });
      }
      return sums;
    },
    { consumption: 0, renewable: 0, co2Emissions: 0 }
  )
);

export const hasCo2Emissions = memoizeOne(consumptionKPIs =>
  hasEnergyFraction(ENERGY_CONSUMPTION_SENSOR_TYPES, 'co2Emissions', consumptionKPIs)
);

export const hasRenewableEnergy = memoizeOne(consumptionKPIs =>
  hasEnergyFraction(ENERGY_CONSUMPTION_SENSOR_TYPES, 'renewable', consumptionKPIs)
);

const hasEnergyFraction = (sensorTypes, fractionKey, consumptionKPIs) => {
  if (isEmpty(consumptionKPIs)) {
    return false;
  }
  return sensorTypes.some(sensorType => {
    const consumptionData = consumptionKPIs[sensorType]?.data;
    const consumptionByFl = consumptionData?.byFL;
    const fractionEnergyByFl = consumptionData?.[fractionKey]?.byFL;
    if (!consumptionByFl || !fractionEnergyByFl) {
      return false;
    }
    return Object.keys(consumptionByFl).some(
      functionalLocation => consumptionByFl[functionalLocation] && fractionEnergyByFl[functionalLocation]
    );
  });
};

export const calculateCo2EmissionByFl = consumptionKPIs =>
  calculateEnergyFraction(ENERGY_CONSUMPTION_SENSOR_TYPES, 'co2Emissions', consumptionKPIs);

export const calculateRenewableEnergyByFl = consumptionKPIs =>
  calculateEnergyFraction(ENERGY_CONSUMPTION_SENSOR_TYPES, 'renewable', consumptionKPIs, true);

export const calculateEnergyFraction = (sensorTypes, dataKey, consumptionKPIs, fillPercentage) =>
  sensorTypes.reduce((energyByFl, sensorType) => {
    const consumptionData = consumptionKPIs[sensorType]?.data;
    const consumptionByFl = consumptionData?.byFL;
    const energyFractionByFl = consumptionData?.[dataKey]?.byFL;
    if (!consumptionByFl || !energyFractionByFl) {
      return energyByFl;
    }
    Object.keys(consumptionByFl).forEach(functionalLocation => {
      if (!energyByFl[functionalLocation]) {
        energyByFl[functionalLocation] = { total: 0, [dataKey]: 0 };
        if (fillPercentage) {
          energyByFl[functionalLocation].percentage = 0;
        }
      }
      energyByFl[functionalLocation].total += consumptionByFl[functionalLocation];
      if (energyFractionByFl[functionalLocation]) {
        energyByFl[functionalLocation][dataKey] += energyFractionByFl[functionalLocation];
      }
      if (fillPercentage) {
        energyByFl[functionalLocation].percentage = calculatePercentage(
          energyByFl[functionalLocation][dataKey],
          energyByFl[functionalLocation].total
        );
      }
    });
    return energyByFl;
  }, {});

export const consumptionValueFormatter = entry => {
  let { value, unit = 'kWh' } = entry;
  if (Number.isFinite(value)) {
    ({ value, unit } = formatValue(value, unit));
  }
  return `${value ?? ''} ${unit ?? ''}`;
};

export const convertCo2ToTonnes = co2InGrams => {
  // As consumption unit is kWh and CO2 emission multiplier is g CO2/kWh, unit for CO2 is g.
  return co2InGrams / 1000 / 1000;
};

export const getChangedFilterValue = (oldSelectedValues, property, value, originalValue) => {
  if (!isEmpty(value)) {
    return { ...oldSelectedValues, [property]: value };
  }
  if (
    isArray(oldSelectedValues[property]) &&
    isPlainObject(oldSelectedValues[property][0]) &&
    oldSelectedValues[property].length > 1
  ) {
    return {
      ...oldSelectedValues,
      [property]: reject(oldSelectedValues[property], { value: originalValue }),
    };
  }
  return omit(oldSelectedValues, property);
};
