import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

export const StyledContextualHelpButton = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: var(--size-xxs);
`;
StyledContextualHelpButton.displayName = 'StyledContextualHelpButton';

const Icon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.sm};
`;
Icon.displayName = 'Icon';

const ContextualHelpButton = ({ onClick }) => {
  return (
    <StyledContextualHelpButton onClick={onClick}>
      <Icon name="help" />
    </StyledContextualHelpButton>
  );
};

ContextualHelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContextualHelpButton;
