import { connect } from 'react-redux';
import { getProfile } from 'containers/Application/Modules/RecyclingModule/common/selectors';

const getRatesBreakdownData = state => state.values.recycling.ratesBreakdown.data;
const getRatesBreakdownTotals = state => state.values.recycling.ratesBreakdownTotals.data;
const getOPIData = state => state.values.recycling.opi.data || [];

export const mapStateToProps = (state, props) => ({
  profile: getProfile(state),
  ratesBreakdownData: getRatesBreakdownData(state),
  ratesBreakdownTotals: getRatesBreakdownTotals(state),
  opiData: getOPIData(state),
});

const connector = connect(mapStateToProps);

export default connector;
