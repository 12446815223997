import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import translations from 'decorators/Translations/translations';

import Content from 'components/Content/Content';
import Section from 'components/Section/Section';
import FunctionalLocationTable from 'components/FunctionalLocationTable/FunctionalLocationTable';
import SectionHeader from 'components/Section/SectionHeader';

const EquipmentModule = props => {
  const { t, loadingEquipment, equipments, subEquipments, title } = props;

  const sortedEquipment = sortBy(equipments, ['text', 'equipmentNumber']);

  const mappedEquipment = sortedEquipment.map(equipment => ({
    functionalLocation: equipment.functionalLocation,
    equipmentNumber: equipment.equipmentNumber,
    description: equipment.text || equipment.equipmentNumber,
    type: 'EQUIPMENT',
    equipmentCount: subEquipments?.[equipment.equipmentNumber]?.length ?? '...',
  }));

  return (
    <Section>
      <Content>
        <SectionHeader noBorder title={title || t('Equipment')} size="small" />
        <FunctionalLocationTable
          loading={loadingEquipment}
          t={t}
          data={mappedEquipment}
          useQueryPaging
          paginate
          hasEquipment
          isEquipment
          hideAddress
          initialSortBy={[{ id: 'description', desc: false }]}
        />
      </Content>
    </Section>
  );
};

EquipmentModule.defaultProps = {
  loadingEquipment: false,
  equipments: [],
};

EquipmentModule.propTypes = {
  t: PropTypes.func.isRequired,
  loadingEquipment: PropTypes.bool,
  equipments: PropTypes.array,
  subEquipments: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default translations(EquipmentModule);
