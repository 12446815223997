import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import SafetyNoticeForm from './SafetyNoticeForm';
import RescuePlanService from 'services/rescuePlan';
import InfoBox from 'components/InfoBox/InfoBox';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 600px;
`;

export const RescuePlanSafetyNotice = ({ t, planId, planLanguage }) => {
  const [SafetyNoticeInputOptions, setSatetyNoticeInputOptions] = useState({});
  const [error, setError] = useState(null);

  const fetchInputOptions = async id => {
    try {
      const options = await RescuePlanService.safetyRemarkInputOptionsById(id, planLanguage);
      setSatetyNoticeInputOptions(options);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchInputOptions(planId);
  }, [planId, planLanguage]);

  const submit = useCallback(
    async model => {
      try {
        await RescuePlanService.safetyRemarkNewById(planId, model);
      } catch (error) {
        setError(error);
      }
    },
    [planId]
  );

  return (
    <Container>
      <InfoBox>{t('Write your observation in the comment field.')}</InfoBox>
      <SafetyNoticeForm inputOptions={SafetyNoticeInputOptions} onSubmit={submit} />
    </Container>
  );
};

RescuePlanSafetyNotice.propTypes = {
  t: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  planLanguage: PropTypes.string.isRequired,
};

export default translations(RescuePlanSafetyNotice);
