import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const utilizationRateChartValues = query =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-values/charts/utilization-rate`,
    query,
    noCache: true,
    useAuthorization: true,
  });

export const utilizationRateChartValuesWithPublicView = query =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-values/charts/utilization-rate/with-public-view`,
    query,
    noCache: true,
    useAuthorization: false,
  });
