import React, { Component, Fragment, Suspense } from 'react';
import styled from 'styled-components';
import BlueprintLegend from 'components/Blueprint/BlueprintLegend';
import FilterPills from 'components/FilterPills/FilterPills';
import FilterPill from 'components/FilterPills/FilterPill';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import SkeletonPill from 'components/Skeletons/SkeletonPill';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import withQuery from 'decorators/Query/withQuery';

const BlueprintMap = React.lazy(() => import(/* webpackChunkName: "blueprint-map" */ './BlueprintMap'));

const FilterPillsContainer = styled.div`
  margin-top: var(--size-sm);
  border-bottom: var(--light-separator-border);
`;

const FloorPlan = styled.div`
  position: relative;
  padding-bottom: ${props => props.imageSize && (props.imageSize.height / props.imageSize.width) * 100 + '%'};
  overflow: ${props => (props.hasActions ? 'visible' : 'hidden')};
  @media print {
    page-break-inside: avoid;
  }
`;

const FloorPlanInner = styled.div`
  position: ${props => (props.isExpanded ? 'fixed' : 'absolute')};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--floor-plan-bg);
  z-index: ${props => props.isExpanded && props.theme.zIndex('blueprint')};
`;

const FloorSelector = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 var(--size-md);
  flex-flow: row nowrap;
  max-width: 700px;

  ${props => props.theme.media.landscape`
        display: ${props => (props.isExpanded ? 'flex' : 'none')};
    `}
`;

const SkeletonFilter = () => <SkeletonPill width="110px" margin="8px 0px 0px" />;

class Blueprint extends Component {
  state = {
    scrollTo: null,
    featureGroupsVisible: [],
  };

  componentDidMount() {
    // Format featureGroups to be visible by default
    const { featureGroups } = this.props;
    if (featureGroups && featureGroups.length > 0) {
      this.setState({
        featureGroupsVisible: featureGroups.map(() => true),
      });
    }
  }

  componentDidUpdate(prevProps) {
    // If featureGroups have changed, show all by default
    const { featureGroups } = this.props;
    if (featureGroups && featureGroups.length > 0 && !isEqual(featureGroups, prevProps.featureGroups)) {
      this.setState({
        featureGroupsVisible: featureGroups.map(() => true),
      });
    }
  }

  toggleFilter = layer => {
    this.setState(prevState => {
      const visibility = !prevState.featureGroupsVisible[layer];
      const visibleLayers = prevState.featureGroupsVisible.slice();
      visibleLayers[layer] = visibility;
      return { featureGroupsVisible: visibleLayers };
    });
  };

  getLoader = () => (
    <Fragment>
      {this.props.showFilters && (
        <FilterPillsContainer>
          <FilterPills>
            <SkeletonFilter />
            <SkeletonFilter />
            <SkeletonFilter />
          </FilterPills>
        </FilterPillsContainer>
      )}
      <SkeletonChart height="880px" width="100%">
        {this.props.t('Loading data')}...
      </SkeletonChart>
    </Fragment>
  );

  render() {
    const { showFilters, loading, icons, isExpanded, location, query, actions, renderFloorSelector, ...other } =
      this.props;
    const hasActions = actions?.length > 0;

    if (loading) {
      return this.getLoader();
    }

    return (
      <Suspense fallback={this.getLoader()}>
        {!this.props.editMode && showFilters && this.props.featureGroupNames && (
          <FilterPillsContainer>
            <FilterPills>
              {this.props.featureGroupNames.map((group, idx) => {
                return (
                  <FilterPill
                    selected={this.state.featureGroupsVisible[idx]}
                    onClick={() => {
                      this.toggleFilter(idx);
                    }}
                    key={'filter' + idx}
                  >
                    {group}
                  </FilterPill>
                );
              })}
            </FilterPills>
          </FilterPillsContainer>
        )}
        <FloorPlan imageSize={this.props.imageSize} hasActions={hasActions}>
          <FloorPlanInner isExpanded={isExpanded}>
            {(this.props.featureGroups && this.props.featureGroups.length > 0) || this.props.editMode ? (
              <Fragment>
                <BlueprintMap
                  {...other}
                  featureGroupsVisible={this.state.featureGroupsVisible}
                  isExpanded={isExpanded}
                  actions={actions}
                />
                {typeof renderFloorSelector === 'function' && (
                  <FloorSelector isExpanded={isExpanded}>{renderFloorSelector({ small: true })}</FloorSelector>
                )}
              </Fragment>
            ) : (
              <img src={this.props.image} alt={this.props.title} width="100%" />
            )}
          </FloorPlanInner>
        </FloorPlan>
        <BlueprintLegend t={this.props.t} icons={icons} />
      </Suspense>
    );
  }
}

Blueprint.defaultValues = {
  featureGroups: [],
  featureGroupNames: [],
  editMode: false,
  isExpanded: false,
  title: '',
  showFilters: false,
  loading: true,
  icons: [],
};

Blueprint.propTypes = {
  // Image url
  image: PropTypes.string.isRequired,
  // Image size as { height, width }
  imageSize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }).isRequired,
  renderFloorSelector: PropTypes.func,
  // Translation function
  t: PropTypes.func.isRequired,
  // react-router location object
  location: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  // Feature groups (containing areas, sensors, etc)
  featureGroups: PropTypes.array,
  // Feature group names
  featureGroupNames: PropTypes.array,
  // Edit mode allows drawing features
  editMode: PropTypes.bool,
  // State of full window view
  isExpanded: PropTypes.bool,
  // Image title
  title: PropTypes.string,
  // Show filters
  showFilters: PropTypes.bool,
  // Loading state
  loading: PropTypes.bool,
  // Icons
  icons: PropTypes.array,
  // Sensor action menu actions
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default withRouter(withQuery(Blueprint));
