import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { IOT_PATH } from './index';

export const getEnergyChartValues = (
  request,
  { functionalLocations = [] }
) =>
  request.post({
    apiPath: `${IOT_PATH}/values/charts/energy`,
    data: {
      functionalLocations
    },
  });

export default wrapEachWithThunkRequest({
  getEnergyChartValues: getEnergyChartValues,
});
