import { ColumnDef } from '@tanstack/react-table';

import { useTranslations } from 'decorators/Translations/translations';
import { DateCell } from 'components/VirtualizedTable/shared/DateCell';

import { InsightTableMessage } from './InsightTable';
import { InsightLocationCell } from './InsightLocationCell';
import { InsightMessageCell } from './InsightMessageCell';

export function useInsightTableColumns(): ColumnDef<InsightTableMessage, string>[] {
  const [t] = useTranslations();

  return [
    {
      header: t('Location'),
      accessorKey: 'location',
      cell: InsightLocationCell,
      minSize: 100,
      size: 150,
    },
    {
      header: t('Insight message'),
      accessorKey: 'insightMessage',
      cell: InsightMessageCell,
      enableSorting: false,
      minSize: 250,
    },
    {
      header: t('Created at'),
      accessorKey: 'createdAt',
      cell: DateCell,
      enableResizing: false,
      minSize: 150,
      size: 150,
    },
    {
      header: t('Status'),
      accessorKey: 'status',
    },
  ];
}
