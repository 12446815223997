import { useMemo } from 'react';
import { compose, filter, map } from 'lodash/fp';
import { SHARED_SENSORS } from 'components/EditOrCreateSensor/utils';

const isForbiddenType = targetOption => SHARED_SENSORS.indexOf(targetOption.sensorType);
const isReference = targetOption => targetOption.label === 'Reference';
const isValidOption = targetOption => !isReference(targetOption) && !isForbiddenType(targetOption);
const toTargetOption = series => {
  const sensor = series.sensors?.[0] ?? {};
  const { id, sensorType } = sensor;
  return {
    label: series.name,
    sensorType,
    value: { sensorId: id, category: series.category },
  };
};
const getSensorTargets = compose(filter(isValidOption), map(toTargetOption));

const useSensorTargetOptionsForSeries = series => {
  const targetOptions = useMemo(() => getSensorTargets(series), [series]);

  return targetOptions;
};

export default useSensorTargetOptionsForSeries;
