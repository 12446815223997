import { connect } from 'react-redux';
import { OrderType, PLANNED_MAINTENANCE_SERVICE_ORDERS } from 'constants/maintenance';
import {
  loadServiceOrder,
  loadServiceOrderForPlannedMaintenance,
  loadPlannedMaintenance,
  loadFunctionalLocations,
  loadServiceOrderOperations,
  loadPartnerMeta,
  loadEquipments,
  loadServiceOrderLogs,
  loadPlannedMaintenanceLogs,
} from 'redux/modules';

// check if planned maintenance has a linked service order
export const getHasLinkedOrder = (state, props) => {
  const order = getOrder(state, props);
  if (props.orderType !== OrderType.PLANNED || !order?.serviceOrderNumber) {
    return false;
  }
  // For SAP it should only show linked service order if maintenance activity type is one of allowed ones
  if (order?.externalType === 'sap' && !PLANNED_MAINTENANCE_SERVICE_ORDERS.includes(order?.maintenanceActivityType)) {
    return false;
  }
  return true;
};

const getLinkedOrderEquipments = (equipments, orderEquipmentNumber) => {
  return equipments?.filter?.(({ equipmentNumber }) => orderEquipmentNumber.includes(equipmentNumber)) || [];
};

// related service order for planned maintenance
export const getLinkedOrder = (state, props) => {
  const linkedOrder = state.serviceOrders?.index?.byId?.[getOrder(state, props)?.serviceOrderNumber];
  const noInitialEquipment = linkedOrder?.equipmentNumber && !linkedOrder?.equipment?.length > 0;
  if (noInitialEquipment) {
    const equipments = state.equipments.equipments[linkedOrder.functionalLocation] || [];
    linkedOrder.equipment = getLinkedOrderEquipments(equipments, linkedOrder.equipmentNumber);
  }
  return linkedOrder;
};

export const getIsSmartViewOrder = (state, props) => getOrder(state, props)?.externalType === 'smartview';

export const getFunctionalLocation = (state, props) =>
  state.functionalLocations.functionalLocations[props.functionalLocationId];

const getDataSource = (state, props) =>
  state[props.orderType === OrderType.ORDER ? 'serviceOrders' : 'plannedMaintenance'];

export const getOrder = (state, props) => getDataSource(state, props).index.byId[props.orderId];

export const getLoadingKey = (_, props) => ['order', props.orderType, props.orderId].join(':');

const mapStateToProps = (state, props) => ({
  order: getOrder(state, props),
  linkedOrder: getLinkedOrder(state, props),
  hasLinkedOrder: getHasLinkedOrder(state, props),
  isSmartViewOrder: getIsSmartViewOrder(state, props),
  functionalLocation: getFunctionalLocation(state, props),
  loadingKey: getLoadingKey(state, props),
});

const mapDispatchToProps = {
  loadServiceOrder,
  loadServiceOrderForPlannedMaintenance,
  loadPlannedMaintenance,
  loadFunctionalLocations,
  loadServiceOrderOperations,
  loadPartnerMeta,
  loadEquipments: fl => loadEquipments(fl, true),
  loadServiceOrderLogs,
  loadPlannedMaintenanceLogs,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
