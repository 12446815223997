import find from 'lodash/find';
import get from 'lodash/get';

export const showContactCaverion = (partnerMeta, profile, divisionConfig) => {
  const divisionEmail = get(divisionConfig, ['contact_email', profile.division]);
  const partnerEmail = partnerMeta?.find(meta => meta.key === 'contact_email')?.value;
  return !!(divisionEmail || partnerEmail);
};

export const contactPersonInfo = partnerMeta => ({
  header: 'Caverion contact person',
  name: (find(partnerMeta, { key: 'contact_name' }) || {}).value,
  tel: (find(partnerMeta, { key: 'contact_tel' }) || {}).value,
  email: (find(partnerMeta, { key: 'contact_email' }) || {}).value,
});

export const contactSalesInfo = (profile, divisionConfig, t) => ({
  header: 'Division sales',
  name: `Caverion ${divisionName(profile.division, t)}`,
  tel: get(divisionConfig, ['contact_phone', profile.division]),
  email: get(divisionConfig, ['contact_email', profile.division]),
});

export const divisionName = (division, t) => {
  const key = [`division_${division}`];
  const translated = t(key);
  return translated !== key ? translated : division;
};
