import React from 'react';

export const useReplyForm = serviceOrder => {
  const [replyFormModel, setReplyFormModel] = React.useState({});

  React.useEffect(() => {
    setReplyFormModel(oldModel => ({ ...oldModel, status: serviceOrder?.status }));
  }, [serviceOrder]);

  const handleReplyFormChange = React.useCallback(
    (property, value) => {
      setReplyFormModel(oldModel => ({ ...oldModel, [property]: value }));
    },
    [setReplyFormModel]
  );

  return {
    replyFormModel,
    handleReplyFormChange,
  };
};
