import subYears from 'date-fns/subYears';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import addQuarters from 'date-fns/addQuarters';
import startOfQuarter from 'date-fns/startOfQuarter';
import endOfQuarter from 'date-fns/endOfQuarter';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { format } from 'utils/Date/dateFormatter';

export const formatTimeframe = (category, timeframe) => {
  const startTime = timeframe.startTime;
  switch (category) {
    case 'month':
      return format(startTime, 'LLLL yyyy');
    case 'quarter':
      return format(startTime, 'QQQ yyyy');
    case 'year':
      return format(startTime, 'yyyy');
    default:
      throw new Error('Unsupported timeframe category');
  }
};

export const createTimeframe = (localTimeframe, timeframeCategory) => {
  const { startTime, endTime } = localTimeframe;

  switch (timeframeCategory) {
    case 'year':
    case 'lastYear':
      return {
        startTime: startOfYear(startTime),
        endTime: endOfYear(endTime),
      };
    case 'month':
    case 'quarter':
      return {
        startTime: startOfMonth(startTime),
        endTime: endOfMonth(endTime),
      };
    default:
      throw new Error('Unsupported timeframe category');
  }
};

export const createTimeframeByCategory = timeframeCategory => {
  if (timeframeCategory === 'lastYear') {
    const lastYear = subYears(new Date(), 1);
    return createTimeframe({ startTime: lastYear, endTime: lastYear }, timeframeCategory);
  }

  const today = new Date();

  if (timeframeCategory === 'quarter') {
    return createTimeframe({ startTime: startOfQuarter(today), endTime: endOfQuarter(today) }, timeframeCategory);
  }

  return createTimeframe({ startTime: today, endTime: today }, timeframeCategory);
};

export const createAdjacentTimeframe = (timeframe, timeframeCategory, diff) => {
  const { startTime, endTime } = timeframe;

  switch (timeframeCategory) {
    case 'year':
      return {
        startTime: addYears(startTime, diff),
        endTime: addYears(endTime, diff),
      };
    case 'month':
      return {
        startTime: addMonths(startTime, diff),
        endTime: addMonths(endTime, diff),
      };
    case 'quarter':
      return {
        startTime: addQuarters(startTime, diff),
        endTime: addQuarters(endTime, diff),
      };
    default:
      return timeframe;
  }
};
