import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'decorators/Translations/translations';
import size from 'lodash/size';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import SkeletonText from 'components/Skeletons/SkeletonText';

const InvestmentCalendarTable = styled.table`
  width: 100%;
`;

const DataCell = styled.td`
  font-size: ${props => props.theme.font.size.xxs};
  border-width: 1px;
  border-color: ${props => props.theme.colors.lightGray};
  border-style: solid;
  padding: var(--size-md);
  background-color: ${props => (props.highlight ? rgba(props.theme.colors.cerulean, 0.05) : props.theme.colors.white)};

  text-align: center;
  ${props =>
    props.isLocationColumn &&
    css`
      border-left: none;
      text-align: left;
    `}
`;

const FunctionalLocationLink = styled.span`
  cursor: pointer;
`;

const HeadingCell = styled.th`
  border-width: 1px;
  border-color: ${props => props.theme.colors.lightGray};
  border-style: solid;
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  background-color: ${props =>
    props.highlight ? rgba(props.theme.colors.cerulean, 0.1) : props.theme.colors.alabaster};

  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacing.default};
  padding: var(--size-sm) var(--size-md);
  text-align: center;
  ${props =>
    props.isLocationColumn &&
    css`
      border-left: none;
      text-align: left;
    `}
`;

function formatInvestmentSum(sum) {
  const sumAsNumber = Number(sum);
  return Number.isFinite(sumAsNumber) ? (Math.round(sumAsNumber / 100) / 1000).toFixed(1) : '';
}

const InvestmentCalendar = props => {
  const { investments = {}, loading, selectFunctionalLocation, functionalLocations } = props;
  const [t] = useTranslations();

  const yearColumns = Object.keys(investments.byYear).sort();
  const showFunctionalLocationColumn = Boolean(functionalLocations);

  if (loading) {
    return (
      <InvestmentCalendarTable>
        <tr>
          <HeadingCell>
            <SkeletonText />
          </HeadingCell>
          <HeadingCell>
            <SkeletonText />
          </HeadingCell>
        </tr>
        <tr>
          <DataCell>
            <SkeletonText />
          </DataCell>
          <DataCell>
            <SkeletonText />
          </DataCell>
        </tr>
      </InvestmentCalendarTable>
    );
  }

  const currentYear = String(new Date().getFullYear());
  return (
    <>
      <InvestmentCalendarTable>
        <thead>
          <tr>
            {showFunctionalLocationColumn && <HeadingCell isLocationColumn>{t('Location')}</HeadingCell>}
            {yearColumns.map(year => (
              <HeadingCell key={year} highlight={currentYear === year}>
                {year === 'noExecutionDate' ? t('Year not defined') : t(year)}
              </HeadingCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(investments.byFl).map(functionalLocation => (
            <tr key={functionalLocation}>
              {showFunctionalLocationColumn && (
                <DataCell isLocationColumn>
                  <FunctionalLocationLink
                    role="button"
                    onClick={() => {
                      if (typeof selectFunctionalLocation === 'function') {
                        selectFunctionalLocation(functionalLocation);
                      }
                    }}
                  >
                    {functionalLocations?.[functionalLocation]?.name || functionalLocation}
                  </FunctionalLocationLink>
                </DataCell>
              )}
              {yearColumns.map(year => (
                <DataCell key={year} highlight={currentYear === year}>
                  {formatInvestmentSum(investments.byFl[functionalLocation][year])}
                </DataCell>
              ))}
            </tr>
          ))}
        </tbody>
        {size(investments.byFl) > 1 && (
          <tfoot>
            <tr>
              <HeadingCell>{t('Total')}</HeadingCell>
              {yearColumns.map(year => (
                <HeadingCell key={year} highlight={currentYear === year}>
                  {formatInvestmentSum(investments.byYear[year])}
                </HeadingCell>
              ))}
            </tr>
          </tfoot>
        )}
      </InvestmentCalendarTable>
    </>
  );
};

InvestmentCalendar.propTypes = {
  investments: PropTypes.shape({
    byFl: PropTypes.object,
    byYear: PropTypes.object,
  }),
  loading: PropTypes.bool,
  selectFunctionalLocation: PropTypes.func,
  functionalLocations: PropTypes.object,
};

export default InvestmentCalendar;
