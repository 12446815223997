import React from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from 'decorators/Translations/translations';
import CellPopper from 'components/CellPopper/CellPopper';
import SimpleDropdownItem from 'components/SimpleDropdown/SimpleDropdownItem';

const ActionColumn = ({ actions, data }) => {
  const [t] = useTranslations();
  if (!actions || Object.keys(actions).length === 0) {
    return null;
  }

  return (
    <CellPopper>
      {Object.entries(data.actions).map(([key, action]) => (
        <SimpleDropdownItem useWarningColor={key === 'Delete'} key={key} onClick={() => action(data)}>
          {t(key)}
        </SimpleDropdownItem>
      ))}
    </CellPopper>
  );
};

ActionColumn.propTypes = {
  actions: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default ActionColumn;
