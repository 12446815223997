import React from 'react';
import throttle from 'lodash/throttle';
import { breakpoints } from 'styles/definitions';

export function activeBreakpoints() {
  return Object.entries(breakpoints).reduce((accu, [breakpoint, size]) => {
    accu[breakpoint] = size <= window.innerWidth;
    return accu;
  }, {} as { [key: string]: boolean });
}

export function useBreakpoints() {
  const [breakpoints, setBreakpoints] = React.useState(activeBreakpoints());

  React.useEffect(() => {
    const updateBreakpoints = throttle(() => {
      setBreakpoints(activeBreakpoints());
    }, 600);

    window.addEventListener('resize', updateBreakpoints);

    return () => window.removeEventListener('resize', updateBreakpoints);
  }, []);

  return breakpoints;
}
