import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';

const invalidateAllMaintenancePlanCaches = () => invalidateCachesWithUrlMatch(`${MASTER_DATA_PATH}/maintenancePlans`);

export const maintenancePlansFn = (request, code, partnerNumber, functionalLocation) =>
  request.get({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans`,
    query: { code, partnerNumber, functionalLocation },
  });

export const upsertMaintenancePlanFn = async (request, maintenancePlan) => {
  const result = await request.post({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans/upsert`,
    data: maintenancePlan,
  });
  await invalidateAllMaintenancePlanCaches();
  return result;
};

export const deleteMaintenancePlanFn = (request, code) =>
  request.delete({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans/${code}`,
  });

export const syncPlannedMaintenancesFn = (request, code, startDate, endDate) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans/syncPlannedMaintenances`,
    data: { code, startDate, endDate },
  });

export const copyMaintenancePlanFn = (request, code, functionalLocations, createPlannedMaintenances = true) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans/${code}/copy`,
    data: { functionalLocations, createPlannedMaintenances },
  });

export default wrapEachWithThunkRequest({
  maintenancePlans: maintenancePlansFn,
  upsertMaintenancePlan: upsertMaintenancePlanFn,
  deleteMaintenancePlan: deleteMaintenancePlanFn,
  syncPlannedMaintenances: syncPlannedMaintenancesFn,
  copyMaintenancePlan: copyMaintenancePlanFn,
});
