import styled, { css } from 'styled-components';

const TableCell = styled.td`
  padding: ${props => (props.noPadding ? 0 : 'var(--size-md) 0 var(--size-md) var(--size-sm)')};
  display: ${props => props.hideOnMobile && 'none'};
  vertical-align: ${props => (props.alignMiddle ? 'middle' : 'top')};

  ${props => props.theme.media.landscape`
    display: ${props => (props.hideOnDesktop ? 'none' : 'table-cell')};
    vertical-align: ${props => !props.alignTop && 'middle'};
  `}

  ${props =>
    props.grow &&
    css`
      width: 100%;
    `}

  @media print {
    display: ${props => (props.hideOnPrint ? 'none' : 'table-cell')};
  }
`;

export default TableCell;
