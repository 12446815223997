import React from 'react';
import PropTypes from 'prop-types';

import { StyledLabel, StyledInputText } from './AdditionalSaleForm';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';

const FormInput = ({ property, label, register, required, error, extraText }) => {
  const { t } = useUnauthenticatedTranslations();
  return (
    <>
      <StyledLabel text={t(label)} required={required} errorText={error} extraText={extraText} />
      <StyledInputText simple uncontrolled property={property} inputProps={register(property, { required })} />
    </>
  );
};

FormInput.propTypes = {
  property: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  error: PropTypes.string,
  extraText: PropTypes.string,
};

export default FormInput;
