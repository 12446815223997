import { tooltipFormatter, getCommonExportingOptions, getCommonNavigationOptions } from '../utils';
import { getGraphTypeBooleanValueText, isBooleanType } from 'components/Sensor/SensorBody/utils';
import { isBetweenOpeningHours } from 'containers/Application/SensorValues/SensorValuesUtils';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import merge from 'lodash/merge';
import parseISO from 'date-fns/parseISO';
import getISOWeek from 'date-fns/getISOWeek';
import getYear from 'date-fns/getYear';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';
import { BASE_CONFIG, GRAPH_TYPES_WITH_STEP } from './constants';
import endOfToday from 'date-fns/endOfToday';
import { startOfUTCYear } from 'utils/Date/date';
import set from 'lodash/set';
import { getAxisTextStyles } from '../utils';

const END_OF_TODAY = endOfToday();
const startOfCurrentUTCYear = startOfUTCYear(new Date());

/**
 * Map data for sorting purposes or return as is
 * @param {Array} data Sensor data to be visualized
 * @param {String} aggregationType Sensor aggregation type
 * @param {String} aggregationFreq Sensor aggregation frequency
 * @param {Boolean} hasSort If data is sorted
 * @param {Func} t Translation function
 */
export const mapData = (data, aggregationType, aggregationFreq, hasSort, t) => {
  if (!hasSort) {
    return data.map(point => [parseISO(point.timestamp).getTime(), point.value]);
  }
  if (aggregationFreq === 'yearly' || aggregationFreq === 'weekly') {
    const startOf = aggregationFreq === 'yearly' ? startOfYear : startOfWeek;
    return Object.values(
      data
        .filter(point => point.value)
        .map(point => ({
          x: startOf(parseISO(point.timestamp), 1).getTime(),
          y: point.value,
          name: [
            `${getYear(parseISO(point.timestamp))}`,
            ...(aggregationFreq === 'weekly' ? [t('Week'), getISOWeek(parseISO(point.timestamp))] : []),
          ].join(' '),
        }))
        .reduce((result, point) => {
          result[point.x] = result[point.x] || [];
          result[point.x].push(point);
          return result;
        }, {})
    )
      .map(week => ({
        ...week[0],
        y: week.reduce((result, entry) => result + entry.y, 0) / (aggregationType === 'average' ? week.length : 1),
      }))
      .map(point => ({ ...point, sort: point.y }));
  }
  let dateFormat = '';
  switch (aggregationFreq) {
    case 'monthly':
      dateFormat = DATE_FORMATS.yearMonthShort;
      break;
    case 'daily':
      dateFormat = DATE_FORMATS.dateShortLocal;
      break;
    case 'hourly':
      dateFormat = DATE_FORMATS.dateTimeShortLocal;
      break;
    default:
      dateFormat = DATE_FORMATS.dateShortLocal;
      break;
  }
  return data
    .filter(point => point.value)
    .map(point => ({
      x: parseISO(point.timestamp).getTime(),
      y: point.value,
      name: `${format(parseISO(point.timestamp), dateFormat)}`,
      sort: point.value ?? 0,
    }));
};

/**
 * Sort data by sort configuration
 * @param {Array} data Sensor data to be visualized
 * @param {Array} sort Sensor ids and sort directions
 */
export const sortData = (data, sort = []) => {
  const sortIndex = {};
  if (!sort || sort.length === 0) {
    return data;
  }
  return [
    ...data
      .sort(
        (a, b) =>
          (sort?.filter(item => item.sensorId === b.id).length ?? 0) -
          (sort?.filter(item => item.sensorId === a.id).length ?? 0)
      )
      .map(series => {
        const sortBy = sort?.find(item => item.sensorId === series.id);
        if (sortBy) {
          series.data = series.data
            .sort(sortBy.order === 'desc' ? (a, b) => b.sort - a.sort : (a, b) => a.sort - b.sort)
            .map((point, index) => {
              sortIndex[point.x] = index;
              const x = index ?? 0;
              return { ...point, x };
            });
        } else {
          series.data = series.data
            .concat([])
            .map(point => {
              const x = sortIndex[point.x] ?? 0;
              return { ...point, x };
            })
            .sort((a, b) => a.x - b.x);
        }
        return series;
      }),
  ];
};

const getUnit = unit => (unit == null ? '' : unit);

const getAggregationType = type => (type === 'sum' ? 'sum' : 'average');

/**
 * Get series from sensor data, type and options
 * @param {Array} data Sensor data to be visualized
 * @param {Object} sensorType Sensor type for the data
 * @param {Object} options Options
 */
export const getSeries = (
  data = [],
  sensor = {},
  yAxis,
  options = {},
  t,
  aggregationFreq,
  hasSort,
  color,
  flipAxes,
  onClick,
  dataAggregation,
  useBoostModule,
  noNavigatorSeries
) => {
  const { name: seriesName, sensorType, sensorMeta } = sensor;
  const { graphType: seriesType } = options;
  const { name: sensorTypeName, unit: sensorUnit, graphType, seriesGraphType } = sensorType;
  const unit = getUnit(sensorUnit);
  const type = getSeriesType(sensorTypeName, seriesGraphType ?? seriesType ?? graphType, graphType, useBoostModule);
  const trueValueByType = {
    presence: t('Occupied'),
    event: t('Active'),
    boolean: getGraphTypeBooleanValueText(true, sensorType.unit, t, sensorMeta),
  };
  const falseValueByType = {
    presence: t('Available'),
    event: t('Inactive'),
    boolean: getGraphTypeBooleanValueText(false, sensorType.unit, t, sensorMeta),
  };
  const aggregationType = getAggregationType(sensorType?.aggregations?.[0]?.type);
  const dataGrouping = getDataGrouping(aggregationType, aggregationFreq);
  return {
    id: sensor.id,
    name: seriesName,
    color,
    type,
    data,
    singleLine: true,
    unit,
    yAxis: flipAxes ? yAxis : yAxis.findIndex(axis => axis.sensorTypeName === sensorTypeName || axis.unit === unit),
    step: isBooleanType(sensorTypeName, graphType) || GRAPH_TYPES_WITH_STEP.includes(graphType) ? 'left' : undefined,
    trueValue: trueValueByType[graphType],
    falseValue: falseValueByType[graphType],
    dataGrouping: hasSort ? undefined : dataGrouping,
    grouping: options.barGrouping === false ? false : undefined,
    hasSort,
    aggregationType,
    aggregationFreq,
    sensorId: sensor.id,
    events: {
      click: onClick,
    },
    navigatorOptions: {
      type,
    },
    gapSize: dataAggregation !== 'raw' && !hasSort ? 1.5 : 0,
    showInNavigator: (!dataGrouping?.enabled || aggregationFreq === 'raw') && !noNavigatorSeries,
    ...(hasSort && { turboThreshold: 0 }),
  };
};

/**
 * Get chart config
 */
export const getChartConfig = ({
  chartWidth,
  chartHeight,
  theme,
  hasBarType = false,
  aggregationFreq,
  verticalZoom,
  t,
  hasSort,
  hasNavigator,
  openHourBands = [],
  plotOptions = {},
  title,
  flipAxes,
  xMin,
  xMax,
  xMaxRange,
  useBoostModule,
  xRange,
}) => {
  const xAxisMinMax = {
    max: !hasNavigator ? undefined : xMax ? xMax : END_OF_TODAY.getTime(),
    min: !hasNavigator ? undefined : xMin,
  };

  const isYTD = endOfToday().getTime() - startOfCurrentUTCYear.getTime() === xRange;
  return merge({}, BASE_CONFIG, {
    chart: {
      plotBackgroundColor: theme.colors.white,
      width: chartWidth,
      height: chartHeight,
      zoomType: verticalZoom ? 'xy' : 'x',
      style: {
        fontFamily: theme.font.family.cairo,
      },
    },
    ...(useBoostModule
      ? {
          boost: {
            useGPUTranslations: true,
          },
        }
      : {}),
    tooltip: {
      formatter: function () {
        return tooltipFormatter(
          this.points ?? [this.point],
          this.x,
          theme,
          flipAxes ? 10 : chartHeight - (!hasNavigator ? 125 : 160),
          'no temperature'
        );
      },
    },
    plotOptions,
    xAxis: merge({}, getAxisTextStyles(theme), {
      minPadding: hasBarType ? 0.02 : undefined,
      maxPadding: hasBarType ? 0.02 : undefined,
      type: hasSort ? 'category' : 'datetime',
      lineColor: theme.colors.mystic,
      gridLineColor: theme.colors.mystic,
      labels: {
        y: !flipAxes ? 25 : undefined,
        ...(aggregationFreq === 'weekly' && !hasSort
          ? {
              formatter: function (timestamp) {
                const date = new Date(timestamp.value);
                return `${t('Week')} ${getISOWeek(date)}`;
              },
            }
          : {}),
      },
      plotBands: getOpenHourPlotBands(openHourBands, theme),
      minRange: !hasNavigator ? undefined : 3600 * 1000, // 1 hour,
      maxRange: !hasSort ? xMaxRange : undefined,
      range: !isYTD && !hasSort ? xRange : undefined,
      ...xAxisMinMax,
    }),
    exporting: getCommonExportingOptions({ title }),
    navigation: getCommonNavigationOptions(),
    rangeSelector: {
      buttons: [
        {
          type: 'hour',
          count: 24,
          text: '24h',
          title: t('{0} hours', 24),
        },
        {
          type: 'day',
          count: 7,
          text: '7d',
          title: t('{0} days', 7),
        },
        ...(!xMaxRange
          ? [
              {
                type: 'day',
                count: 31,
                text: '31d',
                title: t('{0} days', 31),
              },
              {
                type: 'month',
                count: 6,
                text: '6m',
                title: t('{0} months', 6),
              },
              {
                type: 'ytd',
                text: 'YTD',
                title: t('Year to date'),
              },
              {
                type: 'year',
                count: 1,
                text: '1y',
                title: t('1 year'),
              },
              {
                type: 'all',
                text: 'All',
                title: t('View all'),
              },
            ]
          : []),
      ],
      buttonTheme: {
        // styles for the buttons
        fill: theme.colors.concrete,
        r: 2,
        states: {
          select: {
            fill: theme.brandColors.turquoise01,
            style: {
              color: theme.colors.white,
            },
          },
        },
      },
      buttonPosition: {
        y: -2,
      },
      inputBoxHeight: 12,
      inputStyle: {
        color: theme.colors.black,
      },
      // Handle YTD correctly
      selected: !xMaxRange && isYTD ? 4 : undefined,
    },
    navigator: {
      xAxis: {
        max: !hasNavigator ? undefined : xMax ? xMax : END_OF_TODAY.getTime(),
        min: !hasNavigator ? undefined : xMin,
        maxRange: undefined,
      },
    },
  });
};

const getSeriesType = (name, graphType, sensorGraphType, useBoostModule) => {
  if (graphType === 'bar') {
    return 'column';
  }
  if (graphType === 'area') {
    return 'area';
  }
  if (isBooleanType(name, sensorGraphType) || GRAPH_TYPES_WITH_STEP.includes(sensorGraphType)) {
    return 'line';
  }
  return useBoostModule ? 'line' : 'spline';
};

/**
 * Get all values as a single array from the sensor data
 * @param {Array} sensorData
 */
export const getValues = sensorData => sensorData?.flatMap(series => series?.data);

/**
 * Test if sensorData has values
 * @param {Array} sensorData
 */
export const hasData = sensorData => !!getValues(sensorData)?.length;

/**
 * Get unique sensor types from sensor data
 * @param {Array} sensorData
 */
export const getUniqueSensorTypes = sensorData =>
  uniqBy(
    sensorData.map(data => data.sensor?.sensorType),
    'name'
  );

/**
 * Get Y axis config
 * @param {Array} unitsAndGraphTypes
 * @param {Object} theme
 */
export const getYAxisConfig = (axisTypes = [], theme, verticalZoom) => {
  const yAxis = axisTypes.map(({ sensorType }, unitIndex) => {
    const { isEvent } = getBooleanAxisStates(sensorType);
    const isBooleanAxis = !isAxisLabelsEnabled(sensorType);
    const unit = getUnit(sensorType?.unit);
    return merge({}, getAxisTextStyles(theme), {
      title: {
        text: unit,
      },
      opposite: unitIndex % 2 === 1,
      labels: {
        format: `{value}`,
        enabled: isAxisLabelsEnabled(sensorType),
      },
      unit,
      min: isBooleanAxis ? 0 : undefined,
      max: isBooleanAxis ? 1 : undefined,
      tickInterval: isEvent ? 1 : undefined,
      startOnTick: verticalZoom ? false : !isEvent,
      endOnTick: !verticalZoom,
      showEmpty: true,
      sensorTypeName: sensorType?.name,
    });
  });
  return yAxis;
};

/**
 *
 * @param {String} aggregationType
 * @param {String} aggregationFreq
 * @returns
 */
export const getDataGrouping = (aggregationType, aggregationFreq) => {
  if (aggregationFreq) {
    const dataGrouping = {
      enabled: true,
      forced: true,
      approximation: aggregationType,
    };
    if (aggregationFreq === 'weekly') {
      return { ...dataGrouping, units: [['week', [1]]] };
    } else if (aggregationFreq === 'yearly') {
      return { ...dataGrouping, units: [['year', [1]]] };
    }
  }
  return { enabled: false };
};

const getOpenHourPlotBands = (openHourBands, theme) =>
  openHourBands.map(([from, to]) => ({ from, to, color: theme.colors.lightGray }));

export const splitToOpenHourSeries = (t, series, options) => {
  const { sensor, data, openHours } = series;
  const { buildingTimezone } = options;

  if (!openHours || !buildingTimezone) {
    return [series];
  }

  const { inside, outside } = splitDataWithOpeningHours(data, openHours, buildingTimezone);

  return [
    {
      sensor: { ...sensor, name: `${t('During open hours')}${sensor.name ? `: ${sensor.name}` : ``}` },
      data: inside,
    },
    {
      sensor: { ...sensor, name: `${t('Outside open hours')}${sensor.name ? `: ${sensor.name}` : ``}` },
      data: outside,
    },
  ];
};

export const splitDataWithOpeningHours = (data, openHours, buildingTimezone) => {
  return data.reduce(
    (accu, valueObject) => {
      if (isBetweenOpeningHours(valueObject, openHours, buildingTimezone, true)) {
        accu.inside.push(valueObject);
      } else {
        accu.outside.push(valueObject);
      }
      return accu;
    },
    { inside: [], outside: [] }
  );
};

const getBooleanAxisStates = type => {
  return {
    isBoolean: isBooleanType(type?.name, type?.graphType),
    isPresence: type?.graphType === 'presence',
    isEvent: type?.graphType === 'event',
  };
};

const isAxisLabelsEnabled = type => {
  const { isBoolean, isPresence, isEvent } = getBooleanAxisStates(type);
  return !isBoolean && !isPresence && !isEvent;
};

export const getOptions = ({
  sensorData,
  aggregationFreq,
  chartWidth,
  chartHeight,
  onClick,
  t,
  theme,
  options,
  sort,
  verticalZoom,
  noNavigator,
  noNavigatorSeries,
  useBoostModule,
  onLoad,
}) => {
  const sensorTypes = getUniqueSensorTypes(sensorData);
  // There can be many sensor types with same unit and these can use same Y-axis
  // Sensor types without unit get their own y-axis
  const uniqueSensorTypesByUnit = uniqWith(sensorTypes, (a, b) => a.unit && b.unit && a.unit === b.unit);
  // Graph type array with same index as the unit, allow overriding graphType by using options
  const axisTypes = [
    // Let's add axes without labels to last so that visible axes are shown equally on both sides
    ...uniqueSensorTypesByUnit.filter(type => isAxisLabelsEnabled(type)),
    ...uniqueSensorTypesByUnit.filter(type => !isAxisLabelsEnabled(type)),
  ].map(sensorType => {
    return {
      sensorType,
    };
  });

  const hasBarType = sensorTypes.some(type => type.seriesGraphType === 'bar');
  const hasSort = sort?.length > 0;
  const hasNavigator = !hasSort && !noNavigator;

  // Get base config for chart
  const config = getChartConfig({
    chartWidth,
    chartHeight,
    theme,
    hasBarType,
    aggregationFreq,
    verticalZoom,
    t,
    hasSort,
    hasNavigator,
    openHourBands: options.openHourBands,
    plotOptions: options.plotOptions || {},
    title: options.title,
    flipAxes: options.flipAxes,
    xMin: options.xMin,
    xMax: options.xMax,
    xMaxRange: options.xMaxRange,
    xRange: options.xRange,
  });

  // Add events
  if (typeof onClick === 'function') {
    config.chart.events = {
      ...config.chart.events,
      click: onClick,
    };
  }

  config.chart.events = {
    ...config.chart.events,
    load: function () {
      if (options.xCustomRange && !hasSort) {
        this.xAxis[0].setExtremes(options.xCustomRange.min, options.xCustomRange.max);
      }
      if (typeof onLoad === 'function') {
        onLoad(this);
      }
    },
  };

  // Add y-axis
  config.yAxis = getYAxisConfig(axisTypes, theme, verticalZoom);

  // Add series
  config.series = sortData(
    sensorData
      .flatMap(series => splitToOpenHourSeries(t, series, options))
      .map((series, index) =>
        getSeries(
          mapData(series.data, series.aggregationType, aggregationFreq, hasSort, t),
          series.sensor,
          config.yAxis,
          options,
          t,
          aggregationFreq,
          hasSort,
          options.chartColors?.[index] ? options.chartColors[index] : theme.chartColors[index],
          undefined,
          onClick,
          series.data?.[0]?.aggregation,
          useBoostModule,
          noNavigatorSeries
        )
      ),
    sort
  );

  // Set options for navigator
  if (hasNavigator) {
    set(config, 'navigator.adaptToUpdatedData', true);
    set(config, 'navigator.enabled', true);
    set(config, 'scrollbar.enabled', true);
    set(config, 'scrollbar.liveRedraw', false);
    set(config, 'plotOptions.series.showInNavigator', !noNavigatorSeries);
  } else {
    set(config, 'navigator.enabled', false);
    set(config, 'plotOptions.series.showInNavigator', false);
    set(config, 'rangeSelector.enabled', false);
  }

  // Set options for flipped axes
  if (options.flipAxes) {
    set(config, 'chart.inverted', true);
    set(config, 'navigator.enabled', false);
  }

  return config;
};
