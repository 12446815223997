import React from 'react';
import styled from 'styled-components';

import AdditionalSaleChatAttachment from './AdditionalSaleChatAttachment';
import Svg from 'components/Svg/Svg';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import useCommentAttachment from './useCommentAttachment';

import { additionalSalePropType, isActionWrongPerson } from './utils';
import { icons } from 'components/Icon/IconNames';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import sortBy from 'lodash/sortBy';
import unescape from 'lodash/unescape';

const ChatRow = styled.div`
  display: flex;
  flex-direction: ${props => (props.position === 'left' ? 'row' : 'row-reverse')};
  flex-wrap: nowrap;
  align-content: center;
  justify-content: 'flex-start';
  align-items: center;
  gap: var(--size-sm);
  margin: 0 0 var(--size-sm) 0;
  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xs};
`;

const ChatIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
  border: 1px solid ${props => (props.position === 'left' ? props.theme.colors.lightGray : props.theme.colors.midnight)};
  background-color: ${props => (props.position === 'left' ? props.theme.colors.white : props.theme.colors.midnight)};
`;

const ChatIcon = styled(props => <Svg {...props} />)`
  fill: ${props => (props.position === 'left' ? props.theme.colors.black : props.theme.colors.white)};
`;

const ChatContainer = styled.div`
  padding: var(--size-sm);
  border-radius: ${props => (props.position === 'left' ? 0 : 10)}px ${props => (props.position === 'right' ? 0 : 10)}px
    10px 10px;
  background-color: ${props => props.theme.colors.alabaster};
`;

const ChatHeader = styled.div`
  font-weight: ${props => props.theme.font.weight.bold};
  margin: 0 0 var(--size-sm) 0;
`;

const ChatInfo = styled.div`
  margin: var(--size-sm) 0 0 0;
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  letter-spacing: 0.3px;
  line-height: ${props => props.theme.font.size.sm};
`;

const ChatAttachmentsHeader = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  margin: var(--size-sm) 0;
`;

const EMPTY_OBJECT = {};

const isCustomer = creator => creator === 'customer';

const formatDate = dateStr => format(parseISO(dateStr), 'dd.MM.yyyy, HH:mm');
const position = creator => (isCustomer(creator) ? 'left' : 'right');
const iconName = creator => (isCustomer(creator) ? icons.OBSERVATION_EXPERT : icons.CAVERION_C);

const AdditionalSaleChat = ({ additionalSale = EMPTY_OBJECT }) => {
  const { t } = useUnauthenticatedTranslations();
  const { downloadFile, renderedNotification } = useCommentAttachment();

  const sortedComments = React.useMemo(() => {
    // Comments are escaped in the service API, so we need to unescape them here to use them in JSX
    additionalSale.comments = additionalSale.comments.map(item => ({
      ...item,
      comment: unescape(item.comment),
    }));
    return sortBy(additionalSale.comments, ['created']);
  }, [additionalSale]);

  return (
    <>
      {sortedComments?.map(({ comment, created, creator, action, attachments }, i) => (
        <ChatRow key={i} position={position(creator)}>
          <ChatIconContainer position={position(creator)}>
            <ChatIcon position={position(creator)} name={iconName(creator)} />
          </ChatIconContainer>
          <ChatContainer position={position(creator)}>
            {isCustomer(creator) && (
              <>
                <ChatHeader>{t(`additional-sale/${action}`)}</ChatHeader>
                {isActionWrongPerson(action) ? t(comment) : comment}
              </>
            )}
            {!isCustomer(creator) && (isActionWrongPerson(action) ? <ChatHeader>{t(comment)}</ChatHeader> : comment)}
            <ChatInfo>{formatDate(created)}</ChatInfo>
            {attachments?.length > 0 && (
              <>
                <ChatAttachmentsHeader>{t('Attachments')}</ChatAttachmentsHeader>
                {attachments.map(attachment => (
                  <AdditionalSaleChatAttachment
                    key={attachment.fileId}
                    onDownload={downloadFile}
                    attachment={attachment}
                  />
                ))}
              </>
            )}
          </ChatContainer>
        </ChatRow>
      ))}
      {renderedNotification}
    </>
  );
};

AdditionalSaleChat.propTypes = {
  additionalSale: additionalSalePropType,
};

export default AdditionalSaleChat;
