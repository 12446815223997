import React from 'react';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import SectionHeader from 'components/Section/SectionHeader';
import Section from 'components/Section/Section';
import TabContent from 'components/TabContent/TabContent';
import OPICardsSkeleton from 'components/OPICard/OPICardsSkeleton';
import Content from 'components/Content/Content';

export const EnergySkeleton = () => (
  <TabContent>
    <Content>
      <OPICardsSkeleton numberOfOPICards={3} />
      <Section>
        <SectionHeader>
          <SkeletonText header />
        </SectionHeader>
        <Columns>
          <Column
            columnWidth={{
              landscape: 12,
              default: 12,
            }}
          >
            <SkeletonText height="200px" />
            <SkeletonChart height="200px" />
          </Column>
        </Columns>
      </Section>
    </Content>
  </TabContent>
);
