export const observationsForFL = (request, query) =>
  request.get({
    apiPath: `/api/observations`,
    query,
  });

export const observationsForPartner = (request, query) =>
  request.get({
    apiPath: `/api/observations`,
    query,
  });

export const observationsKPIForPartner = (request, query) =>
  request.get({
    apiPath: `/api/observations/kpi`,
    query,
  });

export const observationBenchmark = (request, query) =>
  request.get({
    apiPath: `/api/observations/benchmark`,
    query,
  });

export const observationPerformance = (request, query) =>
  request.get({
    apiPath: `/api/observations/performance`,
    query,
  });

export const downloadObservationAnnotationFile = (request, { id, annotationId }) =>
  request.download({
    method: 'GET',
    apiPath: `/api/observations/${id}/annotations/${annotationId}/download`,
    noCache: true,
  });
