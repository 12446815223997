import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const getInsights = ({ functionalLocation }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/insights`,
    query: { functionalLocation },
    useAuthorization: true,
  });

export const getLogsForInsight = ({ insightId, functionalLocation, startDate, endDate, pageSize, pageNumber }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/insights/${insightId}/logs`,
    query: { functionalLocation, startDate, endDate, pageSize, pageNumber },
    useAuthorization: true,
  });

export const getInsightLogs = ({ functionalLocation, startDate, endDate, pageSize, pageNumber }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/insights/logs`,
    query: { functionalLocation, startDate, endDate, pageSize, pageNumber },
    useAuthorization: true,
  });

export const getInsightMessages = ({ functionalLocation, startDate, endDate }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/insights/messages`,
    query: { functionalLocation, startDate, endDate },
    useAuthorization: true,
  });

export const getInsightMessagesWithPublicView = ({ publicViewId }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/insights/messages-with-public-view`,
    query: { publicViewId },
    useAuthorization: false,
  });
