import { useMemo } from 'react';
import { VirtualizerOptions } from '@tanstack/virtual-core';
import { RowData } from '@tanstack/table-core/build/lib/types';
import { TableOptions, Table, PartialKeys, getPaginationRowModel, PaginationState } from '@tanstack/react-table';

import { PaginationQueryArgs, usePaginationQuery } from 'components/Pagination/usePaginationQuery';

import { TableWindowVirtualizer, useVirtualizedTable } from './useVirtualizedTable';

/**
 * Paginated version of {@link useVirtualizedTable} that takes a full dataset and paginates
 * over it. This is useful when all the data is known locally.  Note that this hook uses
 * {@link usePaginationQuery}, which in turn uses query parameters for pagination.
 *
 * @param tableOptions Table options.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 * @param virtualizerOptions Patch default virtualizer options. You should at least provide `estimateSize`
 *   (the default function always returns 80). Default `overscan` is 5.
 *   See {@link https://tanstack.com/virtual/v3/docs/api/virtualizer}
 * @param paginationArgs Any arguments to pass to the {@link usePaginationQuery}, e.g. query parameter names.
 *   Note that these must correspond to the table rendering component's (e.g. {@link RichWindowVirtualizedTable })
 *   arguments.
 */
export function usePaginatedVirtualizedTable<TData extends RowData>(
  tableOptions: PartialKeys<TableOptions<TData>, 'getCoreRowModel' | 'getPaginationRowModel'>,
  virtualizerOptions: Partial<VirtualizerOptions<Window, HTMLTableRowElement>>,
  paginationArgs?: Partial<PaginationQueryArgs>
): [Table<TData>, TableWindowVirtualizer] {
  const { pageIndex, pageSize } = usePaginationQuery(paginationArgs);

  const pagination: PaginationState = useMemo(
    () =>
      pageSize
        ? // Page size is selected
          { pageIndex, pageSize }
        : // Page size is null, show all items
          { pageIndex: 0, pageSize: Number.MAX_SAFE_INTEGER },
    [pageIndex, pageSize]
  );

  return useVirtualizedTable(
    {
      getPaginationRowModel: getPaginationRowModel(),

      ...tableOptions,

      state: {
        pagination,
        ...tableOptions.state,
      },
    },
    virtualizerOptions
  );
}
