import { createReducerFromMapping } from 'redux/utils/index';

import cookies from 'utils/Cookies/Cookies';

const naviCookie = cookies.getItem('naviExtended');
const extended = !naviCookie ? true : naviCookie === 'true';

const initialState = {
  menuOpen: false,
  sideNaviExtended: extended,
  generalLoading: false,
};

export const MENU_OPEN = true;
export const MENU_CLOSED = false;
export const SET = 'CUSTOMER_PLATFORM/NavigationMenuOpen/SET';
export const setMenuStatus = status => ({ type: SET, status });

export const SET_NAVI_EXPANDED = 'CUSTOMER_PLATFORM/NavigationMenuOpen/SET_NAVI_EXPANDED';
export const setSideNavigationStatus = status => ({ type: SET_NAVI_EXPANDED, status });

export const SET_GENERAL_LOADING = 'CUSTOMER_PLATFORM/NavigationMenuOpen/SET_GENERAL_LOADING';
export const setGeneralLoading = status => ({ type: SET_GENERAL_LOADING, status });

export default createReducerFromMapping(
  {
    [SET]: (state, action) => ({ ...state, menuOpen: action.status }),
    [SET_NAVI_EXPANDED]: (state, action) => ({ ...state, sideNaviExtended: action.status }),
    [SET_GENERAL_LOADING]: (state, action) => ({ ...state, setGeneralLoading: action.status }),
  },
  initialState
);
