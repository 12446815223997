import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import * as JsSearch from 'js-search';
import styled from 'styled-components';
import withQuery from 'decorators/Query/withQuery';

import { loadPartnerOverview } from 'redux/modules/containers/partner-overview';
import { getFileCounts } from 'redux/modules/document/file';

import SectionHeader from 'components/Section/SectionHeader';
import FunctionalLocationTable from 'components/FunctionalLocationTable/FunctionalLocationTable';
import Section from 'components/Section/Section';
import { InputSearch } from 'components/index';
import { functionalLocationName } from 'utils/Data/functionalLocations';

const InputSearchContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.landscape`
        width: auto;
        min-width: 18rem;
    `}

  & > div {
    width: 100%;
  }
`;

const mapFuntionalLocationData = (t, functionalLocation, fileCountsByFL) => ({
  functionalLocation: functionalLocation.functionalLocation,
  description: functionalLocationName(t, functionalLocation),
  name: functionalLocation.name,
  key: functionalLocation.key,
  type: functionalLocation.type,
  addressInfo: `${functionalLocation.address || ''} ${functionalLocation.city || ''}`,
  tab: 'Documents',
  count: fileCountsByFL?.[functionalLocation.functionalLocation] || 0,
});

const initSearch = () => {
  const flSearch = new JsSearch.Search('functionalLocation');
  flSearch.addIndex('functionalLocation');
  flSearch.addIndex('name');
  flSearch.addIndex('description');
  flSearch.addIndex('addressInfo');
  return flSearch;
};

const BuildingTable = props => {
  const {
    match: {
      params: { partnerNumber },
    },
    t,
    loadPartnerOverview,
    getFileCounts,
    loading,
    topLevelFunctionalLocations,
    fileCountsByFL,
    setQuery,
  } = props;

  const [searchResults, setSearchResults] = useState(null);
  const flSearch = React.useRef(null);

  useEffect(() => {
    loadPartnerOverview(partnerNumber);
    flSearch.current = initSearch();
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (partnerNumber && partnerNumber !== 'all') {
      getFileCounts(partnerNumber);
    }
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const functionalLocationData = topLevelFunctionalLocations.map(fl => mapFuntionalLocationData(t, fl, fileCountsByFL));

  if (flSearch.current) {
    flSearch.current.addDocuments(functionalLocationData);
  }

  return (
    <Section>
      <SectionHeader noBorder size="small">
        <InputSearchContainer>
          <InputSearch
            id="fl-search-input"
            onChange={value => {
              const result = flSearch.current?.search(value) || [];
              setSearchResults(result);
              setQuery({ page: 1 });
            }}
            placeholder={t('Filter by Name or Address')}
            onClear={() => setSearchResults(null)}
          />
        </InputSearchContainer>
      </SectionHeader>
      <FunctionalLocationTable
        loading={loading}
        t={t}
        data={searchResults || functionalLocationData}
        useQueryPaging
        paginate
        hideAddress
        removeHead
        showCount
        initialSortBy={[{ id: 'count', desc: true }]}
      />
    </Section>
  );
};

BuildingTable.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loadPartnerOverview: PropTypes.func.isRequired,
  getFileCounts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  topLevelFunctionalLocations: PropTypes.array.isRequired,
  fileCountsByFL: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.partnerOverview.loading || state.file.loadingCounts,
  topLevelFunctionalLocations: state.functionalLocations.topLevelFunctionalLocations,
  fileCountsByFL: state.file.fileCountsByFL,
});

const mapDispatchToProps = dispatch => ({
  loadPartnerOverview: partnerNumber => dispatch(loadPartnerOverview(partnerNumber, true)),
  getFileCounts: fls => dispatch(getFileCounts(fls)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(translations(withQuery(BuildingTable)));
