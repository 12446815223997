import documents from './documents';
import files, {
  filesForFunctionalLocation,
  filesForPartner,
  fileCounts,
  uploadFile,
  uploadFileWithToken,
  findForChecklistReport,
  findForChecklistReportWithToken,
  downloadUrl,
  downloadUrlForServiceOrderWithToken,
  downloadUrlForAdditionalSaleWithToken,
  uploadCustomViewFile,
  deleteFile,
  deleteCustomViewFile,
  invalidateMaintenanceFileCaches,
} from './files';
import { createFolder, deleteFolder, downloadFolder, getFolders, updateFolder } from './folders';

export const DOCUMENT_PATH = '/document';

const exports = {
  ...documents,
  ...files,
  filesForFunctionalLocation,
  filesForPartner,
  fileCounts,
  uploadFile,
  uploadFileWithToken,
  findForChecklistReport,
  findForChecklistReportWithToken,
  downloadUrl,
  downloadUrlForServiceOrderWithToken,
  downloadUrlForAdditionalSaleWithToken,
  uploadCustomViewFile,
  deleteFile,
  deleteCustomViewFile,
  invalidateMaintenanceFileCaches,
  createFolder,
  deleteFolder,
  downloadFolder,
  getFolders,
  updateFolder,
};

export default exports;
