import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import IntersectionObserverList from '@researchgate/react-intersection-list';
import translations from 'decorators/Translations/translations';

const getRowsContainerMinHeight = ({ showAll, rowCount, rowHeight = 48 }) => {
  if (!showAll) {
    return undefined;
  }
  return `${rowCount * rowHeight}px`;
};

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${props => getRowsContainerMinHeight(props)};

  template {
    flex-grow: 1;
  }
`;
StyledTableBody.displayName = 'StyledTableBody';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  width: 100%;
  border-bottom: var(--table-row-border);
  font-family: ${props => props.theme.fontFamily.text};
  font-size: ${props => props.theme.fontSize.sm};

  cursor: ${props => props.isSelectable && 'pointer'};

  [role='rowgroup'] &:hover {
    background: var(--table-row-bg-hl);
  }
`;
Row.displayName = 'Row';

export const Cell = styled.div`
  position: relative;
  flex-grow: 1;
  padding: var(--size-xs) 0 var(--size-xs) var(--size-sm);
  display: ${props => (props.hideOnMobile ? 'none' : 'block')};
  flex-basis: ${props => (props.fullWidthOnMobile ? '100%' : 'auto')};
  vertical-align: middle;
  width: ${props => props.width};
  ${props => props.theme.media.landscape`
      display: ${props => (props.hideOnDesktop ? 'none' : 'block')};
      flex-basis: auto;
  `}

  &:last-child {
    padding-right: var(--size-xs);
  }

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  @media print {
    display: ${props => (props.hideOnPrint ? 'none' : 'block')};
  }
`;
Cell.displayName = 'Cell';

const MaxRowsReached = styled.div`
  text-align: center;
  display: block;
  width: 100%;
  padding: var(--size-md);
`;
MaxRowsReached.displayName = 'MaxRowsReached';

const MAX_ROWS_TO_RENDER = 1000;

const FlexTableBody = ({ showAll, rows, printingMode, RenderBody, RenderRow, t }) => {
  if (showAll && !printingMode) {
    return (
      <IntersectionObserverList itemCount={rows.length} itemsRenderer={RenderBody} pageSize={50}>
        {RenderRow}
      </IntersectionObserverList>
    );
  }
  const renderedRows = rows.slice(0, MAX_ROWS_TO_RENDER).map((row, index) => RenderRow(index, row.id));
  if (rows.length > MAX_ROWS_TO_RENDER) {
    renderedRows.push(
      <Row key="max-rows-reached">
        <MaxRowsReached>
          {t(
            'Restricting visible rows to {0} because of a very large data set.',
            `${MAX_ROWS_TO_RENDER} / ${rows.length}`
          )}
        </MaxRowsReached>
      </Row>
    );
  }
  return RenderBody(renderedRows);
};

FlexTableBody.propTypes = {
  showAll: PropTypes.bool,
  rows: PropTypes.array,
  RenderBody: PropTypes.func,
  RenderRow: PropTypes.func,
};

export default translations(FlexTableBody);
