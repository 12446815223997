import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_VARIANT } from './notification.constants';

const getBackgroundColor = ({ variant }) => {
  switch (variant) {
    case COLOR_VARIANT.Blue:
      return 'var(--brand-color)';
    case COLOR_VARIANT.Black:
      return 'var(--black)';
    case COLOR_VARIANT.Green:
      return 'var(--green-01)';
    case COLOR_VARIANT.Red:
      return 'var(--red-01)';
    default:
      return 'var(--white)';
  }
};

const StyledNotification = styled.div`
  position: relative;
  background-color: ${props => getBackgroundColor(props)};
  color: var(--white);
`;

const NotificationContent = ({ variant, children }) => {
  return <StyledNotification variant={variant}>{children}</StyledNotification>;
};

NotificationContent.propTypes = {
  variant: PropTypes.oneOf(Object.values(COLOR_VARIANT)),
  children: PropTypes.any,
};

export default NotificationContent;
