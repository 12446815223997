import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const apiKeys = profileId =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/api-keys`,
    query: { profileId },
    useAuthorization: true,
  });

export const createApiKey = apiKey =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/api-keys`,
    data: apiKey,
    noCache: true,
    useAuthorization: true,
  });

export const deleteApiKey = apiKeyId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/api-keys/${apiKeyId}`,
    noCache: true,
    useAuthorization: true,
  });
