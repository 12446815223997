import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { IOT_PATH } from './index';

export const energyRatingKPIFn = (request, partnerNumber) =>
  request.get({
    apiPath: `${IOT_PATH}/values/kpis/energyRating`,
    query: { partnerNumber },
  });

export const consumptionKPIFn = (request, partnerNumber, type) =>
  request.get({
    apiPath: `${IOT_PATH}/values/kpis/consumption`,
    query: { partnerNumber, type },
  });

export const airQualityKPIFn = (request, partnerNumber) =>
  request.get({
    apiPath: `${IOT_PATH}/values/kpis/airQuality`,
    query: { partnerNumber },
  });

export default wrapEachWithThunkRequest({
  energyRatingKPI: energyRatingKPIFn,
  consumptionKPI: consumptionKPIFn,
  airQualityKPI: airQualityKPIFn,
});
