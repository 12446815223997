import memoizeOne from 'memoize-one';
import { Division } from 'types/Division';
import { divisionName } from 'containers/Application/Modules/ContactModule/utils';

export const ClassificationTypes = {
  Service: 'service'
};

export const getClassificationCodeOptions = memoizeOne(t => ({
  division: Object.values(Division).map(value => ({ value, label: divisionName(value, t) })),
  type: Object.entries(ClassificationTypes).map(([key, value]) => (
    { value: value, label: t(key) }
  )),
}));
