import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { SERVICE_API_PATH, MASTER_DATA_PATH } from './index';


export const getPartnerMeta = (partnerNumber, { refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/meta/partner/${partnerNumber}`,
    refreshCache,
    useAuthorization: true,
  });

export const upsertPartnerMeta = async partnerMetaArray =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/meta/partner`,
    data: partnerMetaArray,
    noCache: true,
    useAuthorization: true,
  });

export const deletePartnerMeta = async (partnerNumber, key) => {
  await ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/meta/partner/${partnerNumber}`,
    query: { key },
    noCache: true,
    useAuthorization: true,
  });
}
