import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';

const AttachmentIcon = styled(props => <Svg {...props} name={icons.ATTACHMENT} />)`
  fill: ${props => props.theme.colors.black};
`;

const AttachmentName = styled.span`
  font-size: ${props => props.theme.font.size.xxs};
`;

const AttachmentRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--size-xs);

  &:hover {
    cursor: pointer;

    ${AttachmentIcon} {
      fill: ${props => props.theme.colors.cerulean};
    }
    ${AttachmentName} {
      color: ${props => props.theme.colors.cerulean};
    }
  }
`;

const AdditionalSaleChatAttachment = ({ onDownload, attachment }) => {
  return (
    <AttachmentRow onClick={() => onDownload(attachment.fileId)}>
      <AttachmentIcon />
      <AttachmentName>{attachment.name}</AttachmentName>
    </AttachmentRow>
  );
};

AdditionalSaleChatAttachment.propTypes = {
  onDownload: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    fileId: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default AdditionalSaleChatAttachment;
