import { createReducerFromMapping } from 'redux/utils/index';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import assign from 'lodash/assign';
import setWith from 'lodash/setWith';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';

import cookies from 'utils/Cookies/Cookies';
import { fixLanguage } from 'utils/Data/language';
import { deriveEditedPermissions, isExtenalADUser, getADDomain, getEnabledTeasers } from 'utils/profile';
import { getFeaturesWithDefaults, cleanLegacyFeatures } from 'utils/Data/features';
import ProfileService from 'services/profile';
import { normalizeName } from './utils';
import { NewProfileFeatureDefaults, isAdminOrCaverionUserRole } from 'utils/Data/profileData';

const SEARCH_LIMIT = 2000;
export const SEARCH_TYPE_PARTNER_NUMBER = 'SEARCH_TYPE_PARTNER_NUMBER';
export const SEARCH_TYPE_FUNCTIONAL_LOCATION = 'SEARCH_TYPE_FUNCTIONAL_LOCATION';

const initialState = {
  profile: {},
  topLevelPermissions: [],
  newProfile: {
    language: 'en',
    role: 'user',
    permissions: {},
    features: NewProfileFeatureDefaults,
    featureTeasers: [],
  },
  welcomeProfile: {},
  profiles: {},
  editProfiles: {},
  activePartner: null,
  error: '',
  usernameExists: {},
  resentVerification: {},
  loading: false,
  userSearch: undefined,
  userSearchFilters: {
    limit: SEARCH_LIMIT,
    internal: true,
    external: true,
  },
  apiKeys: {},
  tos: {
    text: undefined,
    hash: undefined,
    open: false,
    saving: false,
    error: false,
  },
};

export const LOAD_ME = 'CUSTOMER_PLATFORM/Profile/LOAD_ME';
export const LOAD_ME_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_ME_SUCCESS';
export const LOAD_ME_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_ME_FAIL';

export const loadMe = () => {
  return async dispatch => {
    dispatch({ type: LOAD_ME });
    try {
      const profile = await ProfileService.myProfile();
      dispatch({
        type: LOAD_ME_SUCCESS,
        payload: profile,
      });
      return profile;
    } catch (error) {
      dispatch({
        type: LOAD_ME_FAIL,
        error: 'Loading own profile failed!',
      });
      throw error;
    }
  };
};

export const LOAD = 'CUSTOMER_PLATFORM/Profile/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_FAIL';

export const load = id => {
  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await ProfileService.profileById(id);

      return dispatch({
        type: LOAD_SUCCESS,
        id,
        result,
      });
    } catch (error) {
      dispatch({
        type: LOAD_FAIL,
        error: 'Loading profile failed!',
      });
      throw error;
    }
  };
};

export const LOAD_AS_NEW_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_AS_NEW_SUCCESS';
export const LOAD_AS_NEW_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_AS_NEW_FAIL';

const cleanFieldsForDuplicateProfile = profile =>
  pick(
    profile,
    'division',
    'serviceOrderStartDate',
    'documentStartDate',
    'giosgId',
    'featureTeasers',
    'features',
    'kpis',
    'language',
    'partnerNumbers',
    'partnerPermissions',
    'addedPartnerPermissions',
    'permissions',
    'role'
  );

export const loadAsNew = id => {
  return async dispatch => {
    dispatch({ type: LOAD });
    try {
      const result = await ProfileService.profileById(id);
      const newProfile = cleanFieldsForDuplicateProfile(result);
      dispatch({
        type: LOAD_AS_NEW_SUCCESS,
        id,
        newProfile,
      });
      return newProfile;
    } catch (error) {
      dispatch({
        type: LOAD_AS_NEW_FAIL,
        error: 'Loading profile failed!',
      });
    }
  };
};

export const RESEND_VERIFICATION = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION';
export const RESEND_VERIFICATION_SUCCESS = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_FAIL = 'CUSTOMER_PLATFORM/Profile/RESEND_VERIFICATION_FAIL';

export const resendVerification = id => {
  return async dispatch => {
    dispatch({ type: RESEND_VERIFICATION });
    try {
      const verificationDetails = await ProfileService.resendProfileVerification(id);
      return dispatch({
        type: RESEND_VERIFICATION_SUCCESS,
        id,
        verificationDetails,
      });
    } catch (error) {
      return dispatch({
        type: RESEND_VERIFICATION_FAIL,
        id,
        error: 'Sending verification code failed!',
      });
    }
  };
};

export const SET_NEW_PROFILE_PROPERTY = 'CUSTOMER_PLATFORM/Profile/SET_NEW_PROFILE_PROPERTY';

export const setNewProfileProperty = (property, value) => {
  return {
    type: SET_NEW_PROFILE_PROPERTY,
    property,
    value,
  };
};

export const SET_PROPERTY = 'CUSTOMER_PLATFORM/Profile/SET_PROPERTY';

export const setProperty = (id, property, value) => {
  return {
    type: SET_PROPERTY,
    id,
    property,
    value,
  };
};

export const SET_EDIT = 'CUSTOMER_PLATFORM/Profile/SET_EDIT';

export const setEdit = id => {
  return {
    type: SET_EDIT,
    id,
  };
};

export const CLEAR_EDIT = 'CUSTOMER_PLATFORM/Profile/CLEAR_EDIT';

export const clearEdit = () => ({
  type: CLEAR_EDIT,
});

export const SAVE = 'CUSTOMER_PLATFORM/Profile/SAVE';
export const SAVE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SAVE_SUCCESS';
export const SAVE_FAIL = 'CUSTOMER_PLATFORM/Profile/SAVE_FAIL';

export const save = (id, data) => {
  const profile = {
    ...data,
    ...deriveEditedPermissions(data),
    ...getEnabledTeasers(data.features, data.featureTeasers),
  };
  const cleanData = omit(profile, [
    'keyword',
    'edited',
    'addedFunctionalLocations',
    'deletedFunctionalLocations',
    'addedPartnerPermissions',
    'deletedPartnerPermissions',
    'syntheticFeatures',
    'inactivePermissions',
    'cacheTag',
  ]);

  return async (dispatch, getState) => {
    // Some users might have old profiles with missing features that are set on by default in UI
    // If current user is not an admin or a Caverion user, don't try to change feature set for own profile
    const profileState = getState().profile;
    if (!isAdminOrCaverionUserRole(profileState.profile?.role) && cleanData.id === profileState.profile?.id) {
      cleanData.features = profileState.profileOriginalFeatures;
    }
    const result = await ProfileService.saveProfile(cleanData);
    dispatch({
      type: SAVE_SUCCESS,
      id,
      result,
    });
    return result;
  };
};

export const CREATE = 'CUSTOMER_PLATFORM/Profile/CREATE';
export const CREATE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/CREATE_SUCCESS';
export const CREATE_FAIL = 'CUSTOMER_PLATFORM/Profile/CREATE_FAIL';

export const create = data => {
  const cleanData = omit(data, [
    'keyword',
    'edited',
    'addedFunctionalLocations',
    'deletedFunctionalLocations',
    'addedPartnerPermissions',
    'deletedPartnerPermissions',
    'syntheticFeatures',
    'inactivePermissions',
  ]);

  return async dispatch => {
    dispatch({ type: CREATE });
    try {
      const result = await ProfileService.createProfile(cleanData);
      await ProfileService.invalidateProfileCache();
      dispatch({
        type: CREATE_SUCCESS,
        result,
      });
      return result;
    } catch (error) {
      dispatch({
        type: CREATE_FAIL,
        error: error?.message,
      });
      throw new Error(error?.message);
    }
  };
};

export const SEARCH = 'CUSTOMER_PLATFORM/Profile/SEARCH';
export const SEARCH_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_FAIL';

export const search = (keyword, searchType) => async dispatch => {
  if (searchType === SEARCH_TYPE_FUNCTIONAL_LOCATION) {
    return await ProfileService.searchProfiles({
      functionalLocation: keyword,
      limit: SEARCH_LIMIT,
    });
  }

  return searchByProfileAttributes(keyword, searchType);
};

const searchByProfileAttributes = (keyword, searchType) => {
  let filter = {};
  switch (searchType) {
    case SEARCH_TYPE_PARTNER_NUMBER:
      filter = {
        partnerNumber: keyword,
      };
      break;

    default:
      throw new Error(`Unknown search type: '${searchType}'`);
  }

  return ProfileService.searchProfiles(filter, true);
};

export const SEARCH_USERNAME = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME';
export const SEARCH_USERNAME_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME_SUCCESS';
export const SEARCH_USERNAME_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERNAME_FAIL';

export const searchUsername = username => {
  return async dispatch => {
    dispatch({ type: SEARCH_USERNAME });
    try {
      const result = await ProfileService.searchProfiles({ username }, true);

      return dispatch({
        type: SEARCH_USERNAME_SUCCESS,
        username,
        result,
      });
    } catch (error) {
      return dispatch({
        type: SEARCH_USERNAME_FAIL,
        error: 'Username search failed!',
      });
    }
  };
};

export const SEARCH_USERS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_FAIL';

export const searchUsers = filter => async dispatch => {
  dispatch({ type: SEARCH_USERS });
  try {
    const result = await ProfileService.searchProfiles(filter);
    return dispatch({
      type: SEARCH_USERS_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_USERS_FAIL,
      error: 'Search failed!',
    });
  }
};

export const SEARCH_USERS_CLEAR = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_CLEAR';

export const clearUserSearch = () => ({
  type: SEARCH_USERS_CLEAR,
});

export const SEARCH_USERS_SET_FILTERS = 'CUSTOMER_PLATFORM/Profile/SEARCH_USERS_SET_FILTERS';

export const setUserSearchFilters = filters => ({
  type: SEARCH_USERS_SET_FILTERS,
  filters,
});

export const SET_ACTIVE_PARTNER = 'CUSTOMER_PLATFORM/Profile/SET_ACTIVE_PARTNER';

export const setActivePartner = partnerNumber => {
  cookies.setItem('selectedPartner', partnerNumber, Infinity, '/');

  return {
    type: SET_ACTIVE_PARTNER,
    partnerNumber: partnerNumber || null,
  };
};

export const DELETE_PROFILE = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE';
export const DELETE_PROFILE_SUCCESS = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAIL = 'CUSTOMER_PLATFORM/Profile/DELETE_PROFILE_FAIL';

export const deleteProfile = profileId => async dispatch => {
  dispatch({ type: DELETE_PROFILE });
  try {
    await ProfileService.deleteProfile(profileId);
    await ProfileService.invalidateProfileCache();
    return dispatch({
      type: DELETE_PROFILE_SUCCESS,
      profileId,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_PROFILE_FAIL,
      profileId,
      error: 'Failed to delete profile.',
    });
  }
};

export const UPDATE_TRANSLATIONS = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS';
export const UPDATE_TRANSLATIONS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS_SUCCESS';
export const UPDATE_TRANSLATIONS_FAIL = 'CUSTOMER_PLATFORM/Profile/UPDATE_TRANSLATIONS_FAIL';

export const updateTranslations = () => {
  return async dispatch => {
    dispatch({ type: UPDATE_TRANSLATIONS });
    try {
      await ProfileService.updateTranslations();
      return dispatch({ type: UPDATE_TRANSLATIONS_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_TRANSLATIONS_FAIL,
        error: 'Updating translations failed!',
      });
    }
  };
};

export const LOAD_API_KEYS = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS';
export const LOAD_API_KEYS_SUCCESS = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS_SUCCESS';
export const LOAD_API_KEYS_FAIL = 'CUSTOMER_PLATFORM/Profile/LOAD_API_KEYS_FAIL';

export const loadApiKeys =
  (profileId, cache = true) =>
  async (dispatch, getState) => {
    if (cache && getState().profile.apiKeys[profileId]) {
      return;
    }

    dispatch({ type: LOAD_API_KEYS, profileId });
    try {
      const result = await ProfileService.apiKeys(profileId);
      return dispatch({ type: LOAD_API_KEYS_SUCCESS, profileId, result });
    } catch (error) {
      return dispatch({ type: LOAD_API_KEYS_FAIL, profileId, error: error.message });
    }
  };

export const DELETE_API_KEY = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAIL = 'CUSTOMER_PLATFORM/Profile/DELETE_API_KEY_FAIL';

export const deleteApiKey = (profileId, keyId) => async dispatch => {
  dispatch({ type: DELETE_API_KEY, profileId });
  try {
    await ProfileService.deleteApiKey(keyId);
    return dispatch({ type: DELETE_API_KEY_SUCCESS, profileId, keyId });
  } catch (error) {
    return dispatch({ type: DELETE_API_KEY_FAIL, profileId, error: error.message });
  }
};

export const CREATE_API_KEY = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY';
export const CREATE_API_KEY_SUCCESS = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAIL = 'CUSTOMER_PLATFORM/Profile/CREATE_API_KEY_FAIL';

export const createApiKey = (profileId, apiKey) => async dispatch => {
  dispatch({ type: CREATE_API_KEY, profileId });
  try {
    const result = await ProfileService.createApiKey({ ...apiKey, profileId });
    return dispatch({ type: CREATE_API_KEY_SUCCESS, profileId, result });
  } catch (error) {
    return dispatch({ type: CREATE_API_KEY_FAIL, profileId, error: error.message });
  }
};

export const SET_TOS_OPEN = 'CUSTOMER_PLATFORM/Profile/SET_TOS_OPEN';
export const setTosOpen = open => ({ type: SET_TOS_OPEN, payload: open });

export const ACKNOWLEDGE_TOS = 'CUSTOMER_PLATFORM/Profile/ACKNOWLEDGE_TOS';
export const acknowledgeTos = tosText => async dispatch => {
  dispatch({ type: ACKNOWLEDGE_TOS });
  try {
    const acknowledgement = await ProfileService.acknowledgeTos(tosText);
    dispatch({ type: ACKNOWLEDGE_TOS, payload: acknowledgement });
  } catch (error) {
    dispatch({ type: ACKNOWLEDGE_TOS, error: true, payload: error });
  }
};

const IGNORE_FOR_EDITED = ['edited', 'keyword', 'ccAlarms'];

export const setImplicitProperties = (oldProfile, newProfile, isNewProfile) => {
  const profile = Object.assign({}, newProfile);

  if (isNewProfile) {
    if (isExtenalADUser(profile.email)) {
      // Set username equal to email address for external AD users.
      // Hackish way to prevent editing username for hackishly made internal AD users with external AD email
      const username = profile.email.split('@')[0];
      const adDomain = getADDomain(profile.email);
      profile.username = `${username}@${adDomain}`.toLowerCase();
    } else if (profile.firstName !== undefined && profile.lastName !== undefined) {
      profile.username = `${normalizeName(profile.firstName)}.${normalizeName(profile.lastName)}@${
        process.env.REACT_APP_AZUREAD_DOMAIN
      }`;
    }
  }

  if (oldProfile.edited) {
    profile.edited = true;
  } else if (!isEqual(oldProfile, profile)) {
    const formPropertyChanged = IGNORE_FOR_EDITED.some(field => !isEqual(oldProfile[field], profile[field]));
    profile.edited = !formPropertyChanged;
  } else {
    profile.edited = false;
  }

  return profile;
};

const loadMeReducer = (state, action) => {
  return {
    ...state,
  };
};

const loadMeSuccessReducer = (state, action) => {
  const { tos, ...profile } = action.payload;
  const cleanedFeatures = cleanLegacyFeatures(profile.features);
  return {
    ...state,
    profileOriginalFeatures: profile.features,
    profile: {
      ...profile,
      features: getFeaturesWithDefaults(cleanedFeatures),
    },
    topLevelPermissions: uniq(flatten(Object.keys(profile.permissions || {}).map(x => profile.permissions[x]))),
    profiles: assign({}, state.profiles, {
      [profile.id]: { ...profile, features: cleanedFeatures },
    }),

    // Get selected partner from a cookie or set first partner as active partner or null if not available.
    activePartner: cookies.getItem('selectedPartner') || (Object.keys(profile.permissions) || [])[0] || null,
    tos: {
      ...state.tos,
      ...tos,
    },
  };
};

const loadMeFailReducer = (state, action) => {
  return {
    ...state,
    profile: action.error,
  };
};

const loadReducer = (state, action) => {
  return {
    ...state,
  };
};

const loadSuccessReducer = (state, action) => {
  const result = action.result;
  const cleanedFeatures = cleanLegacyFeatures(result.features);
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: { ...action.result, features: cleanedFeatures, language: fixLanguage(result.language) },
    },
  };
};

const loadFailReducer = (state, action) => {
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: action.error,
    },
  };
};

const loadAsNewSuccessReducer = (state, action) => {
  const { newProfile } = action;
  return {
    ...state,
    newProfile,
    error: undefined,
  };
};

const loadAsNewFailReducer = (state, action) => {
  return {
    ...state,
    newProfile: {
      ...state.newProfile,
      error: action.error,
    },
  };
};

const setNewProfilePropertyReducer = (state, action) => {
  return {
    ...state,
    newProfile: setImplicitProperties(
      state.newProfile,
      setWith(cloneDeep(state.newProfile), action.property, action.value, Object),
      true
    ),
  };
};

const setPropertyReducer = (state, action) => {
  const oldData = state.editProfiles[action.id];
  return {
    ...state,
    editProfiles: {
      ...state.editProfiles,
      [action.id]:
        oldData &&
        setImplicitProperties(
          oldData,
          setWith(cloneDeep(state.editProfiles[action.id]), action.property, action.value, Object)
        ),
    },
  };
};

const createReducer = (state, action) => {
  return {
    ...state,
    error: null,
  };
};

const createSuccessReducer = (state, action) => {
  return {
    ...state,
    newProfile: initialState.newProfile,
    profiles: {
      ...state.profiles,
      [action.result.id]: action.result,
    },
  };
};

const createFailReducer = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const saveSuccessReducer = (state, action) => {
  const ownProfile = state.profile.id === action.result.id;
  return {
    ...state,
    profiles: {
      ...state.profiles,
      [action.id]: action.result,
    },
    editProfiles: {
      ...state.editProfiles,
      [action.id]: action.result,
    },
    profile: ownProfile
      ? { ...action.result, features: getFeaturesWithDefaults(action.result.features) }
      : state.profile,
    topLevelPermissions: ownProfile
      ? uniq(flatten(Object.keys(action.result.permissions || {}).map(x => action.result.permissions[x]))) || []
      : uniq(flatten(Object.keys(state.profile.permissions || {}).map(x => state.profile.permissions[x]))) || [],
  };
};

const resendVerificationReducer = (state, action) => {
  return {
    ...state,
    resentVerification: {},
    resendingVerification: true,
  };
};

const resendVerificationSuccessReducer = (state, action) => {
  return {
    ...state,
    resentVerification: { [action.id]: action.verificationDetails || true },
    resendingVerification: false,
  };
};

const resendVerificationFailReducer = (state, action) => {
  return {
    ...state,
    resentVerification: { [action.id]: false },
    resendingVerification: false,
  };
};

const searchUsernameReducer = (state, action) => {
  return {
    ...state,
    usernameExists: {},
  };
};

const searchUsernameSuccessReducer = (state, action) => {
  return {
    ...state,
    usernameExists: { [action.username]: action.result.length > 0 },
  };
};

const searchUsernameFailReducer = (state, action) => {
  return {
    ...state,
    usernameExists: {},
    error: action.error,
  };
};

const setActivePartnerReducer = (state, action) => {
  return {
    ...state,
    activePartner: action.partnerNumber,
  };
};

const setEditReducer = (state, action) => {
  return {
    ...state,
    editProfiles: {
      ...state.editProfiles,
      [action.id]: cloneDeep(state.profiles[action.id]),
    },
  };
};

const clearEditReducer = state => ({
  ...state,
  editProfiles: initialState.editProfiles,
});

const deleteProfileSuccessReducer = (state, action) => ({
  ...state,
  userSearch: state.userSearch
    ? {
        ...state.userSearch,
        users: state.userSearch.users.filter(user => user.id !== action.profileId),
      }
    : undefined,
  profiles: {
    ...state.profiles,
    [action.profileId]: undefined,
  },
});

const searchUsersReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: [],
    state: 'loading',
  },
});

const searchUsersSuccessReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: action.result,
    state: 'success',
  },
});

const searchUsersFailReducer = (state, action) => ({
  ...state,
  userSearch: {
    users: [],
    state: 'error',
  },
});

const searchUsersClearReducer = state => ({
  ...state,
  userSearch: undefined,
  userSearchFilters: initialState.userSearchFilters,
});

const searchUsersSetFiltersReducer = (state, action) => ({
  ...state,
  userSearchFilters: action.filters,
});

const loadApiKeysReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      loading: true,
    },
  },
});

const loadApiKeysSuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: action.result,
    },
  },
});

const loadApiKeysFailReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      error: action.error,
    },
  },
});

const deleteApiKeySuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: state.apiKeys[action.profileId].data.filter(key => key.id !== action.keyId),
    },
  },
});

const createApiKeySuccessReducer = (state, action) => ({
  ...state,
  apiKeys: {
    ...state.apiKeys,
    [action.profileId]: {
      data: [...state.apiKeys[action.profileId].data, omit(action.result, 'token')],
    },
  },
});

const acknowledgeTosReducer = (state, action) => {
  const newTos = {
    ...state.tos,
    saving: !action.payload,
    error: !!action.error,
  };
  if (action.payload && !action.error) {
    // Editable profile is retrieved from profiles and editProfile can be open when we accept ToS so we need to save new state to profile, profiles and editProfiles
    const { userId, tosHash } = action.payload;
    const injectHash = (profile, hash) => {
      if (!profile || !hash) {
        return profile;
      }
      return {
        ...profile,
        tosAcknowledgementHashes: [...(profile.tosAcknowledgementHashes ?? []), hash],
      };
    };
    return {
      ...state,
      profile: injectHash(state.profile, action.payload.tosHash),
      profiles: {
        ...state.profiles,
        [userId]: injectHash(state.profiles[userId], tosHash),
      },
      editProfiles: {
        ...state.editProfiles,
        [userId]: injectHash(state.editProfiles[userId], tosHash),
      },
      tos: newTos,
    };
  }
  return {
    ...state,
    tos: newTos,
  };
};

const setTosOpenReducer = (state, action) => ({
  ...state,
  tos: {
    ...state.tos,
    open: action.payload,
  },
});

export default createReducerFromMapping(
  {
    [LOAD_ME]: loadMeReducer,
    [LOAD_ME_SUCCESS]: loadMeSuccessReducer,
    [LOAD_ME_FAIL]: loadMeFailReducer,
    [LOAD]: loadReducer,
    [LOAD_SUCCESS]: loadSuccessReducer,
    [LOAD_FAIL]: loadFailReducer,
    [LOAD_AS_NEW_SUCCESS]: loadAsNewSuccessReducer,
    [LOAD_AS_NEW_FAIL]: loadAsNewFailReducer,
    [SET_NEW_PROFILE_PROPERTY]: setNewProfilePropertyReducer,
    [SET_PROPERTY]: setPropertyReducer,
    [CREATE]: createReducer,
    [CREATE_SUCCESS]: createSuccessReducer,
    [CREATE_FAIL]: createFailReducer,
    [SAVE_SUCCESS]: saveSuccessReducer,
    [SEARCH_USERNAME]: searchUsernameReducer,
    [SEARCH_USERNAME_SUCCESS]: searchUsernameSuccessReducer,
    [SEARCH_USERNAME_FAIL]: searchUsernameFailReducer,
    [SET_ACTIVE_PARTNER]: setActivePartnerReducer,
    [SET_EDIT]: setEditReducer,
    [CLEAR_EDIT]: clearEditReducer,
    [RESEND_VERIFICATION]: resendVerificationReducer,
    [RESEND_VERIFICATION_SUCCESS]: resendVerificationSuccessReducer,
    [RESEND_VERIFICATION_FAIL]: resendVerificationFailReducer,
    [DELETE_PROFILE_SUCCESS]: deleteProfileSuccessReducer,
    [SEARCH_USERS]: searchUsersReducer,
    [SEARCH_USERS_SUCCESS]: searchUsersSuccessReducer,
    [SEARCH_USERS_FAIL]: searchUsersFailReducer,
    [SEARCH_USERS_CLEAR]: searchUsersClearReducer,
    [SEARCH_USERS_SET_FILTERS]: searchUsersSetFiltersReducer,
    [LOAD_API_KEYS]: loadApiKeysReducer,
    [LOAD_API_KEYS_SUCCESS]: loadApiKeysSuccessReducer,
    [LOAD_API_KEYS_FAIL]: loadApiKeysFailReducer,
    [DELETE_API_KEY_SUCCESS]: deleteApiKeySuccessReducer,
    [CREATE_API_KEY_SUCCESS]: createApiKeySuccessReducer,
    [ACKNOWLEDGE_TOS]: acknowledgeTosReducer,
    [SET_TOS_OPEN]: setTosOpenReducer,
  },
  initialState
);
