import PropTypes from 'prop-types';
import { FeatureKeys } from 'utils/Data/profileData';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { RECYCLING_META_KEYS } from './recyclingMeta';
import {
  SERVICE_ORDER_CONFIGURATION_META_KEYS,
  PLANNED_MAINTENANCE_CONFIGURATION_META_KEYS,
  OverdueHourLimit,
} from './maintenance';

const booleanOptions = ['true', 'false'];

/* eslint-disable camelcase */
export const DEFAULT_META_VALUES = {
  co2_multiplier: 217,
};

const SLA_META_KEYS = {
  SLA_reactionTime_prio1: [],
  SLA_reactionTime_prio2: [],
  SLA_reactionTime_prio3: [],
  SLA_reactionTime_prio4: [],
  SLA_downtime_prio1: [],
  SLA_downtime_prio2: [],
  SLA_downtime_prio3: [],
  SLA_downtime_prio4: [],
  SLA_leadTime_prio1: [],
  SLA_leadTime_prio2: [],
  SLA_leadTime_prio3: [],
  SLA_leadTime_prio4: [],
};

const SERVICE_CALENDAR_SOURCE_TOGGLE_KEYS = {
  hide_planon_service_orders: booleanOptions,
  hide_planon_planned_maintenance: booleanOptions,
  hide_sap_service_orders: booleanOptions,
  hide_sap_planned_maintenance: booleanOptions,
  hide_smartView_service_orders: booleanOptions,
  hide_smartView_planned_maintenance: booleanOptions,
};

export const OVERDUE_HOUR_LIMIT_KEY_PREFIX = 'serviceCalendar/overdue_hour_limit_for_';
export const OVERDUE_HOUR_LIMIT_KEYS = Object.fromEntries(
  Object.keys(OverdueHourLimit).map(priority => [
    `${OVERDUE_HOUR_LIMIT_KEY_PREFIX}${priority}`,
    [OverdueHourLimit[priority]],
  ])
);

export const HIDE_OVERDUE_STATUS_META_KEY = 'serviceCalendar/hide_overdue_service_order_status';

export const PARTNER_META_KEYS = {
  service_request_email: [],
  contact_email: [],
  contact_name: [],
  contact_tel: [],
  tampuuriConfig: [],
  hide_response_times: booleanOptions,
  hide_operation_long_texts: booleanOptions,
  [HIDE_OVERDUE_STATUS_META_KEY]: booleanOptions,
  new_service_request_categories: ['option1,option2,option3'],
  ...RECYCLING_META_KEYS,
  ...SLA_META_KEYS,
  ...SERVICE_CALENDAR_SOURCE_TOGGLE_KEYS,
  ...OVERDUE_HOUR_LIMIT_KEYS,
};

const USE_CASES = [undefined, 'cooler', 'freezer', 'storage', 'indoor'];
const PERFORMANCE_COMPONENTS = [
  'temperature',
  'humidity',
  'carbondioxide',
  'organic_gas',
  'radon',
  'particle/PM10',
  'particle/PM2.5',
  'pressure',
];

const constructPerformanceMetaKey = (...args) => ['performance', ...args].filter(_ => _).join('/');
const performanceHoursMetaKeys = USE_CASES.map(useCase => constructPerformanceMetaKey(useCase, 'hours'));
const performanceLimitMetaKeys = USE_CASES.flatMap(useCase =>
  PERFORMANCE_COMPONENTS.flatMap(component => [
    constructPerformanceMetaKey(useCase, component, 'min'),
    constructPerformanceMetaKey(useCase, component, 'max'),
  ])
);

// Populate all possible meta keys used to configure performance calculation. These include performance/<use-case>/hours
// and performance/<use-case>/<component>/<min|max>, where use case is optional.
const performanceMetaKeys = [...performanceHoursMetaKeys, ...performanceLimitMetaKeys].reduce(
  (acc, key) => ({
    ...acc,
    [key]: [],
  }),
  {}
);

const ServiceOrderMetaKeys = Object.fromEntries(
  Object.values(SERVICE_ORDER_CONFIGURATION_META_KEYS).map(key => [key, booleanOptions])
);

const PlannedMaintenanceMetaKeys = Object.fromEntries(
  Object.values(Object.values(PLANNED_MAINTENANCE_CONFIGURATION_META_KEYS)).map(key => [key, booleanOptions])
);

/**
 * See {@link SustainabilityEmissionTargets}
 */
export const SustainabilityMetaKeys = {
  'sustainability/emissionTargets': [],
};

export const BUILDING_META_KEYS = {
  BuildingType: [
    'Kindergarten',
    'Shopping center shop',
    'Hotel',
    'Sportsbuilding',
    'Office',
    'Cultural building',
    'Light industry workshop',
    'School',
    'Small house',
    'Hospital',
    'Nursing home',
    'University',
  ],
  YearOfConstruction: [],
  grossArea: [],
  netArea: [],
  degree_days_location: ['Helsinki'],
  energy_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  district_heating_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  heating_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  electricity_reference: ['[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]'],
  location_latlng: ['60.1,24.8'],
  tampuuriId: [],
  weather_station_id: [],
  timezone: ['EET'],
  opening_hours: ['08:00-16:00'],
  utilization_calculation_hours: ['09:00-15:00'],
  'performance/min': [],
  'performance/max': [],
  default_outdoor_temperature_id: [],
  [HIDE_OVERDUE_STATUS_META_KEY]: booleanOptions,
  ...RECYCLING_META_KEYS,
  ...performanceMetaKeys,
  ...SLA_META_KEYS,
  ...ServiceOrderMetaKeys,
  ...PlannedMaintenanceMetaKeys,
  ...SustainabilityMetaKeys,
  ...OVERDUE_HOUR_LIMIT_KEYS,
};

export const USE_LTP_STATUS_META_KEY = 'service_calendar/use_long_term_planning_status';

export const MetaType = {
  Email: 'email',
  Boolean: 'boolean',
  Number: 'number',
  Text: 'text',
};

export const MetaCategory = {
  BuildingInformation: 'Building Information',
  EmailRecipients: 'Email Recipients',
  Maintenance: 'Service Calendar',
};

export const MetaFeature = {
  Maintenance: 'Service Calendar',
  NewServiceRequest: 'New Service Request',
};

export const TypedMeta = [
  {
    key: 'BuildingType',
    label: 'Building Type',
    helpText: `${CTXHELP_PREFIX} Building Type`,
    category: MetaCategory.BuildingInformation,
    type: MetaType.Text,
    suggestions: BUILDING_META_KEYS.BuildingType,
    useInPortfolio: false,
    useInBuilding: true,
  },
  {
    key: 'location_latlng',
    label: ['Location Latitude', 'Location Longitude'],
    helpText: `${CTXHELP_PREFIX} Location latlng`,
    category: MetaCategory.BuildingInformation,
    type: MetaType.Number,
    isTupleOf: 2,
    useInPortfolio: false,
    useInBuilding: true,
  },
  {
    key: 'grossArea',
    label: 'Gross Area',
    unit: 'm²',
    helpText: `${CTXHELP_PREFIX} Gross Area`,
    category: MetaCategory.BuildingInformation,
    type: MetaType.Number,
    useInPortfolio: false,
    useInBuilding: true,
  },
  {
    key: 'netArea',
    label: 'Net Area',
    unit: 'm²',
    helpText: `${CTXHELP_PREFIX} Net Area`,
    category: MetaCategory.BuildingInformation,
    type: MetaType.Number,
    useInPortfolio: false,
    useInBuilding: true,
  },
  {
    key: 'service_calendar/notification_email',
    label: 'Service Module notifications',
    helpText: `${CTXHELP_PREFIX} Service Module notifications`,
    category: MetaCategory.EmailRecipients,
    feature: MetaFeature.Maintenance,
    type: MetaType.Email,
    isArray: true,
    useInPortfolio: true,
    useInBuilding: true,
    requiredFeature: FeatureKeys.SERVICE_ORDERS,
  },
  {
    key: 'service_request_email',
    label: 'New Service Request recipient',
    helpText: `${CTXHELP_PREFIX} New Service Request recipient`,
    category: MetaCategory.EmailRecipients,
    feature: MetaFeature.NewServiceRequest,
    type: MetaType.Email,
    isArray: false,
    useInPortfolio: true,
    useInBuilding: false,
  },
  {
    key: 'service_calendar/use_long_term_planning_status',
    label: 'Show statuses in Long Term Planning',
    description: 'Show statuses and status-based statistics for long term planning items',
    category: MetaCategory.Maintenance,
    type: MetaType.Boolean,
    useInPortfolio: true,
    useInBuilding: true,
    requiredFeature: FeatureKeys.LONG_TERM_PLANNING,
  },
  {
    key: 'service_calendar/include_division_checklists',
    label: 'Include division checklists',
    description: 'Allows using division level checklist templates in service orders',
    category: MetaCategory.Maintenance,
    type: MetaType.Boolean,
    useInPortfolio: true,
    useInBuilding: false,
    requiredFeature: FeatureKeys.CHECKLISTS,
  },
  {
    key: 'serviceCalendar/hide_overdue_service_order_status',
    label: 'Hide overdue service order status',
    description: 'Hides the status "overdue" status markings from maintenance',
    category: MetaCategory.Maintenance,
    type: MetaType.Boolean,
    useInPortfolio: true,
    useInBuilding: true,
    requiredFeature: FeatureKeys.SERVICE_ORDERS,
  },
];

export const TypedMetaItemShape = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  description: PropTypes.string,
  helpText: PropTypes.string,
  category: PropTypes.oneOf(Object.values(MetaCategory)),
  type: PropTypes.oneOf(Object.values(MetaType)),
  isArray: PropTypes.bool,
  isTupleOf: PropTypes.number,
  useInPortfolio: PropTypes.bool,
  useInBuilding: PropTypes.bool,
  requiredFeature: PropTypes.oneOf(Object.values(FeatureKeys)),
});
