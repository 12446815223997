import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import Infotip from 'components/Infotip/Infotip';

const Container = styled.div`
  position: relative;
  height: 100%;
  display: none;

  ${props => props.theme.media.portrait`
    display: flex;
    width: 55px;
    justify-content: center;
  `}
`;

const IconButton = styled.span`
  height: 100%;
  width: 100%;
  display: none;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 5px;
  pointer-events: auto;
  cursor: pointer;

  &:hover {
    > * {
      transform: scale(1.15);
      transition: all ${props => props.theme.motion.quick} ${props => props.theme.motion.easing};
    }
  }

  ${props => props.theme.media.portrait`
        display: flex;
    `}
`;

const Icon = styled(Svg)`
  fill: var(--legacy-header-fg);
  font-size: 22px;
`;

const NavBarButton = ({ icon, onClick, children, label }) => {
  return (
    <Container>
      <Infotip position="bottom" text={label}>
        <IconButton onClick={onClick}>
          <Icon name={icon} />
        </IconButton>
        {children}
      </Infotip>
    </Container>
  );
};

NavBarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default NavBarButton;
