import React, { SVGAttributes } from 'react';
import styled, { css } from 'styled-components';

import Svg from 'components/Svg/Svg';

type CaretToggleProps = {
  type: 'accordion' | 'dropdown';
  isOpen: boolean;
} & SVGAttributes<SVGElement>;

const iconBaseStyle = css`
  fill: var(--caret-fg);
  font-size: var(--size-xs-plus);
  transition: 0.2s ease-in-out;
`;

const CaretIconAccordion = styled(({ isOpen, ...restProps }) => <Svg {...restProps} />)`
  ${iconBaseStyle}

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(90deg);
    `};
`;

const CaretIconDropdown = styled(({ isOpen, ...restProps }) => <Svg {...restProps} />)`
  ${iconBaseStyle}

  ${props =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

/**
 * @implements {UIv3}
 *
 * @param type Type is 'accordion' or 'dropdown'
 * @param isOpen
 * @param props
 */
export const CaretToggle = ({ type, isOpen, ...props }: CaretToggleProps) => {
  if (type === 'accordion') {
    return <CaretIconAccordion name="caret-right" {...props} isOpen={isOpen}></CaretIconAccordion>;
  }

  if (type === 'dropdown') {
    return <CaretIconDropdown name="caret-down" {...props} isOpen={isOpen}></CaretIconDropdown>;
  }

  return null;
};
