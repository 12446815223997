import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import find from 'lodash/find';
import map from 'lodash/map';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Hero from 'components/Hero/Hero';
import StandardPage from 'components/StandardPage/StandardPage';
import Header from 'containers/Application/Header/Header';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import BuildingTable from './BuildingTable/BuildingTable';
import DocumentModule from 'containers/Application/Modules/DocumentModule/DocumentModule';
import DocumentModuleV2 from 'containers/Application/Modules/DocumentModule/DocumentModuleV2';

import { useTranslations } from 'decorators/Translations/translations';
import { getPartnerNumbers } from 'utils/profile';
import { loadPartnerFiles, loadPartnerFolders } from 'redux/modules/index';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  width: 100%;
  margin: var(--size-md) auto 0;

  ${props => props.theme.media.landscape`
    margin: ${props => props.theme.section.spacing} auto 0;
  `}
`;

export const PartnerDocumentModule = props => {
  const {
    customers,
    match: {
      params: { partnerNumber },
      path,
      url,
    },
    location,
    history,
    profile,
    features: { portfolioDocuments },
    loadPartnerFiles,
    loadPartnerFolders,
  } = props;
  const [t] = useTranslations();

  useEffect(() => {
    if (portfolioDocuments && partnerNumber && partnerNumber !== 'all') {
      loadPartnerFiles(partnerNumber);
      loadPartnerFolders(partnerNumber);
    }
  }, [portfolioDocuments, partnerNumber, loadPartnerFiles, loadPartnerFolders]);

  if (!portfolioDocuments) {
    return null;
  }

  if (!partnerNumber || (partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber))) {
    return <ErrorPage type="partner" />;
  }

  const tabOptions = [
    { value: 'Portfolio', label: t('Portfolio') },
    { value: 'Buildings', label: t('Buildings') },
  ];

  const customerName = customers[partnerNumber] && customers[partnerNumber].name;

  const selectedTab = find(map(tabOptions, 'value'), tabValue => location.pathname.endsWith(tabValue));

  const isDocumentsV2Path = path?.includes('DocumentsV2');

  return (
    <StandardPage withTabs>
      <Helmet title={t('Documents')} />
      <Header t={t} selected="documents" showPartnerSelect />
      <Hero title={t('Documents')} subTitle={customerName} t={t} type="DOCUMENTS" />
      <Container>
        {isDocumentsV2Path ? (
          <DocumentModuleV2 />
        ) : (
          <>
            <SectionHeader>
              <SectionTabSelector
                options={tabOptions}
                model={{ selectedTab }}
                property="selectedTab"
                onTabChange={(property, value) => history.push(`${url}/${value}`)}
              />
            </SectionHeader>
            <Switch>
              <Route path={`${path}/Buildings`} component={BuildingTable} />
              <Route exact path={`${path}/Portfolio`} render={() => <DocumentModule partnerNumber={partnerNumber} />} />
              <Redirect exact from={`${path}`} to={`${path}/Portfolio`} />
            </Switch>
          </>
        )}
      </Container>
    </StandardPage>
  );
};

PartnerDocumentModule.propTypes = {
  customers: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  loadPartnerFiles: PropTypes.func.isRequired,
  loadPartnerFolders: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  customers: state.customer.customers,
  features: state.profile.profile.features,
});

const mapDispatchToProps = {
  loadPartnerFiles,
  loadPartnerFolders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PartnerDocumentModule);
