import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import DateColumn, { StyledDateColumn } from '../ColumnTypes/DateColumn';
import ToggleDescriptionColumn from '../ColumnTypes/ToggleDescriptionColumn';
import StatusColumn from '../ColumnTypes/StatusColumn';
import ImprovementColumn, { StyledImprovementColumn } from '../ColumnTypes/ImprovementColumn';
import LocationColumn from '../ColumnTypes/LocationColumn';
import EventsRow from '../EventsRow/EventsRow';
import EventsCell from '../EventsRow/EventsCell';
import { DefaultCell } from './ObservationsCells';
import { createElementIdForGTM } from 'utils/Analytics/analytics';

const MobileStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--size-sm);
  ${StyledImprovementColumn} {
    > div {
      margin: 0;
      font-size: ${props => props.theme.font.size.xxs};
      padding: var(--size-xxxs) var(--size-xxs);
    }
  }
`;

const MobileStatusLabel = styled.div`
  display: flex;
  margin-right: var(--size-sm);
  &:not(:first-child) {
    margin-left: var(--size-sm);
  }
`;

const MobileDateColumn = styled.div`
  margin-top: var(--size-sm);
  ${StyledDateColumn} {
    padding-left: 0;
  }
`;

const getCell = (t, rowData, column, hideMobileComponent) => {
  const hideOnMobile = !hideMobileComponent;
  const Cell = column.Cell != null ? column.Cell : DefaultCell;
  return (
    <EventsCell key={column.accessor} hideOnMobile={hideOnMobile} grow={column.grow}>
      <Cell t={t} rowData={rowData} field={column.field} />
    </EventsCell>
  );
};

const ObservationsRow = props => {
  const { t, rowData, onClick, columns, hideMobileComponent } = props;

  return (
    <EventsRow
      // id for GTM
      id={`ObservationElement${createElementIdForGTM(rowData.id)}`}
      onClick={() => onClick(rowData)}
    >
      {columns.map(column => getCell(t, rowData, column, hideMobileComponent))}
      {!hideMobileComponent && (
        <EventsCell hideOnDesktop hideOnPrint onClick={e => e.stopPropagation()}>
          <MobileStatusWrapper>
            <MobileStatusLabel>{t('Status')}:</MobileStatusLabel>
            <StatusColumn data={{ status: rowData.status, subStatus: rowData.subStatus }} t={t} />
            {rowData.savingsPotentialSum !== 0 && (
              <>
                <MobileStatusLabel>{t('Improvement')}:</MobileStatusLabel>
                <ImprovementColumn data={rowData.savingsPotentialSum} t={t} />
              </>
            )}
            {!isNil(rowData.functionalLocationName) && (
              <>
                <MobileStatusLabel>{t('Location')}:</MobileStatusLabel>
                <LocationColumn
                  t={t}
                  data={{
                    name: rowData.functionalLocationName,
                    functionalLocation: rowData.functionalLocation,
                    partnerNumber: rowData.currentPartnerNumber,
                  }}
                />
              </>
            )}
          </MobileStatusWrapper>
          <ToggleDescriptionColumn t={t} data={rowData.location} />
          <MobileDateColumn>
            <DateColumn data={rowData.date} />
          </MobileDateColumn>
        </EventsCell>
      )}
    </EventsRow>
  );
};

ObservationsRow.propTypes = {
  t: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideMobileComponent: PropTypes.bool,
};

export default ObservationsRow;
