import React, { lazy, Suspense } from 'react';
import { FullScreenLoader } from 'components/Loader/Loader';

const Admin = lazy(() =>
  import(
    /* webpackChunkName: "admin" */
    'containers/Application/Admin/Admin'
  )
);

const LazyLoadedAdmin = props => (
  <Suspense fallback={<FullScreenLoader size="LARGE" />}>
    <Admin {...props} />
  </Suspense>
);

export default LazyLoadedAdmin;
