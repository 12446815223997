import React from 'react';
import PropTypes from 'prop-types';

import InputForm from 'components/Form/InputForm';
import InputText from 'components/Form/InputText';
import InputTextArea from 'components/Form/InputTextArea';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import PrimaryButton from 'components/Button/PrimaryButton';

const ContactForm = ({ t, model, onSubmit, onFormChange, categoryOptions, sending, sent }) => (
  <InputForm onPropertyChange={onFormChange} onSubmit={onSubmit} model={model}>
    <InputRow fullRow required>
      <InputLabel text={t('Category')} />
      <InputSelectDropdown t={t} property="category" options={categoryOptions} id="contactCategorySelect" />
    </InputRow>
    <InputRow fullRow required>
      <InputLabel text={t('Description')} />
      <InputTextArea property="description" id="contactDescriptionArea" />
    </InputRow>
    <InputRow fullRow>
      <InputLabel text={t('Phone number for contact')} extraText={`(${t('Optional')})`} />
      <InputText property="createdByPhoneNumber" />
    </InputRow>
    <PrimaryButton confirm large loading={sending} disabled={sent}>
      {sent ? t('Sent') : t('Send')}
    </PrimaryButton>
  </InputForm>
);

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  sending: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default ContactForm;
