import ApiRequest from 'utils/Fetch/ApiRequest';
import { MASTER_DATA_PATH } from './index';

export const getMaintenancePlanLogs = ({ code, refreshCache = false, onlyInvalidateCache = false }) => {
  return ApiRequest.get({
    apiPath: `${MASTER_DATA_PATH}/maintenancePlans/${code}/getLogs`,
    refreshCache,
    onlyInvalidateCache,
    useAuthorization: true,
  });
};
