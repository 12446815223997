import papa from 'papaparse';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import isDate from 'date-fns/isDate';

import { downloadCsvFile } from 'utils/CSV/downloadCsvFile';

const inspectionToCsvFields = ({ timestamp, functionalLocation, status, location, device, description }) =>
  cleanWhitespaceAndRemoveLineBreaks({
    type: location?.value || device,
    status: typeof status === 'string' ? status : status?.status,
    timestamp,
    functionalLocationName: functionalLocation?.description,
    functionalLocation: functionalLocation?.functionalLocation,
    description,
  });

const alarmToCsvFields = ({ message, event, action, functionalLocation }) =>
  cleanWhitespaceAndRemoveLineBreaks({
    alarm: message,
    event: event?.description,
    source: action?.source,
    actionCategoryText: action?.actionCategoryText,
    alarmTimestamp: action?.timestamp,
    actionTimestamp: action?.actionTimestamp,
    functionalLocationName: functionalLocation?.description,
    functionalLocation: functionalLocation?.functionalLocation,
  });

const observationToCsvFields = ({
  timestamp,
  deviceGroup,
  device,
  functionalLocation,
  status,
  location,
  description,
  savingsPotentialSum,
  ...rest
}) =>
  cleanWhitespaceAndRemoveLineBreaks({
    timestamp,
    deviceGroup,
    location: location?.value || device,
    description,
    status: typeof status === 'string' ? status : status?.status,
    functionalLocationName: functionalLocation?.description,
    functionalLocation: functionalLocation?.functionalLocation,
    savingsPotentialSum,
    ...rest,
  });

const hydrateFunctionalLocation = functionalLocations => item => ({
  ...item,
  functionalLocation: functionalLocations?.[item.functionalLocation],
});

const CSV_UNPARSE_OPTIONS = { delimiter: ';' }; // Using ; seems works better than comma in Excel

export const getSelectedTabCsvContent = (selectedTab, data) => {
  const { alarms, inspections, observations, investments, functionalLocations } = data;
  switch (selectedTab) {
    case 'Alarms':
      return papa.unparse(
        alarms.alarms
          ?.map(alarm => ({ ...alarm, functionalLocation: alarm.action?.functionalLocation }))
          .map(hydrateFunctionalLocation(functionalLocations))
          .map(alarmToCsvFields),
        CSV_UNPARSE_OPTIONS
      );
    case 'Inspections':
      return papa.unparse(
        inspections?.map(hydrateFunctionalLocation(functionalLocations)).map(inspectionToCsvFields),
        CSV_UNPARSE_OPTIONS
      );
    case 'Observations':
      return papa.unparse(
        observations?.map(hydrateFunctionalLocation(functionalLocations)).map(observationToCsvFields),
        CSV_UNPARSE_OPTIONS
      );
    case 'InvestmentCalendar': {
      // Use array form of data since papaparse lost object properties that were year strings
      const fields = [
        'functionalLocation.functionalLocation',
        'functionalLocation.description',
        ...Object.keys(investments.byYear).sort(),
      ];
      const fieldNames = ['functionalLocation', 'functionalLocationName', ...Object.keys(investments.byYear).sort()];
      const data = Object.keys(investments.byFl)
        .sort()
        .map(functionalLocation => ({
          functionalLocation,
          ...investments.byFl[functionalLocation],
        }))
        .map(hydrateFunctionalLocation(functionalLocations))
        .map(item => fields.map(field => get(item, field)));
      return papa.unparse({ fields: fieldNames, data }, CSV_UNPARSE_OPTIONS);
    }
    case 'Sustainability': {
      const fieldNames = ['title', 'unit', 'co2eFactor', 'code'].concat(
        Object.keys(data[0] || {})
          .map(Number)
          .filter(Number.isInteger)
          .sort()
      );
      return papa.unparse({ fields: fieldNames, data }, CSV_UNPARSE_OPTIONS);
    }
    default: {
      const fieldNames = Object.keys(data[0] || {});
      return papa.unparse({ fields: fieldNames, data }, CSV_UNPARSE_OPTIONS);
    }
  }
};

// We need to remove line breaks since Excel CSV import can't handle them properly with UTF-8 (having BOM)
// See https://stackoverflow.com/a/1251372
const cleanWhitespaceAndRemoveLineBreaks = content => {
  Object.keys(content).forEach(key => {
    if (typeof content[key] === 'string') {
      content[key] = content[key].replace(/\s+/gi, ' ').trim();
    } else if (typeof content[key] === 'object') {
      if (content[key] && uniq(Object.keys(content[key]).concat(['value', 'date'])).length === 2) {
        content[key] = isDate(content[key].value) ? content[key].value.toISOString() : content[key].value;
      } else {
        content[key] = JSON.stringify(content[key]);
      }
    }
  });
  return content;
};

export const downloadCsv = (selectedTab, contentOptions) => {
  const content = getSelectedTabCsvContent(selectedTab, contentOptions);
  if (content) {
    const filename = `${selectedTab.toLowerCase()}_export.csv`;
    downloadCsvFile(filename, content);
  }
};
