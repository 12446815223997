import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Infotip } from 'components';
import translations from 'decorators/Translations/translations';

const Column = styled.div``;
Column.displayName = 'Column';

const Value = styled.div`
  padding: var(--size-xxs);
  background-color: ${props => props.color};
  font-size: ${props => props.theme.font.size.xxs};
  text-align: center;

  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props => props.theme.media.landscape`
        width: 100%;
    `}
`;
Value.displayName = 'Value';

const StyledInfotip = styled(Infotip)`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: var(--size-md);
  width: calc(100% - var(--size-md));
  font-size: ${props => props.theme.fontSize.xxs};
`;

export const ValueColumn = ({ t, data }) => {
  const value =
    data.value === 'N/A' ? null : (
      <Value color={data.color}>
        {data.value}&#8287;{data.unit}
      </Value>
    );
  return (
    <Column>
      {data.infotip ? (
        <StyledInfotip absolute text={t(data.infotip)}>
          {value}
        </StyledInfotip>
      ) : (
        value
      )}
    </Column>
  );
};
ValueColumn.displayName = 'ValueColumn';

ValueColumn.defaultProps = {
  data: {},
};

ValueColumn.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
};

export default translations(ValueColumn);
