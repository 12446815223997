import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import { useTranslations } from 'decorators/Translations/translations';
import useNotification from 'decorators/Notification/useNotification';
import ServiceRequestForm, { serviceRequestFormPropTypes } from './ServiceRequestForm';

export const ServiceRequestFormWrapper = ({ notificationType, ...props }) => {
  const [t] = useTranslations();
  const { showNotification, renderedNotification } = useNotification();

  useEffect(() => {
    if (notificationType === 'error') {
      showNotification?.({ type: 'error', message: t('Creating a new service request failed!'), autoHide: true });
    }
    if (notificationType === 'success') {
      showNotification?.({
        type: 'success',
        message: t('Service request was created successfully!'),
        autoHide: true,
      });
    }
  }, [notificationType, t]);

  return (
    <>
      <Section>
        <SectionHeader noBorder title={t('Service Request')} />
        <ServiceRequestForm {...props} />
      </Section>
      {renderedNotification}
    </>
  );
};

ServiceRequestFormWrapper.propTypes = {
  notificationType: PropTypes.string,
  ...serviceRequestFormPropTypes,
};
