import { createReducerFromMapping } from 'redux/utils/index';
import assign from 'lodash/assign';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';
import MasterDataService from 'services/masterData';
import startOfHour from 'date-fns/startOfHour';
import { ANNOUNCEMENT_TYPE } from 'components/Announcement/utils';

const now = new Date();
const startOfCurrentHour = startOfHour(now);

export const MAX_ANNOUNCEMENTS = 60;

const initialState = {
  loading: false,
  menuOpen: false,
  byFL: {},
  general: [],
  personal: [],
  error: '',
};

export const TOGGLE_ANNOUNCEMENT_MENU = 'CUSTOMER_PLATFORM/Announcement/TOGGLE_ANNOUNCEMENT_MENU';

export const toggleAnnouncementMenu = () => async dispatch => await dispatch({ type: TOGGLE_ANNOUNCEMENT_MENU });

export const LOAD_ANNOUNCEMENT_BY_FL = 'CUSTOMER_PLATFORM/Announcement/LOAD_ANNOUNCEMENT_BY_FL';
export const LOAD_ANNOUNCEMENT_BY_FL_SUCCESS = 'CUSTOMER_PLATFORM/Announcement/LOAD_ANNOUNCEMENT_BY_FL_SUCCESS';
export const LOAD_ANNOUNCEMENT_BY_FL_FAIL = 'CUSTOMER_PLATFORM/Announcement/LOAD_ANNOUNCEMENT_BY_FL_FAIL';

export const loadAnnouncementsByFL = functionalLocation => {
  const queryParams = {
    expirationDate: { gt: startOfCurrentHour.toISOString() },
    functionalLocation,
    limit: MAX_ANNOUNCEMENTS,
  };
  return async dispatch => {
    dispatch({ type: LOAD_ANNOUNCEMENT_BY_FL });
    try {
      const result = await MasterDataService.announcements(queryParams);
      return dispatch({
        type: LOAD_ANNOUNCEMENT_BY_FL_SUCCESS,
        result: result.data,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_ANNOUNCEMENT_BY_FL_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_FL_ANNOUNCEMENT = 'CUSTOMER_PLATFORM/Announcement/UPDATE_FL_ANNOUNCEMENT';
export const UPDATE_FL_ANNOUNCEMENT_SUCCESS = 'CUSTOMER_PLATFORM/Announcement/UPDATE_FL_ANNOUNCEMENT_SUCCESS';
export const UPDATE_FL_ANNOUNCEMENT_FAIL = 'CUSTOMER_PLATFORM/Announcement/UPDATE_FL_ANNOUNCEMENT_FAIL';

export const updateFLAnnouncement = announcement => {
  return async dispatch => {
    dispatch({ type: UPDATE_FL_ANNOUNCEMENT });
    try {
      let result;
      if (announcement.id) {
        result = await MasterDataService.updateAnnouncement(announcement.id, announcement);
      } else {
        result = await MasterDataService.createAnnouncement(announcement);
      }
      dispatch({
        type: UPDATE_FL_ANNOUNCEMENT_SUCCESS,
        result: result.data,
      });
      return result;
    } catch (error) {
      dispatch({
        type: UPDATE_FL_ANNOUNCEMENT_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const DELETE_FL_ANNOUNCEMENT = 'CUSTMOER_PLATFORM/Announcement/DELETE_FL_ANNOUNCEMENT';
export const DELETE_FL_ANNOUNCEMENT_SUCCESS = 'CUSTMOER_PLATFORM/Announcement/DELETE_FL_ANNOUNCEMENT_SUCCESS';
export const DELETE_FL_ANNOUNCEMENT_FAIL = 'CUSTMOER_PLATFORM/Announcement/DELETE_FL_ANNOUNCEMENT_FAIL';

export const deleteAnnouncementByFL = (id, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DELETE_FL_ANNOUNCEMENT });
    try {
      const result = await MasterDataService.deleteAnnouncement(id);
      dispatch({
        type: DELETE_FL_ANNOUNCEMENT_SUCCESS,
        result,
        announcementId: id,
        functionalLocation,
      });
      return result;
    } catch (error) {
      dispatch({
        type: DELETE_FL_ANNOUNCEMENT_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const LOAD_GENERAL_ANNOUNCEMENT = 'CUSTOMER_PLATFORM/Announcement/LOAD_GENERAL_ANNOUNCEMENT';
export const LOAD_GENERAL_ANNOUNCEMENT_SUCCESS = 'CUSTOMER_PLATFORM/Announcement/LOAD_GENERAL_ANNOUNCEMENT_SUCCESS';
export const LOAD_GENERAL_ANNOUNCEMENT_FAIL = 'CUSTOMER_PLATFORM/Announcement/LOAD_GENERAL_ANNOUNCEMENT_FAIL';

export const loadAnnouncements = () => {
  const queryParams = {
    expirationDate: { gt: startOfCurrentHour.toISOString() },
    limit: MAX_ANNOUNCEMENTS,
  };
  return async dispatch => {
    dispatch({ type: LOAD_GENERAL_ANNOUNCEMENT });
    try {
      const result = await MasterDataService.announcements(queryParams);
      return dispatch({
        type: LOAD_GENERAL_ANNOUNCEMENT_SUCCESS,
        result: {
          general: result.data?.filter(item => item.type !== ANNOUNCEMENT_TYPE.PERSONAL),
          personal: result.data?.filter(item => item.type === ANNOUNCEMENT_TYPE.PERSONAL),
        },
      });
    } catch (error) {
      return dispatch({
        type: LOAD_GENERAL_ANNOUNCEMENT_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_GENERAL_ANNOUNCEMENT = 'CUSTOMER_PLATFORM/Announcement/UPDATE_GENERAL_ANNOUNCEMENT';
export const UPDATE_GENERAL_ANNOUNCEMENT_SUCCESS = 'CUSTOMER_PLATFORM/Announcement/UPDATE_GENERAL_ANNOUNCEMENT_SUCCESS';
export const UPDATE_GENERAL_ANNOUNCEMENT_FAIL = 'CUSTOMER_PLATFORM/Announcement/UPDATE_GENERAL_ANNOUNCEMENT_FAIL';

export const updateGeneralAnnouncement = announcement => {
  return async dispatch => {
    dispatch({ type: UPDATE_GENERAL_ANNOUNCEMENT });
    try {
      let result;
      if (announcement.id) {
        result = await MasterDataService.updateAnnouncement(announcement.id, announcement);
      } else {
        result = await MasterDataService.createAnnouncement(announcement);
      }
      return dispatch({
        type: UPDATE_GENERAL_ANNOUNCEMENT_SUCCESS,
        result: result.data,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_GENERAL_ANNOUNCEMENT_FAIL,
        error,
      });
    }
  };
};

export const DELETE_GENERAL_ANNOUNCEMENT = 'CUSTMOER_PLATFORM/Announcement/DELETE_GENERAL_ANNOUNCEMENT';
export const DELETE_GENERAL_ANNOUNCEMENT_SUCCESS = 'CUSTMOER_PLATFORM/Announcement/DELETE_GENERAL_ANNOUNCEMENT_SUCCESS';
export const DELETE_GENERAL_ANNOUNCEMENT_FAIL = 'CUSTMOER_PLATFORM/Announcement/DELETE_GENERAL_ANNOUNCEMENT_FAIL';

export const deleteGeneralAnnouncement = id => {
  return async dispatch => {
    dispatch({ type: DELETE_GENERAL_ANNOUNCEMENT });
    try {
      const result = await MasterDataService.deleteAnnouncement(id);
      return dispatch({
        type: DELETE_GENERAL_ANNOUNCEMENT_SUCCESS,
        result,
        announcementId: id,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_GENERAL_ANNOUNCEMENT_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [TOGGLE_ANNOUNCEMENT_MENU]: state => ({ ...state, menuOpen: !state.menuOpen }),
    [LOAD_ANNOUNCEMENT_BY_FL]: (state, action) => ({ ...state, loading: true }),
    [LOAD_ANNOUNCEMENT_BY_FL_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_ANNOUNCEMENT_BY_FL_SUCCESS]: (state, action) => {
      const byFL = assign({}, state.byFL, groupBy(action.result, 'functionalLocation'));
      return {
        ...state,
        byFL,
        loading: false,
      };
    },
    [UPDATE_FL_ANNOUNCEMENT]: (state, action) => ({ ...state, loading: true }),
    [UPDATE_FL_ANNOUNCEMENT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_FL_ANNOUNCEMENT_SUCCESS]: (state, action) => {
      const updatedAnnoucement = assign({}, action.result);
      const byFLArray = state.byFL[action.result.functionalLocation] || [];
      const index = findIndex(byFLArray, ['id', updatedAnnoucement.id]);
      // if index was not found, create new announcement
      if (index < 0) {
        byFLArray.push(updatedAnnoucement);
      } else {
        // else update existing one
        byFLArray[index] = updatedAnnoucement;
      }

      return {
        ...state,
        byFL: {
          ...state.byFL,
          [updatedAnnoucement.functionalLocation]: [...byFLArray],
        },
        loading: false,
      };
    },
    [DELETE_FL_ANNOUNCEMENT]: state => ({ ...state, loading: true }),
    [DELETE_FL_ANNOUNCEMENT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [DELETE_FL_ANNOUNCEMENT_SUCCESS]: (state, action) => {
      const { result, announcementId, functionalLocation } = action;
      if (result && result.affected === 1) {
        const flAnnouncements = reject(state.byFL[functionalLocation], { id: announcementId });
        return {
          ...state,
          byFL: { ...state.byFL, [functionalLocation]: flAnnouncements },
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    },
    [LOAD_GENERAL_ANNOUNCEMENT]: state => ({ ...state, loading: true }),
    [LOAD_GENERAL_ANNOUNCEMENT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_GENERAL_ANNOUNCEMENT_SUCCESS]: (state, action) => {
      const general = action.result.general || [];
      const personal = action.result.personal || [];
      return {
        ...state,
        general,
        personal,
        loading: false,
      };
    },
    [UPDATE_GENERAL_ANNOUNCEMENT]: (state, action) => ({ ...state, loading: true }),
    [UPDATE_GENERAL_ANNOUNCEMENT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_GENERAL_ANNOUNCEMENT_SUCCESS]: (state, action) => {
      const updatedAnnoucement = assign({}, action.result);
      const general = state.general;
      const index = findIndex(general, ['id', updatedAnnoucement.id]);
      general[index] = updatedAnnoucement;
      // if index was not found, create new announcement
      if (index < 0) {
        general.push(updatedAnnoucement);
      } else {
        // else update existing one
        general[index] = updatedAnnoucement;
      }

      return {
        ...state,
        general,
      };
    },

    [DELETE_GENERAL_ANNOUNCEMENT]: state => ({ ...state, loading: true }),
    [DELETE_GENERAL_ANNOUNCEMENT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [DELETE_GENERAL_ANNOUNCEMENT_SUCCESS]: (state, action) => {
      const { result, announcementId } = action;
      if (result && result.affected === 1) {
        const general = reject(state.general, { id: announcementId });
        return {
          ...state,
          general,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    },
  },
  initialState
);
