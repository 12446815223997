import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

import Loader from 'components/Loader/Loader';

type SpinnerOverlayProps = {
  fixed?: boolean;
};

const Overlay = styled.div<SpinnerOverlayProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => transparentize(0.3, props.theme.colors.white)};
  z-index: ${props => props.theme.zIndex('modal')};

  ${props => props.theme.media.landscape`
    position: ${() => !props.fixed && 'absolute'};
  `}
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.font.size.lg};
  color: ${props => props.theme.colors.black};
  text-align: center;
`;

const LoaderWrapper = styled.div`
  margin-bottom: var(--size-lg);
`;

const SpinnerOverlay = ({ children, fixed }: React.PropsWithChildren<SpinnerOverlayProps>) => (
  <Overlay fixed={fixed}>
    <Content>
      <div>
        <LoaderWrapper>
          <Loader color="BLACK" size="LARGE" />
        </LoaderWrapper>
        {children}
      </div>
    </Content>
  </Overlay>
);

export default SpinnerOverlay;
