import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MasterDataService from 'services/masterData';
import translations from 'decorators/Translations/translations';
import withNotification from 'decorators/Notification/withNotification';

import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import PublicServiceRequestForm from './PublicServiceRequestForm';
import SuccessPage from 'components/SuccessPage/SuccessPage';
import SuccessPageButtons from 'components/SuccessPage/SuccessPageButtons';
import SuccessPageButton from 'components/SuccessPage/SuccessPageButton';
import PrimaryButton from 'components/Button/PrimaryButton';

import { getLocationTitle, guessDivisionForPhoneNumberField } from './utils';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import { useAnonymousFunctionalLocations } from './useAnonymousFunctionalLocations';

const SuccessText = styled.p`
  margin: var(--size-xl) 0;
  text-align: center;

  ${props => props.theme.media.landscape`
    text-align: left;
  `}
`;

const SUBMIT_STATUS = {
  INITIAL: 0,
  SUBMITTING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export const PublicServiceRequest = props => {
  const { t, publicView, showNotification, language } = props;
  const [status, setStatus] = useState(SUBMIT_STATUS.INITIAL);
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const { functionalLocations, loading: loadingFunctionalLocations } = useAnonymousFunctionalLocations(publicView);

  const submit = useCallback(
    (serviceRequest, attachments) => {
      setStatus(SUBMIT_STATUS.SUBMITTING);
      dispatch(
        MasterDataService.createServiceOrderRequestAsAnonymousUser({
          serviceRequest: { ...serviceRequest, requestedByEmail: serviceRequest.requestedByEmail?.trim(), attachments },
          publicView,
          language,
        })
      )
        .then(() => setStatus(SUBMIT_STATUS.SUCCESS))
        .catch(() => {
          setStatus(SUBMIT_STATUS.ERROR);
          showNotification({ type: 'error', message: t('Save Failed'), autoHide: true });
        });
    },
    [publicView, language, showNotification, t, dispatch]
  );

  if (!publicView) {
    return null;
  }

  if (status === SUBMIT_STATUS.SUCCESS) {
    if (breakpoints.landscape) {
      return (
        <Section>
          <SectionHeader noBorder title={t('Thank you!')} />
          <SuccessText>{t('Service request was sent succesfully')}</SuccessText>
          <PrimaryButton add large onClick={() => setStatus(SUBMIT_STATUS.INITIAL)}>
            {t('Create new')}
          </PrimaryButton>
        </Section>
      );
    }
    return (
      <SuccessPage message={t('Service request successfully sent for approval.')}>
        <SuccessPageButtons>
          <SuccessPageButton onClick={() => setStatus(SUBMIT_STATUS.INITIAL)}>{t('Create new')}</SuccessPageButton>
        </SuccessPageButtons>
      </SuccessPage>
    );
  }

  return (
    <PublicServiceRequestForm
      t={t}
      onSubmit={submit}
      locationTitle={getLocationTitle(publicView)}
      division={guessDivisionForPhoneNumberField(publicView, language)}
      submitting={status === SUBMIT_STATUS.SUBMITTING}
      functionalLocations={functionalLocations}
      loadingFunctionalLocations={loadingFunctionalLocations}
    />
  );
};

PublicServiceRequest.propTypes = {
  t: PropTypes.func.isRequired,
  publicView: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
  language: PropTypes.string,
};

export default translations(withNotification(PublicServiceRequest));
