import styled from 'styled-components';

const NotificationPositioner = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  ${props => props.theme.media.landscape`
    right: var(--size-md);
    bottom: var(--size-md);
    left: auto;
  `}

  z-index: ${props => props.theme.zIndex('snackbarError')}
`;

export default NotificationPositioner;
