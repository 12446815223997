import React from 'react';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading/Loading';
import FadeTransition from 'components/FadeTransition/FadeTransition';

import { RootState } from 'types/Redux';

const GeneralLoading = () => {
  const loading = useSelector<RootState>(state => state.generalLoading.loading);
  const splash = useSelector<RootState>(state => state.generalLoading.splash);

  if (loading) {
    return (
      <FadeTransition>
        <Loading showLogo={false} />
      </FadeTransition>
    );
  } else if (splash) {
    return (
      <FadeTransition>
        <Loading />
      </FadeTransition>
    );
  }
  return null;
};

export default GeneralLoading;
