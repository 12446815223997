import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import { useHistory } from 'react-router';
import sortBy from 'lodash/sortBy';

import DialogModal from 'components/Dialog/DialogModal';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';

const RadioButtons = styled.div`
  margin: ${props => props.theme.section.verticalSpacing} 0;
`;

const SelectMissingPartner = ({ t, onClose, link, customers }) => {
  const [selectedPartner, setSelectedPartner] = React.useState();
  const history = useHistory();
  const customerOptions = sortBy(
    customers.map(customer => ({ label: customer.name, value: customer.partnerNumber })),
    'label'
  );

  const handleConfirm = () => {
    if (selectedPartner) {
      const fixedLink = `/${selectedPartner}/${link.split('/').slice(2).join('/')}`; // replace partner part in link
      history.push(fixedLink);
    }
  };

  return (
    <DialogModal
      animate
      small
      isActive
      onOverlayClick={onClose}
      t={t}
      actions={{
        cancel: {
          onClick: onClose,
        },
        confirm: {
          onClick: handleConfirm,
          disabled: !selectedPartner,
          label: t('Select'),
        },
      }}
    >
      <section>
        <h3>{t('Select portfolio')}</h3>
        <RadioButtons>
          <RadioButtonGroup
            name="missing-partner-number-select"
            value={selectedPartner}
            onChange={(_, value) => setSelectedPartner(value)}
          >
            {customerOptions.map(({ label, value }) => (
              <RadioButton key={value} label={label} value={value} />
            ))}
          </RadioButtonGroup>
        </RadioButtons>
      </section>
    </DialogModal>
  );
};

SelectMissingPartner.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default translations(SelectMissingPartner);
