import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundaryFallback from 'components/ErrorPage/ErrorBoundaryFallback';
import RecyclingRatesBreakdownSkeleton from 'containers/Application/Modules/RecyclingModule/RecyclingRatesBreakdown/RecyclingRatesBreakdownSkeleton';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import RecyclingRatesBreakdownTab from 'containers/Application/Modules/RecyclingModule/RecyclingRatesBreakdown/RecyclingRatesBreakdownTab/RecyclingRatesBreakdownTab';
import withQuery from 'decorators/Query/withQuery';
import translations from 'decorators/Translations/translations';
import connect from 'containers/Application/Modules/RecyclingModule/RecyclingRatesBreakdown/connectRecyclingRatesBreakdown';
import recyclingBuildingLevelTabs from 'containers/Application/Modules/RecyclingModule/tabs';
import { withRouter } from 'react-router-dom';
import { RATES_TIMEFRAME } from 'constants/recycling';

export const RECYCLING_RATES_BREAKDOWN_ELEMENT_ID = 'rates-breakdown';

export const RecyclingRatesBreakdown = ({
  t,
  functionalLocationId,
  wasteSensors,
  syntheticSensors,
  loadRatesBreakdownData,
  loadRatesBreakdownTotals,
  dataStatus,
  query,
  setQuery,
}) => {
  React.useEffect(() => {
    if (dataStatus.needsReload) {
      loadRatesBreakdownData(
        {
          functionalLocationId,
          sensorIds: [
            ...wasteSensors.map(wasteSensor => wasteSensor.id),
            ...syntheticSensors.map(syntheticSensor => syntheticSensor.id),
          ],
        },
        RATES_TIMEFRAME
      );
      loadRatesBreakdownTotals(
        {
          functionalLocationId,
          sensorIds: syntheticSensors.map(syntheticSensor => syntheticSensor.id),
        },
        RATES_TIMEFRAME
      );
    }
  }, [dataStatus.needsReload]);

  if (dataStatus.errors.some(error => error.status)) {
    return <ErrorBoundaryFallback />;
  }
  if (dataStatus.isLoading) {
    return <RecyclingRatesBreakdownSkeleton />;
  }
  const ratesTabs = recyclingBuildingLevelTabs.ratesTabs;
  const selectedTab = query?.rateTab || ratesTabs.RECYCLING_RATE.id;

  return (
    <Section>
      <ScrollToComponent id={RECYCLING_RATES_BREAKDOWN_ELEMENT_ID} />
      <SectionHeader>
        <SectionTabSelector
          options={Object.keys(ratesTabs).map(key => ({
            value: ratesTabs[key].id,
            label: t(ratesTabs[key].label),
          }))}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(property, value) => {
            setQuery({
              rateTab: value,
              scrollTo: RECYCLING_RATES_BREAKDOWN_ELEMENT_ID,
            });
          }}
        />
      </SectionHeader>
      {Object.keys(ratesTabs).map(key => (
        <RecyclingRatesBreakdownTab key={key} tab={ratesTabs[key]} visible={selectedTab === ratesTabs[key].id} />
      ))}
    </Section>
  );
};

RecyclingRatesBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  syntheticSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  loadRatesBreakdownData: PropTypes.func,
  loadRatesBreakdownTotals: PropTypes.func,
  dataStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.bool.isRequired,
        message: PropTypes.string,
      }).isRequired
    ).isRequired,
    needsReload: PropTypes.bool.isRequired,
  }).isRequired,
  query: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
};

export default withRouter(withQuery(translations(connect(RecyclingRatesBreakdown))));
