import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import highchartsBorderRadius from 'highcharts-border-radius';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsAnnotations from 'highcharts/modules/annotations';
import highchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsIndicators from 'highcharts/indicators/indicators';
import highchartsIndicatorsTrendline from 'highcharts/indicators/trendline';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import includes from 'lodash/includes';

import ErrorPage from './ErrorPage/ErrorPage';
import TermsOfServiceModal from './ToS/Modal';
import PartnerRedirect from 'containers/PartnerRedirect/PartnerRedirect';
import Partner from 'containers/Application/Partner/Partner';
import Admin from 'containers/Application/Admin';
import Profile from 'containers/Application/Profile/Profile';
import LogoutSession from 'containers/Application/Authorization/LogoutSession';
import NoProfileFound from 'containers/Application/User/NoProfileFound';
import UserManualV1 from 'containers/Application/Prismic/UserManualV1/UserManual';
import AdminUserManual from 'containers/Application/Prismic/AdminUserManual/AdminUserManual';
import Roadmap from 'containers/Application/Prismic/Roadmap/Roadmap';
import UserManual from 'containers/Application/Prismic/UserManual/UserManual';
import UserManualPreview from 'containers/Application/Prismic/UserManual/UserManualPreview';
import SensorMetaDataMapping from './SensorMetaDataMapping/SensorMetaDataMapping';
import Giosg from 'components/Giosg/Giosg';
import Header from 'containers/Application/Header/Header';
import Navigation from 'containers/Application/Navigation/Navigation';

import { stripSensitiveInformationFromUrl, pushToDataLayer } from 'utils/Analytics/analytics';
import { setGeneralLoading, setSplashLoading } from 'redux/modules';
import ProfileService from 'services/profile';
import translations from 'decorators/Translations/translations';
import { downloadSymbol, commentSymbol, getChartTranslations } from 'components/Charts/utils';
import themes from 'styles/themes';
import { loadApplication } from 'redux/modules/containers/application';
import { hasAccessToAdminRoute } from 'utils/Data/profileData';
import { getNavigationItems } from 'containers/Application/Navigation/NavigationItems';
import LocalizationContext from 'contexts/LocalizationContext';
import { reportErrorToNewRelic } from 'utils/newRelic';

highchartsBorderRadius(Highcharts);
highchartsExporting(Highcharts);
highchartsExporting(Highstock);
highchartsExportData(Highstock);
highchartsExportData(Highcharts);
highchartsAnnotations(Highcharts);
highchartsOfflineExporting(Highcharts);
highchartsOfflineExporting(Highstock);
highchartsIndicators(Highstock);
highchartsIndicatorsTrendline(Highstock);
highchartsAccessibility(Highstock);
Highcharts.SVGRenderer.prototype.symbols.download = downloadSymbol;
Highcharts.SVGRenderer.prototype.symbols.comment = commentSymbol;

class Application extends Component {
  static contextType = LocalizationContext;

  constructor(props) {
    super(props);
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'Cairo, sans-serif',
        },
      },
      lang: getChartTranslations(props.t),
    });
    this.state = {
      showError: false,
    };
    this.pageTitle = '';
  }
  userId = null;

  componentDidMount() {
    const {
      location: { pathname },
      history,
      loadApplication,
    } = this.props;

    loadApplication(pathname).then(result => {
      if (result) {
        this.context.updateTranslations({ language: result.language, translations: result.translations });
      }
    });

    this.pingTimer = setInterval(ProfileService.ping, 5 * 60 * 1000);
    if (pathname !== '/login') {
      this.props.setGeneralLoading(false);
      this.props.setSplashLoading(true);
    } else {
      this.props.setSplashLoading(false);
      this.props.setGeneralLoading(true);
    }
    // Listen to history changes and create pageview events
    history.listen((location, action) => {
      // Don't create page view if redirecting
      if (action !== 'REPLACE') {
        this.createPageviewEvent();
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.pingTimer);
    if (this.pageRefreshTimer) {
      clearInterval(this.pageRefreshTimer);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ showError: true });
    reportErrorToNewRelic(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    const { loading, activePartner, profile, location } = this.props;
    if (loading === false) {
      this.props.setGeneralLoading(false);
      this.props.setSplashLoading(false);
    }
    // Create first page view after activePartner is set
    if (!isNil(activePartner) && isNil(prevProps.activePartner)) {
      this.createPageviewEvent();
    }
    if (profile?.id && !this.userId) {
      pushToDataLayer({ userId: profile.id });
      pushToDataLayer({ userRole: profile.role });
      pushToDataLayer({ userFeatures: profile.features });
      this.userId = profile.id;
    }

    const urlHasRefreshParam = location?.search?.includes('refresh=');
    const shouldAutoRefresh = profile?.usedForInfoscreen || urlHasRefreshParam;
    if (shouldAutoRefresh && !this.pageRefreshTimer) {
      const refreshIntervalMinutes = urlHasRefreshParam ? parseInt(location?.search?.split('refresh=')[1], 10) : 60;
      if (Number.isFinite(refreshIntervalMinutes)) {
        this.pageRefreshTimer = setTimeout(() => {
          window.location.reload();
        }, refreshIntervalMinutes * 60 * 1000);
      }
    }
  }

  createPageviewEvent() {
    const self = this;
    // Hacky way to wait for pagetitle
    setTimeout(() => {
      const { activePartner } = self.props;
      const eventData = {
        event: 'pageview',
        pageTitle: this.pageTitle,
        pagePath: stripSensitiveInformationFromUrl(window.location.href),
        partnerNumber: activePartner,
      };
      pushToDataLayer(eventData);
      // If there is active partner, create partner-pageview event with same data
      if (activePartner && activePartner !== 'all') {
        pushToDataLayer({
          ...eventData,
          event: `partner-pageview-${activePartner}`,
        });
      }
    }, 500);
  }

  handlePageView = newState => {
    if (newState && newState.title && newState.title !== this.pageTitle) {
      this.pageTitle = newState.title;
    }
  };

  render() {
    const {
      t,
      error,
      loading,
      location: { pathname },
      profile,
      activePartner,
    } = this.props;
    const { showError } = this.state;
    // Show general loading indicator in index.js when the authorization is in progress.
    if (loading) {
      return null;
    }

    // Show error page if there is an error in authentication
    if (showError) {
      return (
        <ThemeProvider theme={themes.customerPlatform}>
          <Fragment>
            <ErrorPage />
          </Fragment>
        </ThemeProvider>
      );
    }

    // Show different error page if there is an error loading profile data (except if it's logout route).
    if (error && pathname !== '/Logout') {
      return <NoProfileFound />;
    }

    const navigationItems = getNavigationItems(activePartner, profile, t);
    const showAdminManual = navigationItems.find(item => item.linkTo === '/AdminUserManual')?.show;
    const showRoadmap = navigationItems.find(item => item.linkTo === '/Roadmap')?.show;
    const pathsWithHeader = ['/UserManual', '/AdminUserManual', '/DataMapping', '/Roadmap', '/UserManualV1'];
    const showHeader = some(pathsWithHeader, pathWithHeader => includes(pathname, pathWithHeader));

    return (
      <ThemeProvider theme={themes.customerPlatform}>
        <Fragment>
          <Helmet titleTemplate="%s - Caverion SmartView" onChangeClientState={this.handlePageView} />
          <TermsOfServiceModal />
          {showHeader && <Header />}
          <Navigation />
          <Switch>
            <Route exact path="/UserManual" component={UserManual} />
            <Route exact path="/UserManual/preview" component={UserManual} />
            <Route exact path="/UserManualPreview" component={UserManualPreview} />
            <Route path="/UserManualV1" component={UserManualV1} />
            <Route path="/AdminUserManual" component={showAdminManual ? AdminUserManual : PartnerRedirect} />
            <Route path="/Roadmap" component={showRoadmap ? Roadmap : PartnerRedirect} />
            <Route exact path="/" component={PartnerRedirect} />
            <Route exact path="/index.html" component={PartnerRedirect} />
            <Route exact path="/Overview" component={PartnerRedirect} />
            <Route path="/Admin" component={hasAccessToAdminRoute(profile) ? Admin : PartnerRedirect} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/DataMapping" component={SensorMetaDataMapping} />
            <Route exact path="/Logout" component={LogoutSession} />
            <Route path="/:partnerNumber" component={Partner} />
          </Switch>
          {profile.role && <Giosg />}
        </Fragment>
      </ThemeProvider>
    );
  }
}

Application.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  profile: PropTypes.object,
  activePartner: PropTypes.string,
  loadApplication: PropTypes.func.isRequired,
  setGeneralLoading: PropTypes.func.isRequired,
  setSplashLoading: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  firstLoad: state.application.firstLoad,
  loading: state.application.loading,
  error: state.application.error,
  profile: state.profile.profile,
  activePartner: state.profile.activePartner,
  activeFunctionalLocationId: state.common.activeFunctionalLocationId,
});

const mapDispatchToProps = {
  loadApplication,
  setGeneralLoading,
  setSplashLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(translations(connector(Application)));
