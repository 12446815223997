import isEmpty from 'lodash/isEmpty';
import { combineReducers } from 'redux';
import { createActions, createReducer } from 'redux/utils/reducerUtils';
import IoTService from 'services/iot';
import startOfYear from 'date-fns/startOfYear';
import { startOfUTCDay } from 'utils/Date/date';
import endOfYear from 'date-fns/endOfYear';
import { endOfUTCDay } from 'utils/Date/date';
import subYears from 'date-fns/subYears';
import { getFilterForSensorsValues } from './sensor_values';

const dateNow = new Date();

const loadIoTData = (
  dispatch,
  { partnerNumber, functionalLocationId, sensorIds },
  { aggregations, startTime, endTime },
  { load, fail, success },
  metaAdditions
) => {
  const meta = { partnerNumber, functionalLocationId, sensorIds, ...metaAdditions };
  dispatch(load({ payload: null, meta }));
  if (isEmpty(sensorIds)) {
    return Promise.resolve(dispatch(fail({ payload: new Error('No sensors to fetch data for'), meta })));
  }
  const filters = aggregations.map(aggregation =>
    getFilterForSensorsValues(sensorIds, startTime, endTime, aggregation)
  );
  return Promise.all(filters.map(filter => IoTService.sensorValuesFind(filter))).then(
    responses => dispatch(success({ payload: responses.flatMap(response => response.data), meta })),
    error => dispatch(fail({ payload: error, meta }))
  );
};

const OPIDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_OPI_DATA');
const amountsBreakdownDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_AMOUNTS_BREAKDOWN_DATA');
const amountsBreakdownTotalsActions = createActions(
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_AMOUNTS_BREAKDOWN_TOTALS_DATA'
);
const amountsBreakdownMonthliesActions = createActions(
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_AMOUNTS_BREAKDOWN_MONTHLIES_DATA'
);
const amountsBreakdownMonthliesTotalsActions = createActions(
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_AMOUNTS_BREAKDOWN_MONTHLIES_TOTALS_DATA'
);
const ratesBreakdownDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_RATES_BREAKDOWN_DATA');
const ratesBreakdownTotalsActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_RATES_BREAKDOWN_TOTALS');
const summaryDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_SUMMARY_DATA');
const benchmarkDataActions = createActions('CUSTOMER_PLATFORM/IoT_Values/LOAD_RECYCLING_BENCHMARK_DATA');

export const loadOpiData = args => dispatch => {
  // data in db is on timestamp 1970-01-01T00:00:00.000Z
  const epochTime = new Date(0).toISOString();
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: [
        'recyclingOpiWasteAmount',
        'recyclingOpiRecyclingRate',
        'recyclingOpiRecyclingRateDelta',
        'recyclingOpiRecoveryRate',
        'recyclingOpiRecoveryRateDelta',
        'recyclingOpiCO2e',
      ],
      startTime: epochTime,
      endTime: epochTime,
    },
    OPIDataActions
  );
};

export const loadAmountsBreakdownData = (args, selectedTimeframeCategory, selectedTimeframe) => dispatch => {
  let aggregations;
  switch (selectedTimeframeCategory) {
    case 'month':
      aggregations = ['monthlySum', 'recyclingMonthlyCO2e', 'monthlyCO2e'];
      break;
    case 'quarter':
      aggregations = ['quarterlySum', 'recyclingQuarterlyCO2e'];
      break;
    case 'year':
      aggregations = ['yearlySum', 'recyclingYearlyCO2e', 'yearlyCO2e'];
      break;
    default:
      aggregations = ['yearlySum', 'recyclingYearlyCO2e', 'yearlyCO2e'];
  }
  return loadIoTData(
    dispatch,
    args,
    {
      aggregations,
      startTime: startOfUTCDay(selectedTimeframe.startTime).toISOString(),
      endTime: endOfUTCDay(selectedTimeframe.endTime).toISOString(),
    },
    amountsBreakdownDataActions,
    { selectedTimeframeCategory, selectedTimeframe: selectedTimeframe }
  );
};

export const loadAmountsBreakdownTotals = (args, selectedTimeframeCategory, selectedTimeframe) => dispatch => {
  let aggregations;
  switch (selectedTimeframeCategory) {
    case 'month':
      aggregations = ['recyclingMonthlyWasteAmount', 'recyclingMonthlyRecyclableSum', 'recyclingMonthlyCO2eSum'];
      break;
    case 'quarter':
      aggregations = ['recyclingQuarterlyWasteAmount', 'recyclingQuarterlyRecyclableSum', 'recyclingQuarterlyCO2eSum'];
      break;
    case 'year':
      aggregations = ['recyclingYearlyWasteAmount', 'recyclingYearlyRecyclableSum', 'recyclingYearlyCO2eSum'];
      break;
    default:
      aggregations = ['recyclingMonthlyWasteAmount', 'recyclingMonthlyRecyclableSum', 'recyclingYearlyCO2eSum'];
  }
  loadIoTData(
    dispatch,
    args,
    {
      aggregations,
      startTime: startOfUTCDay(selectedTimeframe.startTime).toISOString(),
      endTime: endOfUTCDay(selectedTimeframe.endTime).toISOString(),
    },
    amountsBreakdownTotalsActions,
    { selectedTimeframeCategory, selectedTimeframe: selectedTimeframe }
  );
};

export const loadAmountsBreakdownMonthlies = (args, timeframe) => dispatch => {
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['monthlySum', 'recyclingMonthlyCO2e'],
      startTime: startOfUTCDay(timeframe.startTime).toISOString(),
      endTime: endOfUTCDay(timeframe.endTime).toISOString(),
    },
    amountsBreakdownMonthliesActions,
    { selectedTimeframe: timeframe }
  );
};

export const loadAmountsBreakdownMonthliesTotals = (args, timeframe) => dispatch => {
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['yearlySum', 'recyclingYearlyCO2e'],
      startTime: startOfUTCDay(timeframe.startTime).toISOString(),
      endTime: endOfUTCDay(timeframe.endTime).toISOString(),
    },
    amountsBreakdownMonthliesTotalsActions,
    { selectedTimeframe: timeframe }
  );
};

export const loadRatesBreakdownData = (args, timeframe) => dispatch => {
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['monthlySum', 'recyclingMonthlyRecyclingRate', 'recyclingMonthlyRecoveryRate'],
      startTime: startOfUTCDay(timeframe.startTime).toISOString(),
      endTime: endOfUTCDay(timeframe.endTime).toISOString(),
    },
    ratesBreakdownDataActions
  );
};

export const loadRatesBreakdownTotals = (args, timeframe) => dispatch => {
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['recyclingYearlyRecyclingRate', 'recyclingYearlyRecoveryRate'],
      startTime: startOfUTCDay(timeframe.startTime).toISOString(),
      endTime: endOfUTCDay(timeframe.endTime).toISOString(),
    },
    ratesBreakdownTotalsActions
  );
};

export const loadSummaryData = args => dispatch => {
  const startTime = startOfUTCDay(startOfYear(subYears(dateNow, args.yearCount - 1)));
  const endTime = endOfUTCDay(endOfYear(dateNow));
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['yearlySum', 'recyclingYearlyCO2e', 'recyclingYearlyRecyclingRate', 'recyclingYearlyRecoveryRate'],
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    },
    summaryDataActions
  );
};

export const loadBenchmarkData = args => dispatch => {
  // data in db is on timestamp 1970-01-01T00:00:00.000Z
  const epochTime = new Date(0).toISOString();
  loadIoTData(
    dispatch,
    args,
    {
      aggregations: ['recyclingOpiRecyclingRate', 'recyclingOpiRecoveryRate', 'recyclingOpiCO2e'],
      startTime: epochTime,
      endTime: epochTime,
    },
    benchmarkDataActions
  );
};

export default combineReducers({
  opi: createReducer(OPIDataActions),
  amountsBreakdown: createReducer(amountsBreakdownDataActions),
  amountsBreakdownTotals: createReducer(amountsBreakdownTotalsActions),
  amountsBreakdownMonthlies: createReducer(amountsBreakdownMonthliesActions),
  amountsBreakdownMonthliesTotals: createReducer(amountsBreakdownMonthliesTotalsActions),
  ratesBreakdown: createReducer(ratesBreakdownDataActions),
  ratesBreakdownTotals: createReducer(ratesBreakdownTotalsActions),
  summary: createReducer(summaryDataActions),
  benchmark: createReducer(benchmarkDataActions),
});
