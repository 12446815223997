import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import WasteChart from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteChart/WasteChart';
import WasteTimeframeSelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeSelector/WasteTimeframeSelector';
import WasteTimeframeCategorySelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTimeframeCategorySelector/WasteTimeframeCategorySelector';
import translations from 'decorators/Translations/translations';
import BreakdownTotals from 'components/BreakdownTotals/BreakdownTotals';
import connect from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownAllWasteTypes/connectBreakdownAllWasteTypes';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';

const transformData = (t, wasteSensors, tab, breakdownData) => {
  const series = tab.createSeries(breakdownData, wasteSensors);
  return {
    ...series,
    categories: series.categories.map(category => ({
      ...category,
      name: t(category.name),
    })),
    series: series.series.map(entry => ({
      ...entry,
      wasteType: t(entry.wasteType),
      unit: t(entry.unit),
    })),
  };
};

const transformTotals = (t, tab, breakdownTotals) => {
  const totals = tab.createTotals(breakdownTotals);
  return {
    ...totals,
    title: t(totals.title),
    unit: t(totals.unit),
    perCategory: totals.perCategory.map(entry => ({
      ...entry,
      categoryName: t(entry.categoryName),
      unit: t(entry.unit),
    })),
  };
};

export const BreakdownAllWasteTypes = ({
  t,
  theme,
  profile,
  wasteSensors,
  tab,
  timeframeCategoryOptions,
  selectedTimeframeCategory,
  onTimeframeCategoryChange,
  selectedTimeframe,
  selectedTimeframeDescription,
  onTimeframeChange,
  breakdownData,
  breakdownTotals,
  selectSensor,
}) => {
  const transformedData = useMemo(
    () => transformData(t, wasteSensors, tab, breakdownData),
    [t, wasteSensors, breakdownData, tab]
  );
  const transformedTotals = useMemo(() => transformTotals(t, tab, breakdownTotals), [t, breakdownTotals, tab]);
  const handleBarClick = React.useCallback(event => selectSensor(event.point.sensorId), [selectSensor]);
  return (
    <Columns justifyContent="space-between">
      <Column columnWidth={{ desktop: 9, default: 12 }}>
        <WasteChart profile={profile} data={transformedData} onValueBarClick={handleBarClick} />
        <WasteTimeframeSelector
          selectedTimeframeCategory={selectedTimeframeCategory}
          selectedTimeframe={selectedTimeframe}
          onTimeframeChange={onTimeframeChange}
          selectedTimeframeDescription={selectedTimeframeDescription}
        />
      </Column>
      <Column columnWidth={{ desktop: 3, default: 12 }}>
        <WasteTimeframeCategorySelector
          timeframeCategoryOptions={timeframeCategoryOptions}
          selectedTimeframeCategory={selectedTimeframeCategory}
          onTimeframeCategoryChange={onTimeframeCategoryChange}
        />
        {transformedTotals?.total ? (
          <BreakdownTotals
            header={transformedTotals?.title}
            label={t(selectedTimeframeDescription)}
            icon={tab.totalsIconName}
            value={transformedTotals?.total}
            unit={transformedTotals?.unit}
            language={profile.language}
            positiveValueColor={theme.colors?.black}
            categories={transformedTotals?.perCategory.map(category => ({
              ...category,
              color: theme.charts?.colorsRecycling[category.colorName],
            }))}
          />
        ) : null}
      </Column>
    </Columns>
  );
};

BreakdownAllWasteTypes.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.object.isRequired,
  timeframeCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  onTimeframeCategoryChange: PropTypes.func.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  selectedTimeframeDescription: PropTypes.string.isRequired,
  onTimeframeChange: PropTypes.func.isRequired,
  breakdownData: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  breakdownTotals: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  selectSensor: PropTypes.func.isRequired,
};

export default withRouter(withTheme(translations(connect(BreakdownAllWasteTypes))));
