import React, { Component } from 'react';
import PropTypes from 'prop-types';
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import isEqual from 'lodash/isEqual';

import withQuery from 'decorators/Query/withQuery';

smoothscrollPolyfill.polyfill();

class ScrollToComponent extends Component {
  lastScroll = 0;
  topOffset = 160;
  scrollNode = React.createRef();

  componentDidMount() {
    this.shouldScroll() && this.scrollToComponent();
  }

  shouldComponentUpdate(nextProps) {
    if (this.isScrolledToBottom() && this.getComponentTopInViewport() > 0) {
      return false;
    }
    // It is possible to user to want to scroll back to same position after
    // staying and scrolling around on the same page. Props are still the same,
    // but we should scroll again if there is more than 2500ms from previous scroll
    if (isEqual(this.props, nextProps)) {
      return this.lastScroll < Date.now() - 2500;
    }
    return true;
  }

  componentDidUpdate() {
    this.shouldScroll() && this.scrollToComponent();
  }

  shouldScroll = () => {
    const { query, id } = this.props;
    if (id) {
      return query.scrollTo === id;
    }
    return true;
  };

  isScrolledToBottom() {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  }

  getComponentTopInViewport() {
    if (!this.scrollNode.current) {
      return 0;
    }
    return this.scrollNode.current.getBoundingClientRect().top;
  }

  scrollToComponent() {
    const { setQuery } = this.props;
    setTimeout(() => {
      window.scroll({
        top: this.getComponentTopInViewport() - this.topOffset + window.scrollY,
        left: 0,
        behavior: 'smooth',
      });
      setQuery({ scrollTo: undefined }, { useReplace: true });
    }, 0);
    this.lastScroll = Date.now();
  }

  render() {
    return <div ref={this.scrollNode} />;
  }
}

ScrollToComponent.propTypes = {
  id: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

export default withQuery(ScrollToComponent);
