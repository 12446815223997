import { connect } from 'react-redux';
import { getProfile, wasteSensorsSelector } from 'containers/Application/Modules/RecyclingModule/common/selectors';

const getBreakdownData = state => state.values.recycling.amountsBreakdown.data;
const getBreakdownTotals = state => state.values.recycling.amountsBreakdownTotals.data;

export const mapStateToProps = (state, props) => ({
  profile: getProfile(state),
  wasteSensors: wasteSensorsSelector(state, props),
  breakdownData: getBreakdownData(state),
  breakdownTotals: getBreakdownTotals(state),
});

const connector = connect(mapStateToProps);

export default connector;
