import { useState } from 'react';
import { TabOption } from './Tabs';

const useTabSelect = (tabs: TabOption[]) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const visibleContent = tabs[selectedTabIndex];

  return { selectedTabIndex, setSelectedTabIndex, visibleContent };
};

export default useTabSelect;
