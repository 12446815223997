import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getMonthsShort } from 'utils/Date/dateFormatter';
import capitalize from 'lodash/capitalize';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';
import ZoomableChart from 'components/Charts/ZoomableChart';
import BreakdownTotals from 'components/BreakdownTotals/BreakdownTotals';
import translations from 'decorators/Translations/translations';
import connect from 'containers/Application/Modules/RecyclingModule/RecyclingRatesBreakdown/RecyclingRatesBreakdownTab/connectRecyclingRatesBreakdownTab';
import { ErrorBoundaryDisplay } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/ErrorDisplay';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { TONNES_UNIT, LAST_YEAR, THIS_YEAR } from 'constants/recycling';

const createRateSeries = (t, theme, tab, ratesBreakdownData) => {
  const ratesSeries = tab.createRateSeries(ratesBreakdownData, [LAST_YEAR, THIS_YEAR]);
  return ratesSeries.map(entry => ({
    ...entry,
    id: `${tab.id}-${entry.id}`,
    name: `${t(tab.label)} ${entry.name}`,
    color: theme.charts.colorsRecycling[entry.colorName],
    _unit: '%',
    _showTooltipForZeroValue: true,
    yAxis: 0,
    showInLegend: entry.data.some(value => value),
  }));
};

const createRateTotals = (t, tab, ratesBreakdownTotals, opiData) => {
  const rateTotals = tab.createRateTotals(ratesBreakdownTotals, opiData);
  return {
    ...rateTotals,
    title: t(tab.label),
    unit: '%',
  };
};

const createWasteAmountSeries = (t, theme, tab, ratesBreakdownData) => {
  const wasteAmountsSeries = tab.createAmountSeries(ratesBreakdownData, [LAST_YEAR, THIS_YEAR]);
  return wasteAmountsSeries.map(entry => ({
    ...entry,
    type: 'column',
    id: `waste-amount-${entry.id}`,
    name: t('Waste amount') + ' ' + entry.name,
    color: theme.charts.colorsRecycling[entry.colorName],
    _unit: entry.unit === TONNES_UNIT ? t('tonnes') : 'kg',
    _showTooltipForZeroValue: true,
    yAxis: 1,
    showInLegend: entry.data.some(value => value),
  }));
};

const createYAxisConfig = (t, rateSeries, wasteAmountSeries) => {
  const ratesDataValues = rateSeries.flatMap(entry => entry.data);
  const ratesAverage = ratesDataValues.reduce((prev, curr) => prev + curr, 0) / ratesDataValues.length;
  const maxDataValue = Math.max(...wasteAmountSeries.flatMap(entry => entry.data).filter(value => value));
  const yMax = ratesAverage > 60 ? maxDataValue * 1.25 : undefined;
  const unitTitle = wasteAmountSeries[0]?.unit === TONNES_UNIT ? t('Tonnes') : t('Kilograms');

  return [
    { title: { text: t('Percentage') }, min: 0, max: 100, allowDecimals: false },
    {
      title: { text: unitTitle },
      max: yMax,
      allowDecimals: false,
      opposite: true,
    },
  ];
};

export const RecyclingRatesBreakdownTab = ({
  t,
  theme,
  profile,
  tab,
  ratesBreakdownData,
  ratesBreakdownTotals,
  opiData,
  visible,
}) => {
  const rateSeries = useMemo(() => createRateSeries(t, theme, tab, ratesBreakdownData), [ratesBreakdownData]);
  const wasteAmountSeries = useMemo(
    () => createWasteAmountSeries(t, theme, tab, ratesBreakdownData),
    [ratesBreakdownData]
  );
  const rateTotals = useMemo(
    () => createRateTotals(t, tab, ratesBreakdownTotals, opiData),
    [ratesBreakdownTotals, opiData]
  );
  const yAxisConfig = useMemo(() => createYAxisConfig(t, rateSeries, wasteAmountSeries), [ratesBreakdownData]);
  if (!visible) {
    return null;
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryDisplay}>
      <Columns justifyContent="space-between">
        <Column columnWidth={{ desktop: 9, default: 12 }}>
          <ZoomableChart
            t={t}
            language={profile.language}
            categories={getMonthsShort().map(capitalize)}
            series={wasteAmountSeries}
            temperatureSeries={rateSeries}
            xTitle={null}
            yAxisConfig={yAxisConfig}
            legendReversed={true}
          />
        </Column>
        <Column columnWidth={{ desktop: 3, default: 12 }}>
          {rateTotals.total ? (
            <BreakdownTotals
              header={t(tab.label)}
              label={t('Past 12 months')}
              icon={tab.totalsIconName}
              value={rateTotals.total}
              unit={rateTotals.unit}
              language={profile.language}
              positiveValueColor={theme.colors.black}
              categories={rateTotals?.perCategory.map(category => ({
                ...category,
                color: theme.charts.colorsRecycling[category.colorName],
              }))}
            />
          ) : null}
        </Column>
      </Columns>
    </ErrorBoundary>
  );
};

RecyclingRatesBreakdownTab.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  tab: PropTypes.object.isRequired,
  ratesBreakdownData: PropTypes.array.isRequired,
  ratesBreakdownTotals: PropTypes.array.isRequired,
  opiData: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default withRouter(withTheme(translations(connect(RecyclingRatesBreakdownTab))));
