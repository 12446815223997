import { css } from 'styled-components';

export const datepickerStyles = css`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  input {
    caret-color: transparent;
    width: 100%;
    height: var(--input-height);
    background-color: ${props => props.theme.input.background.default};
    padding: ${props => props.theme.input.padding};
    padding-left: ${props => props.hasIcon && '3.375rem'};
    border: ${props => props.theme.input.border.static};
    border-bottom-color: ${props => props.highlightError && 'var(--error-color)'};
    box-shadow: ${props => !props.simple && props.theme.input.shadow.static};
    color: ${props => props.theme.input.font.color.default};
    font-size: ${props => props.theme.input.font.mobileSize};
    font-family: ${props => props.theme.input.font.family};
    line-height: ${props => props.theme.input.lineHeight.default};
    transition: ${props => props.theme.input.transition};

    &:hover,
    &:focus {
      border: ${props => props.theme.input.border.active};
      box-shadow: ${props => props.theme.input.shadow.active};
    }
    &:disabled {
      background-color: ${props => props.theme.input.background.disabled};
    }

    &::placeholder {
      color: ${props => props.theme.input.font.color.placeholder};
    }

    ${props => props.theme.media.portrait`
      border-left-color: ${props => props.isRight && 'transparent'};
      box-shadow: ${props =>
        props.isLeft || props.isRight || props.simple ? 'none' : props.theme.input.shadow.static};
    `}

    ${props => props.theme.media.landscape`
      font-size: ${props => props.theme.input.font.size};
    `}
  }

  /** use hover styles for auto-focused days */
  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
    color: #000;
  }

  .react-datepicker__close-icon {
    width: 1rem;
    height: 1rem;
    padding: 0;
    margin: 0;
    right: 0.875rem;
    top: calc(25px - 0.5rem);
  }

  .react-datepicker__close-icon::after {
    background-color: var(--input-supplemental-icon-fg);
    font-size: ${props => props.theme.input.font.mobileSize};
    line-height: 0.8rem;
    padding: 0;
  }

  /** styles for month and year selectors when props.showMonthAndYearDropdowns */
  .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
  }
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: 4px;
    border-width: 0.4rem;
  }
  .react-datepicker__header__dropdown {
    padding: 2px 0 8px;
  }
  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    border: var(--border-thin) solid var(--date-picker-dropdown-bc);
    background: var(--date-picker-dropdown-fg);
    line-height: ${props => props.theme.font.lineHeight.md};
    padding-right: 0.4rem;
  }
`;
