import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TabPanels = styled.div`
  position: relative;
`;

const hiddenTabPanel = css`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
  pointer-events: none;
`;

export const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => rgba(props.theme.colors.white, 0.2)};
  box-shadow: ${props => props.theme.section.shadow};
  border-radius: 0 ${props => props.theme.section.borderRadius} ${props => props.theme.section.borderRadius};
  background-color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.font.size.sm};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => props.theme.colors.black};

  ${props => props.notSelected && hiddenTabPanel};
`;
