import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { MASTER_DATA_PATH, SERVICE_API_PATH } from './index';

export const invalidateFunctionalLocationCache = () =>
  Promise.allSettled([
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/functional-locations`),
    invalidateCachesWithUrlMatch(`${MASTER_DATA_PATH}/hierarchies`), // For backwards compatibility, also clear old master data hierarchies
  ]);

export const functionalLocationsOverview = partnerNumber =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/functional-locations/overview${partnerNumber ? `/${partnerNumber}` : ''}`,
  });

export const functionalLocationsOverviewAnonymous = publicViewId =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/functional-locations/overview/anonymous/${publicViewId}`,
    useAuthorization: false,
  });

export const functionalLocations = functionalLocations =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/find-by-functional-locations`,
    data: { functionalLocations },
  });

export const adminFunctionalLocations = functionalLocations =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/admin/find-by-functional-locations`,
    data: { functionalLocations },
  });

export const adminFunctionalLocationsByPartnerNumbers = (partnerNumberWithParents, types = ['BU', 'UN']) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/admin/find-by-partner-numbers`,
    data: { partnerNumberWithParents, types },
  });

export const functionalLocationsByKeyword = keyword =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/functional-locations/search/${keyword}`,
  });

export const functionalLocationsByPartnerNumbers = (partnerNumbers, functionalLocations) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/find-by-partner-number-with-parents`,
    data: { partnerNumberWithParents: partnerNumbers, functionalLocations },
  });

export const functionalLocationCountsByPartnerNumbers = partnerNumbers =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/admin/find-by-partner-numbers/counts`,
    data: { partnerNumbers },
  });

export const functionalLocationsByParent = functionalLocationParents =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/functional-locations/find-by-functional-location-parents`,
    data: { functionalLocationParents },
  });
