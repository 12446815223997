import { Division } from 'types/Division';

export const getLocationTitle = publicView => {
  if (!publicView) {
    return '';
  }

  if (publicView.functionalLocation) {
    return publicView.functionalLocation.description;
  } else if (publicView.customer) {
    return publicView.customer.name;
  }
  return '';
};

export const guessDivisionForPhoneNumberField = (publicView, language) => {
  const divisions = Object.values(Division);
  if (divisions.includes(language)) {
    return language;
  } else if (publicView?.functionalLocation?.functionalLocation) {
    const divisionFromFl = publicView.functionalLocation.functionalLocation.slice(0, 2).toLowerCase();
    if (divisions.includes(divisionFromFl)) {
      return divisionFromFl;
    }
  }
  return Division.Finland;
};
