import memoizeOne from 'memoize-one';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import isWithinInterval from 'date-fns/isWithinInterval';
import { formatDistanceToNow } from 'utils/Date/dateFormatter';
import startOfMonth from 'date-fns/startOfMonth';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';
import sortBy from 'lodash/sortBy';
import meanBy from 'lodash/meanBy';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import values from 'lodash/values';
import capitalize from 'lodash/capitalize';
import forOwn from 'lodash/forOwn';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { CELSIUS, HEATING_TYPE, COOLING_TYPE, OUTDOOR_TYPE, defaultOutdoorsTemperatureKey } from 'utils/Data/values';
import { getPresenceData } from 'containers/Application/Modules/FloorModule/FloorOPICards/FloorOPIUtils';

const parseTimestamp = timestamp => (typeof timestamp === 'string' ? parseISO(timestamp) : toDate(timestamp));

export const getAirQualityValues = memoizeOne((floors, groups, latestValues, t) => {
  const floorsAndGroups = [...floors, ...groups];
  const airQualitySensors = getAirQualitySensors(floorsAndGroups);
  if (!isEmpty(airQualitySensors)) {
    const airQualitySensorIds = airQualitySensors.map(sensor => sensor.id);
    return {
      value: getAirQualityOPIValue(airQualitySensorIds, latestValues),
      sensorGroups: getSensorGroups(floorsAndGroups, airQualitySensors, t),
      sensorsIds: airQualitySensorIds,
    };
  }
});

export const getAirQualitySensors = hierarchies =>
  hierarchies
    .flatMap(hierarchy => hierarchy.sensors)
    .filter(sensor => sensor?.sensorType?.name === 'technical_performance');

export const getBuildingConditionsValues = memoizeOne((buildingConditions, buildingMeta) => {
  let result = {
    cooling: [],
    heating: [],
    outdoorTemperature: [],
  };

  if (!isEmpty(buildingConditions)) {
    const coolingUtilizationValues = [];
    forOwn(
      groupBy(buildingConditions[COOLING_TYPE], value => value.timestamp),
      (value, key) => {
        coolingUtilizationValues.push({
          timestamp: parseTimestamp(key).valueOf(),
          avg: meanBy(value, a => a.avg),
          sensorName: value[0].sensorName,
        });
      }
    );
    coolingUtilizationValues.sort((a, b) => a.timestamp - b.timestamp);

    const heatingUtilizationValues = [];
    forOwn(
      groupBy(buildingConditions[HEATING_TYPE], value => value.timestamp),
      (value, key) => {
        heatingUtilizationValues.push({
          timestamp: parseTimestamp(key).valueOf(),
          avg: meanBy(value, a => a.avg),
          sensorName: value[0].sensorName,
        });
      }
    );
    heatingUtilizationValues.sort((a, b) => a.timestamp - b.timestamp);

    const outdoorsTemperatureSensorId = +findOutdoorsTemperatureSensorId(buildingConditions, buildingMeta);
    const outdoorsTemperatureValues = buildingConditions[OUTDOOR_TYPE]?.filter(
      value => +value.sensorId === outdoorsTemperatureSensorId
    ).map(row => ({
      ...row,
      timestamp: parseTimestamp(row.timestamp).valueOf(),
    }));

    result = Object.assign({}, result, {
      cooling: coolingUtilizationValues,
      heating: heatingUtilizationValues,
      outdoorTemperature: outdoorsTemperatureValues,
    });
  }
  return result;
});

const EMPTY_OBJECT = {};

const getSensorGroups = (hierarchies, opiSensors, t) =>
  compact(
    hierarchies.map(hierarchy => {
      const opiSensorsInHierarchy = opiSensors.filter(opiSensor =>
        hierarchy.sensors?.some(
          hierarchySensor => hierarchySensor.id === opiSensor.id || hierarchySensor.id === opiSensor.parentId
        )
      );

      if (isEmpty(opiSensorsInHierarchy)) {
        return null;
      }

      let name;
      if (hierarchy.type === 'floor') {
        name = `${t('Floor')} ${t(hierarchy.shortName || hierarchy.name || 'Unnamed')}`;
      } else {
        name = hierarchy.name || t('Unnamed');
      }

      return {
        name,
        isGroup: true,
        sensors: opiSensorsInHierarchy,
        sensorType: opiSensors[0].sensorType,
        sensorTypeId: opiSensors[0].sensorTypeId,
      };
    })
  );

export const getUtilizationRateValues = memoizeOne((floors, valuesBySensorId, buildingMeta, t) => {
  if (isEmpty(floors) || isEmpty(valuesBySensorId) || isEmpty(buildingMeta)) {
    return EMPTY_OBJECT;
  }

  const floorSensors = floors.flatMap(floor => floor.sensors);

  const presenceAreaData = getPresenceData(floorSensors, valuesBySensorId, 'presence_area', buildingMeta);
  const presenceArea = {
    value: presenceAreaData.value,
    sensorGroups: getSensorGroups(floors, presenceAreaData.sensors, t),
    sensorsIds: presenceAreaData.sensorIds,
  };

  const presenceSeatData = getPresenceData(floorSensors, valuesBySensorId, 'presence_seat', buildingMeta);
  const presenceSeat = {
    value: presenceSeatData.value,
    sensorGroups: getSensorGroups(floors, presenceSeatData.sensors, t),
    sensorsIds: presenceSeatData.sensorIds,
  };

  const presenceZoneData = getPresenceData(floorSensors, valuesBySensorId, 'presence_zone', buildingMeta);
  const presenceZone = {
    value: presenceZoneData.value,
    sensorGroups: getSensorGroups(floors, presenceZoneData.sensors, t),
    sensorsIds: presenceZoneData.sensorIds,
  };

  return {
    presenceArea,
    presenceZone,
    presenceSeat,
  };
});

export const getOPICards = memoizeOne(
  (airQuality, outsideTemperature, cooling, heating, presenceArea, presenceZone, presenceSeat, toggleModal, t) => {
    const opiCards = [];

    if (typeof airQuality !== 'undefined') {
      opiCards.push({
        title: 'Indoor Air Quality',
        subtitle: '7 Days Average',
        value: airQuality.value,
        s2Link: true,
        isAirQuality: true,
        modal: {
          id: 'airQuality',
          title: t('Indoor Air Quality'),
          sensorGroups: airQuality.sensorGroups,
          sensorsIds: airQuality.sensorsIds,
          isAirQuality: true,
          showCombinationGroup: true,
        },
        icon: 'open-modal',
      });
    }

    if (outsideTemperature) {
      opiCards.push({
        title: 'Outdoors temperature',
        subtitle: outsideTemperature && capitalize(formatDistanceToNow(parseTimestamp(outsideTemperature.timestamp))),
        valueInside: outsideTemperature ? `${outsideTemperature.avg}${CELSIUS}` : null,
        scrollTo: 'BS',
        noCircle: true,
        icon: 'opi-arrow',
      });
    }

    if (cooling) {
      opiCards.push({
        title: 'Cooling utilization',
        subtitle: cooling && capitalize(formatDistanceToNow(parseTimestamp(cooling.timestamp))),
        value: cooling ? cooling.avg : null,
        scrollTo: 'BS',
        neutral: true,
        icon: 'opi-arrow',
      });
    }

    if (heating) {
      opiCards.push({
        title: 'Heating utilization',
        subtitle: heating && capitalize(formatDistanceToNow(parseTimestamp(heating.timestamp))),
        value: heating ? heating.avg : null,
        scrollTo: 'BS',
        neutral: true,
        icon: 'opi-arrow',
      });
    }

    if (presenceArea && !isEmpty(presenceArea.sensorsIds)) {
      opiCards.push({
        title: 'Meeting Room Utilization',
        subtitle: 'Last 7 days',
        value: presenceArea.value,
        neutral: true,
        modal: {
          id: 'meetingRoomUtilization',
          title: t('Meeting Room Utilization'),
          sensorGroups: presenceArea.sensorGroups,
          sensorsIds: presenceArea.sensorsIds,
          isUtilizationRate: true,
          showCombinationGroup: true,
        },
        icon: 'open-modal',
      });
    }

    if (presenceZone && !isEmpty(presenceZone.sensorsIds)) {
      opiCards.push({
        title: 'Open Office Utilization',
        subtitle: 'Last 7 days',
        value: presenceZone.value,
        neutral: true,
        modal: {
          id: 'openOfficeUtilization',
          title: t('Open Office Utilization'),
          sensorGroups: presenceZone.sensorGroups,
          sensorsIds: presenceZone.sensorsIds,
          isUtilizationRate: true,
          showCombinationGroup: true,
        },
        icon: 'open-modal',
      });
    }

    if (presenceSeat && !isEmpty(presenceSeat.sensorsIds)) {
      opiCards.push({
        title: 'Seat Utilization',
        subtitle: 'Last 7 days',
        value: presenceSeat.value,
        neutral: true,
        modal: {
          id: 'seatUtilization',
          title: t('Seat Utilization'),
          sensorGroups: presenceSeat.sensorGroups,
          sensorsIds: presenceSeat.sensorsIds,
          isUtilizationRate: true,
          showCombinationGroup: true,
        },
        icon: 'open-modal',
      });
    }

    return opiCards;
  }
);

export const getAirQualityAverages = memoizeOne((values, sensorIds, aggregation, startDate, endDate) => {
  const start = startDate.valueOf();
  const end = endDate.valueOf();
  const sensorIdMap = keyBy(sensorIds);
  const airQualityValues = values.filter(
    value =>
      value.aggregation === aggregation &&
      value.sensorId in sensorIdMap &&
      isWithinInterval(parseTimestamp(value.timestamp), { start, end })
  );

  const getStartByAggregation = (date, aggregation) => {
    switch (aggregation) {
      case 'monthlyAverage':
        return startOfMonth(date);
      case 'weeklyAverage':
        return startOfISOWeek(date);
      case 'dailyAverage':
        return startOfDay(date);
      default:
        return startOfHour(date);
    }
  };

  const buckets = new Map();
  airQualityValues.forEach(value => {
    const key = getStartByAggregation(parseTimestamp(value.timestamp), aggregation).valueOf();
    const bucket = buckets.get(key);
    if (bucket) {
      bucket.push(value);
    } else {
      buckets.set(key, [value]);
    }
  });

  return sortBy(
    Array.from(buckets, ([timestamp, bucket]) => ({
      timestamp,
      value: meanBy(bucket, 'value'),
    })),
    'timestamp'
  );
});

export const getAirQualityOPIValue = memoizeOne((sensorIds, latestValues) => {
  const airQualityValues = values(pick(latestValues, sensorIds));
  return airQualityValues.length ? meanBy(airQualityValues, 'value') : undefined;
});

// Use default sensor set in building meta, or the first sensor found in building conditions data
export const findOutdoorsTemperatureSensorId = (buildingConditions, buildingMeta) => {
  const defaultSensorIdFromMeta = buildingMeta?.find(meta => meta.key === defaultOutdoorsTemperatureKey)?.value;
  return defaultSensorIdFromMeta || buildingConditions?.[OUTDOOR_TYPE]?.[0]?.sensorId;
};
