import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
    0% { transform: translate(-60px, 0); }
    100% { transform: translate(calc(100% + 60px), 0); }
`;

export const AnimationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  animation: ${animation} 1.5s ease infinite;
`;

const SkeletonShine = styled.div`
  width: 5px;
  height: 100%;
  box-shadow: 0 0 50px 50px ${props => props.theme.skeleton.effectColor};
  background-color: ${props => props.theme.skeleton.effectColor};
`;

const SkeletonAnimation = () => (
  <AnimationWrapper>
    <SkeletonShine />
  </AnimationWrapper>
);

export default SkeletonAnimation;
