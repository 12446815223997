import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from '../masterData/index';

export const rescuePlansInfoByFunctionalLocation = async functionalLocation =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/rescue-plans/${functionalLocation}/info`,
  });

export const infoById = async id =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/info`,
    useAuthorization: false,
  });

export const documentById = async (id, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/document`,
    query: { language },
    useAuthorization: false,
  });

export const actionSectionById = async (id, sectionSlug, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/action-section/${sectionSlug}`,
    query: { language },
    useAuthorization: false,
  });

export const actionSectionsById = async (id, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/action-section`,
    query: { language },
    useAuthorization: false,
  });

export const markAsReadInputOptionsById = async (id, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/mark-as-read/input-options`,
    query: { language },
    useAuthorization: false,
  });

export const markAsReadById = async (id, mark) =>
  await ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/mark-as-read`,
    data: mark,
    useAuthorization: false,
  });

export const safetyRemarkInputOptionsById = async (id, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/safety-remark/input-options`,
    query: { language },
    useAuthorization: false,
  });

export const safetyRemarkNewById = async (id, remark) =>
  await ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/safety-remark`,
    data: remark,
    useAuthorization: false,
  });

export const printFilesStatus = async (id, language) =>
  await ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/rescue-plan/${id}/print/print_files_status`,
    query: { language },
    useAuthorization: false,
  });
