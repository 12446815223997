export const InputType = {
  DROPDOWN: 'dropdown',
  TEXT: 'text',
  EMPTY: 'empty',
};

export const FORM_ONLY_FIELDS = {
  supplierInput: InputType.TEXT,
  categoryInput: InputType.DROPDOWN,
  sendSMSInput: true,
  sendEmailInput: true,
  assignmentSettings: true,
};

export const UI_ONLY_FIELDS = ['detailedStatus', 'orderType', 'system', 'supplierId'];
