import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import differenceInDays from 'date-fns/differenceInDays';
import { formatDistanceToNow } from 'utils/Date/dateFormatter';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import MarkdownContent from './MarkdownContent';
import { statusToColor, statusToIcon, DESCRIPTION_MAX_LENGTH, ANNOUNCEMENT_TYPE, announcementShape } from './utils';

const StyledAnnouncement = styled.section`
  display: flex;
  padding: var(--size-md);
  border-bottom: var(--light-separator-border);
  &:last-child {
    border-bottom: none;
  }
`;
StyledAnnouncement.displayName = 'StyledAnnouncement';

const IconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: var(--size-md);
  width: 25px;
`;
IconWrapper.displayName = 'IconWrapper';

const AnnouncementWrapper = styled.div`
  flex-grow: 1;
`;
AnnouncementWrapper.displayName = 'AnnouncementWrapper';

const Title = styled.h4`
  color: ${props => (props.read ? 'var(--supplemental-text-fg)' : 'var(--headline-fg)')};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.fontWeight.heading};
`;
Title.displayName = 'Title';

const PartnerName = styled.div`
  color: var(--subheader-fg);
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  padding: var(--size-xxs) 0;
`;
PartnerName.displayName = 'PartnerName';

const Icon = styled(({ small, ...props }) => <Svg {...props} />)`
  font-size: ${props => (props.small ? '0.7em' : '1.6em')};
  fill: ${props => props.fill};
`;
Icon.displayName = 'Icon';

const StyledDate = styled.div`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
`;
StyledDate.displayName = 'StyledDate';

const ReadMore = styled.a`
  display: ${props => !props.show && 'none'};
  position: relative;
  margin-left: auto;
  text-decoration: underline;
  white-space: nowrap;
  font-size: ${props => props.theme.font.size.xs};
  cursor: pointer;
`;
ReadMore.displayName = 'ReadMore';

const DescriptionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  /* Max height: two lines of description and one margin */
  max-height: calc(${props => `${props.theme.font.size.xs} * ${props.theme.font.lineHeight.lg}`} * 2 + var(--size-xs));
`;
DescriptionWrapper.displayName = 'DescriptionWrapper';

const StyledMarkdownContent = styled(MarkdownContent)`
  p {
    padding: 0;
    color: var(--supplemental-text-fg);
    font-size: ${props => props.theme.font.size.xs};
    line-height: ${props => props.theme.font.lineHeight.lg};
  }
`;
StyledMarkdownContent.displayName = 'StyledMarkdownContent';

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--size-xs);
`;

const GeneralAnnouncement = props => {
  const { announcement, onClick, unread, partner, t, plain } = props;
  const getTimeFromNow = date => {
    const dateObject = parseISO(date);
    const now = new Date();
    return differenceInDays(now, dateObject) >= 1 ? formatDistanceToNow(dateObject) : t('today');
  };
  const descriptionHasLineBreaks = /\r|\n/.exec(announcement.description);

  return (
    <StyledAnnouncement plain={plain}>
      <IconWrapper>
        <Icon name={statusToIcon[announcement.status]} fill={statusToColor[announcement.status]} />
      </IconWrapper>
      <AnnouncementWrapper>
        <Title data-test-id="AnnouncementBoxTitle" read={!unread}>
          {announcement.title}
        </Title>
        {partner && <PartnerName>{partner.name}</PartnerName>}
        <DescriptionWrapper data-test-id="AnnouncementBoxDescription">
          <StyledMarkdownContent content={announcement.description} />
        </DescriptionWrapper>
        <DateWrapper>
          <StyledDate>{getTimeFromNow(announcement.startDate)}</StyledDate>
          <ReadMore
            onClick={onClick}
            show={descriptionHasLineBreaks || announcement.description.length > DESCRIPTION_MAX_LENGTH}
          >
            {t('Read more')} &rarr;
          </ReadMore>
        </DateWrapper>
      </AnnouncementWrapper>
    </StyledAnnouncement>
  );
};

GeneralAnnouncement.propTypes = {
  announcement: PropTypes.oneOfType([
    announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
    announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
  ]).isRequired,
  partner: PropTypes.object,
  unread: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  plain: PropTypes.bool,
};

export default GeneralAnnouncement;
