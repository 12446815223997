import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import startsWith from 'lodash/startsWith';

import translations from 'decorators/Translations/translations';
import { setTosOpen } from 'redux/modules';
import Svg from 'components/Svg/Svg';
import { getTos } from 'redux/modules/profile/profile.selectors';

const StyledFooter = styled.footer`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: var(--size-xl) var(--size-md);
  background-color: ${props => props.theme.colors.white};
  transition: margin-left ${props => props.theme.navigation.transition};
  border-top: 1px solid ${props => props.theme.colors.lightGray};

  ${props => props.theme.media.landscape`
    margin-left: ${props =>
      props.noMargin
        ? 0
        : props.naviExtended
        ? props.theme.navigation.sideTabletWidth
        : props.theme.navigation.sideSmallWidth};
  `};

  ${props => props.theme.media.desktop`
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-left: ${props =>
      props.noMargin
        ? 0
        : props.naviExtended
        ? props.theme.navigation.sideWidth
        : props.theme.navigation.sideSmallWidth};
  `}

  @media print {
    display: none;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: 2;
  ${props => (props.simpleMobile ? '' : 'margin-top: var(--size-md);')}

  a {
    display: ${props => props.simpleMobile && 'none'};
  }

  ${props => props.theme.media.desktop`
    margin-top: auto;
    flex-direction: row;
    width: 33%;

    a {
      display: block;
    }
  `}
`;

const LogoSvg = styled(Svg)`
  fill: ${props => props.theme.colors.blue};
`;

const LinksContainer = styled.div`
  text-align: center;
  ${props => props.theme.media.desktop`
    width: 33%;
  `}
`;

const TermsAndConditions = styled.div`
  color: ${props => props.theme.colors.blue};
  font-size: ${props => props.theme.font.size.xs};
  cursor: pointer;
  display: inline;
  margin-left: var(--size-xl);

  &:first-child {
    margin-left: 0;
  }
`;

const PrivacyLink = TermsAndConditions.withComponent('a');

const Copyright = styled.span`
  color: ${props => props.theme.colors.rockBlue};
  font-size: ${props => props.theme.font.size.xs};
  margin-left: 0;
  margin-top: ${props => !props.simpleMobile && '1em'};
  ${props => props.theme.media.desktop`
    margin-left: var(--size-xl);
    margin-top: 0;
  `}
`;

const Footer = ({ t, location: { pathname }, tosText, naviExtended, noMargin, setTosOpen, simpleMobile }) => {
  const openTOS = React.useCallback(() => setTosOpen(true), [setTosOpen]);

  const shouldNotShowFooter = [
    '/UserManual',
    '/AdminUserManual',
    '/UserManualPreview',
    '/Roadmap',
    '/UserManualV1',
  ].some(path => startsWith(pathname, path));

  if (shouldNotShowFooter) {
    return null;
  }

  return (
    <StyledFooter className="footer" naviExtended={naviExtended} noMargin={noMargin}>
      <LogoContainer simpleMobile={simpleMobile}>
        <Link to="/" className="footer-logo">
          <LogoSvg name="caverion_logo" height={37} width={140} alt="Caverion Logo" />
        </Link>
        <Copyright simpleMobile={simpleMobile}>{`©${new Date().getFullYear()} Caverion Corporation`}</Copyright>
      </LogoContainer>
      <LinksContainer>
        {tosText && <TermsAndConditions onClick={openTOS}>{t('Terms and Conditions')}</TermsAndConditions>}
        {t('privacy_link') !== 'privacy_link' && (
          <PrivacyLink className="footer-link" href={t('privacy_link')} target="_blank" rel="noopener noreferrer">
            {t('Privacy statement')}
          </PrivacyLink>
        )}
      </LinksContainer>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  naviExtended: false,
  noMargin: false,
  simpleMobile: false,
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  tosText: PropTypes.string,
  setTosOpen: PropTypes.func.isRequired,
  naviExtended: PropTypes.bool,
  noMargin: PropTypes.bool,
  simpleMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  tosText: getTos(state).text,
  naviExtended: state.navigation.sideNaviExtended,
});

const mapDispatchToProps = {
  setTosOpen,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(translations(Footer)));
