import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { useTranslations } from 'decorators/Translations/translations';
import FeedbackPanel from './FeedbackPanel';
import { toggleFeedbackPanel, sendFeedback } from 'redux/modules/feedback/feedback';
import SnackBar from 'components/SnackBar/SnackBar';
import NavBarButton from 'components/NavBarButton/NavBarButton';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import useFormValidation from 'containers/Application/ServiceModule/ServiceModuleForm/hooks/useFormValidation';
import * as yup from 'yup';

const FeedbackContainer = styled.div`
  position: relative;
  height: 100%;
  width: 0px;
  display: flex;

  ${props => props.theme.media.portrait`
        width: 55px;
        justify-content: center;
    `}
`;

const Overlay = styled.div`
  pointer-events: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.3 ease-in-out;
  z-index: ${props => props.theme.zIndex('feedback') - 1};
  background-color: var(--overlay-medium-color);
`;
Overlay.displayName = 'Overlay';

export const Feedback = ({ feedbackMenuOpen, toggleFeedbackPanel, sendFeedback, sending, sent, error }) => {
  const [t] = useTranslations();
  const noNotification = { type: '', visible: false, message: '' };
  const [notification, setNotification] = useState(noNotification);

  const defaultModel = { freeFormFeedback: '' };
  const [model, setModel] = useState(defaultModel);

  const schemas = {
    feedbackForm: yup.object({
      freeFormFeedback: yup.string().required(t('Feedback field is required')),
    }),
  };

  const [validationErrors, validateForms] = useFormValidation(model, schemas);

  useEffect(() => {
    validateForms(['resetValidation']);
  }, [model, feedbackMenuOpen]);

  useEffect(() => {
    !feedbackMenuOpen && setModel(defaultModel);
  }, [feedbackMenuOpen]);

  const errorMessages = { ...validationErrors, error: t(error) };

  const onSubmit = async feedback => {
    if (await validateForms()) {
      await sendFeedback({
        ...feedback,
        sentFromUrl: window && window.location.href,
      });
      setNotification({ type: 'success', visible: true, message: t('Thank you for your feedback!') });
      setTimeout(() => setNotification(noNotification), NOTIFICATION_TIMEOUT);
      setModel(defaultModel);
    }
  };

  return (
    <FeedbackContainer data-test-id="Feedback">
      <Transition
        in={feedbackMenuOpen}
        timeout={{
          enter: 0,
          exit: 300,
        }}
        unmountOnExit
        mountOnEnter
      >
        {transitionState => {
          const active = transitionState === 'entered';
          return (
            <Fragment>
              <Overlay key="feedbackOverlay" active={active} onClick={toggleFeedbackPanel} />
              <FeedbackPanel
                key="feedbackPanel"
                onClose={toggleFeedbackPanel}
                onSubmit={onSubmit}
                active={active}
                errorMessages={errorMessages}
                sending={sending}
                sent={sent}
                model={model}
                setModel={setModel}
              />
            </Fragment>
          );
        }}
      </Transition>
      <NavBarButton icon="smiley-good" label={t('Feedback')} onClick={toggleFeedbackPanel} />
      <SnackBar variant={notification.type} visible={notification.visible}>
        {notification.message}
      </SnackBar>
    </FeedbackContainer>
  );
};

Feedback.displayName = 'Feedback';

Feedback.propTypes = {
  feedbackMenuOpen: PropTypes.bool.isRequired,
  toggleFeedbackPanel: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  sending: PropTypes.bool,
  sent: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = state => ({
  feedbackMenuOpen: state.feedback.menuOpen,
  sending: state.feedback.sending,
  sent: state.feedback.sent,
  error: state.feedback.error,
});

const mapDispatchToProps = {
  toggleFeedbackPanel,
  sendFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
