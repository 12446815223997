import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from '../masterData/index';

export const loadExternalDocumentsFn = (request, { functionalLocation, equipmentNumber, serviceOrderNumber }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/mfiles/${functionalLocation}`,
    query: equipmentNumber || serviceOrderNumber ? { searchTerm: equipmentNumber || serviceOrderNumber } : null,
  });

export const downloadExternalDocumentsFn = (
  request,
  { functionalLocation, serviceOrderNumber, documentId, fileId, fileName }
) =>
  request.download({
    method: 'GET',
    apiPath: `${SERVICE_API_PATH}/document/mfiles/${functionalLocation}/download`,
    query: { documentId, fileId, searchTerm: serviceOrderNumber },
    noCache: true,
    fileName,
  });

export default wrapEachWithThunkRequest({
  loadExternalDocuments: loadExternalDocumentsFn,
  downloadExternalDocuments: downloadExternalDocumentsFn,
});
