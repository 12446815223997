import { createReducerFromMapping } from 'redux/utils/index';
import { loadEnergyData, loadEnergyKPI, loadEnergyKPIToday } from 'redux/modules';
import subDays from 'date-fns/subDays';

export const LOAD_KPI_ENERGY = 'CUSTOMER_PLATFORM/KPIEnergyContainer/LOAD_KPI_ENERGY';
export const LOAD_KPI_ENERGY_SUCCESS = 'CUSTOMER_PLATFORM/KPIEnergyContainer/LOAD_KPI_ENERGY_SUCCESS';
export const LOAD_KPI_ENERGY_FAIL = 'CUSTOMER_PLATFORM/KPIEnergyContainer/LOAD_KPI_ENERGY_FAIL';

const initialState = {
  loading: false,
  error: false,
};

const now = new Date();

const monthAgoStart = subDays(now, 31);
const monthAgoEnd = subDays(now, 30);

// Deprecated, don't use
export function loadKPIEnergyContainer(dispatch, partnerNumber) {
  return {
    types: [LOAD_KPI_ENERGY, LOAD_KPI_ENERGY_SUCCESS, LOAD_KPI_ENERGY_FAIL],
    promise: () => ({
      promise: new Promise((resolve, reject) =>
        Promise.all(
          dispatch(loadEnergyData(partnerNumber)),
          dispatch(loadEnergyKPIToday(partnerNumber)),
          dispatch(loadEnergyKPI(partnerNumber, monthAgoStart, monthAgoEnd))
        )
          .then(resolve)
          .catch(reject)
      ),
    }),
  };
}

export default createReducerFromMapping(
  {
    [LOAD_KPI_ENERGY]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_KPI_ENERGY_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [LOAD_KPI_ENERGY_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
