import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.colors.emerald};
  z-index: ${props => props.theme.zIndex('modal')};
`;
Background.displayName = 'Background';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.font.size.md};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: ${props => props.theme.colors.white};
  text-align: center;
  padding: var(--size-xxl);
  padding-bottom: calc(50px + var(--size-xxxl));
`;
Content.displayName = 'Content';

const SignContainer = styled.div`
  margin-bottom: var(--size-md);
`;

const Sign = styled.div`
  position: relative;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 50%;
  width: 50px;
  height: 50px;

  &::after {
    content: '';
    position: absolute;
    left: 9px;
    top: 18px;
    width: 15px;
    height: 20px;
    border: solid ${props => props.theme.colors.white};
    border-width: 0 5px 5px 0;
    transform: rotate(45deg) translateY(-50%);
  }
`;
Sign.displayName = 'Sign';

const SuccessPage = ({ message, children, ...otherProps }) => (
  <Background {...otherProps}>
    <Content>
      <SignContainer>
        <Sign />
      </SignContainer>
      {message}
    </Content>
    {children}
  </Background>
);

SuccessPage.propTypes = {
  children: PropTypes.node,
  message: PropTypes.node,
};

export default SuccessPage;
