import themes from 'styles/themes';

export const MAX_LOG_MESSAGES = 500;
export const MAX_INTERVAL_DURATION_DAYS = 30;
export const INSIGHT_STATUS = {
  success: 2,
  active: 1,
  inactive: 0,
};
export const STATUS_COLORS = {
  [INSIGHT_STATUS.success]: themes.customerPlatform.status.okColor,
  [INSIGHT_STATUS.active]: themes.customerPlatform.status.alarmColor,
  [INSIGHT_STATUS.inactive]: 'transparent',
};

export const INSIGHTS_CONFIGURATION_KEY = 'calculate_insights';
