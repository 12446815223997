import React from 'react';
import styled from 'styled-components';

import SkeletonPill from 'components/Skeletons/SkeletonPill';

export const ShowContainer = styled.div`
  padding: var(--size-xs) 0;
`;

export const NoDataAvailable = styled.p`
  margin: var(--size-md) 0;
  color: ${props => props.theme.colors.black};
`;
NoDataAvailable.displayName = 'NoDataAvailable';

export const HeaderInfo = styled.span`
  margin: var(--size-sm);
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.darkGray};

  ${props => props.theme.media.landscape`
        margin-left: var(--size-md);
        margin-right: auto;
    `}
`;

export const SkeletonFilter = () => <SkeletonPill width="110px" margin="8px 8px 0" />;

export const overrideColumnWidth = {
  bigDesktop: 4,
  desktop: 4,
  landscape: 4,
  portrait: 4,
  default: 6,
};
