import React, { useMemo } from 'react';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import brokenAxis from 'highcharts/modules/broken-axis';
import boost from 'highcharts/modules/boost';
import accessibility from 'highcharts/modules/accessibility';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslations } from 'decorators/Translations/translations';
import { hasData, getOptions } from './utils';
import useChartDimensions from '../useChartDimensions';
import useChartSkeleton from '../useChartSkeleton';
import useLabeledAnnotations from './useLabeledAnnotations';

brokenAxis(Highstock);
boost(Highstock);
accessibility(Highstock);

const StyledSensorChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${props => props.chartHeight};
  height: 100%;

  .highcharts-range-selector-buttons .highcharts-button-box {
    /* It seems to be impossible to unset CSS height value for SVG rect (it is set in our global styles),
    so let's override it here with a new value that should match with height that is set in the SVG */
    height: 23px;
  }

  .highcharts-range-input .highcharts-label-box {
    fill: ${props => props.theme.colors.concrete};
    /* Looks like we can't change padding for input boxes so let's do it with border */
    stroke: ${props => props.theme.colors.concrete};
    stroke-width: 7px;
  }
`;

const CHART_HEIGHT_WITH_NAVIGATOR = '34rem';
const CHART_HEIGHT_WITHOUT_NAVIGATOR = '26rem';

const getChartHeight = (height, hasSort) => {
  if (height) {
    return height;
  }
  if (hasSort) {
    return CHART_HEIGHT_WITHOUT_NAVIGATOR;
  }
  return CHART_HEIGHT_WITH_NAVIGATOR;
};

const CONTAINER_PROPS = { style: { width: '100%' } };

const DefaultCustomChart = props => {
  const {
    width,
    height,
    loading,
    error,
    sensorData,
    options,
    onSelect,
    onClick,
    aggregationFreq,
    sort,
    annotations,
    setExtremes,
    isPreview,
    verticalZoom,
    noNavigator,
    noNavigatorSeries,
    useBoostModule,
    onLoad,
    chartRef,
  } = props;

  const [t] = useTranslations();
  const theme = useTheme();
  const containerRef = React.useRef();
  const [chartWidth, chartHeight] = useChartDimensions(containerRef, width, height);

  const chartAnnotations = useLabeledAnnotations(annotations, !sort?.length > 0 && !noNavigator);
  const chartConfigs = useMemo(
    () =>
      getOptions({
        sensorData,
        aggregationFreq,
        chartWidth,
        chartHeight,
        onClick,
        t,
        theme,
        options,
        sort,
        verticalZoom,
        noNavigator,
        noNavigatorSeries,
        useBoostModule,
        onLoad,
      }),
    [
      sensorData,
      aggregationFreq,
      chartWidth,
      chartHeight,
      onClick,
      t,
      theme,
      options,
      sort,
      verticalZoom,
      noNavigator,
      noNavigatorSeries,
      useBoostModule,
      onLoad,
    ]
  );

  chartConfigs.xAxis.events = {
    ...chartConfigs.xAxis.events,
    setExtremes,
  };

  chartConfigs.chart.events.select = { ...chartConfigs.chart.events, select: onSelect };

  const chartOptions = useMemo(
    () => ({ ...chartConfigs, annotations: chartAnnotations }),
    [chartConfigs, chartAnnotations]
  );

  React.useEffect(() => {
    Highstock.setOptions({
      lang: {
        rangeSelectorZoom: t('Zoom'),
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const chartSkeleton = useChartSkeleton(
    loading || isNil(sensorData),
    error,
    !hasData(sensorData) && !options.xMaxRange,
    chartWidth,
    chartHeight
  );

  return (
    <StyledSensorChart ref={containerRef} chartHeight={getChartHeight(height, !isEmpty(sort))}>
      {chartSkeleton ?? (
        <HighchartsReact
          ref={chartRef}
          highcharts={Highstock}
          options={chartOptions}
          containerProps={CONTAINER_PROPS}
          constructorType={isEmpty(sort) ? 'stockChart' : 'chart'}
          immutable={isPreview}
        />
      )}
    </StyledSensorChart>
  );
};

export default DefaultCustomChart;

DefaultCustomChart.displayName = 'DefaultCustomChart';

DefaultCustomChart.defaultProps = {
  loading: false,
  error: false,
  sensorData: [],
  options: {},
  sort: [],
  isPreview: false,
  noNavigator: false,
  noNavigatorSeries: false,
};

DefaultCustomChart.propTypes = {
  sensorData: PropTypes.arrayOf(
    PropTypes.shape({
      sensor: PropTypes.shape({
        sensorType: PropTypes.shape({
          unit: PropTypes.string,
          graphType: PropTypes.string,
        }),
        name: PropTypes.string,
        sensorMeta: PropTypes.array,
        displayName: PropTypes.string,
      }),
      data: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.number,
          timestamp: PropTypes.any,
        })
      ),
      openHours: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    })
  ).isRequired,
  sort: PropTypes.arrayOf(
    PropTypes.shape({
      sensorId: PropTypes.number,
      order: PropTypes.oneOf(['asc', 'desc']),
    })
  ),
  loading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  error: PropTypes.bool,
  options: PropTypes.shape({
    graphType: PropTypes.string,
    openHourBands: PropTypes.arrayOf(PropTypes.array),
    buildingTimezone: PropTypes.string,
    plotOptions: PropTypes.object,
  }),
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  aggregationFreq: PropTypes.string,
  annotations: PropTypes.array,
  setExtremes: PropTypes.func,
  isPreview: PropTypes.bool,
  verticalZoom: PropTypes.bool,
  noNavigator: PropTypes.bool,
  noNavigatorSeries: PropTypes.bool,
  useBoostModule: PropTypes.bool,
  onLoad: PropTypes.func,
};
