import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { startOfYear, endOfYear } from 'date-fns';

import { LOAD_SUCCESS as LOAD_ORDER_SUCCESS, verifyDates } from './serviceOrders';
import { UI_ONLY_FIELDS } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/constants';
import { OrderStatus } from 'constants/maintenance';

const initialState = {
  // new service request feature:
  loading: [],
  serviceRequests: {},
  error: '',
  // public service request feature:
  counts: {
    loading: false,
    partnerNumber: {},
    functionalLocation: {},
  },
  calendarRequests: {
    loading: false,
    partnerNumber: {},
    functionalLocation: {},
    serviceOrderNumber: {},
  },
};

export const CREATE = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE';
export const CREATE_SUCCESS = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE_SUCCESS';
export const CREATE_FAIL = 'CUSTOMER_PLATFORM/ServiceRequests/CREATE_FAIL';

export const create = serviceRequest => {
  return async dispatch => {
    dispatch({ type: CREATE });
    try {
      const result = await dispatch(MasterDataService.createServiceRequest(serviceRequest));
      return dispatch({
        key: JSON.stringify([CREATE, serviceRequest]),
        type: CREATE_SUCCESS,
        precondition: (store, action) => store.serviceRequests.loading.indexOf(action.key) < 0,
        result,
      });
    } catch (error) {
      return dispatch({
        key: JSON.stringify([CREATE, serviceRequest]),
        type: CREATE_FAIL,
        error: 'Creating a new service request failed!',
      });
    }
  };
};

export const MESSAGE = 'CUSTOMER_PLATFORM/ServiceRequests/MESSAGE';
export const MESSAGE_SUCCESS = 'CUSTOMER_PLATFORM/ServiceRequests/MESSAGE_SUCCESS';
export const MESSAGE_FAIL = 'CUSTOMER_PLATFORM/ServiceRequests/MESSAGE_FAIL';

export const sendSapMessage = message => async dispatch => {
  dispatch({ type: MESSAGE });
  try {
    const result = await dispatch(MasterDataService.sendSapServiceOrderMessage(message));
    return dispatch({
      type: MESSAGE_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: MESSAGE_FAIL,
      error,
    });
  }
};

export const softDeleteServiceRequest = (serviceOrderNumber, partnerNumber) => async (dispatch, getState) => {
  const serviceRequest = getState().serviceRequests.calendarRequests.serviceOrderNumber[serviceOrderNumber];

  if (!serviceRequest) {
    throw new Error('Cannot find service request for soft delete');
  }

  const result = await dispatch(
    MasterDataService.upsertServiceOrder({
      ...omit(serviceRequest, UI_ONLY_FIELDS),
      status: 'Cancelled',
      partnerNumber: [partnerNumber],
    })
  );
  dispatch({
    type: LOAD_ORDER_SUCCESS,
    loadOne: true,
    result: [result],
  });
};

export const LOAD_COUNT = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD_COUNT';
export const LOAD_COUNT_SUCCESS = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD_COUNT_SUCCESS';
export const LOAD_COUNT_FAIL = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD_COUNT_FAIL';

export const loadServiceRequestCount =
  ({
    startDate = startOfYear(new Date()),
    endDate = endOfYear(new Date()),
    functionalLocation,
    partnerNumber,
    refreshCache = false,
  }) =>
  async dispatch => {
    dispatch({ type: LOAD_COUNT });

    try {
      verifyDates(startDate, endDate);

      const serviceOrders = await MasterDataService.serviceOrdersCounts({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        functionalLocation,
        partnerNumber,
        status: [OrderStatus.REQUEST],
        refreshCache,
      });
      return dispatch({
        type: LOAD_COUNT_SUCCESS,
        result: serviceOrders.count,
        functionalLocation,
        partnerNumber: !functionalLocation && partnerNumber,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_COUNT_FAIL,
        error,
      });
    }
  };

export const LOAD = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/ServiceRequests/LOAD_FAIL';

export const loadServiceRequests =
  ({
    startDate = startOfYear(new Date()),
    endDate = endOfYear(new Date()),
    functionalLocation,
    partnerNumber,
    refreshCache = false,
  }) =>
  async dispatch => {
    dispatch({ type: LOAD });

    try {
      verifyDates(startDate, endDate);

      const result = await dispatch(
        MasterDataService.serviceOrders({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          functionalLocation,
          partnerNumber,
          status: [OrderStatus.REQUEST],
          refreshCache,
        })
      );
      return dispatch({
        type: LOAD_SUCCESS,
        result,
        functionalLocation,
        partnerNumber,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        error,
      });
    }
  };

export default createReducerFromMapping(
  {
    [CREATE]: (state, action) => ({
      ...state,
      loading: state.loading.concat([action.key]),
    }),
    [CREATE_SUCCESS]: (state, action) => ({
      ...state,
      loading: state.loading.filter(x => x !== action.key),
    }),
    [CREATE_FAIL]: (state, action) => ({
      ...state,
      loading: state.loading.filter(x => x !== action.key),
      error: action.error,
    }),
    [LOAD_COUNT]: state => ({
      ...state,
      counts: {
        ...initialState.counts,
        loading: true,
      },
    }),
    [LOAD_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      counts: {
        loading: false,
        partnerNumber: {
          ...state.counts.partnerNumber,
          [action.partnerNumber]: action.result,
        },
        functionalLocation: {
          ...state.counts.functionalLocation,
          [action.functionalLocation]: action.result,
        },
      },
    }),
    [LOAD_COUNT_FAIL]: state => ({
      ...state,
      counts: {
        ...state.counts,
        loading: false,
      },
    }),

    [LOAD]: state => ({
      ...state,
      calendarRequests: {
        ...initialState.calendarRequests,
        loading: true,
      },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      calendarRequests: {
        loading: false,
        partnerNumber: {
          ...state.calendarRequests.partnerNumber,
          [action.partnerNumber]: action.result,
        },
        functionalLocation: {
          ...state.calendarRequests.functionalLocation,
          ...groupBy(action.result, 'functionalLocation'),
        },
        serviceOrderNumber: {
          ...state.calendarRequests.serviceOrderNumber,
          ...keyBy(action.result, 'serviceOrderNumber'),
        },
      },
    }),
    [LOAD_FAIL]: state => ({
      ...state,
      calendarRequests: {
        ...state.calendarRequests,
        loading: false,
      },
    }),
  },
  initialState
);
