import { createReducerFromMapping } from 'redux/utils/index';
import remoteCenterService from 'services/remoteCenter';
import filter from 'lodash/filter';
import cloneDeep from 'lodash/cloneDeep';
import subYears from 'date-fns/subYears';
import { startOfUTCDay } from 'utils/Date/date';
import parseISO from 'date-fns/parseISO';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import getYear from 'date-fns/getYear';
import { getAuthorizationParams } from 'utils/profile/index';

const initialState = {
  byFL: {},
  observationsByPartner: {},
  inspectionsByPartner: {},
  slaByFL: {},
  loading: false,
  loadingSLA: false,
  loadingForPartner: false,
  observationKPI: {
    loading: false,
  },
  inspectionKPI: {
    loading: false,
  },
  observationPerformance: {},
  monthlyInspections: {},
  observationBenchmark: {},
  columnConfig: null,
};

const dateNow = new Date();
const yearAgo = startOfUTCDay(subYears(dateNow, 1));

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_FOR_FL';
export const LOAD_INSPECTIONS_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_FL_SUCCESS';
export const LOAD_OBSERVATIONS_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationObservations = (functionalLocation, partnerNumber, energyImpact) => {
  const filter = {
    functionalLocations: [functionalLocation.functionalLocation],
    partnerNumbers: [partnerNumber],
    energyImpact,
  };
  return async dispatch => {
    dispatch({ type: LOAD_FOR_FL });
    try {
      const result = await dispatch(remoteCenterService.observationsForFL(filter));

      return dispatch({
        type: LOAD_OBSERVATIONS_FOR_FL_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FL_FAIL,
        error,
      });
    }
  };
};

export const loadFunctionalLocationInspections = (functionalLocation, partnerNumber) => {
  const filter = { functionalLocations: [functionalLocation.functionalLocation], partnerNumbers: [partnerNumber] };
  return async dispatch => {
    dispatch({ type: LOAD_FOR_FL });
    try {
      const result = await dispatch(remoteCenterService.inspectionsForFL(filter));

      return dispatch({
        type: LOAD_INSPECTIONS_FOR_FL_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FL_FAIL,
        error,
      });
    }
  };
};

export const LOAD_OBSERVATIONS_FOR_PARTNER = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER';
export const LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS';
export const LOAD_OBSERVATIONS_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATIONS_FOR_PARTNER_FAIL';

export const loadPartnerObservations = (partnerNumber, where) => async (dispatch, getState) => {
  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({ profile, partnerNumber });

  const filter = {
    ...authorizationParams,
  };

  where.end = where.end || dateNow;

  if (where.start) {
    filter.start = where.start.toISOString();
    filter.end = where.end.toISOString();
  }

  if (typeof where.wasteObservation !== 'undefined') {
    filter.wasteObservation = where.wasteObservation;
  }

  if (typeof where.energyImpact !== 'undefined') {
    filter.energyImpact = where.energyImpact;
  }

  const year = where.end.getUTCFullYear();

  dispatch({ type: LOAD_OBSERVATIONS_FOR_PARTNER, year });

  try {
    const result = await dispatch(remoteCenterService.observationsForPartner(filter));

    return dispatch({
      type: LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS,
      partnerNumber,
      result,
      year,
      isEnergy: where?.energyImpact,
      isRecycling: where?.wasteObservation,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_OBSERVATIONS_FOR_PARTNER_FAIL,
      error,
      year,
    });
  }
};

export const LOAD_INSPECTIONS_FOR_PARTNER = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER';
export const LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS';
export const LOAD_INSPECTIONS_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTIONS_FOR_PARTNER_FAIL';

export const loadPartnerInspections = (partnerNumber, where) => async (dispatch, getState) => {
  dispatch({ type: LOAD_INSPECTIONS_FOR_PARTNER });

  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({ profile, partnerNumber });

  const filter = {
    ...authorizationParams,
  };

  where.end = where.end || dateNow;

  if (where.start) {
    filter.start = where.start.toISOString();
    filter.end = where.end.toISOString();
  }

  try {
    const result = await dispatch(remoteCenterService.inspectionsForPartner(filter));
    const slaResult = await dispatch(remoteCenterService.SLAsForPartner(filter));

    return dispatch({
      type: LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS,
      partnerNumber,
      result,
      slaResult: slaResult,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_INSPECTIONS_FOR_PARTNER_FAIL,
      error,
    });
  }
};

export const LOAD_MONTHLY_INSPECTIONS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS';
export const LOAD_MONTHLY_INSPECTIONS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS_SUCCESS';
export const LOAD_MONTHLY_INSPECTIONS_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_MONTHLY_INSPECTIONS_FAIL';

export const loadMonthlyInspections =
  (partnerNumber, { start, end }) =>
  async (dispatch, getState) => {
    dispatch({ type: LOAD_MONTHLY_INSPECTIONS });

    const profile = getState().profile.profile;
    const authorizationParams = getAuthorizationParams({ profile, partnerNumber });

    try {
      const result = await dispatch(
        remoteCenterService.inspectionCountsByMonth({
          ...authorizationParams,
          start: start.toISOString(),
          end: end.toISOString(),
        })
      );

      return dispatch({
        type: LOAD_MONTHLY_INSPECTIONS_SUCCESS,
        partnerNumber,
        result,
        year: getYear(start),
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MONTHLY_INSPECTIONS_FAIL,
        error,
      });
    }
  };

export const LOAD_SLA_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL';
export const LOAD_SLA_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL_SUCCESS';
export const LOAD_SLA_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_SLA_FOR_FL_FAIL';

export const loadFunctionalLocationSla = (functionalLocation, partnerNumber) => {
  const filter = {
    functionalLocations: [functionalLocation.functionalLocation],
    partnerNumbers: [partnerNumber],
  };

  return async dispatch => {
    dispatch({ type: LOAD_SLA_FOR_FL });
    try {
      const result = await dispatch(remoteCenterService.SLAsForFL(filter));

      return dispatch({
        type: LOAD_SLA_FOR_FL_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result: result[0],
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SLA_FOR_FL_FAIL,
        error,
      });
    }
  };
};

export const LOAD_OBSERVATION_KPI = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI';
export const LOAD_OBSERVATION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI_SUCCESS';
export const LOAD_OBSERVATION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_KPI_FAIL';

export const loadObservationKpi =
  (partnerNumber, { start, end }) =>
  async dispatch => {
    dispatch({ type: LOAD_OBSERVATION_KPI });
    try {
      const result = await dispatch(
        remoteCenterService.observationsKPIForPartner({
          partnerNumbers: [partnerNumber],
          start: start.toISOString(),
          end: end.toISOString(),
        })
      );

      return dispatch({
        type: LOAD_OBSERVATION_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_OBSERVATION_KPI_FAIL,
        error,
      });
    }
  };

export const LOAD_INSPECTION_KPI = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI';
export const LOAD_INSPECTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI_SUCCESS';
export const LOAD_INSPECTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_INSPECTION_KPI_FAIL';

export const loadInspectionKpi =
  (partnerNumber, { start, end }) =>
  async dispatch => {
    dispatch({ type: LOAD_INSPECTION_KPI });
    try {
      const result = await dispatch(
        remoteCenterService.inspectionKPIForPartner({
          partnerNumbers: [partnerNumber],
          start: start.toISOString(),
          end: end.toISOString(),
        })
      );

      return dispatch({
        type: LOAD_INSPECTION_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_INSPECTION_KPI_FAIL,
        error,
      });
    }
  };

export const LOAD_OBSERVATION_PERFORMANCE = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE';
export const LOAD_OBSERVATION_PERFORMANCE_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE_SUCCESS';
export const LOAD_OBSERVATION_PERFORMANCE_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_PERFORMANCE_FAIL';

export const loadObservationPerformance =
  (partnerNumber, { start, end }) =>
  async dispatch => {
    dispatch({ type: LOAD_OBSERVATION_PERFORMANCE, partnerNumber });
    try {
      const result = await dispatch(
        remoteCenterService.observationPerformance({
          partnerNumbers: [partnerNumber],
          start: start.toISOString(),
          end: end.toISOString(),
        })
      );
      dispatch({
        type: LOAD_OBSERVATION_PERFORMANCE_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_OBSERVATION_PERFORMANCE_FAIL,
        partnerNumber,
        error,
      });
    }
  };

export const LOAD_OBSERVATION_BENCHMARK = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_BENCHMARK';
export const LOAD_OBSERVATION_BENCHMARK_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_BENCHMARK_SUCCESS';
export const LOAD_OBSERVATION_BENCHMARK_FAIL = 'CUSTOMER_PLATFORM/IoT_Notice/LOAD_OBSERVATION_BENCHMARK_FAIL';

export const loadObservationBenchmark = partnerNumber => async (dispatch, getState) => {
  dispatch({ type: LOAD_OBSERVATION_BENCHMARK });

  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({ profile, partnerNumber });

  try {
    const result = await dispatch(remoteCenterService.observationBenchmark(authorizationParams));
    dispatch({
      type: LOAD_OBSERVATION_BENCHMARK_SUCCESS,
      partnerNumber,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_OBSERVATION_BENCHMARK_FAIL,
      error,
    });
  }
};

export const SET_OBSERVATIONS_COLUMNS = 'CUSTOMER_PLATFORM/IoT_Notice/SET_OBSERVATIONS_COLUMNS';

export const setColumnConfig = config => ({ type: SET_OBSERVATIONS_COLUMNS, config });

export default createReducerFromMapping(
  {
    [LOAD_FOR_FL]: state => ({
      ...state,
      loading: true,
    }),

    [LOAD_SLA_FOR_FL]: state => ({
      ...state,
      loadingSLA: true,
    }),

    [LOAD_OBSERVATIONS_FOR_FL_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      byFL: (data => {
        data[action.functionalLocation] = {
          ...data[action.functionalLocation],
          observations: filter(action.result, { type: 'observation' }).map(mapToSortableData),
        };

        return data;
      })(cloneDeep(state.byFL)),
    }),

    [LOAD_INSPECTIONS_FOR_FL_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      byFL: (data => {
        data[action.functionalLocation] = {
          ...data[action.functionalLocation],
          inspections: filter(action.result, notice => {
            const date = parseISO(notice?.timestamp);
            return (
              ['extended', 'limited', 'nightly', 'adhoc'].indexOf(notice?.type) !== -1 &&
              (isAfter(date, yearAgo) || isEqual(date, yearAgo))
            );
          }).map(mapToSortableData),
        };

        return data;
      })(cloneDeep(state.byFL)),
    }),

    [LOAD_SLA_FOR_FL_SUCCESS]: (state, action) => ({
      ...state,
      loadingSLA: false,
      slaByFL: Object.assign({}, state.slaByFL, { [action.functionalLocation]: action.result }),
    }),

    [LOAD_SLA_FOR_FL_FAIL]: (state, action) => ({
      ...state,
      loadingSLA: false,
      error: action.error,
    }),

    [LOAD_FOR_FL_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),

    [LOAD_OBSERVATION_KPI]: state => ({
      ...state,
      observationKPI: {
        ...state.observationKPI,
        loading: true,
      },
    }),

    [LOAD_OBSERVATION_KPI_SUCCESS]: (state, action) => ({
      ...state,
      observationKPI: {
        ...state.observationKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_OBSERVATION_KPI_FAIL]: (state, action) => ({
      ...state,
      observationKPI: {
        ...state.observationKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_INSPECTION_KPI]: state => ({
      ...state,
      inspectionKPI: {
        ...state.inspectionKPI,
        loading: true,
      },
    }),

    [LOAD_INSPECTION_KPI_SUCCESS]: (state, action) => ({
      ...state,
      inspectionKPI: {
        ...state.inspectionKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_INSPECTION_KPI_FAIL]: (state, action) => ({
      ...state,
      inspectionKPI: {
        ...state.inspectionKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_OBSERVATION_PERFORMANCE]: (state, action) => ({
      ...state,
      observationPerformance: {
        ...state.observationPerformance,
        [action.partnerNumber]: {
          loading: true,
        },
      },
    }),
    [LOAD_OBSERVATION_PERFORMANCE_SUCCESS]: (state, action) => ({
      ...state,
      observationPerformance: {
        ...state.observationPerformance,
        [action.partnerNumber]: {
          loading: false,
          data: action.result,
        },
      },
    }),
    [LOAD_OBSERVATION_PERFORMANCE_FAIL]: (state, action) => ({
      ...state,
      observationPerformance: {
        ...state.observationPerformance,
        [action.partnerNumber]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_OBSERVATION_BENCHMARK]: state => ({
      ...state,
      observationBenchmark: {
        ...state.observationBenchmark,
        loading: true,
      },
    }),

    [LOAD_OBSERVATION_BENCHMARK_SUCCESS]: (state, action) => ({
      ...state,
      observationBenchmark: {
        ...state.observationBenchmark,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_OBSERVATION_BENCHMARK_FAIL]: (state, action) => ({
      ...state,
      observationBenchmark: {
        ...state.observationBenchmark,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER]: (state, action) => ({
      ...state,
      loadingForPartner: true,
      observationsByPartner: {
        ...state.observationsByPartner,
        [action.partnerNumber]: {
          ...state.observationsByPartner[action.partnerNumber],
          [action.year]: { loading: true },
        },
      },
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER_SUCCESS]: (state, action) => ({
      ...state,
      loadingForPartner: false,
      observationsByPartner: {
        ...state.observationsByPartner,
        [action.partnerNumber]: {
          ...state.observationsByPartner[action.partnerNumber],
          ...(!action.isEnergy && !action.isRecycling && { current: action.result.map(mapToSortableData) }),
          ...(action.isEnergy && { energy: action.result.map(mapToSortableData) }),
          ...(action.isRecycling && { recycling: action.result.map(mapToSortableData) }),
          [action.year]: {
            data: filter(action.result, observation => {
              const date = parseISO(observation.timestamp);
              return date.getUTCFullYear() === action.year;
            }).map(mapToSortableData),
            loading: false,
          },
        },
      },
    }),

    [LOAD_OBSERVATIONS_FOR_PARTNER_FAIL]: (state, action) => ({
      ...state,
      loadingForPartner: false,
      observationsByPartner: {
        ...state.observationsByPartner,
        [action.partnerNumber]: {
          ...state.observationsByPartner[action.partnerNumber],
          [action.year]: { loading: false, error: action.error },
        },
      },
      error: action.error,
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER]: (state, action) => ({
      ...state,
      inspectionsByPartner: {
        ...state.inspectionsByPartner,
        [action.partnerNumber]: { loading: true },
      },
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER_SUCCESS]: (state, action) => ({
      ...state,
      inspectionsByPartner: {
        ...state.inspectionsByPartner,
        [action.partnerNumber]: { loading: false, data: action.result, slaData: action.slaResult },
      },
    }),

    [LOAD_INSPECTIONS_FOR_PARTNER_FAIL]: (state, action) => ({
      ...state,
      inspectionsByPartner: {
        ...state.inspectionsByPartner,
        [action.partnerNumber]: { loading: false, error: action.error },
      },
      error: action.error,
    }),

    [LOAD_MONTHLY_INSPECTIONS]: (state, action) => ({
      ...state,
      monthlyInspections: {
        ...state.monthlyInspections,
        [action.partnerNumber]: { loading: true },
      },
    }),

    [LOAD_MONTHLY_INSPECTIONS_SUCCESS]: (state, action) => ({
      ...state,
      monthlyInspections: {
        ...state.monthlyInspections,
        [action.partnerNumber]: { loading: false, [action.year]: action.result },
      },
    }),

    [LOAD_MONTHLY_INSPECTIONS_FAIL]: (state, action) => ({
      ...state,
      monthlyInspections: {
        ...state.monthlyInspections,
        [action.partnerNumber]: { loading: false, error: action.error },
      },
      error: action.error,
    }),
    [SET_OBSERVATIONS_COLUMNS]: (state, action) => ({
      ...state,
      columnConfig: action.config,
    }),
  },
  initialState
);

// Mapping used to make it work with sortable table -component
const mapToSortableData = row => {
  const consumptions = [];
  if (row.effectHeatValue) {
    consumptions.push({
      title: 'heating',
      value: row.effectHeatValue,
      unit: row.effectHeatingValueUnit,
    });
  }
  if (row.effectWaterValue) {
    consumptions.push({
      title: 'water',
      value: row.effectWaterValue,
      unit: row.effectWaterValueUnit,
    });
  }
  if (row.effectElectricityValue) {
    consumptions.push({
      title: 'electricity',
      value: row.effectElectricityValue,
      unit: row.effectElectricityValueUnit,
    });
  }
  let actionType = '';
  if (row.savingsInvestment === true) {
    actionType = 'actionTypeTechnical';
  }
  if (row.savingsAdjustment === true) {
    actionType = 'actionTypeOperational';
  }
  const date = {
    date: parseISO(row.createdOn ? row.createdOn : row.timestamp),
    value: parseISO(row.createdOn ? row.createdOn : row.timestamp),
  };
  const modifiedOn = row.modifiedOn
    ? {
        date: parseISO(row.modifiedOn),
        value: parseISO(row.modifiedOn),
      }
    : null;
  const startTime = row.startTime
    ? {
        date: parseISO(row.startTime),
        value: parseISO(row.startTime),
      }
    : null;
  const estimatedExecutionDate = row.estimatedExecutionDate
    ? {
        date: parseISO(row.estimatedExecutionDate),
        value: parseISO(row.estimatedExecutionDate),
      }
    : null;

  return {
    ...row,
    location: {
      value: row.device,
      description: row.description,
      consumptions,
    },
    date,
    modifiedOn,
    startTime,
    estimatedExecutionDate,
    status: {
      value: statusMapper(row.status),
      status: row.status,
      title: row.status,
    },
    system: {
      value: row.deviceGroup || 'other',
      icon: row.deviceGroup ? row.deviceGroup.charAt(0).toUpperCase() + row.deviceGroup.slice(1) : 'Other',
    },
    actionType,
    savingsPotentialSum: (row.savingsPotentialElectricity || 0) + (row.savingsPotentialHeat || 0),
  };
};

const statusMapper = status => {
  switch (status) {
    case 'open':
      return 1;
    case 'started':
      return 2;
    case 'completed':
      return 3;
    default:
      return 1;
  }
};
