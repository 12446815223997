import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import withQuery from 'decorators/Query/withQuery';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

import { getSectionTitleText, scrollTo, getSectionSubjectText, SLICE_TYPE } from './UserManualUtils';
import { BackLink } from './UserManualBackLink';
import SkeletonText from 'components/Skeletons/SkeletonText';

const StyledUserManualSectionListDesktop = styled.div`
  display: none;
  background-color: ${props => props.theme.colors.midnight};
  ${props => props.theme.media.landscape`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 0;
        padding-top: 48px;
        bottom: 0;
        width: 25%;
        max-width: 300px;
        padding-bottom: var(--size-md);
        transition: padding 0.1s ease-in;
    `}
  h3 {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.colors.lightGray};
    line-height: 1.5;
  }
`;

StyledUserManualSectionListDesktop.displayName = 'StyledUserManualSectionListDesktop';

const StyledUserManualSectionListMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: auto;
  width: 100%;
  top: 0;
  padding-top: ${props => (props.top ? 0 : props.theme.navigation.height)};
  transition: padding 0.1s ease-in;
  background-color: ${props => props.theme.colors.midnight};
  h3 {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.colors.lightGray};
    line-height: 1.5;
  }
  ${props => props.theme.media.landscape`
        display: none;
    `}
`;

StyledUserManualSectionListMobile.displayName = 'StyledUserManualSectionListMobile';

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.rockBlue};
`;

Separator.displayName = 'Separator';

const UserManualSectionHeader = styled.p`
  width: 100%;
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  text-transform: uppercase;
  text-align: center;
  padding: var(--size-md);
  ${props => props.theme.media.landscape`
        padding: var(--size-xl);
        text-align: left;
    `}
  color: ${props => props.theme.colors.white};
`;

UserManualSectionHeader.displayName = 'UserManualSectionHeader';

const UserManualSectionTitles = styled.a`
  display: flex;
  padding: var(--size-xs) var(--size-xl);
  h3 {
    margin-left: var(--size-md);
  }
  h2,
  h3 {
    color: ${props => props.theme.colors.rockBlue};
    font-size: ${props => props.theme.font.size.sm};
    &:hover {
      cursor: pointer;
    }
  }
`;

UserManualSectionTitles.displayName = 'UserManualSectionTitles';

const UserManualSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;
UserManualSectionContainer.displayName = 'UserManualSectionContainer';

export class UserManualSectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSection: this.getQueryString(),
    };
  }

  getSectionContentDesktop = sectionContent => (
    <UserManualSectionContainer>
      {sectionContent.map((singleSectionContent, index) => {
        switch (singleSectionContent.slice_type) {
          case SLICE_TYPE.SECTION_SUBJECT: {
            const subject = getSectionSubjectText(singleSectionContent);
            return (
              <UserManualSectionTitles onClick={() => this.setSelectedSection(subject)} key={index}>
                {RichText.render(singleSectionContent.primary.contentSubject)}
              </UserManualSectionTitles>
            );
          }
          case SLICE_TYPE.CONTENT_SECTION: {
            const title = getSectionTitleText(singleSectionContent);
            return (
              <UserManualSectionTitles onClick={() => this.setSelectedSection(title)} key={index}>
                {RichText.render(singleSectionContent.primary.contentTitle)}
              </UserManualSectionTitles>
            );
          }
          default:
            return null;
        }
      })}
    </UserManualSectionContainer>
  );

  getSkeleton = () => (
    <UserManualSectionContainer>
      <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
      <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
      <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
      <SkeletonText style={{ width: '80%', marginBottom: '1em' }} />
    </UserManualSectionContainer>
  );

  setSelectedSection = selectedSection => this.setState({ selectedSection }, () => scrollTo(selectedSection));

  getQueryString = () => this.props.query.title ?? '';

  render() {
    const {
      t,
      data,
      scroll: { scrollTop, scrollingUp },
      loading,
    } = this.props;
    const top = scrollingUp ? scrollTop > 64 + 20 : scrollTop > 20;

    return (
      <Fragment>
        <StyledUserManualSectionListDesktop>
          <BackLink text={t('Go Back')} />
          <Separator />
          <UserManualSectionHeader>{t('table of contents')}</UserManualSectionHeader>
          {loading && this.getSkeleton()}
          {!loading && data && data.body && this.getSectionContentDesktop(data.body)}
        </StyledUserManualSectionListDesktop>
        <StyledUserManualSectionListMobile top={top}>
          <UserManualSectionHeader>{t('table of contents')}</UserManualSectionHeader>
        </StyledUserManualSectionListMobile>
      </Fragment>
    );
  }
}

UserManualSectionList.propTypes = {
  t: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  scroll: PropTypes.shape({
    scrollTop: PropTypes.number.isRequired,
    scrollingUp: PropTypes.bool.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
};

export default withQuery(translations(UserManualSectionList));
