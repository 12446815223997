import React from 'react';
import MarkdownImages from './MarkdownImages';

const imageProperties = image => {
  const caption = image.properties.alt?.replace(/ *\{[^)]*\} */g, '');
  return {
    src: image.properties.src,
    caption: caption,
    alt: caption,
  };
};
export const markdownImageComponent = paragraph => {
  const { node } = paragraph;
  const images = node.children.filter(isImage => {
    return isImage.tagName === 'img';
  });

  if (images.length > 0) {
    const imageData = images.map(image => imageProperties(image));

    return <MarkdownImages imageData={imageData} />;
  }

  return <p>{paragraph.children}</p>;
};
