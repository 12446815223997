import { ServiceOrderLogEventType } from '@caverion/loopback-shared/lb4/types';

export const createEventType = (order, newLog) => {
  if (order.status !== newLog.status) {
    return ServiceOrderLogEventType.StatusUpdate;
  }
  return ServiceOrderLogEventType.CommentCreate;
};

export const TECHNICIAN_STATUSES = ['Open', 'In Progress', 'Partly Completed', 'Completed', 'Draft'];
export const NON_TECHNICIAN_STATUSES = ['Open', 'In Progress', 'Partly Completed', 'Completed'];

export const statusLabels = {
  Draft: 'Reject assignment',
};
