import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';

import ServiceModuleDocuments from 'containers/Application/ServiceModule/ServiceModuleForm/ServiceModuleDocuments/ServiceModuleDocuments';
import ServiceModuleDocumentUpload from 'containers/Application/ServiceModule/ServiceModuleForm/ServiceModuleDocuments/ServiceModuleDocumentUpload';

const Container = styled.div`
  padding-bottom: var(--size-lg);
`;

const TechniciansDocuments = ({
  t,
  loadingDocuments,
  documents,
  onDocumentDownload,
  newFiles,
  onFileUpload,
  onFileRemoval,
  uploading,
  erroredFiles,
  onUploadButtonClick,
}) => {
  return (
    <Container>
      <h4>{t('Documents')}</h4>
      <ServiceModuleDocuments loading={loadingDocuments} files={documents} downloadFile={onDocumentDownload} />
      <ServiceModuleDocumentUpload
        files={newFiles}
        erroredFiles={erroredFiles}
        onFileUpload={onFileUpload}
        onFileRemoval={onFileRemoval}
        onButtonClick={onUploadButtonClick}
        submitting={uploading}
      />
    </Container>
  );
};

TechniciansDocuments.propTypes = {
  t: PropTypes.func.isRequired,
  loadingDocuments: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired,
  onDocumentDownload: PropTypes.func.isRequired,
  newFiles: PropTypes.array.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileRemoval: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  erroredFiles: PropTypes.array.isRequired,
  onUploadButtonClick: PropTypes.func.isRequired,
};

export default translations(TechniciansDocuments);
