import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import withQuery from 'decorators/Query/withQuery';
import isEmpty from 'lodash/isEmpty';

import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import ObservationsFilter from './ObservationsFilter';
import InvestmentCalendar from 'components/BuildingEvents/Observations/InvestmentCalendar';
import ObservationsTable from 'components/BuildingEvents/Observations/ObservationsTable';
import ObservationsTableConfigurationModal from 'components/BuildingEvents/Observations/ObservationsTableConfigurationModal';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import { downloadCsv } from 'components/Modules/ControlRoomModule/utils';

import { DEFAULT_PORTFOLIO_COLUMNS } from 'constants/observations';
import { filterObservationsWithDateFilter, filterObservationsWithoutDateFilter } from 'utils/Data/notice';
import { dropdownItems, orderByTimestamp } from './ObservationsModalUtils';
import { getColumns } from '../BuildingEvents/Observations/observationsTableColumns';
import Content from 'components/Content/Content';
import { defaultFilterValues, OBSERVATION_TABS, convertObservationsToInvestments } from './utils';
import useObservationsFilters from './useObservationsFilters';

const DialogHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${props => props.theme.media.landscape`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}

  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.fontWeight.heading};
  margin-bottom: ${props => props.theme.section.verticalSpacing};
`;

const HeaderButtons = styled.div`
  display: flex;
  margin-bottom: var(--size-sm);

  ${props => props.theme.media.landscape`
    margin-bottom: 0;
  `};

  @media print {
    display: none;
  }
`;

const MIN_HEIGHT = 475;

const TableWrapper = styled.section`
  background-color: var(--white);

  ${props => props.theme.media.landscape`
    min-height: ${MIN_HEIGHT}px;
    max-width: ${props => !props.fullScreen && props.theme.grid.maxWidth};
  `};
`;

export const ObservationsModalContent = ({
  t,
  observations = [],
  loading,
  functionalLocations,
  defaultColumns = DEFAULT_PORTFOLIO_COLUMNS,
  query,
  setQuery,
  fullScreen,
  selectObservation,
}) => {
  const [showListConfiguration, setShowListConfiguration] = React.useState(false);
  const { filters } = useObservationsFilters();
  const selectedTab = query.selectedModalTab || OBSERVATION_TABS.OBSERVATIONS;

  const selectFunctionalLocation = functionalLocation => {
    setQuery({
      selectedModalTab: OBSERVATION_TABS.OBSERVATIONS,
      functionalLocation,
    });
  };
  const selectTab = tabName => {
    setQuery({ selectedModalTab: tabName });
  };

  const initialFilterValues = { ...defaultFilterValues, observationsModalVisible: true };

  // Set initial filters to query
  useEffect(() => {
    setQuery({ ...initialFilterValues, ...query }, { useReplace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const observationsFilter = { ...filters };
  const filteredWithoutDateObservations = filterObservationsWithoutDateFilter(observations, observationsFilter);
  const filteredObservations = filterObservationsWithDateFilter(filteredWithoutDateObservations, observationsFilter);
  const investments = React.useMemo(
    () => convertObservationsToInvestments(filteredObservations, observationsFilter.investmentSumField),
    [filteredObservations, observationsFilter.investmentSumField]
  );
  const columns = getColumns(t, isEmpty(functionalLocations));

  const downloadObservations = () =>
    downloadCsv('Observations', { observations: orderByTimestamp(filteredObservations), functionalLocations });
  const downloadInvestments = () => downloadCsv('InvestmentCalendar', { investments, functionalLocations });

  return (
    <>
      <DialogHeader>
        <SectionTabSelector
          options={Object.values(OBSERVATION_TABS).map(value => ({ value, label: t(value) }))}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(property, value) => selectTab(value)}
          noMobilePadding
        />
        <HeaderButtons>
          <ButtonDropdown
            secondary
            buttonProps={{ iconName: 'fa-ellipsis-h' }}
            alignment="right"
            textAlign="left"
            items={dropdownItems({
              t,
              selectedTab,
              clickHandlers: {
                onClickDownloadObservations: downloadObservations,
                onClickDownloadInvestments: downloadInvestments,
                onShowObservationListConfiguration: () => setShowListConfiguration(true),
              },
            })}
          />
        </HeaderButtons>
      </DialogHeader>
      <Content>
        <ObservationsFilter
          observations={observations}
          loading={loading}
          functionalLocations={functionalLocations}
          filteredObservations={filteredWithoutDateObservations}
          selectedTab={selectedTab}
          defaultFilterValues={initialFilterValues}
        />
        <TableWrapper fullScreen={fullScreen}>
          {selectedTab === OBSERVATION_TABS.INVESTMENTS ? (
            <InvestmentCalendar
              loading={loading}
              investments={investments}
              functionalLocations={functionalLocations}
              selectFunctionalLocation={selectFunctionalLocation}
            />
          ) : (
            <ObservationsTable
              loading={loading}
              t={t}
              data={filteredObservations}
              onRowClick={selectObservation}
              showImprovement={true}
              functionalLocations={functionalLocations}
              defaultColumns={defaultColumns}
              columns={columns}
              hideConfiguration
            />
          )}
        </TableWrapper>
      </Content>
      <ObservationsTableConfigurationModal
        defaultColumns={defaultColumns}
        columns={columns}
        visible={showListConfiguration}
        onClose={() => setShowListConfiguration(false)}
      />
    </>
  );
};

ObservationsModalContent.propTypes = {
  t: PropTypes.func.isRequired,
  observations: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  functionalLocations: PropTypes.object,
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  query: PropTypes.shape({
    selectedModalTab: PropTypes.string,
  }),
  setQuery: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  selectObservation: PropTypes.func.isRequired,
};
export default withQuery(translations(ObservationsModalContent));
