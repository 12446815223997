import { createReducerFromMapping } from 'redux/utils/index';
import * as SriService from 'services/sri';
import { SriState } from 'types/Sri';
import { DispatchFunc, RootState } from 'types/Redux';

const initialState: SriState = {
  byFunctionalLocation: {},
};

interface CheckAvailabilitySuccessAction {
  type: string;
  functionalLocation: string;
  result: SriReportAvailability;
}

interface SriReportAvailability {
  functionalLocation: string;
  available: boolean;
}

interface CheckAvailabilityFailAction {
  type: string;
  functionalLocation: string;
  error: unknown;
}

export const CHECK_AVAILABILITY = 'CUSTOMER_PLATFORM/Sri/CHECK_AVAILABILITY';
export const CHECK_AVAILABILITY_SUCCESS = 'CUSTOMER_PLATFORM/Sri/CHECK_AVAILABILITY_SUCCESS';
export const CHECK_AVAILABILITY_FAIL = 'CUSTOMER_PLATFORM/Sri/CHECK_AVAILABILITY_FAIL';

export const checkSriAvailability = (functionalLocationId: string) => {
  return async (dispatch: DispatchFunc, getState: () => RootState) => {
    // Optimization to not call the API again for the same FL
    if (getState().sri.byFunctionalLocation[functionalLocationId]) {
      return;
    }
    dispatch({ type: CHECK_AVAILABILITY, functionalLocation: functionalLocationId });
    try {
      const result = await SriService.getSriReportAvailability(functionalLocationId);

      return dispatch({
        type: CHECK_AVAILABILITY_SUCCESS,
        functionalLocation: functionalLocationId,
        result,
      });
    } catch (error) {
      return dispatch({
        type: CHECK_AVAILABILITY_FAIL,
        functionalLocation: functionalLocationId,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [CHECK_AVAILABILITY_SUCCESS]: (state: SriState, action: CheckAvailabilitySuccessAction): SriState => ({
      ...state,
      byFunctionalLocation: {
        ...state.byFunctionalLocation,
        [action.functionalLocation]: {
          ...state.byFunctionalLocation[action.functionalLocation],
          enabled: action.result.available,
          error: null,
        },
      },
    }),

    [CHECK_AVAILABILITY_FAIL]: (state: SriState, action: CheckAvailabilityFailAction): SriState => ({
      ...state,
      byFunctionalLocation: {
        ...state.byFunctionalLocation,
        [action.functionalLocation]: {
          ...state.byFunctionalLocation[action.functionalLocation],
          enabled: false,
          error: action.error,
        },
      },
    }),
  },
  initialState
);
