import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircleTab from 'components/CircleTabs/CircleTab';
import translations from 'decorators/Translations/translations';

const FloorLabelButton = styled.span`
  white-space: nowrap;
  cursor: pointer;
`;

const FloorTabLabel = ({ label, index, selected, t, small, onSelectTab }) => (
  <CircleTab selected={selected} key={index} performance={label.performance} small={small}>
    <FloorLabelButton role="button" onClick={() => onSelectTab(label)}>
      {t(label.title || label.shortName)}
    </FloorLabelButton>
  </CircleTab>
);

FloorTabLabel.propTypes = {
  label: PropTypes.shape({
    title: PropTypes.string,
    shortName: PropTypes.string,
    performance: PropTypes.string,
  }),
  index: PropTypes.number,
  selected: PropTypes.bool,
  t: PropTypes.func,
  small: PropTypes.bool,
  onSelectTab: PropTypes.func,
};
export default translations(FloorTabLabel);
