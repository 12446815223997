import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';
import DateTools, { DATE_RANGES } from 'components/Form/DateTools';

const Tools = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--dialog-footer-bg);
  border-top: var(--light-separator-border);
  padding: ${props => props.theme.sensorValues.padding.mobile};

  ${props => props.theme.media.portrait`
    align-items: center;
    padding: var(--size-sm) ${props => props.theme.sensorValues.padding.tablet} var(--size-md);
  `}

  ${props => props.theme.media.desktop`
    padding: var(--size-sm) ${props => props.theme.sensorValues.padding.desktop} var(--size-md);
  `}

  @media print {
    display: none;
  }
`;
Tools.displayName = 'Tools';

const SensorTools = ({
  parameterModel,
  updateParameters,
  defaultRange = DATE_RANGES.DAYS_7,
  sensorGranularity,
  isUtilizationRate,
}) => {
  const [t] = useTranslations();

  const disabledButtons = React.useMemo(() => {
    if (isUtilizationRate) {
      return [DATE_RANGES.HOURS_24];
    }
    switch (sensorGranularity) {
      case 'month':
        return [DATE_RANGES.HOURS_24, DATE_RANGES.DAYS_7, DATE_RANGES.DAYS_30];
      case 'day':
        return [DATE_RANGES.HOURS_24];
      default:
        return undefined;
    }
  }, [isUtilizationRate, sensorGranularity]);

  const onParameterChange = React.useCallback(
    (property, value) => {
      updateParameters({
        [property]: value,
        isZoomed: false,
      });
    },
    [updateParameters]
  );

  return (
    <Tools>
      <DateTools
        t={t}
        model={parameterModel}
        onChange={onParameterChange}
        defaultRange={defaultRange}
        showArrows
        disabledButtons={disabledButtons}
        updateFromModel
      />
    </Tools>
  );
};

export default SensorTools;

SensorTools.propTypes = {
  // required:
  parameterModel: PropTypes.object.isRequired,
  updateParameters: PropTypes.func.isRequired,
  // optional:
  defaultRange: PropTypes.string,
  sensorGranularity: PropTypes.string,
  isUtilizationRate: PropTypes.bool,
};
