import React from 'react';

const LocalizationContext = React.createContext({
  translations: {},
  translationRowNumbers: {},
  showTranslationKeys: false,
  updateTranslations: () => {}, // eslint-disable-line no-empty-function
});

export default LocalizationContext;
