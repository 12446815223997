import { createReducerFromMapping } from 'redux/utils/index';

import { loadBuildingConditions } from 'redux/modules/iot/values/conditions';
import { loadSensorValues } from 'redux/modules/iot/values/sensor_values';
import { loadCleaning } from 'redux/modules/iot/values/cleaning';
import { loadLatestSensorValuesForAggregationGroups } from 'redux/modules/iot/values/sensor_values';

import { loadSensorAlarms } from 'redux/modules/iot/sensorAlarms';

import { loadSensorHierarchies, loadSensorDataTypes } from 'redux/modules/customer/sensorHierarchy';
import {
  loadFunctionalLocationObservations,
  loadFunctionalLocationInspections,
  loadFunctionalLocationSla,
} from 'redux/modules/iot/notice';
import { loadFunctionalLocationAlarms } from 'redux/modules/iot/alarm';
import { loadAnnouncementsByFL } from 'redux/modules/announcement/announcement';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { loadPartnerMeta } from 'redux/modules/customer/partnerMeta';
import { loadServiceRequestCount } from 'redux/modules';

import head from 'lodash/head';
import groupBy from 'lodash/groupBy';
import startsWith from 'lodash/startsWith';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';
import { utilizationTypes } from 'utils/Data/values';
import { isControlRoomTabEnabled, isServiceRequestToolsEnabled } from 'utils/Data/profileData';
import subYears from 'date-fns/subYears';
import { startOfUTCDay } from 'utils/Date/date';

const initialState = {
  // Make the default state loading so that the first load respects loading order via promise resolve
  loading: true,
  error: false,
};

function granularityToFrequency(granularity) {
  const mapping = {
    hour: 'hourly',
    day: 'daily',
    month: 'monthly',
  };
  return mapping[granularity];
}

function compareFrequencies(a, b) {
  const frequencies = {
    hourly: 5,
    daily: 4,
    weekly: 3,
    monthly: 2,
    yearly: 1,
    undefined: 0,
  };
  if (frequencies[a] > frequencies[b]) {
    return 1;
  }
  if (frequencies[a] < frequencies[b]) {
    return -1;
  }
  return 0;
}

function isSensorFrequencyLessAccurateThanLatestValue(sensorFrequency, latestValueFrequency) {
  return Boolean(sensorFrequency) && compareFrequencies(sensorFrequency, latestValueFrequency) < 0;
}

function getLatestValueAggregation(sensor) {
  let latestValueAggregation = sensor?.sensorType?.latestValueAggregation;
  const latestValueFrequency = latestValueAggregation?.frequency;
  const sensorFrequency = sensor?.granularity && granularityToFrequency(sensor.granularity);
  if (isSensorFrequencyLessAccurateThanLatestValue(sensorFrequency, latestValueFrequency)) {
    const aggregationWithSensorFrequency = sensor.sensorType.aggregations?.find(
      aggregation => aggregation.frequency === sensorFrequency
    );
    if (aggregationWithSensorFrequency) {
      latestValueAggregation = aggregationWithSensorFrequency;
    }
  }
  return latestValueAggregation?.aggregation || 'undefined';
}

export const BUILDING_CONTAINER_LOAD = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD';
export const BUILDING_CONTAINER_LOAD_SUCCESS = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD_SUCCESS';
export const BUILDING_CONTAINER_LOAD_FAIL = 'CUSTOMER_PLATFORM/BuildingContainer/LOAD_FAIL';

export const loadBuildingContainer = (partnerNumber, functionalLocation, startDate, endDate, features) => {
  const now = new Date();
  const yearAgo = startOfUTCDay(subYears(now, 1));

  return async dispatch => {
    dispatch({ type: BUILDING_CONTAINER_LOAD });
    try {
      dispatch(loadSensorHierarchies(functionalLocation.functionalLocation))
        // Only load the latest values for the sensors in the building container.
        .then(res => {
          if (res.error) {
            return console.error('Error with sensor hierarchies', res.error);
          }

          // Dispatch success here to start rendering
          dispatch({ type: BUILDING_CONTAINER_LOAD_SUCCESS });
          const sensors = getBuildingSensorsFromHierarchy(head(res.result)) || [];

          const cleaningSensors = sensors.filter(sensor => sensor.sensorType?.name === 'cleaning');

          const utilizationSensors = sensors.filter(
            sensor => sensor.sensorType && utilizationTypes.indexOf(sensor.sensorType.name) !== -1
          );

          const conditionsSensors = sensors.filter(sensor => sensor.sensorType?.name === 's2');

          if (cleaningSensors.length > 0 && features.cleaningTab) {
            dispatch(
              loadCleaning(
                cleaningSensors.map(sensor => sensor.id),
                startDate,
                endDate
              )
            );
          }
          if (utilizationSensors.length > 0 && features.buildingAutomationTab) {
            dispatch(
              loadBuildingConditions(functionalLocation.functionalLocation, utilizationSensors, startDate, endDate)
            );
          }
          if (conditionsSensors.length > 0 && features.buildingAutomationTab) {
            dispatch(
              loadSensorValues(
                conditionsSensors.map(sensor => sensor.id),
                startDate,
                endDate,
                'hourlyAverage'
              )
            );
          }

          if (sensors.length > 0) {
            const aggregationGroups = groupBy(sensors, getLatestValueAggregation);
            dispatch(loadLatestSensorValuesForAggregationGroups(aggregationGroups));
          }

          if (features.floorsTab) {
            const presenceSensors = sensors.filter(
              sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'presence')
            );
            dispatch(
              loadSensorValues(
                presenceSensors.map(sensor => sensor.id),
                startDate,
                endDate,
                'hourlyUtilizationRate'
              )
            );
          }
        });

      if (isControlRoomTabEnabled(features)) {
        dispatch(loadFunctionalLocationObservations(functionalLocation, partnerNumber));
        dispatch(loadFunctionalLocationInspections(functionalLocation, partnerNumber));
        dispatch(loadFunctionalLocationSla(functionalLocation, partnerNumber));
        dispatch(loadFunctionalLocationAlarms(functionalLocation, yearAgo, now));
      }

      dispatch(loadSensorDataTypes());
      features.announcementsTab && dispatch(loadAnnouncementsByFL(functionalLocation.functionalLocation));
      dispatch(loadBuildingMeta([functionalLocation.functionalLocation]));
      dispatch(loadPartnerMeta(partnerNumber));
      features.conditions && dispatch(loadSensorAlarms());

      if (isServiceRequestToolsEnabled(features)) {
        dispatch(
          loadServiceRequestCount({
            partnerNumber,
            functionalLocation: functionalLocation.functionalLocation,
          })
        );
      }
    } catch (error) {
      return dispatch({
        type: BUILDING_CONTAINER_LOAD_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [BUILDING_CONTAINER_LOAD]: state => ({
      ...state,
      loading: true,
    }),
    [BUILDING_CONTAINER_LOAD_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [BUILDING_CONTAINER_LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
