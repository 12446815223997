import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getPartnerNumber,
  createObservationsSelector,
  partnerFunctionalLocationsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyUtil';

const getObservationsForPartner = (state, props) =>
  state.notice.observationsByPartner[props.match?.params?.partnerNumber]?.recycling ?? [];

const observationsSelector = createObservationsSelector(getObservationsForPartner);

const dataSelector = createSelector(
  partnerFunctionalLocationsSelector,
  observationsSelector,
  (functionalLocations, observations) => {
    if (!isEmpty(functionalLocations) && !isEmpty(observations?.apiData)) {
      return functionalLocations
        .map(functionalLocation => {
          const openObservationCount = observations.apiData
            .filter(observation => observation.functionalLocation === functionalLocation.functionalLocation)
            .filter(observation => observation.status.status !== 'completed').length;
          return {
            title: functionalLocation.name,
            address:
              (functionalLocation.address &&
                functionalLocation.city &&
                `${functionalLocation.address}, ${functionalLocation.city}`) ||
              '',
            value: openObservationCount,
            functionalLocationId: functionalLocation.functionalLocation,
          };
        })
        .filter(entry => entry.value);
    }
    return [];
  }
);

export const mapStateToProps = (state, props) => ({
  partnerNumber: getPartnerNumber(state, props),
  functionalLocations: filterFLsBySelectedPartner(
    state.functionalLocations.functionalLocations,
    props.match.params.partnerNumber
  ),
  data: dataSelector(state, props),
});

const connector = connect(mapStateToProps);

export default connector;
