import { useState, useEffect, useCallback } from 'react';

const usePrintingMode = () => {
  const [printingMode, setPrintingMode] = useState(false);
  const onBeforePrint = useCallback(() => setPrintingMode(true), [setPrintingMode]);
  const onAfterPrint = useCallback(() => setPrintingMode(false), [setPrintingMode]);

  useEffect(() => {
    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  });

  return printingMode;
}

export default usePrintingMode;
