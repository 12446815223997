import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';
import parseISO from 'date-fns/parseISO';
import getISOWeek from 'date-fns/getISOWeek';
import subDays from 'date-fns/subDays';
import { format } from 'utils/Date/dateFormatter';
import minFn from 'lodash/min';
import maxFn from 'lodash/max';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import styled, { withTheme, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Responsive from '../Responsive/Responsive';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import { tooltipFormatter, getCommonExportingOptions, getCommonNavigationOptions, buildZonesForNulls } from './utils';
import { generateOpeningHourBands } from 'utils/Data/performance';
import { getBuildingTimezone } from '@caverion/loopback-shared/utility/performance';

accessibility(Highcharts);

Highcharts.dateFormats = {
  W: timestamp => {
    const date = new Date(timestamp);
    return getISOWeek(date);
  },
};

const fadeIn = keyframes`
    0%      { opacity: 0 }
    50%     { opacity: 0 }
    100%    { opacity: 1 }
`;

const StyledSensorChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.colors.darkGray};
  transition: top 280ms ease-out, width 280ms ease-in, height 280ms ease-out;

  .sensor-chart {
    opacity: 1;
    animation-duration: 560ms;
    animation-iteration-count: 1;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
  }
`;

class AirQualityChart extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    dimensions: PropTypes.object,
    loading: PropTypes.bool,
    histories: PropTypes.array,
    error: PropTypes.string,
    theme: PropTypes.object,
    minThreshold: PropTypes.number,
    sensorNames: PropTypes.array,
    annotations: PropTypes.array,
    onClick: PropTypes.func,
    onSelection: PropTypes.func,
    unit: PropTypes.string,
    aggregation: PropTypes.string,
    sensorConfigurations: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    minXRange: PropTypes.number,
    openingHours: PropTypes.array,
    functionalLocation: PropTypes.string,
    buildingMeta: PropTypes.array,
    yAxisTitle: PropTypes.string,
    title: PropTypes.string,
  };

  renderChart() {
    // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.
    let chartWidth = this.props.width || (this.props.dimensions ? this.props.dimensions.width : null);
    const chartHeight = this.props.height
      ? this.props.height
      : this.props.dimensions
      ? this.props.dimensions.height
      : null;

    if (chartWidth > window.innerWidth) {
      chartWidth = window.innerWidth;
    }

    // loader, error, skeleton
    const showLoader = this.props.loading || isNil(this.props.histories);
    const noData = this.props.histories.length === 0;
    const showError = noData && !isNil(this.props.error);
    const skeletonContent =
      (showLoader && <Loader color="BLUE" size="LARGE" />) ||
      (showError && this.props.error) ||
      (noData && this.props.t('No data available')) ||
      undefined;

    if (skeletonContent !== undefined) {
      return (
        <SkeletonChart width={chartWidth ? `${chartWidth}px` : null} height={chartHeight ? `${chartHeight}px` : null}>
          {skeletonContent}
        </SkeletonChart>
      );
    }

    const valueField = this.props.histories[0].avg !== undefined ? 'avg' : 'value';
    const data = this.props.histories.map(value => [parseISO(value.timestamp).getTime(), value[valueField]]);

    const showMinThreshold = !!this.props.minThreshold;
    const thresholdData = [];
    if (showMinThreshold) {
      data.forEach(([timestamp, value]) => {
        if (!isNaN(value)) {
          thresholdData.push([timestamp, this.props.minThreshold]);
        }
      });
    }

    if (showMinThreshold && thresholdData.length > 0) {
      const dataLabelOptions = {
        enabled: true,
        useHTML: true,
        padding: 0,
        align: 'right',
      };
      const dataLabelStyle = `
                color: ${this.props.theme.colors.white};
                background-color: ${this.props.theme.colors.radicalRed};
                font-size: ${this.props.theme.font.size.xxxs};
                padding: 0 5px 1px;
            `;
      const minLabel = `${this.props.minThreshold}${String.fromCharCode(160)}% ${this.props.t('threshold')}`;

      const [x, y] = thresholdData[thresholdData.length - 1];

      thresholdData[thresholdData.length - 1] = {
        x,
        y,
        dataLabels: {
          ...dataLabelOptions,
          y: 15,
          formatter: function () {
            return `<span style="${dataLabelStyle}">${minLabel}</span>`;
          },
        },
      };
    }

    // Take the first sensor name and unit for the series name since they are all the same.
    const sensorName =
      get(this.props.sensorNames, [this.props.histories[0].sensorName]) || this.props.histories[0].sensorName;
    const unit =
      this.props.unit || get(this.props.sensorConfigurations, [this.props.histories[0].sensorName, 'unit']) || '';

    let tooltipDateFormatter;
    switch (this.props.aggregation) {
      case 'monthlyAverage':
        tooltipDateFormatter = timestamp => `${format(new Date(timestamp), `LLLL y`)}`;
        break;
      case 'weeklyAverage':
        tooltipDateFormatter = timestamp => `${this.props.t('Week')} ${format(new Date(timestamp), `I / y`)}`;
        break;
      default:
        break;
    }

    const startOfUTCDay = new Date().setUTCHours(0, 0, 0, 0);

    const endDate = this.props.endDate || subDays(startOfUTCDay, 1);
    const startDate = this.props.startDate || subDays(startOfUTCDay, 6);
    const series = [
      {
        name: sensorName,
        type: 'spline',
        data: data,
        yAxis: 0,
        zIndex: 2,
        singleLine: true,
        color: this.props.theme.colors.midnight,
        hideTime: this.props.aggregation === 'dailyAverage',
        pointStart: startDate,
        tooltipDateFormatter,
        zones: buildZonesForNulls(data),
        zoneAxis: 'x',
        connectNulls: true,
      },
      showMinThreshold && {
        data: thresholdData,
        color: this.props.theme.colors.radicalRed,
        type: 'line',
        dashStyle: 'Dash',
        lineWidth: 1,
        zIndex: 2,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        states: {
          hover: {
            lineWidthPlus: 0,
            halo: { size: 0 },
          },
        },
        tooltip: false,
        showInLegend: false,
        includeInDataExport: false,
      },
    ];

    // red areas
    if (showMinThreshold) {
      const minThreshold = this.props.minThreshold;
      const minHighlightColor = transparentize(1, this.props.theme.colors.radicalRed);
      const maxHighlightColor = transparentize(0.75, this.props.theme.colors.radicalRed);
      let maxValue = maxFn(data.map(point => point[1] || 100));
      let minValue = minFn(data.map(point => point[1] || 0));
      // Avoid division by zero if max and min are equal.
      if (maxValue < minThreshold) {
        maxValue = minThreshold;
      }
      if (maxValue - minValue === 0) {
        maxValue = 100;
        minValue = 0;
      }
      const startHighlight = (maxValue - minThreshold) / (maxValue - minValue);
      const highlightSplineStyle = {
        type: 'areaspline',
        data: data,
        color: 'transparent',
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        states: {
          hover: {
            lineWidthPlus: 0,
            halo: { size: 0 },
          },
          inactive: {
            opacity: 1,
          },
        },
        zIndex: 0,
        tooltip: false,
      };
      const minAreaSpline = {
        ...highlightSplineStyle,
        negativeFillColor: {
          linearGradient: { x1: 0, x2: 0, y1: startHighlight, y2: 1 },
          stops: [
            [0, minHighlightColor],
            [1, maxHighlightColor],
          ],
        },
        fillColor: 'transparent',
        threshold: minThreshold,
        includeInDataExport: false,
      };
      showMinThreshold && series.push(minAreaSpline);
    }

    const { theme, openingHours, functionalLocation, buildingMeta } = this.props;
    const timezone = getBuildingTimezone(functionalLocation, buildingMeta);
    const openingHourBands = generateOpeningHourBands(openingHours, timezone, startDate, endDate, theme);

    const config = {
      annotations: this.props.annotations,
      chart: {
        // zoomType: 'xy', // Disabled zoomType for now
        backgroundColor: 'transparent',
        plotBackgroundColor: this.props.theme.colors.white,
        spacing: [8, 8, 16, 8],
        width: chartWidth,
        height: chartHeight,
        className: 'sensor-chart',
        marginTop: 55,
        spacingLeft: 30,
        spacingBottom: 20,
        events: {
          click: this.props.onClick,
          selection: this.props.onSelection,
        },
      },
      title: {
        text: '',
      },
      credits: false,
      tooltip: {
        split: true,
        shared: false,
        crosshairs: true,
        borderWidth: 0,
        padding: 0,
        backgroundColor: null,
        useHTML: true,
        formatter: function () {
          return tooltipFormatter(this.points, this.x, theme, chartHeight - 60);
        },
      },
      plotOptions: {
        spline: {
          lineColor: this.props.theme.colors.midnight,
          turboThreshold: 400, // Make rendering large series (> 400) more efficient.
          marker: {
            enabled: true,
            lineColor: this.props.theme.colors.midnight,
            lineWidth: 2,
            radius: 3,
            fillColor: this.props.theme.colors.white,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      legend: false,
      xAxis: {
        type: 'datetime',
        lineWidth: 0,
        lineColor: this.props.theme.colors.mystic,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        gridLineColor: this.props.theme.colors.mystic,
        labels: {
          y: 25,
          style: {
            color: this.props.theme.colors.rockBlue,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontWeight: this.props.theme.font.weight.bold,
          },
          format: this.props.aggregation === 'weeklyAverage' && `{value:${this.props.t('Week')} %W / %Y}`,
        },
        minRange: this.props.minXRange,
        min: startDate.valueOf(),
        max: endDate.valueOf(),
        plotBands: openingHourBands,
      },
      yAxis: {
        min: 0,
        softMax: 100,
        lineWidth: 0,
        endOnTick: false,
        lineColor: this.props.theme.colors.mystic,
        labels: {
          format: `{value:.0f}`,
          style: {
            color: this.props.theme.colors.rockBlue,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontWeight: this.props.theme.font.weight.bold,
          },
        },
        title: {
          align: 'high',
          offset: 0,
          text: this.props.yAxisTitle || unit,
          rotation: 0,
          y: -20,
          style: {
            color: this.props.theme.colors.black,
            fontWeight: this.props.theme.font.weight.bold,
          },
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        gridLineColor: this.props.theme.colors.mystic,
      },
      series: series,
      exporting: getCommonExportingOptions({ title: this.props.title }),
      navigation: getCommonNavigationOptions(),
    };

    return <HighchartsReact highcharts={Highcharts} options={config} ref="AirQualitySensorChart" />;
  }

  render() {
    return <StyledSensorChart>{this.renderChart()}</StyledSensorChart>;
  }
}

export default Responsive(withTheme(AirQualityChart));
