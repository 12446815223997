import React from 'react';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import PageTemplate from './PageTemplate';
import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';
import { Description, Note, Title } from './StyledElements';


const SomethingWentWrong = () => {
  const { t } = useUnauthenticatedTranslations();
  return (
    <PageTemplate title={t('Something went wrong')}>
      <Title>{t('Something went wrong')}</Title>
      <Description>{t('Please try again later')}</Description>
      <Note>{t('If the problem persists, please contact local support for further assistance.')}</Note>
      <PrimaryButton large add reactLink to="/login">{t('Go to login')}</PrimaryButton>
    </PageTemplate>
  );
};

export default SomethingWentWrong;
