import React from 'react';
import { startOfHour, addMinutes } from 'date-fns';
import { useSelector } from 'react-redux';
import clamp from 'lodash/clamp';
import uniqBy from 'lodash/uniqBy';

import {
  annotationsLabelConfigs,
  timelineClickPosition,
  isEventFromInsideHighchartsInteractiveElement,
  timelineAnnotationGroup,
  chartDateAnnotationGroup,
} from './utils';
import { hasBuildingAndPortfolioAdminTools } from 'utils/Data/profileData';

const hourAdjustedTimestamp = timestamp =>
  timestamp ? startOfHour(addMinutes(new Date(timestamp), 39)).toISOString() : null;

const xMatchesValue = value => datapoint => datapoint.x === value;

const eventTimestamp = event => {
  if (event?.point) {
    return hourAdjustedTimestamp(event.point.timestamp || event.point.x);
  }
  if (event.xAxis?.[0]?.axis?.dateTime) {
    return hourAdjustedTimestamp(event.xAxis[0].value);
  }
  if (event.xAxis?.[0]?.axis?.categories) {
    return hourAdjustedTimestamp(
      event.xAxis[0].axis.series
        .flatMap(series => series.data)
        .find(xMatchesValue(clamp(Math.round(event.xAxis[0].value), event.xAxis[0].axis.min, event.xAxis[0].axis.max)))
        ?.datetime
    );
  }
  return null;
};

const useChartAnnotations = (chart, granularity, annotations) => {
  const allowAnnotationsAdminTools = useSelector(state => hasBuildingAndPortfolioAdminTools(state.profile.profile));
  const [annotationPopover, setAnnotationPopover] = React.useState();
  const [createAnnotationAt, setCreateAnnotationAt] = React.useState();

  const getAnnotationConfigs = React.useCallback(
    granularity =>
      annotationsLabelConfigs(
        (chart?.chartType === 'comparison' ? chartDateAnnotationGroup : timelineAnnotationGroup)(
          annotations,
          granularity
        ),
        setAnnotationPopover
      ),
    [annotations, chart]
  );

  const chartAnnotations = React.useMemo(
    () => uniqBy(getAnnotationConfigs(granularity), 'id'),
    [granularity, getAnnotationConfigs]
  );

  const onClickChart = React.useCallback(
    event =>
      allowAnnotationsAdminTools &&
      eventTimestamp(event) &&
      !isEventFromInsideHighchartsInteractiveElement(event) &&
      setCreateAnnotationAt({
        timestamp: eventTimestamp(event),
        position: timelineClickPosition(event),
        sensorId: event.point?.sensorId,
      }),
    [allowAnnotationsAdminTools]
  );

  const onCloseAnnotations = () => {
    setAnnotationPopover(null);
    setCreateAnnotationAt(null);
  };

  return {
    annotationPopover,
    createAnnotationAt,
    onClickChart,
    onCloseAnnotations,
    chartAnnotations,
    getAnnotationConfigs,
  };
};

export default useChartAnnotations;
