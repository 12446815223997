import compact from 'lodash/compact';
import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import { getFilterForSensorsValues } from './sensor_values';

export const initialState = {
  cleaningValues: [],
  loadingCleaning: false,
};

export const LOAD_CLEANING = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING';
export const LOAD_CLEANING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_SUCCESS';
export const LOAD_CLEANING_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CLEANING_FAIL';

export const loadCleaning = (sensorIds, startTime, endTime) => {
  const filter = getFilterForSensorsValues(sensorIds, startTime, endTime, 'raw');

  return async dispatch => {
    dispatch({ type: LOAD_CLEANING });
    try {
      const response = await IoTService.sensorValuesFind(filter);

      return dispatch({
        type: LOAD_CLEANING_SUCCESS,
        result: response.data,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CLEANING_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_CLEANING]: state => ({
      ...state,
      loadingCleaning: true,
    }),
    [LOAD_CLEANING_SUCCESS]: (state, action) => ({
      ...state,
      cleaningValues: compact([].concat(state.cleaningValues, action.result)),
      loadingCleaning: false,
    }),

    [LOAD_CLEANING_FAIL]: state => ({
      ...state,
      loadingCleaning: false,
    }),
  },
  initialState
);
