import capitalize from 'lodash/capitalize';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import { MAX_INTERVAL_DURATION_DAYS, INSIGHT_STATUS } from './constants';

const createMessageString = (message, t, templateType) => {
  const valueForTemplate = message.insightValue >= 0 ? 'positive' : 'negative';
  return t(
    `${message.insightDescription} ${templateType} ${valueForTemplate}`,
    createLocationString(message, t),
    message.insightValue
  );
};

const createShortMessageString = (message, t) => createMessageString(message, t, 'short_template');

const createLongMessageString = (message, t) => createMessageString(message, t, 'long_template');

const createLocationString = (message, t) =>
  `${t(capitalize(message.sensorHierarchyType))} ` +
  `${message.sensorHierarchyName ? t(capitalize(message.sensorHierarchyName)) : ''}`;

const createFormattedLocalTimestamp = timestampString => format(parseISO(timestampString), 'Pp');

const createDurationString = (totalHours, t) => {
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  return (
    (totalHours >= MAX_INTERVAL_DURATION_DAYS * 24 ? `${t('more than')} ` : '') +
    (days > 0 ? `${days} ${t(days > 1 ? 'days' : 'day')} ` : '') +
    (hours > 0 ? `${hours} ${t(hours > 1 ? 'hours' : 'hour')}` : '')
  ).trim();
};

const getSuccessInsightMessage = (sensorHierarchyType, sensorHierarchyName = null) => ({
  insightDescription: 'success insight',
  timestamp: new Date().toISOString(),
  sensorHierarchyType,
  sensorHierarchyName,
  success: true,
  insightValue: 1,
});

export {
  createMessageString,
  createShortMessageString,
  createLongMessageString,
  createLocationString,
  createFormattedLocalTimestamp,
  createDurationString,
  getSuccessInsightMessage,
  INSIGHT_STATUS,
};
