import React, { Component, PropsWithChildren, MouseEventHandler, MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';

import { toggleScroll } from 'utils/Modal/toggleScroll';

import { DialogAnimate } from './DialogAnimate';

export const DialogFadein = keyframes`
    from {
        opacity: 0.3;
    }
`;

export type StyledDialogProps = {
  active: boolean;
  animate: boolean;
};

const StyledDialog = styled.div<StyledDialogProps>`
  ${props =>
    props.active &&
    `
        position: fixed;
        z-index: ${props.theme.zIndex('modal')};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    `}
  @media print {
    display: none;
    &:last-child {
      display: block;
      visibility: visible;
      position: static;
    }
  }
`;

StyledDialog.displayName = 'StyledDialog';

export type DialogOverlayProps = {
  active: boolean;
};

export const DialogOverlay = styled.div<DialogOverlayProps>`
  ${props =>
    props.active &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--overlay-hard-color);
      animation: ${DialogFadein} 0.25s ease-out;

      @media print {
        visibility: visible;
        background: none;
        display: none;
      }
    `}
`;

DialogOverlay.displayName = 'DialogOverlay';

export type DialogInanimateProps = {
  isActive: boolean;
};

const DialogInanimate = styled.div<DialogInanimateProps>`
  ${props =>
    props.isActive &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      @media print {
        position: static;
        width: auto;
        height: auto;
      }
    `}
`;

DialogInanimate.displayName = 'DialogInanimate';

const toggleDialogScroll = (newState: boolean) => toggleScroll(newState, true);

const portalRoot = document.getElementById('modal-root');

export type DialogProps = {
  isActive: boolean;
  onOverlayClick: MouseEventHandler;
  animate: boolean;
};

/**
 * Dialog (modal) UI component
 */
export class Dialog extends Component<PropsWithChildren<DialogProps>> {
  static defaultProps = {
    isActive: false,
    onOverlayClick: null,
    animate: false,
    children: null,
  };

  componentDidMount() {
    if (this.props.isActive) {
      toggleDialogScroll(true);
    }
  }

  componentWillUnmount() {
    toggleDialogScroll(false);
  }

  componentDidUpdate(prevProps: Readonly<DialogProps>) {
    const { isActive } = this.props;
    if (!prevProps.isActive && isActive) {
      toggleDialogScroll(true);
    }
  }

  handleOverlayClick = (event: MouseEvent) => {
    const { onOverlayClick } = this.props;

    if (typeof onOverlayClick === 'function') {
      event.stopPropagation();
      toggleDialogScroll(false);
      onOverlayClick(event);
    }
  };

  render() {
    const { children, isActive, animate } = this.props;

    if (isActive) {
      toggleDialogScroll(true);
    }

    const contents = (
      <StyledDialog active={isActive} animate={animate}>
        <DialogOverlay onClick={this.handleOverlayClick} active={isActive} />
        {animate ? (
          <DialogAnimate>{children}</DialogAnimate>
        ) : (
          <DialogInanimate isActive={isActive}>{children}</DialogInanimate>
        )}
      </StyledDialog>
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Tests rely on this to be called even with nullish portalRoot
    return ReactDOM.createPortal(contents, portalRoot);
  }
}

export default Dialog;
