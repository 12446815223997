import React, { useRef } from 'react';
import { RowData } from '@tanstack/table-core/build/lib/types';
import { VirtualizerOptions } from '@tanstack/virtual-core';

import { RemotePaginatedTableProps } from './RemotePaginatedTable';
import { LoadingDataNotification } from './LoadingDataNotification';
import { TableWindowVirtualizer } from './useVirtualizedTable';
import { PaginatedDataTable } from './PaginatedDataTable';
import { DataNotFound } from './DataNotFound';

// Legitimate use of `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RemotePaginatedDataTableProps<TData extends RowData, TError = any> = RemotePaginatedTableProps<
  TData,
  TError
> & {
  virtualizerOptions?: Partial<VirtualizerOptions<Window, HTMLTableRowElement>>;
};

/**
 * NOTE! For non-virtualized version, see {@link RemotePaginatedTable}. Think of this as
 * {@link RemotePaginatedVirtualizedDataTable} (exported as an alias as well).
 *
 * Simple table component, based on {@link PaginatedDataTable}, to display data from a remote source.
 * If you need to display local data, use the {@link PaginatedDataTable} directly instead. Note that
 * same props and constraints apply to this component as the base component.
 *
 * For sorting, extract the sorting state out of the `tableOptions`.
 *
 * Note that this component disables scrolling to the top of the table after page change, because
 * the layout can jump a lot while data is fetched. You need to trigger the scrolling yourself.
 * See the example.
 *
 * @example Sorting state
 *   const [sorting, setSorting] = useState(undefined);
 *   const tableOptions = {
 *     columns,
 *     onSortingChange: setSorting,
 *     state: { sorting },
 *   };
 *
 * @example Trigger scroll-to-top
 *  const virtualizerRef = useRef<TableWindowVirtualizer | null>(null);
 *  const scrollToTop = () => scrollWindowVirtualizerToTop(virtualizerRef.current);
 *  return (
 *    <RemotePaginatedDataTable
 *       virtualizerRef={virtualizerRef}
 *       ...
 *
 * @implements {UIv3}
 *
 * @param remoteDataState Subset of query state as understood by `react-query` for the current page.
 *   Make sure that the data corresponds to the pagination status query parameters, see
 *   {@link usePaginationQuery}.
 *
 * @param tableOptions Table options. At least column definitions are required.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 * @param genericTableProps Any props to pass to `<GenericWindowVirtualizedTable>`, e.g. cell or row renderers.
 * @param virtualizerOptions Additional virtualizer options, e.g. `scrollPaddingStart`.
 * @param paginationProps Any props to pass to the {@link QueryConnectedPagination}, e.g. query parameter names.
 *   At least field `totalItems` is required.
 * @param estimatedRowSize Defaults to 40 (pixels).
 *
 * @param enableColumnResizing Allow column resizing. If you need to e.g. save the column
 *   widths, override `onColumnSizingChange` in `tableOptions` as instructed in
 *   {@link https://tanstack.com/table/v8/docs/api/features/column-sizing#oncolumnsizingchange}.
 * @param enableRowSelection Allow row selection.
 *   For the selecting to work, add a selection checkbox column (for example generated using
 *   {@link generateSelectColumn}) or some other mechanism to actually make the selections.
 *   State is kept internally, but if it is needed outside, override `tableOptions.onRowSelectionChange`
 *
 * @param enableLoadingIndicator Display {@link LoadingDataNotification} when fetching data (debounced).
 * @param loadingDataText See {@link LoadingDataNotification}.
 * @param dataNotFoundText Passed to {@link DataNotFound}.
 *
 * @param tableRef Optional reference that will be set to the table instance.
 *   This is an escape hatch to access the React Table API. Recommended approach
 *   is to write a new component, similar to this, instead, but this can serve
 *   in simple cases.
 * @param virtualizerRef Optional reference that will be set to the virtualizer instance.
 *   This is an escape hatch to access the React Table API. Recommended approach
 *   is to write a new component, similar to this, instead, but this can serve
 *   in simple cases.
 */
// Legitimate use of `any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RemotePaginatedDataTable<TRowData extends RowData, TRemoteAdapterError = any>({
  remoteDataState,

  tableOptions,
  genericTableProps,
  virtualizerOptions,
  paginationProps,
  estimatedRowSize = 40,

  enableColumnResizing = true,
  enableRowSelection = true,

  enableLoadingIndicator = true,
  loadingDataText,
  dataNotFoundText,

  tableRef,
  virtualizerRef,
}: RemotePaginatedDataTableProps<TRowData, TRemoteAdapterError>) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const localVirtualizerRef = useRef<TableWindowVirtualizer | null>(null);

  const normalizedRemoteDataState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    isFetching: false,

    error: null,

    ...remoteDataState,
  };

  const resolvedTableOptions = {
    data: normalizedRemoteDataState.data ?? [],

    manualSorting: true,
    manualPagination: true,

    ...tableOptions,

    meta: {
      displayingPreviousData: (normalizedRemoteDataState.data && normalizedRemoteDataState.isFetching) ?? false,
      displaySkeleton: (!normalizedRemoteDataState.data && normalizedRemoteDataState.isLoading) ?? false,
      ...tableOptions.meta,
    },
  };

  const paginationPropsWithCustomPageChangeListener = {
    ...paginationProps,
    // Override the default, jump scroll to the beginning. That way the browser can
    // keep the view at the start even when the surrounding layout changes.
    onPageChange() {
      window.scrollTo({
        top: (containerRef.current?.offsetTop ?? 0) - (localVirtualizerRef.current?.options.scrollPaddingStart ?? 0),
      });
    },
  };

  const hasNoData = !normalizedRemoteDataState.data && !normalizedRemoteDataState.isLoading;

  if (virtualizerRef) {
    virtualizerRef.current = localVirtualizerRef.current;
  }

  const showLoadingIndicator =
    // 1. needs to be enabled
    enableLoadingIndicator &&
    // 2. don't show on initial load (skeleton visible)
    !!normalizedRemoteDataState.data &&
    // 3. only show when fetching
    normalizedRemoteDataState.isFetching;

  return (
    <div ref={containerRef}>
      {hasNoData ? (
        <DataNotFound text={dataNotFoundText} />
      ) : (
        <PaginatedDataTable
          tableOptions={resolvedTableOptions}
          virtualizerOptions={virtualizerOptions}
          virtualizerRef={localVirtualizerRef}
          paginationProps={paginationPropsWithCustomPageChangeListener}
          estimatedRowSize={estimatedRowSize}
          enableColumnResizing={enableColumnResizing}
          enableRowSelection={enableRowSelection}
          genericTableProps={genericTableProps}
          tableRef={tableRef}
        />
      )}

      <LoadingDataNotification visible={showLoadingIndicator} text={loadingDataText} />
    </div>
  );
}

export const RemotePaginatedVirtualizedDataTable = RemotePaginatedDataTable;
