export const DEFAULT_PORTFOLIO_COLUMNS = ['origin', 'title', 'location', 'status', 'discipline', 'impact', 'date'];
export const DEFAULT_OBSERVATIONS_COLUMNS = ['origin', 'status', 'date', 'title', 'discipline', 'impact'];
export const DEFAULT_FL_COLUMNS = ['origin', 'title', 'status', 'discipline', 'impact', 'date'];
export const DEFAULT_ENERGY_COLUMNS = [
  'origin',
  'title',
  'location',
  'status',
  'date',
  'modifiedOn',
  'actionType',
  'impact',
  'savingsCost',
  'paybackPeriod',
  'savingsLifeSpan',
];
export const DEFAULT_RECYCLING_COLUMNS = [
  'origin',
  'title',
  'location',
  'status',
  'date',
  'modifiedOn',
  'actionType',
  'impact',
];
