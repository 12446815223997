import { css } from 'styled-components';
// We need to have SVG inline for OpenLayers
/* eslint-disable max-len */
const defaultSvg = {
  data:
    '<svg width="36" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" viewPort="0 0 120 120">' +
    '<circle fill="#FFFFFF" cx="60" cy="60" r="34.2"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill-opacity="0.15" fill="#00B5E2" d="M60,5.3C29.8,5.3,5.3,29.8,5.3,60s24.5,54.7,54.7,54.7s54.7-24.5,54.7-54.7S90.2,5.3,60,5.3z M60,94.2 c-18.9,0-34.2-15.3-34.2-34.2S41.1,25.8,60,25.8S94.2,41.1,94.2,60S78.9,94.2,60,94.2z"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill="#00B5E2" d="M60,25.8c-18.9,0-34.2,15.3-34.2,34.2S41.1,94.2,60,94.2S94.2,78.9,94.2,60S78.9,25.8,60,25.8z M60,74 c-7.7,0-14-6.3-14-14s6.3-14,14-14s14,6.3,14,14S67.7,74,60,74z"/>' +
    '</svg>',
  size: [36, 36],
};
const sensorSvg = {
  data:
    '<svg width="36" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" viewPort="0 0 120 120">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill-opacity="0.15" fill="#00B5E2" d="M60,4.5C29.4,4.5,4.5,29.4,4.5,60s24.8,55.5,55.5,55.5s55.5-24.8,55.5-55.5S90.6,4.5,60,4.5z M60,94.7 c-19.2,0-34.7-15.5-34.7-34.7S40.8,25.3,60,25.3S94.7,40.8,94.7,60S79.2,94.7,60,94.7z"/>' +
    '<circle fill="#FFFFFF" class="st1" cx="60" cy="59.9" r="40.4"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill="#00B5E2" d="M60,19.7c-22.3,0-40.4,18.1-40.4,40.4c0,22.3,18.1,40.4,40.4,40.4s40.4-18.1,40.4-40.4 C100.4,37.8,82.3,19.7,60,19.7z M46.1,77.4c-0.4,0.4-1,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6c-9.6-9.7-9.6-25.3,0-34.9 c0.9-0.9,2.3-0.9,3.1,0c0.9,0.9,0.9,2.3,0,3.1c-7.9,7.9-7.9,20.8-0.1,28.6C47,75.2,47,76.6,46.1,77.4z M51.8,68.6 c0.9,0.9,0.9,2.3,0,3.1c-0.4,0.4-1,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6c-6.5-6.5-6.5-17.1,0-23.6c0.9-0.9,2.3-0.9,3.1,0 c0.8,0.9,0.8,2.3-0.1,3.1C47.1,56.1,47.1,63.9,51.8,68.6z M60.5,66.2c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2 S64,66.2,60.5,66.2z M70.7,72.4c-0.6,0-1.1-0.2-1.5-0.6c-0.9-0.9-0.9-2.3,0-3.1c4.8-4.8,4.8-12.5,0-17.3c-0.9-0.9-0.9-2.3,0-3.1 c0.9-0.9,2.3-0.9,3.1,0c6.5,6.5,6.5,17-0.1,23.6C71.8,72.2,71.3,72.4,70.7,72.4z M77.9,77.5c-0.4,0.4-1,0.6-1.5,0.6 c-0.6,0-1.1-0.2-1.5-0.6c-0.9-0.9-0.9-2.3,0-3.1c7.9-7.9,7.9-20.8,0-28.6c-0.9-0.9-0.9-2.3,0-3.1c0.9-0.9,2.3-0.9,3.1,0 C87.6,52.2,87.6,67.8,77.9,77.5z"/>' +
    '</svg>',
  size: [36, 36],
};
const sensorDisabledSvg = {
  data:
    '<svg width="36" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" viewPort="0 0 120 120">' +
    '<circle fill="#FFFFFF" cx="60" cy="60" r="34.2"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill-opacity="0.15" fill="#A2B3C9" d="M60,5.3C29.8,5.3,5.3,29.8,5.3,60s24.5,54.7,54.7,54.7s54.7-24.5,54.7-54.7S90.2,5.3,60,5.3z M60,94.2 c-18.9,0-34.2-15.3-34.2-34.2S41.1,25.8,60,25.8S94.2,41.1,94.2,60S78.9,94.2,60,94.2z"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill="#A2B3C9" d="M60,25.8c-18.9,0-34.2,15.3-34.2,34.2S41.1,94.2,60,94.2S94.2,78.9,94.2,60S78.9,25.8,60,25.8z M60,74 c-7.7,0-14-6.3-14-14s6.3-14,14-14s14,6.3,14,14S67.7,74,60,74z"/>' +
    '</svg>',
  size: [36, 36],
};
const equipmentSvg = {
  data:
    '<svg width="36" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" viewPort="0 0 120 120">' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill="#446794" fill-opacity="0.15" d="M60,4.5C29.4,4.5,4.5,29.4,4.5,60s24.8,55.5,55.5,55.5s55.5-24.8,55.5-55.5S90.6,4.5,60,4.5z M60,94.7 c-19.2,0-34.7-15.5-34.7-34.7S40.8,25.3,60,25.3S94.7,40.8,94.7,60S79.2,94.7,60,94.7z"/>' +
    '<circle fill-rule="evenodd" clip-rule="evenodd" fill="#446794" cx="60" cy="60" r="40.4"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M76.9,68.8L61.5,79.9V63.2l15.4-11.2V68.8z M60,60.5L44.3,49.2l15.2-10.3L75.7,49L60,60.5z M59.5,35.2 L39.9,48.3v22.1L60,84.8l20.1-14.4V48.2L59.5,35.2z"/>' +
    '</svg>',
  size: [36, 36],
};
const deviceSvg = {
  data:
    '<svg width="36" height="36" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" style="enable-background:new 0 0 120 120;">' +
    '<path fill="#00B5E2" fill-opacity="0.15" d="M60,5C29.7,5,5,29.7,5,60s24.6,55,55,55s55-24.6,55-55S90.3,5,60,5z M60,99.9c-22.1,0-40-17.9-40-40s17.9-40,40-40s40,17.9,40,40S82.1,99.9,60,99.9z"/>' +
    '<circle fill="#00B5E2" cx="60.1" cy="59.9" r="40.3"/>' +
    '<g>' +
    '<path fill="#FFFFFF" d="M76.9,64.2L61.5,75.3v-7.7l15.4-11.2V64.2zM60,64.9L44.3,53.6l15.2-10.3l16.2,10.1L60,64.9zM59.5,39.6L39.9,52.7v13.1L60,80.2l20.1-14.4V52.6L59.5,39.6z"/>' +
    '</g>' +
    '</svg>',
  size: [36, 36],
};
const presenceAvailableSvg = {
  data:
    '<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
    '<g id="Seat-Green" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
    '<g id="Group-13" transform="translate(1.000000, 1.000000)">' +
    '<circle id="Oval" stroke="#3CC87A" stroke-width="1.5" fill="#FFFFFF" fill-rule="nonzero" cx="9" cy="9" r="9"></circle>' +
    '<path d="M12.4115659,6.15864256 L8.67525647,10.0547103 C8.47209892,10.2663178 8.14367843,10.2663178 7.9410472,10.0547103 L5.64420901,7.65991939 C5.44631461,7.45318263 5.12684147,7.44777068 4.92263129,7.64747188 L4.16421154,8.38728638 C3.95000137,8.59672911 3.94473823,8.9490475 4.15210629,9.16552578 L7.56104784,12.720099 C7.965784,13.1422317 8.62262498,13.1427729 9.02788745,12.7211814 L13.8473529,7.70700341 C14.0457736,7.50080785 14.0515631,7.16743131 13.8599845,6.95420021 L13.1573541,6.17109006 C12.9568281,5.94811744 12.618934,5.94216429 12.4115659,6.15864256" id="Icon" fill="#3CC87A"></path>' +
    '</g>' +
    '</g>' +
    '</svg>',
  size: [20, 20],
};
const presenceUnavailableSvg = {
  data:
    '<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
    '<g id="Seat-Red" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
    '<g id="Group-12" transform="translate(1.000000, 1.000000)">' +
    '<circle id="Oval-Copy-3" stroke="#FF4656" stroke-width="1.5" fill="#FFFFFF" fill-rule="nonzero" cx="9" cy="9" r="9"></circle>' +
    '<path d="M9.26104187,8.00857423 C9.14548418,8.12383563 8.95881405,8.12383563 8.84355266,8.00857423 L5.92142447,5.08644605 C5.80616308,4.97118465 5.61919665,4.97118465 5.50393526,5.08644605 L5.08644605,5.50393526 C4.97118465,5.61919665 4.97118465,5.80586678 5.08644605,5.92142447 L8.00857423,8.84355266 C8.12383563,8.95881405 8.12383563,9.14548418 8.00857423,9.26104187 L5.19074427,12.0785755 C5.07548288,12.1938369 5.07548288,12.3808033 5.19074427,12.4960647 L5.60823349,12.913554 C5.72349488,13.0288153 5.9104613,13.0288153 6.0257227,12.913554 L8.84355266,10.095724 C8.95881405,9.9804626 9.14548418,9.9804626 9.26104187,10.095724 L11.9742773,12.8092557 C12.0895387,12.9245171 12.2765051,12.9245171 12.3917665,12.8092557 L12.8092557,12.3917665 C12.9245171,12.2765051 12.9245171,12.0895387 12.8092557,11.9742773 L10.095724,9.26104187 C9.9804626,9.14548418 9.9804626,8.95881405 10.095724,8.84355266 L12.913554,6.0257227 C13.0288153,5.9104613 13.0288153,5.72349488 12.913554,5.60823349 L12.4960647,5.19074427 C12.3808033,5.07548288 12.1941332,5.07548288 12.0788718,5.19074427 L9.26104187,8.00857423 Z" id="Icon-Copy-20" fill="#FF4656"></path>' +
    '</g>' +
    '</g>' +
    '</svg>',
  size: [20, 20],
};
const zoneAvailableSvg = {
  data:
    '<svg width="36" height="36" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33.7 33.7" style="enable-background:new 0 0 33.7 33.7;" xml:space="preserve">' +
    '<g>' +
    '<circle cx="16.9" cy="16.9" r="15" fill="#3CC87A" fill-opacity="0.15" />' +
    '<g transform="translate(8, 8)">' +
    '<path d="M12.4115659,6.15864256 L8.67525647,10.0547103 C8.47209892,10.2663178 8.14367843,10.2663178 7.9410472,10.0547103 L5.64420901,7.65991939 C5.44631461,7.45318263 5.12684147,7.44777068 4.92263129,7.64747188 L4.16421154,8.38728638 C3.95000137,8.59672911 3.94473823,8.9490475 4.15210629,9.16552578 L7.56104784,12.720099 C7.965784,13.1422317 8.62262498,13.1427729 9.02788745,12.7211814 L13.8473529,7.70700341 C14.0457736,7.50080785 14.0515631,7.16743131 13.8599845,6.95420021 L13.1573541,6.17109006 C12.9568281,5.94811744 12.618934,5.94216429 12.4115659,6.15864256" id="Icon" fill="#3CC87A"></path>' +
    '</g>' +
    '</g>' +
    '</svg>',
  size: [36, 36],
};
const zoneUnavailableSvg = {
  data:
    '<svg width="36" height="36" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33.7 33.7" style="enable-background:new 0 0 33.7 33.7;" xml:space="preserve">' +
    '<g>' +
    '<circle cx="16.9" cy="16.9" r="15" fill="#FF4656" fill-opacity="0.15" />' +
    '<g transform="translate(8, 8)">' +
    '<path d="M9.26104187,8.00857423 C9.14548418,8.12383563 8.95881405,8.12383563 8.84355266,8.00857423 L5.92142447,5.08644605 C5.80616308,4.97118465 5.61919665,4.97118465 5.50393526,5.08644605 L5.08644605,5.50393526 C4.97118465,5.61919665 4.97118465,5.80586678 5.08644605,5.92142447 L8.00857423,8.84355266 C8.12383563,8.95881405 8.12383563,9.14548418 8.00857423,9.26104187 L5.19074427,12.0785755 C5.07548288,12.1938369 5.07548288,12.3808033 5.19074427,12.4960647 L5.60823349,12.913554 C5.72349488,13.0288153 5.9104613,13.0288153 6.0257227,12.913554 L8.84355266,10.095724 C8.95881405,9.9804626 9.14548418,9.9804626 9.26104187,10.095724 L11.9742773,12.8092557 C12.0895387,12.9245171 12.2765051,12.9245171 12.3917665,12.8092557 L12.8092557,12.3917665 C12.9245171,12.2765051 12.9245171,12.0895387 12.8092557,11.9742773 L10.095724,9.26104187 C9.9804626,9.14548418 9.9804626,8.95881405 10.095724,8.84355266 L12.913554,6.0257227 C13.0288153,5.9104613 13.0288153,5.72349488 12.913554,5.60823349 L12.4960647,5.19074427 C12.3808033,5.07548288 12.1941332,5.07548288 12.0788718,5.19074427 L9.26104187,8.00857423 Z" id="Icon-Copy-20" fill="#FF4656"></path>' +
    '</g>' +
    '</g>' +
    '</svg>',
  size: [36, 36],
};
/* eslint-enable max-len */

export const controlsCss = css`
  .ol-zoom-in,
  .ol-zoom-out {
    border: var(--border-semibold) solid var(--grey-100);
    border-radius: 50%;
    padding: 0;
    height: calc(1em + 2px);
    width: calc(1em + 2px);
    background-color: var(--white);
    color: transparent;
    box-sizing: content-box;
    font-size: 100%;
    cursor: pointer;
    margin: 0.5em;

    &::before {
      content: '';
      position: absolute;
      border-top: var(--border-semibold) solid var(--grey-100);
      left: 0.25em;
      right: 0.25em;
      top: 0.5em;
    }

    &:hover {
      border-color: var(--blue-02);

      &:before,
      &:after {
        border-color: var(--blue-02);
      }
    }
  }

  .ol-zoom-in {
    &::after {
      content: '';
      position: absolute;
      border-left: var(--border-semibold) solid var(--grey-100);
      top: 0.25em;
      bottom: 0.25em;
      left: 0.5em;
    }
  }

  .ol-controls {
    display: flex;
    flex-flow: row nowrap;

    .ol-control {
      position: relative;
      margin: 0.5em;
    }

    .ol-expand-button {
      position: relative;
      border: 0;
      background: transparent;
      cursor: pointer;
      width: 2.25em;
      height: 2.25em;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 48%;
        width: 100%;
        border: var(--border-thin) solid var(--grey-100);
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover {
        &::before,
        &::after {
          border-color: var(--blue-02);
        }

        svg {
          fill: var(--blue-02);
        }
      }
    }
  }
`;

export const mapIconToSvg = {
  default: defaultSvg,
  measuringPoint: sensorSvg,
  sensorDisabled: sensorDisabledSvg,
  equipment: equipmentSvg,
  device: deviceSvg,
  presenceAvailable: presenceAvailableSvg,
  presenceUnavailable: presenceUnavailableSvg,
  seatAvailable: presenceAvailableSvg,
  seatUnavailable: presenceUnavailableSvg,
  zoneAvailable: zoneAvailableSvg,
  zoneUnavailable: zoneUnavailableSvg,
};
