import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import BaseButton from '../BaseButton/BaseButton';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

export const StyledButton = styled(BaseButton)`
  ${props =>
    props.reveal &&
    css`
      background-color: var(--btn-blue-bg);
      border-color: var(--btn-blue-bg);
      color: var(--btn-blue-fg);
      fill: var(--btn-blue-fg);
      &:hover {
        background-color: var(--btn-blue-bg-hl);
        border-color: var(--btn-blue-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.reveal &&
    props.invertColor &&
    css`
      background-color: var(--btn-blue-fg);
      border-color: var(--btn-blue-bg);
      color: var(--btn-blue-bg);
      fill: var(--btn-blue-bg);
      &:hover {
        background-color: var(--btn-blue-fg);
        color: var(--btn-blue-bg-hl);
        fill: var(--btn-blue-bg-hl);
        border-color: var(--btn-blue-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.add &&
    css`
      background-color: var(--btn-primary-bg);
      border-color: var(--btn-primary-bg);
      color: var(--btn-primary-fg);
      fill: var(--btn-primary-fg);
      &:hover {
        background-color: var(--btn-primary-bg-hl);
        border-color: var(--btn-primary-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.add &&
    props.invertColor &&
    css`
      background-color: var(--btn-primary-fg);
      border-color: var(--btn-primary-bg);
      color: var(--btn-primary-bg);
      fill: var(--btn-primary-bg);
      &:hover {
        background-color: var(--btn-primary-fg);
        color: var(--btn-primary-bg-hl);
        fill: var(--btn-primary-bg-hl);
        border-color: var(--btn-primary-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.confirm &&
    css`
      background-color: var(--btn-success-bg);
      border-color: var(--btn-success-bg);
      color: var(--btn-success-fg);
      fill: var(--btn-success-fg);
      &:hover {
        background-color: var(--btn-success-bg-hl);
        border-color: var(--btn-success-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.confirm &&
    props.invertColor &&
    css`
      background-color: var(--btn-success-fg);
      border-color: var(--btn-success-bg);
      color: var(--btn-success-bg);
      fill: var(--btn-success-bg);
      &:hover {
        background-color: var(--btn-success-fg);
        color: var(--btn-success-bg-hl);
        fill: var(--btn-success-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.naked &&
    css`
      background-color: transparent;
      color: var(--btn-naked-fg);
      fill: var(--btn-naked-fg);
      &:hover {
        color: var(--btn-naked-fg-hl);
        fill: var(--btn-naked-fg-hl);
      }
    `}

  ${props =>
    props.nakedLightLink &&
    css`
      background-color: transparent;
      color: var(--grey-100);
      padding: 0;
      min-width: auto;
      font-weight: var(--regular);
      &:hover {
        color: var(--grey-70);
      }
    `}

  ${props =>
    props.warning &&
    css`
      background-color: var(--btn-danger-bg);
      border-color: var(--btn-danger-bg);
      color: var(--btn-danger-fg);
      fill: var(--btn-danger-fg);
      &:hover {
        background-color: var(--btn-danger-bg-hl);
        border-color: var(--btn-danger-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.warning &&
    props.invertColor &&
    css`
      background-color: var(--btn-danger-fg);
      border-color: var(--btn-danger-bg);
      color: var(--btn-danger-bg);
      fill: var(--btn-danger-bg);
      &:hover {
        background-color: var(--btn-danger-fg);
        color: var(--btn-danger-bg-hl);
        fill: var(--btn-danger-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

    ${props =>
    props.previous &&
    css`
      background-color: var(--btn-previous-bg);
      border-color: var(--btn-previous-bg);
      color: var(--btn-previous-fg);
      fill: var(--btn-previous-fg);
      &:hover {
        background-color: var(--btn-previous-bg-hl);
        border-color: var(--btn-previous-bg-hl);
        box-shadow: var(--btn-shadow-hover);
      }
    `}

  ${props =>
    props.transparentBorder &&
    css`
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    `}
`;

const PrimaryButton = ({ iconName = '', loading = false, children, ...buttonProps }) => (
  <StyledButton {...buttonProps}>
    <ButtonIcon
      iconName={iconName}
      loading={loading}
      marginRight={!!(iconName && children)}
      large={buttonProps.large}
      loaderColor={buttonProps.naked ? 'BLACK' : 'WHITE'}
    />
    {(!loading || iconName) && children}
  </StyledButton>
);
PrimaryButton.displayName = 'PrimaryButton';

PrimaryButton.propTypes = {
  iconName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  previous: PropTypes.bool,
  reveal: PropTypes.bool,
  add: PropTypes.bool,
  confirm: PropTypes.bool,
  naked: PropTypes.bool,
  remove: PropTypes.bool,
  invertColor: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PrimaryButton;
