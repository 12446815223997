import { activeBreakpoints } from 'utils/Breakpoints/useBreakpoints';

const fontSize = theme => (activeBreakpoints().landscape ? theme.input.font.size : theme.input.font.mobileSize);

const disabledControl = isDisabled =>
  isDisabled
    ? {
        background: 'transparent',
        boxShadow: 'unset',
      }
    : {};

const oldSelectInputStyles = theme => ({
  control: (provided, { highlightError, isDisabled }) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    height: 'var(--input-height)',
    backgroundColor: theme.input.background.default,
    fontSize: fontSize(theme),
    paddingRight: theme.input.paddingHorizontal,
    border: 'var(--border-thin) solid var(--input-bc)',
    borderBottomColor: highlightError && 'var(--error-color)',
    borderRadius: '0',
    boxShadow: theme.input.shadow.static,
    transition: theme.input.transition,
    lineHeight: theme.input.lineHeight.default,
    ':hover': {
      boxShadow: theme.input.shadow.active,
      border: theme.input.border.active,
    },
    ':focus': {
      boxShadow: theme.input.shadow.active,
      border: theme.input.border.active,
    },
    ...disabledControl(isDisabled),
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    color: 'var(--supplemental-text-fg)',
    opacity: isDisabled && '0.35',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999,
    borderRadius: 0,
    boxShadow: theme.input.shadow.active,
    border: theme.input.border.active,
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '180px',
  }),
  option: provided => ({
    ...provided,
    fontSize: fontSize(theme),
    lineHeight: theme.input.lineHeight.default,
    paddingTop: '12px',
    paddingBottom: '16px',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: fontSize(theme),
    lineHeight: theme.input.lineHeight.default,
    overflow: 'visible',
    opacity: isDisabled && '0.35',
  }),
  noOptionsMessage: provided => ({
    ...provided,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.input.padding,
    fontSize: fontSize(theme),
    fontFamily: theme.input.font.family,
    lineHeight: theme.input.lineHeight.default,
  }),
  input: provided => ({
    ...provided,
    fontSize: fontSize(theme),
    lineHeight: theme.input.lineHeight.default,
  }),
});

export default oldSelectInputStyles;
