import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const annotations = ({ sensorIds, functionalLocation, customChartId, startDate, endDate }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/annotations`,
    query: {
      sensorIds,
      functionalLocation,
      customChartId,
      startDate,
      endDate,
    },
    noCache: true,
  });

export const upsertAnnotation = annotation =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/annotations`,
    data: annotation,
    noCache: true,
  });

export const deleteAnnotation = annotationId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/annotations/${annotationId}`,
    noCache: true,
  });

export const annotationsWithPublicView = ({ sensorIds, functionalLocation, startDate, endDate, publicViewId }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/annotations/with-public-view`,
    query: {
      sensorIds,
      functionalLocation,
      startDate,
      endDate,
      publicViewId,
    },
    useAuthorization: false,
  });
