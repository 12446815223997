import { PublicClientApplication, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import queryStringLibrary from 'query-string';
import { getCachedResponse, cacheResponse, invalidateCache } from './cache';
import {
  createRequestBody,
  getRequestCacheMode,
  getErrorHandler,
  handleJsonResponse,
  getFilenameFromResponse,
  downloadBlob,
} from './utils';
import { ApiSettingsProvider } from './providers';

import { loadAuthConfig } from './authConfig';

const CACHE_EXPIRATION_MS = 5 * 60 * 1000; // 5 minutes

/**
 * A list of local API endpoints for development (an up-to-date list can be found in webpack.config.js).
 * @typedef {'http://localhost:8210'|'http://localhost:8200'|'http://localhost:8201'|'http://localhost:8202'|'http://localhost:8203'|'http://localhost:8406'} ApiBaseUrls
 */

/**
 * @typedef {Object} RequestConfig
 * @property {string} apiPath - api endpoint path and query, no domain (must include leading slash)
 * @property {string} method - http method GET/POST/PUT/PATCH/DELETE
 * @property {Object} query - url query parameters as object
 * @property {string|Object} [data] - payload data either as string or json
 * @property {string} [mode] - Fetch API mode, defaults to cors
 * @property {string} [contentType] - Content type header, defaults to application/json
 * @property {boolean} [useAuthorization] - Defaults to true. If session exists and it must not be used in request, set to false.
 * @property {string} [credentials] - Fetch API credentials, defaults to include
 * @property {boolean} [noCache] - Disable cache, defaults to false
 * @property {boolean} [refreshCache] - Force cache refresh, defaults to false
 * @property {boolean} [enableSessionInvalidation] - Remove session and redirect to login when api responds 401, defaults to true
 * @property {boolean} [onlyInvalidateCache] - Only invalidate cache for this request, does not send the request to server
 * @property {number} [cacheExpirationTimeMs] - Fetch API credentials
 * @property {ApiBaseUrls} [apiBaseUrl] - Override the API base URL (no trailing slash) (works only in development environment!)
 *   Available URLs:
 *   http://localhost:8210 -> caverion-client-net-api, http://localhost:8200 -> caverion-client-net-profile-api,
 *   http://localhost:8201 -> caverion-client-net-master-data-api, http://localhost:8202 -> caverion-client-net-iot-api,
 *   http://localhost:8203 -> caverion-client-net-powerbi-api, http://localhost:8406 -> caverion-client-net-data-processing-api
 */

/** @type {RequestConfig} */
const defaults = Object.freeze({
  method: 'GET',
  contentType: 'application/json',
  useAuthorization: true,
  mode: 'cors',
  credentials: 'include',
  enableSessionInvalidation: true,
});

export const createRequestHeaders = ({ contentType, useAuthorization, accessToken }) => {
  const headers = new Headers();
  headers.append('ocp-apim-subscription-key', ApiSettingsProvider.getApiSubscriptionKey());
  headers.append('content-type', contentType);
  if (useAuthorization) {
    if (accessToken) {
      headers.append('authorization', `Bearer ${accessToken}`);
    } else {
      throw new Error('Authentication is required for this request');
    }
  }
  return headers;
};

export const fetchWithCache = async (request, { noCache, refreshCache, cacheExpirationTimeMs }) => {
  if (!noCache && !refreshCache) {
    let expirationMs = cacheExpirationTimeMs;
    if (!expirationMs) {
      expirationMs = CACHE_EXPIRATION_MS;
    }
    const cachedResponse = await getCachedResponse(request, expirationMs);
    if (cachedResponse) {
      return cachedResponse;
    }
  }
  const freshResponse = await fetch(request);
  if (!noCache) {
    await cacheResponse(request, freshResponse);
  }
  return freshResponse;
};

const ApiRequest = {};

ApiRequest.initialize = async () => {
  const config = loadAuthConfig();
  const msalInstance = new PublicClientApplication(config);

  await msalInstance.initialize();

  // Check if clientId matches with previously used to avoid mixing b2c and aad logins
  if (
    sessionStorage.getItem('msal.interaction.status') &&
    sessionStorage.getItem('msal.interaction.status') !== config.auth.clientId
  ) {
    msalInstance.clearCache();
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  ApiRequest.msalInstance = msalInstance;
  return msalInstance;
};

/**
 * Executes api request using Fetch and CacheStorage
 * @param {RequestConfig} config
 */
ApiRequest.request = async config => {
  const { apiPath, method, data, query, onlyInvalidateCache, apiBaseUrl, ...options } = config;
  if (!apiPath) {
    throw new Error('Missing request url path');
  }
  const queryString = query ? `?${queryStringLibrary.stringify(query)}` : '';
  const {
    contentType = defaults.contentType,
    useAuthorization = defaults.useAuthorization,
    mode = defaults.mode,
    credentials = defaults.credentials,
    enableSessionInvalidation = defaults.enableSessionInvalidation,
  } = options;
  let session;

  if (useAuthorization) {
    const activeAccount = ApiRequest.msalInstance.getActiveAccount();
    const accounts = ApiRequest.msalInstance.getAllAccounts();
    if (activeAccount && accounts.length >= 0) {
      try {
        session = await ApiRequest.msalInstance.acquireTokenSilent({
          scopes: loadAuthConfig().auth.scopes,
        });
      } catch (error) {
        console.log(error, InteractionRequiredAuthError);
        if (error instanceof InteractionRequiredAuthError) {
          session = await ApiRequest.msalInstance
            .acquireTokenPopup({
              scopes: loadAuthConfig().auth.scopes,
            })
            .catch(error => {
              console.error(error);
            });
        }
        console.error(error);
      }
    }
  }

  const request = new Request(
    `${
      (process.env.NODE_ENV === 'development' && apiBaseUrl) || ApiSettingsProvider.getApiUrl()
    }${apiPath}${queryString}`,
    {
      method: method || defaults.method,
      body: createRequestBody(data),
      headers: createRequestHeaders({
        contentType,
        useAuthorization,
        accessToken: session?.accessToken,
      }),
      mode,
      credentials,
      cache: getRequestCacheMode(method, options),
    }
  );

  if (onlyInvalidateCache) {
    return invalidateCache(request);
  }
  return fetchWithCache(request, options).then(getErrorHandler({ enableSessionInvalidation })).then(handleJsonResponse);
};

/**
 * Calls {@link ApiRequest#request} with method=GET set to {@link RequestConfig}
 * @param {RequestConfig} config
 */
ApiRequest.get = (config = {}) => ApiRequest.request({ ...config, method: 'GET' });
/**
 * Calls {@link ApiRequest#request} with method=POST set to {@link RequestConfig}
 * @param {RequestConfig} config
 */
ApiRequest.post = (config = {}) => ApiRequest.request({ ...config, method: 'POST' });
/**
 * Calls {@link ApiRequest#request} with method=PUT set to {@link RequestConfig}
 * @param {RequestConfig} config
 */
ApiRequest.put = (config = {}) => ApiRequest.request({ ...config, method: 'PUT' });
/**
 * Calls {@link ApiRequest#request} with method=PATCH set to {@link RequestConfig}
 * @param {RequestConfig} config
 */
ApiRequest.patch = (config = {}) => ApiRequest.request({ ...config, method: 'PATCH' });
/**
 * Calls {@link ApiRequest#request} with method=DELETE set to {@link RequestConfig}
 * @param {RequestConfig} config
 */
ApiRequest.delete = (config = {}) => ApiRequest.request({ ...config, method: 'DELETE' });
/**
 * Invalidates cache of {@link ApiRequest#request} without sending any request to server
 * @param {RequestConfig} config
 */
ApiRequest.invalidateCache = (config = {}) => ApiRequest.request({ ...config, onlyInvalidateCache: true });
/**
 * Calls {@link ApiRequest#request} with given config and
 * immediately downloads the attachment from api response
 * @param {RequestConfig} config
 */
ApiRequest.download = async config => {
  const response = await ApiRequest.request(config);
  const filename = config.fileName || getFilenameFromResponse(response);
  const blob = await response.blob();
  downloadBlob(blob, filename, config.windowName);
};

export default ApiRequest;
