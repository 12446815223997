import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import TermsOfService from './TermsOfService';
import PageTemplate from '../User/PageTemplate';

export const Page = ({ title, fetchTerms }) => {
  const { selectedLanguage, setSelectedLanguage, translationsReady } = useUnauthenticatedTranslations();
  const [termsReady, setTermsReady] = useState();
  const [terms, setTerms] = useState();

  useEffect(() => {
    fetchTerms(selectedLanguage)
      .then(response => setTerms(response))
      .finally(() => setTermsReady(true));
  }, [fetchTerms, selectedLanguage]);

  if (!translationsReady || !termsReady) {
    return null;
  }

  const languageSelector = (
    <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
  );
  return (
    <PageTemplate title={title} languageSelector={languageSelector}>
      <TermsOfService text={terms.text} title={title} />
    </PageTemplate>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  fetchTerms: PropTypes.func.isRequired,
};

export default Page;
