import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Hero from 'components/Hero/Hero';
import Header from 'containers/Application/Header/Header';
import StandardPage from 'components/StandardPage/StandardPage';
import RescuePlanTable from './RescuePlanTable/RescuePlanTable';

import MasterDataService from 'services/masterData';

const Container = styled.div`
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  width: 100%;
  margin: var(--size-md) auto 0;

  ${props => props.theme.media.landscape`
    margin: ${props => props.theme.section.spacing} auto 0;
  `}
`;

const PartnerRescuePlan = props => {
  const {
    customers,
    match: {
      params: { partnerNumber },
    },
    t,
    features: { rescuePlan },
  } = props;

  const [rescueFunctionalLocations, setRescueFunctionalLocations] = useState({});
  const [error, setError] = useState(null);

  const loadRescuePlanFunctionalLocationsByPartner = async partnerNumber => {
    try {
      const result = await MasterDataService.adminFunctionalLocationsByPartnerNumbers([partnerNumber], ['SYSTEM']);
      const safetyUnits = result.filter(location => {
        return location.functionalLocation.endsWith('-SAF');
      });
      const safs = Object.fromEntries(safetyUnits.map(location => [location.path[1], location]));

      setRescueFunctionalLocations(safs);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (rescuePlan && partnerNumber && partnerNumber !== 'all') {
      loadRescuePlanFunctionalLocationsByPartner(partnerNumber);
    }
  }, [rescuePlan, partnerNumber]);

  if (!rescuePlan) {
    return null;
  }

  const customerName = customers[partnerNumber] && customers[partnerNumber].name;

  return (
    <StandardPage withTabs>
      <Helmet title={t('Pelsu')} />
      <Header t={t} selected="rescuePlan" showPartnerSelect />
      <Hero t={t} title={t('Pelsu')} subTitle={customerName} type="DOCUMENTS" />
      <Container>
        <RescuePlanTable match={props.match} rescueFunctionalLocations={rescueFunctionalLocations} />
      </Container>
    </StandardPage>
  );
};

PartnerRescuePlan.propTypes = {
  customers: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  features: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  customers: state.customer.customers,
  features: state.profile.profile.features,
});

const connector = connect(mapStateToProps);

export default connector(translations(PartnerRescuePlan));
