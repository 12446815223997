import React from 'react';
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import SkeletonAnimation from './SkeletonAnimation';

export const Skeleton = styled.div`
  position: relative;
  overflow: hidden;
  margin: auto;
  margin-top: ${props => (!isNil(props.margin) ? props.margin : 'var(--size-xs)')};
  &:first-child {
    margin-top: 0;
  }
  border-radius: 4px;
  width: ${props => props.width || '100%'};
  height: ${props => (props.header && 'var(--size-xl)') || 'var(--size-md)'};
  background-color: ${props => props.theme.skeleton.backgroundColor};
`;
Skeleton.displayName = 'Skeleton';

const SkeletonText = props => (
  <Skeleton {...props}>
    <SkeletonAnimation />
  </Skeleton>
);

export default SkeletonText;
