export const RECYCLING_META_PROPS = {
  RECYCLABLE: 'recyclable',
  RECOVERABLE: 'recoverable',
  CO2E_FACTOR: 'co2eFactor',
};

// Defra's emission factors
// https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/847122/Conversion-Factors-2019-Full-set-for-advanced-users.xls

const DEFRA_CO2E_FACTORS = {
  // per tonne
  LANDFILL_WASTE: 0.5865138,
  CONSTRUCTION_WASTE: 0.0012643, // other than soil
  BIOWASTE: 0.0102039,
  CARDBOARD: 0.0213538,
  ELECTRONICS: 0.0213538,
  GLASS: 0.0213538,
  HEAVY_METAL_BATTERIES: 0.0646365,
  MAGAZINE_PAPER: 0.0213538,
  METAL: 0.0213538,
  OFFICE_PAPER: 0.0213538,
  PLASTIC: 0.0213538,
  MIXED_WASTE: 0.0213538,
  BURNED_MIXED_WASTE: 0.0213538,
  ENERGY_WASTE: 0.0213538,
  WOOD: 0.0213538,
};

// Notes: These factors are defined by Gaia, not yet in use
// Hazardous waste (other than batteries, e.g. chemicals)	(no EWC code encompassing all hazardous waste)
// Soil: landfilled
// Soil: recycled
// Grease trap sludge and (organic) oily waste, solid

const createEntries = ({ sensorType, recyclable, recoverable, co2factor }) => ({
  [`${sensorType}/${RECYCLING_META_PROPS.RECYCLABLE}`]: recyclable,
  [`${sensorType}/${RECYCLING_META_PROPS.RECOVERABLE}`]: recoverable,
  [`${sensorType}/${RECYCLING_META_PROPS.CO2E_FACTOR}`]: co2factor,
});

const RECYCLING_GLOBAL_META_DEFAULTS = {
  // Non-recyclable
  ...createEntries({ sensorType: 'waste/landfill_waste', co2factor: DEFRA_CO2E_FACTORS.LANDFILL_WASTE }),
  ...createEntries({ sensorType: 'waste/construction_waste', co2factor: DEFRA_CO2E_FACTORS.CONSTRUCTION_WASTE }),

  // Recyclable
  ...createEntries({ sensorType: 'waste/biowaste', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.BIOWASTE }),
  ...createEntries({ sensorType: 'waste/cardboard', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.CARDBOARD }),
  ...createEntries({ sensorType: 'waste/electronics', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.ELECTRONICS }),
  ...createEntries({ sensorType: 'waste/glass', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.GLASS }),
  ...createEntries({
    sensorType: 'waste/heavy_metal_batteries',
    recyclable: true,
    co2factor: DEFRA_CO2E_FACTORS.HEAVY_METAL_BATTERIES,
  }),
  ...createEntries({
    sensorType: 'waste/magazine_paper',
    recyclable: true,
    co2factor: DEFRA_CO2E_FACTORS.MAGAZINE_PAPER,
  }),
  ...createEntries({ sensorType: 'waste/metal', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.METAL }),
  ...createEntries({ sensorType: 'waste/office_paper', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.OFFICE_PAPER }),
  ...createEntries({ sensorType: 'waste/plastic', recyclable: true, co2factor: DEFRA_CO2E_FACTORS.PLASTIC }),

  // Non-recyclable, but recoverable
  ...createEntries({ sensorType: 'waste/mixed_waste', recoverable: true, co2factor: DEFRA_CO2E_FACTORS.MIXED_WASTE }),
  ...createEntries({
    sensorType: 'waste/burned_mixed_waste',
    recoverable: true,
    co2factor: DEFRA_CO2E_FACTORS.BURNED_MIXED_WASTE,
  }),
  ...createEntries({ sensorType: 'waste/energy_waste', recoverable: true, co2factor: DEFRA_CO2E_FACTORS.ENERGY_WASTE }),
  ...createEntries({ sensorType: 'waste/wood', recoverable: true, co2factor: DEFRA_CO2E_FACTORS.WOOD }),
};

const getOptions = key => {
  if (key.includes(RECYCLING_META_PROPS.RECYCLABLE) || key.includes(RECYCLING_META_PROPS.RECOVERABLE)) {
    return ['true', 'false'];
  }
  return [];
};

export const RECYCLING_META_KEYS = Object.keys(RECYCLING_GLOBAL_META_DEFAULTS).reduce(
  (prev, curr) => ({ ...prev, [curr]: getOptions(curr) }),
  {}
);

export const GLOBAL_DEFAULTS = Object.keys(RECYCLING_GLOBAL_META_DEFAULTS).map(key => ({
  key,
  value: RECYCLING_GLOBAL_META_DEFAULTS[key],
}));

