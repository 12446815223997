/**
 * Return an analytics function object with updated parameters.
 * Analytics function parameters with matching id property are replaced
 * with updated objects, non-matched parameters remain unchanged.
 * @param {object} analyticsFunction
 * @param {array} updatedParameters
 */
export const updateFunctionParameters = (analyticsFunction, updatedParameters) => ({
  ...analyticsFunction,
  parameters: analyticsFunction.parameters.map(
    parameter => updatedParameters.find(up => up.id === parameter.id) || parameter
  ),
});
