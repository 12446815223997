import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from '../masterData/index';

export const invalidateMaintenanceFileCaches = () =>
  Promise.all([
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/maintenance-plan`),
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/service-order`),
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/planned-maintenance`),
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/checklist-report`),
  ]);

export const findForMaintenancePlanFn = (request, { maintenancePlanId, refreshCache = false }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/maintenance-plan/${maintenancePlanId}`,
    refreshCache,
    useAuthorization: true,
  });

export const findForServiceOrderFn = (request, { serviceOrderNumber, refreshCache = false }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/service-order/${serviceOrderNumber}`,
    refreshCache,
    useAuthorization: true,
  });

export const findForServiceOrderRequestFn = (request, { serviceOrderNumber, refreshCache = false } = {}) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/service-order/${serviceOrderNumber}/untrusted`,
    refreshCache,
    useAuthorization: true,
  });

export const findForPlannedMaintenanceFn = (request, { plannedMaintenanceId, refreshCache = false }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/planned-maintenance/${plannedMaintenanceId}`,
    refreshCache,
    useAuthorization: true,
  });

export const findForLongTermPlanFn = (request, { longTermPlanId, refreshCache = false }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/long-term-plan/${longTermPlanId}`,
    refreshCache,
    useAuthorization: true,
  });

export const findForChecklistReport = ({ checklistReportId, onlyInvalidateCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/checklist-report/${checklistReportId}`,
    onlyInvalidateCache,
    useAuthorization: true,
  });

export const findForChecklistReportWithToken = ({ token, checklistReportId, onlyInvalidateCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/checklist-report/${checklistReportId}/with-token`,
    query: { token },
    onlyInvalidateCache,
    useAuthorization: false,
  });

export const downloadArchiveForChecklistReport = ({ checklistReportId }) =>
  ApiRequest.download({
    method: 'GET',
    apiPath: `${SERVICE_API_PATH}/document/files/checklist-report/${checklistReportId}/download`,
    useAuthorization: true,
    noCache: true,
  });

export const downloadUrlForObjectFn = (request, { fileId, inline }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-object/${fileId}`,
    query: { inline },
    useAuthorization: true,
  });

export const downloadUrl = ({ fileId, functionalLocation, inline }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url/${fileId}`,
    query: { functionalLocation, inline },
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForPartnerFn = (request, { fileId, inline }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-partner/${fileId}`,
    query: { inline },
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForServiceOrderWithToken = ({ fileId, token, checklistReportId }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-service-order/${fileId}/with-token`,
    query: { token, checklistReportId },
    noCache: true,
    useAuthorization: false,
  });

export const downloadUrlForCustomViewFn = (request, { customViewId }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-custom-view/${customViewId}`,
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForAdditionalSaleWithToken = ({ fileId, token }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-additional-sale-attachment/${fileId}/with-token`,
    query: { token },
    noCache: true,
    useAuthorization: false,
  });

export const downloadExternalFileFn = (request, { functionalLocation, fileId, fileName, windowName }) =>
  request.download({
    apiPath: `${SERVICE_API_PATH}/document/files/download/external/${functionalLocation}/${fileId}`,
    fileName,
    windowName,
    noCache: true,
    useAuthorization: true,
  });

export const downloadUrlForServiceOrderUntrustedFn = (request, { serviceOrderNumber, fileName }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/download-url-for-service-order/${serviceOrderNumber}/untrusted`,
    query: { fileName },
    noCache: true,
    useAuthorization: true,
  });

export const filesForFunctionalLocation = ({
  functionalLocation,
  partnerNumber,
  includeChildrenOfSystemGeneratedFolders = true,
  refreshCache = false,
}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files`,
    query: { functionalLocation, partnerNumber, includeChildrenOfSystemGeneratedFolders },
    refreshCache,
    useAuthorization: true,
  });

export const filesForPartner = ({ partnerNumber, refreshCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/${partnerNumber}`,
    refreshCache,
    useAuthorization: true,
  });

export const fileCounts = ({ partnerNumber }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/files/${partnerNumber}/counts`,
    useAuthorization: true,
  });

export const filesForServiceOrderWithTokenFn = (request, { token }) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/document/files/with-token`,
    query: { token },
    useAuthorization: false,
  });

export const uploadFile = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/files`,
    data,
    noCache: true,
    useAuthorization: true,
  });

export const uploadCustomViewFile = ({ file }) => {
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/files`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });
};

export const uploadFileWithToken = async ({ file, token }) => {
  const result = await ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/files/with-token`,
    query: { token },
    data: file,
    noCache: true,
    useAuthorization: false,
  });
  await invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/document/files/with-token`);
  return result;
};

export const updateFileFn = (request, { file, functionalLocation }) =>
  request.patch({
    apiPath: `${SERVICE_API_PATH}/document/files/functional-location/${functionalLocation}`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });

export const updatePartnerFileFn = (request, { file, partnerNumber }) =>
  request.patch({
    apiPath: `${SERVICE_API_PATH}/document/files/partner/${partnerNumber}`,
    data: file,
    noCache: true,
    useAuthorization: true,
  });

export const deleteFile = ({ fileId }) =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/${fileId}`,
    noCache: true,
    useAuthorization: true,
  });

export const deleteCustomViewFile = ({ customViewId }) =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/delete-custom-view-file/${customViewId}`,
    noCache: true,
    useAuthorization: true,
  });

export const deleteServiceOrderUntrustedFileFn = (request, { serviceOrderNumber, fileName }) =>
  request.delete({
    apiPath: `${SERVICE_API_PATH}/document/files/delete-service-order-untrusted-file/${serviceOrderNumber}`,
    query: { fileName },
    noCache: true,
    useAuthorization: true,
  });

export default wrapEachWithThunkRequest({
  findForMaintenancePlan: findForMaintenancePlanFn,
  findForServiceOrder: findForServiceOrderFn,
  findForServiceOrderRequest: findForServiceOrderRequestFn,
  findForPlannedMaintenance: findForPlannedMaintenanceFn,
  findForLongTermPlan: findForLongTermPlanFn,
  downloadUrlForObject: downloadUrlForObjectFn,
  downloadUrlForPartner: downloadUrlForPartnerFn,
  downloadUrlForCustomView: downloadUrlForCustomViewFn,
  downloadExternalFile: downloadExternalFileFn,
  downloadUrlForServiceOrderUntrusted: downloadUrlForServiceOrderUntrustedFn,
  filesForServiceOrderWithToken: filesForServiceOrderWithTokenFn,
  updateFile: updateFileFn,
  updatePartnerFile: updatePartnerFileFn,
  deleteServiceOrderUntrustedFile: deleteServiceOrderUntrustedFileFn,
});
