import React from 'react';
import PropTypes from 'prop-types';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SortableTable from 'components/SortableTable/SortableTable';
import SummaryRow from 'containers/Application/Recycling/RecyclingBenchmark/SummaryTab/SummaryRow';
import ErrorBoundaryFallback from 'components/ErrorPage/ErrorBoundaryFallback';
import connect from 'containers/Application/Recycling/RecyclingBenchmark/SummaryTab/connectSummaryTab';
import translations from 'decorators/Translations/translations';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { formatNumber } from 'utils/Number/decimalFormat';

const SkeletonBodyComponent = () => (
  <tbody>
    <tr>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);

const formatValues = (entry, userLanguage, columns) =>
  Object.keys(entry)
    .filter(key => columns.some(column => column.field === key && key !== 'type'))
    .map(key => ({
      [key]: entry[key] > 0 ? formatNumber(userLanguage, entry[key]) : null,
    }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

const translateResult = (t, userLanguage, result) => ({
  ...result,
  columns: result.columns?.map(column => ({
    ...column,
    title: t(column.title),
  })),
  data: result.data?.map(entry => ({
    ...entry,
    type: `${t(entry.type)}, ${t(entry.unit)}`,
    children: entry.children?.map(childEntry => ({
      type: t(childEntry.type),
      ...formatValues(childEntry, userLanguage, result.columns),
    })),
    ...formatValues(entry, userLanguage, result.columns),
  })),
});

export const SummaryTab = ({
  t,
  profile,
  containerLoading,
  visible,
  partnerNumber,
  sensorIds,
  loadSummaryData,
  data,
}) => {
  React.useEffect(() => {
    if (!containerLoading && data.needsReload) {
      loadSummaryData({
        partnerNumber,
        sensorIds,
        yearCount: 3,
      });
    }
  }, [containerLoading, data.needsReload]);

  if (!visible) {
    return null;
  }
  if (data.error.status) {
    return <ErrorBoundaryFallback />;
  }
  const translatedResult = translateResult(t, profile.language, data.result);
  return (
    <SortableTable
      t={t}
      columns={translatedResult.columns}
      data={translatedResult.data}
      RowComponent={SummaryRow}
      SkeletonBodyComponent={SkeletonBodyComponent}
      loading={containerLoading || data.isLoading}
      removeHeadOnMobile={false}
      topStripe={true}
      headerBackgroundColor="alabaster"
      orderBy="nothing"
    />
  );
};

SummaryTab.propTypes = {
  t: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  containerLoading: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  partnerNumber: PropTypes.string,
  sensorIds: PropTypes.array.isRequired,
  loadSummaryData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      message: PropTypes.string,
    }).isRequired,
    needsReload: PropTypes.bool.isRequired,
    result: PropTypes.shape({
      columns: PropTypes.array,
      data: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

export default withRouter(translations(withTheme(connect(SummaryTab))));
