import { handleActions, combineActions, createAction } from 'redux-actions';
import { isError } from 'flux-standard-action';

const createActionWithMeta = (type, loading) =>
  createAction(
    type,
    ({ payload }) => payload,
    ({ meta }) => ({ ...meta, loading })
  );

export const createActions = type => ({
  load: createActionWithMeta(`${type}`, true),
  fail: createActionWithMeta(`${type}_FAIL`, false),
  success: createActionWithMeta(`${type}_SUCCESS`, false),
});

export const createReducer = (
  { load, fail, success },
  initialState = { error: { status: false }, data: [], meta: { loading: false } }
) =>
  handleActions(
    {
      [combineActions(load, fail, success)]: (state, action) => {
        const { payload, error, meta } = action;
        if (isError(action)) {
          return { ...state, error: { status: error, message: payload.message }, meta };
        }
        return { ...state, error: { status: false }, data: payload, meta };
      },
    },
    initialState
  );
