import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const uploadFileFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/fileContainers/uploadFile`,
    data,
    noCache: true
  })

export default wrapEachWithThunkRequest({
  uploadFile: uploadFileFn
});
