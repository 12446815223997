export const formatNumber = (userLanguage, value, options) => {
  if (!value) {
    return 0;
  }
  if (userLanguage) {
    return Number(value).toLocaleString(userLanguage, options);
  }
  if (Number.isFinite(options?.maximumFractionDigits)) {
    return Number(value).toFixed(options.maximumFractionDigits);
  }
  return value;
};
