import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';

const StyledEquipmentColumn = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
`;

export const EquipmentColumn = ({ equipmentCount, t, type }) => (
  <StyledEquipmentColumn>
    {`${equipmentCount} ${type === 'EQUIPMENT' ? t('Subequipment') : t('Equipment')}`}
  </StyledEquipmentColumn>
);

EquipmentColumn.propTypes = {
  t: PropTypes.func.isRequired,
  equipmentCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
};

export default translations(EquipmentColumn);
