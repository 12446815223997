import NudeWrapper from './Wrappers/NudeWrapper';
import DefaultSettingsWrapper from './Wrappers/DefaultSettingsWrapper';
import TitleWrapper from './Wrappers/TitleWrapper';
import BreakRowWrapper from './Wrappers/BreakRowWrapper';
import CheckboxWrapper from './Wrappers/CheckboxWrapper';
import HiddenWrapper from './Wrappers/HiddenWrapper';
import { CHART_TYPES } from 'containers/Application/Modules/CustomChartsModule/constants';

export { CHART_TYPES };

export const ENERGY_BASE_UNIT = 'kWh';

export const INPUT_TYPES = {
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  CHECKBOX: 'CHECKBOX',
  TIME_FRAME: 'TIME_FRAME',
  COMPARABLE_YEARS_SELECTION: 'COMPARABLE_YEARS_SELECTION',
  EMPTY_COMPONENT: 'EMPTY_COMPONENT',
};

export const COMPARABLE_YEARS = {
  LAST_2_YEARS: -2,
  LAST_3_YEARS: -3,
  LAST_4_YEARS: -4,
  LAST_5_YEARS: -5,
  LAST_6_YEARS: -6,
  CUSTOM: 'CUSTOM',
};

export const GRANULARITY_OPTIONS = {
  AUTOMATIC: 'automatic',
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  DAILY: 'daily',
  HOURLY: 'hourly',
  RAW: 'raw',
};

export const AGGREGATION_OPTIONS = {
  AVERAGE: 'average',
  SUM: 'sum',
};

const HOUR = 60 * 60 * 1000;

export const TIMEFRAME_PRESETS = {
  LAST_24_HOURS: -(HOUR * 24),
  LAST_7_DAYS: -(HOUR * 24 * 7),
  LAST_31_DAYS: -(HOUR * 24 * 31),
  LAST_6_MONTHS: -(HOUR * 24 * 30 * 6),
  YEAR_TO_DATE: -1,
  LAST_365_DAYS: -(HOUR * 24 * 365),
  ALL_DATA: -3,
  AUTOMATIC: -2,
  CUSTOM: 'custom',
};

const NAME_INPUT = {
  label: 'Chart title',
  property: 'title',
  required: true,
  type: INPUT_TYPES.TEXT,
  WrapperComponent: TitleWrapper,
};

export const NORMALIZED_ENERGY_OPTIONS = {
  NORMALIZED: 'normalized',
  ABSOLUTE: 'absolute',
};

export const CHART_INPUTS = {
  NAME: NAME_INPUT,
  ACCESS_RIGHTS: {
    label: 'Access rights',
    property: 'public',
    type: INPUT_TYPES.SELECT,
    options: [
      {
        label: 'Private',
        value: false,
      },
      {
        label: 'Public',
        value: true,
      },
    ],
    clearable: false,
  },
  TIME_FRAME: {
    label: 'Default time frame',
    property: 'timeFrame',
    type: INPUT_TYPES.TIME_FRAME,
    WrapperComponent: NudeWrapper,
    clearable: false,
  },
  GRANULARITY: {
    label: 'Granularity',
    property: 'aggregationFreq',
    type: INPUT_TYPES.SELECT,
    options: [
      {
        label: 'Automatic',
        value: GRANULARITY_OPTIONS.AUTOMATIC,
      },
      {
        label: 'Monthly',
        value: GRANULARITY_OPTIONS.MONTHLY,
      },
      {
        label: 'Weekly',
        value: GRANULARITY_OPTIONS.WEEKLY,
      },
      {
        label: 'Daily',
        value: GRANULARITY_OPTIONS.DAILY,
      },
      {
        label: 'Hourly',
        value: GRANULARITY_OPTIONS.HOURLY,
      },
      {
        label: 'Raw',
        value: GRANULARITY_OPTIONS.RAW,
      },
    ],
    clearable: false,
  },
  SHOW_AVERAGES: {
    label: 'Show averages',
    property: 'showAverages',
    type: INPUT_TYPES.CHECKBOX,
    keyConfiguration: true,
    WrapperComponent: CheckboxWrapper,
  },
  FLIP_AXIS: {
    label: 'Flip axes',
    property: 'flipAxes',
    type: INPUT_TYPES.CHECKBOX,
    WrapperComponent: CheckboxWrapper,
  },
  SHOW_OUTDOOR_TEMPERATURE: {
    label: 'Show Outdoor Temperature',
    property: 'showOutdoorTemperature',
    type: INPUT_TYPES.CHECKBOX,
    keyConfiguration: true,
    WrapperComponent: CheckboxWrapper,
  },
  SHOW_TOTALS: {
    label: 'Show totals',
    property: 'showTotals',
    type: INPUT_TYPES.CHECKBOX,
    keyConfiguration: true,
    WrapperComponent: CheckboxWrapper,
  },
  ORDER_BY: {
    label: 'Order by',
    property: 'sort',
    type: INPUT_TYPES.SELECT,
    disabled: true,
    clearable: false,
  },
  COMPARABLE_YEARS: {
    label: 'Comparable years',
    property: 'comparableYears',
    type: INPUT_TYPES.COMPARABLE_YEARS_SELECTION,
    WrapperComponent: NudeWrapper,
    options: [
      {
        label: 'Last {0} years',
        value: COMPARABLE_YEARS.LAST_2_YEARS,
        labelCount: 2,
      },
      {
        label: 'Last {0} years',
        value: COMPARABLE_YEARS.LAST_3_YEARS,
        labelCount: 3,
      },
      {
        label: 'Last {0} years',
        value: COMPARABLE_YEARS.LAST_4_YEARS,
        labelCount: 4,
      },
      {
        label: 'Last {0} years',
        value: COMPARABLE_YEARS.LAST_5_YEARS,
        labelCount: 5,
      },
      {
        label: 'Last {0} years',
        value: COMPARABLE_YEARS.LAST_6_YEARS,
        labelCount: 6,
      },
      {
        label: 'Custom',
        value: COMPARABLE_YEARS.CUSTOM,
      },
    ],
  },
  UNIT: {
    label: 'Unit',
    property: 'unit',
    type: INPUT_TYPES.SELECT,
  },
  REFERENCE: {
    property: 'referenceMetaKey',
    type: INPUT_TYPES.SELECT,
    clearable: true,
    WrapperComponent: HiddenWrapper,
  },
  SHOW_TREND: {
    label: 'Show trend',
    property: 'showTrend',
    type: INPUT_TYPES.CHECKBOX,
    keyConfiguration: true,
    WrapperComponent: CheckboxWrapper,
  },
  BREAK_ROW: {
    type: INPUT_TYPES.EMPTY_COMPONENT,
    WrapperComponent: BreakRowWrapper,
  },
  NORMALIZED_ENERGY: {
    label: 'Values',
    property: 'normalizedEnergySensor',
    type: INPUT_TYPES.SELECT,
    options: [
      {
        label: 'Absolute',
        value: NORMALIZED_ENERGY_OPTIONS.ABSOLUTE,
      },
      {
        label: 'Normalized',
        value: NORMALIZED_ENERGY_OPTIONS.NORMALIZED,
      },
    ],
    clearable: false,
  },
};

export const CHART_CONFIGS = {
  [CHART_TYPES.DEFAULT]: {
    inputs: [
      CHART_INPUTS.NAME,
      CHART_INPUTS.ACCESS_RIGHTS,
      CHART_INPUTS.TIME_FRAME,
      CHART_INPUTS.GRANULARITY,
      CHART_INPUTS.ORDER_BY,
      CHART_INPUTS.BREAK_ROW,
      CHART_INPUTS.SHOW_OUTDOOR_TEMPERATURE,
      CHART_INPUTS.FLIP_AXIS,
      CHART_INPUTS.SHOW_AVERAGES,
    ],
    limits: {
      sensorLimit: 8,
      unitLimit: 2,
    },
  },
  [CHART_TYPES.COMPARISON]: {
    inputs: [
      CHART_INPUTS.NAME,
      CHART_INPUTS.COMPARABLE_YEARS,
      CHART_INPUTS.ACCESS_RIGHTS,
      CHART_INPUTS.UNIT,
      CHART_INPUTS.NORMALIZED_ENERGY,
      CHART_INPUTS.BREAK_ROW,
      CHART_INPUTS.REFERENCE,
      CHART_INPUTS.SHOW_OUTDOOR_TEMPERATURE,
      CHART_INPUTS.FLIP_AXIS,
      CHART_INPUTS.SHOW_TOTALS,
      CHART_INPUTS.SHOW_AVERAGES,
    ],
    limits: {
      sensorLimit: 1,
      unitLimit: 1,
    },
  },
  [CHART_TYPES.SCATTER_PLOT]: {
    inputs: [CHART_INPUTS.NAME, CHART_INPUTS.ACCESS_RIGHTS, CHART_INPUTS.BREAK_ROW, CHART_INPUTS.SHOW_TREND],
    limits: {
      sensorLimit: 8,
      unitLimit: 2,
    },
  },
};

export const SETTINGS_WRAPPER_COMPONENTS = {
  [CHART_TYPES.DEFAULT]: DefaultSettingsWrapper,
};

export const AUTOMATIC_ORDER_BY_OPTION = {
  value: 'automatic',
  label: 'Automatic',
};
