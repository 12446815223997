import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import StatusMeter from 'components/StatusMeter/StatusMeter';
import SensorNarrowItem from './SensorNarrowItem';

const SensorItem = props => {
  const { property, performance, value, onClick, noRounding, unit, ...other } = props;

  const roundedValue = !performance || noRounding ? performance : Math.round(performance);

  const hasOnClick = typeof onClick === 'function';
  const onCardClick = React.useCallback(() => onClick(property, value), [onClick, property, value]);

  const [isNarrow, setIsNarrow] = React.useState(window.innerHeight < 600);
  const throttledSetNarrow = React.useCallback(throttle(() => setIsNarrow(window.innerHeight < 600), 100), []);

  React.useEffect(() => {
    window.addEventListener('resize', throttledSetNarrow);

    return () => {
      window.removeEventListener('resize', throttledSetNarrow);
    };
  }, []);

  if (isNarrow) {
    return (
      <SensorNarrowItem value={roundedValue} onClick={hasOnClick ? onCardClick : undefined} unit={unit} {...other} />
    );
  }

  return (
    <StatusMeter
      value={unit && props.noChart && !props.noUnit ? `${roundedValue} ${unit}` : roundedValue}
      onClick={hasOnClick ? onCardClick : undefined}
      {...other}
    />
  );
};
SensorItem.displayName = 'SensorItem';

SensorItem.defaultProps = {
  title: '',
  subtitle: undefined,
  loading: false,
  color: undefined,
  selected: false,
  onClick: undefined,
  property: undefined,
  value: undefined,
  noRounding: false,
  noUnit: undefined,
};

SensorItem.propTypes = {
  value: PropTypes.any,
  property: PropTypes.string,
  performance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.string,
  noRounding: PropTypes.bool,
  unit: PropTypes.string,
  noUnit: PropTypes.bool,
  noChart: PropTypes.bool,
};

export default SensorItem;
