import { OBSERVATION_TABS } from './utils';
import { onClickPrint } from 'utils/Print/print';
import orderBy from 'lodash/orderBy';

export const orderByTimestamp = data => orderBy(data, ['timestamp'], ['desc']);

export const dropdownItems = ({
  t,
  selectedTab,
  clickHandlers: {
    onClickDownloadObservations,
    onClickPrintObservations = onClickPrint,
    onClickDownloadInvestments,
    onClickPrintInvestments = onClickPrint,
    onShowObservationListConfiguration,
  },
}) => {
  const items = [];

  items.push({
    label: t('Export'),
    items: [
      {
        label: t('CSV'),
        onClick:
          selectedTab === OBSERVATION_TABS.OBSERVATIONS ? onClickDownloadObservations : onClickDownloadInvestments,
      },
      {
        label: t('PDF'),
        onClick: selectedTab === OBSERVATION_TABS.OBSERVATIONS ? onClickPrintObservations : onClickPrintInvestments,
      },
    ],
  });

  if (selectedTab === OBSERVATION_TABS.OBSERVATIONS) {
    items.push({
      label: t('List configuration'),
      onClick: onShowObservationListConfiguration,
    });
  }

  return items;
};
