import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PrimaryButton from 'components/Button/PrimaryButton';
import { useTranslations } from 'decorators/Translations/translations';

const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 800px;
  max-width: 90%;

  ${props => props.theme.media.portrait`
    padding-bottom: ${props => props.theme.section.spacing};
  `}
`;
Content.displayName = 'Content';

const Title = styled.h2`
  color: var(--headline-fg);
  text-align: center;
  white-space: normal;
  font-size: ${props => props.theme.font.size.xxxl};
  font-weight: ${props => props.theme.font.weight.normal};
`;
Title.displayName = 'Title';

const DefaultTitle = styled.h2`
  margin-bottom: ${props => props.theme.spacing.md};
  color: var(--headline-fg);
  text-align: center;
  white-space: normal;
  font-size: ${props => props.theme.font.size.xxxl};
  font-weight: ${props => props.theme.font.weight.bold};
`;
DefaultTitle.displayName = 'DefaultTitle';

const Instructions = styled.p`
  font-weight: ${props => props.theme.font.weight.normal};
  font-size: ${props => props.theme.font.size.sm};
  padding: ${props => props.theme.section.spacing};
  text-align: center;
  line-height: ${props => props.theme.lineHeight.text};
`;
Instructions.displayName = 'Instructions';

const ButtonContainer = styled.div`
  display: flex;
  a {
    margin-right: 1em;
  }
`;
ButtonContainer.displayName = 'ButtonContainer';

const ErrorPageContent = ({
  pageTitle,
  title,
  instructions,
  buttonText,
  buttonLink,
  onButtonClick,
  buttons: Buttons = () => null,
}) => {
  const [t] = useTranslations();

  return (
    <Content>
      <DefaultTitle>{pageTitle ?? t('Something went wrong')}</DefaultTitle>
      {title && <Title>{title}</Title>}
      {instructions && <Instructions>{instructions}</Instructions>}
      <ButtonContainer>
        {buttonLink && (
          <a href={buttonLink}>
            <PrimaryButton large add>
              {buttonText}
            </PrimaryButton>
          </a>
        )}
        {onButtonClick && (
          <PrimaryButton large add onClick={onButtonClick}>
            {buttonText}
          </PrimaryButton>
        )}
        <Buttons />
      </ButtonContainer>
    </Content>
  );
};

ErrorPageContent.defaultProps = {
  title: null,
  instructions: null,
  buttonLink: null,
  buttonText: null,
  onButtonClick: null,
};

ErrorPageContent.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  instructions: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  onButtonClick: PropTypes.func,
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ErrorPageContent;
