import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { getFunctionalLocation } from '../../ServiceOrder/connectServiceOrder';

const PrintHeader = styled.div`
  display: none;
  @media print {
    width: 100%;
    display: flex;
    font-size: ${props => props.theme.fontSize.xxs};
    color: ${props => props.theme.colors.darkGray};
    margin-bottom: var(--size-lg);
    justify-content: space-between;
  }
`;

const ChecklistPrintHeader = ({ serviceOrder }) => {
  const functionalLocation = useSelector(state => getFunctionalLocation(state, { functionalLocationId: serviceOrder.functionalLocation }))
  return (
    <PrintHeader>
        {functionalLocation?.name}<br />
        {serviceOrder.serviceOrderNumber}, {serviceOrder.title}
    </PrintHeader>
  );
};

ChecklistPrintHeader.propTypes = {
  serviceOrder: PropTypes.object,
};

export default ChecklistPrintHeader;
