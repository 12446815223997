import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getPartnerNumber,
  getFunctionalLocationId,
  syntheticSensorsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { loadOpiData } from 'redux/modules/iot/values/recycling';
import { PERCENTAGE_PRECISION, CO2E_TONNES_PRECISION, TONNES_PRECISION } from 'constants/recycling';

const getAverage = (result, aggregation, precision) => {
  if (!isEmpty(result)) {
    const values = result.filter(entry => entry.aggregation === aggregation).map(entry => entry?.value || 0);
    const sum = values.reduce((prev, curr) => prev + curr, 0);
    return round(sum / values.length, precision);
  }
  return 0;
};

const getSum = (result, aggregation, precision) => {
  if (!isEmpty(result)) {
    const sum = result
      .filter(entry => entry.aggregation === aggregation)
      .map(entry => entry?.value || 0)
      .reduce((prev, curr) => prev + curr, 0);
    return round(sum, precision);
  }
  return 0;
};

const getError = state => state.values.recycling.opi.error;
const getResult = state => state.values.recycling.opi.data;
const getMeta = state => state.values.recycling.opi.meta;

const needsReloadSelector = createSelector(
  getPartnerNumber,
  getFunctionalLocationId,
  syntheticSensorsSelector,
  getMeta,
  (partnerNumber, functionalLocationId, syntheticSensors, meta) =>
    !isEmpty(syntheticSensors) &&
    (meta.functionalLocationId !== functionalLocationId || meta.partnerNumber !== partnerNumber)
);

const calculatedDataSelector = createSelector(
  syntheticSensorsSelector,
  getResult,
  (syntheticSensors, result) =>
    !isEmpty(syntheticSensors) &&
    result && {
      recyclingRate: getAverage(result, 'recyclingOpiRecyclingRate', PERCENTAGE_PRECISION),
      recyclingRateDelta: getAverage(result, 'recyclingOpiRecyclingRateDelta', PERCENTAGE_PRECISION),
      recoveryRate: getAverage(result, 'recyclingOpiRecoveryRate', PERCENTAGE_PRECISION),
      recoveryRateDelta: getAverage(result, 'recyclingOpiRecoveryRateDelta', PERCENTAGE_PRECISION),
      carbonFootprint: getSum(result, 'recyclingOpiCO2e', CO2E_TONNES_PRECISION),
      carbonFootprintDelta: getAverage(result, 'recyclingOpiCO2eDelta', PERCENTAGE_PRECISION),
      wasteAmount: getSum(result, 'recyclingOpiWasteAmount', TONNES_PRECISION),
    }
);

export const dataSelector = createSelector(
  getMeta,
  getError,
  needsReloadSelector,
  calculatedDataSelector,
  (meta, error, needsReload, calculatedData) => ({
    isLoading: meta.loading,
    error,
    needsReload,
    result: calculatedData || {},
  })
);

export const mapStateToProps = (state, props) => ({
  partnerNumber: getPartnerNumber(state, props),
  functionalLocationId: getFunctionalLocationId(state, props),
  syntheticSensors: syntheticSensorsSelector(state, props),
  data: dataSelector(state, props),
});

const mapDispatchToProps = {
  loadOpiData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
