import React from 'react';
import PropTypes from 'prop-types';

const AnnotationDataValidityContext = React.createContext({
  invalidationTime: null,
  setDataInvalid: () => {}, // eslint-disable-line no-empty-function
});

export const AnnotationDataValidityProvider = ({ children }) => {
  const [invalidationTime, setInvalidationTime] = React.useState(null);
  const setDataInvalid = React.useCallback(() => setInvalidationTime(Date.now()), []);

  return (
    <AnnotationDataValidityContext.Provider
      value={React.useMemo(
        () => ({
          invalidationTime,
          setDataInvalid,
        }),
        [invalidationTime, setDataInvalid]
      )}
    >
      {children}
    </AnnotationDataValidityContext.Provider>
  );
};

AnnotationDataValidityProvider.propTypes = {
  children: PropTypes.node,
};

export const useAnnotationDataValidity = () => React.useContext(AnnotationDataValidityContext);
