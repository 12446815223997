import React from 'react';
import isEqual from 'lodash/isEqual';
import { usePrevious } from 'utils/General/utils';
import { CHART_TYPES } from '../utils';

const EMPTY_ARRAY = [];

const useCustomChartKey = (chart = {}) => {
  const chartKey = React.useRef(1);

  const updateKey = key => key.current + 1;

  const series = chart.series ?? EMPTY_ARRAY;
  const type = chart.chartType ?? null;

  const prevChart = usePrevious(chart);
  const prevSeries = usePrevious(series);

  if (
    prevChart &&
    type === CHART_TYPES.DEFAULT &&
    (!isEqual(chart.aggregationFreq, prevChart.aggregationFreq) ||
      !isEqual(chart.series, prevChart.series) ||
      !isEqual(chart.verticalZoom, prevChart.verticalZoom) ||
      !isEqual(chart.chartType, prevChart.chartType))
  ) {
    chartKey.current = updateKey(chartKey);
  } else if (prevSeries && type === CHART_TYPES.SCATTER_PLOT && prevSeries.length === series.length) {
    // check if axes, timeFrame or aggregationFreq has changed in any of the series

    const dataLoadingPropertyChanged = series.some((seriesObj, index) => {
      const prevSeriesObj = prevSeries[index];
      if (
        !isEqual(prevSeriesObj.axes, seriesObj.axes) ||
        !isEqual(prevSeriesObj.timeFrame, seriesObj.timeFrame) ||
        prevSeriesObj.aggregationFreq !== seriesObj.aggregationFreq
      ) {
        return true;
      }
      return false;
    });

    if (dataLoadingPropertyChanged) {
      chartKey.current = updateKey(chartKey);
    }
  }

  return chartKey.current;
};

export default useCustomChartKey;
