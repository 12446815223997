import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_V2_PATH } from './index';

export const getAnalyticsFunctionLogsFn = (request, findOptions) =>
  request.post({
    apiPath: `${MASTER_DATA_V2_PATH}/analyticsFunctionLogs/find`,
    data: findOptions,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  getAnalyticsFunctionLogs: getAnalyticsFunctionLogsFn,
});
