import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const EventsRow = styled.tr`
  border-bottom: ${props => (props.gray ? 'var(--border-semibold)' : 'var(--border-thin)')} solid
    ${props => (props.gray ? props.theme.colors.white : props.theme.colors.alabaster)};
  font-family: ${props => props.theme.fontFamily.text};
  font-size: ${props => props.theme.fontSize.xs};

  background-color: ${props => props.gray && props.theme.colors.concrete};
  background-color: ${props => props.highlight && rgba(props.theme.colors.cerulean, 0.05)};

  ${props => props.theme.media.landscape`
        ${props =>
          (props.onClick || props.link) &&
          css`
            cursor: pointer;

            &:hover {
              background-color: ${props => props.theme.colors.alabaster};
            }
          `};
    `}
`;

export default EventsRow;
