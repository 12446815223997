import { startOfUTCDay } from './date';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';

const getServiceOrderStartDate = (serviceOrderStartDate, start, end) => {
  const startDate = serviceOrderStartDate && startOfUTCDay(parseISO(serviceOrderStartDate));

  if (startDate) {
    if (isAfter(startDate, end)) {
      return null;
    }
    if (isAfter(startDate, start)) {
      return startDate;
    }
  }

  return start;
};

export default getServiceOrderStartDate;
