import { createReducerFromMapping } from 'redux/utils/index';

const initialState = {
  loading: {},
  activeFunctionalLocationId: null,
  statisticsView: {
    visible: false,
    contentType: undefined,
    statisticsType: undefined,
  },
};

export const ADD_LOADING = 'CUSTOMER_PLATFORM/Common/ADD_LOADING';
export const addLoading = (key, component) => ({ type: ADD_LOADING, key, component });

export const REMOVE_LOADING = 'CUSTOMER_PLATFORM/Common/REMOVE_LOADING';
export const removeLoading = (key, component) => ({ type: REMOVE_LOADING, key, component });

export const SET_ACTIVE_FUNCTIONAL_LOCATION = 'CUSTOMER_PLATFORM/Common/SET_ACTIVE_FUNCTIONAL_LOCATION';
export const setActiveFunctionalLocationId = functionalLocationId => ({
  type: SET_ACTIVE_FUNCTIONAL_LOCATION,
  functionalLocationId,
});

export const SET_STATISTICS_VIEW = 'CUSTOMER_PLATFORM/Common/SET_STATISTICS_VIEW';
export const setStatisticsView = options => ({
  type: SET_STATISTICS_VIEW,
  options,
});

export default createReducerFromMapping(
  {
    [SET_ACTIVE_FUNCTIONAL_LOCATION]: (state, action) => ({
      ...state,
      activeFunctionalLocationId: action.functionalLocationId,
    }),
    [ADD_LOADING]: (state, action) => {
      return {
        ...state,
        loading: Array.isArray(state.loading[action.component])
          ? // Component array already exists in the loading state. Add key to the component's array.
            { ...state.loading, [action.component]: state.loading[action.component].concat(action.key) }
          : // Component array does not exist in the loading state. Create an array containing the key.
            { ...state.loading, [action.component]: [action.key] },
      };
    },
    [REMOVE_LOADING]: (state, action) => {
      return {
        ...state,
        loading: (index =>
          index > -1
            ? // Remove the *first* occurrence of the key, if it exists.
              {
                ...state.loading,
                [action.component]: state.loading[action.component]
                  .slice(0, index)
                  .concat(state.loading[action.component].slice(index + 1)),
              }
            : // Don't change the state.
              state.loading)(
          // Get the index of the *first* matching key to be removed in the component's loading array, if it exists.
          Array.isArray(state.loading[action.component]) ? state.loading[action.component].indexOf(action.key) : -1
        ),
      };
    },
    [SET_STATISTICS_VIEW]: (state, action) => ({
      ...state,
      statisticsView: {
        ...state.statisticsView,
        ...action.options,
      },
    }),
  },
  initialState
);
