import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';

export const invalidateAllPlannedMaintenanceCaches = () =>
  Promise.all([invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/planned-maintenances`)]);

export const plannedMaintenance = ({
  partnerNumber,
  functionalLocation,
  id,
  maintenancePlanNumber,
  equipmentNumber,
  startDate,
  endDate,
  refreshCache,
}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances`,
    query: { partnerNumber, functionalLocation, id, maintenancePlanNumber, equipmentNumber, startDate, endDate },
    refreshCache,
    useAuthorization: true,
  });

export const plannedMaintenancesForUser = (startDate, endDate, partnerNumber, functionalLocation, refreshCache) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/for-user`,
    query: {
      startDate,
      endDate,
      partnerNumber,
      functionalLocation,
    },
    refreshCache,
    useAuthorization: true,
  });

export const plannedMaintenanceForServiceOrder = serviceOrderNumber =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/service-orders/${serviceOrderNumber}/planned-maintenance`,
    useAuthorization: true,
  });

export const plannedMaintenancesExport = ({
  partnerNumber,
  functionalLocation,
  ids,
  equipmentNumber,
  startDate,
  endDate,
}) =>
  ApiRequest.download({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/export`,
    data: { partnerNumber, functionalLocation, id: ids, equipmentNumber, startDate, endDate },
    useAuthorization: true,
  });

export const plannedMaintenancesExportForUser = ({ startDate, endDate, partnerNumber, functionalLocation, ids }) =>
  ApiRequest.download({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/export/for-user`,
    data: {
      startDate,
      endDate,
      partnerNumber,
      functionalLocation,
      id: ids,
    },
    useAuthorization: true,
  });

export const plannedMaintenancesSearch = ({ keyword, partnerNumber, functionalLocation, limit, refreshCache }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/search`,
    query: { keyword, partnerNumber, functionalLocation, limit },
    refreshCache,
    useAuthorization: true,
  });

export const plannedMaintenancesCounts = ({ startDate, endDate, partnerNumber, functionalLocation }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/counts`,
    query: { startDate, endDate, partnerNumber, functionalLocation },
    useAuthorization: true,
  });

export const upsertPlannedMaintenance = maintenance =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances`,
    data: maintenance,
    useAuthorization: true,
  });

export const deletePlannedMaintenance = id =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/${id}`,
    useAuthorization: true,
  });

export const plannedMaintenanceServiceOrder = id =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/${id}/service-order`,
    useAuthorization: true,
  });

export const plannedMaintenanceLogs = (plannedMaintenanceId, refreshCache) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/${plannedMaintenanceId}/logs`,
    refreshCache,
    useAuthorization: true,
  });

export const cancelAssignmentForPlannedMaintenance = plannedMaintenanceId =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/planned-maintenances/${plannedMaintenanceId}/cancel-assignment`,
    useAuthorization: true,
  });
