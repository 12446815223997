import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import withNotification from 'decorators/Notification/withNotification';

import TechniciansView from './TechniciansView';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import Section from 'components/Section/Section';

import { connector } from './connectTechnician';
import { SubmitError } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/SubmitError';
import MasterDataService from 'services/masterData';

export const TechniciansViewContainer = ({
  t,
  token,
  serviceOrder,
  serviceOrderDocuments,
  loadServiceOrderWithToken,
  loadServiceOrderFilesWithToken,
  loadingServiceOrder,
  loadingDocuments,
  uploadServiceOrderFileWithToken,
  downloadServiceOrderFileWithToken,
  showNotification,
  loadingTranslations,
  language,
}) => {
  const [showRejectedMessage, setShowRejectedMessage] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      loadServiceOrderWithToken(token);
      loadServiceOrderFilesWithToken(token);
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitComment = React.useCallback(
    async ({ status, comment, createdBy }) => {
      try {
        await MasterDataService.updateServiceOrderStatusWithToken({ token, status, comment, createdBy, language });
        if (status === 'Draft') {
          setShowRejectedMessage(true);
        }

        showNotification({ type: 'success', message: t('Sent successfully'), autoHide: true });
        loadServiceOrderWithToken(token, true);
      } catch (error) {
        showNotification({ type: 'error', message: t('Sending failed'), autoHide: true });
      }
    },
    [token, language, loadServiceOrderWithToken, showNotification, t]
  );

  const handleDocumentUpload = React.useCallback(
    async files => {
      const results = await Promise.all(files.map(file => uploadServiceOrderFileWithToken(file, token)));
      const errors = results.map((action, index) => (action.error ? index : null)).filter(x => x !== null);

      if (errors.length > 0) {
        showNotification({
          type: 'error',
          message: t('{0} documents uploaded', `${files.length - errors.length} / ${files.length}`),
          autoHide: true,
        });

        throw new SubmitError({
          erroredFileIndices: errors,
        });
      }

      loadServiceOrderFilesWithToken(token);
      loadServiceOrderWithToken(token, true);

      showNotification({ type: 'success', message: t('Document successfully saved'), autoHide: true });
    },
    [
      uploadServiceOrderFileWithToken,
      loadServiceOrderFilesWithToken,
      loadServiceOrderWithToken,
      token,
      showNotification,
      t,
    ]
  );

  const handleDocumentDownload = React.useCallback(
    async file => {
      const result = await downloadServiceOrderFileWithToken(file, token);
      if (result?.error) {
        showNotification({ type: 'error', message: t('Downloading failed'), autoHide: true });
      }
    },
    [downloadServiceOrderFileWithToken, token, showNotification, t]
  );

  if (showRejectedMessage) {
    return (
      <Section>
        <p>{t('Sent successfully')}</p>
        <p>{t('Service order is not available anymore.')}</p>
      </Section>
    );
  }

  if (!token || serviceOrder.error) {
    return (
      <ErrorPageContent
        title={t(`Service order not found or you don't have the right permissions`)}
        instructions={t('Service order is deleted or reassigned, and is therefore not available anymore.')}
      />
    );
  }

  return (
    <TechniciansView
      serviceOrder={serviceOrder}
      onSubmitComment={handleSubmitComment}
      loading={loadingServiceOrder || loadingTranslations}
      documents={serviceOrderDocuments}
      loadingDocuments={loadingDocuments}
      onDocumentUpload={handleDocumentUpload}
      onDocumentDownload={handleDocumentDownload}
      showNotification={showNotification}
    />
  );
};

TechniciansViewContainer.propTypes = {
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  loadServiceOrderWithToken: PropTypes.func.isRequired,
  loadServiceOrderFilesWithToken: PropTypes.func.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  serviceOrderDocuments: PropTypes.array.isRequired,
  loadingServiceOrder: PropTypes.bool,
  loadingDocuments: PropTypes.bool,
  uploadServiceOrderFileWithToken: PropTypes.func.isRequired,
  downloadServiceOrderFileWithToken: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  loadingTranslations: PropTypes.bool,
  language: PropTypes.string,
};

export default connector(translations(withNotification(TechniciansViewContainer)));
