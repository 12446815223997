import groupBy from 'lodash/groupBy';
import forOwn from 'lodash/forOwn';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import MasterDataService from 'services/masterData';
import NewIoT from 'services/iot';
import { sensorDataAggregations } from 'utils/Data/values';

/**
 * Create filter for sensor values request
 *
 * @param {number[]} sensorIds list of sensor ids
 * @param {Date|string} startTime start time for values
 * @param {Date|string} endTime end time for values
 * @param {string} aggregation aggregation for values
 */
export const getFilterForSensorsValues = (sensorIds, startTime, endTime, aggregation) => ({
  sensorIds,
  timestamp: { ge: startTime, le: endTime },
  aggregation,
});

const timestampStringComparisonFn = ({ timestamp: a }, { timestamp: b }) => {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

export const initialState = {
  sensorValues: [],
  valuesBySensorId: {},
  modalValuesBySensorId: {},
  latestValuesBySensorId: {},
  modalLatestValuesBySensorId: {},
  sensorsByEquipmentNumber: {},
  booleanSensorIntervals: [],
  loadingSensorValues: false,
  loadingLatestSensorValues: false,
  loadingBooleanSensorIntervals: false,
  loadingArray: [],
};

export const LOAD_SENSOR_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES';
export const LOAD_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_SUCCESS';
export const LOAD_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSOR_VALUES_FAIL';

export const loadSensorValues = (sensorIds, startTime, endTime, aggregation) => {
  const filter = getFilterForSensorsValues(sensorIds, startTime, endTime, aggregation);

  return async dispatch => {
    dispatch({ type: LOAD_SENSOR_VALUES });
    try {
      const response = await IoTService.sensorValuesFind(filter);

      return dispatch({
        type: LOAD_SENSOR_VALUES_SUCCESS,
        result: response.data,
        sensorIds,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSOR_VALUES_FAIL,
        error,
        sensorIds,
      });
    }
  };
};

export const LOAD_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES';
export const LOAD_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_SUCCESS';
export const LOAD_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_SENSORS_VALUES_FAIL';

export const loadSensorsValues = (sensorIds, [startTime, endTime], aggregation, isSensorModal = false) => {
  const filter = getFilterForSensorsValues(sensorIds, startTime, endTime, aggregation);

  return async dispatch => {
    dispatch({ type: LOAD_SENSORS_VALUES });
    try {
      const response = await IoTService.sensorValuesFind(filter);

      return dispatch({
        type: LOAD_SENSORS_VALUES_SUCCESS,
        result: response.data,
        sensorIds,
        isSensorModal,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSORS_VALUES_FAIL,
        error,
        sensorIds,
        isSensorModal,
      });
    }
  };
};

export const getFilterForBucketedSensorValues = (sensorIds, [start, end], aggregation) => {
  const filter = {
    sensorIds,
    start,
    end,
  };
  if (aggregation) {
    filter.aggregation = aggregation;
    filter.bucket =
      sensorDataAggregations.find(aggregationType => aggregationType?.aggregations?.includes(aggregation))?.grouping ||
      'day';
  }
  return filter;
};

export const loadBucketedSensorValues = (sensorIds, [start, end], aggregation, isSensorModal = false) => {
  const filter = getFilterForBucketedSensorValues(sensorIds, [start, end], aggregation);

  return async dispatch => {
    dispatch({ type: LOAD_SENSORS_VALUES });
    try {
      const result = await dispatch(IoTService.findForPeriod(filter));

      return dispatch({
        type: LOAD_SENSORS_VALUES_SUCCESS,
        result,
        sensorIds,
        isSensorModal,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSORS_VALUES_FAIL,
        error,
        sensorIds,
        isSensorModal,
      });
    }
  };
};

export const loadPublicSensorValues = ({
  sensorIds,
  startDate,
  endDate,
  aggregation = 'raw',
  publicViewId,
  floorId,
}) => {
  let bucket;
  if (aggregation !== 'raw') {
    bucket =
      sensorDataAggregations.find(aggregationType => aggregationType?.aggregations?.includes(aggregation))?.grouping ||
      'day';
  }
  return async dispatch => {
    dispatch({ type: LOAD_SENSORS_VALUES });
    try {
      const result = await IoTService.sensorValuesWithPublicView({
        sensorIds,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        aggregation,
        bucket,
        publicViewId,
        floorId,
      });

      return dispatch({
        type: LOAD_SENSORS_VALUES_SUCCESS,
        result,
        sensorIds,
        isSensorModal: true,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SENSORS_VALUES_FAIL,
        error,
        sensorIds,
        isSensorModal: true,
      });
    }
  };
};

export const LOAD_BOOLEAN_SENSOR_INTERVALS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_BOOLEAN_SENSOR_INTERVALS';
export const LOAD_BOOLEAN_SENSOR_INTERVALS_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_BOOLEAN_SENSOR_INTERVALS_SUCCESS';
export const LOAD_BOOLEAN_SENSOR_INTERVALS_FAIL =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_BOOLEAN_SENSOR_INTERVALS_SUCCESS_FAIL';

export const loadBooleanSensorIntervals = (start, end, maxFrequency, aggregation, sensorIds) => {
  return async dispatch => {
    dispatch({ type: LOAD_BOOLEAN_SENSOR_INTERVALS });
    try {
      const result = await dispatch(NewIoT.findBooleanIntervals({ start, end, maxFrequency, aggregation, sensorIds }));
      return dispatch({
        type: LOAD_BOOLEAN_SENSOR_INTERVALS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BOOLEAN_SENSOR_INTERVALS_FAIL,
        error,
      });
    }
  };
};

export const LOAD_PUBLIC_LATEST_SENSORS_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_PUBLIC_LATEST_SENSORS_VALUES_SUCCESS';

export const LOAD_LATEST_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES';
export const LOAD_LATEST_SENSORS_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_SUCCESS';
export const LOAD_LATEST_SENSORS_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_SENSORS_VALUES_FAIL';

export const loadLatestSensorsValues =
  (sensorIds, aggregation = 'raw', isSensorModal = false) =>
  async dispatch => {
    dispatch({ type: LOAD_LATEST_SENSORS_VALUES });
    if (isEmpty(sensorIds)) {
      return dispatch({
        type: LOAD_LATEST_SENSORS_VALUES_FAIL,
        isSensorModal,
      });
    }
    try {
      const result = await IoTService.sensorsLatestValues(sensorIds, aggregation);

      return dispatch({
        type: LOAD_LATEST_SENSORS_VALUES_SUCCESS,
        result,
        isSensorModal,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_LATEST_SENSORS_VALUES_FAIL,
        error,
        isSensorModal,
      });
    }
  };

/**
 * Load latest values for sensors.
 *
 * Sensors can use different aggregations as latest values. This function checks the appropriate aggregation for each
 * sensor and loads the latest value using the correct aggregation for each sensor.
 */
export const loadLatestSensorValuesDetectAggregation =
  (sensors, isSensorModal = false) =>
  dispatch => {
    const groups = groupBy(sensors, sensor => sensor.sensorType?.latestValueAggregation?.aggregation || 'raw');
    return Promise.all(
      Object.entries(groups).map(([aggregation, sensors]) =>
        dispatch(
          loadLatestSensorsValues(
            sensors.map(sensor => sensor.id),
            aggregation,
            isSensorModal
          )
        )
      )
    );
  };

/**
 * Load latest values for aggregation groups (e.g. for building)
 * Multiple fetches, but dispatch LOAD_LATEST_SENSORS_VALUES and LOAD_LATEST_SENSORS_VALUES_SUCCESS only once.
 * Silent failures.
 */
export const loadLatestSensorValuesForAggregationGroups = aggregationGroups => async dispatch => {
  dispatch({ type: LOAD_LATEST_SENSORS_VALUES });
  const CHUNK_SIZE = 250;

  // filter and chunk aggregation groups
  const groupEntries = Object.entries(aggregationGroups).reduce((accu, [aggregation, sensors]) => {
    if (aggregation === 'undefined') {
      return accu;
    }
    if (sensors.length <= CHUNK_SIZE) {
      return accu.concat([[aggregation, sensors]]);
    }
    return accu.concat(chunk(sensors, CHUNK_SIZE).map(sensorsChunk => [aggregation, sensorsChunk]));
  }, []);

  const result = await Promise.allSettled(
    groupEntries.map(([aggregation, sensors]) =>
      IoTService.sensorsLatestValues(
        sensors.map(sensor => sensor.id),
        aggregation
      )
    )
  ).then(settled => settled.filter(({ status }) => status === 'fulfilled').flatMap(({ value }) => value));
  dispatch({
    type: LOAD_LATEST_SENSORS_VALUES_SUCCESS,
    result,
  });
};

export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES';
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS';
export const LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL =
  'CUSTOMER_PLATFORM/IoT_Values/LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL';

export const loadLatestEquipmentSensorsValues = (functionalLocation, equipmentNumber) => {
  const sensorFilter = {
    where: {
      functionalLocation,
      equipmentNumber,
    },
    include: [
      {
        children: [
          {
            relation: 'sensorMeta',
          },
          {
            relation: 'sensorType',
            scope: {
              include: [
                {
                  relation: 'aggregations',
                  scope: {
                    where: {
                      active: true,
                    },
                    fields: ['aggregation', 'frequency'],
                  },
                },
              ],
            },
          },
        ],
      },
    ],
  };

  return async dispatch => {
    dispatch({ type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES });
    try {
      const equipmentSensors = await dispatch(MasterDataService.sensors(sensorFilter));
      const sensorIds = equipmentSensors[0].children?.flatMap(child => child.id);
      const result = await IoTService.sensorsLatestValues(sensorIds);

      return dispatch({
        type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS,
        equipmentNumber,
        equipmentSensors: equipmentSensors[0].children,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_SENSOR_VALUES]: (state, action) => ({
      ...state,
    }),
    [LOAD_SENSOR_VALUES_SUCCESS]: (state, action) => {
      let newValues = action.sensorIds.reduce((accu, id) => {
        accu[id] = [];
        return accu;
      }, {});

      if (action.result && action.result.length > 0) {
        const result = forOwn(
          groupBy(action.result, value => value.sensorId),
          sensorValues => sensorValues.sort(timestampStringComparisonFn)
        );
        newValues = { ...newValues, ...result };
      }

      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        valuesBySensorId: Object.assign({}, state.valuesBySensorId, newValues),
      };
    },
    [LOAD_SENSOR_VALUES_FAIL]: (state, action) => {
      const sensorIds = Array.isArray(action.sensorIds)
        ? action.sensorIds.reduce((accu, id) => {
            accu[id] = [];
            return accu;
          }, {})
        : {};

      return {
        ...state,
        valuesBySensorId: {
          ...state.valuesBySensorId,
          ...sensorIds,
        },
        error: action.error,
      };
    },
    [LOAD_SENSORS_VALUES]: (state, action) => ({
      ...state,
      loadingSensorValues: true,
      loadingArray: [...state.loadingArray, true],
    }),
    [LOAD_SENSORS_VALUES_SUCCESS]: (state, action) => {
      let newValues = action.sensorIds.reduce((accu, id) => {
        accu[id] = [];
        return accu;
      }, {});

      if (action.result && action.result.length > 0) {
        const result = forOwn(
          groupBy(action.result, value => value.sensorId),
          sensorValues => sensorValues.sort(timestampStringComparisonFn)
        );
        newValues = { ...newValues, ...result };
      }

      if (action.isSensorModal) {
        return {
          ...state,
          modalValuesBySensorId: Object.assign({}, state.modalValuesBySensorId, newValues),
          loadingSensorValues: false,
          loadingArray: state.loadingArray.slice(1),
        };
      }

      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        valuesBySensorId: Object.assign({}, state.valuesBySensorId, newValues),
        loadingSensorValues: false,
        loadingArray: state.loadingArray.slice(1),
      };
    },
    [LOAD_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingSensorValues: false,
      loadingArray: state.loadingArray.slice(1),
    }),
    [LOAD_LATEST_SENSORS_VALUES]: state => ({
      ...state,
      loadingLatestSensorValues: true,
    }),
    [LOAD_LATEST_SENSORS_VALUES_SUCCESS]: (state, action) => {
      const newValues = keyBy(action.result, value => value.sensorId);
      if (action.isSensorModal) {
        return {
          ...state,
          modalLatestValuesBySensorId: { ...state.modalLatestValuesBySensorId, ...newValues },
          latestValuesBySensorId: { ...state.latestValuesBySensorId, ...newValues },
          loadingLatestSensorValues: false,
        };
      }
      return {
        ...state,
        sensorValues: [].concat(state.sensorValues, action.result),
        latestValuesBySensorId: { ...state.latestValuesBySensorId, ...newValues },
        loadingLatestSensorValues: false,
      };
    },
    [LOAD_LATEST_SENSORS_VALUES_FAIL]: state => ({
      ...state,
      loadingLatestSensorValues: false,
    }),
    [LOAD_PUBLIC_LATEST_SENSORS_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      modalLatestValuesBySensorId: action.result,
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES]: (state, action) => ({
      ...state,
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      sensorValues: [].concat(state.sensorValues, action.result),
      sensorsByEquipmentNumber: {
        ...state.sensorsByEquipmentNumber,
        [action.equipmentNumber]: action.equipmentSensors,
      },
      latestValuesBySensorId: keyBy(action.result, value => value.sensorId),
    }),
    [LOAD_LATEST_EQUIPMENT_SENSORS_VALUES_FAIL]: (state, action) => ({
      ...state,
    }),
    [LOAD_BOOLEAN_SENSOR_INTERVALS]: state => ({
      ...state,
      loadingBooleanSensorIntervals: true,
    }),
    [LOAD_BOOLEAN_SENSOR_INTERVALS_SUCCESS]: (state, action) => ({
      ...state,
      booleanSensorIntervals: action.result,
      loadingBooleanSensorIntervals: false,
    }),
    [LOAD_BOOLEAN_SENSOR_INTERVALS_FAIL]: state => ({
      ...state,
      loadingBooleanSensorIntervals: false,
    }),
  },
  initialState
);
