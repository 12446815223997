import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import SubformComponent from './SubformComponent';

import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import ChecklistTemplatePreview from './ChecklistTemplatePreview';

export const createSchema = t =>
  yup.object().shape({
    checklistTemplateId: yup.number().nullable().label(t('Checklist')),
  });

const ChecklistForm = ({ t, model, onFormChange, checklistTemplates, disabled, validationErrors }) => {
  const checklistTemplateOptions = React.useMemo(
    () =>
      sortBy(
        checklistTemplates.map(template => ({ label: template.title, value: template.id })),
        ['label']
      ),
    [checklistTemplates]
  );
  return (
    <InputForm
      onPropertyChange={onFormChange}
      model={model}
      FormComponent={SubformComponent}
      validationErrors={validationErrors}
    >
      <InputRow dense fullRow>
        <InputLabel text={t('Checklist')} t={t} ctxHelp={`${CTXHELP_PREFIX} Checklist`} ctxHelpPosition="left" />
        <InputSelectDropdown
          property="checklistTemplateId"
          options={checklistTemplateOptions}
          t={t}
          placeholder={`${t('Select or leave empty')}...`}
          disabled={disabled}
          resetValue={null}
        />
        {!!model?.checklistTemplateId && (
          <ChecklistTemplatePreview
            template={checklistTemplates.find(template => template.id === model.checklistTemplateId)}
          />
        )}
      </InputRow>
    </InputForm>
  );
};

ChecklistForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  checklistTemplates: PropTypes.array,
  disabled: PropTypes.bool,
  validationErrors: PropTypes.object.isRequired,
};

export default ChecklistForm;
