import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Loading from 'components/Loading/Loading';
import { getWelcomeUrl } from 'utils/Data/language';

const LogoutSession = () => {
  let welcomeUrl = getWelcomeUrl();
  const { instance } = useMsal();
  useEffect(() => {
    instance.logoutRedirect({ postLogoutRedirectUri: welcomeUrl });
  }, []);

  return <Loading showLogo={false} />;
};

export default LogoutSession;
