import React, { ReactNode } from 'react';
import { useReactTable, TableOptions, Table, getCoreRowModel, PartialKeys } from '@tanstack/react-table';
import { CellContext } from '@tanstack/table-core/build/lib/core/cell';
import { RowData } from '@tanstack/table-core/build/lib/types';

import { TableLabelWrapper } from './GenericTable';

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    /**
     * How table width should be handled.
     * - `auto` – Don't take explicit column widths, calculated by react-table,
     *   into account. The table width is controlled by the browser.
     * - `full` – Force table width to 100 %. Browser will then render columns
     *   stretched proportionally to make the table full width. This does not
     *   work well with column resizing, and the result may surprise. On the
     *   other hand, columns will fill the table in a consistent manner.
     * - `fill` – Add a filler column that makes the width at least 100%.
     *   This works well with column resizing. If the width is more than the
     *   container width, the filler column will have zero width. If full-width
     *   table is required, this is the recommended option.
     */
    width?: 'auto' | 'full' | 'fill';
  }
}

/**
 * Low-level hook that uses `useTable` from `react-table` with the following defaults:
 * - Enable column visibility toggling
 * - Wrap cells to {@link TableLabelWrapper} by default (no cell provided)
 *
 * @param tableOptions Table options. For automatic width for all columns and the table, set `meta.width = "auto"`.
 *   For full-width table, set `meta.width = "full"`.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 */
export function useTable<TData extends RowData>(
  tableOptions: PartialKeys<TableOptions<TData>, 'getCoreRowModel'>
): Table<TData> {
  const [columnVisibility, setColumnVisibility] = React.useState({});

  return useReactTable({
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,

    ...tableOptions,

    defaultColumn: {
      // Wrap value for default formatting
      cell: ({ getValue }: CellContext<TData, unknown>) => (
        <TableLabelWrapper>{getValue() as ReactNode}</TableLabelWrapper>
      ),
      ...tableOptions.defaultColumn,
    },

    state: {
      columnVisibility,
      ...tableOptions.state,
    },
  });
}
