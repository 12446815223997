import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputText from 'components/Form/InputText';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import SubformComponent from './SubformComponent';

import { InputType } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/constants';
import {
  getSupplierOptions,
  getPhoneNumberPlaceholder,
} from 'containers/Application/ServiceModule/ServiceModuleForm/utils/options';

const SendingMethod = styled.div`
  display: block;
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: 2;
  ${props => (props.denser ? 'margin-bottom: calc(-1 * var(--size-xs));' : '')};
`;

const Required = styled.span`
  font-weight: ${props => props.theme.font.weight.normal};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.radicalRed};
`;

const CheckboxWrapper = styled.div`
  ${props =>
    props.useLabelStyles &&
    css`
      & label {
        line-height: 2.5;
        color: ${props => props.theme.colors.darkGray};
        font-size: ${props => props.theme.font.size.xxs};
        font-weight: ${props => props.theme.font.weight.bold};
      }
    `}
`;

export const createSchema = t =>
  yup.object().shape({
    supplierInput: yup.string().nullable().oneOf([InputType.DROPDOWN, InputType.TEXT, InputType.EMPTY]),
    sendSMSInput: yup.boolean().nullable(),
    sendEmailInput: yup.boolean().nullable(),
    supplierId: yup
      .string()
      .nullable()
      .label(t('Supplier'))
      .when('supplierInput', (supplierInput, schema) =>
        supplierInput === InputType.DROPDOWN ? schema.required() : schema
      ),
    supplierName: yup
      .string()
      .nullable()
      .label(t('Supplier'))
      .when('supplierInput', (supplierInput, schema) =>
        supplierInput === InputType.TEXT ? schema.required() : schema
      ),
    supplierPhoneNumber: yup
      .string()
      .nullable()
      .label(t('Supplier phone'))
      .when(['sendSMSInput', 'supplierInput'], (sendSMSInput, supplierInput, schema) =>
        sendSMSInput && supplierInput !== InputType.EMPTY ? schema.required().matches(/^[+][0-9 ]*$/) : schema
      ),
    supplierEmail: yup
      .string()
      .nullable()
      .label(t('Supplier email'))
      .when(['sendEmailInput', 'supplierInput'], (sendEmailInput, supplierInput, schema) =>
        sendEmailInput && supplierInput !== InputType.EMPTY ? schema.required().email() : schema
      ),
  });

const SupplierForm = ({ t, model, onFormChange, suppliers, id, disableSupplierFields, division, validationErrors }) => (
  <InputForm
    onPropertyChange={onFormChange}
    model={model}
    FormComponent={SubformComponent}
    validationErrors={validationErrors}
  >
    <InputRow dense fullRow>
      <RadioButtonGroup row id={id} name="supplierInput" value={model.supplierInput} onChange={onFormChange}>
        <RadioButton label={t('Select from list')} value={InputType.DROPDOWN} disabled={disableSupplierFields} />
        <RadioButton label={t('Write supplier')} value={InputType.TEXT} disabled={disableSupplierFields} />
        <RadioButton label={t('Same as contact person')} value={InputType.EMPTY} disabled={disableSupplierFields} />
      </RadioButtonGroup>
    </InputRow>
    {model.supplierInput !== InputType.EMPTY && (
      <InputRow dense fullRow>
        {model.supplierInput === InputType.DROPDOWN && (
          <InputSelectDropdown
            t={t}
            property="supplierId"
            options={getSupplierOptions(suppliers)}
            disabled={disableSupplierFields}
            required
            clearable={false}
          />
        )}
        {model.supplierInput === InputType.TEXT && (
          <InputText
            property="supplierName"
            placeholder={`${t('Supplier')} *`}
            disabled={disableSupplierFields}
            autoComplete="section-supplier name"
            required
          />
        )}
      </InputRow>
    )}
    {!disableSupplierFields && (
      <>
        <InputRow fullRow dense>
          <SendingMethod denser={model.supplierInput !== InputType.EMPTY}>
            {t('Select a sending method')} <Required>*</Required>
          </SendingMethod>
        </InputRow>
        {model.supplierInput !== InputType.EMPTY && <div />}
      </>
    )}
    <InputRow dense>
      {disableSupplierFields && model.supplierInput !== InputType.EMPTY && (
        <InputLabel text={t('Supplier phone')} extraText={t('use country code')} required />
      )}
      {!disableSupplierFields && (
        <CheckboxWrapper useLabelStyles={model.supplierInput !== InputType.EMPTY}>
          <InputBooleanCheckbox
            id={`${id}_supplierPhoneNumberCheckbox`}
            property="sendSMSInput"
            label={t('SMS to phone number')}
          />
        </CheckboxWrapper>
      )}
      {model.supplierInput !== InputType.EMPTY && (
        <InputText
          property="supplierPhoneNumber"
          placeholder={getPhoneNumberPlaceholder(division)}
          disabled={disableSupplierFields || !model.sendSMSInput}
          autoComplete="section-supplier tel"
          required={model.sendSMSInput}
          type="tel"
          inputProps={{ pattern: '^[+][0-9 ]*$' }}
        />
      )}
    </InputRow>
    <InputRow dense>
      {disableSupplierFields && model.supplierInput !== InputType.EMPTY && (
        <InputLabel text={t('Supplier email')} required />
      )}
      {!disableSupplierFields && (
        <CheckboxWrapper useLabelStyles={model.supplierInput !== InputType.EMPTY}>
          <InputBooleanCheckbox
            id={`${id}_supplierEmailCheckbox`}
            property="sendEmailInput"
            label={t('Mail to email address')}
          />
        </CheckboxWrapper>
      )}
      {model.supplierInput !== InputType.EMPTY && (
        <InputText
          property="supplierEmail"
          placeholder={t('Email address')}
          disabled={disableSupplierFields || !model.sendEmailInput}
          autoComplete="section-supplier email"
          required={model.sendEmailInput}
        />
      )}
    </InputRow>
  </InputForm>
);

SupplierForm.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  disableSupplierFields: PropTypes.bool,
  division: PropTypes.string.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default SupplierForm;
