import { downloadBlob } from 'utils/Fetch/utils';

/**
 * Initiate file download with given name and mime type of 'text/csv'.
 * Prepend the content with a UTF-8 byte order mark (BOM).
 */
export function downloadCsvFile(filename: string, content: BlobPart) {
  const utf8BomContentArray = [
    // UTF-8 BOM is mandatory for Excel to open file correctly
    new Uint8Array([0xef, 0xbb, 0xbf]),
    content,
  ];
  const file = new File(utf8BomContentArray, filename, { type: 'text/csv' });
  downloadBlob(file);
}
