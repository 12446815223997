import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import InputText from 'components/Form/InputText';

const InputTextWithState = props => {
  const { value, model, property, onChange, onChangeDelay = 300, ...passThroughProps } = props;
  const externalValue = value ?? model[property];
  const [internalValue, setInternalValue] = useState(externalValue);
  const debouncedOnChange = useMemo(() => debounce(onChange, onChangeDelay), [onChange, onChangeDelay]);
  const internalOnChange = (property, value) => {
    setInternalValue(value);
    debouncedOnChange(property, value);
  };
  React.useEffect(() => {
    if (externalValue !== internalValue) {
      setInternalValue(externalValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue]);
  return <InputText {...passThroughProps} property={property} value={internalValue} onChange={internalOnChange} />;
};

InputTextWithState.propTypes = {
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  model: PropTypes.object,
  onChange: PropTypes.func,
  onChangeDelay: PropTypes.number,
};

export default InputTextWithState;
