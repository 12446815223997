import { useEffect, useImperativeHandle, useCallback } from 'react';

const getRowAndChildren = row => {
  if (row.children) {
    return [...row.children, row];
  }
  return row;
};

const getManuallySelectedRows = (row, rowSelectionData) => {
  const flattened = rowSelectionData.flatMap(getRowAndChildren);
  let selectedData = flattened.filter(dataRow => !!dataRow.selected);
  if (selectedData.some(selected => selected.id === row?.original.id)) {
    selectedData = selectedData.filter(selected => selected.id !== row?.original.id);
  } else {
    selectedData.push(row?.original);
  }
  return selectedData;
};

const useSelectableRows = options => {
  const { disableRowSelection, manualRowSelection, tableInstance, onSelectedChange, tableRef, data, originalData } =
    options;
  const rowSelectionEnabled = !disableRowSelection && typeof onSelectedChange === 'function';

  useEffect(() => {
    if (rowSelectionEnabled && !manualRowSelection) {
      const { selectedRowIds } = tableInstance.state;
      onSelectedChange(
        tableInstance.selectedFlatRows.map(tableRow => tableRow.original),
        selectedRowIds
      );
    }
  }, [tableInstance.selectedFlatRows, onSelectedChange]);

  const handleFilterChange = () => {
    if (rowSelectionEnabled && data?.length) {
      const rows = Array.isArray(tableInstance.flatRows) ? tableInstance.flatRows : tableInstance.rows;
      let selectedRows = [];
      selectedRows = rows.filter(row => !!row?.original?.selected);
      tableInstance.toggleAllRowsSelected(false);
      selectedRows.forEach(row => {
        tableInstance.toggleRowSelected(row.id, true);
      });
    }
  };

  const manualRowSelectionData = manualRowSelection && (originalData || data);

  const handleRowSelection = useCallback(
    row => {
      if (!rowSelectionEnabled) {
        return;
      }
      if (manualRowSelection) {
        // No react-table row toggling since toggle state is manually controlled by the row original data
        onSelectedChange(getManuallySelectedRows(row, manualRowSelectionData));
      } else {
        row.toggleRowSelected();
      }
    },
    [manualRowSelectionData, onSelectedChange, rowSelectionEnabled, manualRowSelection]
  );

  useImperativeHandle(tableRef, () => ({
    clearSelection() {
      if (onSelectedChange) {
        tableInstance.toggleAllRowsSelected(false);
      }
    },
    onFilterChange() {
      handleFilterChange();
    },
  }));

  return {
    handleRowSelection: rowSelectionEnabled ? handleRowSelection : undefined,
  };
};

export default useSelectableRows;
