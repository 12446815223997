import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const loadUserCustomCharts = () =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/custom-charts/for-user`,
    noCache: true,
    useAuthorization: true,
  });

export const loadBuildingCustomCharts = ({ functionalLocation }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/custom-charts/for-building`,
    query: { functionalLocation },
    noCache: true,
    useAuthorization: true,
  });

export const loadFeatureCustomCharts = ({ feature, functionalLocation }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/custom-charts/for-feature`,
    query: { feature, functionalLocation },
    noCache: true,
    useAuthorization: true,
  });

export const createCustomChart = data =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/custom-charts`,
    data,
    useAuthorization: true,
  });

export const updateCustomChart = (id, data) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/custom-charts/${id}`,
    data,
    useAuthorization: true,
  });

export const deleteCustomChart = id =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/custom-charts/${id}`,
    useAuthorization: true,
  });
