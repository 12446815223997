import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useTranslations } from 'decorators/Translations/translations';
import { functionalLocationName } from 'utils/Data/functionalLocations';

import ObservationsMonthSelector from './ObservationsMonthSelector';
import RadioButtonGroup from '../Form/RadioButtonGroup';
import RadioButton from '../Form/RadioButton';
import Filters from 'components/Filters/FiltersV2';
import Filter from 'components/Filters/Filter';
import FilterTitle from 'components/Filters/FilterTitle';
import FilterCollapse from 'components/Filters/FilterCollapse';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import { horizontallyScrollableMobile } from 'components/HorizontalScroll/HorizontalScroll';
import { MonthSelector } from 'components/Calendar/CalendarMonthSelector';
import Content from 'components/Content/Content';
import useObservationsFilters from './useObservationsFilters';
import { ObservationsSelectFilter } from './ObservationsSelectFilter';
import { DATE_FILTER_FIELD, OBSERVATION_TABS, SUBSTATUS_FILTER_FIELD, DISCIPLINE_FILTER_FIELD } from './utils';
import { getUniqueSubStatuses } from 'utils/Data/notice';

const StyledMonthSelector = styled(ObservationsMonthSelector)`
  ${horizontallyScrollableMobile}
  ${MonthSelector}:after {
    max-width: ${props => props.theme.layoutSpacing.sm};
  }
`;

const excludedFilters = [
  'page',
  'year',
  'month',
  'investmentSumField',
  'showOther',
  'observationsModalVisible',
  'dateFilterField',
  'selectedModalTab',
];

const FilterWrapper = styled.div`
  min-width: 10rem;
  ${props => props.theme.media.desktop`
    min-width: 0;
  `};
`;

const getFunctionalLocationOptions = (t, observations, functionalLocations) => {
  if (observations?.length > 0 && !isEmpty(functionalLocations)) {
    // get unique fls from observations and map to name / value pairs with fl data
    const fls = [...new Set(observations.map(observation => observation.functionalLocation))];
    return fls.map(fl => ({ label: functionalLocationName(t, functionalLocations[fl]) || fl, value: fl }));
  }
  return [];
};

const getSubStatusOptions = (t, observations) => {
  if (observations?.length > 0) {
    const subStatuses = getUniqueSubStatuses(observations);
    return [
      ...subStatuses.map(subStatus => ({
        label: t(subStatus),
        value: encodeURIComponent(subStatus),
      })),
      {
        label: t('Undefined'),
        value: SUBSTATUS_FILTER_FIELD.UNDEFINED,
      },
    ];
  }
  return [];
};

export const ObservationsFilter = ({
  observations = [],
  filteredObservations = [],
  loading,
  functionalLocations,
  impactDisabled,
  defaultFilterValues,
  selectedTab,
}) => {
  const [t] = useTranslations();
  const breakpoints = useBreakpoints();
  const { filters, onChangeFilter } = useObservationsFilters();

  const handleMultipleFilterChanges = changes => {
    for (const filterName in changes) {
      onChangeFilter(filterName, changes[filterName]);
    }
  };

  const showMonthSelector = selectedTab === OBSERVATION_TABS.OBSERVATIONS;
  const showInvestmentContentOptions = selectedTab === OBSERVATION_TABS.INVESTMENTS;
  const functionalLocationOptions = React.useMemo(
    () => getFunctionalLocationOptions(t, observations, functionalLocations),
    [t, observations, functionalLocations]
  );
  const subStatusOptions = React.useMemo(() => getSubStatusOptions(t, observations), [t, observations]);

  const sortFilterOptionsInPlace = options => {
    options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  };

  const observationFilters = React.useMemo(() => {
    const availableFilters = [
      {
        property: 'investmentSumField',
        label: t('View'),
        isMulti: false,
        hidden: !showInvestmentContentOptions,
        radioButton: true,
        options: [
          { value: 'savingsCost', label: t('Investment, KEUR') },
          { value: 'savingsPotentialSum', label: t('Energy Impact, MWh total') },
          { value: 'savingsPotentialHeat', label: t('Energy Impact, MWh heating') },
          { value: 'savingsPotentialElectricity', label: t('Energy Impact, MWh electricity') },
        ],
      },
      {
        property: 'status',
        label: t('Status'),
        isMulti: true,
        options: [
          {
            label: t('Open'),
            value: 'open',
          },
          {
            label: t('In progress'),
            value: 'inProgress',
          },
          {
            label: t('Completed'),
            value: 'completed',
          },
        ],
      },
      {
        property: 'subStatus',
        label: t('Substatus'),
        isMulti: true,
        hidden: !subStatusOptions?.length,
        options: subStatusOptions,
      },
      {
        property: 'dateFilterField',
        label: t('Date'),
        isMulti: false,
        radioButton: true,
        options: [
          { value: DATE_FILTER_FIELD.CREATED, label: t('Created') },
          { value: DATE_FILTER_FIELD.MODIFIED, label: t('Modified') },
        ],
      },
      {
        property: 'discipline',
        label: t('Discipline'),
        isMulti: true,
        options: [
          {
            label: t('Cooling'),
            value: 'cooling',
          },
          {
            label: t('Heating'),
            value: 'heating',
          },
          {
            label: t('Ventilation'),
            value: 'ventilation',
          },
          {
            label: t('Electric'),
            value: 'electric',
          },
          {
            label: t('Energy consumption'),
            value: 'energyConsumption',
          },
          {
            label: t('Alarms'),
            value: 'alarms',
          },
          {
            label: t('Other'),
            value: DISCIPLINE_FILTER_FIELD.OTHER,
          },
        ],
      },
      {
        property: 'impact',
        label: t('Impact'),
        isMulti: true,
        disabled: impactDisabled,
        options: [
          { label: t('Energy impact'), value: 'energy' },
          { label: t('Recycle impact'), value: 'recycle' },
        ],
      },
      {
        property: 'change',
        label: t('Change'),
        isMulti: true,
        options: [
          {
            label: t('Hide observations without improvement'),
            value: 'hideWithoutImprovement',
          },
        ],
      },
      {
        property: 'createdBy',
        label: t('Created by'),
        isMulti: true,
        options: [
          {
            label: t('Caverion Expert'),
            value: 'caverionExpert',
          },
          {
            label: t('Analytics'),
            value: 'analytics',
          },
        ],
      },
      {
        property: 'functionalLocation',
        label: t('Location'),
        isMulti: true,
        hidden: functionalLocationOptions.length < 2,
        options: functionalLocationOptions,
      },
    ];
    for (const filter of availableFilters) {
      sortFilterOptionsInPlace(filter.options);
    }
    return availableFilters;
  }, [functionalLocationOptions, impactDisabled, showInvestmentContentOptions, subStatusOptions, t]);

  return (
    <>
      <Content>
        <FilterTitle
          defaultSelectedFilters={defaultFilterValues}
          setValidationModel={() => {}}
          excludedFilters={excludedFilters}
        />
        <FilterCollapse onlyInMobile={true} initialIsCollapsed={!breakpoints.tablet}>
          <Filters>
            {observationFilters.map(filter => {
              if (filter.hidden) {
                return null;
              }
              if (filter.radioButton) {
                return (
                  <Filter
                    key={filter.property}
                    label={filter.label}
                    currentValue={
                      filter.options.find(option => option.value === filters[filter.property])?.label ||
                      filter.options[0].label
                    }
                  >
                    <FilterWrapper>
                      <RadioButtonGroup
                        name={filter.property}
                        value={filters[filter.property] || filter.options[0].value}
                        onChange={onChangeFilter}
                      >
                        {filter.options?.map(entry => (
                          <RadioButton key={entry.label} label={entry.label} value={entry.value} />
                        ))}
                      </RadioButtonGroup>
                    </FilterWrapper>
                  </Filter>
                );
              }
              return (
                <ObservationsSelectFilter
                  key={`filter-${filter.property}`}
                  label={filter.label}
                  onChange={onChangeFilter}
                  property={filter.property}
                  model={filters}
                  isMulti={filter.isMulti}
                  options={filter.options}
                  disabled={filter.disabled}
                />
              );
            })}
          </Filters>
        </FilterCollapse>
      </Content>
      {showMonthSelector && (
        <StyledMonthSelector
          items={filteredObservations}
          onMonthClick={handleMultipleFilterChanges}
          dateField={filters.dateFilterField || DATE_FILTER_FIELD.CREATED}
          loading={loading}
        />
      )}
    </>
  );
};

ObservationsFilter.propTypes = {
  observations: PropTypes.array,
  functionalLocations: PropTypes.object,
  loading: PropTypes.bool,
  filteredObservations: PropTypes.array,
  impactDisabled: PropTypes.bool,
  defaultFilterValues: PropTypes.object,
  selectedTab: PropTypes.string,
};

export default ObservationsFilter;
