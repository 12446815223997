import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import sum from 'lodash/sum';
import keys from 'lodash/keys';
import isNil from 'lodash/isNil';

import { loadAlarmsContainer, loadMoreAlarms } from 'redux/modules/containers/remote-center';
import { percentage } from 'utils/math';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { filterFLsBySelectedPartner } from 'containers/Application/Energy/EnergyUtil';
import DialogModal from 'components/Dialog/DialogModal';
import EventSummary from 'components/EventSummary/EventSummary';
import Benchmarking from './Benchmarking';
import ChartSection from './ChartSection';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import { getAlarmCountSeries } from './utils';
import { functionalLocationName } from 'utils/Data/functionalLocations';
import { useTranslations } from 'decorators/Translations/translations';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';

const EMPTY_ARRAY = [];
const getMostAlarms = (t, benchmarking, functionalLocations, partnerNumber) => {
  if (!benchmarking || !functionalLocations) {
    return EMPTY_ARRAY;
  }

  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/Alarms`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const count = benchmarking[fl.functionalLocation] || 0;
    return { title: functionalLocationName(t, fl), subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

const currentYear = new Date().getFullYear();

const TABS = {
  ALARM_COUNTS: 'countsPerMonth',
  HANDLING_TIME: 'handlingTime',
  TIME_TO_ACTION: 'timeToAction',
};

const Alarms = props => {
  const {
    partnerNumber,
    loadData,
    loadMore,
    opis: { performance, counts, handlingTimeOpi, timeToActionOpi },
    benchmarking,
    functionalLocations,
    handlingTime,
    timeToAction,
    countsPerMonth,
  } = props;

  const [t] = useTranslations();
  const [showActions, setShowActions] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TABS.ALARM_COUNTS);
  const [year, setYear] = useState(currentYear);
  const [scrollTo, setScrollTo] = useState(false);

  useEffect(() => {
    loadData(partnerNumber);
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  const flList = values(filterFLsBySelectedPartner(functionalLocations, partnerNumber)) || EMPTY_ARRAY;
  const mostAlarms = getMostAlarms(t, benchmarking, flList, partnerNumber);

  let chartData;
  switch (selectedTab) {
    case TABS.HANDLING_TIME:
      chartData = handlingTime;
      break;
    case TABS.TIME_TO_ACTION:
      chartData = timeToAction;
      break;
    case TABS.ALARM_COUNTS:
      chartData = countsPerMonth;
      break;
    default:
      chartData = {};
  }

  const chartDataForYear = chartData?.[year];
  const loadingChartData = !chartDataForYear || chartDataForYear.loading;

  let series;
  if (selectedTab === TABS.ALARM_COUNTS) {
    series = getAlarmCountSeries(t, chartDataForYear?.data);
  } else {
    series = chartDataForYear?.data?.map(data => ({ ...data, sensorName: 'no temperature' })) || [];
  }

  const changeYear = newYear => {
    setYear(newYear);
    loadMore(partnerNumber, newYear, { [selectedTab]: true });
  };

  const changeTab = newTab => {
    setSelectedTab(newTab);
    loadMore(partnerNumber, year, { [newTab]: true });
  };

  const scrollToElement = tab => {
    selectedTab !== tab && changeTab(tab);
    setScrollTo(true);
    setTimeout(() => setScrollTo(false), 500);
  };

  return (
    <Fragment>
      <OPICards>
        <OPICard
          title={t('Alarms')}
          subtitle={`${performance.handled ? performance.handled : '-'}\u00A0/\u00A0${
            performance.total ? performance.total : '-'
          }\u00A0${t('Handled')}\n(${t('Last 365 Days')})`}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Alarms OPI`,
          }}
          contentWithCircle={{
            value: performance.value,
          }}
          onClick={() => scrollToElement(TABS.ALARM_COUNTS)}
          loading={performance.loading}
        />
        <OPICard
          title={t('Actions to Alarms')}
          subtitle={t('Last 365 Days')}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Action breakdown`,
          }}
          content={{
            value: counts.total,
          }}
          clickEventType="openModal"
          onClick={() => setShowActions(true)}
          loading={counts.loading}
        />
        <OPICard
          title={t('Alarm handling time')}
          subtitle={t('Last 365 days average')}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Alarm handling time`,
          }}
          content={{
            value: handlingTimeOpi.mean,
            unit: t('min'),
            isPositive: handlingTimeOpi.positive,
          }}
          onClick={() => scrollToElement(TABS.HANDLING_TIME)}
          loading={handlingTimeOpi.loading}
        />
        <OPICard
          title={t('Time to action')}
          subtitle={t('Last 365 days average')}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Time to action`,
          }}
          content={{
            value: timeToActionOpi.mean,
            unit: t('min'),
            isPositive: timeToActionOpi.positive,
          }}
          onClick={() => scrollToElement(TABS.TIME_TO_ACTION)}
          loading={timeToActionOpi.loading}
        />
      </OPICards>
      <Benchmarking
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Observations Benchmarking`}
        leftTitle="Most alarms"
        rightTitle="Least alarms"
        leftData={mostAlarms}
        rightData={mostAlarms.slice().reverse()}
        infoText="Last 365 Days"
        loading={!benchmarking || benchmarking.loading}
      />
      {scrollTo && <ScrollToComponent />}
      <ChartSection
        year={year}
        changeYear={changeYear}
        series={series}
        loading={loadingChartData}
        tabOptions={[
          { label: t('Alarms'), value: TABS.ALARM_COUNTS },
          { label: t('Average alarm handling time'), value: TABS.HANDLING_TIME },
          { label: t('Average time to action'), value: TABS.TIME_TO_ACTION },
        ]}
        selectedTab={selectedTab}
        onTabChange={(property, value) => changeTab(value)}
        lineChart={selectedTab !== TABS.ALARM_COUNTS}
        stacked={false}
        hideTooltipTotal
      />
      {showActions && (
        <DialogModal isActive onOverlayClick={() => setShowActions(false)} t={t}>
          <EventSummary
            breakdownHeading={t('Action breakdown')}
            breakdownItems={counts ? orderBy(counts.byAction, 'value', 'desc') : {}}
            t={t}
          />
        </DialogModal>
      )}
    </Fragment>
  );
};

Alarms.propTypes = {
  partnerNumber: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  opis: PropTypes.object.isRequired,
  benchmarking: PropTypes.object,
  functionalLocations: PropTypes.object.isRequired,
  handlingTime: PropTypes.object,
  timeToAction: PropTypes.object,
  countsPerMonth: PropTypes.object,
};

const getPerformanceOPI = createSelector(
  (state, props) => state.alarm.byPartner[props.partnerNumber],
  state => state.alarm.byPartner.loading,
  (alarms, loading) => {
    const total = alarms ? alarms.total : null;
    const handled = alarms ? alarms.handled : null;
    return {
      loading,
      total,
      handled,
      value: alarms ? percentage(handled, total) : undefined,
    };
  }
);

const meanCombiner = (kpi, loading, limit) => {
  const mean = kpi && !isNil(kpi.mean) ? kpi.mean : '-';
  return {
    loading,
    mean,
    positiveValue: mean < limit,
  };
};

const getHandlingTimeOPI = createSelector(
  (state, props) => state.alarm.handlingTimeKPI[props.partnerNumber],
  state => state.alarm.handlingTimeKPI.loading,
  () => 60,
  meanCombiner
);

const getTimeToActionOPI = createSelector(
  (state, props) => state.alarm.timeToActionKPI[props.partnerNumber],
  state => state.alarm.timeToActionKPI.loading,
  () => 180,
  meanCombiner
);

const getActionCountOPI = createSelector(
  (state, props) => state.alarm.actionCounts[props.partnerNumber],
  (state, props) =>
    !state.alarm.actionCounts[props.partnerNumber] || state.alarm.actionCounts[props.partnerNumber].loading,
  (data, loading) => {
    const counts = data && data.data;
    const total = counts ? sum(values(counts)) : null;
    const byAction =
      counts &&
      keys(counts).map(count => {
        const parts = count.split('_');
        const activeKeyMax = parts[0] === 'FI' ? 10 : 8;
        const group = +parts.pop() <= activeKeyMax ? count : 'unknown';
        return { title: `alarmActionGroup_${group}`, value: counts[count] };
      });
    return {
      loading,
      total,
      byAction,
    };
  }
);

const getOPIs = createStructuredSelector({
  performance: getPerformanceOPI,
  counts: getActionCountOPI,
  handlingTimeOpi: getHandlingTimeOPI,
  timeToActionOpi: getTimeToActionOPI,
});

const mapStateToProps = (state, props) => ({
  opis: getOPIs(state, props),
  benchmarking: state.alarm.benchmarking[props.partnerNumber],
  functionalLocations: state.functionalLocations.functionalLocations,
  handlingTime: state.alarm.handlingTime[props.partnerNumber],
  timeToAction: state.alarm.timeToAction[props.partnerNumber],
  countsPerMonth: state.alarm.countsPerMonth[props.partnerNumber],
});

const mapDispatchToProps = {
  loadData: loadAlarmsContainer,
  loadMore: loadMoreAlarms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
