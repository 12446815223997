import React, { ReactNode, useMemo } from 'react';
import { useTheme } from 'styled-components';
import SelectV5, { Props, GroupBase } from 'react-select';
import V5Styles from './V5Styles';
import V5Components from './V5Components';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    label?: ReactNode;
  }
}

function optionHasTruthyDisabledField<Option>(option: Option): boolean {
  return typeof option === 'object' && !!option && 'disabled' in option && !!option.disabled;
}

/**
 * React-select v5 ({@link https://react-select.com/}) component with defaults:
 * - UI v3 styles
 * - Default `isOptionDisabled` implementation (option field `disabled` is true)
 * - Custom `DownChevron` component (override in `components` prop if needed)
 * - Custom `LoadingMessage` component (override in `components` prop if needed)
 * - Custom `ClearIndicator` component (override in `components` prop if needed)
 *
 * This is purposefully a very generic component. Please don't extend it without careful
 * consideration. Use this component instead as the heart of more specialized components.
 * This way excess interface bloat and magic can be avoided.
 *
 * @implements UIv3
 * @see {@link PaginationPageSizeSelector}
 */
export function SelectInputV5<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  styles,
  isOptionDisabled = optionHasTruthyDisabledField,
  components,
  ...restProps
}: Props<Option, IsMulti, Group>): JSX.Element {
  const theme = useTheme();
  const finalStyles = useMemo(() => V5Styles(theme, styles), [theme, styles]);
  const finalComponents = useMemo(() => V5Components(components), [components]);

  return (
    <SelectV5 styles={finalStyles} isOptionDisabled={isOptionDisabled} components={finalComponents} {...restProps} />
  );
}
