import { getSensorMetaValue } from 'utils/Data/values';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { format } from 'utils/Date/dateFormatter';
import { intervalToDuration } from 'date-fns';
import find from 'lodash/find';

export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_MONTH = 30 * ONE_DAY;

export const getLabelFormatter = tickInterval => {
  let dateFormat;
  switch (tickInterval) {
    case ONE_MONTH:
      dateFormat = 'MMM';
      break;
    case ONE_DAY:
      dateFormat = 'do MMM';
      break;
    default:
      dateFormat = 'do MMM HH:mm';
  }

  return function () {
    return format(new Date(this.value), dateFormat);
  };
};

export const getTooltipHeaderFormatter = (aggregation, graphType) => {
  let dateFormat;
  switch (aggregation) {
    case 'monthlySum':
      dateFormat = 'MMM';
      break;
    case 'hourlySum':
    case 'raw':
      if (graphType === 'boolean') {
        dateFormat = 'd. MMM HH:mm:ss';
      } else {
        dateFormat = 'd. MMM HH:mm';
      }
      break;
    case 'energyRating':
    case 'dailySum':
    default:
      dateFormat = 'd. MMM';
  }

  return value => format(new Date(value), dateFormat);
};

export const getTickInterval = timespan => {
  const duration = intervalToDuration({
    start: new Date(0),
    end: new Date(timespan),
  });
  if (duration.months > 1 || duration.years > 0) {
    return ONE_MONTH;
  } else if (duration.days > 2 || duration.months > 0) {
    return ONE_DAY;
  }
  return ONE_HOUR;
};

export const getPerformanceChartConfig = ({
  sensorTypeName,
  sensorTypeUnit,
  graphType,
  sensorData,
  sensorMeta,
  timespan,
  maxX,
  getThreshold,
}) => {
  const isAreaCount = sensorTypeName === 'area_count';
  const isBoolean = isBooleanType(sensorTypeName, graphType);
  const isLuxType = sensorTypeUnit === 'lux';
  const config = {
    histories: sensorData,
    minXRange: timespan,
    unit: sensorTypeUnit,
    maxX,
    getThreshold,
    isBooleanType: isBoolean,
  };
  if (isBoolean || isAreaCount) {
    config.yMin = 0;
    config.precision = 0;
  }
  if (isBoolean) {
    config.yMax = 1;
  }
  if (isAreaCount) {
    const capacity = getSensorMetaValue('capacity', sensorMeta);
    if (capacity) {
      config.yFormatter = value => `${value} (${Math.round((value / capacity) * 100)} %)`;
    }
  }
  if (isLuxType) {
    config.yMin = 0;
  }
  return config;
};

export const isBooleanType = (sensorTypeName, graphType) =>
  sensorTypeName === 'boolean' || sensorTypeName === 'IND_binary' || graphType === 'boolean';

export const getTimespanFromHighchartsZoomEvent = event => {
  const { xAxis } = event;
  if (!xAxis?.length) {
    return;
  }
  const max = new Date(xAxis[0]?.max);
  const min = new Date(xAxis[0]?.min);
  if (!isValid(min) || !isValid(max)) {
    return;
  }
  const sameDayRange = isSameDay(min, max);
  return {
    start: sameDayRange ? min : startOfDay(min),
    end: sameDayRange ? max : endOfDay(max),
  };
};

export const getParameterChangesFromHighchartsZoom = (event, parameterModel = {}) => {
  const {
    startDatetime: oldStart,
    endDatetime: oldEnd,
    isZoomed: wasAlreadyZoomed,
    resetZoomDatetimes,
  } = parameterModel;
  const timespan = getTimespanFromHighchartsZoomEvent(event);
  if (timespan) {
    const parameterChanges = {
      startDatetime: timespan.start,
      endDatetime: timespan.end,
      resetZoomDatetimes,
      isZoomed: true,
    };
    if (!wasAlreadyZoomed) {
      parameterChanges.resetZoomDatetimes = {
        start: oldStart,
        end: oldEnd,
      };
    }
    return parameterChanges;
  }
};

/**
 * Get value text for boolean graph type using sensor value and sensor unit
 * @param {Number} sensorValue Sensor value to be checked
 * @param {String} sensorTypeUnit Sensor type unit for specific handling
 * @param {Function} t Translation function
 * @param {Array} sensorMeta Sensor metadata collection
 */
export const getGraphTypeBooleanValueText = (sensorValue, sensorTypeUnit, t, sensorMeta = []) => {
  switch (sensorTypeUnit) {
    case 'activity':
      return sensorValue > 0 ? t('Active') : t('Inactive');
    default: {
      let onOffLabels = getOnOffLabelsFromMeta(sensorMeta);
      if (onOffLabels.length !== 2) {
        onOffLabels = [t('On'), t('Off')];
      }
      return sensorValue > 0 ? onOffLabels[0] : onOffLabels[1];
    }
  }
};

/**
 * Get on/off labels from the sensor metadata
 * @param {Array} sensorMeta Sensor metadata collection
 */
export const getOnOffLabelsFromMeta = sensorMeta => {
  const onOffLabelsMeta = sensorMeta && find(sensorMeta, { metaKey: 'on_off_labels' });
  if (!onOffLabelsMeta) {
    return [];
  }

  try {
    return JSON.parse(onOffLabelsMeta.value).slice(0, 2).map(String);
  } catch {
    return [];
  }
};

/**
 * Get data grouping highcharts series options for sum type data
 */
export const getDataGrouping = aggregation => {
  const dataGrouping = {
    approximation: 'sum',
    enabled: true,
    forced: true,
  };
  if (aggregation === 'hourlySum') {
    return { ...dataGrouping, units: [['hour', [1]]] };
  }
  if (aggregation === 'dailySum') {
    return { ...dataGrouping, units: [['day', [1]]] };
  }
  if (aggregation === 'monthlySum') {
    return { ...dataGrouping, units: [['month', [1]]] };
  }
};
