import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from 'decorators/Translations/translations';
import DialogModal from 'components/Dialog/DialogModal';
import { DEFAULT_PORTFOLIO_COLUMNS } from 'constants/observations';
import ObservationsModalContent from './ObservationsModalContent';
import ObservationsModalSingleObservation from './ObservationsModalSingleObservation';
import useFunctionalLocations from './useFunctionalLocations';
import useQuery from 'decorators/Query/useQuery';

const ObservationsModal = ({ observations = [], onClose, loading, defaultColumns = DEFAULT_PORTFOLIO_COLUMNS }) => {
  const [t] = useTranslations();
  const { query, setQuery } = useQuery();
  const [selectedObservation, setSelectedObservation] = useState(null);
  const functionalLocations = useFunctionalLocations(observations.map(observation => observation.functionalLocation));

  React.useEffect(() => {
    if (query.observationId) {
      setSelectedObservation(observations.find(observation => observation.id === query.observationId));
    } else if (selectedObservation) {
      setSelectedObservation(null);
    }
  }, [observations, query.observationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenObservationModal = React.useCallback(
    observation => {
      if (observation) {
        setQuery({ observationId: observation.id });
      }
    },
    [setQuery]
  );

  const handleCloseObservationModal = React.useCallback(() => {
    setQuery({ observationId: undefined });
  }, [setQuery]);

  if (selectedObservation) {
    return (
      <ObservationsModalSingleObservation
        selectedObservation={selectedObservation}
        onClose={handleCloseObservationModal}
        functionalLocations={functionalLocations}
        showBackButton
      />
    );
  }

  return (
    <DialogModal large isActive onOverlayClick={onClose} title={t('Observations')}>
      {fullScreen => (
        <ObservationsModalContent
          fullScreen={fullScreen}
          loading={loading}
          defaultColumns={defaultColumns}
          observations={observations}
          selectObservation={handleOpenObservationModal}
          functionalLocations={functionalLocations}
        />
      )}
    </DialogModal>
  );
};

ObservationsModal.propTypes = {
  observations: PropTypes.array,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
};
export default ObservationsModal;
