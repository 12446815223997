import { createReducerFromMapping } from 'redux/utils/index';
import DocumentService from 'services/document';
import { OrderType } from 'constants/maintenance';

const initialState = {
  functionalLocations: {},
  serviceOrders: {},
  equipment: {},
  filteredFunctionalLocations: {},
  filteredServiceOrders: {},
  filteredEquipment: {},
  filterKeyword: null,
};

export const LOAD_BY_FUNCTIONAL_LOCATION = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_FUNCTIONAL_LOCATION';
export const LOAD_BY_FUNCTIONAL_LOCATION_SUCCESS = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_FUNCTIONAL_LOCATION_SUCCESS';
export const LOAD_BY_FUNCTIONAL_LOCATION_FAIL = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_FUNCTIONAL_LOCATION_FAIL';

export const loadByFunctionalLocation = functionalLocation => {
  return async dispatch => {
    dispatch({ type: LOAD_BY_FUNCTIONAL_LOCATION, functionalLocation: functionalLocation.functionalLocation });
    try {
      const result = await dispatch(
        DocumentService.loadExternalDocuments({ functionalLocation: functionalLocation.functionalLocation })
      );

      return dispatch({
        type: LOAD_BY_FUNCTIONAL_LOCATION_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BY_FUNCTIONAL_LOCATION_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const LOAD_BY_EQUIPMENT = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_EQUIPMENT';
export const LOAD_BY_EQUIPMENT_SUCCESS = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_EQUIPMENT_SUCCESS';
export const LOAD_BY_EQUIPMENT_FAIL = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_EQUIPMENT_FAIL';
export const loadByEquipment = (functionalLocation, equipmentNumber) => {
  return async dispatch => {
    dispatch({ type: LOAD_BY_EQUIPMENT, equipmentNumber });
    try {
      const result = await dispatch(
        DocumentService.loadExternalDocuments({
          functionalLocation: functionalLocation.functionalLocation,
          equipmentNumber,
        })
      );

      return dispatch({
        type: LOAD_BY_EQUIPMENT_SUCCESS,
        equipmentNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BY_EQUIPMENT_FAIL,
        equipmentNumber,
        error,
      });
    }
  };
};

export const LOAD_BY_SERVICE_ORDER = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_SERVICE_ORDER';
export const LOAD_BY_SERVICE_ORDER_SUCCESS = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_SERVICE_ORDER_SUCCESS';
export const LOAD_BY_SERVICE_ORDER_FAIL = 'CUSTOMER_PLATFORM/Documents/LOAD_BY_SERVICE_ORDER_FAIL';

export const loadByServiceOrder = (orderId, orderType, functionalLocationId, refreshCache = false) => {
  return async dispatch => {
    dispatch({ type: LOAD_BY_SERVICE_ORDER, orderId });

    try {
      const [result1, result2, result3] = await Promise.all([
        dispatch(
          DocumentService.loadExternalDocuments({
            functionalLocation: functionalLocationId,
            serviceOrderNumber: orderId,
          })
        ),
        (orderType === OrderType.ORDER &&
          dispatch(DocumentService.findForServiceOrder({ serviceOrderNumber: orderId, refreshCache }))) ||
          [],
        (orderType === OrderType.PLANNED &&
          dispatch(DocumentService.findForPlannedMaintenance({ plannedMaintenanceId: orderId, refreshCache }))) ||
          [],
      ]);

      const all = result1.concat(result2.map(filesToDocuments)).concat(result3.map(filesToDocuments));

      return dispatch({
        type: LOAD_BY_SERVICE_ORDER_SUCCESS,
        orderId,
        result: all,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BY_SERVICE_ORDER_FAIL,
        orderId,
        error,
      });
    }
  };
};

export const DOWNLOAD = 'CUSTOMER_PLATFORM/Documents/DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'CUSTOMER_PLATFORM/Documents/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAIL = 'CUSTOMER_PLATFORM/Documents/DOWNLOAD_FAIL';

export const download = (functionalLocation, serviceOrderNumber, documentId, fileId, filename) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD, filename });
    try {
      const result = await dispatch(
        DocumentService.downloadExternalDocuments({
          functionalLocation,
          serviceOrderNumber,
          documentId,
          fileId,
          fileName: filename,
        })
      );

      return dispatch({
        type: DOWNLOAD_SUCCESS,
        filename,
        result,
      });
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FAIL,
        error,
      });
    }
  };
};

export const FILTER_BY_KEYWORD = 'CUSTOMER_PLATFORM/Documents/FILTER_BY_KEYWORD';

export const filterByKeyword = keyword => {
  return {
    type: [FILTER_BY_KEYWORD],
    keyword,
  };
};

export const RESET_FILTER_BY_KEYWORD = 'CUSTOMER_PLATFORM/Documents/RESET_FILTER_BY_KEYWORD';

export const resetFilterByKeyword = () => {
  return {
    type: [RESET_FILTER_BY_KEYWORD],
  };
};

export default createReducerFromMapping(
  {
    [LOAD_BY_FUNCTIONAL_LOCATION]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: null,
      },
      filteredFunctionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: null,
      },
      filterKeyword: null,
    }),
    [LOAD_BY_FUNCTIONAL_LOCATION_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result || [],
      },
      filteredFunctionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result || [],
      },
      filterKeyword: null,
    }),
    [LOAD_BY_FUNCTIONAL_LOCATION_FAIL]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: [],
      },
      filteredFunctionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: [],
      },
      filterKeyword: null,
    }),

    [LOAD_BY_SERVICE_ORDER]: (state, action) => ({
      ...state,
      serviceOrders: {
        ...state.serviceOrders,
        [action.orderId]: null,
      },
      filteredServiceOrders: {
        ...state.serviceOrders,
        [action.orderId]: null,
      },
      filterKeyword: null,
    }),
    [LOAD_BY_SERVICE_ORDER_SUCCESS]: (state, action) => ({
      ...state,
      serviceOrders: {
        ...state.serviceOrders,
        [action.orderId]: action.result,
      },
      filteredServiceOrders: {
        ...state.serviceOrders,
        [action.orderId]: action.result,
      },
      filterKeyword: null,
    }),
    [LOAD_BY_SERVICE_ORDER_FAIL]: (state, action) => ({
      ...state,
      serviceOrders: {
        ...state.serviceOrders,
        [action.orderId]: action,
      },
      filteredServiceOrders: {
        ...state.serviceOrders,
        [action.orderId]: action,
      },
    }),

    [LOAD_BY_EQUIPMENT]: (state, action) => ({
      ...state,
      equipment: {
        ...state.equipment,
        [action.equipmentNumber]: null,
      },
      filteredEquipment: {
        ...state.equipment,
        [action.equipmentNumber]: null,
      },
      filterKeyword: null,
    }),
    [LOAD_BY_EQUIPMENT_SUCCESS]: (state, action) => ({
      ...state,
      equipment: {
        ...state.equipment,
        [action.equipmentNumber]: action.result,
      },
      filteredEquipment: {
        ...state.equipment,
        [action.equipmentNumber]: action.result,
      },
      filterKeyword: null,
    }),
    [LOAD_BY_EQUIPMENT_FAIL]: (state, action) => ({
      ...state,
      equipment: {
        ...state.equipment,
        [action.equipmentNumber]: action,
      },
      filteredEquipment: {
        ...state.equipment,
        [action.equipmentNumber]: action,
      },
    }),

    [FILTER_BY_KEYWORD]: (state, action) => ({
      ...state,
      filteredFunctionalLocations: applyFilter(action.keyword, state.functionalLocations),
      filteredEquipment: applyFilter(action.keyword, state.equipment),
      filteredServiceOrders: applyFilter(action.keyword, state.serviceOrders),
      filterByKeyword: action.keyword,
    }),

    [RESET_FILTER_BY_KEYWORD]: state => ({
      ...state,
      filteredFunctionalLocations: state.functionalLocations,
      filteredServiceOrders: state.serviceOrders,
      filteredEquipment: state.equipment,
      filterByKeyword: null,
    }),
  },
  initialState
);

const applyFilter = (keyword, object) => {
  if (object === null || object === undefined) {
    return object;
  }

  const filtered = {};

  keyword = keyword.toLowerCase();

  for (const k in object) {
    if (!object.hasOwnProperty(k)) {
      continue;
    }

    filtered[k] = object[k].filter(
      x =>
        (x.title || '').toLowerCase().indexOf(keyword) >= 0 ||
        x.files.some(y => (y.name || '').toLowerCase().indexOf(keyword) >= 0)
    );
  }

  return filtered;
};

const filesToDocuments = file => ({
  title: file.name,
  id: file.id,
  files: [{ ...file, lastModified: file.modified }],
});
