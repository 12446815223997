import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import startsWith from 'lodash/startsWith';
import times from 'lodash/times';
import {
  TONNES_UNIT,
  TONNES_PRECISION,
  KG_UNIT,
  KG_PRECISION,
  CO2E_TONNES_UNIT,
  CO2E_TONNES_PRECISION,
  CO2E_KG_UNIT,
  CO2E_KG_PRECISION,
  PERCENTAGE_UNIT,
  PERCENTAGE_PRECISION,
  LAST_YEAR,
  THIS_YEAR,
  YEAR_COLORS,
  RECYCLABLE_WASTE,
  NON_RECYCLABLE_WASTE,
  CARBON_FOOTPRINT,
} from 'constants/recycling';
import { RECYCLING_META_PROPS, GLOBAL_DEFAULTS } from 'constants/recyclingMeta';
import { parseISO } from 'date-fns';

const WASTE_BREAKDOWN_TOTALS_TITLE = 'Waste amounts (all)';
const WASTE_BREAKDOWN_CATEGORIES = [
  { id: RECYCLABLE_WASTE, name: 'Recyclable waste', colorName: RECYCLABLE_WASTE },
  { id: NON_RECYCLABLE_WASTE, name: 'Non-recyclable waste', colorName: NON_RECYCLABLE_WASTE },
];

const CARBON_FOOTPRINT_TOTALS_TITLE = 'Carbon footprint (all)';
const CARBON_FOOTPRINT_CATEGORIES = [{ id: CARBON_FOOTPRINT, name: 'Carbon footprint', colorName: CARBON_FOOTPRINT }];

const RATES_BREAKDOWN_TOTALS_TITLE = 'Total rate';
const RATES_BREAKDOWN_CATEGORIES = [
  { id: LAST_YEAR, name: String(LAST_YEAR), colorName: YEAR_COLORS[0] },
  { id: THIS_YEAR, name: String(THIS_YEAR), colorName: YEAR_COLORS[1] },
];

const getRecyclingMetaValue = (config, metaKey, wasteSensor) =>
  config?.find(entry => entry.key === `${wasteSensor.sensorType?.name}/${metaKey}`)?.value;

const getRecyclingMetaEntry = (metaKey, buildingConfig, partnerConfig, wasteSensor) => {
  let value = getRecyclingMetaValue(buildingConfig, metaKey, wasteSensor);
  if (value === undefined) {
    value = getRecyclingMetaValue(partnerConfig, metaKey, wasteSensor);
    if (value === undefined) {
      value = getRecyclingMetaValue(GLOBAL_DEFAULTS, metaKey, wasteSensor);
    }
  }
  return value ? { metaKey, value } : null;
};

const mapSensorMetadata = (buildingWasteSensors, buildingMetadata, partnerMetadata) =>
  buildingWasteSensors.map(wasteSensor => {
    const recyclable = getRecyclingMetaEntry(
      RECYCLING_META_PROPS.RECYCLABLE,
      buildingMetadata,
      partnerMetadata,
      wasteSensor
    );
    const recoverable = getRecyclingMetaEntry(
      RECYCLING_META_PROPS.RECOVERABLE,
      buildingMetadata,
      partnerMetadata,
      wasteSensor
    );
    const co2eFactor = getRecyclingMetaEntry(
      RECYCLING_META_PROPS.CO2E_FACTOR,
      buildingMetadata,
      partnerMetadata,
      wasteSensor
    );
    if (!wasteSensor.sensorMeta) {
      wasteSensor.sensorMeta = [];
    }
    if (recyclable) {
      wasteSensor.sensorMeta.push(recyclable);
    }
    if (recoverable) {
      wasteSensor.sensorMeta.push(recoverable);
    }
    if (co2eFactor) {
      wasteSensor.sensorMeta.push(co2eFactor);
    }
    return wasteSensor;
  });

const resolveHighestValue = ioTData => Math.max(...ioTData.filter(entry => entry).map(entry => entry.value));

const createEmptySeries = (unit, categories) => ({
  categories,
  series: [],
  unit,
});

const createEmptyTotals = (title, unit, categories, breakdown = true) => ({
  title,
  total: 0,
  unit,
  perCategory: breakdown
    ? categories.map(category => ({
        categoryId: String(category.id),
        categoryName: category.name,
        colorName: category.colorName,
        total: 0,
        unit,
      }))
    : [],
});

/**
 * Calculates a total sum of IoT values for one sensor.
 * @param  {number} sensorId - sensor id to create the sum for
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} wasteSensors - waste type sensors
 */
const calculateTotalValueForSensor = (sensorId, ioTData) =>
  ioTData
    .filter(ioTEntry => Number(ioTEntry.sensorId) === Number(sensorId))
    .map(ioTEntry => ioTEntry.value)
    .reduce((prev, curr) => prev + curr, 0);

/**
 * Formats and rounds a value based on the given unit and a highest value in the data.
 * E.g. Small tonnes values will be converted to kg
 * @param {*} ioTDataValue the value to format
 * @param {*} ioTDataUnit fetched data unit
 * @param {*} ioTDataHighestValue highest value in the fetched data
 */
const formatValue = (ioTDataValue, ioTDataUnit, ioTDataHighestValue) => {
  const formattedValue = ioTDataHighestValue < 1 ? ioTDataValue * 1000 : ioTDataValue;
  let precision = PERCENTAGE_PRECISION;
  if (ioTDataUnit === TONNES_UNIT) {
    precision = ioTDataHighestValue < 1 ? KG_PRECISION : TONNES_PRECISION;
  } else if (ioTDataUnit === CO2E_TONNES_UNIT) {
    precision = ioTDataHighestValue < 1 ? CO2E_KG_PRECISION : CO2E_TONNES_PRECISION;
  }
  return round(formattedValue, precision);
};

/**
 * Resolves a unit for the data. Output unit depends on the highest value of the data.
 * @param {number} ioTDataHighestValue fetched data highest value
 * @param {string} ioTDataUnit fetched data unit
 */
const getUnit = (ioTDataHighestValue, ioTDataUnit) => {
  if (ioTDataUnit === TONNES_UNIT) {
    return ioTDataHighestValue < 1 ? KG_UNIT : TONNES_UNIT;
  } else if (ioTDataUnit === CO2E_TONNES_UNIT) {
    return ioTDataHighestValue < 1 ? CO2E_KG_UNIT : CO2E_TONNES_UNIT;
  }
  return ioTDataUnit;
};

/**
 * Creates yearly series for displaying monthly data in Highcharts.
 * @param {number} year a year to create the series for
 * @param {number} index index for the given year in the argument array
 * @param {Object[]} ioTData source IoT data
 * @param {number} ioTDataUnit unit of the data
 */
const createSeriesForAYear = (year, index, ioTData, ioTDataUnit) => {
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  const data = times(12).map(month =>
    formatValue(
      ioTData
        .filter(entry => {
          const entryTimestamp = parseISO(entry.timestamp);
          return year === entryTimestamp.getFullYear() && entryTimestamp.getMonth() === month;
        })
        .map(entry => entry.value || 0)
        .reduce((prev, curr) => prev + curr, 0),
      ioTDataUnit,
      ioTDataHighestValue
    )
  );
  return {
    id: String(year),
    name: String(year),
    data,
    colorName: YEAR_COLORS[index],
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
  };
};

/**
 * Creates yearly total for to display data in Highcharts.
 * @param {number} year a year to create the totals for
 * @param {number} index index for the given year in the argument array
 * @param {Object[]} ioTData source IoT data
 * @param {number} ioTDataUnit unit of the data
 */
const createTotalForAYear = (year, index, ioTData, ioTDataUnit) => {
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  const total = ioTData.find(entry => parseISO(entry.timestamp).getFullYear() === year)?.value;
  return {
    categoryId: String(year),
    categoryName: String(year),
    colorName: YEAR_COLORS[index],
    total: formatValue(total, ioTDataUnit, ioTDataHighestValue),
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
  };
};

export const sensorTypeNameToWasteType = sensorTypeName =>
  capitalize(sensorTypeName?.replace('waste/', '').replace(/_/g, ' '));

export const getWasteSensorsForFunctionalLocation = (buildingHierarchy, buildingMeta, functionalLocationId) => {
  const buildingSensors = buildingHierarchy[functionalLocationId]?.[0]?.sensors;
  const buildingWasteSensors = (buildingSensors ?? []).filter(
    sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'waste/')
  );
  const buildingMetadata = buildingMeta?.[functionalLocationId] ?? [];
  return mapSensorMetadata(buildingWasteSensors, buildingMetadata);
};

export const getSyntheticSensorsForFunctionalLocation = (buildingHierarchy, functionalLocationId) =>
  (buildingHierarchy[functionalLocationId]?.[0]?.sensors ?? []).filter(
    sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'recycling/')
  );

export const getWasteSensorsForPortfolio = (
  buildingHierarchy,
  buildingMeta,
  partnerMeta,
  partnerNumber,
  functionalLocations
) =>
  Object.keys(buildingHierarchy)
    .filter(functionalLocationId => functionalLocations[functionalLocationId]?.partnerNumber?.includes(partnerNumber))
    .map(functionalLocationId => {
      const buildingSensors = buildingHierarchy[functionalLocationId]?.[0]?.sensors;
      const buildingWasteSensors = (buildingSensors ?? []).filter(
        sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'waste/')
      );
      const buildingMetadata = buildingMeta[functionalLocationId] || [];
      const partnerMetadata = partnerMeta[partnerNumber]?.meta || [];
      return mapSensorMetadata(buildingWasteSensors, buildingMetadata, partnerMetadata);
    })
    .reduce((prev, curr) => [...prev, ...curr], []);

export const getSyntheticSensorsForPortfolio = (buildingHierarchy, partnerNumber, functionalLocations) =>
  Object.keys(buildingHierarchy)
    .filter(functionalLocationId => functionalLocations[functionalLocationId]?.partnerNumber?.includes(partnerNumber))
    .map(functionalLocationId =>
      (buildingHierarchy[functionalLocationId]?.[0]?.sensors ?? []).filter(
        sensor => sensor.sensorType && startsWith(sensor.sensorType.name, 'recycling/')
      )
    )
    .reduce((prev, curr) => [...prev, ...curr], []);

export const getRecyclableSensors = wasteSensors =>
  (wasteSensors ?? []).filter(
    wasteSensor =>
      String(wasteSensor.sensorMeta?.find(entry => entry.metaKey === RECYCLING_META_PROPS.RECYCLABLE)?.value) === 'true'
  );

export const getRecoverableSensors = wasteSensors =>
  (wasteSensors ?? []).filter(
    wasteSensor =>
      String(wasteSensor.sensorMeta?.find(entry => entry.metaKey === RECYCLING_META_PROPS.RECYCLABLE)?.value) ===
        'true' ||
      String(wasteSensor.sensorMeta?.find(entry => entry.metaKey === RECYCLING_META_PROPS.RECOVERABLE)?.value) ===
        'true'
  );

export const getCarbonFootprintSensors = wasteSensors =>
  (wasteSensors ?? []).filter(
    wasteSensor =>
      wasteSensor.sensorMeta?.find(entry => entry.metaKey === RECYCLING_META_PROPS.CO2E_FACTOR)?.value !== undefined
  );

export const formatSeries = (ioTData, ioTDataUnit, series) => {
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  return series.map(entry => ({
    ...entry,
    data: entry.data.map(value => formatValue(value, ioTDataUnit, ioTDataHighestValue)),
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
  }));
};

/**
 * Creates waste amount series for to display data in Highcharts from the given IoT data.
 * Fixed data categories are respected.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {string} ioTDataUnit - fetched IoT data unit
 * @param  {Object[]} wasteSensors - waste sensors to create the data for
 */
export const createBreakdownWasteAmountSeries = (ioTData, ioTDataUnit, wasteSensors) => {
  if (isEmpty(ioTData)) {
    return createEmptySeries(KG_UNIT, WASTE_BREAKDOWN_CATEGORIES);
  }
  const recyclableSensors = getRecyclableSensors(wasteSensors);
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  const rawSeries = wasteSensors
    .map(sensor => {
      const wasteType = sensorTypeNameToWasteType(sensor.sensorType?.name);
      const sensorTotal = calculateTotalValueForSensor(sensor.id, ioTData);
      const isRecyclable = recyclableSensors.some(recyclableSensor => recyclableSensor.id === sensor.id);
      return {
        sensorId: sensor.id,
        wasteType,
        colorName: isRecyclable ? RECYCLABLE_WASTE : NON_RECYCLABLE_WASTE,
        totalValue: formatValue(sensorTotal, ioTDataUnit, ioTDataHighestValue),
        unit: getUnit(ioTDataHighestValue, ioTDataUnit),
      };
    })
    .filter(entry => entry.totalValue > 0);
  const series = orderBy(rawSeries, 'wasteType', 'asc');
  return {
    categories: WASTE_BREAKDOWN_CATEGORIES,
    series,
  };
};

/**
 * Creates a totals object for the WasteTotals component to consume. Fixed data categories
 * are respected.
 * @param  {number} wasteAmountTotal - fetched waste amount total
 * @param  {number} recyclableTotal - fetched recyclable total
 * @param  {string} ioTDataUnit - fetched IoT data unit
 */
export const createBreakdownWasteAmountTotals = (wasteAmountTotal, recyclableTotal, ioTDataUnit) => {
  if (!wasteAmountTotal) {
    return createEmptyTotals(WASTE_BREAKDOWN_TOTALS_TITLE, KG_UNIT, WASTE_BREAKDOWN_CATEGORIES);
  }
  const ioTDataHighestValue = Math.max([recyclableTotal, wasteAmountTotal]);
  return {
    title: WASTE_BREAKDOWN_TOTALS_TITLE,
    total: formatValue(wasteAmountTotal, ioTDataUnit, ioTDataHighestValue),
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
    perCategory: WASTE_BREAKDOWN_CATEGORIES.map(category => {
      const categoryTotal = category.id === RECYCLABLE_WASTE ? recyclableTotal : wasteAmountTotal - recyclableTotal;
      return {
        categoryId: String(category.id),
        categoryName: category.name,
        colorName: category.colorName,
        total: formatValue(categoryTotal, ioTDataUnit, ioTDataHighestValue),
        unit: getUnit(ioTDataHighestValue, ioTDataUnit),
      };
    }),
  };
};

/**
 * Creates CO2e series for to display data in Highcharts from the given IoT data.
 * Fixed data categories are respected.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {string} ioTDataUnit - fetched IoT data unit
 * @param  {Object[]} wasteSensors - waste sensors to create the data for
 */
export const createBreakdownCarbonFootprintSeries = (ioTData, ioTDataUnit, wasteSensors) => {
  const co2Sensors = getCarbonFootprintSensors(wasteSensors);
  if (isEmpty(co2Sensors) || isEmpty(ioTData)) {
    return createEmptySeries(CO2E_TONNES_UNIT, CARBON_FOOTPRINT_CATEGORIES);
  }
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  const rawSerie = co2Sensors
    .map(co2Sensor => {
      const wasteType = sensorTypeNameToWasteType(co2Sensor.sensorType?.name);
      const sensorTotal = calculateTotalValueForSensor(co2Sensor.id, ioTData);
      return {
        sensorId: co2Sensor.id,
        wasteType,
        colorName: CARBON_FOOTPRINT,
        totalValue: formatValue(sensorTotal, ioTDataUnit, ioTDataHighestValue),
        unit: getUnit(ioTDataHighestValue, ioTDataUnit),
      };
    })
    .filter(entry => entry.totalValue > 0);
  const series = orderBy(rawSerie, 'wasteType', 'asc');
  return {
    categories: CARBON_FOOTPRINT_CATEGORIES,
    series,
  };
};

/**
 * Creates a totals object for the WasteTotals component to consume. Fixed data categories
 * are respected.
 * @param  {number} total - fetched total
 * @param  {string} ioTDataUnit - fetched IoT data unit
 */
export const createBreakdownCarbonFootprintTotals = (total, ioTDataUnit) => {
  if (!total) {
    return createEmptyTotals(CARBON_FOOTPRINT_TOTALS_TITLE, CO2E_TONNES_UNIT, CARBON_FOOTPRINT_CATEGORIES, false);
  }
  return {
    title: CARBON_FOOTPRINT_TOTALS_TITLE,
    total: formatValue(total, ioTDataUnit, total),
    unit: getUnit(total, ioTDataUnit),
    perCategory: [],
  };
};

/**
 * Creates yearly series for given timeframe and sensors.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {number[]} years - years to create the series for
 * @param  {string} ioTDataUnit - unit of the data
 */
export const createBreakdownYearlySeries = (ioTData, years, ioTDataUnit) =>
  years.map((year, index) => createSeriesForAYear(year, index, ioTData, ioTDataUnit));

/**
 * Creates a yearly totals object for the WasteTotals component to consume.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {number[]} years - years to create the totals for
 * @param  {string} ioTDataUnit - unit of the data
 */
export const createBreakdownYearlyTotals = (ioTData, years, ioTDataUnit) => {
  const ioTDataHighestValue = resolveHighestValue(ioTData);
  const total = ioTData.map(entry => entry.value || 0).reduce((prev, curr) => prev + curr, 0);
  return {
    total: formatValue(total, ioTDataUnit, ioTDataHighestValue),
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
    perCategory: years.map((year, index) => createTotalForAYear(year, index, ioTData, ioTDataUnit)),
  };
};

/**
 * Creates rates series for to display data in Highcharts from the given IoT data.
 * Fixed data categories are respected.
 * @param  {Object[]} ioTData - fetched IoT data
 * @param  {Object[]} years - years to create the data for
 * @param  {string} ioTDataUnit - unit of the data
 */
export const createBreakdownRatesSeries = (ioTData, years, ioTDataUnit) =>
  createBreakdownYearlySeries(ioTData, years, ioTDataUnit).map(entry => ({
    ...entry,
    data: entry.data.map(value => value || null),
  }));

/**
 * Creates a totals object for the WasteTotals component to consume. Fixed data categories
 * are respected.
 * @param {Object[]} ioTData yearly data
 * @param {Object[]} ioTOpiData past 12 months data
 * @param {stirng} iotDataUnit unit of the source data
 */
export const createBreakdownRateTotals = (ioTData, ioTOpiData, ioTDataUnit) => {
  if (isEmpty(ioTData) || isEmpty(ioTOpiData) || !ioTOpiData[0]?.value) {
    return createEmptyTotals(RATES_BREAKDOWN_TOTALS_TITLE, PERCENTAGE_UNIT, RATES_BREAKDOWN_CATEGORIES);
  }
  const ioTDataHighestValue = resolveHighestValue([...ioTData, ...ioTOpiData]);
  return {
    title: RATES_BREAKDOWN_TOTALS_TITLE,
    total: formatValue(ioTOpiData[0]?.value, ioTDataUnit, ioTDataHighestValue),
    unit: getUnit(ioTDataHighestValue, ioTDataUnit),
    perCategory: RATES_BREAKDOWN_CATEGORIES.map(category => ({
      categoryId: String(category.id),
      categoryName: category.name,
      colorName: category.colorName,
      total: formatValue(
        ioTData.find(entry => parseISO(entry.timestamp).getFullYear() === category.id)?.value || 0,
        ioTDataUnit,
        ioTDataHighestValue
      ),
      unit: getUnit(ioTDataHighestValue, ioTDataUnit),
    })),
  };
};
