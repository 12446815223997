import { useCallback, useEffect, useRef } from 'react';

const useTimeout = delay => {
  const timeoutRef = useRef([]);

  const push = useCallback(
    callback => {
      timeoutRef.current.push(
        setTimeout(() => {
          callback();
        }, delay)
      );
    },
    [delay]
  );

  const clear = useCallback(() => {
    timeoutRef.current.forEach(timeout => clearTimeout(timeout));
    timeoutRef.current = [];
  }, []);

  useEffect(() => {
    return clear;
  }, [push, clear]);

  return { push, clear, timeoutRef };
};

export default useTimeout;
