import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const StyledFilterPill = styled.a`
  display: flex;
  align-items: center;
  padding: var(--size-xs) var(--size-sm);
  background-color: ${props => (props.selected ? 'var(--blue-filter-pill-bg-selected)' : 'var(--blue-filter-pill-bg)')};
  border-radius: 0.25em;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const Icon = styled(Svg)`
  fill: var(--blue-filter-pill-fg);
  margin-right: var(--size-xs);
`;

const Label = styled.span`
  color: var(--blue-filter-pill-fg);
  font-size: ${props => props.theme.fontSize.xs};
  line-height: 16px;
`;

const FilterPill = props => {
  const { icon, children, onClick, ...other } = props;

  const hasOnClick = onClick ? { onClick: () => onClick(!props.selected) } : {};
  return (
    <StyledFilterPill {...other} {...hasOnClick}>
      {icon && <Icon name={icon} />}
      <Label>{children}</Label>
    </StyledFilterPill>
  );
};

export default FilterPill;
