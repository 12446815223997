import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';
import reject from 'lodash/reject';
import { canConfigureCustomViews } from 'utils/Data/profileData';

const initialState = {
  loading: false,
  customViews: [],
  customViewsByFl: {},
  customViewsByCustomer: {},
  customViewsByUser: {},
  error: false,
};

export const LOAD_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW';
export const LOAD_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW_SUCCESS';
export const LOAD_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_CUSTOM_VIEW_FAIL';

export const loadCustomViews = () => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && canConfigureCustomViews(profile);

    dispatch({ type: LOAD_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterDataService.customViews(noCache));

      return dispatch({
        type: LOAD_CUSTOM_VIEW_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const LOAD_MY_CUSTOM_VIEWS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS';
export const LOAD_MY_CUSTOM_VIEWS_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS_SUCCESS';
export const LOAD_MY_CUSTOM_VIEWS_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOM_VIEWS_FAIL';

export const loadMyCustomViews = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = state.profile && state.profile.profile;
    // Invalidate cache for admin users
    const noCache = profile && canConfigureCustomViews(profile);

    dispatch({ type: LOAD_MY_CUSTOM_VIEWS });
    try {
      const result = await dispatch(MasterDataService.myCustomViews(noCache));

      dispatch({
        type: LOAD_MY_CUSTOM_VIEWS_SUCCESS,
        userId: profile.id,
        result,
      });
      return result;
    } catch (error) {
      dispatch({
        type: LOAD_MY_CUSTOM_VIEWS_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const LOAD_USER_CUSTOM_VIEWS = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS';
export const LOAD_USER_CUSTOM_VIEWS_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS_SUCCESS';
export const LOAD_USER_CUSTOM_VIEWS_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_USER_CUSTOM_VIEWS_FAIL';

export const loadUserCustomViews = userId => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && canConfigureCustomViews(profile);

    dispatch({ type: LOAD_USER_CUSTOM_VIEWS });
    try {
      const result = await dispatch(MasterDataService.userCustomViews(userId, noCache));

      dispatch({
        type: LOAD_USER_CUSTOM_VIEWS_SUCCESS,
        userId,
        result,
      });
      return result;
    } catch (error) {
      dispatch({
        type: LOAD_USER_CUSTOM_VIEWS_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const UPDATE_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW';
export const UPDATE_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW_SUCCESS';
export const UPDATE_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/UPDATE_CUSTOM_VIEW_FAIL';

export const updateCustomView = customView => {
  return async dispatch => {
    dispatch({ type: UPDATE_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterDataService.upsertCustomView(customView));
      return dispatch({
        type: UPDATE_CUSTOM_VIEW_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const DELETE_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW';
export const DELETE_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW_SUCCESS';
export const DELETE_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/DELETE_CUSTOM_VIEW_FAIL';

export const deleteCustomView = id => {
  return async dispatch => {
    dispatch({ type: DELETE_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterDataService.deleteCustomView(id));
      return dispatch({
        type: DELETE_CUSTOM_VIEW_SUCCESS,
        result,
        customViewId: id,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CUSTOM_VIEW_FAIL,
        error,
      });
      throw error;
    }
  };
};

export const LOAD_MY_FL_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW';
export const LOAD_MY_FL_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW_SUCCESS';
export const LOAD_MY_FL_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_FL_CUSTOM_VIEW_FAIL';

export const myFLCustomViews = functionalLocation => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && canConfigureCustomViews(profile);

    dispatch({ type: LOAD_MY_FL_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterDataService.myFLCustomViews(functionalLocation, noCache));
      return dispatch({
        type: LOAD_MY_FL_CUSTOM_VIEW_SUCCESS,
        result,
        functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MY_FL_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export const LOAD_MY_CUSTOMER_CUSTOM_VIEW = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW';
export const LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS';
export const LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL = 'CUSTOMER_PLATFORM/CustomView/LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL';

export const myCustomerCustomViews = customerNumber => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && canConfigureCustomViews(profile);

    dispatch({ type: LOAD_MY_CUSTOMER_CUSTOM_VIEW });
    try {
      const result = await dispatch(MasterDataService.myCustomerCustomViews(customerNumber, noCache));
      return dispatch({
        type: LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS,
        result,
        customerNumber,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViews: action.result,
        loading: false,
      };
    },
    [LOAD_MY_CUSTOM_VIEWS_FAIL]: (state, action) => ({ ...state, error: action.error }),
    [LOAD_MY_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({
      ...state,
      customViewsByUser: { ...state.customViewsByUser, [action.userId]: action.result },
    }),
    [LOAD_USER_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({ ...state, error: action.error }),
    [LOAD_USER_CUSTOM_VIEWS_SUCCESS]: (state, action) => ({
      ...state,
      customViewsByUser: { ...state.customViewsByUser, [action.userId]: action.result },
    }),
    [UPDATE_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [UPDATE_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [UPDATE_CUSTOM_VIEW_SUCCESS]: (state, action) => ({ ...state, loading: false }),
    [DELETE_CUSTOM_VIEW]: state => ({ ...state }),
    [DELETE_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, error: action.error }),
    [DELETE_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      const { result, customViewId } = action;
      if (!!result?.ok) {
        const customViews = reject(state.customViews, { id: customViewId });
        return {
          ...state,
          customViews,
        };
      }
      return {
        ...state,
      };
    },
    [LOAD_MY_FL_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_MY_FL_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_MY_FL_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViewsByFl: {
          ...state.customViewsByFl,
          [action.functionalLocation]: action.result,
        },
        loading: false,
      };
    },
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW]: (state, action) => ({ ...state, loading: true }),
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_MY_CUSTOMER_CUSTOM_VIEW_SUCCESS]: (state, action) => {
      return {
        ...state,
        customViewsByCustomer: {
          ...state.customViewsByCustomer,
          [action.customerNumber]: action.result,
        },
        loading: false,
      };
    },
  },
  initialState
);
