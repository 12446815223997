import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import * as observations from './observations';
import * as inspections from './inspections';
import * as slas from './slas';

export default wrapEachWithThunkRequest({
  ...observations,
  ...inspections,
  ...slas,
});
