import { MutableRefObject, useLayoutEffect, useRef } from 'react';

export function useVirtualizedTableScrollMargin(): {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  scrollMargin: number;
} {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const parentOffsetRef = useRef(0);

  useLayoutEffect(() => {
    const clientRect = containerRef.current?.getClientRects()[0];
    parentOffsetRef.current = clientRect?.top ?? 0;
  }, []);

  const scrollMargin = parentOffsetRef.current;

  return { containerRef, scrollMargin };
}
