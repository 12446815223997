import { icons } from 'components/Icon/IconNames';

export const VIEW_TYPES = {
  powerBi: 'Power BI',
  freeLayout: 'Free Layout',
  embedded: 'Embedded page',
  externalLink: 'External link',
  embeddedDocument: 'Embedded PDF/image',
  customCharts: 'Custom Chart',
};

export const getFormDefaults = type => {
  switch (type) {
    case VIEW_TYPES.powerBi:
      return powerbiModelDefaults;
    case VIEW_TYPES.embedded:
      return embeddedDefaults;
    case VIEW_TYPES.externalLink:
      return externalLinkDefaults;
    case VIEW_TYPES.embeddedDocument:
      return embeddedDocumentDefaults;
    case VIEW_TYPES.customCharts:
      return customChartDefaults;
    case VIEW_TYPES.freeLayout:
    default:
      return freeLayoutDefaults;
  }
};

const commonDefaults = {
  portfolio: true,
  public: false,
  viewName: '',
  viewDesc: '',
  users: [],
  functionalLocations: [],
  customers: '',
};

export const customChartDefaults = {
  ...commonDefaults,
  viewType: VIEW_TYPES.customCharts,
  customChartIds: null,
  functionalLocations: null,
  portfolio: false,
};

export const powerbiModelDefaults = {
  ...commonDefaults,
  powerbiAppId: '9fcc9f88-d268-4be5-b2e6-3a19be4b0f23',
  viewType: VIEW_TYPES.powerBi,
  powerbiReportId: '',
  powerbiWorkspaceId: '',
};

export const freeLayoutDefaults = {
  ...commonDefaults,
  viewType: VIEW_TYPES.freeLayout,
  content: '',
};

export const embeddedDefaults = {
  ...commonDefaults,
  viewType: VIEW_TYPES.embedded,
  content: '',
  height: 700,
  offsetTop: 0,
};

export const externalLinkDefaults = {
  ...commonDefaults,
  viewType: VIEW_TYPES.externalLink,
  content: '',
};

export const embeddedDocumentDefaults = {
  ...commonDefaults,
  viewType: VIEW_TYPES.embeddedDocument,
  content: '',
};

export const getIcon = viewType => {
  let icon = null;
  switch (viewType) {
    case VIEW_TYPES.externalLink:
      icon = icons.EXTERNAL_LINK;
      break;
    case VIEW_TYPES.embeddedDocument:
      icon = icons.IMAGE;
      break;
    default:
      icon = icons.CUSTOM_VIEW;
  }
  return icon;
};
