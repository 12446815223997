import { rgba } from 'polished';
import { pointTooltip, getCommonExportingOptions, getCommonNavigationOptions, getAxisTextStyles } from '../utils';
import merge from 'lodash/merge';

export const getChartConfig = ({
  seriesData,
  xUnit,
  yUnit,
  chartWidth,
  chartHeight,
  theme,
  chartTitle,
  annotations,
  setpoint,
  showTrend,
  t,
  onOpenAnnotationForm,
}) => {
  const chartColors = [...theme.chartColors];
  let series = [];

  series = seriesData.map(el => {
    const { id, name, color: configuredColor, data } = el;

    const color = configuredColor || chartColors.shift();
    return {
      id: String(id), // for some reason trendline disappears if id is number
      name,
      data: [...data].sort((a, b) => a[0] - b[0]),
      color,
      marker: {
        symbol: 'circle',
        fillColor: rgba(color, 0.6),
        lineColor: color,
      },
    };
  });

  if (showTrend) {
    series = series.concat(
      series.map(({ id, name, color }) => ({
        linkedTo: id,
        type: 'trendline',
        name: seriesData.length === 1 ? t('Trend') : `${t('Trend')} (${name})}`,
        color,
      }))
    );
  }

  if (setpoint) {
    series = series.concat([
      {
        name: setpoint.name || t('Setpoint'),
        data: [...setpoint.coordinates].sort((a, b) => b.x - a.x).map(({ x, y }) => [x, y]),
        color: setpoint.color || chartColors.shift(),
        type: 'line',
      },
    ]);
  }

  return {
    annotations,
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      backgroundColor: 'transparent',
      plotBackgroundColor: theme.colors.white,
      spacing: [8, 20, 20, 20],
      width: chartWidth,
      height: chartHeight,
      className: 'scatter-plot',
      style: {
        fontFamily: theme.fontFamily.text,
      },
    },
    boost: {
      useGPUTranslations: true,
    },
    title: false,
    credits: false,
    tooltip: {
      followPointer: true,
      split: true,
      shared: true,
      crosshairs: true,
      borderWidth: 0,
      padding: 0,
      backgroundColor: 'transparent',
      useHTML: true,
      formatter() {
        if (this.point) {
          const x = this.point.x.toFixed(2);
          const y = this.point.y.toFixed(2);
          return pointTooltip({
            theme,
            point: this.point,
            showName: true,
            values: [
              { value: x, unit: xUnit },
              { value: y, unit: yUnit },
            ],
          });
        }
        if (this.points?.[0]) {
          const x = this.x.toFixed(2);
          const y = this.y.toFixed(2);
          return pointTooltip({
            theme,
            point: this.points[0],
            showName: true,
            values: [
              { value: x, unit: xUnit },
              { value: y, unit: yUnit },
            ],
          });
        }
      },
    },
    plotOptions: {
      series: { stickyTracking: false },
      scatter: {
        point: {
          events: {
            click: onOpenAnnotationForm,
          },
        },
        marker: {
          radius: 5,
          states: {
            hover: {
              enabled: true,
              lineColor: theme.colors.darkGray,
            },
          },
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        turboThreshold: 400,
        boostTreshold: 2000,
      },
      line: {
        lineWidth: 2,
        connectNulls: true,
        turboThreshold: 400, // Make rendering large series (> 400) more efficient.
        enableMouseTracking: false,
        marker: {
          enabled: false,
          lineWidth: 2,
          radius: 5,
          symbol: 'circle',
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
      trendline: {
        showInLegend: true,
        enableMouseTracking: false,
        lineWidth: 2,
        marker: {
          enabled: false,
          lineWidth: 2,
          radius: 5,
          symbol: 'circle',
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
    legend: {
      align: 'left',
      margin: 26,
      verticalAlign: 'top',
      borderWidth: 0,
    },
    xAxis: merge({}, getAxisTextStyles(theme), {
      crosshair: true,
      lineWidth: 0,
      lineColor: theme.colors.mystic,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      gridLineColor: theme.colors.mystic,
      tickLength: 5,
      labels: {
        y: 25,
      },
      title: {
        text: xUnit,
      },
    }),
    yAxis: merge({}, getAxisTextStyles(theme), {
      crosshair: true,
      title: {
        text: yUnit,
      },
      labels: {
        format: `{value}`,
        enabled: true,
      },
    }),
    exporting: getCommonExportingOptions({ title: chartTitle, exportCSV: true }),
    navigation: getCommonNavigationOptions(),
    series,
  };
};

/**
 * Test if sensorData has values
 * @param {Array} sensorData
 */
export const hasData = sensorData => sensorData.some(data => data.data?.length);
