import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { SERVICE_API_PATH } from './index';

export const invalidateAllChecklistCaches = () => invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/checklists`);

export const invalidateTemplateListingCaches = () =>
  Promise.all([
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/checklists/division`),
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/checklists/partner`),
  ]);

export const checklistTemplatesForDivision = ({ includeDisabled, refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/division`,
    query: { includeDisabled },
    refreshCache,
    useAuthorization: true,
  });

export const checklistTemplatesForAdmin = ({ division, refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/admin/${division}`,
    refreshCache,
    useAuthorization: true,
  });

export const checklistTemplatesForPartner = (partnerNumber, { includeDisabled, refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/partner/${partnerNumber}`,
    query: { includeDisabled },
    refreshCache,
    useAuthorization: true,
  });

export const checklistTemplate = (templateId, { refreshCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/${templateId}`,
    refreshCache,
    useAuthorization: true,
  });

export const checklistTemplateWithToken = token =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/with-token`,
    query: { token },
    useAuthorization: false,
  });

export const createChecklistTemplate = async template => {
  await invalidateTemplateListingCaches();
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists`,
    data: template,
    useAuthorization: true,
  });
};

export const updateChecklistTemplate = async template => {
  await invalidateTemplateListingCaches();
  return ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/${template.id}`,
    data: template,
    useAuthorization: true,
  });
};

export const deleteChecklistTemplate = async templateId => {
  await invalidateTemplateListingCaches();
  return ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/checklists/${templateId}`,
    useAuthorization: true,
  });
};

export const createChecklistTemplateOption = option =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/${option.checklistTemplateId}/options`,
    data: option,
    useAuthorization: true,
  });

export const updateChecklistTemplateOption = option =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/${option.checklistTemplateId}/options/${option.id}`,
    data: option,
    useAuthorization: true,
  });

export const deleteChecklistTemplateOption = option =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/checklists/${option.checklistTemplateId}/options/${option.id}`,
    useAuthorization: true,
  });

export const createChecklistTemplateItem = item =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/${item.checklistTemplateId}/items`,
    data: item,
    useAuthorization: true,
  });

export const updateChecklistTemplateItem = item =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/${item.checklistTemplateId}/items/${item.id}`,
    data: item,
    useAuthorization: true,
  });

export const deleteChecklistTemplateItem = item =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/checklists/${item.checklistTemplateId}/items/${item.id}`,
    useAuthorization: true,
  });

export const findChecklistReportsForPartner = (
  partnerNumber,
  startDate,
  endDate,
  { refreshCache, includeTemplates } = {}
) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/find`,
    query: { partnerNumber, startDate, endDate, includeTemplates },
    refreshCache,
    useAuthorization: true,
  });

export const findChecklistReportsForFunctionalLocation = (
  functionalLocation,
  startDate,
  endDate,
  { refreshCache, includeTemplates } = {}
) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/find`,
    query: { functionalLocation, startDate, endDate, includeTemplates },
    refreshCache,
    useAuthorization: true,
  });

export const checklistReports = (serviceOrderNumber, { refreshCache, onlyInvalidateCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/find`,
    query: { serviceOrderNumber },
    refreshCache,
    onlyInvalidateCache,
    useAuthorization: true,
  });

export const createChecklistReport = async report => {
  await invalidateAllChecklistCaches();
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/${report.checklistTemplateId}/reports`,
    data: report,
    useAuthorization: true,
  });
};

export const updateChecklistReport = async report => {
  await invalidateAllChecklistCaches();
  return ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${report.id}`,
    data: report,
    useAuthorization: true,
  });
};

export const checklistReportsWithToken = (token, { refreshCache, onlyInvalidateCache } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/with-token`,
    query: { token },
    refreshCache,
    onlyInvalidateCache,
    useAuthorization: false,
  });

export const createChecklistReportWithToken = async (token, report) => {
  await invalidateAllChecklistCaches();
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/with-token`,
    query: { token },
    data: report,
    useAuthorization: false,
  });
};

export const updateChecklistReportWithToken = async (token, report) => {
  await invalidateAllChecklistCaches();
  return ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${report.id}/with-token`,
    query: { token },
    data: report,
    useAuthorization: false,
  });
};

export const createChecklistReportItems = (checklistReportId, reportItems) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${checklistReportId}/items`,
    data: reportItems,
    useAuthorization: true,
  });

export const updateChecklistReportItems = (checklistReportId, reportItems) =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${checklistReportId}/items`,
    data: reportItems,
    useAuthorization: true,
  });

export const createChecklistReportItemsWithToken = (token, checklistReportId, reportItems) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${checklistReportId}/items/with-token`,
    query: { token },
    data: reportItems,
    useAuthorization: false,
  });

export const updateChecklistReportItemsWithToken = (token, checklistReportId, reportItems) =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/checklists/reports/${checklistReportId}/items/with-token`,
    query: { token },
    data: reportItems,
    useAuthorization: false,
  });

export const copyChecklistTemplatesToPartner = async (sourcePartnerNumber, destinationPartnerNumbers, templateIds) => {
  await invalidateTemplateListingCaches();
  return ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/checklists/partner/${sourcePartnerNumber}/copy`,
    query: { destinationPartnerNumbers },
    data: templateIds,
    useAuthorization: true,
  });
};
