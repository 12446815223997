import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

const StyledNameColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${props => props.theme.font.weight.bold};
`;
StyledNameColumn.displayName = 'StyledNameColumn';

const Count = styled.div`
  margin-left: var(--size-xs);
  font-weight: ${props => props.theme.font.weight.normal};
  color: ${props => props.theme.colors.darkGray};
`;
Count.displayName = 'Count';

export const NameColumn = ({ description, count }) => {
  return (
    <StyledNameColumn data-test-id="FLTableName">
      {description} {!isNil(count) && <Count>({count})</Count>}
    </StyledNameColumn>
  );
};

NameColumn.propTypes = {
  description: PropTypes.string.isRequired,
};
