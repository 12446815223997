import React from 'react';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

export const StyledTypeColumn = styled.div`
  color: ${props => props.theme.colors.black};
`;

export const getTypeTitle = (deviceGroup, t) => t(`${deviceGroup[0].toUpperCase()}${deviceGroup.substring(1)}`);

const TypeColumn = props => {
  if (!props.data) {
    return null;
  }
  const deviceGroup = props.data;
  const type = deviceGroup && deviceGroup[0] && getTypeTitle(deviceGroup, props.t);

  return <StyledTypeColumn>{type}</StyledTypeColumn>;
};

export default translations(TypeColumn);
