import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Notes = styled.div`
  order: 3;
  display: ${props => props.hideOnMobile && 'none'};
  height: 24px;

  ${props => props.theme.media.landscape`
    order: 0;
    display: block;
    height: auto;
  `}
`;
Notes.displayName = 'Notes';

const ValueNote = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.darkGray};
  letter-spacing: 0.3px;
`;
ValueNote.displayName = 'ValueNote';

const StatusInfoOutsideValueNotes = ({ valueNote, hideOnMobile }) => {
  return (
    <Notes hideOnMobile={hideOnMobile}>
      <ValueNote>{valueNote}</ValueNote>
    </Notes>
  );
};

export default StatusInfoOutsideValueNotes;

StatusInfoOutsideValueNotes.defaultProps = {
  valueNote: '',
  hideOnMobile: false,
};

StatusInfoOutsideValueNotes.propTypes = {
  valueNote: PropTypes.string,
  hideOnMobile: PropTypes.bool,
};
