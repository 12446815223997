import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { parseISO } from 'date-fns';

import Svg from 'components/Svg/Svg';
import EditCommentForm from './EditCommentForm';
import ChatBubble from 'components/ChatBubble/ChatBubble';

import { isAdminRole } from 'utils/Data/profileData';
import { format } from 'utils/Date/dateFormatter';
import { Info, SubSection } from '../ServiceOrder.styled';
import CalendarStatusTag from 'components/Calendar/CalendarStatusTag/CalendarStatusTag';

const EditIcon = styled(Svg)`
  fill: ${props => props.theme.colors.black};
  cursor: pointer;
  vertical-align: baseline;
  margin-left: var(--size-xxs);
`;
EditIcon.displayName = 'EditIcon';

const CommentInfo = styled(Info)`
  display: flex;
  gap: 0.5em;
  align-items: center;
`;

const formatDate = date => format(parseISO(date), 'd.M.yyyy, HH:mm');

const ServiceOrderComments = props => {
  const { t, comments, loading, profile, editServiceModuleComment, statusColors } = props;
  const [editCommentId, setEditCommentId] = React.useState(null);

  if (loading) {
    return (
      <SubSection>
        <h3>{t('Comments')} (...)</h3>
        <SkeletonText />
      </SubSection>
    );
  }
  if (!comments.length) {
    return (
      <SubSection>
        <h3>{t('Comments')} (0)</h3>
        <Info>{t('No comments available')}</Info>
      </SubSection>
    );
  }
  return (
    <SubSection>
      <h3>
        {t('Comments')} ({comments.length})
      </h3>
      {comments.map(comment => {
        const editing = editCommentId === comment.id;
        const allowEdit =
          profile &&
          (isAdminRole(profile.role) || profile.id === comment.createdById) &&
          typeof editServiceModuleComment === 'function';
        return (
          <ChatBubble fullWidth={editing} key={comment.created}>
            {editing && (
              <EditCommentForm
                id={comment.id}
                comment={comment.comment}
                editServiceModuleComment={editServiceModuleComment}
                onCloseEdit={() => setEditCommentId(null)}
              />
            )}
            {!editing && (
              <>
                {comment.comment}
                {allowEdit && <EditIcon name="pen" onClick={() => setEditCommentId(comment.id)} />}
                <CommentInfo>
                  <CalendarStatusTag
                    status={comment.status}
                    statusColors={statusColors}
                    text={t(comment.status)}
                    showFullText={false}
                  />
                  {formatDate(comment.created)}, {comment.createdBy}
                  {comment.modified && (
                    <>
                      / {t('Modified')}: {formatDate(comment.modified)}, {comment.modifiedBy}
                    </>
                  )}
                </CommentInfo>
              </>
            )}
          </ChatBubble>
        );
      })}
    </SubSection>
  );
};

ServiceOrderComments.defaultProps = {
  loading: false,
  comments: [],
};

ServiceOrderComments.propTypes = {
  t: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      serviceOrderNumber: PropTypes.string,
      eventType: PropTypes.string,
      status: PropTypes.string,
      comment: PropTypes.string,
      created: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  profile: PropTypes.shape({
    id: PropTypes.string,
    role: PropTypes.string,
  }),
  editServiceModuleComment: PropTypes.func,
  statusColors: PropTypes.object,
};

export default ServiceOrderComments;
