import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import CalendarStatusTag from 'components/Calendar/CalendarStatusTag/CalendarStatusTag';
import { OrderStatusColors } from 'constants/maintenance';

import FunctionalLocationHeader from 'components/FunctionalLocationHeader/FunctionalLocationHeader';

export const getStatusTag = (status, statusColors, t) => (
  <CalendarStatusTag
    status={status}
    statusColors={statusColors ? statusColors : OrderStatusColors}
    text={t(status)}
    showFullText
  />
);

export const ServiceOrderHeader = props => {
  const { t, status, status2, statusColors } = props;
  return (
    <FunctionalLocationHeader
      {...props}
      statusTag={status ? getStatusTag(status, statusColors, t) : null}
      statusTag2={status2 ? getStatusTag(status2, statusColors, t) : null}
    />
  );
};

ServiceOrderHeader.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string,
  status: PropTypes.string,
  status2: PropTypes.string,
  statusColors: PropTypes.object,
  functionalLocation: PropTypes.shape({
    name: PropTypes.string,
    functionalLocation: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
  }),
  hideOnMobile: PropTypes.bool,
  buttons: PropTypes.node,
};

export default translations(ServiceOrderHeader);
