import styled from 'styled-components';

export const PortalContainer = styled.div`
  z-index: ${props => props.theme.zIndex('modal') + 1};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Popover = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const Annotation = styled.div`
  padding: 13px 14px 13px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;
