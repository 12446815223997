import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { connect } from 'react-redux';

import { saveProfile } from 'redux/modules/index';
import TableConfiguration from 'components/TableConfiguration/TableConfiguration';
import translations from 'decorators/Translations/translations';
import withNotification from 'decorators/Notification/withNotification';

const FunctionalLocationListConfiguration = props => {
  const { t, performanceOptions, profile, saveProfile, selectedPerformances, showNotification } = props;

  const onSubmit = model => {
    const newConfiguration = map(
      filter(performanceOptions, option => model[option.value] && model[option.value].selected),
      'value'
    );
    return saveProfile(profile.id, { ...profile, buildingPerformanceSources: newConfiguration })
      .then(() => {
        showNotification({ type: 'success', message: t('Saved'), autoHide: true });
      })
      .catch(() => {
        showNotification({ type: 'error', message: t('Save Failed'), autoHide: true });
      });
  };

  return (
    <TableConfiguration
      onSubmit={onSubmit}
      configurationOptions={performanceOptions.map(option => ({ ...option, accessor: option.value }))}
      configuration={selectedPerformances}
    />
  );
};

FunctionalLocationListConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
  performanceOptions: PropTypes.array.isRequired,
  selectedPerformances: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  saveProfile: PropTypes.func.isRequired,
  showNotification: PropTypes.func,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  saveProfile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withNotification(translations(connector(FunctionalLocationListConfiguration)));
