import MasterDataService from 'services/masterData';
import DocumentService from 'services/document';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

const getFileUploadErrorInformation = (error, file) => ({
  checklistReportItemId: file.checklistReportItemId,
  message: file.filename,
  error,
});

const getFileForUpload = (item, file, functionalLocationId) => ({
  ...file,
  checklistReportId: item.checklistReportId,
  checklistReportItemId: item.id,
  functionalLocation: functionalLocationId,
  createThumbnail: true,
});

const uploadItemFile = (file, token) => {
  const uploadPromise = token ? DocumentService.uploadFileWithToken({ file, token }) : DocumentService.uploadFile(file);
  return uploadPromise.catch(error => getFileUploadErrorInformation(error, file));
};

export const createAndUpdateChecklistReportItemsWithFiles = async ({
  checklistReportId,
  reportItems,
  functionalLocationId,
  token,
}) => {
  const createItems = [];
  const updateItems = [];
  // sort report items for create/update
  reportItems.forEach(reportItem => {
    if (reportItem.id) {
      updateItems.push(reportItem);
    } else if (reportItem.selectedChecklistTemplateOptionId) {
      createItems.push(reportItem);
    }
  });

  const results = [];
  const fileResults = [];

  // CREATE
  if (createItems.length) {
    const createItemsWithoutFiles = createItems.map(item => omit(item, ['files']));
    const files = createItems.map(item => item.files || []);

    const creationPromise = token
      ? MasterDataService.createChecklistReportItemsWithToken(token, checklistReportId, createItemsWithoutFiles)
      : MasterDataService.createChecklistReportItems(checklistReportId, createItemsWithoutFiles);

    results.push(
      creationPromise.then(items => {
        // upload files per item
        items.forEach((item, index) => {
          // expect response to preserve ordering, so that we can get the item id for files
          const itemFiles = files[index];
          const itemFilesForUpload = itemFiles.map(file => getFileForUpload(item, file, functionalLocationId));

          // push file upload promises to fileResults
          fileResults.push(...itemFilesForUpload.map(file => uploadItemFile(file, token)));
        });

        return items;
      })
    );
  }
  // UPDATE
  if (updateItems.length) {
    const updateItemsWithoutFiles = updateItems.map(item => omit(item, ['files']));
    const filesForUpload = flatten(
      updateItems.map(item => item.files?.map(file => getFileForUpload(item, file, functionalLocationId)) || [])
    );

    const uploadPromise = token
      ? MasterDataService.updateChecklistReportItemsWithToken(token, checklistReportId, updateItemsWithoutFiles)
      : MasterDataService.updateChecklistReportItems(checklistReportId, updateItemsWithoutFiles);

    results.push(
      uploadPromise.then(items => {
        // push file upload promises to fileResults
        fileResults.push(...filesForUpload.map(file => uploadItemFile(file, token)));

        return items;
      })
    );
  }

  // await for results
  const allItems = flatten(await Promise.all(results));
  const allFiles = await Promise.all(fileResults);

  // add uploaded and errored files to items
  const itemsWithFiles = allItems
    .filter(item => !!item)
    .map(item => ({
      ...item,
      uploadedFiles: allFiles.filter(file => file.checklistReportItemId === item.id && !file.error),
      erroredFiles: allFiles.filter(file => file.checklistReportItemId === item.id && file.error),
    }));

  return itemsWithFiles;
};

export const createChecklistReportWithFiles = async ({ checklistReport, functionalLocationId, token }) => {
  const reportWithoutFiles = {
    ...checklistReport,
    reportItems: checklistReport.reportItems.map(item => omit(item, ['files'])),
  };
  const files = checklistReport.reportItems.map(item => item.files || []);

  let reportResult;

  if (token) {
    reportResult = await MasterDataService.createChecklistReportWithToken(token, reportWithoutFiles);
  } else {
    reportResult = await MasterDataService.createChecklistReport(reportWithoutFiles);
  }

  const fileResults = flatten(
    await Promise.all(
      // upload files per item
      reportResult.reportItems.map((item, index) => {
        // expect response to preserve ordering, so that we can get the item id for files
        const itemFiles = files[index];
        const itemFilesForUpload = itemFiles.map(file => getFileForUpload(item, file, functionalLocationId));

        return Promise.all(itemFilesForUpload.map(file => uploadItemFile(file, token)));
      })
    )
  );

  const itemsWithFiles = reportResult.reportItems.map(item => ({
    ...item,
    uploadedFiles: fileResults.filter(file => file.checklistReportItemId === item.id && !file.error),
    erroredFiles: fileResults.filter(file => file.checklistReportItemId === item.id && file.error),
  }));

  return { ...reportResult, reportItems: itemsWithFiles };
};

export const downloadFile = async (file, inline) => {
  const result = await DocumentService.downloadUrl({
    fileId: file.id,
    functionalLocation: file.functionalLocation,
    inline,
  });
  if (inline) {
    window.open(result.sasUrl, 'name');
  } else {
    window.location.href = result.sasUrl;
  }
};

export const downloadFileWithToken = async (token, checklistReportId, file, inline) => {
  const result = await DocumentService.downloadUrlForServiceOrderWithToken({
    fileId: file.id,
    token,
    checklistReportId,
  });
  if (inline) {
    window.open(result.sasUrl, 'name');
  } else {
    window.location.href = result.sasUrl;
  }
};
