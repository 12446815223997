import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import { OUTDOOR_TYPE, WEATHER_TYPE } from 'utils/Data/values';
import { getFilterForSensorsValues } from './sensor_values';

export const initialState = {
  conditions: {},
  loadingConditions: false,
};

export const LOAD_CONDITIONS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS';
export const LOAD_CONDITIONS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_SUCCESS';
export const LOAD_CONDITIONS_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONDITIONS_FAIL';

export const loadBuildingConditions = (functionalLocation, sensors, startTime, endTime) => {
  const sensorIds = sensors.map(sensor => sensor.id);
  // TODO: aggregation needs to be dynamic from the sensor.analytics , if has hourlyAverage analytics, use that, otherwise pick all values.
  const filter = getFilterForSensorsValues(sensorIds, startTime, endTime, 'hourlyAverage');

  return async dispatch => {
    dispatch({ type: LOAD_CONDITIONS });

    if (!sensors || sensors.length === 0) {
      return dispatch({
        type: LOAD_CONDITIONS_FAIL,
      });
    }

    try {
      const response = await IoTService.sensorValuesFind(filter);

      return dispatch({
        type: LOAD_CONDITIONS_SUCCESS,
        result: response.data,
        sensors,
        functionalLocation,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CONDITIONS_FAIL,
        error,
      });
    }
  };
};

const mapConditionsData = (conditions, sensors) => {
  const mapData = (accu, value) => {
    const sensor = sensors?.find(sensor => sensor.id === parseInt(value.sensorId, 10));
    if (sensor?.sensorType) {
      accu.push({
        avg: Math.round(value.value),
        // map weather types to outdoor type
        sensorName: sensor.sensorType.name === WEATHER_TYPE ? OUTDOOR_TYPE : sensor.sensorType.name,
        unit: value.unit,
        timestamp: value.timestamp,
        sensorId: value.sensorId,
      });
    }
    return accu;
  };
  return groupBy(orderBy(conditions.reduce(mapData, []), 'timestamp', 'asc'), row => row.sensorName);
};

export default createReducerFromMapping(
  {
    [LOAD_CONDITIONS]: (state, action) => ({
      ...state,
      loadingConditions: true,
    }),
    [LOAD_CONDITIONS_SUCCESS]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        [action.functionalLocation]: mapConditionsData(action.result, action.sensors),
      },
      loadingConditions: false,
    }),
    [LOAD_CONDITIONS_FAIL]: (state, action) => ({
      ...state,
      loadingConditions: false,
    }),
  },
  initialState
);
