import filter from 'lodash/filter';
import includes from 'lodash/includes';
import memoizeOne from 'memoize-one';
import {
  OriginCell,
  DescriptionCell,
  LocationCell,
  StatusCell,
  TypeCell,
  ImprovementCell,
  DateCell,
  YearCell,
  TranslatedCell,
  SavingsCell,
  CostCell,
} from './ObservationsCells';

export const getColumns = memoizeOne((t, noLocations) =>
  [
    {
      field: 'origin',
      accessor: 'origin',
      sortable: false,
      Cell: OriginCell,
    },
    {
      title: t('Location'),
      accessor: 'location',
      field: 'functionalLocationName',
      sortable: true,
      Cell: LocationCell,
    },
    {
      title: t('Description'),
      accessor: 'title',
      field: 'location',
      sortable: true,
      Cell: DescriptionCell,
    },
    {
      title: t('Status'),
      accessor: 'status',
      field: 'statusValue',
      sortable: true,
      width: '180px',
      align: 'left',
      Cell: StatusCell,
    },
    {
      title: t('Discipline'),
      accessor: 'discipline',
      field: 'disciplineValue',
      sortable: true,
      width: '100px',
      align: 'left',
      Cell: TypeCell,
    },
    {
      title: t('Action type'),
      accessor: 'actionType',
      field: 'actionType',
      sortable: true,
      Cell: TranslatedCell,
    },
    {
      title: t('Has energy impact'),
      accessor: 'energyImpact',
      field: 'energyImpact',
      sortable: true,
    },
    {
      title: t('Has recycle impact'),
      accessor: 'wasteObservation',
      field: 'wasteObservation',
      sortable: true,
    },
    {
      title: t('Impact'),
      accessor: 'impact',
      field: 'savingsPotentialSum',
      sortable: true,
      Cell: ImprovementCell,
    },
    {
      title: t('Heating impact'),
      accessor: 'heatingImpact',
      field: 'savingsPotentialHeat',
      sortable: true,
      Cell: ImprovementCell,
    },
    {
      title: t('Electricity impact'),
      accessor: 'electricityImpact',
      field: 'savingsPotentialElectricity',
      sortable: true,
      Cell: ImprovementCell,
    },
    {
      title: t('Water impact'),
      accessor: 'waterImpact',
      field: 'savingsPotentialWater',
      sortable: true,
      Cell: ImprovementCell,
    },
    {
      title: t('Yearly heating savings'),
      accessor: 'heatSavingsYearlySum',
      field: 'heatSavingsYearlySum',
      sortable: true,
      Cell: SavingsCell,
    },
    {
      title: t('Yearly electricity savings'),
      accessor: 'electricitySavingsYearlySum',
      field: 'electricitySavingsYearlySum',
      sortable: true,
      Cell: SavingsCell,
    },
    {
      title: t('Yearly water savings'),
      accessor: 'waterSavingsYearlySum',
      field: 'waterSavingsYearlySum',
      sortable: true,
      Cell: SavingsCell,
    },
    {
      title: t('Yearly other savings'),
      accessor: 'otherSavingsYearlySum',
      field: 'otherSavingsYearlySum',
      sortable: true,
      Cell: SavingsCell,
    },
    {
      title: t('Yearly total savings'),
      accessor: 'totalSavingsYearlySum',
      field: 'totalSavingsYearlySum',
      sortable: true,
      Cell: SavingsCell,
    },
    {
      title: t('Heating savings price'),
      accessor: 'savingsHeatPrice',
      field: 'savingsHeatPrice',
      sortable: true,
      Cell: CostCell,
    },
    {
      title: t('Electricity savings price'),
      accessor: 'savingsElectricityPrice',
      field: 'savingsElectricityPrice',
      sortable: true,
      Cell: CostCell,
    },
    {
      title: t('Water savings price'),
      accessor: 'savingsWaterPrice',
      field: 'savingsWaterPrice',
      sortable: true,
      Cell: CostCell,
    },
    {
      title: t('Investment'),
      accessor: 'savingsCost',
      field: 'savingsCost',
      sortable: true,
      Cell: CostCell,
    },
    {
      title: t('Life span'),
      accessor: 'savingsLifeSpan',
      field: 'savingsLifeSpan',
      sortable: true,
      Cell: YearCell,
    },
    {
      title: t('Payback period'),
      accessor: 'paybackPeriod',
      field: 'paybackPeriod',
      sortable: true,
      Cell: YearCell,
    },
    {
      title: t('Created'),
      accessor: 'date',
      field: 'date',
      sortable: true,
      width: '150px',
      align: 'left',
      Cell: DateCell,
    },
    {
      title: t('Start time'),
      accessor: 'startTime',
      field: 'startTime',
      sortable: true,
      width: '150px',
      align: 'left',
      Cell: DateCell,
    },
    {
      title: t('Modified'),
      accessor: 'modifiedOn',
      field: 'modifiedOn',
      sortable: true,
      width: '150px',
      align: 'left',
      Cell: DateCell,
    },
    {
      title: t('Estimated execution date'),
      accessor: 'estimatedExecutionDate',
      field: 'estimatedExecutionDate',
      sortable: true,
      width: '150px',
      align: 'left',
      Cell: DateCell,
    },
  ].filter(column => !noLocations || column.accessor !== 'location')
);

export const filterConfiguredColumns = memoizeOne((columns, configuration) =>
  filter(columns, column => includes(configuration, column.accessor))
);
