import styled from 'styled-components';
import BaseButton from 'components/Button/BaseButton/BaseButton';

export const PortalContainer = styled.div`
  z-index: ${props => props.theme.zIndex('modal') + 1};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Popover = styled.div`
  z-index: 10;
  max-width: 300px;
  overflow: auto;
  padding-bottom: var(--size-sm);
`;

export const Annotation = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-bottom: var(--size-xs);
  padding: var(--size-sm);
  border: 1px solid ${props => props.theme.colors.lightGray};
  min-width: 180px;
`;

export const Comment = styled.div`
  cursor: ${props => (props.allowAnnotationsAdminTools ? 'pointer' : 'default')};
  font-size: ${props => props.theme.font.size.xs};
  padding-bottom: var(--size-sm);
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Time = styled.div`
  color: ${props => props.theme.colors.darkGray};
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.xxs};
`;

export const Delete = styled(BaseButton)`
  padding: 0;
  min-width: 0;
  color: ${props => props.theme.colors.radicalRed};
  height: auto;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
