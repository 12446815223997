import { useMemo } from 'react';
import { partition, isEmpty } from 'lodash';
import { getComparisonSeries, getReferenceSeries } from './utils';

const useSeriesPartition = (sensorData, reference, intervalType, pointStart, chartType) => {
  const baseSeries = useMemo(() => {
    if (!sensorData) {
      return [];
    }

    const sensorUnit = sensorData?.[0]?.sensors?.[0]?.sensorType?.unit;
    const referenceSeries = getReferenceSeries(reference, intervalType, pointStart, sensorUnit);
    const series = sensorData.flatMap(data =>
      getComparisonSeries(data.comparisonData, data.sensors, intervalType, chartType)
    );

    const [baseSeries, outdoorTemperatureSeries] = partition(series, s => !s.isOutdoorTemperature);

    if (!isEmpty(referenceSeries) && !isEmpty(series)) {
      baseSeries.push(referenceSeries);
    }
    return [baseSeries, outdoorTemperatureSeries];
  }, [sensorData, reference, intervalType, pointStart, chartType]);

  return baseSeries;
};
export default useSeriesPartition;
