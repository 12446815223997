import React from 'react';
import useQuery from 'decorators/Query/useQuery';

const getFilterOptionsFromQuery = filter => {
  if (Array.isArray(filter)) {
    return filter;
  }
  return filter ? [filter] : [];
};

const useObservationsFilters = () => {
  const { query, setQuery } = useQuery();

  const onChangeFilter = (key, value) => {
    const queryChange = { [key]: value === '' ? undefined : value }; // omit empty string from query
    setQuery(queryChange);
  };

  return React.useMemo(
    () => ({
      filters: {
        year: query.year ? Number(query.year) : undefined,
        month: query.month ? Number(query.month) : undefined,
        investmentSumField: query.investmentSumField ?? '',
        status: getFilterOptionsFromQuery(query.status),
        subStatus: getFilterOptionsFromQuery(query.subStatus),
        dateFilterField: query.dateFilterField ?? '',
        discipline: getFilterOptionsFromQuery(query.discipline),
        impact: getFilterOptionsFromQuery(query.impact),
        change: getFilterOptionsFromQuery(query.change),
        createdBy: getFilterOptionsFromQuery(query.createdBy),
        functionalLocation: getFilterOptionsFromQuery(query.functionalLocation),
      },
      onChangeFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      query.year,
      query.month,
      query.investmentSumField,
      query.status,
      query.subStatus,
      query.dateFilterField,
      query.discipline,
      query.impact,
      query.change,
      query.createdBy,
      query.functionalLocation,
    ]
  );
};

export default useObservationsFilters;
