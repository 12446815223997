import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { IOT_PATH, SERVICE_API_PATH } from './index';
import ApiRequest from 'utils/Fetch/ApiRequest';
import qs from 'qs';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';

export const sensorValuesFind = ({ sensorIds, aggregation, timestamp }) => {
  const query = qs.stringify({ sensorIds, aggregation, timestamp }, { arrayFormat: 'comma' });
  return ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-values?${query}`,
  });
};

export const findForPeriodFn = (request, filter) =>
  request.post({
    apiPath: `${IOT_PATH}/values/findForPeriod`,
    data: { ...filter },
  });

export const sensorsLatestValues = (sensorIds, aggregation) =>
  ApiRequest.post({
    apiPath: `${IOT_PATH}/values/latest`,
    data: { sensorIds, aggregation },
    useAuthorization: true,
  });

export const energyRatingFn = (request, filter) =>
  request.post({
    apiPath: `${IOT_PATH}/values/energyRating`,
    data: { filter },
  });

export const findBooleanIntervalsFn = (request, { start, end, maxFrequency, aggregation, sensorIds }) =>
  request.post({
    apiPath: `${IOT_PATH}/values/findBooleanIntervals`,
    data: {
      start,
      end,
      maxFrequency,
      aggregation,
      sensorIds,
    },
  });

export const exportValuesFn = (request, task) =>
  request.post({
    apiPath: `${IOT_PATH}/values/export`,
    data: task,
    noCache: true,
  });

export const sensorValuesWithPublicView = query =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-values/with-public-view`,
    query,
    noCache: true,
    useAuthorization: false,
  });

export const firstValueLimit = sensorIds =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-values/first-value-limit`,
    query: { sensorIds },
  });

export const importGenericSensorValues = async ({ values, aggregateInfo }) => {
  const result = await ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/sensor-values/import/generic`,
    data: { values, aggregateInfo },
  });
  await invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/sensor-values`);
  return result;
};

export const importWasteSensorValues = async ({ type, partnerNumber, functionalLocation, values, aggregateInfo }) => {
  const result = await ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/sensor-values/import/waste`,
    data: { type, partnerNumber, functionalLocation, values, aggregateInfo },
  });
  await invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/sensor-values`);
  return result;
};

export default wrapEachWithThunkRequest({
  energyRating: energyRatingFn,
  findBooleanIntervals: findBooleanIntervalsFn,
  exportValues: exportValuesFn,
  findForPeriod: findForPeriodFn,
});
