import { isCachingPossible } from './cache';
import ApiError from './ApiError';
import { handleUnauthorizedRequest } from 'services/profile/sessionUtils';

export const jsonToUriComponent = object => encodeURIComponent(JSON.stringify(object));

export const CLIENT_CLOCK_ERROR_MARGIN_MS = 5 * 60 * 1000;

export const isSessionValid = session => {
  if (!session.accessTokenExpiresAt) {
    return false;
  }
  return (Date.now() + CLIENT_CLOCK_ERROR_MARGIN_MS) < new Date(session.accessTokenExpiresAt).getTime();
};

export const getErrorHandler = options => async response => {
  if (response && response.ok === false) {
    let parsedErrorResponseBody;
    try {
      parsedErrorResponseBody = await handleJsonResponse(response);
    } catch (error) {
      console.error('Could not parse error body', error);
    }
    if (response.status === 401 && options.enableSessionInvalidation) {
      handleUnauthorizedRequest();
    }
    throw new ApiError(parsedErrorResponseBody, response.status, response.statusText);
  }
  return response;
};

export const handleJsonResponse = response => {
  const contentType = response?.headers?.get('content-type');
  if (contentType?.includes('application/json') && response.status !== 204) {
    return response.json();
  }
  return response;
};

export const getRequestCacheMode = (method, options) => {
  if (options.noCache || isCachingPossible(method)) {
    return 'no-store';
  }
  return 'default';
};

export const createRequestBody = data => {
  if (!data) {
    return undefined;
  }
  if (typeof data === 'string') {
    return data;
  }
  return JSON.stringify(data);
};

export const getFilenameFromResponse = response => {
  const contentDisposition = response?.headers?.get('content-disposition');
  if (contentDisposition) {
    return contentDisposition
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .replace(/"/g, '')
      .trim();
  }
};

export const downloadBlob = (blob, filename, windowName) => {
  // https://stackoverflow.com/a/44210290
  if (window.navigator?.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const blobUrl = URL.createObjectURL(blob);

    if (windowName) {
      window.open(blobUrl, windowName);
    } else {
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = blobUrl.filename || filename || blob.name || 'download';
      link.click();
      setTimeout(() => URL.revokeObjectURL(blobUrl), 0);
    }
  }
};
