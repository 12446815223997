import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import {
  getPartnerNumber,
  getFunctionalLocationId,
  getBuildingHierarchy,
  getFunctionalLocations,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { sensorTypeNameToWasteType } from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { exportSensorData } from 'redux/modules/iot/exportSensorData';
import { TYPE_SORT, SENSOR_SORT } from 'components/Conditions/ConditionUtils';

const getSubmitting = state => state.exportSensorData.loading;

const hasSensors = child => child.sensors?.length > 0;

const mapFunctionalLocationToSensor = (sensor, functionalLocation) => ({ ...sensor, functionalLocation });

const mapSensorToSensorOption = sensor => ({
  id: sensor.id,
  functionalLocationId: sensor.functionalLocation,
  sensorTypeId: sensor.sensorType?.id,
  sensorTypeName: sensor.sensorType?.name,
});

const filterHierarchyByPartnerNumber = (building, fls, partnerNumber) => {
  const fl = fls[building.functionalLocation];
  if (!fl) {
    return false;
  }
  return flBelongsToPartner(fl, partnerNumber);
};

const flBelongsToPartner = (fl, partnerNumber) => fl.partnerNumber.some(flPartnerNumber => flPartnerNumber === partnerNumber);

const getSensors = (state, props) => {
  const partnerNumber = getPartnerNumber(state, props);
  const functionalLocationId = getFunctionalLocationId(state, props);
  const buildingHierarchy = getBuildingHierarchy(state, props);
  const functionalLocations = getFunctionalLocations(state, props);
  if (buildingHierarchy && !isEmpty(buildingHierarchy)) {
    let rootSensors = [];
    let childrenWithSensors = [];
    if (functionalLocationId) {
      // building view
      rootSensors = buildingHierarchy[functionalLocationId][0].sensors
        ?.filter(props.sensorFilterFn)
        .map(sensor => mapFunctionalLocationToSensor(sensor, functionalLocationId));
      childrenWithSensors = buildingHierarchy[functionalLocationId][0].children
        ?.filter(hasSensors)
        .map(child => ({
          ...child,
          sensors: child.sensors
            ?.filter(props.sensorFilterFn)
            .map(sensor => mapFunctionalLocationToSensor(sensor, functionalLocationId))
        }));
    } else {
      // portfolio view
      rootSensors = Object.keys(buildingHierarchy).flatMap(
        functionalLocationId => {
          const building = buildingHierarchy[functionalLocationId][0];
          const fl = functionalLocations[building.functionalLocation];
          if (flBelongsToPartner(fl, partnerNumber)) {
            return building.sensors
              ?.filter(props.sensorFilterFn)
              .map(sensor => mapFunctionalLocationToSensor(sensor, functionalLocationId));
          }
          return [];
        }
      );
      childrenWithSensors = Object.keys(buildingHierarchy).flatMap(
        functionalLocationId => {
          const building = buildingHierarchy[functionalLocationId][0];
          if (building.partnerNumber === partnerNumber) {
            return building.children
              ?.filter(hasSensors)
              .map(child => ({
                ...child,
                sensors: child.sensors
                  ?.filter(props.sensorFilterFn)
                  .map(sensor => mapFunctionalLocationToSensor(sensor, functionalLocationId))
              }));
          }
          return [];
        }
      );
    }
    const childrenSensors = childrenWithSensors.flatMap(child => child.sensors);
    const combinedSensors = [...rootSensors, ...childrenSensors];
    const uniqueSensors = uniqBy(combinedSensors, sensor => [sensor.id, sensor.parentId].join());
    const sensors = sortBy(uniqueSensors, SENSOR_SORT);
    return sensors.map(mapSensorToSensorOption);
  }
  return [];
};

const getBuildingOptions = (state, props) => {
  const partnerNumber = getPartnerNumber(state, props);
  const functionalLocationId = getFunctionalLocationId(state, props);
  const buildingHierarchy = getBuildingHierarchy(state, props);
  const functionalLocations = getFunctionalLocations(state, props);
  if (buildingHierarchy && !isEmpty(buildingHierarchy) && !isEmpty(functionalLocations)) {
    if (functionalLocationId) {
      // building view
      return null;
    }
    // portfolio view
    const buildingsWithWasteSensors = Object.keys(buildingHierarchy)
      .map(functionalLocationId => buildingHierarchy[functionalLocationId][0])
      .filter(building => filterHierarchyByPartnerNumber(building, functionalLocations, partnerNumber))
      .filter(building => building.sensors.some(props.sensorFilterFn));
    const buildingOptions = buildingsWithWasteSensors.map(building => ({
      value: building.functionalLocation,
      label: functionalLocations[building.functionalLocation].description,
    }));
    return sortBy(buildingOptions, 'label');
  }
  return [];
};


const getSensorTypeOptions = (state, props) => {
  const sensors = getSensors(state, props);
  if (sensors && !isEmpty(sensors)) {
    const sensorTypeOptions = sensors.map(sensor => ({
      value: sensor.sensorTypeId,
      label: sensorTypeNameToWasteType(sensor.sensorTypeName),
    }));
    return sortBy(uniqBy(sensorTypeOptions, 'value'), TYPE_SORT);
  }
  return [];
};

export const mapStateToProps = (state, props) => ({
  sensors: getSensors(state, props),
  buildingOptions: getBuildingOptions(state, props),
  sensorTypeOptions: getSensorTypeOptions(state, props),
  submitting: getSubmitting(state),
});

const mapDispatchToProps = {
  exportSensorData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
