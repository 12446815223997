import React from 'react';
import { useSelector } from 'react-redux';
import { getBuildingSensorHierarchies } from 'redux/modules/customer/sensorHierarchy.selectors';
import { getBuildingSensorsFromHierarchy } from 'utils/Data/sensorHierarchy';
import { Sensor, SensorHierarchy } from 'types/SensorHierarchy';

const EMPTY_ARRAY: SensorHierarchy[] = [];

const useBuildingSensors = (functionalLocationId: string) => {
  const sensorHierarchy: SensorHierarchy[] = useSelector(
    state => getBuildingSensorHierarchies(state, { functionalLocationId }) ?? EMPTY_ARRAY
  );
  return React.useMemo<Sensor[]>(() => getBuildingSensorsFromHierarchy(sensorHierarchy[0]), [sensorHierarchy]);
};

export default useBuildingSensors;
