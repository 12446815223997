import React, { PureComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';
import get from 'lodash/get';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isNumber from 'lodash/isNumber';
import parseISO from 'date-fns/parseISO';
import min from 'date-fns/min';
import toDate from 'date-fns/toDate';
import subMilliseconds from 'date-fns/subMilliseconds';
import styled, { withTheme, keyframes } from 'styled-components';
import { transparentize } from 'polished';
import Responsive from '../Responsive/Responsive';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';
import { tooltipFormatter, getCommonExportingOptions, getCommonNavigationOptions, buildZonesForNulls } from './utils';
import { generateTresholds } from '../../utils/Data/performance';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';

accessibility(Highcharts);

const fadeIn = keyframes`
    0%      { opacity: 0 }
    50%     { opacity: 0 }
    100%    { opacity: 1 }
`;

const StyledSensorChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: top 280ms ease-out, width 280ms ease-in, height 280ms ease-out;

  .performance-chart {
    opacity: 1;
    animation-duration: 560ms;
    animation-iteration-count: 1;
    animation-name: ${fadeIn};
    animation-fill-mode: forwards;
  }
`;

const thresholdOptions = {
  marker: {
    enabled: false,
  },
  enableMouseTracking: false,
  showInLegend: false,
  states: {
    inactive: {
      opacity: 1,
    },
  },
  zIndex: 2,
  includeInDataExport: false,
};

/**
 * Generate suitable series to represent limits. Plot bands and lines only support constant values, so the limits are
 * constructed from arearange and area series. Arearange is used to highlight the values in within the limits, while
 * area series are used to highlight red areas (limit -> ±infinity).
 */
const getThresholdSeries = memoizeOne((theme, thresholds) => {
  const series = [];
  const { infiniteMin, infiniteMax } = thresholds;
  if (infiniteMin && infiniteMax) {
    return series;
  }

  if (!infiniteMin && !infiniteMax) {
    series.push({
      ...thresholdOptions,
      type: 'arearange',
      lineWidth: 1,
      lineColor: theme.colors.emerald,
      dashStyle: 'dash',
      fillColor: transparentize(0.95, theme.colors.emerald),
      data: thresholds.within,
    });

    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.NEGATIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.radicalRed),
      lineWidth: 0,
      data: thresholds.below,
    });

    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.POSITIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.radicalRed),
      lineWidth: 0,
      data: thresholds.above,
    });
  }

  if (infiniteMin) {
    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.NEGATIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.emerald),
      lineWidth: 1,
      lineColor: theme.colors.emerald,
      dashStyle: 'dash',
      data: thresholds.above,
    });

    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.POSITIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.radicalRed),
      lineWidth: 1,
      lineColor: theme.colors.emerald,
      dashStyle: 'dash',
      data: thresholds.above,
    });
  }

  if (infiniteMax) {
    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.POSITIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.emerald),
      lineWidth: 0,
      data: thresholds.below,
    });

    series.push({
      ...thresholdOptions,
      type: 'area',
      threshold: Number.NEGATIVE_INFINITY,
      fillColor: transparentize(0.95, theme.colors.radicalRed),
      lineWidth: 0,
      data: thresholds.below,
    });
  }

  return series;
});

/**
 * Find extremes from the dataset.
 */
const analyzeSeries = memoizeOne(data => {
  const minTimestamp = minBy(data, 'timestamp');
  const maxTimestamp = maxBy(data, 'timestamp');
  const minValue = minBy(data, 'value');
  const maxValue = maxBy(data, 'value');
  return {
    minTimestamp: minTimestamp ? minTimestamp.timestamp : undefined,
    maxTimestamp: maxTimestamp ? maxTimestamp.timestamp : undefined,
    minValue: minValue ? minValue.value : undefined,
    maxValue: maxValue ? maxValue.value : undefined,
  };
});

class PerformanceChart extends PureComponent {
  renderChart() {
    const {
      width,
      height,
      dimensions,
      loading,
      histories,
      sensorNames,
      unit,
      sensorConfigurations,
      maxX,
      minXRange,
      theme,
      t,
      yAxisTitle,
      getThreshold,
      thresholdGranularity,
      yMin,
      yMax,
      yFormatter,
      precision,
      isBooleanType,
    } = this.props;

    // Use 1.) explicit width and height, or 2.) Dimensions from Responsive, or 3.) Highcharts default.
    let chartWidth = width || dimensions?.width;
    if (chartWidth > window.innerWidth) {
      chartWidth = window.innerWidth;
    }
    const chartHeight = height || dimensions?.height;

    const hasHistories = histories !== undefined;
    const noDataMessage = hasHistories && !histories.length ? t('No data available') : '';
    const spinnerToShow = loading || !hasHistories ? <Loader color="BLUE" size="LARGE" /> : null;

    const skeletonContent = spinnerToShow || noDataMessage;

    if (skeletonContent) {
      return (
        <SkeletonChart width={chartWidth ? `${chartWidth}px` : null} height={chartHeight ? `${chartHeight}px` : null}>
          {skeletonContent}
        </SkeletonChart>
      );
    }

    // Take the first sensor name and unit for the series name since they are all the same.
    const sensorName = get(sensorNames, [histories[0].sensorName]) || histories[0].sensorName;
    const sensorUnit = unit || get(sensorConfigurations, [histories[0].sensorName, 'unit']) || '';

    // Find extremes from the data set
    const stats = analyzeSeries(histories);
    const end = maxX ?? parseISO(stats.maxTimestamp).valueOf();
    const start = (
      minXRange
        ? min([subMilliseconds(toDate(end), minXRange), parseISO(stats.minTimestamp)])
        : parseISO(stats.minTimestamp)
    ).valueOf();

    const thresholds = generateTresholds(start, end, getThreshold, thresholdGranularity);
    const seriesData = map(histories, point => [
      parseISO(point.timestamp).valueOf(),
      point.avg === undefined ? point.value : point.avg,
    ]);
    const series = [
      {
        name: sensorName,
        type: isBooleanType ? 'line' : 'spline',
        data: seriesData,
        yAxis: 0,
        zIndex: 2,
        color: this.props.theme.colors.midnight,
        singleLine: true,
        unit: sensorUnit,
        step: isBooleanType ? 'left' : undefined,
        zones: buildZonesForNulls(seriesData),
        zoneAxis: 'x',
        connectNulls: true,
      },
      ...getThresholdSeries(theme, thresholds),
    ];

    const config = {
      annotations: this.props.annotations,
      chart: {
        zoomType: 'x',
        backgroundColor: 'transparent',
        plotBackgroundColor: theme.colors.white,
        spacing: [8, 8, 16, 8],
        width: chartWidth,
        height: chartHeight,
        className: 'performance-chart',
        marginTop: 55,
        marginRight: 10,
        spacingLeft: 30,
        spacingBottom: 20,
        events: {
          click: this.props.onClick,
          selection: this.props.onSelection,
        },
      },
      title: false,
      credits: false,
      tooltip: {
        split: true,
        shared: false,
        crosshairs: true,
        borderWidth: 0,
        padding: 0,
        backgroundColor: null,
        useHTML: true,
        formatter: function () {
          return tooltipFormatter(
            this.points,
            this.x,
            theme,
            chartHeight - 60,
            'no temperature',
            undefined,
            undefined,
            precision
          );
        },
      },
      plotOptions: {
        spline: {
          lineColor: theme.colors.midnight,
          lineWidth: 2,
          turboThreshold: 400, // Make rendering large series (> 400) more efficient.
          marker: {
            enabled: false,
            lineColor: theme.colors.midnight,
            lineWidth: 2,
            radius: 5,
            fillColor: this.props.theme.colors.white,
            symbol: 'circle',
            states: {
              normal: {
                animation: {
                  duration: 50,
                },
              },
              hover: {
                lineWidthPlus: 0,
                radiusPlus: 0,
                shadow: false,
                animation: { duration: 0 },
              },
            },
          },
          states: {
            normal: {
              animation: {
                duration: 50,
              },
            },
            hover: {
              lineWidthPlus: 0,
              halo: { size: 0 },
            },
          },
        },
      },
      legend: false,
      xAxis: {
        type: 'datetime',
        lineWidth: 0,
        lineColor: theme.colors.mystic,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        gridLineColor: theme.colors.mystic,
        tickLength: 5,
        labels: {
          y: 25,
          style: {
            color: theme.colors.rockBlue,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontWeight: theme.font.weight.bold,
          },
        },
        min: start,
        max: end,
      },
      yAxis: {
        min: isNumber(yMin) ? yMin : null,
        max: isNumber(yMax) ? yMax : null,
        lineWidth: 0,
        lineColor: theme.colors.mystic,
        labels: {
          formatter: function () {
            if (typeof yFormatter === 'function') {
              return yFormatter(this.value);
            }
            return this.value;
          },
          style: {
            color: theme.colors.rockBlue,
            textTransform: 'uppercase',
            letterSpacing: '1px',
            fontWeight: theme.font.weight.bold,
          },
        },
        title: {
          align: 'high',
          offset: 0,
          text: yAxisTitle || sensorUnit,
          rotation: 0,
          y: -20,
          style: {
            color: theme.colors.black,
            fontWeight: theme.font.weight.bold,
          },
        },
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        gridLineColor: theme.colors.mystic,
      },
      series: series,
      exporting: getCommonExportingOptions({ title: this.props.title }),
      navigation: getCommonNavigationOptions(),
    };

    return <HighchartsReact highcharts={Highcharts} options={config} ref="PerformanceChart" />;
  }

  render() {
    return <StyledSensorChart compact={this.props.compact}>{this.renderChart()}</StyledSensorChart>;
  }
}

PerformanceChart.propTypes = {
  theme: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  dimensions: PropTypes.object,
  loading: PropTypes.bool,
  histories: PropTypes.array,
  error: PropTypes.string,
  sensorNames: PropTypes.object,
  unit: PropTypes.string,
  sensorConfigurations: PropTypes.object,
  maxX: PropTypes.number,
  minXRange: PropTypes.number,
  t: PropTypes.func,
  yAxisTitle: PropTypes.string,
  getThreshold: PropTypes.func,
  thresholdGranularity: PropTypes.string,
  yMin: PropTypes.number,
  yMax: PropTypes.number,
  yFormatter: PropTypes.func,
  precision: PropTypes.number,
  isBooleanType: PropTypes.bool,
  compact: PropTypes.bool,
  title: PropTypes.string,
  onSelection: PropTypes.func,
  onClick: PropTypes.func,
  annotations: PropTypes.array,
};

export default Responsive(withTheme(PerformanceChart));
