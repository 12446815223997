import React from 'react';
import { CellContext } from '@tanstack/table-core/build/lib/core/cell';
import { RowData } from '@tanstack/table-core/build/lib/types';

import { formattedDate } from 'containers/Application/Maintenance/tabs/Deviations/DeviationsTable/FormattedDate';

import * as sharedColumnStyles from './SharedColumns.module.scss';

export function DateCell<TData extends RowData>({ getValue }: CellContext<TData, string | unknown>) {
  const cellClasses = [sharedColumnStyles.dateCell, 'textLabel'].join(' ');

  try {
    const formattedValue = formattedDate(getValue());
    return <div className={cellClasses}>{formattedValue}</div>;
  } catch (error) {
    console.error(error);
    return null;
  }
}
