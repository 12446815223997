import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CustomChart from 'containers/Application/Modules/CustomChartsModule/CustomChart';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

const Description = styled.p`
  margin-bottom: var(--size-md);
`;

const CustomChartCustomView = ({ match, customView }) => {
  const functionalLocationId = match?.params?.functionalLocationId;
  if (!functionalLocationId || isEmpty(customView?.customCharts)) {
    return null;
  }

  return (
    <Section>
      <SectionHeader title={customView.viewName} />
      {customView.viewDesc && <Description>{customView.viewDesc}</Description>}
      <CustomChart chart={customView.customCharts[0]} functionalLocationId={functionalLocationId} />
    </Section>
  );
};

CustomChartCustomView.propTypes = {
  customView: PropTypes.shape({
    customCharts: PropTypes.arrayOf(
      PropTypes.shape({
        sensorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        id: PropTypes.number.isRequired,
        graphType: PropTypes.string,
        aggregationFreq: PropTypes.string,
      })
    ).isRequired,
    viewName: PropTypes.string.isRequired,
    viewDesc: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      functionalLocationId: PropTypes.string,
    }),
  }),
};

export default withRouter(CustomChartCustomView);
