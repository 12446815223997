import { useQueryClient } from '@tanstack/react-query';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';

export const useClearCache = () => {
  const queryClient = useQueryClient();

  const clearWithUrlMatch = (urlMatchString: string) => invalidateCachesWithUrlMatch(urlMatchString);

  const clearAll = async () => await Promise.allSettled([clearWithUrlMatch(''), queryClient.clear()]);

  return {
    clearAll,
    clearWithUrlMatch,
  };
};
