import { useCallback } from 'react';

import useQuery from 'decorators/Query/useQuery';

const useFilterTitle = (defaultSelectedFilters = {}, excludedFilters = [], setValidationModel) => {
  const { query, setQuery } = useQuery();
  const defaultFilters = Object.keys(defaultSelectedFilters).filter(key => !excludedFilters.includes(key));
  const defaultFilterCount = defaultFilters.length;

  // Filters that add to count, exclude filters that should not add to count
  const filters = Object.keys(query)
    .filter(key => !excludedFilters.includes(key))
    .reduce((obj, key) => {
      obj[key] = query[key];
      return obj;
    }, {});

  const filterCount = Object.values(filters).reduce((total, value) => {
    if (Array.isArray(value)) {
      return total + value.length;
    }
    return total + 1;
  }, 0);

  // which keys 'filters' and 'defaultSelectedFilters' have in common
  const matchingKeys = Object.keys(filters).filter(key => defaultFilters.includes(key));

  // count how many of these matching keys values DO NOT match
  const numberOfNotMatchingValues = matchingKeys.reduce((total, key) => {
    if (filters[key] !== defaultSelectedFilters[key]?.toString()) {
      return total + 1;
    }
    return total + 0;
  }, 0);

  const hasActiveFilters = filterCount > 0;
  const hasDefaultSelectedFilters = defaultFilterCount === 0;
  const showClear = hasActiveFilters && hasDefaultSelectedFilters;

  const hasSameAmountOfActiveFilters = filterCount === defaultFilterCount;
  const hasOtherThanDefaultValuesOnFilters = hasSameAmountOfActiveFilters && numberOfNotMatchingValues > 0;
  const showReset = !showClear && (!hasSameAmountOfActiveFilters || hasOtherThanDefaultValuesOnFilters);

  const handleFilterClear = useCallback(() => {
    setQuery({ ...defaultSelectedFilters }, { replace: true });
    setValidationModel({});
  }, [setQuery]);

  const handleFilterReset = useCallback(() => {
    const resettedFilters = Object.keys(query).reduce((obj, key) => {
      obj[key] = undefined;
      return obj;
    }, {});

    setQuery({ ...resettedFilters, ...defaultSelectedFilters });
    setValidationModel({});
  }, [setQuery, query]);

  return {
    filterCount,
    showClear,
    handleFilterClear,
    showReset,
    handleFilterReset,
  };
};

export default useFilterTitle;
