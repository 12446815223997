import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DialogBody from '../Dialog/DialogBody';
import DialogModal from 'components/Dialog/DialogModal';
import ConfirmationModalContent from './ConfirmationModalContent';
import ConfirmationModalFooter from './ConfirmationModalFooter';

export const defaultConfirmationMessage = 'Are you sure?';
export const deleteConfirmationMessage = 'Are you sure you want to remove this?';
export const deleteMultipleConfirmationMessage = 'Are you sure you want to remove these?';

export const SpaciousBody = styled(DialogBody)`
  display: flex;
  justify-content: center;
  ${props => props.theme.media.landscape`
    padding: ${({
      theme: {
        spacing: { lg, xxl },
      },
    }) => `${xxl} ${xxl} ${lg} ${xxl}`};
  `}
`;
SpaciousBody.displayName = 'SpaciousBody';

export const ConfirmationMessage = styled.div`
  text-align: center;
  font-size: ${props => props.theme.fontSize.md};
  line-height: 1.5;
`;
ConfirmationMessage.displayName = 'ConfirmationMessage';

const ContentWrapper = styled.div`
  margin-top: var(--size-lg);
`;
ContentWrapper.displayName = 'ContentWrapper';

const ConfirmationModal = ({
  children,
  message,
  additionalInfoText,
  onDismiss,
  onConfirm,
  closeModal,
  waitForSuccessfulCompletion,
  t,
  confirmButtonType,
}) => {
  const [isLoading, setLoading] = useState(false);

  const onClickCancel = () => {
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
    closeModal();
  };

  const onClickConfirm = () => {
    if (typeof onConfirm === 'function') {
      const result = onConfirm();
      if (waitForSuccessfulCompletion && result instanceof Promise) {
        setLoading(true);
        return result.then(closeModal).finally(() => setLoading(false));
      }
    }
    closeModal();
  };

  const footer = (
    <ConfirmationModalFooter
      onClickCancel={onClickCancel}
      onClickConfirm={onClickConfirm}
      t={t}
      loading={isLoading}
      confirmButtonType={confirmButtonType}
    />
  );

  return (
    <DialogModal
      animate
      small
      disableFullScreen
      disableTitlePanel
      isActive={true}
      onOverlayClick={onClickCancel}
      t={t}
      footer={footer}
      bodyComponent={SpaciousBody}
    >
      <ConfirmationMessage>{message || t(defaultConfirmationMessage)}</ConfirmationMessage>
      {(children || additionalInfoText) && (
        <ContentWrapper>
          <ConfirmationModalContent additionalInfoText={additionalInfoText}>{children}</ConfirmationModalContent>
        </ContentWrapper>
      )}
    </DialogModal>
  );
};

ConfirmationModal.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  additionalInfoText: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  closeModal: PropTypes.func,
  waitForSuccessfulCompletion: PropTypes.bool,
  t: PropTypes.func,
  confirmButtonType: PropTypes.string,
};
export default ConfirmationModal;
