import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subHours from 'date-fns/subHours';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';

/**
 * parseToDate parses ISO string, epoch timestamp, or Date object and returns it as Date
 * NOTE: Please do not use this if not necessary, we should not need to guess what type our dates are in the components
 * @param {String|Number|Date} date
 * @returns {Date}
 */
export const parseToDate = date => {
  if (typeof date === 'string') {
    // Parse ISO string
    return parseISO(date);
  } else if (Number.isInteger()) {
    // Parse epoch timestamp
    return toDate(date);
  }
  // Parse Date objects (returns epoch timestamp with valueOf())
  return toDate(date.valueOf());
};

export const startOfUTCDay = date => {
  const offset = date.getTimezoneOffset();
  const offsetHours = offset / 60;
  // clone date
  const newDate = subHours(startOfDay(new Date(date.valueOf())), offsetHours);
  return newDate;
};

export const endOfUTCDay = date => {
  const offset = date.getTimezoneOffset();
  const offsetHours = offset / 60;
  // clone date
  const newDate = subHours(endOfDay(new Date(date.valueOf())), offsetHours);
  return newDate;
};

export const startOfUTCMonth = date => {
  const newDate = startOfMonth(date);
  return startOfUTCDay(newDate);
};

export const endOfUTCMonth = date => {
  const newDate = endOfMonth(date);
  return endOfUTCDay(newDate);
};

export const startOfUTCYear = date => {
  const newDate = startOfYear(date);
  return startOfUTCDay(newDate);
};

export const endOfUTCYear = date => {
  const newDate = endOfYear(date);
  return endOfUTCDay(newDate);
};

/**
 * Sets hours in UC
 * @param {Date} date
 * @param {Number} hours to be set
 * @returns {Date} with hours set to `hours` in UTC
 */
export const setUTCHours = (date, hours) => {
  const newDate = toDate(date);
  newDate.setUTCHours(hours);
  return newDate;
};

/**
 * Compare two dates if they are on the same day in UTC
 * @param {Date} date
 * @param {Date} compareDate
 * @returns boolean
 */
export const isSameUTCDay = (date, compareDate) => {
  const dateUTCDay = date.getUTCDate();
  const dateUTCMonth = date.getUTCMonth();
  const dateUTCYear = date.getUTCYear();
  const compareDateUTCDay = compareDate.getUTCDate();
  const compareDateUTCMonth = compareDate.getUTCMonth();
  const compareDateUTCYear = compareDate.getUTCYear();
  return dateUTCDay === compareDateUTCDay && dateUTCMonth === compareDateUTCMonth && dateUTCYear === compareDateUTCYear;
};

/**
 * getBrowserTimeZone Uses Intl API to return client time zone in IANA format
 * https://stackoverflow.com/a/34602679
 * @returns {String} browser time zone in IANA format (Europe/Helsinki etc.)
 */
export const getBrowserTimeZone = () => Intl?.DateTimeFormat().resolvedOptions()?.timeZone ?? 'UTC';
