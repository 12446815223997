import { isActionForClosing, isCustomerTokenType, isActionWrongPerson } from '../utils';

export const getActionSubheaderText = (t, action, type) => {
  if (isActionForClosing(action)) {
    return t('Your answer "{0}" has been successfully sent.', t(`additional-sale/${action}`));
  }
  if (isCustomerTokenType(type)) {
    if (isActionWrongPerson(action)) {
      return t('Your answer "{0}" has been successfully sent.', t(`additional-sale/${action}`));
    }
    return t(
      'Your answer "{0}" has been successfully sent. You will be notified when Caverion replies.',
      t(`additional-sale/${action}`)
    );
  }
  return t('Your reply has been successfully sent and the customer will be notified.');
};

export const getActionDescriptionText = (t, action, type, { correctPersonEmail }) => {
  if (isActionForClosing(action)) {
    return t(
      'You should receive confirmation email within couple of minutes. If you need to change your answer or you have more further questions, please be in touch with Caverion HelpDesk.'
    );
  }
  if (isCustomerTokenType(type)) {
    if (isActionWrongPerson(action) && correctPersonEmail) {
      return t('This additional sale will be sent to the new contact person you provided.');
    }
    return t('You should receive confirmation email within couple of minutes.');
  }
  return '';
};

export const getManagerChangeSubheaderText = (t, { managerEmail }) => {
  return t(
    "Caverion's contact has been successfully updated, and the new contact ({0}) will receive an email notification.",
    managerEmail
  );
};
