import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { languageOptions } from 'containers/Application/Admin/UserForm/Settings/Settings';
import translations from 'decorators/Translations/translations';
import { SelectInputV5 } from 'components/Select/SelectInputV5';

const customStyles = theme => ({
  control: provided => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    borderRadius: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: theme.font.size.xs,
    fontWeight: theme.fontWeight.bold,
    color: theme.colors.white,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    fill: theme.colors.white,
  }),
});

const value = (languageOptions, selected) => languageOptions.find(option => option.value === selected);

const LanguageSelector = ({ t, selectedLanguage, setSelectedLanguage }) => {
  const theme = useTheme();
  const styles = useMemo(() => customStyles(theme), [theme]);
  return (
    <div style={{ display: 'inline-block' }}>
      <SelectInputV5
        styles={styles}
        options={languageOptions(t)}
        onChange={selectedOption => setSelectedLanguage(selectedOption.value)}
        value={value(languageOptions(t), selectedLanguage)}
        placeholder="Language"
        isSearchable={false}
      />
    </div>
  );
};

LanguageSelector.propTypes = {
  t: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string,
  setSelectedLanguage: PropTypes.func.isRequired,
};

export default translations(LanguageSelector);
