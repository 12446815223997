import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import find from 'lodash/find';
import PropTypes from 'prop-types';

import DocumentTable from 'components/Documents/DocumentTable/DocumentTable';
import Section from 'components/Section/Section';
import PrimaryButton from 'components/Button/PrimaryButton';
import DocumentManager from 'components/Documents/DocumentManager/DocumentManager';
import FolderManager from 'components/Documents/FolderManager/FolderManager';
import SnackBar from 'components/SnackBar/SnackBar';
import InputSearch from 'components/Form/InputSearch';
import SkeletonPill from 'components/Skeletons/SkeletonPill';

import translations from 'decorators/Translations/translations';
import { findFromFolderTree } from 'utils/Data/documents';
import {
  loadFolders,
  deleteFolder,
  downloadFolder,
  loadFiles,
  deleteFile,
  downloadFile,
  loadPartnerFolders,
  loadPartnerFiles,
  downloadPartnerFolder,
  downloadPartnerFile,
} from 'redux/modules/index';
import { NOTIFICATION_TIMEOUT } from 'constants/common';
import { hasBuildingAndPortfolioAdminTools } from 'utils/Data/profileData';
import Loader from 'components/Loader/Loader';
import Content from 'components/Content/Content';

const Heading = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-bottom: ${props => props.theme.section.md};

  ${props => props.theme.media.portrait`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const DocumentSearch = styled.div`
  display: ${props => props.desktop && 'none'};
  margin: var(--size-md) 0;

  ${props => props.theme.media.portrait`
    width: 300px;
    margin-top: 0;
  `}

  ${props => props.theme.media.desktop`
    display: ${props => (props.desktop ? 'block' : 'none')};
    margin-bottom: 0;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  button:last-of-type {
    margin-top: var(--size-md);
  }

  ${props => props.theme.media.portrait`
    flex-direction: row;
    button:last-of-type {
      margin-top: 0;
      margin-left: var(--size-md);
    }

    button {
      width: auto;
      min-width: auto;
      }
  `}
`;

const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.subHeading};
  font-weight: ${props => props.theme.fontWeight.semibold};
  line-height: ${props => props.theme.lineHeight.heading};

  ${props => props.theme.media.portrait`
    padding-right: var(--size-xl);
  `}
`;

const SnackBarContent = styled.div`
  display: flex;
`;

const SnackBarInfoContainer = styled.div`
  margin-left: var(--size-lg);
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoTitle = styled.p``;

const InfoDescription = styled.p`
  font-weight: ${props => props.theme.font.weight.normal};
`;

const EMPTY_ARRAY = [];

class DocumentModule extends Component {
  state = {
    showDocumentManager: false,
    showFolderManager: false,
    documentId: null,
    folderId: null,
    notificationType: '',
    notificationMessage: '',
    notificationVisible: false,
    openFolders: [],
    filter: '',
  };
  componentDidMount() {
    this.timeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  renderDocumentSearch = showSkeletons => {
    return showSkeletons ? (
      <SkeletonPill />
    ) : (
      <InputSearch
        onChange={value => this.setState({ filter: value?.toLowerCase() || '' })}
        onClear={() => this.setState({ filter: '' })}
        placeholder={this.props.t('Search from documents')}
        initialValue={this.state.filter}
      />
    );
  };

  toggleDocumentManager = document =>
    this.setState(oldState => ({
      showDocumentManager: !oldState.showDocumentManager,
      documentId: document?.id || null,
    }));

  toggleFolderManager = folder =>
    this.setState(oldState => ({
      showFolderManager: !oldState.showFolderManager,
      folderId: folder?.id || null,
    }));

  handleAfterSubmit = (notification, isFolder = false, folderId = null, openFolders = []) => {
    const closing = {
      ...(isFolder ? { showFolderManager: false } : { showDocumentManager: false }),
      ...(isFolder ? { folderId: this.state.showDocumentManager ? folderId : null } : { documentId: null }),
      openFolders,
    };
    this.setState({
      ...closing,
      ...notification,
    });
    this.timeout = setTimeout(
      () => this.setState({ notificationVisible: false, openFolders: [] }),
      NOTIFICATION_TIMEOUT
    );
  };

  handleDeleteDocument = id =>
    this.props
      .deleteFile(id)
      .then(() => this.props.loadFiles())
      .then(response => {
        if (response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Deleting document failed'),
            notificationVisible: true,
          });
        } else {
          this.handleAfterSubmit({
            notificationType: 'success',
            notificationMessage: this.props.t('Document deleted successfully'),
            notificationVisible: true,
          });
        }
      });

  handleDeleteFolder = path =>
    this.props
      .deleteFolder(path)
      .then(() => Promise.all([this.props.loadFolders(), this.props.loadFiles()]))
      .then(response => {
        if (response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Deleting folder failed'),
            notificationVisible: true,
          });
        } else {
          this.handleAfterSubmit({
            notificationType: 'success',
            notificationMessage: this.props.t('Folder deleted successfully'),
            notificationVisible: true,
          });
        }
      });

  handleDownload = (file, inline, folder) => {
    (!!folder ? this.props.downloadFolder(folder.id, folder.name) : this.props.downloadFile(file, inline)).then(
      response => {
        if (response && response.error) {
          this.handleAfterSubmit({
            notificationType: 'error',
            notificationMessage: this.props.t('Downloading failed'),
            notificationVisible: true,
          });
        }
      }
    );
  };

  handleFolderFileUpload = folder => {
    this.setState({ folderId: folder.id });
    this.toggleDocumentManager();
  };

  render() {
    const {
      t,
      functionalLocation,
      functionalLocationFolders,
      functionalLocationFiles,
      partnerNumber,
      partnerFolders,
      partnerFiles,
      profile,
      downloadingFolder,
      loadingFolders,
    } = this.props;
    const {
      showDocumentManager,
      showFolderManager,
      documentId,
      folderId,
      notificationType,
      notificationMessage,
      notificationVisible,
      openFolders,
    } = this.state;

    if (!functionalLocation && !partnerNumber) {
      return null;
    }

    const isPortfolioLevel = !functionalLocation;

    const files = isPortfolioLevel
      ? get(partnerFiles, [partnerNumber])
      : get(functionalLocationFiles, [functionalLocation.functionalLocation]);

    const folders = isPortfolioLevel
      ? get(partnerFolders, [partnerNumber])
      : get(functionalLocationFolders, [functionalLocation.functionalLocation]);

    const showSkeletons = !files || !folders;

    return (
      <Section>
        <Content>
          <Heading>
            <TitleWrapper>
              {!isPortfolioLevel && <Title>{t('Documents')}</Title>}
              <DocumentSearch desktop>{this.renderDocumentSearch(showSkeletons)}</DocumentSearch>
            </TitleWrapper>
            <ButtonWrapper>
              <PrimaryButton add large onClick={e => this.toggleFolderManager()}>
                {t('Create folder')}
              </PrimaryButton>
              <PrimaryButton confirm large onClick={e => this.toggleDocumentManager()}>
                {t('Upload files')}
              </PrimaryButton>
            </ButtonWrapper>
          </Heading>
          <DocumentSearch>{this.renderDocumentSearch(showSkeletons)}</DocumentSearch>

          <DocumentTable
            loading={showSkeletons}
            t={t}
            files={files || EMPTY_ARRAY}
            folders={folders || EMPTY_ARRAY}
            onEdit={this.toggleDocumentManager}
            onDelete={this.handleDeleteDocument}
            onFolderEdit={this.toggleFolderManager}
            onFolderDelete={this.handleDeleteFolder}
            onFolderFileUpload={this.handleFolderFileUpload}
            profile={profile}
            download={this.handleDownload}
            openFolders={openFolders}
            loadingFolders={loadingFolders}
            filterString={this.state.filter}
          />

          {showDocumentManager && (
            <DocumentManager
              onClose={this.toggleDocumentManager}
              onSubmit={this.handleAfterSubmit}
              isCreateMode={!documentId}
              document={find(files, { id: documentId }) || {}}
              folders={folders}
              onNewFolder={this.toggleFolderManager}
              isDocumentsAdmin={hasBuildingAndPortfolioAdminTools(profile)}
              functionalLocation={functionalLocation}
              partnerNumber={partnerNumber}
              folderId={folderId}
            />
          )}
          {showFolderManager && (
            <FolderManager
              onClose={this.toggleFolderManager}
              onSubmit={this.handleAfterSubmit}
              isCreateMode={!folderId}
              folder={folderId ? findFromFolderTree(folderId, { children: folders }) : {}}
              folders={folders}
              functionalLocation={functionalLocation}
              partnerNumber={partnerNumber}
            />
          )}

          <SnackBar variant={notificationType} visible={notificationVisible}>
            {notificationMessage}
          </SnackBar>
          <SnackBar variant="confirmation" visible={downloadingFolder} secondaryContent={<div />}>
            <SnackBarContent>
              <Loader color="CERULEAN" size="MEDIUM" />
              <SnackBarInfoContainer>
                <InfoTitle>{t('We are generating a ZIP file for you.')}</InfoTitle>
                <InfoDescription>{t('The file may take up to a couple of minutes to generate.')}</InfoDescription>
              </SnackBarInfoContainer>
            </SnackBarContent>
          </SnackBar>
        </Content>
      </Section>
    );
  }
}

DocumentModule.propTypes = {
  t: PropTypes.func.isRequired,
  loadFolders: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  downloadFolder: PropTypes.func.isRequired,
  loadFiles: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  functionalLocation: PropTypes.object,
  partnerNumber: PropTypes.string,
  functionalLocationFolders: PropTypes.object,
  functionalLocationFiles: PropTypes.object,
  partnerFolders: PropTypes.object,
  partnerFiles: PropTypes.object,
  downloadingFolder: PropTypes.bool,
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  functionalLocationFolders: state.folder.functionalLocations,
  functionalLocationFiles: state.file.filteredFunctionalLocations,
  partnerFolders: state.folder.partners,
  partnerFiles: state.file.filteredPartners,
  filterKeyword: state.file.filterKeyword,
  downloadingFolder: state.folder.downloadingFolder,
  loadingFolders: state.folder.loadingFolders,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadFolders: () =>
    ownProps.functionalLocation
      ? dispatch(loadFolders(ownProps.functionalLocation, true))
      : dispatch(loadPartnerFolders(ownProps.partnerNumber, true)),
  deleteFolder: path => dispatch(deleteFolder(path)),
  downloadFolder: (id, name) =>
    ownProps.functionalLocation
      ? dispatch(downloadFolder(id, name, ownProps.functionalLocation))
      : dispatch(downloadPartnerFolder(id, name, ownProps.partnerNumber)),
  loadFiles: () =>
    ownProps.functionalLocation
      ? dispatch(loadFiles(ownProps.functionalLocation.functionalLocation, ownProps.partnerNumber, true))
      : dispatch(loadPartnerFiles(ownProps.partnerNumber, true)),
  deleteFile: id => dispatch(deleteFile(id)),
  downloadFile: (file, inline) =>
    ownProps.functionalLocation
      ? dispatch(downloadFile(file, file.functionalLocation ? file : ownProps.functionalLocation, inline))
      : dispatch(downloadPartnerFile(file.id, inline)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(DocumentModule));
