import styled from 'styled-components';

import { FlexTightGroup } from 'components/Layout/FlexTightGroup';
import { Box } from 'components/Framing/Box';

/**
 * Pack Box components into a tight group.
 *
 * @implements {UIv3}
 */
export const FlexBoxGroup = styled(FlexTightGroup)`
  padding-top: var(--border-thin);
  padding-left: var(--border-thin);

  ${Box} {
    margin-left: calc(-1 * var(--border-thin));
    margin-top: calc(-1 * var(--border-thin));
  }
`;
