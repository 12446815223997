import get from 'lodash/get';

import { replaceMultisensorIdWithSubsensorsIds } from './FloorModuleUtils';
import { getDefaultSubsensor } from 'utils/Data/sensor';
import { getOpeningHours } from 'utils/Data/performance';

export const modalConfigNames = {
  TECHNICAL_PERFORMANCE: 'technical_performance',
  INDOOR_AIR_QUALITY: 'indoor_air_quality',
  FLOOR_OPI: 'floor_opi',
  MEETING_ROOM_UTILIZATION: 'meeting_room_utilization',
  OPEN_OFFICE_UTILIZATION: 'open_office_utilization',
  SEAT_UTILIZATION: 'seat_utilization',
  AREA_UTILIZATION: 'area_utilization',
};

export const getDialogType = modalConfigName => {
  switch (modalConfigName) {
    case modalConfigNames.TECHNICAL_PERFORMANCE:
    case modalConfigNames.INDOOR_AIR_QUALITY:
      return 'technical_performance';
    case modalConfigNames.MEETING_ROOM_UTILIZATION:
    case modalConfigNames.OPEN_OFFICE_UTILIZATION:
    case modalConfigNames.SEAT_UTILIZATION:
      return 'utilization_rate';
    case modalConfigNames.FLOOR_OPI:
      return 'floor_opi';
    case modalConfigNames.AREA_UTILIZATION:
    default:
      return null;
  }
};

export const getTitleFromAreaOrSensor = (area, sensor) => {
  if (area?.name) {
    return area.name;
  }
  if (sensor?.parent?.displayName) {
    return sensor.parent.displayName;
  }
  if (sensor?.parent?.name) {
    return sensor.parent.name;
  }
  return sensor?.displayName || sensor?.name;
};

export const getDialogTitle = (modalConfigName, area, sensor) => {
  switch (modalConfigName) {
    case modalConfigNames.TECHNICAL_PERFORMANCE:
      return 'Technical performance';
    case modalConfigNames.INDOOR_AIR_QUALITY:
      return 'Indoor Air Quality';
    case modalConfigNames.MEETING_ROOM_UTILIZATION:
      return 'Meeting Room Utilization';
    case modalConfigNames.OPEN_OFFICE_UTILIZATION:
      return 'Open Office Utilization';
    case modalConfigNames.SEAT_UTILIZATION:
      return 'Seat Utilization';
    case modalConfigNames.FLOOR_OPI:
      return null;
    default:
      return getTitleFromAreaOrSensor(area, sensor);
  }
};

export const closeOpiDialogFn = setQuery => () => {
  setQuery({
    openModalSensorIds: undefined,
    openModalClickedSensorId: undefined,
    openModalConfig: undefined,
    openModalFloorId: undefined,
    openModalAreaId: undefined,
  });
};

export const closeInsightDialogFn = setQuery => () => {
  setQuery({ openInsightId: undefined });
};

export const openInsightDialogFn = setQuery => insightId => {
  closeOpiDialogFn(setQuery)();
  setQuery({ openInsightId: insightId });
};

export const openOpiDialogFn =
  (setQuery, sensors) =>
  ({ floorId, sensorIds = [], areaId, modalConfigName, includeMainSensor = false }) => {
    closeInsightDialogFn(setQuery)();
    const sensorIdsWithSubSensors =
      sensorIds.length > 0 && replaceMultisensorIdWithSubsensorsIds(sensors, sensorIds, includeMainSensor);
    if (sensorIdsWithSubSensors?.length) {
      setQuery({
        openModalSensorIds: sensorIdsWithSubSensors.join(','),
        openModalClickedSensorId: sensorIds[0],
        openModalConfig: modalConfigName || undefined,
        openModalFloorId: floorId || undefined,
        openModalAreaId: areaId || undefined,
      });
    }
  };

export const getOpiDialogProps = ({
  t,
  query,
  setQuery,
  floors,
  sensors,
  buildingMeta,
  functionalLocation,
  partnerNumber,
  features,
  sensorAlarmsById,
}) => {
  const sensorsIds = query.openModalSensorIds.split(',').map(sensorId => parseInt(sensorId, 10));
  const selectedFloorId = parseInt(query.openModalFloorId, 10);
  const selectedAreaId = parseInt(query.openModalAreaId, 10);
  const floor = floors.find(floor => floor.id === selectedFloorId);
  const area = Number.isFinite(selectedAreaId) && floor?.children?.find(child => child.id === selectedAreaId);
  const firstSensor = sensors.find(sensor => sensor.id === sensorsIds[0]);
  const clickedSensor = sensors.find(sensor => sensor.id === +query.openModalClickedSensorId);
  const defaultSensor = getDefaultSubsensor(clickedSensor) || getDefaultSubsensor(firstSensor) || firstSensor;
  const defaultSensorId = defaultSensor?.id;
  const isTechnicalPerformance = get(firstSensor, 'sensorType.name') === 'technical_performance';
  const openingHours = isTechnicalPerformance ? getOpeningHours(floor, buildingMeta) : undefined;

  return {
    dialogTitle: t(getDialogTitle(query.openModalConfig, area, firstSensor)),
    dialogType: getDialogType(query.openModalConfig),
    dialogOpen: true,
    sensorsIds,
    defaultSensorId,
    category: floor,
    openingHours,
    sensor: firstSensor,
    sensors,
    functionalLocation,
    partnerNumber,
    features,
    closeDialog: closeOpiDialogFn(setQuery),
    sensorAlarmsById,
    floors,
    buildingMeta,
  };
};

export const getInsightDialogProps = ({ t, query, setQuery, functionalLocation, insightMessages }) => {
  return {
    isActive: true,
    onClose: closeInsightDialogFn(setQuery),
    functionalLocation,
    insightMessage: insightMessages.find(msg => msg.insightId === +query.openInsightId),
    t,
  };
};
