import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 10px;
  gap: 0.5em;

  h4 {
    font-size: ${props => props.theme.font.size.xs};
    font-weight: ${props => props.theme.font.weight.bold};
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: none;
  gap: 0.5em;

  address {
    width: 100%;
    border-radius: 4px;
    padding: 0.5em 0;
    font-size: ${props => props.theme.font.size.xxxs};
    font-style: normal;
  }

  p {
    line-height: ${props => props.theme.font.lineHeight.md};
    font-family: ${props => props.theme.font.family.cairo};
    font-weight: ${props => props.theme.font.weight.semibold};
    font-size: ${props => props.theme.font.size.xxs};
  }

  ul {
    & > * {
      margin-top: 0.5em;
    }
  }

  li,
  span,
  a {
    vertical-align: text-top;
    & > * + * {
      margin-left: 0.5em;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
