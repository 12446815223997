import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5em;
`;

export const Title = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  align-items: flex-end;

  h2 {
    font-size: ${props => props.theme.fontSize.lg};
    @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
      font-size: ${props => props.theme.fontSize.md};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25em;

  @media print {
    display: none;
  }
`;
