import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollingUp, setScrollingUp] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      const newScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      // Don't update if just scrolling couple of pixels
      if (newScrollPosition !== 0 && Math.abs(newScrollPosition - scrollPosition) <= 5) {
        return;
      }
      setScrollPosition(newScrollPosition);
      const scrollingUp = newScrollPosition < scrollPosition;
      setScrollingUp(scrollingUp);
    };
    const throttledUpdate = throttle(updatePosition, 100);
    window.addEventListener('scroll', throttledUpdate);
    updatePosition();
    return () => window.removeEventListener('scroll', throttledUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [scrollPosition, scrollingUp];
};

export default useScrollPosition;
