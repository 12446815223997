import { connect } from 'react-redux';
import { createNoticeSelector } from 'redux/modules/iot/selectors/notice';

const EMPTY_ARRAY = [];

const selectObservations = (state, { partnerNumber, filterableFLs }) => {
  return state.profile?.profile?.features?.controlRoomObservations ? state.notice.observationsByPartner[partnerNumber]?.energy?.filter(
    item => item.status !== 'completed'
  ).filter(item => filterableFLs.length > 0 ? filterableFLs.includes(item.functionalLocation) : true) ?? EMPTY_ARRAY : EMPTY_ARRAY;
}

const noticeSelector = createNoticeSelector(selectObservations);

const mapStateToProps = (state, props) => ({
  notices: noticeSelector(state, props),
});

const connector = connect(mapStateToProps);

export default connector;
