import React, { PropsWithChildren, ReactNode, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useTranslations } from 'decorators/Translations/translations';
import { formatDuration } from 'utils/time';
import { OnOffSeries } from 'types/Charts';
import { RootState } from 'types/Redux';

import GaugeChart from 'components/Charts/GaugeChart';
import { Box } from 'components/Framing/Box';
import { FlexGroup } from 'components/Layout/FlexGroup';
import { FlexBoxGroup } from 'components/Layout/FlexBoxGroup';
import { FlexTightGroup } from 'components/Layout/FlexTightGroup';
import { GroupPadding } from 'components/Layout/GroupPadding';
import { useResolvedCssVariables } from 'components/CSS/useResolvedCssVariables';

import { Container } from '../SensorSelector/SensorSelector';

import * as onOffStyles from './OnOffAggregations.module.scss';

export type OnOffAggregationsProps = {
  series?: OnOffSeries;
};

export function OnOffAggregations({ series }: OnOffAggregationsProps) {
  const [t] = useTranslations();

  if (!series?.summary) {
    return (
      <Container>
        <FlexBoxGroup row>
          <NotAvailableGauge label={t('On')} />
          <NotAvailableGauge label={t('Off')} />
          <NumberOfEventsGauge eventCount={Number.NaN} />
        </FlexBoxGroup>
      </Container>
    );
  }

  const { labels = ['On', 'Off'], summary } = series;
  const { observationCount, distribution } = summary;
  const [onLabel, offLabel] = labels;

  const onTime = distribution?.find(([value]) => value === 1)?.[1] ?? 0;
  const offTime = distribution?.find(([value]) => value === 0)?.[1] ?? 0;
  const totalTime = onTime + offTime;

  return (
    <Container>
      <FlexBoxGroup row>
        <OnOffGauge timeSlice={onTime} of={totalTime} label={t(onLabel)} />
        <OnOffGauge timeSlice={offTime} of={totalTime} label={t(offLabel)} />
        <NumberOfEventsGauge eventCount={observationCount} />
      </FlexBoxGroup>
    </Container>
  );
}

export type NotAvailableGaugeProps = {
  label: string;
};

export function NotAvailableGauge({ label }: NotAvailableGaugeProps) {
  return <OnOffGauge timeSlice={Number.NaN} of={Number.NaN} label={label} />;
}

export type OnOffGaugeProps = {
  timeSlice: number;
  of: number;
  label: string;
};

export function OnOffGauge({ timeSlice, of, label }: OnOffGaugeProps) {
  const [t] = useTranslations();
  const language = useSelector<RootState, string>(state => state.profile.profile.language);

  const containerRef = useRef<HTMLDivElement>(null);
  const colors = useResolvedCssVariables(containerRef, {
    gaugeBackground: '--opi-card-gauge-bg',
    gaugeForeground: '--opi-card-gauge-fg',
  });

  const percentage = (timeSlice * 100) / of;

  let valueLabel: ReactNode;
  if (Number.isFinite(percentage)) {
    valueLabel = `${Math.round(percentage)} %`;
  } else {
    valueLabel = <span className={onOffStyles.notAvailableText}>N/A</span>;
  }

  let durationShareLabel: string;
  if (!Number.isFinite(timeSlice)) {
    durationShareLabel = t('N/A');
  } else {
    durationShareLabel = formatDuration(timeSlice, language, t);
  }

  return (
    <div ref={containerRef} className={onOffStyles.cardContainer}>
      {colors && (
        <OnOffAggregationCard primaryLabel={label} secondaryLabel={durationShareLabel}>
          <GaugeChart
            size={70}
            value={percentage}
            min={0}
            max={100}
            unit="%"
            background={colors.gaugeBackground}
            stops={[[0, colors.gaugeForeground]]}
            backgroundPadding={0}
            lineWidth={22}
            hidePlus
          >
            {valueLabel}
          </GaugeChart>
        </OnOffAggregationCard>
      )}
    </div>
  );
}

export type NumberOfEventsGaugeProps = {
  eventCount: number;
};

export function NumberOfEventsGauge({ eventCount }: NumberOfEventsGaugeProps) {
  const [t] = useTranslations();

  const eventCountLabel = Number.isFinite(eventCount) ? eventCount : 0;

  return (
    <div className={onOffStyles.cardContainer}>
      <OnOffAggregationCard primaryLabel={t('Number of Events')}>
        <span className={onOffStyles.eventCount}>{eventCountLabel}</span>
      </OnOffAggregationCard>
    </div>
  );
}

export type OnOffAggregationCardProps = PropsWithChildren<{
  primaryLabel: string;
  secondaryLabel?: string;
}>;

export function OnOffAggregationCard({ children, primaryLabel, secondaryLabel }: OnOffAggregationCardProps) {
  return (
    <Box className={onOffStyles.onOffGaugeBox}>
      <GroupPadding>
        <FlexGroup alignItems="center">
          <div className={onOffStyles.valueContainer}>{children}</div>
          <FlexTightGroup alignItems="center">
            <div className={onOffStyles.primaryLabel}>{primaryLabel}</div>
            <div className={onOffStyles.secondaryLabel}>{secondaryLabel}</div>
          </FlexTightGroup>
        </FlexGroup>
      </GroupPadding>
    </Box>
  );
}
