import React, { FormEvent, useState } from 'react';
import PropTypes from 'prop-types';

import translations, { useTranslations } from 'decorators/Translations/translations';
import InputTextArea from 'components/Form/InputTextArea';
import PrimaryButton from 'components/Button/PrimaryButton2';

import * as Styled from './ScatterPlotAnnotationForm.styled';

const roundToThree = (num: number | string): number => {
  return +(Math.round(Number.parseFloat(num + 'e+3')) + 'e-3');
};

type PointProps = { desc: string; data: string | number; unit: string };
const Point = ({ desc, data, unit }: PointProps) => (
  <span>
    <b>{desc}</b> {roundToThree(data)} {unit}
  </span>
);

type AnnotationFormProps = {
  onSubmit: (arg0: object, arg1: string) => void;
  onClose: () => void;
  chartUnits: { xUnit: string; yUnit: string };
  createAnnotationAt: { pointData: { x: number; y: number }; markerColor: string };
};

const ScatterPlotAnnotationForm = (props: AnnotationFormProps) => {
  const [t] = useTranslations();

  const { onSubmit, onClose, createAnnotationAt, chartUnits } = props;
  const { pointData, markerColor } = createAnnotationAt;

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [missingField, setMissingField] = useState('');

  const onChange = (_: object, value: string) => setComment(value);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!comment) {
      setMissingField('comment');
      return;
    }

    setLoading(true);
    try {
      await onSubmit(pointData, comment);
      onClose();
    } catch (error) {
      setLoading(false);
      setMissingField('comment');
    }
  };

  return (
    <Styled.Form id="annotation-form" onSubmit={handleSubmit}>
      <Styled.LabelHeading>
        <h2>{t('Add Note')}</h2>
      </Styled.LabelHeading>
      <Styled.PointInfo>
        <Styled.Dot markerColor={markerColor} />
        <Point desc="Y:" data={pointData.y} unit={chartUnits.yUnit} />
        <Point desc="X:" data={pointData.x} unit={chartUnits.xUnit} />
      </Styled.PointInfo>
      <div>
        <Styled.Label>{t('Your note')}</Styled.Label>
        <InputTextArea
          type="text"
          property="comment"
          value={comment}
          onChange={onChange}
          highlightError={missingField === 'comment'}
          model={undefined}
          id={undefined}
          placeholder={undefined}
          readOnly={undefined}
          required={undefined}
          maxLength={undefined}
          height={undefined}
          disabled={undefined}
          className={undefined}
          simple={undefined}
        />
      </div>
      <Styled.ButtonContainer>
        <PrimaryButton naked large block onClick={onClose} loading={loading}>
          {t('Cancel')}
        </PrimaryButton>
        <PrimaryButton confirm large block loading={loading}>
          {t('Save')}
        </PrimaryButton>
      </Styled.ButtonContainer>
    </Styled.Form>
  );
};

ScatterPlotAnnotationForm.propTypes = {
  position: PropTypes.shape({
    chartX: PropTypes.number,
    chartY: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default translations(ScatterPlotAnnotationForm);
