import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import intersection from 'lodash/intersection';
import Helmet from 'react-helmet';
import { StandardPage } from 'components/index';
import translations from 'decorators/Translations/translations';
import { getPartnerNumbers } from 'utils/profile';
import { isValidPartner } from 'utils/Data/partners';
import Header from 'containers/Application/Header/Header';
import find from 'lodash/find';
import memoizeOne from 'memoize-one';

import { pushToDataLayer } from 'utils/Analytics/analytics';
import { icons } from 'components/Icon/IconNames';
import { GO_BACK_LINK } from 'containers/Application/Admin/utils';

import { createServiceRequest, loadFunctionalLocations, loadPartnerMeta } from 'redux/modules';
import { ServiceRequestFormWrapper } from './ServiceRequestFormWrapper';

class ServiceRequest extends Component {
  state = { saving: false, notificationType: '', newServiceRequest: {} };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadData();
    }
  }

  componentDidMount() {
    const { profile } = this.props;
    if (profile?.phoneNumber) {
      this.setProperty('createdByPhoneNumber', profile.phoneNumber);
    }
    this.loadData();
  }

  loadData = () => {
    const {
      loadFunctionalLocation,
      loadPartnerMeta,
      location: { search },
      match: {
        params: { partnerNumber },
      },
    } = this.props;

    const query = queryString.parse(search),
      functionalLocationKey = query.functionalLocation;

    functionalLocationKey && loadFunctionalLocation(functionalLocationKey);

    if (partnerNumber && partnerNumber !== 'all') {
      loadPartnerMeta(partnerNumber);
    }
  };

  setProperty = (propertyName, value) => {
    this.setState(state => ({
      newServiceRequest: {
        ...state.newServiceRequest,
        [propertyName]: value,
      },
    }));
  };

  createServiceRequest = () => {
    const { profile, functionalLocations, customers } = this.props;
    const { newServiceRequest } = this.state;
    const data = Object.assign({}, newServiceRequest);

    this.setState({ notificationType: '' });
    this.setState({ saving: true });

    // If we are in '/all' -path, find a partnerNumber from FL that is also in user's profile
    // (there should always be at least one)
    if (!isValidPartner(data.partnerNumber)) {
      const profilePartnerNumbers = getPartnerNumbers(profile);
      const functionalLocation = functionalLocations[data.functionalLocation];
      const commonPartnerNumbers =
        functionalLocation && intersection(profilePartnerNumbers, functionalLocation.partnerNumberWithParents);
      if (!commonPartnerNumbers.length) {
        this.setState({ saving: false });
        return;
      }
      data.partnerNumber = commonPartnerNumbers[0];
    }

    data.customerName = customers[data.partnerNumber]?.name;

    this.props.createServiceRequest(data).then(result => {
      if (result.error) {
        this.setState({ saving: false, notificationType: 'error' });
      } else {
        this.setState({ saving: false, newServiceRequest: {}, notificationType: 'success' });
      }
    });

    if (this.state.saved) {
      pushToDataLayer({ event: 'submitServiceRequest', category: data.category });
    }
  };

  render() {
    const {
      t,
      functionalLocations,
      match: {
        params: { partnerNumber, functionalLocationId },
      },
      features,
      equipment,
      superordinate,
    } = this.props;

    const { newServiceRequest } = this.state;

    if (features && !features.sr) {
      return null;
    }

    const functionalLocation = functionalLocations[functionalLocationId];

    const links = [GO_BACK_LINK, { title: 'Service Request', icon: icons.NEW_SERVICE_REQUEST }];

    const content = (
      <ServiceRequestFormWrapper
        id="serviceRequestForm"
        model={newServiceRequest}
        setProperty={this.setProperty}
        submit={this.createServiceRequest}
        t={t}
        functionalLocation={functionalLocation}
        partnerNumber={partnerNumber}
        equipment={equipment}
        superordinate={superordinate}
        saving={this.state.saving}
        notificationType={this.state.notificationType}
      />
    );
    if (functionalLocationId) {
      return content;
    }

    return (
      <StandardPage>
        <Helmet title={t('Service Request')} />
        <Header t={t} showPartnerSelect links={links} selected="service request" />
        {content}
      </StandardPage>
    );
  }
}

ServiceRequest.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  functionalLocations: PropTypes.objectOf(PropTypes.object).isRequired,
  profile: PropTypes.object,
  features: PropTypes.objectOf(PropTypes.bool),
  equipment: PropTypes.object,
  superordinate: PropTypes.object,
  loadFunctionalLocation: PropTypes.func.isRequired,
  loadPartnerMeta: PropTypes.func.isRequired,
  createServiceRequest: PropTypes.func.isRequired,
  customers: PropTypes.object.isRequired,
};

const getEquipmentById = (equipment, id) => (id ? find(equipment, { equipmentNumber: id }) : undefined);
const getEquipment = memoizeOne(getEquipmentById);
const getSuperordinate = memoizeOne(getEquipmentById);

const mapStateToProps = (state, props) => ({
  features: state.profile.profile.features,
  functionalLocations: state.functionalLocations.functionalLocations,
  equipment: getEquipment(
    state.equipments.equipments[props.match.params.functionalLocationId],
    props.match.params.equipmentNumber
  ),
  superordinate: getSuperordinate(
    state.equipments.equipments[props.match.params.functionalLocationId],
    props.match.params.superordinate
  ),
  customers: state.customer.customers,
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  createServiceRequest,
  loadFunctionalLocation: functionalLocationId => loadFunctionalLocations([functionalLocationId]),
  loadPartnerMeta,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(translations(ServiceRequest)));
