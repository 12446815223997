import { CoreRow, flexRender, Row, Table } from '@tanstack/react-table';
import { Header, Cell, RowData, HeaderGroup } from '@tanstack/table-core/build/lib/types';

import React, { ComponentType, CSSProperties, MutableRefObject } from 'react';

import { TableWindowVirtualizer } from 'components/VirtualizedTable/useVirtualizedTable';
import { DefaultColumnDragHandle } from 'components/VirtualizedTable/DefaultColumnDragHandle';
import { DefaultColumnSortingControl } from 'components/VirtualizedTable/DefaultColumnSortingControl';
import usePrintingMode from 'components/FlexTable/usePrintingMode';

import { SkeletonCell } from './shared/SkeletonCell';

import { generateRandomIdNotIn } from './GenericTable';

import * as styles from './GenericWindowVirtualizedTable.module.scss';

import {
  GenericColumnSortingControlComponent,
  GenericColumnHandleComponent,
  GenericTableComponentProps,
  GenericTDComponent,
  GenericTHComponent,
  GenericTRComponent,
  TableHorizontalScrollerProps,
  GenericTR,
  GenericTD,
  GenericTH,
  TableHorizontalScroller,
  TableHeaderContainer,
  TableHeaderLabel,
} from './GenericTable';

export type GenericVirtualizedTableProps = GenericTableComponentProps & {
  virtualizer: TableWindowVirtualizer;
  flex?: boolean;
};

export type GenericVirtualizedTableComponent = ComponentType<GenericVirtualizedTableProps>;

export function GenericVirtualizedTable({
  table,
  virtualizer,
  children,
  style,
  flex,
  ...props
}: GenericVirtualizedTableProps) {
  let width: string | number | undefined = flex ? props.width ?? undefined : table.getTotalSize();
  let minWidth: string | undefined = undefined;

  if (table.options.meta?.width === 'auto') {
    width = undefined;
  }

  if (table.options.meta?.width === 'fill') {
    minWidth = '100%';
  }

  if (table.options.meta?.width === 'full') {
    minWidth = '100%';
  }
  return (
    <table
      style={{
        position: 'relative',
        width,
        minWidth,
        height: 'fit-content',
        ...style,
      }}
      {...props}
    >
      {children}
    </table>
  );
}

export type GenericWindowVirtualizedTableProps<TData extends RowData> = {
  table: Table<TData>;
  virtualizer: TableWindowVirtualizer;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  Table?: GenericVirtualizedTableComponent;
  TR?: GenericTRComponent;
  TH?: GenericTHComponent;
  TD?: GenericTDComponent;
  ColumnDragHandle?: GenericColumnHandleComponent<TData>;
  ColumnSortingControl?: GenericColumnSortingControlComponent<TData>;
  scrollerProps?: Omit<TableHorizontalScrollerProps<TData>, 'table'>;
  onRowClick?(e: React.MouseEvent<HTMLElement>, row: CoreRow<TData>): () => void;
  flex?: boolean;
};

function canSort<TData extends RowData>(table: Table<TData>, header: Header<TData, unknown>): boolean {
  return !!table.options.enableSorting && header.column.getCanSort();
}

/**
 * Generic, unstyled table component with row virtualization attached to the window scrolling.
 * Note that this won't work with scrollable container elements, that requires `useVirtualizer`
 * instead of `useWindowVirtualizer` {@see useVirtualizedTable}.
 *
 * If header is a string it will be wrapped to a default `div.textLabel` that can be styled using
 * a style provider component, see {@link DefaultTableStyleProvider}. Similar thing happens to cells
 * but that comes from the {@link useVirtualizedTable} hook.
 *
 * To enable side-scrolling, confine the component that contains this table to a given width, e.g. '100%'.
 * E.g. {@link RichWindowVirtualizedTable} does this for you.
 *
 * In printing mode the virtualization is disabled.
 *
 * @param table As output by e.g. {@link useVirtualizedTable}.
 * @param virtualizer As output by e.g. {@link useVirtualizedTable}.
 * @param containerRef Use this reference to get the dimensions for virtualization.
 * @param Table Component to render `<table>`, defaults to {@link GenericVirtualizedTable}.
 * @param TR Component to render `<tr>`, defaults to {@link GenericTR}.
 * @param TH Component to render `<th>`, defaults to {@link GenericTH}.
 * @param TD Component to render `<td>`, defaults to {@link GenericTD}.
 * @param ColumnDragHandle Component to render column drag handle, if resize is enabled.
 * @param ColumnSortingControl Component to render sorting status, if sorting is enabled (asc/desc/none).
 * @param scrollerProps Any props to pass to the enclosing `<TableHorizontalScroller>`.
 *
 * @example Adjust virtualizer for the table container's offset
 *   const { containerRef, scrollMargin } = useVirtualizedTableScrollMargin();
 *   const virtualizerOptions = { scrollMargin, ... };
 *   return <GenericWindowVirtualizedTable containerRef={containerRef} ...
 */
export function GenericWindowVirtualizedTable<TData extends RowData>({
  table,
  virtualizer,
  containerRef,
  Table = GenericVirtualizedTable,
  TR = GenericTR,
  TH = GenericTH,
  TD = GenericTD,
  ColumnDragHandle = DefaultColumnDragHandle,
  ColumnSortingControl = DefaultColumnSortingControl,
  scrollerProps,
  onRowClick,
  flex,
}: GenericWindowVirtualizedTableProps<TData>): JSX.Element {
  const printing = usePrintingMode();

  const items = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  const firstItemStart = items.length > 0 ? items?.[0]?.start || 0 : 0;
  const lastItemEnd = items.length > 0 ? totalSize - (items?.[items.length - 1]?.end || 0) : 0;

  const paddingTop = firstItemStart - virtualizer.options.scrollMargin;
  const paddingBottom = lastItemEnd + virtualizer.options.scrollMargin;

  const itemsToRender = printing ? table.getRowModel().rows : items;

  const displayingPreviousData = Boolean(table.options.meta?.displayingPreviousData);
  const skeletonRowsToRender = table.options.meta?.displaySkeleton ? [...Array(3)] : [];

  const flatHeaderGroups = [
    ...table.getLeftHeaderGroups(),
    ...table.getCenterHeaderGroups(),
    ...table.getRightHeaderGroups(),
  ];

  // Make sure that pinned groups are split
  const topLevelHeaderGroups: HeaderGroup<TData>[] = table.getHeaderGroups().map(group => ({ ...group, headers: [] }));
  for (const headerGroup of flatHeaderGroups) {
    topLevelHeaderGroups[headerGroup.depth].headers.push(...headerGroup.headers);
  }

  return (
    <div ref={containerRef}>
      <TableHorizontalScroller table={table} {...scrollerProps}>
        <Table
          table={table as Table<unknown>}
          virtualizer={virtualizer}
          className={[displayingPreviousData && 'previousData', styles.table].filter(Boolean).join(' ')}
          flex={flex}
        >
          <thead>
            {topLevelHeaderGroups.map(headerGroup => {
              const headers = headerGroup.headers.map(header => {
                // Make sure that only headers that come from left or right are marked as pinned.
                // Column grouping combined with pinning can cause groups to be split in the middle.
                // Note! The string matching relies on library implementation details and is subject
                // to change. If this happens, e.g. another index can be built to check where the
                // header comes from.
                const headerIsPinned =
                  header.column.getIsPinned() &&
                  (header.headerGroup.id.startsWith('left_') || header.headerGroup.id.startsWith('right_'));

                const headerClasses = [header.column.getIsSorted() && 'sorted', headerIsPinned && 'pinned']
                  .filter(Boolean)
                  .join(' ');

                const headerStyles: CSSProperties = {};
                if (headerIsPinned) {
                  headerStyles.position = 'sticky';
                  if (header.column.getIsPinned() === 'left') {
                    headerStyles.left = header.column.getStart('left');
                  } else if (header.column.getIsPinned() === 'right') {
                    headerStyles.right = header.column.getStart('right');
                  }
                }

                return (
                  <TH
                    key={header.id}
                    colSpan={header.colSpan}
                    table={table as Table<unknown>}
                    header={header as Header<unknown, unknown>}
                    data-pinned={header.column.getIsPinned() || undefined}
                    className={headerClasses}
                    style={{
                      ...headerStyles,
                      width: header.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : header.getSize(),
                    }}
                  >
                    <TableHeaderContainer
                      onClick={canSort(table, header) ? header.column.getToggleSortingHandler() : undefined}
                    >
                      <TableHeaderLabel header={header} />
                      {canSort(table, header) && <ColumnSortingControl header={header as Header<TData, unknown>} />}
                    </TableHeaderContainer>

                    {table.options.enableColumnResizing && header.column.getCanResize() && (
                      <ColumnDragHandle table={table} header={header as Header<TData, unknown>} />
                    )}
                  </TH>
                );
              });

              if (table.options.meta?.width === 'fill') {
                const ids = headerGroup.headers.map(header => header.id);
                headers.push(<th key={generateRandomIdNotIn(ids)} />);
              }

              return (
                <TR key={headerGroup.id} table={table as Table<unknown>}>
                  {headers}
                </TR>
              );
            })}
          </thead>

          <tbody>
            {!printing && paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}

            {!printing &&
              skeletonRowsToRender.map((empty, index) => {
                const childColumns = table.getVisibleLeafColumns().map(column => (
                  <td key={column.id}>
                    <SkeletonCell />
                  </td>
                ));

                if (table.options.meta?.width === 'fill') {
                  const ids = table.getVisibleLeafColumns().map(column => column.id);
                  childColumns.push(<td key={generateRandomIdNotIn(ids)} />);
                }

                return <tr key={String(index)}>{childColumns}</tr>;
              })}

            {itemsToRender.map(item => {
              const row = table.getRowModel().rows[item.index];

              const visibleCells = [
                ...row.getLeftVisibleCells(),
                ...row.getCenterVisibleCells(),
                ...row.getRightVisibleCells(),
              ];
              const rowClasses = [
                row.getIsExpanded() && 'expanded',
                row.getIsSelected() && 'selected',
                onRowClick && styles.hoverable,
              ]
                .filter(Boolean)
                .join(' ');

              const cells = visibleCells.map(cell => {
                const cellClasses = [cell.column.getIsSorted() && 'sorted', cell.column.getIsPinned() && 'pinned']
                  .filter(Boolean)
                  .join(' ');

                const cellStyles: CSSProperties = {};

                if (cell.column.getIsPinned()) {
                  cellStyles.position = 'sticky';
                  if (cell.column.getIsPinned() === 'left') {
                    cellStyles.left = cell.column.getStart('left');
                  } else if (cell.column.getIsPinned() === 'right') {
                    cellStyles.right = cell.column.getStart('right');
                  }
                }

                return (
                  <TD
                    key={cell.id}
                    table={table as Table<unknown>}
                    cell={cell as Cell<unknown, unknown>}
                    data-pinned={cell.column.getIsPinned() || undefined}
                    data-depth={row.depth}
                    className={cellClasses}
                    style={{
                      ...cellStyles,
                      width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TD>
                );
              });

              if (table.options.meta?.width === 'fill') {
                const ids = visibleCells.map(cell => cell.id);
                cells.push(<td key={generateRandomIdNotIn(ids)} />);
              }

              return (
                <TR
                  key={row.id}
                  table={table as Table<unknown>}
                  row={row as Row<unknown>}
                  ref={printing ? undefined : virtualizer.measureElement}
                  className={rowClasses}
                  data-depth={row.depth}
                  data-index={item.index}
                  onClick={e => onRowClick?.(e, row)}
                >
                  {cells}
                </TR>
              );
            })}

            {!printing && paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </Table>
      </TableHorizontalScroller>
    </div>
  );
}
