import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import BreakdownTab from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownTab/BreakdownTab';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import ExportData from 'components/ExportData/ExportData';
import SnackBar from 'components/SnackBar/SnackBar';
import PrimaryButton from 'components/Button/PrimaryButton';
import withQuery from 'decorators/Query/withQuery';
import translations from 'decorators/Translations/translations';
import recyclingBuildingLevelTabs from 'containers/Application/Modules/RecyclingModule/tabs';
import { onClickPrint } from 'utils/Print/print';
import {
  isWasteSensor,
  ButtonDropdownWrapper,
} from 'containers/Application/Recycling/RecyclingBenchmark/RecyclingBenchmark';
import { DATE_RANGES } from 'components/Form/DateTools';
import { GRANULARITY } from 'components/ExportData/ExportSensorDataForm';

export const WASTE_BREAKDOWN_ELEMENT_ID = 'waste-breakdown';

export const WasteBreakdown = ({ t, query, setQuery }) => {
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [notification, setNotification] = React.useState({
    visible: false,
  });
  const wasteBreakdownTabs = recyclingBuildingLevelTabs.wasteBreakdownTabs;
  const selectedTab = query?.breakdownTab || wasteBreakdownTabs.WASTE_AMOUNTS.id;
  const tabOptions = useMemo(
    () =>
      Object.keys(wasteBreakdownTabs).map(key => ({
        value: wasteBreakdownTabs[key].id,
        label: t(wasteBreakdownTabs[key].label),
      })),
    [t, wasteBreakdownTabs]
  );
  const tabComponents = useMemo(
    () =>
      Object.keys(wasteBreakdownTabs).map(key => (
        <BreakdownTab key={key} tab={wasteBreakdownTabs[key]} visible={selectedTab === wasteBreakdownTabs[key].id} />
      )),
    [wasteBreakdownTabs, selectedTab]
  );
  const showNotification = (message, type) => {
    setNotification({
      visible: true,
      message,
      type,
      showCloseButton: true,
    });
  };
  const hideNotification = () => {
    setNotification({
      visible: false,
    });
  };
  return (
    <Section>
      <ScrollToComponent id={WASTE_BREAKDOWN_ELEMENT_ID} />
      <SectionHeader>
        <SectionTabSelector
          options={tabOptions}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(property, value) =>
            setQuery({
              breakdownTab: value,
              scrollTo: WASTE_BREAKDOWN_ELEMENT_ID,
            })
          }
        />
        <ButtonDropdownWrapper>
          <ButtonDropdown
            secondary
            buttonLabel={t('Export')}
            buttonProps={{ iconName: 'export' }}
            alignment="right"
            items={[
              { label: t('CSV'), onClick: () => setShowExportModal(true) },
              { label: t('PDF'), onClick: onClickPrint },
            ]}
          />
        </ButtonDropdownWrapper>
      </SectionHeader>
      {tabComponents}
      {showExportModal && (
        <ExportData
          onClose={() => setShowExportModal(false)}
          onSuccess={message => showNotification(message, 'success')}
          onError={message => showNotification(message, 'error')}
          sensorFilterFn={isWasteSensor}
          defaultDateRange={DATE_RANGES.DAYS_365}
          defaultGranularity={GRANULARITY.monthly}
          showDateRangeAllOption={true}
        />
      )}
      <SnackBar
        variant={notification.type}
        visible={notification.visible}
        secondaryContent={
          notification.showCloseButton && (
            <PrimaryButton
              confirm={notification.type === 'success'}
              warning={notification.type === 'error'}
              invertColor
              transparentBorder
              large
              onClick={hideNotification}
            >
              {t('Close')}
            </PrimaryButton>
          )
        }
      >
        {notification.message}
      </SnackBar>
    </Section>
  );
};

WasteBreakdown.propTypes = {
  t: PropTypes.func.isRequired,
  query: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
};

export default withQuery(translations(WasteBreakdown));
