import React from 'react';
import styled, { css } from 'styled-components';

import { PaginationStepButton } from './PaginationPageSelector';

export type TinyProps = {
  tiny?: boolean;
};

export const PaginationTinyPageSelectorContainer = styled.div<TinyProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${props =>
    props.tiny
      ? css`
          gap: var(--size-xs) var(--size-xs);
        `
      : css`
          gap: var(--size-sm) var(--size-md);
        `}

  font-size: ${props => props.theme.font.size.xs};

  @media print {
    display: none;
  }
`;

export const PaginationTinyPageSelectorText = styled.div<TinyProps>`
  white-space: nowrap;

  ${props =>
    props.tiny &&
    css`
      font-size: ${props => props.theme.font.size.xxs};
    `}

  font-weight: ${props => props.theme.font.weight.bold};
`;

export type PaginationTinyPageSelectorProps = {
  selectedPage: number;
  totalPages: number;
  onChange?: (newPageNumber: number) => void;
  ultraNarrow?: boolean;
};

/**
 * @param selectedPage Currently selected page.
 * @param totalPages Total number of pages.
 * @param onChange Called when page is changed by the user.
 * @param ultraNarrow Even smaller buttons and texts.
 * @constructor
 */
export function PaginationTinyPageSelector({
  selectedPage,
  totalPages,
  onChange,
  ultraNarrow = false,
}: PaginationTinyPageSelectorProps): JSX.Element {
  const previousButton = (
    <PaginationStepButton
      enabled={selectedPage > 1}
      tiny={ultraNarrow}
      onClick={() => onChange?.(selectedPage - 1)}
      iconName="caret-left"
    />
  );

  const nextButton = (
    <PaginationStepButton
      enabled={selectedPage < totalPages}
      tiny={ultraNarrow}
      onClick={() => onChange?.(selectedPage + 1)}
      iconName="caret-right"
    />
  );

  return (
    <PaginationTinyPageSelectorContainer tiny={ultraNarrow}>
      {previousButton}
      <PaginationTinyPageSelectorText tiny={ultraNarrow}>
        {`${selectedPage}\u00a0/\u00a0${totalPages}`}
      </PaginationTinyPageSelectorText>
      {nextButton}
    </PaginationTinyPageSelectorContainer>
  );
}
