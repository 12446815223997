export const OrderType = {
  ORDER: 'ORDER_TYPE_ORDER',
  PLANNED: 'ORDER_TYPE_PLANNED',
  REQUEST: 'ORDER_TYPE_REQUEST',
};

export const ContentType = {
  ...OrderType,
  LONG_TERM_PLAN: 'CONTENT_TYPE_LONG_TERM_PLAN',
  CHECKLIST: 'CONTENT_TYPE_CHECKLIST',
  DEVIATION: 'DEVIATION',
};

export const STATISTICS_ORDER_REACTION_TIME_HOURS = 'STATISTICS_ORDER_REACTION_TIME_HOURS';
export const STATISTICS_ORDER_DOWNTIME_HOURS = 'STATISTICS_ORDER_DOWNTIME_HOURS';
export const STATISTICS_ORDER_LEAD_TIME_HOURS = 'STATISTICS_ORDER_LEAD_TIME_HOURS';
export const STATISTICS_ORDER_REACTION_TIME_PERCENTS = 'STATISTICS_ORDER_REACTION_TIME_PERCENTS';
export const STATISTICS_ORDER_DOWNTIME_PERCENTS = 'STATISTICS_ORDER_DOWNTIME_PERCENTS';
export const STATISTICS_ORDER_LEAD_TIME_PERCENTS = 'STATISTICS_ORDER_LEAD_TIME_PERCENTS';
export const STATISTICS_ORDER_PERFORMANCE_RATIO = 'STATISTICS_ORDER_PERFORMANCE_RATIO';
export const STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE = 'STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE';
export const STATISTICS_ORDER_VOLUMES_BY_BUILDING = 'STATISTICS_ORDER_VOLUMES_BY_BUILDING';
export const STATISTICS_ORDERS_PER_AREA_BY_BUILDING = 'STATISTICS_ORDERS_PER_AREA_BY_BUILDING';
export const STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT = 'STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT';
export const STATISTICS_ORDER_VOLUMES_BY_TYPE = 'STATISTICS_ORDER_VOLUMES_BY_TYPE';
export const STATISTICS_ORDER_VOLUMES_BY_PROVIDER = 'STATISTICS_ORDER_VOLUMES_BY_PROVIDER';

export const STATISTICS_PLANNED_PERFORMANCE_RATIO = 'STATISTICS_PLANNED_PERFORMANCE_RATIO';
export const STATISTICS_PLANNED_VOLUMES_BY_BUILDING = 'STATISTICS_PLANNED_VOLUMES_BY_BUILDING';
export const STATISTICS_PLANNED_VOLUMES_BY_PROVIDER = 'STATISTICS_PLANNED_VOLUMES_BY_PROVIDER';
export const STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE = 'STATISTICS_PLANNED_VOLUMES_BY_DISCIPLINE';
export const STATISTICS_PLANNED_VOLUMES_BY_TYPE = 'STATISTICS_PLANNED_VOLUMES_BY_TYPE';

export const STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_BUILDING = 'STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_BUILDING';
export const STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_CATEGORY = 'STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_CATEGORY';
export const STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_YEAR = 'STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_YEAR';

export const STATISTICS_TYPES = {
  [ContentType.ORDER]: [
    STATISTICS_ORDER_PERFORMANCE_RATIO,
    STATISTICS_ORDER_VOLUMES_BY_DISCIPLINE,
    STATISTICS_ORDER_VOLUMES_BY_BUILDING,
    STATISTICS_ORDERS_PER_AREA_BY_BUILDING,
    STATISTICS_ORDER_VOLUMES_BY_EQUIPMENT,
    STATISTICS_ORDER_VOLUMES_BY_PROVIDER,
    STATISTICS_ORDER_VOLUMES_BY_TYPE,
    STATISTICS_ORDER_DOWNTIME_HOURS,
    STATISTICS_ORDER_LEAD_TIME_HOURS,
    STATISTICS_ORDER_REACTION_TIME_HOURS,
    STATISTICS_ORDER_DOWNTIME_PERCENTS,
    STATISTICS_ORDER_LEAD_TIME_PERCENTS,
    STATISTICS_ORDER_REACTION_TIME_PERCENTS,
  ],
  [ContentType.PLANNED]: [
    STATISTICS_PLANNED_PERFORMANCE_RATIO,
    STATISTICS_PLANNED_VOLUMES_BY_BUILDING,
    STATISTICS_PLANNED_VOLUMES_BY_PROVIDER,
    STATISTICS_PLANNED_VOLUMES_BY_TYPE,
  ],
  [ContentType.LONG_TERM_PLAN]: [
    STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_BUILDING,
    STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_YEAR,
    STATISTICS_LONG_TERM_PLAN_BUDGETS_BY_CATEGORY,
  ],
};

export const FILTERABLE_FIELDS = [
  'status',
  'type',
  'location',
  'address',
  'city',
  'discipline',
  'supplier',
  'priority',
  'source',
  'profile',
  'removed',
  'stopped',
  'overdue',
  'checklistTemplate',
];

export const DEFAULT_FILTER = {
  removed: 'false',
};

export const OrderStatus = {
  OPEN: 'Open',
  OVERDUE: 'Late',
  COMPLETED_ON_TIME: 'Completed on time',
  COMPLETED_EARLY: 'Completed early',
  COMPLETED_LATE: 'Completed late',
  IN_PROGRESS: 'In Progress',
  PARTLY_COMPLETED: 'Partly Completed',
  COMPLETED: 'Completed',
  POSTPONED: 'Postponed',
  PLANNED: 'Planned',
  DELAYED: 'Postponed',
  RELEASED: 'Released',
  STARTED: 'Started',
  ARRIVED: 'Arrived',
  DRAFT: 'Draft',
  CANCELLED: 'Cancelled',
  REQUEST: 'Request',
};

export const OrderStatusColors = {
  [OrderStatus.OPEN]: 'openColor',
  [OrderStatus.OVERDUE]: 'overdueColor',
  [OrderStatus.COMPLETED_ON_TIME]: 'completedColor',
  [OrderStatus.COMPLETED_EARLY]: 'completedColor',
  [OrderStatus.COMPLETED_LATE]: 'lateColor',
  [OrderStatus.IN_PROGRESS]: 'inProgressColor',
  [OrderStatus.PARTLY_COMPLETED]: 'partlyCompletedColor',
  [OrderStatus.COMPLETED]: 'completedColor',
  [OrderStatus.POSTPONED]: 'postponedColor',
  [OrderStatus.PLANNED]: 'plannedColor',
  [OrderStatus.DELAYED]: 'delayedColor',
  [OrderStatus.RELEASED]: 'releasedColor',
  [OrderStatus.STARTED]: 'startedColor',
  [OrderStatus.ARRIVED]: 'arrivedColor',
  [OrderStatus.DRAFT]: 'draftColor',
  [OrderStatus.CANCELLED]: 'cancelledColor',
  [OrderStatus.REQUEST]: 'requestColor',
};

export const LongTermPlanStatusForUser = {
  PLANNED: 'Planned',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  TO_BE_MOVED: 'To be moved',
};
export const LongTermPlanStatus = { ...LongTermPlanStatusForUser, REMOVED: 'Removed' };

export const LongTermPlanStatusColors = {
  [LongTermPlanStatus.PLANNED]: 'plannedColor',
  [LongTermPlanStatus.ACCEPTED]: 'acceptedColor',
  [LongTermPlanStatus.REJECTED]: 'rejectedColor',
  [LongTermPlanStatus.COMPLETED]: 'completedColor',
  [LongTermPlanStatus.TO_BE_MOVED]: 'toBeMovedColor',
  [LongTermPlanStatus.REMOVED]: 'removedColor',
};

export const SyntheticOrderStatus = {
  UNFINISHED: 'Open / Unfinished',
  COMPLETED_OTHER: 'Completed, other',
};

export const ChecklistReportStatus = {
  UNDONE: 'Undone',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const ChecklistReportStatusColors = {
  [ChecklistReportStatus.UNDONE]: 'undoneColor',
  [ChecklistReportStatus.COMPLETED]: 'completedColor',
  [ChecklistReportStatus.IN_PROGRESS]: 'inProgressColor',
};

export const ViewMode = {
  LIST: 'list',
  CALENDAR: 'calendar',
  CALENDAR_PLAN: 'calendarPlan',
};

export const ExternalType = {
  SAP: 'sap',
  TAMPUURI: 'tampuuri',
  SMARTVIEW: 'smartview',
};

export const SERVICE_ORDER_CONFIGURATION_META_KEYS = {
  hidePlanon: 'hide_planon_service_orders',
  hideSap: 'hide_sap_service_orders',
  hideSmartView: 'hide_smartView_service_orders',
};

export const PLANNED_MAINTENANCE_CONFIGURATION_META_KEYS = {
  hidePlanon: 'hide_planon_planned_maintenance',
  hideSap: 'hide_sap_planned_maintenance',
  hideSmartView: 'hide_smartView_planned_maintenance',
};

export const SmartViewPriority = {
  URGENT: 'urgent',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const OverdueHourLimit = {
  [SmartViewPriority.URGENT]: 3,
  [SmartViewPriority.HIGH]: 5,
  [SmartViewPriority.MEDIUM]: 16, // between 7:00-15:30 on business days
  [SmartViewPriority.LOW]: 40, // between 7:00-15:30 on business days
};

export const DEFAULT_PRIORITY = 4;

export const SLA_META_KEY_REACTION_TIME = 'SLA_reactionTime_prio';
export const SLA_META_KEY_DOWNTIME = 'SLA_downtime_prio';
export const SLA_META_KEY_LEAD_TIME = 'SLA_leadTime_prio';

/**
 * Service order breakdown fields have been changing, so this mapping is used when orders are processed.
 */
export const BreakdownFields = {
  REACTION_TIME: 'reactionTime',
  DOWNTIME: 'breakdownTime',
  LEAD_TIME: 'downtime',
};

export const GROUP_UNKNOWN = 'UNKNOWN';

export const ListConfigurationProperty = {
  [OrderType.ORDER]: 'serviceOrders',
  [OrderType.PLANNED]: 'plannedMaintenances',
  [ContentType.DEVIATION]: 'deviations',
  [ContentType.CHECKLIST]: 'checklistReports',
};
export const DEFAULT_COLUMNS = {
  [OrderType.ORDER]: ['title', 'createdDate', 'modifiedDate', 'location', 'actions'],
  [OrderType.PLANNED]: ['title', 'modifiedDate', 'plannedDate', 'location', 'actions'],
  [OrderType.REQUEST]: ['title', 'serviceOrderNumber', 'createdDate', 'location', 'actions'],
  [ContentType.DEVIATION]: [
    'status',
    'id',
    'friendlyId',
    'equipmentNumber',
    'category',
    'createdDate',
    'question',
    'information',
    'estimatedPrice',
    'address',
    'discipline',
    'templateName',
    'modifiedDate',
  ],
  [ContentType.CHECKLIST]: ['title', 'score', 'created', 'location', 'supplierName'],
};

export const getListConfiguration = (profile, type = OrderType.ORDER) => {
  const columns = profile.listConfiguration?.[ListConfigurationProperty[type]] || DEFAULT_COLUMNS[type] || [];
  if (columns?.includes('actions') === false) {
    columns.push('actions');
  }
  if (profile.features?.serviceModule === false) {
    columns.splice(columns.indexOf('actions'), 1);
  }
  return columns;
};

export const SERVICE_CLASSIFICATION_CODE_TYPE = 'service';

export const PLANNED_MAINTENANCE_SERVICE_ORDERS = [
  'Y02',
  'FVP',
  'FVL', // Danish
  'Z10', // Swedish
  'Z11', // Swedish
  'Z12', // Swedish
  'Z13', // Swedish
  'Z14', // Swedish
  'Z15', // Swedish
  'Z16', // Swedish
  'Z17', // Swedish
  'Z18', // Swedish
  'Z19', // Swedish
];
