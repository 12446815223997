import React from 'react';
import { TabOption } from './Tabs';
import * as Styled from './Tabs.styled';

interface TabBarProps {
  tabs: TabOption[];
  selectedTabIndex: number;
  setSelectedTabIndex: (arg0: number) => void;
}

const TabBar = (props: TabBarProps) => {
  const { tabs, selectedTabIndex, setSelectedTabIndex } = props;

  return (
    <Styled.TabContainer>
      {tabs.map((tab, idx) => (
        <Styled.Tab
          key={tab.id}
          onClick={() => setSelectedTabIndex(idx)}
          selected={idx === selectedTabIndex}
          disabled={tab.disabled}
        >
          <span>{tab.title}</span>
        </Styled.Tab>
      ))}
    </Styled.TabContainer>
  );
};

export default TabBar;
