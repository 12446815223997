import React from 'react';

const ContactEmail = () => (
  <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
    <path
      d="M30.3,4.6H1.7C0.7,4.6,0,5.3,0,6.2v18.9c0,1.3,1,2.3,2.3,2.3h27.3c1.3,0,2.3-1,2.3-2.3V6.2C32,5.3,31.3,4.6,30.3,4.6z
     M17.8,15c-0.5,0.4-1.2,0.6-1.8,0.6c-0.7,0-1.4-0.2-1.8-0.6L3.8,6.6h24.4L17.8,15z M29.7,25.4H2.3c-0.2,0-0.3-0.1-0.3-0.3V7.7
    l9.7,7.9l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l5.1-5.1c0.8,0.5,1.8,0.8,2.7,0.8c0.9,0,1.9-0.3,2.7-0.8l5.1,5.1
    c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-4.9-4.9L30,7.7v17.4C30,25.3,29.9,25.4,29.7,25.4z"
    />
  </svg>
);

export default ContactEmail;
