import React, { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTable, useSortBy, usePagination, useRowSelect, useExpanded } from 'react-table';

import withQueryPaging from './withQueryPaging';
import TableHead from './FlexTableHead';
import TableFooter from './FlexTableFooter';
import TableBody, { Row, Cell } from './FlexTableBody';
import Pagination from './Pagination';
import withLoadingSkeleton from '../Skeletons/withLoadingSkeleton';
import FlexTableSkeleton from './FlexTableSkeleton';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import useRenderers from './useRenderers';
import usePrintingMode from './usePrintingMode';
import useSortingChange from './useSortingChange';
import useNestedRows from './useNestedRows';
import useSelectableRows from './useSelectableRows';

export const StyledTable = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.horizontalScroll && '100%'};
  width: ${props => (props.horizontalScroll ? 'fit-content' : '100%')};
  overflow: ${props => !props.horizontalScroll && 'auto'};
  color: var(--body-text-fg);
  ${props => (props.topStripe ? `border-top: var(--table-row-border);` : '')};
`;
StyledTable.displayName = 'StyledTable';

const HorizonalScrollPadding = styled.div`
  padding: ${props => (props.verticalPadding ? 'var(--size-sm)' : '0')}
    ${props => (props.horizontalPadding ? 'var(--size-md)' : '0')};

  ${props => props.theme.media.portrait`
  padding:
    ${props => (props.verticalPadding ? 'var(--size-md)' : '0')}
    ${props => (props.horizontalPadding ? 'var(--size-lg)' : '0')};
  `}

  padding-top: ${props => props.paginator && '0'};
  ${props => props.theme.media.portrait`
    padding-top: ${props => props.paginator && '0'};
  `}
`;
HorizonalScrollPadding.displayName = 'HorizonalScrollPadding';

export const cellPropTypes = {
  row: PropTypes.shape({
    original: PropTypes.object,
  }),
};

const getTableHooks = ({ onSelectedChange, paginate, expandAllRows, expandRows }) => {
  const tableHooks = [useSortBy];
  if (paginate) {
    tableHooks.push(usePagination);
  }
  if (expandAllRows || expandRows) {
    tableHooks.push(useExpanded);
  }
  if (onSelectedChange) {
    tableHooks.push(useRowSelect);
  }
  return tableHooks;
};

const DEFAULT_HORIZONTAL_SCROLL = {
  enabled: undefined,
  borderRadius: undefined,
  horizontalPadding: undefined,
  verticalPadding: undefined,
};

export const FlexTable = props => {
  const {
    columns,
    data,
    onClick,
    paginate = false,
    manualPagination = false,
    showPagination = true,
    useSimpleShowAll = false,
    pageSize = 20,
    pageCount,
    state,
    totalRowCount,
    useResetPagination = () => undefined,
    removeHead = false,
    showFooter = false,
    initialSortBy = [],
    hiddenColumns = [],
    initialPageIndex = 0,
    autoResetPage = true,
    autoResetSortBy = true,
    onChangePageIndex,
    onChangeShowAll,
    CellComponent = Cell,
    RowComponent = Row,
    onSelectedChange,
    onSortBy,
    customRowHeight,
    isRowSelectable,
    initialSelected = {},
    tableRef,
    getSubRows,
    getRowId,
    expandAllRows,
    expandRows,
    originalData,
    manualSortBy = false,
    disableRowSelection = false, // Use when you need to toggle row selecting on/off
    manualRowSelection = false, // Use if you want to control the selected rows instead of react-table
    manualRowSelectedKey, // Use when you want to have row selection info in original row data
    horizontalScroll = DEFAULT_HORIZONTAL_SCROLL, // Use HorizontalScroll wrapper element for horizontal scroll instead of default behavior
    receiveToggleAllRowsSelected,
    className,
  } = props;

  const containerRef = useRef(null);
  const [showAll, setShowAll] = useState(!paginate);
  const printingMode = usePrintingMode();

  // Note that you can not change plugin hooks after mount to enable/disable them - follow The Laws of Hooks
  const hooks = useMemo(() => getTableHooks({ onSelectedChange, paginate, expandAllRows, expandRows }), []); // eslint-disable-line react-hooks/exhaustive-deps

  // Add a new action to deselect all rows on all pages
  // (the default functionality doesn't work correctly with
  // controller pagination).
  const stateReducer = (newState, action) => {
    if (action.type === 'deselectAllRows') {
      return { ...newState, selectedRowIds: {} };
    }

    return newState;
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialSortBy,
        pageIndex: initialPageIndex,
        selectedRowIds: initialSelected,
        pageSize,
        hiddenColumns,
      },
      state,
      getSubRows,
      getRowId,
      autoResetPage,
      disableSortRemove: true,
      autoResetSelectedRows: !manualSortBy,
      autoResetSortBy: onSelectedChange ? false : autoResetSortBy,
      autoResetExpanded: !onSelectedChange && !getSubRows,
      manualSortBy,
      manualRowSelectedKey,
      pageCount,
      manualPagination,
      stateReducer,
    },
    ...hooks
  );

  // Pass the toggle function to the parent using a callback
  useEffect(() => {
    if (tableInstance.toggleAllRowsSelected) {
      receiveToggleAllRowsSelected?.(() => tableInstance.dispatch({ type: 'deselectAllRows' }));
    }
  }, [receiveToggleAllRowsSelected, tableInstance]);

  const { handleRowSelection } = useSelectableRows({
    disableRowSelection,
    manualRowSelection,
    tableInstance,
    initialSelected,
    onSelectedChange,
    tableRef,
    data,
    originalData,
  });
  useNestedRows({
    tableInstance,
    expandRows,
    expandAllRows,
  });
  useSortingChange({
    sortBy: tableInstance.state.sortBy,
    onSortBy,
  });

  const rows = paginate && !showAll && !printingMode ? tableInstance.page : tableInstance.rows;

  const { RenderBody, RenderRow } = useRenderers({
    rows,
    isRowSelectable,
    tableInstance,
    onClick,
    customRowHeight,
    showAll,
    RowComponent,
    CellComponent,
    handleRowSelection,
  });

  const table = (
    <StyledTable
      {...tableInstance.getTableProps({ className: className })}
      horizontalScroll={horizontalScroll?.enabled}
    >
      {!removeHead && <TableHead headerGroups={tableInstance.headerGroups} />}
      <TableBody
        rows={rows}
        showAll={showAll}
        printingMode={printingMode}
        RenderBody={RenderBody}
        RenderRow={RenderRow}
      />
      {showFooter && <TableFooter footerGroups={tableInstance.footerGroups} />}
    </StyledTable>
  );

  const handleToggleShowAll = () => {
    const newValue = !showAll;
    if (typeof onChangeShowAll === 'function') {
      onChangeShowAll(newValue);
    }
    setShowAll(newValue);
  };

  useResetPagination(tableInstance);

  return (
    <div ref={containerRef}>
      {horizontalScroll?.enabled ? (
        <HorizontalScroll borderRadius={horizontalScroll?.borderRadius}>
          <HorizonalScrollPadding
            horizontalPadding={horizontalScroll?.horizontalPadding}
            verticalPadding={horizontalScroll?.verticalPadding}
          >
            {table}
          </HorizonalScrollPadding>
        </HorizontalScroll>
      ) : (
        table
      )}
      {paginate && !printingMode && showPagination && (
        <HorizonalScrollPadding
          paginator
          horizontalPadding={horizontalScroll?.horizontalPadding}
          verticalPadding={horizontalScroll?.verticalPadding}
        >
          <Pagination
            showAll={showAll}
            onToggleShowAll={handleToggleShowAll}
            rowCount={totalRowCount ?? tableInstance.rows.length}
            gotoPage={tableInstance.gotoPage}
            onChangePageIndex={onChangePageIndex}
            pageIndex={tableInstance.state.pageIndex}
            pageSize={tableInstance.state.pageSize}
            tableRef={containerRef}
            useSimpleShowAll={useSimpleShowAll}
            manualPagination={manualPagination}
          />
        </HorizonalScrollPadding>
      )}
    </div>
  );
};

FlexTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  initialSortBy: PropTypes.array,
  hiddenColumns: PropTypes.array,
  onClick: PropTypes.func,
  removeHead: PropTypes.bool,
  paginate: PropTypes.bool,
  manualPagination: PropTypes.bool,
  showPagination: PropTypes.bool,
  useSimpleShowAll: PropTypes.bool,
  pageSize: PropTypes.number,
  pageCount: PropTypes.number,
  state: PropTypes.object,
  totalRowCount: PropTypes.number,
  initialPageIndex: PropTypes.number,
  useResetPagination: PropTypes.func,
  autoResetPage: PropTypes.bool,
  autoResetSortBy: PropTypes.bool,
  onChangePageIndex: PropTypes.func,
  onChangeShowAll: PropTypes.func,
  CellComponent: PropTypes.elementType,
  RowComponent: PropTypes.elementType,
  'data-test-id': PropTypes.string,
  onSelectedChange: PropTypes.func,
  onSortBy: PropTypes.func,
  customRowHeight: PropTypes.number,
  isRowSelectable: PropTypes.func,
  initialSelected: PropTypes.object,
  tableRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  expandAllRows: PropTypes.bool,
  expandRows: PropTypes.array,
  getSubRows: PropTypes.func,
  getRowId: PropTypes.func,
  originalData: PropTypes.array,
  manualSortBy: PropTypes.bool,
  disableRowSelection: PropTypes.bool,
  manualRowSelection: PropTypes.bool,
  manualRowSelectedKey: PropTypes.string,
  receiveToggleAllRowsSelected: PropTypes.func,
  showFooter: PropTypes.bool,
  horizontalScroll: PropTypes.shape({
    enabled: PropTypes.bool,
    horizontalPadding: PropTypes.bool,
    verticalPadding: PropTypes.bool,
    borderRadius: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default withLoadingSkeleton(React.memo(FlexTable), FlexTableSkeleton);

export const FlexTableWithQueryPaging = withLoadingSkeleton(withQueryPaging(FlexTable), FlexTableSkeleton);
