import styled from 'styled-components';
import { StyledButton as SecondaryButton } from 'components/Button/SecondaryButton/SecondaryButton';

export const Footer = styled.div`
  padding: var(--size-md);
  background-color: ${props => props.theme.colors.alabaster};

  @media print {
    display: none;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  min-height: 26rem;
`;

export const ResetZoomButton = styled(SecondaryButton)`
  position: absolute;
  z-index: 0;
  top: 5px;
  right: 50px;
`;
