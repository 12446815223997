import map from 'lodash/map';
import includes from 'lodash/includes';
import first from 'lodash/first';
import orderBy from 'lodash/orderBy';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import uniqBy from 'lodash/uniqBy';
import meanBy from 'lodash/meanBy';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import compact from 'lodash/compact';
import mergeWith from 'lodash/mergeWith';
import { parse, parseISO, subHours, getISODay } from 'date-fns';
import { formatDistanceToNow } from 'utils/Date/dateFormatter';

import { mapThresholdToStatusValue, getPerformanceLimit, areaUtilizationStatus } from 'utils/Data/performance';

const hourAgo = subHours(new Date(), 1);

export const FLOOR_OPI = {
  airQuality: 'Indoor Air Quality',
  temperature: 'Temperature',
  cleaning: 'Cleaning',
  humidity: 'Humidity',
  co2: 'CO2',
  pm10: 'PM10',
  tvoc: 'TVOC',
  pm25: 'PM2.5',
  radon: 'Radon',
  pressureDifference: 'PressureDifference',
  presenceArea: 'PresenceArea',
  presenceSeat: 'PresenceSeat',
  presenceZone: 'PresenceZone',
  areaUtilizations: 'Area Utilizations',
};

const mapDataForOPI = ({ opi, floorSensors, floorAreas, valuesBySensorId: values, buildingMeta, latestValues }) => {
  function customizer(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return objValue.concat([srcValue]);
    }
    return [srcValue];
  }
  const valuesBySensorId = Object.assign({}, values);
  mergeWith(valuesBySensorId, latestValues, customizer);

  switch (opi) {
    case FLOOR_OPI.airQuality:
      return getAirQualityData(floorSensors, latestValues);
    case FLOOR_OPI.temperature:
      return getTemperatureData(floorSensors, valuesBySensorId, buildingMeta);
    case FLOOR_OPI.co2:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'carbondioxide', buildingMeta);
    case FLOOR_OPI.humidity:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'humidity', buildingMeta);
    case FLOOR_OPI.pm10:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'particle/PM10', buildingMeta);
    case FLOOR_OPI.pm25:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'particle/PM2.5', buildingMeta);
    case FLOOR_OPI.tvoc:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'organic_gas', buildingMeta);
    case FLOOR_OPI.radon:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'radon', buildingMeta);
    case FLOOR_OPI.pressureDifference:
      return getBasicOpiData(floorSensors, valuesBySensorId, 'pressure', buildingMeta);
    case FLOOR_OPI.presenceArea:
      return getPresenceData(floorSensors, valuesBySensorId, 'presence_area', buildingMeta);
    case FLOOR_OPI.presenceSeat:
      return getPresenceData(floorSensors, valuesBySensorId, 'presence_seat', buildingMeta);
    case FLOOR_OPI.presenceZone:
      return getPresenceData(floorSensors, valuesBySensorId, 'presence_zone', buildingMeta);
    case FLOOR_OPI.areaUtilizations:
      return compact(map(floorAreas, area => getAreaUtilizationData(area, latestValues)));
    default:
      return {};
  }
};

export const getOPIData = ({ floorSensors, floorAreas, valuesBySensorId, buildingMeta, latestValues }) => {
  const data = {};
  forEach(values(FLOOR_OPI), opi => {
    data[opi] = mapDataForOPI({ opi, floorSensors, floorAreas, valuesBySensorId, buildingMeta, latestValues });
  });
  return data;
};

export const getAirQualityData = (floorSensors, latestValues) => {
  const airQualitySensor = floorSensors.find(
    sensor => sensor.sensorType && sensor.sensorType.name === 'technical_performance'
  );

  if (airQualitySensor) {
    const latest = latestValues && latestValues[airQualitySensor.id];
    if (latest) {
      return {
        value: Math.round(latest.value),
        latestTime: latest.timestamp,
        sensors: [airQualitySensor],
      };
    }
  }

  return { sensors: [] };
};

export const getTemperatureData = (floorSensors, valuesBySensorId, buildingMeta) => {
  const { sensors, latestValue, latestTime } = getFloorDataForSensorTypes(
    ['indoor temperature', 'temperature'],
    null,
    floorSensors,
    valuesBySensorId
  );

  let temperature, latestTemperatureSensor, valueIsInvalid, statusValue;

  if (latestValue) {
    const timestamp = parseISO(latestValue.timestamp);
    temperature = latestValue.value;
    latestTemperatureSensor = find(sensors, { id: parseInt(latestValue.sensorId, 10) });
    valueIsInvalid = timestamp <= hourAgo;

    const getLimit = getPerformanceLimit(latestTemperatureSensor, latestTemperatureSensor.parent, buildingMeta);
    if (getLimit) {
      const [min, max] = getLimit(timestamp);
      statusValue = valueIsInvalid ? 10 : temperature >= min && temperature <= max ? 100 : 10;
    }
  }

  return {
    latestSensor: latestTemperatureSensor,
    latestTime,
    value: temperature && Math.round(temperature),
    valueIsInvalid,
    statusValue,
    sensors: sensors,
  };
};

const isBusinessDay = timestamp => {
  const weekday = getISODay(new Date(timestamp));
  return weekday >= 1 && weekday <= 5;
};

const isBetweenOpeningHours = (timestamp, startHour, endHour) => {
  const hour = new Date(timestamp).getHours();
  return hour >= startHour && hour < endHour;
};

export const getPresenceData = (floorSensors, valuesBySensorId, type, buildingMeta) => {
  const utilizationHours = (find(buildingMeta, { key: 'utilization_calculation_hours' }) || { value: '09:00-15:00' })
    .value;
  const [start, end] = map(utilizationHours.split('-'), time => parse(time, 'HH:mm', new Date()).getHours());

  const filterValue = valueObject =>
    valueObject.aggregation === 'hourlyUtilizationRate' &&
    isBusinessDay(valueObject.timestamp) &&
    isBetweenOpeningHours(valueObject.timestamp, start, end);

  const data = getFloorDataForSensorTypes([type], filterValue, floorSensors, valuesBySensorId);

  const value = isEmpty(data.values) ? null : round(100 * meanBy(data.values, 'value'));

  return {
    ...data,
    value,
  };
};

export const getAreaUtilizationData = (area, latestValues) => {
  const countSensor = area.sensors && find(area.sensors, sensor => sensor.sensorType.name === 'area_count');

  if (countSensor) {
    const latestValue = latestValues[countSensor.id];
    const latestTime = latestValue && formatDistanceToNow(parseISO(latestValue.timestamp));
    const capacity = countSensor.sensorMeta && find(countSensor.sensorMeta, { metaKey: 'capacity' });
    const value = latestValue && capacity && Math.min(round((latestValue.value / capacity.value) * 100), 100);

    return {
      latestTime,
      value,
      sensors: [countSensor],
      title: area.name,
      status: areaUtilizationStatus(value),
    };
  }
};

export const getBasicOpiData = (floorSensors, valuesBySensorId, type, buildingMeta) => {
  const data = getFloorDataForSensorTypes([type], null, floorSensors, valuesBySensorId);

  let value, statusValue, valueIsInvalid;

  if (data.latestValue) {
    const valueTime = parseISO(data.latestValue.timestamp);
    value = data.latestValue && data.latestValue.value;
    valueIsInvalid = valueTime <= hourAgo;

    const latestSensorId = Number.parseInt(data.latestValue.sensorId, 10);
    const latestSensor = data.sensors.find(sensor => sensor.id === latestSensorId);
    if (latestSensor) {
      const getLimit = getPerformanceLimit(latestSensor, latestSensor.parent, buildingMeta);
      if (getLimit) {
        const limit = getLimit(valueTime);
        statusValue = mapThresholdToStatusValue(value, limit);
      }
    }
  }

  return { ...data, value, statusValue, valueIsInvalid };
};

export const getFloorDataForSensorTypes = (types, filterValue, floorSensors, valuesBySensorId) => {
  const sensors = uniqBy(
    floorSensors.reduce((acc, sensor) => {
      if (sensor && sensor.sensorType) {
        if (!isEmpty(sensor.children)) {
          return [
            ...acc,
            ...sensor.children.filter(child => child.sensorType && includes(types, child.sensorType.name)),
          ];
        }

        if (includes(types, sensor.sensorType.name)) {
          return [...acc, sensor];
        }
      }

      return acc;
    }, []),
    'id'
  );

  const sensorIds = map(sensors, 'id');
  let values = sensorIds.reduce((acc, sensorId) => {
    if (valuesBySensorId[sensorId] && valuesBySensorId[sensorId].length > 0) {
      return acc.concat(valuesBySensorId[sensorId]);
    }
    return acc;
  }, []);
  if (filterValue) {
    values = values.filter(filterValue);
  }
  const latestValue = first(orderBy(values, 'timestamp', 'desc'));
  const latestTime = latestValue && capitalize(formatDistanceToNow(parseISO(latestValue.timestamp)));

  return { sensors, sensorIds, values, latestValue, latestTime };
};
