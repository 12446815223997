import { useMemo } from 'react';

const useVirtualReference = position =>
  useMemo(
    () => ({
      getBoundingClientRect: () => ({
        top: position.y,
        left: position.x,
        bottom: position.y,
        right: position.x,
        width: 0,
        height: 0,
      }),
    }),
    [position]
  );

export default useVirtualReference;
