import React from 'react';
import MasterDataService from 'services/masterData';

export const ACTION_STAGE = {
  Initial: 0,
  Error: 1,
  Sent: 2,
};

const useAdditionalSale = token => {
  const [loading, setLoading] = React.useState(false);
  const [additionalSale, setAdditionalSale] = React.useState();
  const [error, setError] = React.useState();
  const [stage, setStage] = React.useState(ACTION_STAGE.Initial);
  const [submitData, setSubmitData] = React.useState({});

  React.useEffect(() => {
    if (token) {
      setLoading(true);
      MasterDataService.additionalSaleWithToken(token)
        .then(setAdditionalSale)
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [token]);

  const submitAction = React.useCallback(
    formData =>
      MasterDataService.sendAdditionalSaleActionWithToken(token, formData)
        .then(response => {
          setSubmitData(formData);
          setAdditionalSale(response);
          setStage(ACTION_STAGE.Sent);
        })
        .catch(error => {
          if (error.statusCode >= 500) {
            setStage(ACTION_STAGE.Error);
          } else {
            throw error;
          }
        }),
    [token]
  );

  const submitManagerChange = React.useCallback(
    formData =>
      MasterDataService.updateAdditionalSaleManagerWithToken(token, formData)
        .then(response => {
          setSubmitData(formData);
          setAdditionalSale(response);
          setStage(ACTION_STAGE.Sent);
        })
        .catch(error => {
          if (error.statusCode >= 500) {
            setStage(ACTION_STAGE.Error);
          } else {
            throw error;
          }
        }),
    [token]
  );

  return { additionalSale, loading, error, stage, submitAction, submitManagerChange, submitData };
};

export default useAdditionalSale;
