import getTime from 'date-fns/getTime';
import startOfMonth from 'date-fns/startOfMonth';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';
import endOfDay from 'date-fns/endOfDay';
import { setYear, set, getDaysInMonth, getMonth } from 'date-fns';
import { format } from 'utils/Date/dateFormatter';
import parseISO from 'date-fns/parseISO';
import { startOfUTCYear, startOfUTCMonth, endOfUTCYear, endOfUTCMonth } from 'utils/Date/date';
import times from 'lodash/times';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import { colors } from 'styles/definitions';
import { formatEnergyValueToUnit, ENERGY_UNIT_DIVIDERS } from 'utils/Data/values';

const TEMPERATURE_UNIT = '°C';

const SERIES_BASE_CONFIG = {
  zIndex: 2,
};

const REFERENCE_INDEX = 9999;

const REFERENCE_BASE_CONFIG = {
  color: colors.orange,
  type: 'spline',
  category: 'reference',
  lineWidth: 1,
  zIndex: 0,
  showInLegend: true,
  enableMouseTracking: true,
  marker: {
    enabled: false,
    lineWidth: 2,
    radius: 5,
    symbol: 'circle',
  },
  states: {
    hover: {
      enabled: false,
    },
    inactive: {
      enabled: false,
    },
  },
  legendIndex: REFERENCE_INDEX,
};

export const INTERVAL_TYPES = {
  MONTH: 'monthly',
  DAY: 'daily',
  HOUR: 'hourly',
};

const INTERVAL_TYPES_LIST = [INTERVAL_TYPES.MONTH, INTERVAL_TYPES.DAY, INTERVAL_TYPES.HOUR];

export const CHART_YEAR = new Date(Date.now()).getFullYear();

export const getNextIntervalType = intervalType => {
  if (INTERVAL_TYPES_LIST[INTERVAL_TYPES_LIST.length - 1] === intervalType) {
    return intervalType;
  }
  return INTERVAL_TYPES_LIST[INTERVAL_TYPES_LIST.indexOf(intervalType) + 1];
};

/**
 * Gets drilldown interval dates
 * @param {*} date
 * @param {*} intervalType
 * @returns
 */
export const getDrilldownIntervalDates = (date, intervalType) => {
  if (intervalType === INTERVAL_TYPES_LIST[1]) {
    return [startOfUTCMonth(date), endOfUTCMonth(date)];
  } else if (intervalType === INTERVAL_TYPES_LIST[2]) {
    return [startOfDay(date), endOfDay(date)];
  }
  return [startOfUTCYear(date), endOfUTCYear(date)];
};

export const formatLabel = (value, labelFormat) => format(new Date(value), labelFormat);

const getUnit = sensorType => (sensorType?.unit == null ? '' : sensorType.unit);

const getMappedData = (sensorData, intervalType, category) =>
  sensorData.map(data => {
    const x = getX(parseISO(data.timestamp, intervalType));
    const useUTC = intervalType !== INTERVAL_TYPES.HOUR;

    return {
      useUTC,
      y: data.value,
      x,
      timestamp: data.timestamp,
      sensorId: data.sensorId,
      drilldown: `${category}_${intervalType}`,
    };
  });

export const getX = (date, intervalType) => {
  const newDate = new Date(date.getTime());
  // Unify aggregated monthly and daily values to the first hour of the UTC day
  // We set chart year for monthly and daily values based on UTC time and locally for hourly values
  if (intervalType !== INTERVAL_TYPES.HOUR) {
    newDate.setUTCFullYear(CHART_YEAR);
    newDate.setUTCHours(0);
  } else {
    newDate.setFullYear(CHART_YEAR);
  }
  // Unify aggregated monthly values to the first day of the UTC month
  if (intervalType === INTERVAL_TYPES.MONTH) {
    newDate.setUTCDate(1);
  }
  return startOfHour(newDate).getTime();
};

const getSeriesType = (graphType, chartType, isOutdoorTemperature) => {
  if (graphType === 'bar') {
    return 'column';
  }
  if (graphType === 'iot' || isOutdoorTemperature) {
    return 'line';
  }
  return chartType;
};

/**
 * getSeries
 * @param {Array} values
 */
export const getSeries = (
  sensorData = [],
  sensors,
  category,
  intervalType,
  color,
  chartType,
  dashStyle,
  isOutdoorTemperature,
  name,
  graphType
) => {
  const _unit = getUnit(sensors[0]?.sensorType);
  const data = getMappedData(sensorData, intervalType, category);
  const index = isOutdoorTemperature ? category + REFERENCE_INDEX : category;
  return {
    ...SERIES_BASE_CONFIG,
    _unit: isOutdoorTemperature ? TEMPERATURE_UNIT : _unit,
    intervalType,
    sensors,
    category,
    name,
    color,
    data,
    legendIndex: index,
    index,
    type: getSeriesType(graphType, chartType, isOutdoorTemperature),
    dashStyle,
    isOutdoorTemperature,
  };
};

/**
 * getComparisonSeries
 * @param {Array} comparisonData
 * @param {Object} sensors
 * @param {string} intervalType
 * @param {string} color
 */
export const getComparisonSeries = (comparisonData = [], sensors = [], intervalType, chartType) =>
  comparisonData.map(comparison =>
    getSeries(
      comparison.data,
      sensors,
      comparison.category,
      intervalType,
      comparison.color,
      chartType,
      comparison.dashStyle,
      comparison.isOutdoorTemperature,
      comparison.name,
      comparison.graphType
    )
  );

/**
 * getTickInterval
 * @param {String} intervalType
 */
export const getTickInterval = intervalType => {
  switch (intervalType) {
    case INTERVAL_TYPES.DAY:
      return 1000 * 3600 * 24;
    case INTERVAL_TYPES.HOUR:
      return 1000 * 3600;
    case INTERVAL_TYPES.MONTH:
    default:
      return 1000 * 3600 * 24 * 30;
  }
};

/**
 * getPointStart
 * @param {Array} sensorData
 * @param {String} intervalType
 */
export const getPointStart = (startDate, intervalType) => {
  let pointStart = startOfMonth(startDate);
  if (intervalType === INTERVAL_TYPES.HOUR) {
    pointStart = startOfHour(startDate);
  }
  if (intervalType === INTERVAL_TYPES.DAY) {
    pointStart = startOfDay(startDate);
  }
  return getTime(setYear(pointStart, 1970));
};

/**
 * getLabelFormat
 * @param {String} intervalType
 */
export const getLabelFormat = intervalType => {
  switch (intervalType) {
    case INTERVAL_TYPES.DAY:
      return 'd. MMM';
    case INTERVAL_TYPES.HOUR:
      return 'HH:mm';
    case INTERVAL_TYPES.MONTH:
    default:
      return 'MMM';
  }
};

export const getMostGranularIntervalType = sensor => {
  const { granularity } = sensor;
  if (granularity === 'month') {
    return INTERVAL_TYPES.MONTH;
  } else if (granularity === 'day') {
    return INTERVAL_TYPES.DAY;
  }
  return INTERVAL_TYPES.HOUR;
};

export const getReferenceSeries = (reference, intervalType, pointStart, unit) => {
  if (!reference || isEmpty(reference.data)) {
    return {};
  }
  let referenceData = reference.data;

  if (ENERGY_UNIT_DIVIDERS[unit]) {
    // Energy references are always in MWh, so we need to make a kWh conversion first by multiplying with 1000,
    // and then we can format it to unit
    referenceData = reference.data.map(value => formatEnergyValueToUnit(value * 1000, unit));
  }

  if (intervalType === INTERVAL_TYPES.MONTH) {
    return {
      ...REFERENCE_BASE_CONFIG,
      name: reference.name,
      color: reference.color,
      intervalType,
      data: referenceData.map((value, index) => ({
        name: reference.name,
        y: value,
        x: getX(set(new Date(), { month: index }), INTERVAL_TYPES.MONTH),
        useUTC: true,
      })),
      _unit: unit,
    };
  }

  if (intervalType === INTERVAL_TYPES.DAY) {
    const month = getMonth(new Date(pointStart));
    const daysInMonth = getDaysInMonth(new Date(pointStart));
    const averageDayValue = round(referenceData[month] / daysInMonth, 3);

    return {
      ...REFERENCE_BASE_CONFIG,
      name: reference.name,
      color: reference.color,
      intervalType,
      data: times(daysInMonth, index => ({
        name: reference.name,
        y: averageDayValue,
        x: getX(set(new Date(), { month, date: index + 1 }), INTERVAL_TYPES.DAY),
        useUTC: true,
      })),
    };
  }

  return {};
};
