import { createSelector } from 'reselect';
import sum from 'lodash/sum';
import { DEFAULT_META_VALUES } from 'constants/meta';
import getYear from 'date-fns/getYear';
import toDate from 'date-fns/toDate';
import parseISO from 'date-fns/parseISO';

const EMPTY_ARRAY = [];

const dateNow = new Date();

/**
 * Gets observations from state for props.match.params.functionalLocationId
 */
export const getObservations = (state, props) => {
  return state.profile?.profile?.features?.controlRoomObservations
    ? state.notice.byFL?.[props.functionalLocationId ?? props.match?.params?.functionalLocationId]?.observations ??
        EMPTY_ARRAY
    : EMPTY_ARRAY;
};
/**
 * Gets observations from state and return those that have energyImpact
 * expects following props: match.params.functionalLocationId
 */
export const getEnergyObservations = createSelector(getObservations, observations =>
  observations.filter(item => item.energyImpact)
);

/**
 * Gets observations from state and return those that have wasteObservation
 * expects following props: match.params.functionalLocationId
 */
export const getWasteObservations = createSelector(getObservations, observations =>
  observations.filter(item => item.wasteObservation)
);

export const combineMetaValues = (partnerMeta = [], buildingMeta = []) => {
  const reducer = (mem, item) => {
    mem[item.key] = item.value;
    return mem;
  };
  const partnerLevelValues = partnerMeta.reduce(reducer, {});
  const buildingLevelValues = buildingMeta.reduce(reducer, {});
  return { ...DEFAULT_META_VALUES, ...partnerLevelValues, ...buildingLevelValues };
};

/**
 * Creates selector that does energy observation related calculations
 * @param {function} selectObservations
 */
export const createNoticeSelector = selectObservations =>
  createSelector(selectObservations, notices => {
    let completedRatio = 100;
    let completedCount = 0;
    let completedCountCurrentYear = 0;
    let completedRatioCurrentYear = 0;
    let totalSavingsPotential = 0;
    let totalSavingsPotentialMonetary = 0;
    const currentYear = getYear(dateNow);

    const filterCurrentYear = item =>
      getYear(toDate(item.modifiedOn?.date ?? parseISO(item.timestamp))) === currentYear;
    const observations = notices.map(item => {
      const savingsPotentialSum = sum([item.savingsPotentialElectricity || 0, item.savingsPotentialHeat || 0]);
      return { ...item, savingsPotentialSum };
    });

    const observationsCurrentYear = observations.filter(filterCurrentYear);
    const completed = observations.filter(item => item.status?.status === 'completed');
    if (observations.length > 0) {
      completedCount = completed.length;
      completedRatio = (completedCount / observations.length) * 100;
      totalSavingsPotential = sum(
        observations.filter(item => item.status?.status !== 'completed').map(item => item.savingsPotentialSum ?? 0)
      );
      totalSavingsPotentialMonetary = sum(
        observations.filter(item => item.status?.status !== 'completed').map(item => item.totalSavingsYearlySum ?? 0)
      );
    }
    if (observationsCurrentYear.length > 0) {
      completedCountCurrentYear = completed.filter(filterCurrentYear).length;
      completedRatioCurrentYear = (completedCountCurrentYear / observationsCurrentYear.length) * 100;
    }

    return {
      observations,
      completedCount,
      completedCountCurrentYear,
      totalCount: observations.length,
      totalCountCurrentYear: observationsCurrentYear.length,
      completedRatio,
      completedRatioCurrentYear,
      totalSavingsPotential,
      totalSavingsPotentialMonetary,
    };
  });
