import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { time } from 'styles/definitions';

const SMALL = '480px';
const DEFAULT = '768px';
const LARGE = '1280px';
const EXTRA_LARGE = '1500px';
const FULL_SCREEN = '100vw';

const getWidth = props => {
  if (props.fullScreen) {
    return FULL_SCREEN;
  }
  if (props.extraLarge) {
    return EXTRA_LARGE;
  }
  if (props.large) {
    return LARGE;
  }
  if (props.small) {
    return SMALL;
  }
  return DEFAULT;
};

const getMaxWidth = ({ fullScreen }, bigDesktop = false) => {
  if (fullScreen) {
    return '100vw';
  } else if (bigDesktop) {
    return EXTRA_LARGE;
  }
  return 'calc(100vw - 96px)'; // Allow margin of 48px on both sides
};

/**
 * Dialog body UI component
 */
export const StyledDialogBody = styled.div`
  background-color: var(--window-bg);
  ${props => (props.noPadding ? '' : 'padding: var(--size-lg)')};
  transition: ${props => `all ${props.theme.motion.default} ${props.theme.motion.easing}`};
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
    width: ${props => getWidth(props)};
    max-width: ${props => getMaxWidth(props)};
  `}

  ${props => props.theme.media.bigDesktop`
    max-width: ${props => getMaxWidth(props, true)};
  `}

  ${props =>
    props.statusColor &&
    css`
      border-top: 2px solid ${props => props.statusColor};
    `}

  @media print {
    width: 100vw;
    height: auto;
    max-width: none;
    max-height: none;
  }
`;
StyledDialogBody.displayName = 'StyledDialogBody';

const DialogBody = ({ fullScreen, statusColor, ...props }) => {
  // Fire window resize event after fullscreen transition is finished
  // to update Responsive component dimensions
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, parseInt(time.default, 10) + 1);
  }, [fullScreen]);

  return <StyledDialogBody fullScreen={fullScreen} statusColor={statusColor} {...props} />;
};
DialogBody.propTypes = {
  fullScreen: PropTypes.bool,
  statusColor: PropTypes.string,
  noPadding: PropTypes.bool,
};

export default DialogBody;
