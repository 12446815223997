import React from 'react';
import styled from 'styled-components';
import { RowData } from '@tanstack/table-core/build/lib/types';
import { SortDirection } from '@tanstack/table-core/build/lib/features/Sorting';

import { DefaultColumnSortingControlProps } from 'components/VirtualizedTable/GenericTable';
import Svg from 'components/Svg/Svg';

export type ColumnSortingControlContainerProps = {
  isSorted: false | SortDirection;
};

export const ColumnSortingControlContainer = styled.div<ColumnSortingControlContainerProps>`
  align-self: stretch;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;

  svg {
    display: block;
    font-size: 0.5rem;

    fill: ${props => (props.isSorted ? 'inherit' : props.theme.colors.darkGray)};
  }
`;

export function DefaultColumnSortingControl<TData extends RowData>({
  header,
}: DefaultColumnSortingControlProps<TData>) {
  return (
    <ColumnSortingControlContainer
      onClick={header.column.getToggleSortingHandler()}
      isSorted={header.column.getIsSorted()}
    >
      {(() => {
        switch (header.column.getIsSorted()) {
          case 'asc':
            return <Svg name="caret-down" />;
          case 'desc':
            return <Svg name="caret-up" />;
          default:
            return (
              <>
                <Svg name="caret-up" />
                <Svg name="caret-down" />
              </>
            );
        }
      })()}
    </ColumnSortingControlContainer>
  );
}
