import { createReducerFromMapping } from 'redux/utils/index';

const initialState = { title: '', context: '' };

export const SET = 'CUSTOMER_PLATFORM/Title/SET';
export const RESET = 'CUSTOMER_PLATFORM/Title/RESET';

export const setTitle = (title, context) => ({ type: SET, title, context });
export const resetTitle = () => ({ type: RESET });

/**
 * Checks if the title context is the current route path for the given React component.
 * @param component {Component}
 * @returns {boolean}
 */
export function isTitleSet(component) {
  const titleContext = component.props.title.context,
    routePath = component.props.location.pathname;
  if (titleContext === routePath) {
    return true;
  }
  return false;
}

export default createReducerFromMapping(
  {
    [SET]: (state, action) => ({ ...state, ...action }),
    [RESET]: state => ({ ...state, title: '', context: '' }),
  },
  initialState
);
