import { createReducerFromMapping } from 'redux/utils/index';
import DocumentService from 'services/document';

const initialState = {
  functionalLocations: {},
  partners: {},
  error: null,
  downloadingFolder: false,
  loadingFolders: [],
};

export const LOAD_FOLDERS = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS';
export const LOAD_FOLDERS_SUCCESS = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS_SUCCESS';
export const LOAD_FOLDERS_FAIL = 'CUSTOMER_PLATFORM/Folder/LOAD_FOLDERS_FAIL';

export const loadFolders = (functionalLocation, refreshCache) => {
  return async dispatch => {
    dispatch({ type: LOAD_FOLDERS, functionalLocation: functionalLocation.functionalLocation });
    try {
      const result = await DocumentService.getFolders({
        functionalLocation: functionalLocation.functionalLocation,
        refreshCache,
      });

      return dispatch({
        type: LOAD_FOLDERS_SUCCESS,
        functionalLocation: functionalLocation.functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOLDERS_FAIL,
        functionalLocation: functionalLocation.functionalLocation,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_FOLDERS = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS';
export const LOAD_PARTNER_FOLDERS_SUCCESS = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS_SUCCESS';
export const LOAD_PARTNER_FOLDERS_FAIL = 'CUSTOMER_PLATFORM/Folder/LOAD_PARTNER_FOLDERS_FAIL';

export const loadPartnerFolders = (partnerNumber, refreshCache) => {
  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_FOLDERS, partnerNumber });
    try {
      const result = await DocumentService.getFolders({ partnerNumber, refreshCache });

      return dispatch({
        type: LOAD_PARTNER_FOLDERS_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_FOLDERS_FAIL,
        partnerNumber,
        error,
      });
    }
  };
};

export const UPSERT_FOLDER = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER';
export const UPSERT_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER_SUCCESS';
export const UPSERT_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/UPSERT_FOLDER_FAIL';

export const upsertFolder = (path, folder) => {
  return async dispatch => {
    dispatch({ type: UPSERT_FOLDER });
    try {
      let result;
      if (!folder.id) {
        result = await DocumentService.createFolder(path, { name: folder.name });
      } else {
        result = await DocumentService.updateFolder(folder.path, { path, name: folder.name });
      }
      return dispatch({ type: UPSERT_FOLDER_SUCCESS, folderId: result.id });
    } catch (error) {
      return dispatch({
        type: UPSERT_FOLDER_FAIL,
        functionalLocation: folder.functionalLocation,
        error,
      });
    }
  };
};

export const UPSERT_PARTNER_FOLDER = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER';
export const UPSERT_PARTNER_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER_SUCCESS';
export const UPSERT_PARTNER_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/UPSERT_PARTNER_FOLDER_FAIL';

export const upsertPartnerFolder = (path, folder) => {
  return async dispatch => {
    dispatch({ type: UPSERT_PARTNER_FOLDER });
    try {
      let result;
      if (!folder.id) {
        result = await DocumentService.createFolder(path, { name: folder.name });
      } else {
        result = await DocumentService.updateFolder(folder.path, { path, name: folder.name });
      }
      return dispatch({ type: UPSERT_PARTNER_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPSERT_PARTNER_FOLDER_FAIL,
        error,
      });
    }
  };
};

export const DELETE_FOLDER = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DELETE_FOLDER_FAIL';

export const deleteFolder = path => {
  return async dispatch => {
    dispatch({ type: DELETE_FOLDER });
    try {
      await DocumentService.deleteFolder(path);
      return dispatch({ type: DELETE_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_FOLDER_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_FOLDER = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER';
export const DOWNLOAD_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER_SUCCESS';
export const DOWNLOAD_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_FOLDER_FAIL';

export const downloadFolder = (folderId, folderName, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_FOLDER });
    try {
      await DocumentService.downloadFolder({
        functionalLocation: functionalLocation.functionalLocation,
        folderId,
        folderName,
      });
      return dispatch({ type: DOWNLOAD_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FOLDER_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_PARTNER_FOLDER = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER';
export const DOWNLOAD_PARTNER_FOLDER_SUCCESS = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER_SUCCESS';
export const DOWNLOAD_PARTNER_FOLDER_FAIL = 'CUSTOMER_PLATFORM/Folder/DOWNLOAD_PARTNER_FOLDER_FAIL';

export const downloadPartnerFolder = (folderId, folderName, partnerNumber) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_PARTNER_FOLDER });
    try {
      await DocumentService.downloadFolder({ partnerNumber, folderId, folderName });
      return dispatch({ type: DOWNLOAD_PARTNER_FOLDER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_PARTNER_FOLDER_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_FOLDERS_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
    }),
    [LOAD_FOLDERS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_PARTNER_FOLDERS_SUCCESS]: (state, action) => ({
      ...state,
      partners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
    }),
    [LOAD_PARTNER_FOLDERS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_PARTNER_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DELETE_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DOWNLOAD_FOLDER]: (state, action) => ({
      ...state,
      downloadingFolder: true,
    }),
    [DOWNLOAD_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
      downloadingFolder: false,
    }),
    [DOWNLOAD_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      downloadingFolder: false,
    }),

    [DOWNLOAD_PARTNER_FOLDER]: (state, action) => ({
      ...state,
      downloadingFolder: true,
    }),
    [DOWNLOAD_PARTNER_FOLDER_SUCCESS]: (state, action) => ({
      ...state,
      downloadingFolder: false,
    }),
    [DOWNLOAD_PARTNER_FOLDER_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      downloadingFolder: false,
    }),
  },
  initialState
);
