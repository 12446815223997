import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';

export const initialState = {
  consumptionKPIs: {},
};

export const LOAD_CONSUMPTION_KPI = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI';
export const LOAD_CONSUMPTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI_SUCCESS';
export const LOAD_CONSUMPTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_CONSUMPTION_KPI_FAIL';

export const loadConsumptionKPI = (partnerNumber, kpi) => async dispatch => {
  dispatch({ type: LOAD_CONSUMPTION_KPI, kpi });
  try {
    const result = await dispatch(IoTService.consumptionKPI(partnerNumber, kpi));
    return dispatch({
      type: LOAD_CONSUMPTION_KPI_SUCCESS,
      kpi,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_CONSUMPTION_KPI_FAIL,
      kpi,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_CONSUMPTION_KPI]: (state, action) => ({
      ...state,
      consumptionKPIs: {
        ...state.consumptionKPIs,
        [action.kpi]: {
          loading: true,
          data: {},
        },
      },
    }),
    [LOAD_CONSUMPTION_KPI_SUCCESS]: (state, action) => ({
      ...state,
      consumptionKPIs: {
        ...state.consumptionKPIs,
        [action.kpi]: {
          loading: false,
          data: action.result,
        },
      },
    }),
    [LOAD_CONSUMPTION_KPI_FAIL]: (state, action) => ({
      ...state,
      consumptionKPIs: {
        ...state.consumptionKPIs,
        [action.kpi]: {
          loading: false,
          error: action.error,
        },
      },
    }),
  },
  initialState
);
