import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withErrorBoundary } from 'components/ErrorPage/ErrorBoundary';

export const PlainSection = styled.div`
  margin-bottom: ${props => (props.noMargin ? 0 : props.theme.layoutSpacing.md)};
  display: ${props => (props.hideOnMobile ? 'none' : 'inherit')};
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});

  ${props => props.theme.media.portrait`
    display: inherit;
    margin: 0 auto ${props => !props.noMargin && props.theme.section.spacingBetween};
  `}
`;

PlainSection.propTypes = {
  hideOnMobile: PropTypes.bool,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default withErrorBoundary(PlainSection);
