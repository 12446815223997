import ApiRequest from 'utils/Fetch/ApiRequest';
import { MASTER_DATA_V2_PATH } from './index';

export const classificationCodes = ({ division, type, searchText, options = {} }) =>
  ApiRequest.get({
    apiPath: `${MASTER_DATA_V2_PATH}/classificationCodes`,
    query: { division, type, searchText },
    ...options,
  });

export const classificationCode = id =>
  ApiRequest.get({
    apiPath: `${MASTER_DATA_V2_PATH}/classificationCodes/${id}`,
    useAuthorization: false,
    noCache: true,
  });

export const upsertClassificationCode = data =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_V2_PATH}/classificationCodes/upsert`,
    data,
    noCache: true,
  });

export const createMultipleClassificationCodes = data =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_V2_PATH}/classificationCodes/createMultiple`,
    data,
    noCache: true,
  });

export const deleteClassificationCode = id =>
  ApiRequest.delete({
    apiPath: `${MASTER_DATA_V2_PATH}/classificationCodes/${id}`,
    noCache: true,
  });
