import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Division } from 'types/Division';
import { ClassificationTypes } from 'components/ClassificationCode/utils';

export const SENSOR_DATA_CATEGORY = {
  WASTE: 'waste_fl',
  GENERIC: 'generic',
  WASTE_PARTNER: 'waste_partner',
  WASTE_PARTNER_WITH_ADDRESS: 'waste_partner_addr',
  WASTE_PARTNER_WITH_ORDERNUMBER: 'waste_partner_ordernumber',
};

const SUPPORTED_ZONED_DATE_FORMATS = [
  "yyyy-MM-dd'T'HH:mm:ss.SSSXXX",
  "yyyy-MM-dd'T'HH:mm:ss.SSSXX",
  "yyyy-MM-dd'T'HH:mm:ssXXX",
  "yyyy-MM-dd'T'HH:mm:ssXX",
  "yyyy-MM-dd'T'HH:mmXXX",
  "yyyy-MM-dd'T'HH:mmXX",
];

const SUPPORTED_UTC_DATE_FORMATS = [
  'd.M.yyyy',
  'd.M.yyyy H:m',
  'd.M.yyyy H:m:s',
  'd/M/yyyy',
  'yyyy/M',
  'yyyy-M',
  'M/yyyy',
  'M-yyyy',
  'yyyy-M-d',
  'yyyy/M/d',
  'PP',
  'PPP',
  'PPPP',
  'PPpp',
  'PPPpp',
  'PPPPpp',
];

export const parseDate = input => {
  for (const formatString of SUPPORTED_ZONED_DATE_FORMATS) {
    const parsedWithZone = parse(input, formatString, 0);
    if (isValid(parsedWithZone)) {
      return parsedWithZone;
    }
  }

  const parsedLooseISO = parseISO(input);
  if (isValid(parsedLooseISO)) {
    const parsedUtc = parseISO(`${format(parsedLooseISO, "yyyy-MM-dd'T'HH:mm:ss.SSS")}Z`);
    return parsedUtc;
  }

  for (const formatString of SUPPORTED_UTC_DATE_FORMATS) {
    const parsedLocal = parse(input, formatString, 0);
    if (isValid(parsedLocal)) {
      const parsedUtc = parseISO(`${format(parsedLocal, "yyyy-MM-dd'T'HH:mm:ss.SSS")}Z`);
      return parsedUtc;
    }
  }

  return new Date(NaN);
};

const timestampColumn = {
  name: 'timestamp',
  title: 'Date',
  transform: value => parseDate(value),
  isValid: value => value && isValid(value),
  format: value => value && format(value, 'P p (xxx)'),
  get: value => value && value.toISOString(),
  align: 'left',
};

const valueColumn = {
  name: 'value',
  title: 'Value',
  transform: value => {
    if (!isNil(value) && typeof value === 'number' && !isNaN(value)) {
      return Number(value);
    }
    if (value && typeof value === 'string') {
      // replace commas with dots and long dashes with normal ones, remove spaces
      const cleaned = value.replace(',', '.').replace('−', '-').replace(/\s/g, '');
      if (!isNaN(cleaned)) {
        return parseFloat(cleaned);
      }
      return NaN;
    }
    return undefined;
  },
  isValid: value =>
    (typeof value === 'number' && !Number.isNaN(value) && Number.isFinite(value)) ||
    (typeof value === 'string' && !Number.isNaN(value.replace(',', '.').replace('−', '-').replace(/\s/g, ''))),
  format: value => (typeof value === 'number' ? value.toLocaleString(window.__localeId__) : value),
  get: value => value,
  align: 'right',
};

const ewcCodeColumn = {
  name: 'ewc',
  title: 'EWC Code',
  transform: value => value && String(value),
  isValid: (value, defaults) => Boolean(value),
  format: (value, defaults) => String(value),
  get: (value, defaults) => String(value),
  align: 'left',
};

const rdCodeColumn = {
  name: 'rd',
  title: 'RD Code',
  transform: value => (value ? String(value) : null),
  isValid: (value, defaults) => (value ? Boolean(value) : true),
  format: (value, defaults) => (value ? String(value) : null),
  get: (value, defaults) => (value ? String(value) : null),
  align: 'left',
};

// timestamp, value, sensorId
export const genericCsvColumns = [
  timestampColumn,
  valueColumn,
  {
    name: 'sensorId',
    title: 'Sensor',
    transform: value => value && String(value),
    isValid: (value, defaults) => Boolean(value) || Boolean(get(defaults, 'sensorId')),
    format: (value, defaults) => (value ? String(value) : get(defaults, 'sensorId')),
    get: (value, defaults) => (value ? String(value) : get(defaults, 'sensorId')),
    align: 'left',
  },
];

// Columns: timestamp, value, ewc, rd
export const wasteCsvColumns = [timestampColumn, valueColumn, ewcCodeColumn, rdCodeColumn];

// Columns: timestamp, value, functionalLocation, ewc, rd
export const wastePartnerCsvColumns = [
  timestampColumn,
  valueColumn,
  {
    name: 'functionalLocation',
    title: 'Functional location',
    transform: value => value && String(value),
    isValid: value => Boolean(value),
    format: value => String(value),
    get: value => String(value),
    align: 'left',
  },
  ewcCodeColumn,
  rdCodeColumn,
];

// Columns: timestamp, value, functionalLocation, ewc, rd
export const wastePartnerAddressCsvColumns = [
  timestampColumn,
  valueColumn,
  {
    name: 'functionalLocation',
    title: 'Address of the functional location',
    transform: value => value && String(value),
    isValid: value => {
      if (value) {
        const splitted = value.split(',');
        return splitted.length === 2;
      }
      return false;
    },
    format: value => String(value),
    get: value => String(value),
    align: 'left',
  },
  ewcCodeColumn,
  rdCodeColumn,
];

// Columns: timestamp, value, functionalLocation, ewc, rd
export const wastePartnerOrderNumberCsvColumns = [
  timestampColumn,
  valueColumn,
  {
    name: 'serviceOrderNumber',
    title: 'Service order number',
    transform: value => value && String(value),
    isValid: value => Boolean(value),
    format: value => String(value),
    get: value => String(value),
    align: 'left',
  },
  ewcCodeColumn,
  rdCodeColumn,
];

export const CLASSIFICATION_CODES_TEMPLATE = 'classification_codes';

export const classificationCodesCsvColumns = [
  {
    name: 'code',
    title: 'Code',
    transform: value => value && String(value),
    isValid: value => Boolean(value),
    format: value => (value ? String(value) : undefined),
    get: value => (value ? String(value) : undefined),
    align: 'left',
  },
  {
    name: 'description',
    title: 'Description',
    transform: value => value && String(value),
    isValid: value => true,
    format: value => (value ? String(value) : undefined),
    get: value => (value ? String(value) : undefined),
    align: 'left',
  },
  {
    name: 'division',
    title: 'Division',
    transform: value => value && String(value),
    isValid: (value, defaults) => Object.values(Division).includes(value || get(defaults, 'division')),
    format: (value, defaults) =>
      Object.keys(Division).find(key => Division[key] === (value || get(defaults, 'division'))) || value,
    get: (value, defaults) => (value ? String(value) : get(defaults, 'division')),
    align: 'left',
  },
  {
    name: 'type',
    title: 'Type',
    transform: value => value && String(value),
    isValid: (value, defaults) => Object.values(ClassificationTypes).includes(value || get(defaults, 'type')),
    format: (value, defaults) =>
      Object.keys(ClassificationTypes).find(key => ClassificationTypes[key] === (value || get(defaults, 'type'))) ||
      value,
    get: (value, defaults) => (value ? String(value) : get(defaults, 'type')),
    align: 'left',
  },
];
