import PropTypes from 'prop-types';

export const Setpoint = PropTypes.shape({
  name: PropTypes.string,
  color: PropTypes.string,
  coordinates: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    })
  ),
});

export const KeyConfiguration = PropTypes.shape({
  showTotals: PropTypes.bool,
  showAverages: PropTypes.bool,
  showOpenHours: PropTypes.bool,
  showTrend: PropTypes.bool,
  showOutdoorTemperature: PropTypes.bool,
  showReference: PropTypes.bool,
});

export const CustomChartSeries = PropTypes.shape({
  id: PropTypes.number,
  customChartId: PropTypes.number,
  name: PropTypes.string,
  graphType: PropTypes.string,
  aggregation: PropTypes.string,
  aggregationFreq: PropTypes.string,
  sort: PropTypes.oneOf(['asc', 'desc']),
  color: PropTypes.string,
  comparisonYear: PropTypes.number,
  comparisonYearDiff: PropTypes.number,
  timeframeStart: PropTypes.string,
  timeframeEnd: PropTypes.string,
  timeframeStartDiffMs: PropTypes.number,
  sensorIds: PropTypes.arrayOf(PropTypes.number),
  axes: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
});

export const CustomChart = PropTypes.shape({
  id: PropTypes.number,
  profileId: PropTypes.string,
  title: PropTypes.string,
  functionalLocation: PropTypes.string,
  feature: PropTypes.oneOf(['energy']),
  public: PropTypes.bool,
  description: PropTypes.string,
  chartType: PropTypes.oneOf(['default', 'comparison', 'scatter-plot']),
  aggregationFreq: PropTypes.string,
  flipAxes: PropTypes.bool,
  referenceMetaKey: PropTypes.string,
  verticalZoom: PropTypes.bool,
  setpoint: Setpoint,
  keyConfiguration: KeyConfiguration,
  series: PropTypes.arrayOf(CustomChartSeries),
  readOnly: PropTypes.bool,
});
