import React from 'react';

const ContactPhone = () => (
  <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
    <g>
      <polygon points="31.3,27.4 31.3,27.4 31.3,27.4 	" />
      <path
        d="M31.3,24.1c-1.3-1.3-3.7-3.7-5.1-5.1c-0.5-0.5-1.1-0.7-1.7-0.7c-0.6,0-1.2,0.2-1.7,0.7l-3.2,3.2l0,0
        c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2-0.1c-1.1-0.7-3.1-2.1-5.2-4.2c-2.1-2.1-3.5-4.1-4.2-5.2c0-0.1-0.1-0.1-0.1-0.2
        c0-0.1,0-0.2,0.1-0.3l0,0l0,0l0,0c0.8-0.9,2.2-2.2,3.2-3.2c0.5-0.5,0.7-1.1,0.7-1.7c0-0.6-0.2-1.2-0.7-1.7L7.9,0.7
        C7.5,0.3,6.9,0,6.3,0S5,0.3,4.6,0.7C3.9,1.4,2.8,2.5,1.9,3.4C0.7,4.6,0,6.3,0,8C0,8.5,0,9,0.2,9.5l0,0l0,0l0,0l0,0
        C1,12.8,3,18.2,8.4,23.6c5.4,5.4,10.8,7.4,14.1,8.2l0,0l0,0l0,0c0.5,0.1,1,0.2,1.4,0.2c1.7,0,3.4-0.7,4.7-1.9l0,0
        c0.9-0.9,1.9-1.9,2.7-2.7c0.4-0.4,0.7-1,0.7-1.7C32,25.1,31.8,24.5,31.3,24.1z M29.9,26c-0.7,0.7-1.8,1.8-2.7,2.7l0,0l0,0l0,0
        c-0.9,0.9-2.1,1.4-3.3,1.4c-0.3,0-0.7,0-1-0.1c-3.1-0.7-8.1-2.7-13.2-7.7c-5-5-7-10.1-7.7-13.2C2,8.7,1.9,8.4,1.9,8
        c0-1.2,0.5-2.4,1.4-3.3L6,2.1C6,2,6.2,1.9,6.3,1.9c0.1,0,0.2,0,0.3,0.1l5.1,5.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3
        c-0.9,0.9-2.3,2.3-3.2,3.2l0,0C8,11.4,7.8,12,7.8,12.6c0,0.4,0.1,0.8,0.3,1.2l0,0l0,0l0,0c0.8,1.2,2.3,3.3,4.5,5.6
        c2.2,2.2,4.4,3.7,5.6,4.5l0,0l0,0l0,0l0,0c0.4,0.2,0.8,0.3,1.2,0.3c0.6,0,1.2-0.2,1.7-0.7l3.2-3.2c0.1-0.1,0.2-0.1,0.3-0.1
        c0.1,0,0.2,0,0.3,0.1l5.1,5.1c0.1,0.1,0.1,0.2,0.1,0.3C30.1,25.9,30,26,29.9,26z"
      />
      <path
        d="M16.1,4.2c3.2,0,6.2,1.3,8.3,3.4c2.1,2.1,3.4,5,3.4,8.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-7.5-6.1-13.6-13.6-13.6
        c-0.5,0-1,0.4-1,1C15.2,3.7,15.6,4.2,16.1,4.2z"
      />
      <path
        d="M23.6,15.9c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-5.2-4.2-9.4-9.4-9.4c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1
        c2.1,0,3.9,0.8,5.3,2.2C22.8,11.9,23.6,13.8,23.6,15.9z"
      />
      <path
        d="M15.2,11.7c0,0.5,0.4,1,1,1c0.9,0,1.7,0.4,2.3,1c0.6,0.6,1,1.4,1,2.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1
        c0-2.9-2.3-5.2-5.2-5.2C15.6,10.7,15.2,11.1,15.2,11.7z"
      />
    </g>
  </svg>
);

export default ContactPhone;
