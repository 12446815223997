import styled from 'styled-components';

const DEFAULT_CHART_HEIGHT = '26rem';

export const ChartWrapper = styled.div`
  position: relative;
  height: ${props => (!props.isExpanded || props.isPreview ? DEFAULT_CHART_HEIGHT : '100%')};
  min-height: ${props => (props.isExpanded && props.height ? props.height : DEFAULT_CHART_HEIGHT)};
`;
ChartWrapper.displayName = 'ChartWrapper';
