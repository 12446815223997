import { timelineClickPosition } from 'components/Sensor/SensorAnnotations/utils';
import { useMemo } from 'react';
import { compose, groupBy } from 'lodash/fp';

const groupAnnotationEntriesByPoints = compose(
  Object.entries,
  groupBy(({ point }) => point)
);

const useScatterPlotAnnotationLabels = (annotations, setAnnotationPopover) =>
  useMemo(
    () =>
      groupAnnotationEntriesByPoints(annotations).map(([point, groupedAnnotations], idx) => ({
        draggable: 'xy',
        zIndex: 8,
        labels: [
          {
            id: idx,
            point,
            text: groupedAnnotations.length,
          },
        ],
        labelOptions: {
          allowOverlap: false,
          backgroundColor: '#F7F7F7',
          borderColor: '#727983',
          borderRadius: 0,
          borderWidth: 1,
          fontWeight: 'bold',
          overflow: 'justify',
          padding: 3,
          shape: 'comment',
          style: {
            border: '1px solid #727983',
            fontFamily: 'Arial,sans-serif',
          },
          textColor: 'black',
          visible: true,
          x: 10,
          y: -10,
        },
        events: {
          click(event) {
            setAnnotationPopover({
              annotations: groupedAnnotations,
              position: timelineClickPosition(event),
            });
          },
        },
      })),
    [annotations, setAnnotationPopover]
  );

export default useScatterPlotAnnotationLabels;
