import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import fpMap from 'lodash/fp/map';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import styled from 'styled-components';

import SortableTable from 'components/SortableTable/SortableTable';
import DocumentRow, { MinimalDocumentRow } from './DocumentRow';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';

const InfoText = styled.p`
  font-family: ${props => props.theme.fontFamily.text};
  font-size: ${props => props.theme.fontSize.xs};
  color: ${props => props.theme.colors.darkGray};
  margin: var(--size-md);
`;

const getColumns = (t, minimalUI) => {
  const columns = [
    {
      title: t('Document'),
      field: 'name',
      sortable: true,
      hideOnMobile: true,
    },
    {
      title: minimalUI ? t('Date') : t('Uploaded/Updated'),
      field: 'date',
      sortable: true,
    },
  ];

  if (!minimalUI) {
    columns.push({
      title: t('Size'),
      field: 'size',
      sortable: true,
      hideOnMobile: true,
    });
  }
  columns.push({
    sortable: false,
    hideOnMobile: true,
  });

  return columns;
};

const SkeletonBodyComponent = () => (
  <tbody>
    <tr>
      <TableCell>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);
export const ExternalDocumentTable = props => {
  const { t, documents, download, loading = false, minimalUI = false } = props;

  const data =
    documents &&
    !documents.error &&
    flow(
      filter(document => document.files && document.files.length),
      fpMap(document =>
        fpMap(file => ({ ...file, documentId: document.id, folderId: document.folderId }))(document.files)
      ),
      flatten,
      fpMap(document => ({
        ...document,
        date: {
          date: document.lastModified,
          value: parseISO(document.lastModified).valueOf(),
        },
      }))
    )(documents);

  return (
    <Fragment>
      <SortableTable
        data-test-id={`ExternalDocumentTable-loading-${loading}`}
        columns={getColumns(t, minimalUI)}
        loading={loading}
        data={data}
        onClick={download}
        RowComponent={minimalUI ? MinimalDocumentRow : DocumentRow}
        SkeletonBodyComponent={SkeletonBodyComponent}
        headerBackgroundColor="transparent"
        borderColor={minimalUI ? 'transparent' : 'porcelain'}
        orderBy="date"
        order="asc"
        t={t}
        cellPadding="12px"
        removeHead={minimalUI}
      />
      {!loading && isEmpty(data) && <InfoText>{t('No results found')}</InfoText>}
    </Fragment>
  );
};

ExternalDocumentTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          created: PropTypes.string.isRequired,
          extension: PropTypes.string,
          id: PropTypes.number.isRequired,
          lastModified: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          size: PropTypes.number,
        })
      ).isRequired,
    })
  ),
  download: PropTypes.func,
  minimalUI: PropTypes.bool,
};

export default ExternalDocumentTable;
