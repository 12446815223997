import React from 'react';

import { useQuery } from 'decorators/Query/useQuery';
import { usePaginationQuery } from 'components/Pagination/usePaginationQuery';

import {
  defaultPaginationRowCountOptions,
  PaginationRowCountOption,
  PaginationRowCountValue,
} from 'components/Pagination/PaginationPageSizeSelector';

import { Pagination } from './Pagination';

export type PageChangeCallback = (page: number) => void;

export type QueryParameterPaginationProps = {
  totalItems: number;
  maxVisiblePages?: number;
  pageSizeSelectorWidth?: number;
  paginationRowCountOptions?: PaginationRowCountOption[];
  currentPageQueryParameter?: string;
  pageSizeQueryParameter?: string;
  onPageChange?: PageChangeCallback;
  firstPageOrAll?: boolean;
};

/**
 * This component is self-sufficient. The source of truth is in the query string.
 * To tap into the current values, use the `usePaginationQuery` hook. The arguments
 * `*QueryParameter` set the query parameter names to be used – use corresponding
 * names with the `usePaginationQuery` hook. This way multiple pagination components
 * can co-exist on the same page.
 *
 * @example
 *   const { currentPage, pageSize } = usePaginationQuery();
 *
 * @implements {UIv3}
 * @param totalItems Number of total rows/items
 * @param maxVisiblePages Number of page buttons to show (minus ellipsis buttons)
 * @param paginationRowCountOptions Optional custom page size options
 * @param pageSizeSelectorWidth Custom page size selector width
 * @param currentPageQueryParameter default `"page"`
 * @param pageSizeQueryParameter default `"items"`
 * @param onPageChange {(page: number) => void} Called when page changes
 * @param firstPageOrAll Display simple "first page or all pages" pagination
 */
export function QueryConnectedPagination({
  totalItems,
  maxVisiblePages,
  paginationRowCountOptions = defaultPaginationRowCountOptions,
  pageSizeSelectorWidth,
  currentPageQueryParameter = 'page',
  pageSizeQueryParameter = 'items',
  onPageChange,
  firstPageOrAll,
}: QueryParameterPaginationProps) {
  const { setQuery } = useQuery();

  const {
    currentPage,
    pageSize: currentPageSize,
    offset,
  } = usePaginationQuery({
    currentPageQueryParameter,
    pageSizeQueryParameter,
    paginationRowCountOptions,
  });

  const changePage = (page: number) => {
    if (page !== currentPage) {
      const newPageParameter = page === 1 ? undefined : page;

      setQuery(
        { [currentPageQueryParameter]: newPageParameter },
        // Amend, don't change path, replace instead of pushing to history stack
        { replace: false, pathname: undefined, useReplace: true }
      );
      onPageChange?.(page);
    }
  };

  const changePageSize = (pageSize: PaginationRowCountValue) => {
    if (pageSize !== currentPageSize) {
      const pageSizeOption =
        paginationRowCountOptions.find(option => option.value === pageSize) ?? paginationRowCountOptions[0];
      const pageSizeParameter =
        pageSizeOption.value === paginationRowCountOptions[0]?.value ? undefined : pageSizeOption.label.toLowerCase();

      // Adjust current page so that it matches the previous position
      const newPageSize = pageSizeOption.value;
      const newPage = newPageSize ? Math.floor(offset / newPageSize) + 1 : 1;

      const newPageParameter = newPage === 1 ? undefined : newPage;

      setQuery(
        { [pageSizeQueryParameter]: pageSizeParameter, [currentPageQueryParameter]: newPageParameter },
        // Amend, don't change path, replace instead of pushing to history stack
        { replace: false, pathname: undefined, useReplace: true }
      );
    }
  };

  return (
    <Pagination
      totalItems={totalItems}
      currentPage={currentPage}
      pageSize={currentPageSize}
      maxVisiblePages={maxVisiblePages}
      onPageChange={changePage}
      onPageSizeChange={changePageSize}
      paginationRowCountOptions={paginationRowCountOptions}
      pageSizeSelectorWidth={pageSizeSelectorWidth}
      firstPageOrAll={firstPageOrAll}
    />
  );
}
