import React from 'react';
import PropTypes from 'prop-types';

import { functionalLocationName } from 'utils/Data/functionalLocations';
import * as Styled from './FunctionalLocationHeader.styled';

// Return component with mobile break element appended.
// Optionally wrap it in another component.
const withMobileBreak = (wrappedComponent, WrapperComponent) => {
  if (!wrappedComponent) {
    return <></>;
  }

  if (WrapperComponent) {
    return (
      <>
        <WrapperComponent>{wrappedComponent}</WrapperComponent>
        <Styled.MobileBreak />
      </>
    );
  }

  return (
    <>
      {wrappedComponent}
      <Styled.MobileBreak />
    </>
  );
};

const getWrappedStatus = statusTag => (statusTag ? <Styled.StatusWrapper>{statusTag}</Styled.StatusWrapper> : <></>);

const functionalLocationTitle = (t, functionalLocation) => {
  if (functionalLocation) {
    const name = functionalLocationName(t, functionalLocation);
    if (name && functionalLocation.functionalLocation) {
      return `${name} (${functionalLocation.functionalLocation})`;
    }
    return name || functionalLocation.functionalLocation || t('N/A');
  }
};

const functionalLocationAddress = functionalLocation => {
  if (functionalLocation) {
    return [functionalLocation.address, functionalLocation.city].filter(Boolean).join(', ');
  }
  return '';
};

export const FunctionalLocationHeader = ({
  functionalLocation,
  title,
  afterTitle,
  statusTag,
  statusTag2,
  afterSubtitle,
  loading,
  buttons,
  hideOnMobile,
  t,
}) => {
  const flPrimaryContent = functionalLocationTitle(t, functionalLocation);
  const flSecondaryContent = functionalLocationAddress(functionalLocation);

  const showTitle = !loading && Boolean(title || afterTitle);
  const showSubTitle = !loading && Boolean(flPrimaryContent || flSecondaryContent || afterSubtitle || statusTag);

  return (
    <Styled.Header hideOnMobile={hideOnMobile}>
      <Styled.TitleContainer>
        {loading && (
          <>
            <Styled.TitleSkeleton />
            <Styled.SubTitleSkeleton />
          </>
        )}
        {showTitle && (
          <Styled.Title>
            {withMobileBreak(title)}
            {withMobileBreak(afterTitle)}
          </Styled.Title>
        )}
        {showSubTitle && (
          <Styled.SubTitle>
            {withMobileBreak(getWrappedStatus(statusTag))}
            {withMobileBreak(getWrappedStatus(statusTag2))}
            {withMobileBreak(flPrimaryContent, Styled.SubtitlePrimaryContent)}
            {withMobileBreak(flSecondaryContent, Styled.SubtitleSecondaryContent)}
            {withMobileBreak(afterSubtitle)}
          </Styled.SubTitle>
        )}
      </Styled.TitleContainer>
      {buttons && <Styled.ButtonContainer>{buttons}</Styled.ButtonContainer>}
    </Styled.Header>
  );
};

FunctionalLocationHeader.propTypes = {
  functionalLocation: PropTypes.shape({
    functionalLocation: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
  }),
  title: PropTypes.string,
  afterTitle: PropTypes.node,
  statusTag: PropTypes.node,
  statusTag2: PropTypes.node,
  afterSubtitle: PropTypes.node,
  loading: PropTypes.bool,
  buttons: PropTypes.node,
  hideOnMobile: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default FunctionalLocationHeader;
