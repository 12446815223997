import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import InputTextWithState from 'components/Form/InputTextWithState';
import { SelectFilter } from './SelectFilter/SelectFilter';

/**
 * Filter UI component. Filtering logic, current value and filter form inputs
 * should be handled in the parent component
 */

export const StyledFilterCss = css`
  position: relative;
  flex: 0 0 100%;
  margin-bottom: var(--size-xs);
  min-width: 8rem;
  max-width: 100%;
  height: 50px;

  ${props => props.theme.media.portrait`
    margin-right: var(--size-xs);
    flex-basis: calc(100% / 2 - var(--size-xs));
    max-width: calc(100% / 2 - var(--size-xs));
  `}

  ${props => props.theme.media.landscape`
    flex-basis: calc(100% / 3 - var(--size-xs));
    max-width: calc(100% / 3 - var(--size-xs));
  `}

  ${props => props.theme.media.desktop`
    flex-basis: calc(100% / 5 - var(--size-xs));
    max-width: calc(100% / 5 - var(--size-xs));
  `}

  ${props => props.theme.media.bigDesktop`
    flex-basis: calc(100% / 7 - var(--size-xs));
    max-width: calc(100% / 7 - var(--size-xs));
  `}
`;
export const StyledFilter = styled.div`
  ${StyledFilterCss}
`;
StyledFilter.displayName = 'StyledFilter';

const Title = styled.span`
  display: block;
  color: var(--input-placeholder-fg);
  white-space: nowrap;
  overflow: hidden;

  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  text-overflow: ellipsis;

  transition: all 0.2s ease;
  transition-property: transform;

  ${props =>
    props.isOpen &&
    !props.currentValue &&
    css`
      transform: translateY(-50%);
    `}
`;
Title.displayName = 'Title';

const IconContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 12px;
`;

const TransformingIcon = styled.svg`
  transition: 0.2s ease all;
  transform: rotate(${props => (props.isOpen ? '180deg' : '0deg')});
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;

  ${props =>
    props.hasValue &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

TitleContainer.displayName = 'TitleContainer';

export const Label = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  border: var(--border-thin) solid ${props => (props.isOpen ? 'var(--input-bc-active)' : 'var(--input-bc)')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background-color: ${props => (props.disabled ? 'var(--input-bg-locked)' : 'var(--input-bg)')};
  flex: 1;

  &:hover {
    border-color: hsl(0, 0%, 70%);
  }

  &::after {
    content: '';
    position: absolute;
  }
  svg {
    fill: var(${props => `--input-subcontrol-fg${props.isOpen ? '-focused' : ''}`});
    width: 8px;
    height: 8px;
  }
`;
Label.displayName = 'Label';

const Value = styled.span`
  display: block;
  font-size: ${props => props.theme.fontSize.xs};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;

  color: var(--input-option-fg);
  background-color: 'transparent';
  margin: 0;
`;
Value.displayName = 'Value';

const Popup = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: var(--filter-z);
  left: 0;
  top: 100%;
  min-width: 100%;
  max-width: calc(100% + var(--size-sm));
  border: var(--border-thin) solid var(--dropdown-bc);
  padding: var(--size-sm);
  box-shadow: var(--box-shadow-02);
  background-color: var(--dropdown-bg);

  ${props => props.theme.media.desktop`
    max-width: none;
  `};
`;
Popup.displayName = 'Popup';

const Overlay = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
Overlay.displayName = 'Overlay';

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
IconWrapper.displayName = 'IconWrapper';

export const FilterLabel = styled.span`
  display: block;
  color: var(--filter-input-label-fg);
  font-size: ${props => props.theme.fontSize.xxxs};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.25;
  position: absolute;
  left: 0.5rem;
  top: calc(0.25rem + 1px);
  transition-property: transform, font-size;
  transition-duration: ${props => props.theme.motion.default};
  transform: translateY(0);
  pointer-events: none;
`;

export const FilterInput = styled(InputTextWithState)`
  &::before {
    content: '${props => props.label || ' '}';
    display: ${props => (props.hasPlaceholder ? 'block' : 'none')};
    color: var(--filter-input-label-fg);
    font-size: ${props => props.theme.fontSize.xxxs};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.25;
    position: absolute;
    left: 0.5rem;
    top: calc(0.25rem + 1px);
  }
  input {
    padding: 0.5rem;
    padding-top: ${props => (props.postLabel || props.hasPlaceholder) && '20px'};
    box-shadow: none;
    line-height: 1;
    font-size: ${props => props.theme.fontSize.xs};

    &::placeholder {
      color: var(--filter-input-value-fg);
      line-height: 1;
      font-size: ${props => props.theme.fontSize.xs};
    }

    &:placeholder-shown:not(:focus) + ${FilterLabel} {
      transform: translateY(calc(0.5rem + 2px));
      font-size: ${props => props.theme.fontSize.xxs};
    }
  }
`;

const Filter = props => {
  const { currentValue, label, children, disabled, options, placeholder, className, onClear } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const hasPlaceholder = !!placeholder;
  if (options) {
    return (
      <StyledFilter role="group">
        <SelectFilter {...props} />
      </StyledFilter>
    );
  }
  if (!children) {
    return (
      <StyledFilter role="group">
        <FilterInput
          {...props}
          hasPlaceholder={hasPlaceholder}
          placeholder={placeholder ?? ' '}
          postLabel={!placeholder && <FilterLabel>{label}</FilterLabel>}
        />
      </StyledFilter>
    );
  }

  const onOpen = !disabled ? () => setIsOpen(!isOpen) : undefined;

  return (
    <StyledFilter role="group" className={className}>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)}></Overlay>
      <Label disabled={disabled} isOpen={isOpen}>
        <TitleContainer onClick={onOpen} hasValue={!!currentValue || hasPlaceholder}>
          <Title hasSibling={Boolean(currentValue || hasPlaceholder)} isOpen={isOpen} currentValue={currentValue}>
            {label}
          </Title>
          <Value hasPlaceholder={!currentValue && hasPlaceholder}>{currentValue || placeholder}</Value>
        </TitleContainer>
        <IconContainer>
          {currentValue && (
            <svg>
              <use xlinkHref={`#remove.inline`} />
              <rect width="12px" height="12px" fillOpacity="0.0" onClick={onClear} />
            </svg>
          )}
          <TransformingIcon isOpen={isOpen}>
            <use xlinkHref={`#caret-down.inline`} />
            <rect width="12px" height="12px" fillOpacity="0.0" onClick={onOpen} />
          </TransformingIcon>
        </IconContainer>
      </Label>
      <Popup isOpen={isOpen}>{children}</Popup>
    </StyledFilter>
  );
};

Filter.defaultProps = {
  onOpen: undefined,
  disabled: false,
  placeholder: null,
};

Filter.propTypes = {
  /* Value that is shown as a current value */
  currentValue: PropTypes.string,
  /* Filter label */
  label: PropTypes.string.isRequired,
  /* Form input as children */
  children: PropTypes.node,
  /* Set filter filter */
  disabled: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onClear: PropTypes.func,
};

export default Filter;
