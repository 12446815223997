import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { functionalLocationName } from 'utils/Data/functionalLocations';

export const getFunctionalLocationLabel = (t, functionalLocation) =>
  `${functionalLocationName(t, functionalLocation)} (${functionalLocation.address})`;

export const getDisciplineLabel = (t, system) => t(`DISCIPLINE_${last(system.functionalLocation.split('-'))}`);

export const getFunctionalLocationOptions = (t, functionalLocations) =>
  sortBy(
    functionalLocations.map(functionalLocation => ({
      label: getFunctionalLocationLabel(t, functionalLocation),
      value: functionalLocation.functionalLocation,
    })),
    ['label']
  );

export const getSystemOptions = (t, systems, selectedFunctionalLocation) =>
  (systems[selectedFunctionalLocation] || []).map(system => ({
    label: getDisciplineLabel(t, system),
    value: system.functionalLocation,
  }));

export const getCategoryOptions = categories =>
  categories.map(category => ({
    label: category.category,
    value: category.id,
  }));

export const getSupplierOptions = suppliers =>
  sortBy(
    suppliers.map(supplier => ({ label: supplier.name, value: supplier.id })),
    ['label']
  );

export const getFunctionalLocationText = (t, functionalLocations, selectedFunctionalLocation) => {
  const functionalLocation = functionalLocations.find(fl => fl.functionalLocation === selectedFunctionalLocation);
  if (functionalLocation) {
    return getFunctionalLocationLabel(t, functionalLocation);
  }
  return selectedFunctionalLocation;
};

export const COUNTRY_CODES = {
  fi: '+358',
  se: '+46',
  no: '+47',
  dk: '+45',
  lt: '+370',
  de: '+49',
  ru: '+7',
  at: '+43',
  ee: '+372',
  development: '+358',
};

export const getPhoneNumberPlaceholder = division => {
  const countryCode = COUNTRY_CODES[division] ?? '';
  return `${countryCode} 1 234 5678`;
};
