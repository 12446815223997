import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import curry from 'lodash/fp/curry';
import startOfDay from 'date-fns/startOfDay';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInDays from 'date-fns/differenceInDays';
import { startOfHour, startOfMonth, startOfWeek } from 'date-fns';
import { CHART_YEAR } from 'components/Charts/ComparisonChart/utils';

export const ANNOTATION_TARGET = {
  building: 'BUILDING',
  customChart: 'CUSTOM_CHART',
  measuringPoint: 'MEASURING_POINT',
  device: 'DEVICE',
};

export const ANNOTATION_TARGET_LABEL = {
  [ANNOTATION_TARGET.building]: 'All devices in building',
  [ANNOTATION_TARGET.customChart]: 'Chart',
  [ANNOTATION_TARGET.measuringPoint]: 'Measuring Point',
  [ANNOTATION_TARGET.device]: 'Device',
  sensor: 'Sensor',
};

const getChartDate = timestamp => {
  const groupTime = new Date(timestamp);
  groupTime.setFullYear(CHART_YEAR);
  groupTime.setMinutes(0);
  groupTime.setSeconds(0);
  groupTime.setMilliseconds(0);
  return groupTime;
};

const granularities = {
  0: 'hour',
  1: 'day',
  2: 'week',
  3: 'month',
};

export const intervalTypeToGranularity = (intervalType, isMobile) => {
  switch (intervalType) {
    case 'hourly':
      return granularities[0 + !!isMobile];
    case 'daily':
      return granularities[1 + !!isMobile];
    case 'weekly':
      return 'week';
    case 'monthly':
    default:
      return 'month';
  }
};

export const getAnnotationGranularity = (start, end, isMobile) => {
  if (differenceInMonths(end, start) >= 6) {
    return isMobile ? 'month' : 'week';
  }
  if (differenceInDays(end, start) >= 7) {
    return isMobile ? 'week' : 'day';
  }
  return isMobile ? 'day' : 'hour';
};

const getStartOfWeek = timestamp =>
  new Date(startOfWeek(timestamp)).getMonth() !== new Date(timestamp).getMonth()
    ? startOfMonth(timestamp)
    : startOfWeek(timestamp);

const startOfPeriod = annotationGranularity => {
  switch (annotationGranularity) {
    case 'month':
      return startOfMonth;
    case 'week':
      return getStartOfWeek;
    case 'day':
      return startOfDay;
    case 'hour':
    default:
      return startOfHour;
  }
};

const groupAnnotationsByGranularity = curry((timestampToDate, annotations, granularity) =>
  map(
    groupBy(annotations, annotation => startOfPeriod(granularity)(timestampToDate(annotation.timestamp)).valueOf()),
    (annotations, timestamp) => ({
      timestamp,
      annotations,
    })
  )
);

export const chartDateAnnotationGroup = groupAnnotationsByGranularity(timestamp => getChartDate(timestamp));
export const timelineAnnotationGroup = groupAnnotationsByGranularity(timestamp => new Date(timestamp));

const eventCoordinate = (event, coordinateProperty) => {
  if (!event) {
    return undefined;
  }
  if (event[coordinateProperty] !== undefined) {
    return event[coordinateProperty];
  }
  if (event.changedTouches?.[0]?.[coordinateProperty] !== undefined) {
    return event.changedTouches[0][coordinateProperty];
  }
};

const adjustmentToTimeline = event => {
  try {
    const highchartsRoot = event?.target?.closest('.highcharts-root');
    const chartArea = highchartsRoot.getBoundingClientRect();
    const plotBackground = highchartsRoot.querySelector('.highcharts-plot-background');
    const plotArea = plotBackground.getBoundingClientRect();
    const timelineHeight = chartArea.height - plotArea.height - plotBackground.y.baseVal.value;
    return chartArea.height - event.chartY - timelineHeight || 0;
  } catch (e) {
    return 0;
  }
};

export const timelineClickPosition = event => ({
  x: eventCoordinate(event, 'clientX') || 0,
  y: eventCoordinate(event, 'clientY') || 0 + adjustmentToTimeline(event),
});

export const annotationsLabelConfigs = (groupedAnnotations, setAnnotationPopover) =>
  groupedAnnotations.map(group => {
    return {
      id: group.timestamp,
      draggable: '',
      labelOptions: {
        align: 'center',
        backgroundColor: '#F7F7F7',
        borderColor: '#727983',
        className: 'xaxis-annotation',
        fontWeight: 'bold',
        textColor: 'black',
        allowOverlap: true,
        borderRadius: 0,
        borderWidth: 1,
        padding: 3,
        overflow: 'none',
        crop: false,
        shape: 'comment',
        style: {
          fontFamily: 'Arial,sans-serif',
        },
        x: -5,
        y: 5,
      },
      labels: [
        {
          text: String(group.annotations.length),
          point: {
            x: Number.parseInt(group.timestamp, 10),
            xAxis: 0,
          },
        },
      ],
      events: {
        click: event => {
          event.stopPropagation();
          setAnnotationPopover({
            ...group,
            position: timelineClickPosition(event),
          });
        },
      },
      zIndex: 8,
    };
  });

export const isEventFromInsideHighchartsInteractiveElement = event =>
  event?.target?.tagName === 'text' ||
  event?.path?.some(
    item => item.classList?.contains('highcharts-button') || item.classList?.contains('highcharts-label')
  );
