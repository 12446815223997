import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';
import SectionHeader from 'components/Section/SectionHeader';
import SideToggle from './SideToggle';
import { StyledOverlay } from 'components/ContextualHelp/ContextualHelp';
import { toggleScroll } from 'utils/Modal/toggleScroll';

const FilterContainer = styled.div`
  position: fixed;
  background: ${props => props.theme.colors.white};
  width: ${props => (props.isOpen ? '100%' : 0)};
  ${props =>
    !props.wide &&
    props.theme.media.portrait`
        width: ${props => (props.isOpen ? '400px' : 0)};
    `};
  ${props =>
    props.wide &&
    props.theme.media.landscape`
        width: ${props => (props.isOpen ? '900px' : 0)};
    `};
  box-shadow: ${props => props.theme.shadows.opiCard};
  transition: width ${props => props.theme.navigation.transition};
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.zIndex('sideFilter')};
`;
FilterContainer.displayName = 'FilterContainer';

const CloseCrossContainer = styled.div`
  position: absolute;
  z-index: 1;
  font-size: ${props => props.theme.font.size.lg};
  top: var(--size-md);
  right: ${props => (props.isOpen ? '1.4em' : '-3em')};
  transition: right ${props => props.theme.navigation.transition};
`;
CloseCrossContainer.displayName = 'CloseCrossContainer';

const CloseCross = styled(Svg)`
  fill: ${props => props.theme.colors.black};
  cursor: pointer;
`;
CloseCross.displayName = 'CloseCross';

const FilterContent = styled.div`
  padding: var(--size-md) var(--size-xl) var(--size-xl);
  height: 100%;
  overflow-y: auto;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s;
  transition-delay: ${props => props.isOpen && '0.3s'};
`;
FilterContent.displayName = 'FilterContent';

const Overlay = styled(StyledOverlay)`
  z-index: ${props => props.theme.zIndex('sideFilter') - 1};
`;
Overlay.displayName = 'Overlay';

const SideFilter = ({ t, useSideToggle, isOpen, filtersOn, toggleFilterClick, wide, children }) => {
  if ((!wide && window.innerWidth < 600) || (wide && window.innerWidth < 1200)) {
    toggleScroll(isOpen);
  }

  return (
    <Fragment>
      <Overlay active={isOpen} onClick={toggleFilterClick} />
      <FilterContainer isOpen={isOpen} wide={wide}>
        <CloseCrossContainer isOpen={isOpen}>
          <CloseCross name="remove" onClick={toggleFilterClick} />
        </CloseCrossContainer>
        {useSideToggle && (
          <SideToggle t={t} isOpen={isOpen} filtersOn={filtersOn} toggleFilterClick={toggleFilterClick} />
        )}
        <FilterContent isOpen={isOpen}>
          <SectionHeader title={t('Filters')} />
          {children}
        </FilterContent>
      </FilterContainer>
    </Fragment>
  );
};

SideFilter.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  useSideToggle: PropTypes.bool.isRequired,
  filtersOn: PropTypes.bool.isRequired,
  toggleFilterClick: PropTypes.func.isRequired,
  wide: PropTypes.bool,
  children: PropTypes.any,
};

export default SideFilter;
