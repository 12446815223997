import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const findAlarms = ({ partnerNumbers, functionalLocations, start, end }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms`,
    query: { partnerNumbers, functionalLocations, start, end },
  });

export const alarmCounts = ({ partnerNumbers, functionalLocations, start, end }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/counts`,
    query: { partnerNumbers, functionalLocations, start, end },
  });

export const alarmActionCount = ({ partnerNumbers, functionalLocations, start, end }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/actionCounts`,
    query: { partnerNumbers, functionalLocations, start, end },
  });

export const alarmKpi = ({ partnerNumbers, functionalLocations, kpi }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/kpis/${kpi}`,
    query: { partnerNumbers, functionalLocations },
  });

export const alarmBenchmarking = ({ partnerNumbers, functionalLocations }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/benchmarking`,
    query: { partnerNumbers, functionalLocations },
  });

export const alarmPerformance = ({ partnerNumbers, functionalLocations }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/performance`,
    query: { partnerNumbers, functionalLocations },
  });

export const alarmCountsPerMonth = ({ partnerNumbers, functionalLocations, year }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/monthly/counts`,
    query: { partnerNumbers, functionalLocations, year },
  });

export const alarmHandlingTimeByMonth = ({ partnerNumbers, functionalLocations, year }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/monthly/averages/handlingTime`,
    query: { partnerNumbers, functionalLocations, year },
  });

export const alarmTimeToActionByMonth = ({ partnerNumbers, functionalLocations, year }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/alarms/monthly/averages/timeToAction`,
    query: { partnerNumbers, functionalLocations, year },
  });
