import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH, SERVICE_API_PATH } from './index';

export const imagesFn = (request, { functionalLocations, partnerNumber, type }, noCache) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/images/find`,
    data: { functionalLocations, partnerNumber, type },
    noCache,
  });

export const uploadAndCreateImageFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/images/uploadAndCreate`,
    data,
    noCache: true,
  });

export const upsertImageFn = (request, data) =>
  request.put({
    apiPath: `${SERVICE_API_PATH}/images`,
    data,
    noCache: true,
  });

export const deleteImageFn = (request, imageId) =>
  request.delete({
    apiPath: `${SERVICE_API_PATH}/images/${imageId}`,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  images: imagesFn,
  uploadAndCreateImage: uploadAndCreateImageFn,
  upsertImage: upsertImageFn,
  deleteImage: deleteImageFn,
});
