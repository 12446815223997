import React from 'react';
import PropTypes from 'prop-types';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import { useTranslations } from 'decorators/Translations/translations';
import styled from 'styled-components';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';

const StyledWasteTypeSelector = styled.div`
  width: 100%;
  margin-bottom: 1em;
  p {
    text-transform: capitalize;
  }
  ${props =>
    props.isMobile
      ? `> div {
        justify-content: center;
        margin-top: 0.5em;
      }`
      : ''}
`;

export const WasteTypeSelector = ({ wasteTypeOptions, selectedWasteType, onWasteTypeChange }) => {
  const [t] = useTranslations();
  const breakpoints = useBreakpoints();
  const isMobile = !breakpoints.landscape;
  return (
    <StyledWasteTypeSelector isMobile={isMobile}>
      <InputSelectDropdown
        t={t}
        id="wasteType"
        model={{ wasteType: selectedWasteType }}
        property="wasteType"
        options={wasteTypeOptions}
        onChange={(property, value) => onWasteTypeChange(value)}
        clearable={false}
      />
    </StyledWasteTypeSelector>
  );
};

WasteTypeSelector.propTypes = {
  wasteTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedWasteType: PropTypes.number,
  onWasteTypeChange: PropTypes.func.isRequired,
};

export default WasteTypeSelector;
