import { performanceSourceLabels } from 'constants/performance';

export const getBuildingPerformanceSourceOptions = (t, features) => {
  const options = [];

  if (!features) {
    return options;
  }

  features.controlRoomAlarms &&
    options.push({
      value: 'alarms',
      label: t(performanceSourceLabels.alarms),
    });

  features.controlRoomObservations &&
    options.push({
      value: 'observations',
      label: t(performanceSourceLabels.observations),
    });

  features.energyTab &&
    options.push({
      value: 'energy',
      label: t(performanceSourceLabels.energy),
    });

  features.energyTab &&
    options.push({
      value: 'energyConsumption',
      label: t(performanceSourceLabels.energyConsumption),
    });

  features.conditions &&
    options.push({
      value: 'indoorAirQuality',
      label: t(performanceSourceLabels.indoorAirQuality),
    });

  return options;
};
