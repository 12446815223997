import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from 'components/Icon/Icon';
import NumberBadge from 'components/NumberBadge/NumberBadge';

export const Link = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: ${props => props.theme.fontSize.sm};
  color: ${props => props.theme.colors.blue};
  padding: 0 var(--size-xl);

  left: 0;
  transition-property: transform, opacity, left;
  transition-duration: 0.4s;
  transition-delay: 0.15s, 0.15s, 0s;
  transition-timing-function: ease;
  cursor: pointer;

  &:hover {
    left: 6px;
  }

  svg {
    margin-right: var(--size-xs);
  }

  ${props => props.theme.media.portrait`
        display: none;
    `}
`;

Link.displayName = 'Link';

const AnnouncementUnreadContainer = styled.div`
  position: absolute;
  top: 0.7em;
  left: 3.4em;
`;

export const AnnouncementLink = ({ onClick, t, unread }) => (
  <Link data-test-id="Announcements" onClick={onClick}>
    <Icon name="bell" fill="currentColor" />
    <AnnouncementUnreadContainer>
      <NumberBadge number={unread} />
    </AnnouncementUnreadContainer>
    {t('Announcements')}
  </Link>
);

AnnouncementLink.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  unread: PropTypes.number.isRequired,
};
