import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import Notification from 'components/Notification/Notification';
import NotificationContent from 'components/Notification/NotificationContent';
import { COLOR_VARIANT } from 'components/Notification/notification.constants';
import PropTypes from 'prop-types';
import React from 'react';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextContainer = styled.div`
  color: var(--white);
  padding: var(--size-md);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
`;

const IconPositioner = styled.div`
  padding: 0 var(--size-sm);
`;

const Icon = styled(Svg)`
  fill: var(--white);
  font-size: ${props => props.theme.font.size.md};
`;

/**
 * This is not intended to be used directly. Use e.g. {@link SuccessNotification} instead.
 */
export function GenericTextNotification({ visible, text, variant, iconName }) {
  return (
    <Notification visible={visible}>
      <NotificationContent variant={variant}>
        <FlexContainer>
          <TextContainer>{text}</TextContainer>
          {iconName && (
            <IconPositioner>
              <Icon name={iconName} />
            </IconPositioner>
          )}
        </FlexContainer>
      </NotificationContent>
    </Notification>
  );
}

GenericTextNotification.propTypes = {
  text: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([COLOR_VARIANT.Green, COLOR_VARIANT.Blue, COLOR_VARIANT.Red, COLOR_VARIANT.Black])
    .isRequired,
  iconName: PropTypes.string,
};
