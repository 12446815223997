import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';


export const getTagGroupsFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/tagGroups/find`,
    data
  })

export const upsertTagGroupFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/tagGroups/upsert`,
    data,
    noCache: true
  })

export const deleteTagGroupFn = (request, tagGroupId) =>
  request.delete({
    apiPath: `${MASTER_DATA_PATH}/tagGroups/${tagGroupId}`,
    noCache: true
  })

export const upsertTagDetailsFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/tagDetails/upsert`,
    data,
    noCache: true
  })

export const deleteTagDetailsFn = (request, tagId) =>
  request.delete({
    apiPath: `${MASTER_DATA_PATH}/tagDetails/${tagId}`,
    noCache: true
  })


export default wrapEachWithThunkRequest({
  getTagGroups: getTagGroupsFn,
  upsertTagGroup: upsertTagGroupFn,
  deleteTagGroup: deleteTagGroupFn,
  upsertTagDetails: upsertTagDetailsFn,
  deleteTagDetails: deleteTagDetailsFn,
});
