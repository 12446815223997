import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import themes from 'styles/themes';
import Icon from 'components/Icon/Icon';

// margin-top is a hack to align the box alongside of the charts
const StyledValuesBox = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;
  margin-bottom: var(--size-sm);
`;
StyledValuesBox.displayName = 'StyledValuesBox';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: var(--radius-md);
  border: var(--light-separator-border);
  padding: var(--size-xxs);

  ${props => props.theme.media.desktop`
    display: block;
    padding: var(--size-md);
  `}
`;

const MobileWrapper = styled.div`
  display: flex;

  ${props => props.theme.media.desktop`
    flex-direction: column;
  `}
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: 1rem;
  padding: var(--size-md) var(--size-md) 0;
  color: var(--headline-fg);

  ${props => props.theme.media.desktop`
    text-align: center;
    padding: var(--size-xs);
  `}
`;
Title.displayName = 'Title';

export const ValueWrapper = styled.div`
  padding: var(--size-md);
  line-height: 1;

  ${props => props.theme.media.desktop`
    padding: ${props => props.footer && '0'};
    text-align: center;
  `}
`;
ValueWrapper.displayName = 'ValueWrapper';

const IconWrapper = styled.div`
  display: none;
  margin-top: var(--size-xs);
  text-align: center;

  svg {
    margin: 0;
  }

  ${props => props.theme.media.desktop`
    display: block;
  `}
`;
IconWrapper.displayName = 'IconWrapper';

export const Value = styled.div`
  font-size: ${props => props.theme.fontSize.md};
  color: ${props => (props.valueIsNegative ? 'var(--negative-color)' : 'var(--positive-color)')};

  ${props => props.theme.media.desktop`
    font-size: ${props => props.theme.fontSize.lg};
  `}
`;
Value.displayName = 'Value';

export const Label = styled.div`
  margin-top: var(--size-xxs);
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.fontSize.xxs};
  line-height: var(--line-height-tight);
`;
Label.displayName = 'Label';

export const ValuesBoxFooter = styled.div`
  ${Value} {
    color: var(--violet-01);
  }

  ${props => props.theme.media.desktop`
    background: var(--grey-08);
    overflow: hidden;
    margin: calc(-1 * var(--size-md));
    margin-top: var(--size-sm);
    padding: var(--size-sm);
    border-top: var(--light-separator-border);
    text-align: center;
    ${Title} {
      padding-top: 0;
    }
    ${Value} {
      font-size: ${props => props.theme.fontSize.md};
    }
    ${Label} {
      margin-top: var(--size-sm)};
    }
  `}
`;
ValuesBoxFooter.displayName = 'ValuesBoxFooter';

const ValuesBox = ({
  heading,
  value,
  icon,
  valueIsNegative,
  label,
  children,
  positiveValueColor,
  footer,
  ...others
}) => {
  return (
    <StyledValuesBox {...others}>
      <Wrapper>
        <div>
          {heading && <Title>{heading}</Title>}
          {icon && (
            <IconWrapper>
              <Icon name={icon} fill={valueIsNegative ? null : positiveValueColor} />
            </IconWrapper>
          )}
          <MobileWrapper>
            {(value || label) && (
              <ValueWrapper>
                {value && (
                  <Value valueIsNegative={valueIsNegative} positiveColor={positiveValueColor}>
                    {value}
                  </Value>
                )}
                {label && <Label>{label}</Label>}
              </ValueWrapper>
            )}
            {children}
          </MobileWrapper>
        </div>
        {footer?.header && footer?.value && (
          <ValuesBoxFooter>
            <Title>{footer.header}</Title>
            <ValueWrapper footer>
              <Value>{footer.value}</Value>
              {footer.label && <Label>{footer.label}</Label>}
            </ValueWrapper>
          </ValuesBoxFooter>
        )}
      </Wrapper>
    </StyledValuesBox>
  );
};

ValuesBox.defaultProps = {
  heading: '',
  value: '',
  label: '',
  valueIsNegative: false,
  positiveValueColor: 'var(--positive-color)',
};

ValuesBox.propTypes = {
  /** Heading for the box */
  heading: PropTypes.string,
  /** Value with unit */
  value: PropTypes.string,
  /** Value label */
  label: PropTypes.string,
  /** Boolean to indicate value's negative sense in the context of UI */
  valueIsNegative: PropTypes.bool,
  /** Color to be used in positive context */
  positiveValueColor: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  footer: PropTypes.shape({
    header: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default ValuesBox;
