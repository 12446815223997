export type PageSpread = number[];

export type PageFold = {
  left?: PageSpread;
  leftEllipsis?: boolean;
  mid?: PageSpread;
  rightEllipsis?: boolean;
  right?: PageSpread;
};

export function spread(count: number, origin = 1): number[] {
  return count ? Array.from(Array(count).keys(), key => key + origin) : [];
}

export function foldPages(selectedPage: number, totalPages: number, maxVisiblePages: number): PageFold {
  // Trivial case
  if (totalPages <= maxVisiblePages) {
    return { mid: spread(totalPages) };
  }

  // Span left and right from the selected page
  let midLeft = selectedPage - Math.floor((maxVisiblePages - 1) / 2);
  let midRight = selectedPage + Math.ceil((maxVisiblePages - 1) / 2);

  // Should snap to the first page
  if (midLeft <= 1) {
    const offset = 1 - midLeft;
    midRight += offset;

    const midSpreadWidth = Math.min(midRight, maxVisiblePages - 2);

    return {
      mid: spread(midSpreadWidth),
      rightEllipsis: true,
      right: [totalPages],
    };
  }

  // Should snap to the last page
  if (midRight >= totalPages + 1) {
    const offset = totalPages - midRight;
    midLeft += offset;
    midRight += offset;

    const midSpreadWidth = Math.min(midRight - midLeft, maxVisiblePages - 2);

    return {
      left: [1],
      leftEllipsis: true,
      mid: spread(midSpreadWidth, totalPages - midSpreadWidth + 1),
    };
  }

  // Split in three parts
  return {
    left: [1],
    leftEllipsis: true,
    mid: spread(midRight - midLeft - 3, midLeft + 2),
    rightEllipsis: true,
    right: [totalPages],
  };
}
