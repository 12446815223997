import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import sortByFunc from 'lodash/sortBy';
import partition from 'lodash/partition';

import MultiInputRow from './MultiInputRow';
import PrimaryButton from 'components/Button/PrimaryButton';

export const StyledButton = styled(PrimaryButton)`
  margin-top: var(--size-md);
`;

const sortModel = (model, sortBy) => {
  if (!model) {
    return model;
  }
  const [oldRows, newRows] = partition(model, row => !!row.id);
  return [...sortByFunc(oldRows, sortBy), ...newRows];
};

const MultiInput = props => {
  const containerRef = useRef(null);
  const { t, model, onAddRow, addRowLabel, baseProperty, sortBy, onContainerClick } = props;

  if (!model) {
    return null;
  }

  const modelWithIndex =
    model[baseProperty] &&
    model[baseProperty].map((row, index) => {
      row.index = index;
      return row;
    });

  const sortedModel = sortBy ? { ...model, [baseProperty]: sortModel(modelWithIndex, sortBy) } : model;

  return (
    <div
      onClick={event => {
        if (onContainerClick && event.target === containerRef?.current) {
          onContainerClick(event);
        }
      }}
      ref={containerRef}
    >
      {sortedModel[baseProperty] &&
        sortedModel[baseProperty].map((row, index) => (
          <MultiInputRow
            key={`${baseProperty}-${index}`}
            row={row}
            index={row.index}
            isFirstRow={index === 0}
            isLastRow={index === model[baseProperty].length - 1}
            {...props}
          />
        ))}

      <StyledButton add large onClick={onAddRow}>
        {addRowLabel || t('Add row')}
      </StyledButton>
    </div>
  );
};

MultiInput.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired, // data
  onAddRow: PropTypes.func.isRequired, // to be called when Add row -button is clicked
  baseProperty: PropTypes.string.isRequired, // property in model that contains multi input data
  addRowLabel: PropTypes.string, // custom label for Add row -button
  sortBy: PropTypes.string,
  scrollToFirstInput: PropTypes.bool,
  onContainerClick: PropTypes.func,
};

export default MultiInput;
