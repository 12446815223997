import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import withQuery from 'decorators/Query/withQuery';

import StandardPage from 'components/StandardPage/StandardPage';
import { scrollTo, CUSTOM_TYPE, formatTitleForUrl } from '../utils/UserManualUtils';
import OnImagesLoaded from 'components/OnImagesLoaded/OnImagesLoaded';
import UserManualContent from './UserManualContent';
import UserManualSectionList from './UserManualSectionList';
import { UserManualHeader } from './UserManualHeader';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import SkeletonText from 'components/Skeletons/SkeletonText';
import SkeletonChart from 'components/Skeletons/SkeletonChart';
import { loadUserManualV2Data } from 'redux/modules/userManual/userManual';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import { MOBILE_SECTION_SELECTOR_HEIGHT } from './UserManualSectionList';

const PageContainer = styled.div`
  position: initial;
`;

const StyledUserManualContainer = styled.div`
  display: flex;
`;

const SectionListFill = styled.div`
  display: none;

  ${props => props.theme.media.landscape`
    display: block;
    width: 25%;
    max-width: 300px;
    flex-shrink: 0;
  `};
`;

const UserManualContentContainer = styled.div`
  padding: var(--size-xxl);
  padding-top: ${props => `calc(var(--size-xxl) + ${MOBILE_SECTION_SELECTOR_HEIGHT}px)`};
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  margin: 0 auto;

  ${props => props.theme.media.landscape`
    padding: var(--size-xxl);
    width: 75%;
    max-width: 1100px;
  `};
`;

UserManualContentContainer.displayName = 'UserManualContentContainer';

export const UserManualContainer = ({
  query,
  setQuery,
  profile,
  userManualStoreEntry,
  title,
  loadUserManualV2Data,
}) => {
  const [selectedPage, setSelectedPage] = React.useState(null);

  React.useEffect(() => {
    loadUserManualV2Data(profile.features.buildingAndPortfolioAdminTools);
  }, []);

  const onLoaded = () => {
    const { page, title } = query;
    if (page) {
      setSelectedPage(page);
    }
    if (title) {
      setTimeout(() => scrollTo(title), 100);
    }
  };

  const handleSetSelectedPage = (page, title) => {
    setQuery({ page, title });
    setSelectedPage(page);
  };

  const getUserManualData = userManualStoreEntry => {
    let pages = Array.isArray(userManualStoreEntry?.manual)
      ? userManualStoreEntry?.manual
      : [userManualStoreEntry?.manual];
    pages = pages
      .map(page => {
        if (page) {
          page.orderNumber = parseInt(page?.tags[0], 10);
        }
        return page;
      })
      .filter(page => page);
    pages = orderBy(pages, 'orderNumber');
    return pages || [];
  };

  const getSkeleton = () => (
    <Fragment>
      <SkeletonText header />
      <SkeletonText style={{ margin: '2em 0', width: '20%' }} />
      <SkeletonChart height="200px" />
    </Fragment>
  );

  const onMenuPageItemClick = (selectedPage, title, data) => {
    setSelectedPage(selectedPage);
    const pageTitle = formatTitleForUrl(title ?? data.find(page => page.uid === selectedPage)?.data?.title[0]?.text);
    setQuery({ page: selectedPage, title: pageTitle });
    setTimeout(() => scrollTo(pageTitle, title, 100), 100);
  };

  const data = getUserManualData(userManualStoreEntry);
  const dataSuccessfullyLoaded = !isEmpty(data);
  const error = !!userManualStoreEntry.error;
  const loading = userManualStoreEntry.loading;
  const selectedPageData = selectedPage ? data.find(page => page.uid === selectedPage)?.data : data[0]?.data;

  if (!loading && (error || !dataSuccessfullyLoaded)) {
    return <ErrorPage />;
  }

  return (
    <FadeTransition>
      <StandardPage noMargin>
        <Helmet title={title} />
        <PageContainer>
          {dataSuccessfullyLoaded && !loading && (
            <UserManualSectionList
              profile={profile}
              pages={data}
              loading={loading}
              onPageItemClick={(selectedPage, title) => onMenuPageItemClick(selectedPage, title, data)}
              onTitleItemClick={handleSetSelectedPage}
            />
          )}
          <StyledUserManualContainer>
            <SectionListFill />
            <UserManualContentContainer>
              {loading && getSkeleton()}
              {dataSuccessfullyLoaded && !loading && (
                <Fragment>
                  <UserManualHeader type={CUSTOM_TYPE.USER_MANUAL} data={selectedPageData || {}} />
                  <OnImagesLoaded onLoaded={() => onLoaded(data)} timeout={10000}>
                    <UserManualContent pageUid={selectedPage || ''} data={selectedPageData || {}} />
                  </OnImagesLoaded>
                </Fragment>
              )}
            </UserManualContentContainer>
          </StyledUserManualContainer>
        </PageContainer>
      </StandardPage>
    </FadeTransition>
  );
};

UserManualContainer.defaultProps = {
  userManual: PropTypes.shape({
    manual: null,
    error: null,
  }),
};

UserManualContainer.propTypes = {
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  userManualStoreEntry: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    manual: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object.isRequired), PropTypes.object.isRequired]),
    error: PropTypes.object,
  }),
  loadUserManualV2Data: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userManualStoreEntry: state.userManual,
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  loadUserManualV2Data,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withQuery(connector(UserManualContainer));
