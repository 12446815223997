import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

export const IconContainer = styled.span`
  display: inline-block;
  ${props => (props.marginRight ? ' margin-right: var(--size-xs);' : '')}
  font-size: ${props => (props.large ? props.theme.font.size.sm : props.theme.font.size.xs)};

  & > svg {
    vertical-align: middle;
  }

  & > div {
    display: inline-block;
    vertical-align: middle;
    width: ${props => (props.large ? 16 : 14)}px;
    height: ${props => (props.large ? 16 : 14)}px;
  }
`;
IconContainer.displayName = 'IconContainer';

const ButtonIcon = ({ iconName, loading, marginRight, large, loaderColor = 'WHITE' }) => (
  <IconContainer marginRight={marginRight} large={large}>
    {loading && <Loader color={loaderColor} pixelSize={large ? 16 : 14} />}
    {!loading && iconName && <Svg name={iconName} />}
  </IconContainer>
);
ButtonIcon.displayName = 'ButtonIcon';

ButtonIcon.propTypes = {
  iconName: PropTypes.string,
  loaderColor: PropTypes.string,
  loading: PropTypes.bool,
  marginRight: PropTypes.bool,
  large: PropTypes.bool,
};

export default ButtonIcon;
