import { connect } from 'react-redux';
import { getProfile, wasteSensorsSelector } from 'containers/Application/Modules/RecyclingModule/common/selectors';

const getBreakdownMeta = state => state.values.recycling.amountsBreakdownMonthlies.meta;
const getBreakdownMonthliesData = state => state.values.recycling.amountsBreakdownMonthlies.data;
const getBreakdownMonthliesTotals = state => state.values.recycling.amountsBreakdownMonthliesTotals.data;

export const mapStateToProps = (state, props) => ({
  profile: getProfile(state),
  wasteSensors: wasteSensorsSelector(state, props),
  monthliesData: getBreakdownMonthliesData(state),
  totalsData: getBreakdownMonthliesTotals(state),
  isLoading: getBreakdownMeta(state).loading,
});

const connector = connect(mapStateToProps);

export default connector;
