import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Indicator = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fontSize.xxs};
  color: var(--white);
  border-radius: var(--size-xxs);
  padding: 2px var(--size-xxs);
  margin: 0;
`;

const InactiveIndicator = styled(Indicator)`
  background-color: var(--inactive-color);
`;

const ActiveIndicator = styled(Indicator)`
  background-color: var(--success-color);
`;

const ActivityIndicator = ({ active, t }) =>
  active ? <ActiveIndicator>{t('Active')}</ActiveIndicator> : <InactiveIndicator>{t('Inactive')}</InactiveIndicator>;

ActivityIndicator.propTypes = {
  active: PropTypes.bool,
  t: PropTypes.func,
};

export default ActivityIndicator;
