import React from 'react';
import PropTypes from 'prop-types';
import toUpper from 'lodash/toUpper';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import DialogModal from 'components/Dialog/DialogModal';
import ExportSensorDataForm, { GRANULARITY } from 'components/ExportData/ExportSensorDataForm';
import connect from 'components/ExportData/connectExportData';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import { getDateRanges } from 'components/Form/DateTools';
import { withRouter } from 'react-router-dom';

const Heading = styled.h3`
  margin-bottom: var(--size-md);
`;

const getDefaultTimePeriod = dateRange => {
  const defaults = getDateRanges(Date.now(), true)[dateRange].value;
  return {
    startDatetime: defaults[0],
    endDatetime: defaults[1],
  };
};

export const ExportData = ({
  t,
  exportType,
  onSuccess,
  onError,
  onClose,
  defaultDateRange,
  showDateRangeAllOption,
  defaultGranularity,
  sensors,
  buildingOptions,
  sensorTypeOptions,
  exportSensorData,
  submitting,
}) => {
  const [model, setModel] = React.useState({
    categoriesSelected: [],
    sensorTypesSelected: [],
    granularity: defaultGranularity,
    ...getDefaultTimePeriod(defaultDateRange),
  });
  const [wasteTypeOptions, setWasteTypeOptions] = React.useState(sensorTypeOptions);
  const handleFormChange = (property, value) => {
    setModel({
      ...model,
      [property]: value,
    });
    if (property === 'categoriesSelected') {
      if (value.length === 0) {
        setWasteTypeOptions(sensorTypeOptions);
      } else {
        const selectedSensorTypeIds = uniq(
          sensors.filter(sensor => value?.includes(sensor.functionalLocationId)).map(sensor => sensor.sensorTypeId)
        );
        setWasteTypeOptions(sensorTypeOptions.filter(option => selectedSensorTypeIds.includes(option.value)));
      }
    }
  };
  const getSelectedSensorIds = (categoriesSelected, sensorTypesSelected) => {
    return sensors
      .filter(sensor =>
        !isEmpty(categoriesSelected) ? categoriesSelected.includes(sensor.functionalLocationId) : true
      )
      .filter(sensor => (!isEmpty(sensorTypesSelected) ? sensorTypesSelected.includes(sensor.sensorTypeId) : true))
      .map(sensor => sensor.id);
  };
  const handleDialogClose = e => {
    e.preventDefault();
    onClose();
  };
  const showError = message => {
    onError(message);
  };
  const handleSubmit = e => {
    e.preventDefault();
    const { categoriesSelected, sensorTypesSelected, startDatetime, endDatetime, granularity } = model;

    const sensorIds = getSelectedSensorIds(categoriesSelected, sensorTypesSelected);
    if (isEmpty(sensorIds)) {
      showError(t('No sensors selected. Please select at least one sensor.'));
      return;
    }
    const payload = {
      format: exportType,
      sensorIds,
      start: startDatetime.toISOString(),
      end: endDatetime.toISOString(),
      ...(granularity !== 'raw' ? { granularity } : {}),
    };
    exportSensorData(payload).then(response => {
      if (response.error) {
        return onError(t('EXPORT_FAILED'));
      }
      onClose();
      return onSuccess(t('EXPORT_SUCCESS'));
    });
  };
  return (
    <form>
      <DialogModal
        t={t}
        isActive
        animate
        onOverlayClick={handleDialogClose}
        actions={{
          cancel: {
            onClick: handleDialogClose
          },
          confirm: {
            onClick: handleSubmit,
            loading: submitting,
            label: 'Export'
          }
        }}
      >
        <Heading>{t('Export to {0}', toUpper(exportType))}</Heading>
        <ExportSensorDataForm
          t={t}
          granularityOptions={GRANULARITY}
          model={model}
          onPropertyChange={handleFormChange}
          categoryTitle="Buildings"
          categoryPlaceholderText="All buildings"
          categoryOptions={buildingOptions}
          sensorTypeOptions={wasteTypeOptions}
          defaultDateRange={defaultDateRange}
          showDateRangeAllOption={showDateRangeAllOption}
          defaultGranularity={defaultGranularity}
        />
      </DialogModal>
    </form>
  );
};

ExportData.propTypes = {
  t: PropTypes.func.isRequired,
  exportType: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  defaultDateRange: PropTypes.string.isRequired,
  showDateRangeAllOption: PropTypes.bool,
  defaultGranularity: PropTypes.string.isRequired,
  sensors: PropTypes.array.isRequired,
  buildingOptions: PropTypes.array,
  sensorTypeOptions: PropTypes.array.isRequired,
  sensorFilterFn: PropTypes.func.isRequired,
  exportSensorData: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

ExportData.defaultProps = {
  sensors: [],
  buildingOptions: [],
  sensorTypeOptions: [],
  exportType: 'csv',
};

export default withRouter(translations(connect(ExportData)));
