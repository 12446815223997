import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

const MagnifierIcon = styled(({ show, isLoading, ...props }) => <Svg {...props} />)`
  position: absolute;
  left: 0.875rem;
  top: 0.95rem;
  font-size: ${props => props.theme.font.size.md};
  fill: ${props => (props.isLoading ? 'var(--input-loader-fg)' : 'var(--search-icon-fg)')};
`;
MagnifierIcon.displayName = 'MagnifierIcon';

const LoaderWrapper = styled.div`
  position: absolute;
  right: 0.875rem;
  top: 0.95rem;
`;
LoaderWrapper.displayName = 'LoaderWrapper';

const Input = styled.input`
  appearance: none;
  height: var(--input-height);
  width: 100%;
  background-color: var(--input-bg);
  padding: ${props => props.theme.input.padding};
  border: ${props =>
    (props.error && props.theme.input.border.error) ||
    (props.ok && props.theme.input.border.ok) ||
    props.theme.input.border.static};
  box-shadow: ${props => (props.error ? props.theme.input.shadow.errorStatic : props.theme.input.shadow.searchStatic)};
  color: var(--input-fg);
  font-size: ${props => props.theme.input.font.mobileSize};
  font-family: ${props => props.theme.input.font.family};
  line-height: ${props => props.theme.input.lineHeight.default};
  transition: ${props => props.theme.input.transition};
  padding-left: ${props => (props.noMagnifier ? props.theme.input.padding : `calc(1.8 * ${props.theme.font.size.lg})`)};

  &::-webkit-search-cancel-button {
    display: ${props => props.isLoading && 'none'};
  }

  &:hover,
  &:focus {
    box-shadow: ${props =>
      props.error ? props.theme.input.shadow.errorActive : props.theme.input.shadow.searchActive};
    border: ${props =>
      (props.error && props.theme.input.border.error) ||
      (props.ok && props.theme.input.border.ok) ||
      props.theme.input.border.active};
  }

  &:focus,
  &:active {
    color: var(--input-fg);
    + svg {
      fill: var(--input-subcontrol-fg-focused);
    }
  }

  &:disabled {
    background-color: var(--input-bg-locked);
  }

  &::placeholder {
    color: var(--input-placeholder-fg);
  }

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.input.font.size};
  `}
`;
Input.displayName = 'Input';

const Container = styled.div`
  position: relative;
  width: ${props => props.width};
`;
Container.displayName = 'Container';

class InputSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticker: null,
      value: props.initialValue || '',
    };
  }

  handleChange(value) {
    if (value.length === 0) {
      this.props.onClear && this.props.onClear();
    }

    this.setState(prevState => {
      if (prevState.ticker !== null) {
        clearTimeout(prevState.ticker);
      }

      const ticker =
        typeof value === 'string' && value.length >= this.props.minLength
          ? setTimeout(() => this.props.onChange(value), 500)
          : null;

      return {
        ticker: ticker,
        value: value,
      };
    });
  }

  render() {
    const {
      width,
      className,
      id,
      placeholder,
      noMagnifier,
      loading,
      hasError,
      ok,
      inputRef,
      onFocus,
      onBlur,
      disabled,
    } = this.props;
    const value = this.state.value;

    return (
      <Container width={width} className={className}>
        <Input
          type="search"
          id={id}
          value={value}
          onChange={e => this.handleChange(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          placeholder={placeholder}
          error={hasError}
          isLoading={loading}
          ok={ok}
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          noMagnifier={noMagnifier}
          disabled={disabled}
        />
        {loading && (
          <LoaderWrapper>
            <Loader color="GRAY" size="SMALL" />
          </LoaderWrapper>
        )}
        {!noMagnifier && <MagnifierIcon name="magnifier" isLoading={loading} />}
      </Container>
    );
  }
}

InputSearch.propTypes = {
  width: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  noMagnifier: PropTypes.bool,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  ok: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  minLength: PropTypes.number,
};

InputSearch.defaultProps = {
  minLength: 3,
};

export default InputSearch;
