import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ButtonDropdownItem from './ButtonDropdownItem';
import SimpleDropdown, { Caret, Content } from 'components/SimpleDropdown/SimpleDropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import PrimaryButton from 'components/Button/PrimaryButton';
import SecondaryButton from 'components/Button/SecondaryButton';

const RelativeWrapper = styled.div`
  position: relative;
  display: flex;

  justify-content: ${props =>
    props.mobileAlignment === 'center' ? 'center' : props.mobileAlignment === 'left' ? 'flex-start' : 'flex-end'};

  ${props => props.theme.media.portrait`
    justify-content: ${props =>
      props.alignment === 'center' ? 'center' : props.alignment === 'left' ? 'flex-start' : 'flex-end'};
  `}
`;
RelativeWrapper.displayName = 'RelativeWrapper';

const SimpleDropdownWrapper = styled.div`
  overflow: visible;
  position: absolute;
  z-index: ${props => props.theme.zIndex('dropdown')};

  ${props =>
    props.position === 'bottom' &&
    css`
      top: calc(50% + 18px);
    `}
  ${props =>
    props.position === 'top' &&
    css`
      bottom: calc(50% + 18px);
    `}

  right: ${props => (props.mobileAlignment === 'center' ? '50%' : props.mobileAlignment === 'left' ? 'auto' : '0')};
  left: ${props => (props.mobileAlignment === 'left' ? 0 : 'auto')};
  transform: ${props => (props.mobileAlignment === 'center' ? 'translateX(50%)' : 'none')};

  ${props => props.theme.media.portrait`
    right: ${props => (props.alignment === 'center' ? '50%' : props.alignment === 'left' ? 'auto' : '0')};
    left: ${props => (props.alignment === 'left' ? 0 : 'auto')};
    transform: ${props => (props.alignment === 'center' ? 'translateX(50%)' : 'none')};
  `}

  ${Caret} {
    right: ${props =>
      props.mobileAlignment === 'center' ? 'calc(50% - 10px)' : props.mobileAlignment === 'right' ? '6px' : 'auto'};
    left: ${props => (props.mobileAlignment === 'left' ? '6px' : 'auto')};
    ${props => props.theme.media.portrait`
      right: ${props =>
        props.alignment === 'center' ? 'calc(50% - 10px)' : props.alignment === 'right' ? '6px' : 'auto'};
      left: ${props => (props.alignment === 'left' ? '6px' : 'auto')};
    `}
  }

  // enables multi-level dropdown
  ${Content} {
    overflow: visible;
  }
`;
SimpleDropdownWrapper.displayName = 'SimpleDropdownWrapper';

const ButtonDropdown = ({
  buttonLabel,
  items,
  alignment = 'right',
  position = 'bottom',
  mobileAlignment,
  subMenuAlignment,
  mobileSubMenuAlignment,
  secondary = false,
  buttonProps,
  ButtonComponent,
  textAlign,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleButtonClick = () => setMenuOpen(!menuOpen);
  const handleOutsideClick = () => setMenuOpen(false);

  let ButtonComp = secondary ? SecondaryButton : PrimaryButton;
  const hasButtonComponent = typeof ButtonComponent === 'function';

  if (hasButtonComponent) {
    ButtonComp = ButtonComponent;
  }

  const _mobileAlignment = mobileAlignment || alignment;
  const _subMenuAlignment = subMenuAlignment || (alignment === 'right' ? 'right' : 'left');
  const _mobileSubMenuAlignment = mobileSubMenuAlignment || (_mobileAlignment === 'right' ? 'right' : 'left');

  return (
    <RelativeWrapper alignment={alignment} mobileAlignment={_mobileAlignment}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <ButtonComp
          add={!secondary && !hasButtonComponent ? true : undefined} // default add style button for PrimaryButton
          {...buttonProps}
          isActive={menuOpen}
          onClick={handleButtonClick}
        >
          {buttonLabel}
        </ButtonComp>
        {menuOpen && (
          <SimpleDropdownWrapper alignment={alignment} position={position} mobileAlignment={_mobileAlignment}>
            <SimpleDropdown
              disableCaret
              left={alignment === 'left'}
              mobileLeft={_mobileAlignment === 'left'}
              top={position === 'top'}
            >
              {items.map(item => (
                <ButtonDropdownItem
                  key={item.id || item.label}
                  item={item}
                  subMenuAlignment={_subMenuAlignment}
                  mobileSubMenuAlignment={_mobileSubMenuAlignment}
                  onCloseAll={handleOutsideClick}
                  textAlign={textAlign}
                />
              ))}
            </SimpleDropdown>
          </SimpleDropdownWrapper>
        )}
      </OutsideClickHandler>
    </RelativeWrapper>
  );
};

ButtonDropdown.propTypes = {
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      bold: PropTypes.bool,
      onClick: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          bold: PropTypes.bool,
          onClick: PropTypes.func,
          useWarningColor: PropTypes.bool,
        })
      ),
    })
  ),
  alignment: PropTypes.oneOf(['right', 'left', 'center']),
  position: PropTypes.oneOf(['top', 'bottom']),
  mobileAlignment: PropTypes.oneOf(['right', 'left', 'center']),
  subMenuAlignment: PropTypes.oneOf(['right', 'left']),
  mobileSubMenuAlignment: PropTypes.oneOf(['right', 'left']),
  secondary: PropTypes.bool,
  activeToggle: PropTypes.bool,
  ButtonComponent: PropTypes.elementType,
  textAlign: PropTypes.oneOf(['left', 'center']),
};

export default ButtonDropdown;
