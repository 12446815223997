import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import PartnerOverview from 'containers/Application/PartnerOverview/PartnerOverview';
import FunctionalLocation from 'containers/Application/FunctionalLocation';
import Maintenance from 'containers/Application/Maintenance';
import Equipment from 'containers/Application/Equipment/Equipment';
import ServiceOrder from 'containers/Application/ServiceOrder';
import ServiceRequest from 'containers/Application/ServiceRequest/ServiceRequest';
import Energy from '../Energy/Energy';
import Recycling from 'containers/Application/Recycling/Recycling';
import CustomViewPartnerModule from 'containers/Application/CustomViews/CustomViewPartnerModule';
import PartnerDocumentModule from 'containers/Application/PartnerDocuments/PartnerDocumentModule';
import ContactModule from 'containers/Application/Modules/ContactModule/ContactModule';
import RemoteCenter from 'containers/Application/RemoteCenter/RemoteCenter';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import PartnerRescuePlan from 'containers/Application/PartnerRescuePlan/PartnerRescuePlan';

const PartnerOnlyRoutes = ({ partnerNumber, path }) => {
  if (!partnerNumber || partnerNumber === 'all') {
    return (
      <Route>
        <ErrorPage type="selectPartner" />
      </Route>
    );
  }
  return (
    <Switch>
      <Route exact path={`${path}/ContactCaverion`} component={ContactModule} key="contact" />
      <Route path={`${path}/FunctionalLocation/:functionalLocationId`} component={FunctionalLocation} key="fl" />
      <Route path="/FunctionalLocation/:functionalLocationId" component={FunctionalLocation} key="fl2" />
      <Route
        path={`${path}/Equipment/:functionalLocationId/:superordinate/:equipmentNumber`}
        component={Equipment}
        key="equipment"
      />
      <Route
        path={`${path}/Equipment/:functionalLocationId//:equipmentNumber`}
        component={Equipment}
        key="equipment2"
      />
      <Route
        exact
        path={`${path}/ServiceOrder/:functionalLocationId/:serviceOrderNumber/`}
        component={ServiceOrder}
        key="serviceOrder"
      />
      <Route
        exact
        path="/ServiceOrder/:functionalLocationId/:serviceOrderNumber/"
        component={ServiceOrder}
        key="serviceOrder2"
      />
      <Route exact path={`${path}/Energy/`} component={Energy} key="energy" />
      <Route exact path={`${path}/Recycling/`} component={Recycling} key="recycling" />
      <Route exact path={`${path}/Report/:customViewId`} component={CustomViewPartnerModule} key="report" />
      <Route path={`${path}/Documents`} component={PartnerDocumentModule} key="documents" />
      <Route path={`${path}/DocumentsV2`} component={PartnerDocumentModule} key="documents2" />
      <Route exact path={`${path}`} component={PartnerOverview} key="overview" />
      <Route path={`${path}/RemoteCenter`} component={RemoteCenter} key="remoteCenter" />
      <Route path={`${path}/RescuePlans`} component={PartnerRescuePlan} key="rescuePlans" />
      <Route key="error">
        <ErrorPage />
      </Route>
    </Switch>
  );
};
PartnerOnlyRoutes.propTypes = {
  path: PropTypes.string,
  partnerNumber: PropTypes.string,
};

const Partner = props => {
  const {
    match: {
      path,
      params: { partnerNumber },
    },
  } = props;
  return (
    <div>
      <Switch>
        <Route exact path="/ServiceRequest" component={ServiceRequest} />
        <Route exact path={`${path}/ServiceRequest`} component={ServiceRequest} />
        <Route exact path={`${path}/Overview`} component={PartnerOverview} />
        <Route path={`${path}/Maintenance`} component={Maintenance} key="calendar" />
        <Route path="/Maintenance" component={Maintenance} key="calendar2" />
        <PartnerOnlyRoutes path={path} partnerNumber={partnerNumber} />
      </Switch>
    </div>
  );
};

Partner.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Partner;
