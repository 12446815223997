import React, { isValidElement, useContext, useCallback } from 'react';
import LocalizationContext from 'contexts/LocalizationContext';
import reactStringReplace from 'react-string-replace';

/**
 * @param {Array} translations
 * @param {string} key
 * @param  {...any} templateVariables may be strings or React elements
 * @returns translated string if templateVariables were strings, array to be rendered by React if any of them was React element
 */
export const translate = (translations, key, ...templateVariables) => {
  if (!translations) {
    return key;
  }
  let formatted = translations[key] || key;
  const containsReactElementParams = templateVariables.some(isValidElement);
  for (let i = 0; i < templateVariables.length; i++) {
    const regexp = new RegExp('(\\{' + i + '\\})', 'gi');
    if (containsReactElementParams) {
      formatted = reactStringReplace(formatted, regexp, () => templateVariables[i]);
    } else {
      formatted = formatted.replace(regexp, templateVariables[i]);
    }
  }
  return formatted;
};

export const getTranslateFn = translations => {
  if (!translations) {
    return undefined;
  }
  return (key, ...params) => translate(translations, key, ...params);
};

export const useTranslations = () => {
  const localization = useContext(LocalizationContext);
  // Ensure translate is different function when (and only when) localization data changes
  // to force rerendering (eg. user switching language or showTranslationKeys)
  const t = useCallback(
    (key, ...params) => {
      if (localization.showTranslationKeys) {
        return `${key} (${localization.translationRowNumbers?.[key] || 'N/A'})`;
      }
      return translate(localization?.translations, key, ...params);
    },
    [localization]
  );
  return [t, localization.updateTranslations];
};

const translations = WrappedComponent => {
  function TranslationsComponent(props) {
    const { forwardedRef, ...otherProps } = props;
    const [t, updateTranslations] = useTranslations();

    return (
      <WrappedComponent {...otherProps} ref={forwardedRef} t={t} updateTranslations={updateTranslations} />
    );
  }

  return React.forwardRef((props, ref) => {
    return <TranslationsComponent {...props} forwardedRef={ref} />;
  });
};

export default translations;
