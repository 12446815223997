import { MutableRefObject, RefObject, useLayoutEffect, useState } from 'react';

/**
 * E.g. Highcharts requires absolute color values in order to function well.
 * Use this hook to resolve CSS variables at some point at the DOM.
 * @param elementRef Ref to a div element that the CSS variables will be resolved against
 * @param variables Map key => variable name to be resolved
 * @param trim By default, trim the value (CSS variables are verbatim).
 *   If the leading or trailing whitespace is important, pass false
 * @returns Map key => value with same shape as `variables` argument
 * @example
 *   const containerRef = useRef<HTMLDivElement>(null);
 *   const colors = useResolvedCssVariables(containerRef, {
 *     gaugeBackground: '--opi-card-gauge-bg',
 *     gaugeForeground: '--opi-card-gauge-fg',
 *   });
 */
export function useResolvedCssVariables<ElementType extends HTMLElement, VariableMap extends Record<string, string>>(
  elementRef: MutableRefObject<ElementType | null | undefined> | RefObject<ElementType | null>,
  variables: VariableMap,
  trim = true
): VariableMap | null {
  const [resolvedVariables, setResolvedVariables] = useState<Partial<VariableMap> | null>(null);

  useLayoutEffect(() => {
    if (elementRef.current) {
      const computedStyle = window.getComputedStyle(elementRef.current);

      let valueChanged = false;
      const nextResolvedVariables: Record<string, string> = {};
      for (const [key, variable] of Object.entries(variables)) {
        const value = computedStyle.getPropertyValue(variable);
        nextResolvedVariables[key] = trim ? value.trim() : value;

        if (nextResolvedVariables[key] !== resolvedVariables?.[key]) {
          valueChanged = true;
        }
      }

      if (valueChanged) {
        setResolvedVariables(nextResolvedVariables as VariableMap);
      }
    }
  }, [elementRef.current]);

  return resolvedVariables as VariableMap | null;
}
