export default class ApiError extends Error {
  static createErrorMessage(responseBody, statusCode, statusText) {
    if (typeof responseBody === 'string') {
      return responseBody;
    }
    if (responseBody?.message) {
      return responseBody.message;
    }
    if (statusText) {
      return statusText;
    }
    if (statusCode) {
      return `Request failed with status code ${statusCode}`;
    }
    return 'Request failed';
  }

  constructor(responseBody, statusCode, statusText, ...extraParams) {
    const errorMessage = ApiError.createErrorMessage(responseBody, statusCode, statusText);
    super(errorMessage, ...extraParams);

    this.name = 'ApiError';
    this.statusCode = statusCode;
    if (responseBody) {
      this.responseBody = responseBody;
    }

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }

  getTranslatedMessage(t) {
    return this.message && t(this.message);
  }
}
