import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import fromPairs from 'lodash/fromPairs';
import startsWith from 'lodash/startsWith';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import capitalize from 'lodash/capitalize';
import { parseISO, add, isBefore, isAfter, set, isWeekend, nextMonday, isFriday, isPast } from 'date-fns';

import {
  FILTERABLE_FIELDS,
  OrderStatus,
  SyntheticOrderStatus,
  ExternalType,
  DEFAULT_PRIORITY,
  SLA_META_KEY_REACTION_TIME,
  SLA_META_KEY_DOWNTIME,
  SLA_META_KEY_LEAD_TIME,
  BreakdownFields,
  OrderType,
  SmartViewPriority,
  OverdueHourLimit,
} from 'constants/maintenance';
import { selectAuthorizedPartnerNumber } from 'utils/profile';
import { OVERDUE_HOUR_LIMIT_KEY_PREFIX, HIDE_OVERDUE_STATUS_META_KEY } from 'constants/meta';

// Get an array of unique reference functional locations for the given array of service orders
export const getUniqueFunctionalLocations = serviceOrders => {
  if (!serviceOrders) {
    return [];
  }

  const functionalLocations = new Set();
  serviceOrders.forEach(
    order => order.path?.forEach(fl => functionalLocations.add(fl)) || functionalLocations.add(order.functionalLocation)
  );
  return Array.from(functionalLocations);
};

// Get an array of unique reference equipment for the given array of service orders
export const getUniqueEquipment = serviceOrders => {
  if (!serviceOrders) {
    return [];
  }

  const equipment = new Set();
  serviceOrders.forEach(order => order.equipmentNumber.forEach(fl => equipment.add(fl)));
  return Array.from(equipment);
};

export const filterServiceOrders = (
  serviceOrders,
  activeFilter,
  excluded = null,
  defaultFilter = null,
  fields = FILTERABLE_FIELDS,
  config = {}
) => {
  const orders = serviceOrders.filter(plan => {
    if (config.hideSap && plan.externalType === 'sap') {
      return false;
    }
    if (config.hidePlanon && plan.externalType === 'planon') {
      return false;
    }
    if (config.hideSmartView && plan.externalType === 'smartview') {
      return false;
    }
    return true;
  });
  const combinedFilter = defaultFilter ? { ...defaultFilter, ...activeFilter } : activeFilter ?? {};

  // Quick return if no filter is set
  if (Object.keys(combinedFilter).length === 0) {
    return orders;
  }
  return orders.filter(serviceOrder =>
    fields.every(field => {
      const filterableValue = serviceOrder.meta.filterable[field];
      const filter = combinedFilter[field];
      if (!filter || excluded === field) {
        return true;
      }
      if (isArray(filterableValue) && isArray(filter)) {
        return filterableValue.some(value => filter.includes(value));
      } else if (isArray(filterableValue)) {
        return filterableValue.includes(filter);
      } else if (isArray(filter)) {
        return includes(filter, filterableValue);
      }
      return filter === filterableValue;
    })
  );
};

const unfinishedStatuses = {
  [OrderStatus.OPEN]: true,
  [OrderStatus.IN_PROGRESS]: true,
  [OrderStatus.PARTLY_COMPLETED]: true,
  [OrderStatus.POSTPONED]: true,
  [OrderStatus.PLANNED]: true,
  [OrderStatus.DELAYED]: true,
  [OrderStatus.RELEASED]: true,
  [OrderStatus.STARTED]: true,
  [OrderStatus.ARRIVED]: true,
};

export const getPlannedMaintenanceStatus = order => {
  if (order.externalType && order.externalType !== ExternalType.SAP && order.externalType !== ExternalType.SMARTVIEW) {
    if (order.detailedStatus in unfinishedStatuses) {
      return SyntheticOrderStatus.UNFINISHED;
    }

    return order.detailedStatus;
  }

  // Custom handling for SAP/smartview planned maintenance
  let maintenanceWindowStart;
  let maintenanceWindowEnd;
  if (order.plannedDate) {
    maintenanceWindowStart = parseISO(order.plannedDate);
    maintenanceWindowEnd = add(new Date(maintenanceWindowStart.valueOf()), { days: 90 });
  }

  if (order.status in unfinishedStatuses) {
    if (maintenanceWindowEnd && isBefore(maintenanceWindowEnd, new Date())) {
      return OrderStatus.OVERDUE;
    }
    return SyntheticOrderStatus.UNFINISHED;
  }

  if (order.status === 'Completed') {
    if (!maintenanceWindowStart) {
      return SyntheticOrderStatus.COMPLETED_OTHER;
    }
    const completedDate = parseISO(order.completedDate);
    if (isBefore(completedDate, maintenanceWindowStart)) {
      return OrderStatus.COMPLETED_EARLY;
    } else if (isAfter(completedDate, maintenanceWindowEnd)) {
      return OrderStatus.COMPLETED_LATE;
    }
    return OrderStatus.COMPLETED_ON_TIME;
  }

  return order.detailedStatus;
};

const formatResponseTime = responseTime => {
  // make sortable table sort nil values alike and hide negative values
  if (isNil(responseTime) || responseTime < 0) {
    return null;
  }
  // convert seconds to hours
  return responseTime / 60 / 60;
};

export const getDiscipline = functionalLocation => {
  if (!functionalLocation || !functionalLocation.functionalLocation) {
    return '';
  }

  // FL is usually structured of four levels separated by '-'
  // There are some exceptions and variations:
  //    - the amount of levels can vary
  //    - the abbreviation (used here) for technical building processes can be found from the fourth or the fifth level of the FL

  // FL documentation
  // https://caverion.atlassian.net/wiki/spaces/SHP/pages/1119354892/SAP+Functional+Location+Structure+and+Maintenance+Activity+Types

  const splitFL = functionalLocation.functionalLocation.split('-');
  const fourthIsNaN = isNaN(splitFL[3]);
  const fifthIsNaN = isNaN(splitFL[4]);

  if (splitFL.length >= 4 && fourthIsNaN) {
    return `DISCIPLINE_${splitFL[3].toUpperCase()}`;
  }
  if (splitFL.length >= 5 && fifthIsNaN) {
    return `DISCIPLINE_${splitFL[4].toUpperCase()}`;
  }
  return '';
};

export const translatePriority = (priority, t) => t(capitalize(priority ?? ''));

// Demo building has wrong name, override it with description
// before we get it fixed in SAP
const getDemoBuildingOverrides = functionalLocation => {
  if (functionalLocation?.partnerNumberWithParents?.includes('10465384')) {
    return { location: functionalLocation.description };
  }
  return {};
};

export const getFunctionalLocationAddressInfo = functionalLocation => {
  if (!functionalLocation) {
    return {};
  }
  return {
    address: [functionalLocation.country, functionalLocation.city, functionalLocation.address]
      .filter(part => part)
      .join(', '),
    humanReadableAddress: `${functionalLocation.address} ${functionalLocation.city}`,
    location: `${functionalLocation.name} ${functionalLocation.name2 || ''}`.trim(),
    city: functionalLocation.city ? capitalize(functionalLocation.city) : undefined,
  };
};

export const getLocationFieldsForCalendar = (functionalLocation, functionalLocationKey) => {
  if (functionalLocation) {
    return {
      noPermissions: false,
      ...getFunctionalLocationAddressInfo(functionalLocation),
      ...getDemoBuildingOverrides(functionalLocation),
    };
  }
  return { noPermissions: true, location: functionalLocationKey };
};

export const getOverdueHourLimit = (priority, overdueHourLimitConfiguration = {}) => {
  const hourLimits = {
    ...OverdueHourLimit,
    ...overdueHourLimitConfiguration,
  };

  // limit found for priority
  if (hourLimits[priority]) {
    return hourLimits[priority];
  }

  // backup for numeric priority
  if (!isNaN(priority)) {
    const limitValues = Object.values(OverdueHourLimit);
    return limitValues[priority - 1] || OverdueHourLimit[SmartViewPriority.LOW];
  }

  // default limit
  return OverdueHourLimit[SmartViewPriority.LOW];
};

export const fixDateToBusinessDayAndHours = date => {
  let fixedDate = date; // fix date to be between 7:00-15:30 on business day

  // fix weekend date to next monday 7:00
  if (isWeekend(fixedDate)) {
    fixedDate = set(nextMonday(fixedDate), { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 });
  }
  // fix early hours to 7:00
  if (fixedDate.getHours() < 7) {
    fixedDate = set(fixedDate, { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 });
  }
  // fix late hours to next (business) day 7:00
  if (fixedDate.getHours() > 15 || (fixedDate.getHours() === 15 && fixedDate.getMinutes() > 30)) {
    const nextBusinessDay = isFriday(fixedDate) ? nextMonday(fixedDate) : add(fixedDate, { days: 1 });
    fixedDate = set(nextBusinessDay, {
      hours: 7,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }

  return fixedDate;
};

const UNFINISHED_SERVICE_ORDER_STATUSES = [OrderStatus.OPEN, OrderStatus.IN_PROGRESS, OrderStatus.PARTLY_COMPLETED];

export const getOverdueStatusForServiceOrder = (order, overdueHourLimits) => {
  if (order.orderType === OrderType.ORDER && UNFINISHED_SERVICE_ORDER_STATUSES.includes(order.status)) {
    const hourLimit = getOverdueHourLimit(order.priority || SmartViewPriority.LOW, overdueHourLimits);
    const date = parseISO(order.createdDate);
    let isServiceOrderOverdue;

    if (hourLimit < 8) {
      // add hours to the date, and check if that is in the past
      isServiceOrderOverdue = isPast(add(date, { hours: hourLimit }));
    } else {
      /**
       * If hour limit is over 8 hours, we need to consider working hours and business days
       */

      // if service order is created outside working hours, startDate is fixed to when the next working day starts
      const startDate = fixDateToBusinessDayAndHours(date);

      // add hours to startDate
      let overdueDate = startDate;
      let i = 1;
      while (i <= hourLimit) {
        overdueDate = fixDateToBusinessDayAndHours(add(overdueDate, { hours: 1 }));
        i++;
      }

      // service order is overdue, if the overdueDate is in the past
      isServiceOrderOverdue = isPast(overdueDate);
    }

    if (isServiceOrderOverdue) {
      return OrderStatus.OVERDUE;
    }
  }
  return order.status;
};

export const formatOrderForCalendar = ({
  t,
  order,
  functionalLocations = {},
  profile = {},
  addSyntheticStatus = true,
  loadingFL = false,
  SLAs = { reactionTime: {}, downtime: {}, leadTime: {} },
  overdueHourLimits = {},
}) => {
  const functionalLocation = functionalLocations[order.functionalLocation];
  const { address, humanReadableAddress, location, noPermissions, city } = getLocationFieldsForCalendar(
    functionalLocation,
    order.functionalLocation
  );
  const discipline = order.discipline || getDiscipline(functionalLocation);
  const priority =
    order.priority || order.orderType !== OrderType.PLANNED ? String(order.priority || DEFAULT_PRIORITY) : undefined;

  let syntheticStatus;
  if (addSyntheticStatus) {
    if (order.orderType === OrderType.ORDER) {
      syntheticStatus = getOverdueStatusForServiceOrder(order, overdueHourLimits);
    }
    if (order.orderType === OrderType.PLANNED) {
      syntheticStatus = getPlannedMaintenanceStatus(order);
    }
  }

  // translate data so that sorting works for the user's language
  const translatedPriority = translatePriority(priority, t);
  const translatedDiscipline = t(discipline);
  const translatedType = order.maintenanceActivityType ? t(order.maintenanceActivityType) : '';
  const translatedStatus = t(order.status);

  const date = new Date(order.plannedDate || order.createdDate);

  const reactionTime = formatResponseTime(order[BreakdownFields.REACTION_TIME]);
  const downtime = formatResponseTime(order[BreakdownFields.DOWNTIME]);
  const leadTime = formatResponseTime(order[BreakdownFields.LEAD_TIME]);

  return {
    ...order,
    reactionTime,
    downtime,
    leadTime,
    location,
    address: humanReadableAddress,
    discipline: translatedDiscipline,
    type: translatedType,
    priority: translatedPriority,
    translatedStatus,
    meta: {
      noPermissions,
      loadingFL,
      syntheticStatus,
      authorizedPartnerNumber: selectAuthorizedPartnerNumber(order.partnerNumberWithParents, profile, order.path),
      filterable: {
        type: order.maintenanceActivityType,
        source: order.externalType,
        location,
        address,
        city,
        status: order.status,
        priority,
        date,
        discipline,
        profile: order.requestedByProfileId,
        supplier: order.supplierName,
        removed: order.status === 'Cancelled' ? 'true' : 'false',
        overdue: syntheticStatus === OrderStatus.OVERDUE ? 'true' : 'false',
      },
      overSLA: {
        reactionTime: SLAs && reactionTime > SLAs.reactionTime[priority],
        downtime: SLAs && downtime > SLAs.downtime[priority],
        leadTime: SLAs && leadTime > SLAs.leadTime[priority],
      },
    },
  };
};

export const filterByMonths = (orders, months) => {
  const monthMap = months.reduce((acc, month) => ({ ...acc, [month]: true }), {});
  return orders.filter(order => order.meta.filterable.date && order.meta.filterable.date.getMonth() + 1 in monthMap);
};

export const filterByDay = (orders, month, day) => {
  return orders.filter(
    order =>
      order.meta.filterable.date &&
      order.meta.filterable.date.getMonth() + 1 === month &&
      order.meta.filterable.date.getDate() === day
  );
};

/**
 * Get SLAs from meta.
 * returns something like { reactionTime: { 1: 100, 2: 200 }, downtime: { 1: 110, 2: 220 }, leadTime: { 1: 110, 2: 220 } }
 */
export const getResponseSLAs = memoizeOne(meta => {
  if (meta) {
    const metaKeys = Object.keys(meta);
    return {
      reactionTime: fromPairs(
        metaKeys
          .filter(metaKey => startsWith(metaKey, SLA_META_KEY_REACTION_TIME))
          .map(metaKey => [metaKey.slice(SLA_META_KEY_REACTION_TIME.length), +meta[metaKey]])
      ),
      downtime: fromPairs(
        metaKeys
          .filter(metaKey => startsWith(metaKey, SLA_META_KEY_DOWNTIME))
          .map(metaKey => [metaKey.slice(SLA_META_KEY_DOWNTIME.length), +meta[metaKey]])
      ),
      leadTime: fromPairs(
        metaKeys
          .filter(metaKey => startsWith(metaKey, SLA_META_KEY_LEAD_TIME))
          .map(metaKey => [metaKey.slice(SLA_META_KEY_LEAD_TIME.length), +meta[metaKey]])
      ),
    };
  }
  return null;
});

export const getOverdueHourLimitsFromMeta = meta =>
  Object.fromEntries(
    Object.entries(meta)
      .filter(([key, _value]) => startsWith(key, OVERDUE_HOUR_LIMIT_KEY_PREFIX))
      .map(([key]) => [key.slice(OVERDUE_HOUR_LIMIT_KEY_PREFIX.length), +meta[key]])
      .filter(([_key, value]) => !isNaN(value))
  );

export const getHideOverdueStatusFromMeta = meta => {
  const value = meta[HIDE_OVERDUE_STATUS_META_KEY] || '';

  // default to true, because we need to hide this feature for now
  if (!value) {
    return true;
  }

  if (value.trim().toLowerCase() === 'true') {
    return true;
  }
  return false;
};

export const getCommentCount = serviceOrderLogs => serviceOrderLogs?.filter(log => log.comment).length || 0;
