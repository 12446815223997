import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { useTranslations } from 'decorators/Translations/translations';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import { createAdjacentTimeframe } from '../WasteBreakdownUtils';

const StyledWasteAmountsTimeframeSelector = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.font.size.xxs};
  padding: 0.5em;

  .link {
    font-size: ${props => props.theme.font.size.xxxs};
    font-weight: ${props => props.theme.font.weight.bold};
    cursor: pointer;
    text-transform: uppercase;
  }
  .label {
    text-transform: capitalize;
  }
`;

const WasteTimeframeSelector = ({
  selectedTimeframeCategory,
  selectedTimeframe,
  onTimeframeChange,
  selectedTimeframeDescription,
}) => {
  const [t] = useTranslations();
  const breakpoints = useBreakpoints();

  const onPreviousClick = () => {
    const newTimeframe = createAdjacentTimeframe(selectedTimeframe, selectedTimeframeCategory, -1);
    onTimeframeChange(newTimeframe);
  };

  const onNextClick = () => {
    const newTimeframe = createAdjacentTimeframe(selectedTimeframe, selectedTimeframeCategory, 1);
    onTimeframeChange(newTimeframe);
  };

  const isMobile = !breakpoints.landscape;
  const translatedSelectedCategory = t(capitalize(selectedTimeframeCategory));

  return (
    <StyledWasteAmountsTimeframeSelector>
      <div className="link" onClick={onPreviousClick}>
        &larr; {isMobile ? t('Previous') : `${t(`Previous`)} ${translatedSelectedCategory}`}
      </div>
      <div className="label">{t(selectedTimeframeDescription)}</div>
      <div className="link" onClick={onNextClick}>
        {isMobile ? t('Next') : `${t(`Next`)} ${t(translatedSelectedCategory)}`} &rarr;
      </div>
    </StyledWasteAmountsTimeframeSelector>
  );
};

WasteTimeframeSelector.propTypes = {
  selectedTimeframeCategory: PropTypes.string.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  onTimeframeChange: PropTypes.func.isRequired,
  selectedTimeframeDescription: PropTypes.string.isRequired,
};

export default WasteTimeframeSelector;
