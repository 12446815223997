import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from './index';

export const customViewsFn = (request, noCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/custom-views/accessible-custom-views`,
    noCache,
  });

export const myCustomViewsFn = (request, noCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/custom-views/my-custom-views`,
    noCache,
  });

export const userCustomViewsFn = (request, userId, noCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/custom-views/find-by-user-id`,
    query: { userId },
    noCache,
  });

export const upsertCustomViewFn = (request, data) =>
  request.put({
    apiPath: `${SERVICE_API_PATH}/custom-views/upsert`,
    data,
    noCache: true,
  });

export const deleteCustomViewFn = (request, id) =>
  request.delete({
    apiPath: `${SERVICE_API_PATH}/custom-views/${id}`,
    noCache: true,
  });

export const myFLCustomViewsFn = (request, functionalLocationId, noCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/custom-views/my-fl-custom-views`,
    query: { functionalLocationId },
    noCache,
  });

export const myCustomerCustomViewsFn = (request, customerNumber, noCache) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/custom-views/my-partner-custom-views`,
    query: { partnerNumber: customerNumber },
    noCache,
  });

export default wrapEachWithThunkRequest({
  customViews: customViewsFn,
  myCustomViews: myCustomViewsFn,
  userCustomViews: userCustomViewsFn,
  upsertCustomView: upsertCustomViewFn,
  deleteCustomView: deleteCustomViewFn,
  myFLCustomViews: myFLCustomViewsFn,
  myCustomerCustomViews: myCustomerCustomViewsFn,
});
