import React, { useEffect, useState, Fragment, Suspense } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import endsWith from 'lodash/endsWith';
import times from 'lodash/times';
import throttle from 'lodash/throttle';

import { customViewShape } from 'components/CustomView/utils';
import { getCustomViewFileUrl } from 'redux/modules/document/file';
import Loader from 'components/Loader/Loader';
import useScrollPosition from 'decorators/Scroll/useScrollPosition';

const Document = React.lazy(() =>
  import(/* webpackChunkName: "react-pdf" */ 'react-pdf/dist/esm/entry.webpack').then(module => ({
    default: module.Document,
  }))
);
const Page = React.lazy(() =>
  import(/* webpackChunkName: "react-pdf" */ 'react-pdf/dist/esm/entry.webpack').then(module => ({
    default: module.Page,
  }))
);

const Container = styled.div`
  cursor: ${props => props.progress && 'progress'};
  ${props => props.theme.media.portrait`
        margin-top: var(--size-md);
    `}

  ${props => props.theme.media.landscape`
        margin-top: 0;
    `}
`;

const StyledImage = styled.img`
  max-width: 100%;
`;

const PageContainer = styled.div`
  margin-bottom: var(--size-md);
`;

const ZoomContainer = styled.div`
  display: none;
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  top: ${props => (props.fixed ? '64px' : 'auto')};
  right: 40px;
  z-index: 2;
  margin: var(--size-xs);
  background-color: ${props => props.theme.colors.lightGray};
  border-radius: 2px;

  ${props => props.theme.media.landscape`
        display: block;
    `}
`;

const ZoomElement = styled.span`
  font-size: ${props => props.theme.font.size.xxl};
  padding: var(--size-sm);
  cursor: ${props => (props.progress ? 'progress' : 'pointer')};
  color: var(--embedded-document-view-control-fg);

  &:hover {
    color: var(--embedded-document-view-control-fg-hover);
  }

  &:active {
    cursor: progress;
  }
`;

const LoaderContainer = styled.div`
  text-align: center;
`;

const getLoader = () => (
  <LoaderContainer>
    <Loader color="GRAY" size="LARGE" />
  </LoaderContainer>
);

const ZOOM_STEP = 0.1;
const ZOOM_DIRECTION = {
  UP: 'up',
  DOWN: 'down',
};

const EmbeddedDocumentView = props => {
  const { customView, noDataRender, getFileUrl, t, url, hasHero } = props;

  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [containerWidth, setContainerWidth] = useState(undefined);
  const [scale, setScale] = useState(1);
  const [zooming, setZooming] = useState(false);
  const [scrollPosition] = useScrollPosition();

  const isPDF = customView && endsWith(customView.content, 'pdf');
  const isFixed = !hasHero || scrollPosition > 150 + 64;
  let containerRef = null;

  const setRef = el => {
    if (el) {
      containerRef = el;
    }
  };

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setNumPages(numPages);
  };

  useEffect(() => {
    const loadData = async () => {
      await getFileUrl(customView.id);
      setLoading(false);
    };
    const calculateContainerWidth = () => {
      if (containerRef) {
        const element = ReactDOM.findDOMNode(containerRef);
        if (element) {
          setContainerWidth(scale * element.getBoundingClientRect().width);
        }
      }
    };
    const handleResize = throttle(() => {
      isPDF && calculateContainerWidth();
    }, 500);

    loadData();
    isPDF && calculateContainerWidth();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scaleDocument = direction => {
    setZooming(true);
    const newScale = direction === ZOOM_DIRECTION.UP ? scale + ZOOM_STEP : scale - ZOOM_STEP;
    setScale(newScale);
  };

  if (!customView || isEmpty(customView.content)) {
    return noDataRender;
  }

  if (!loading && !url) {
    return noDataRender;
  }

  return (
    <Container ref={setRef} hasHero={hasHero} progress={zooming}>
      {loading && getLoader()}
      {!isPDF && !loading && <StyledImage src={url} />}
      {isPDF && !loading && (
        <Fragment>
          <ZoomContainer fixed={isFixed}>
            <ZoomElement progress={zooming} onClick={() => scaleDocument(ZOOM_DIRECTION.DOWN)}>
              -
            </ZoomElement>
            <ZoomElement progress={zooming} onClick={() => scaleDocument(ZOOM_DIRECTION.UP)}>
              +
            </ZoomElement>
          </ZoomContainer>
          <Suspense fallback={getLoader()}>
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={getLoader()}
              error={t('Failed to load PDF file.')}
              width="100%"
            >
              {times(numPages).map((el, index) => (
                <PageContainer key={index}>
                  <Page
                    pageIndex={index}
                    loading=""
                    width={scale * containerWidth}
                    onRenderSuccess={() => index === numPages - 1 && setZooming(false)}
                  />
                </PageContainer>
              ))}
            </Document>
          </Suspense>
        </Fragment>
      )}
    </Container>
  );
};

EmbeddedDocumentView.defaultProps = {
  customView: {},
  hasHero: false,
};

EmbeddedDocumentView.propTypes = {
  customView: PropTypes.oneOfType([customViewShape()]),
  noDataRender: PropTypes.node,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  url: ownProps.customView && state.file.customViewUrls[ownProps.customView.id],
});

const mapDispatchToProps = dispatch => ({
  getFileUrl: customViewId => dispatch(getCustomViewFileUrl(customViewId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmbeddedDocumentView);
