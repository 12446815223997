import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

const getProfileState = state => state.profile;

export const getProfile = state => getProfileState(state).profile;

export const getTos = state => getProfileState(state).tos;

export const isTosAcknowledged = createSelector(
  getProfile,
  getTos,
  (profile, tos) => !!(tos.hash && profile?.tosAcknowledgementHashes?.includes(tos.hash))
);

export const isNewUser = state => isEmpty(getProfile(state)?.tosAcknowledgementHashes);
