import { createReducerFromMapping } from 'redux/utils/index';
import { updateFunctionParameters } from './utils';
import MasterDataV2 from 'services/masterData';

const initialState = {
  flAnalyticsFunctions: [],
  flAnalyticsFunctionsLoading: false,
  flAnalyticsFunctionsError: null,
  togglingAnalyticsFunctions: false,
  toggleAnalyticsFunctionsError: null,
  updatingAnalyticsFunction: false,
  updateAnalyticsFunctionError: null,
  disablingDeviceFunctions: false,
  disableDeviceAnalyticsFunctionsError: null,
};

export const LOAD_FUNCTIONS_BY_FL = 'CUSTOMER_PLATFORM/AnalyticsFunction/LOAD_FUNCTIONS_BY_FL';
export const LOAD_FUNCTIONS_BY_FL_SUCCESS = 'CUSTOMER_PLATFORM/AnalyticsFunction/LOAD_FUNCTIONS_BY_FL_SUCCESS';
export const LOAD_FUNCTIONS_BY_FL_FAIL = 'CUSTOMER_PLATFORM/AnalyticsFunction/LOAD_FUNCTIONS_BY_FL_FAIL';

export const loadAnalyticsFunctionsByFl = functionalLocation => async dispatch => {
  dispatch({ type: LOAD_FUNCTIONS_BY_FL });
  try {
    const flAnalyticsFunctions = await dispatch(MasterDataV2.getAnalyticsFunctionsByFl(functionalLocation));
    return dispatch({
      type: LOAD_FUNCTIONS_BY_FL_SUCCESS,
      flAnalyticsFunctions,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FUNCTIONS_BY_FL_FAIL,
      flAnalyticsFunctionsError: error,
    });
  }
};

export const TOGGLE_FUNCTIONS = 'CUSTOMER_PLATFORM/AnalyticsFunction/TOGGLE_FUNCTIONS';
export const TOGGLE_FUNCTIONS_SUCCESS = 'CUSTOMER_PLATFORM/AnalyticsFunction/TOGGLE_FUNCTIONS_SUCCESS';
export const TOGGLE_FUNCTIONS_FAIL = 'CUSTOMER_PLATFORM/AnalyticsFunction/TOGGLE_FUNCTIONS_FAIL';

export const toggleAnalyticsFunctions = (toggles, functionalLocation, comment) => async (dispatch, getState) => {
  dispatch({ type: TOGGLE_FUNCTIONS });
  try {
    await dispatch(
      MasterDataV2.toggleAnalyticsFunctions({
        toggles,
        functionalLocation,
        comment,
      })
    );

    // Update local state if API call is successful
    const flAnalyticsFunctions = [...getState().analyticsFunctions.flAnalyticsFunctions];
    toggles.forEach(toggle => {
      const analyticsFunction = flAnalyticsFunctions.find(
        analyticsFunction => analyticsFunction.id === toggle.analyticsFunctionId
      );
      if (analyticsFunction) analyticsFunction.active = toggle.active;
    });

    return dispatch({
      type: TOGGLE_FUNCTIONS_SUCCESS,
      flAnalyticsFunctions,
    });
  } catch (error) {
    return dispatch({
      type: TOGGLE_FUNCTIONS_FAIL,
      toggleAnalyticsFunctionsError: error,
    });
  }
};

export const DISABLE_DEVICE_FUNCTIONS = 'CUSTOMER_PLATFORM/AnalyticsFunction/DISABLE_DEVICE_FUNCTIONS';
export const DISABLE_DEVICE_FUNCTIONS_SUCCESS = 'CUSTOMER_PLATFORM/AnalyticsFunction/DISABLE_DEVICE_FUNCTIONS_SUCCESS';
export const DISABLE_DEVICE_FUNCTIONS_FAIL = 'CUSTOMER_PLATFORM/AnalyticsFunction/DISABLE_DEVICE_FUNCTIONS_FAIL';

export const disableDeviceAnalyticsFunctions =
  (analyticsFunctionId, functionalLocation, disabledDevices, comment) => async (dispatch, getState) => {
    dispatch({ type: DISABLE_DEVICE_FUNCTIONS });
    try {
      const updatedDisabledDevices = await dispatch(
        MasterDataV2.updateDisabledDevices({
          analyticsFunctionId,
          functionalLocation,
          disabledDevices,
          comment,
        })
      );
      // Update local state if API call is successful
      const flAnalyticsFunctions = [...getState().analyticsFunctions.flAnalyticsFunctions];
      // Find the correct function
      const analyticsFunction = {
        ...flAnalyticsFunctions.find(analyticsFunction => analyticsFunction.id === analyticsFunctionId),
      };
      // Set its disabled devices
      analyticsFunction.disabledDevices = disabledDevices;
      flAnalyticsFunctions[
        flAnalyticsFunctions.findIndex(analyticsFunction => analyticsFunction.id === analyticsFunctionId)
      ] = analyticsFunction;

      return dispatch({
        type: DISABLE_DEVICE_FUNCTIONS_SUCCESS,
        disableDeviceAnalyticsFunction: updatedDisabledDevices,
        flAnalyticsFunctions,
      });
    } catch (error) {
      return dispatch({
        type: DISABLE_DEVICE_FUNCTIONS_FAIL,
        disableDeviceAnalyticsFunctionsError: String(error),
      });
    }
  };

export const UPDATE_FUNCTION = 'CUSTOMER_PLATFORM/AnalyticsFunction/UPDATE_FUNCTION';
export const UPDATE_FUNCTION_SUCCESS = 'CUSTOMER_PLATFORM/AnalyticsFunction/UPDATE_FUNCTION_SUCCESS';
export const UPDATE_FUNCTION_FAIL = 'CUSTOMER_PLATFORM/AnalyticsFunction/UPDATE_FUNCTION_FAIL';

export const updateAnalyticsFunction = (functionalLocation, analyticsFunction, comment) => async dispatch => {
  dispatch({ type: UPDATE_FUNCTION });
  try {
    const analyticsFunctionUpdate = {
      ...analyticsFunction,
      functionalLocation,
      comment,
    };
    const updatedFunction = await dispatch(MasterDataV2.updateAnalyticsFunction(analyticsFunctionUpdate));
    return dispatch({
      type: UPDATE_FUNCTION_SUCCESS,
      updatedFunction,
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_FUNCTION_FAIL,
      updateAnalyticsFunctionError: error,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_FUNCTIONS_BY_FL]: (state, action) => ({
      ...state,
      flAnalyticsFunctions: [],
      flAnalyticsFunctionsLoading: true,
      flAnalyticsFunctionsError: null,
    }),
    [LOAD_FUNCTIONS_BY_FL_SUCCESS]: (state, action) => ({
      ...state,
      ...action,
      flAnalyticsFunctionsLoading: false,
    }),
    [LOAD_FUNCTIONS_BY_FL_FAIL]: (state, action) => ({
      ...state,
      ...action,
      flAnalyticsFunctionsLoading: false,
    }),
    [TOGGLE_FUNCTIONS]: (state, action) => ({
      ...state,
      togglingAnalyticsFunctions: true,
      toggleAnalyticsFunctionsError: null,
    }),
    [TOGGLE_FUNCTIONS_SUCCESS]: (state, action) => ({
      ...state,
      ...action,
      togglingAnalyticsFunctions: false,
    }),
    [TOGGLE_FUNCTIONS_FAIL]: (state, action) => ({
      ...state,
      ...action,
      togglingAnalyticsFunctions: false,
    }),
    [DISABLE_DEVICE_FUNCTIONS]: (state, action) => ({
      ...state,
      ...action,
      disablingDeviceFunctions: true,
    }),
    [DISABLE_DEVICE_FUNCTIONS_FAIL]: (state, action) => ({
      ...state,
      ...action,
      disablingDeviceFunctions: false,
    }),
    [DISABLE_DEVICE_FUNCTIONS_SUCCESS]: (state, action) => ({
      ...state,
      ...action,
      disablingDeviceFunctions: false,
    }),
    [UPDATE_FUNCTION]: (state, action) => ({
      ...state,
      updatingAnalyticsFunction: true,
      updateAnalyticsFunctionError: null,
    }),
    [UPDATE_FUNCTION_SUCCESS]: (state, action) => {
      // Update functional location function list with the changed function
      // and apply changed parameters to other affected functions
      const updatedFunction = action.updatedFunction;
      const updatedParameters = updatedFunction.parameters;
      const updatedFlAnalyticsFunctions = state.flAnalyticsFunctions.map(analyticsFunction =>
        analyticsFunction.id === updatedFunction.id
          ? updatedFunction
          : updateFunctionParameters(analyticsFunction, updatedParameters)
      );

      return {
        ...state,
        flAnalyticsFunctions: updatedFlAnalyticsFunctions,
        updatingAnalyticsFunction: false,
      };
    },
    [UPDATE_FUNCTION_FAIL]: (state, action) => ({
      ...state,
      ...action,
      updatingAnalyticsFunction: false,
    }),
  },
  initialState
);
