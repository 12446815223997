import styled from 'styled-components';

const DefaultSettingsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 calc(calc(-1 * var(--size-sm)) / 2);
`;

export default DefaultSettingsWrapper;
