import styled from 'styled-components';

const ListSettings = styled.div`
  ul,
  ol {
    margin: var(--size-xs) 0;
  }

  ul {
    padding: 0 0 0 25px;
    list-style: initial;
  }

  ul > li > ul {
    padding: 0 0 0 25px;
  }

  ul > li > ol {
    padding: 0 0 0 25px;
  }

  li {
    list-style-position: inside;
    line-height: ${props => props.theme.font.lineHeight.lg};
  }

  ol > li > ul {
    padding: 0 0 0 25px;
  }

  ol > li > ol {
    padding: 0 0 0 25px;
  }
`;

const TableSettings = styled(ListSettings)`
  table,
  td,
  th {
    border: 1px solid black;
  }

  td,
  th {
    padding: var(--size-xs);
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: var(--size-xs) 0;
  }
`;

const HeadindSettings = styled(TableSettings)`
  h1 {
    font-size: ${props => props.theme.fontSize.lg};
  }

  h2 {
    font-size: ${props => props.theme.fontSize.md};
  }

  h3 {
    font-size: ${props => props.theme.fontSize.md};
  }

  h1,
  h2,
  h3 {
    margin: var(--size-md) 0;
  }
`;

const DocumentStyle = styled(HeadindSettings)`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.colors.black};

  p {
    margin: var(--size-xs) 0;
    line-height: ${props => props.theme.font.lineHeight.lg};
    white-space: pre-line;
  }
`;

export default DocumentStyle;
