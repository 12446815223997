import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const TREND_TYPE = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
};

export const getTrendTypeForValue = (trendSign, hasInvertedTrend) => {
  // inverted trend: + = negative/bad, - = positive/good,
  const trendIsNegative = hasInvertedTrend ? trendSign > 0 : trendSign < 0;
  const trendIsPositive = hasInvertedTrend ? trendSign < 0 : trendSign > 0;

  if (trendIsPositive) {
    return TREND_TYPE.POSITIVE;
  }
  if (trendIsNegative) {
    return TREND_TYPE.NEGATIVE;
  }
  return TREND_TYPE.NEUTRAL;
};

const Trend = styled.span`
  display: inline-block;
  background-color: ${props => {
    switch (props.type) {
      case TREND_TYPE.POSITIVE:
        return 'var(--green-01-020)';
      case TREND_TYPE.NEGATIVE:
        return 'var(--red-01-020)';
      case TREND_TYPE.NEUTRAL:
        return 'var(--blue-01-020)';
      default:
        return null;
    }
  }};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: var(--body-text-fg);
  padding: 0 var(--size-xs);
  border-radius: 2px;

  ${props => props.noTrend && 'opacity: 0;'}
`;

export const OPICardTrend = ({ trend, unit, sign, showPlusSign, hasInvertedTrend, ...props }) => {
  const hasTrend = typeof trend === 'number' && !Number.isNaN(trend);
  const trendSign = typeof sign !== 'undefined' ? sign : Math.sign(trend);
  const plusSign = showPlusSign && trendSign > 0 ? '+' : '';
  const trendType = getTrendTypeForValue(trendSign, hasInvertedTrend);
  if (hasTrend) {
    return (
      <Trend type={trendType} noTrend={!hasTrend} {...props}>
        {`${plusSign}${trend}\u00A0${unit}`}
      </Trend>
    );
  }
  return null;
};

OPICardTrend.propTypes = {
  trend: PropTypes.number,
  unit: PropTypes.string,
  // use Math.sign(), (positive, netgative, neutral)
  // sign can also be calculated from trend if that works for the use case
  sign: PropTypes.oneOf([1, -1, 0]),
  showPlusSign: PropTypes.bool,
  hasInvertedTrend: PropTypes.bool,
};

export default OPICardTrend;
