import styled from 'styled-components';
import { tint } from 'polished';

export const DefaultTableStyleProvider = styled.div`
  table {
    font-family: ${props => props.theme.font.family.cairo};
    font-size: ${props => props.theme.font.size.xs};

    thead {
      tr {
        th {
          background-color: var(--table-header-bg);

          vertical-align: middle;
          text-align: left;

          font-size: ${props => props.theme.font.size.xxs};
          font-weight: var(--regular);
          line-height: var(--line-height-default);

          padding: 0;

          &:first-child {
            border-radius: var(--radius-sm) 0 0 var(--radius-sm);
          }

          &:last-child {
            border-radius: 0 var(--radius-sm) var(--radius-sm) 0;
          }

          &.sorted {
            font-weight: var(--bold);
          }

          &.pinned {
            z-index: 1;
          }

          .textLabel {
            padding: var(--size-xs) var(--size-md);
          }
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: var(--white);

          font-weight: var(--regular);
          line-height: var(--line-height-default);

          vertical-align: middle;
          text-align: left;

          padding: 0;

          &.pinned {
            z-index: 1;
          }

          .textLabel {
            padding: var(--size-md);
            overflow-wrap: anywhere;
          }
        }

        &.selected {
          td {
            background-color: var(--table-row-bg-hl);
          }
        }

        &.expanded[data-depth]:is([data-depth='0']),
        &[data-depth]:is([data-depth='1']) {
          td {
            background-color: var(--table-sub-row-level-1-bg);
          }
        }
        &.expanded[data-depth]:is([data-depth='1']),
        &[data-depth]:is([data-depth='2']) {
          td {
            background-color: var(--table-sub-row-level-2-bg);
            border-color: var(--table-sub-row-level-2-bc);
          }
        }
        &.expanded[data-depth]:is([data-depth='2']) {
          td {
            background-color: var(--table-sub-row-level-3-bg);
          }
        }

        &:not(:last-child) td {
          border-bottom: var(--table-row-border);
        }
      }
    }

    &.previousData {
      td .textLabel {
        color: var(--supplemental-text-fg);
      }
    }
  }
`;
