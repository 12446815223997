import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getProfile,
  getPartnerNumber,
  syntheticSensorsSelector,
  partnerFunctionalLocationsSelector,
  getBuildingHierarchy,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { getSyntheticSensorsForFunctionalLocation } from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { loadBenchmarkData } from 'redux/modules/iot/values/recycling';
import { PERCENTAGE_PRECISION, CO2E_TONNES_PRECISION } from 'constants/recycling';

const getError = state => state.values.recycling.benchmark.error;
const getIoTData = state => state.values.recycling.benchmark.data;
const getMeta = state => state.values.recycling.benchmark.meta;

const needsReloadSelector = createSelector(
  getPartnerNumber,
  syntheticSensorsSelector,
  getMeta,
  (partnerNumber, syntheticSensors, meta) => !isEmpty(syntheticSensors) && meta.partnerNumber !== partnerNumber
);

const findSensorId = (syntheticSensors, sensorTypeName) => syntheticSensors.find(sensor => sensor.sensorType.name === sensorTypeName)?.id;
const getValue = (ioTData, sensorId, aggregation, precision) => round(ioTData.find(entry => entry.sensorId === sensorId && entry.aggregation === aggregation)?.value || 0, precision);

const calculatedDataSelector = createSelector(
  partnerFunctionalLocationsSelector,
  getBuildingHierarchy,
  getIoTData,
  (partnerFunctionalLocations, buildingHierarchy, ioTData) => {
    if (!isEmpty(partnerFunctionalLocations) && !isEmpty(ioTData)) {
      return partnerFunctionalLocations.map(functionalLocation => {
        const syntheticSensors = getSyntheticSensorsForFunctionalLocation(
          buildingHierarchy,
          functionalLocation.functionalLocation
        );
        if (isEmpty(syntheticSensors)) { // building has no recycling configuration
          return null;
        }
        const functionalLocationOPISensorId = findSensorId(syntheticSensors, 'recycling/opi');
        const values = {
          recyclingRate: getValue(ioTData, functionalLocationOPISensorId, 'recyclingOpiRecyclingRate', PERCENTAGE_PRECISION),
          recoveryRate: getValue(ioTData, functionalLocationOPISensorId, 'recyclingOpiRecoveryRate', PERCENTAGE_PRECISION),
          carbonFootprint: getValue(ioTData, functionalLocationOPISensorId, 'recyclingOpiCO2e', CO2E_TONNES_PRECISION),
        }
        return {
          functionalLocationId: functionalLocation.functionalLocation,
          name: functionalLocation.name,
          address:
            (functionalLocation.address &&
              functionalLocation.city &&
              `${functionalLocation.address}, ${functionalLocation.city}`) ||
            '',
          values
        };
      }).filter(entry => entry);
    }
    return [];
  }
);


const dataSelector = createSelector(
  getMeta,
  getError,
  needsReloadSelector,
  calculatedDataSelector,
  (meta, error, needsReload, calculatedData) => ({
    isLoading: meta.loading || isEmpty(calculatedData),
    error,
    needsReload,
    result: calculatedData || [],
  })
);

export const mapStateToProps = (state, props) => ({
  profile: getProfile(state),
  partnerNumber: getPartnerNumber(state, props),
  syntheticSensors: syntheticSensorsSelector(state, props),
  data: dataSelector(state, props),
});

export const mapDispatchToProps = {
  loadBenchmarkData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
