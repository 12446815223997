import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import { Icon, ICON_SIZES } from 'components/Icon/Icon';

export const TYPES = {
  DEFAULT: 'default',
  ICON: 'icon',
};

export const StyledLabel = styled.label`
  cursor: ${props => !props.disabled && 'pointer'};
  opacity: ${props => props.disabled && 0.6};
  display: flex;
  align-items: center;
  color: var(--input-label-fg);
  font-size: ${props => props.theme.font.size.sm};
  line-height: ${props => props.theme.font.lineHeight.md};
  white-space: nowrap;
  margin-top: 0;

  svg {
    fill: ${props =>
      props.disabled
        ? 'var(--checkbox-bc-inactive)'
        : props.checked
        ? 'var(--checkbox-bc-active)'
        : 'var(--checkbox-bc-inactive)'};
    margin: 0;
  }

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.font.size.xs};
  `}
`;
StyledLabel.displayName = 'StyledLabel';

const StyledInput = styled.span`
  position: relative;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: ${({ disabled }) => (disabled ? 'var(--grey-20)' : 'white')};
  border: 1px solid
    ${props =>
      props.disabled
        ? 'var(--checkbox-bc-inactive)'
        : props.checked
        ? 'var(--checkbox-bc-active)'
        : 'var(--checkbox-bc-inactive)'};
  border-radius: 50%;
`;
StyledInput.displayName = 'StyledInput';

export const StyledLabelText = styled.div`
  padding-left: var(--size-sm);

  ${props => props.theme.media.landscape`
    padding-left: var(--size-xs);
  `}
`;

StyledLabelText.displayName = 'StyledLabelText';

const ErrorText = styled.span`
  display: none;
  color: var(--error-color);
  margin-left: var(--size-sm);
`;

const Container = styled.div`
  margin: var(--size-xxs) 0;
`;
Container.displayName = 'Container';

const HiddenInput = styled.input`
  right: 1000em;
  position: absolute;

  &:checked ~ ${StyledLabel} {
    ${StyledInput} {
      background: ${props =>
        props.checked
          ? 'var(--checkbox-bg-active)'
          : props.disabled
          ? 'var(--checkbox-bg-inactive)'
          : props.fill || 'var(--checkbox-bg-active)'};
      border: var(--border-thin) solid ${props => (props.disabled ? `var(--checkbox-bc-inactive)` : 'transparent')};

      // CSS hack rendering of the check mark
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 8px;
        border: 0;
        border-bottom: 2px solid var(--checkbox-fg-active);
        border-right: 2px solid var(--checkbox-fg-active);
        border-radius: 20%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
    svg {
      fill: ${props => (props.disabled ? 'var(--checkbox-fg-disabled)' : props.fill || 'var(--checkbox-bg-active)')};
    }
  }

  &:focus:invalid ~ ${StyledLabel} {
    ${StyledInput} {
      border: var(--border-thin) solid var(--error-color);
    }
    ${ErrorText} {
      display: inline-block;
    }
  }
`;
HiddenInput.displayName = 'HiddenInput';

const RadioButton = ({
  type = TYPES.DEFAULT,
  id,
  name,
  value,
  label,
  checked,
  onChange,
  icon,
  color,
  disabled,
  required,
  errorText,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Container {...props}>
      <HiddenInput
        id={`${id}_${name}_${value}`}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        fill={color}
        onChange={() => onChange(name, value)}
        disabled={disabled}
        required={required}
        radioButtonType={type}
      />
      <StyledLabel htmlFor={`${id}_${name}_${value}`} disabled={disabled} data-test-id={props['data-test-id']}>
        {type === TYPES.ICON ? (
          <Icon name={icon} size={ICON_SIZES.MEDIUM} fill="inherit" />
        ) : (
          <StyledInput disabled={disabled} />
        )}
        <StyledLabelText>
          {label}
          <ErrorText>{errorText}</ErrorText>
        </StyledLabelText>
      </StyledLabel>
    </Container>
  );
};

RadioButton.propTypes = {
  'data-test-id': PropTypes.string,
  checked: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf([...Object.values(TYPES)]),
  value: PropTypes.any.isRequired,
};

export default RadioButton;
