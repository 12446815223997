import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import BreakdownTabSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownTab/BreakdownTabSkeleton';
import ErrorBoundaryFallback from 'components/ErrorPage/ErrorBoundaryFallback';
import ErrorBoundary from 'components/ErrorPage/ErrorBoundary';
import BreakdownAllWasteTypes from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownAllWasteTypes/BreakdownAllWasteTypes';
import BreakdownOneWasteType from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownOneWasteType/BreakdownOneWasteType';
import translations from 'decorators/Translations/translations';
import connect from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownTab/connectBreakdownTab';
import { ErrorBoundaryDisplay } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/ErrorDisplay';
import { withRouter } from 'react-router-dom';
import { formatTimeframe } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteBreakdownUtils';
import subYears from 'date-fns/subYears';
import { createTimeframeByCategory, createTimeframe } from '../WasteBreakdownUtils';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';

const fallbacks = [
  { current: 'month', next: 'quarter' },
  { current: 'quarter', next: 'year' },
  { current: 'year', next: 'lastYear' },
];

const TIMEFRAME_CATEGORIES = ['month', 'quarter', 'year'];

export const BreakdownTab = ({
  t,
  functionalLocationId,
  wasteSensors,
  syntheticSensors,
  tab,
  visible,
  selectedTimeframeCategory,
  selectedTimeframe,
  loadAmountsBreakdownData,
  loadAmountsBreakdownTotals,
  loadAmountsBreakdownMonthlies,
  loadAmountsBreakdownMonthliesTotals,
  dataStatus,
}) => {
  const [selectedSensor, setSelectedSensor] = useState(null);

  const handleResult = result => {
    if (result) {
      if (isEmpty(result.payload)) {
        const nextTimeframeCategory = fallbacks.find(
          entry => entry.current === result.meta.selectedTimeframeCategory
        ).next;
        const timeframe = createTimeframeByCategory(nextTimeframeCategory);
        const timeframeCategory = nextTimeframeCategory === 'lastYear' ? 'year' : nextTimeframeCategory;
        return loadAmounts(timeframeCategory, timeframe);
      }
    }
    return Promise.resolve(result);
  };

  useEffect(() => {
    if (visible && dataStatus.needsReload) {
      loadAmounts(selectedTimeframeCategory, selectedTimeframe)
        .then(handleResult)
        .then(handleResult)
        .then(handleResult)
        .then(result => {
          if (result) {
            const timeframeCategory = result.meta.selectedTimeframeCategory;
            const timeframe = result.meta.selectedTimeframe;
            loadTotals(timeframeCategory, timeframe);
          }
        });
    }
  }, [dataStatus.needsReload]);

  const loadAmounts = useCallback(
    (timeframeCategory, timeframe) => {
      return loadAmountsBreakdownData(
        {
          functionalLocationId,
          sensorIds: wasteSensors.map(wasteSensor => wasteSensor.id),
        },
        timeframeCategory,
        timeframe,
      );
    },
    [loadAmountsBreakdownData, functionalLocationId, wasteSensors]
  );

  const loadTotals = useCallback(
    (timeframeCategory, timeframe) =>
      loadAmountsBreakdownTotals(
        {
          functionalLocationId,
          sensorIds: syntheticSensors.map(syntheticSensor => syntheticSensor.id),
        },
        timeframeCategory,
        timeframe
      ),
    [loadAmountsBreakdownTotals, functionalLocationId, syntheticSensors]
  );

  const onTimeframeCategoryChange = useCallback(
    newTimeframeCategory => {
      const newTimeframe = createTimeframeByCategory(newTimeframeCategory);
      loadAmounts(newTimeframeCategory, newTimeframe);
      loadTotals(newTimeframeCategory, newTimeframe);
    },
    [loadAmounts, loadTotals]
  );

  const onTimeframeChange = useCallback(
    newTimeframe => {
      // Add months does not always work as expected if we add / subtract from last day of month
      // so let's make sure we are on first and last day of the selected timeframe
      const formattedNewTimeframe = createTimeframe(newTimeframe, selectedTimeframeCategory);
      loadAmounts(selectedTimeframeCategory, formattedNewTimeframe);
      loadTotals(selectedTimeframeCategory, formattedNewTimeframe);
    },
    [loadAmounts, loadTotals, selectedTimeframeCategory]
  );

  const selectSensor = useCallback(
    sensorId => {
      if (sensorId) {
        setSelectedSensor(wasteSensors.find(sensor => sensor.id === sensorId));
        const prevYear = subYears(selectedTimeframe.startTime, 1);
        const timeframe = {
          startTime: startOfYear(prevYear),
          endTime: endOfYear(selectedTimeframe.endTime),
        };
        loadAmountsBreakdownMonthlies({ functionalLocationId, sensorIds: [sensorId] }, timeframe);
        loadAmountsBreakdownMonthliesTotals({ functionalLocationId, sensorIds: [sensorId] }, timeframe);
      }
    },
    [
      loadAmountsBreakdownMonthlies,
      loadAmountsBreakdownMonthliesTotals,
      selectedTimeframe,
      functionalLocationId,
      wasteSensors,
    ]
  );

  const resetSensorSelection = useCallback(() => {
    setSelectedSensor(null);
  }, []);

  const timeframeCategoryOptions = useMemo(
    () =>
      TIMEFRAME_CATEGORIES.map(category => ({
        value: category,
        label: t(category),
      })),
    [t]
  );

  if (!visible) {
    return null;
  }
  if (dataStatus.errors.some(error => error && error.status)) {
    return <ErrorBoundaryFallback />;
  }
  if (dataStatus.isLoading) {
    return <BreakdownTabSkeleton />;
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryDisplay}>
      {selectedSensor ? (
        <BreakdownOneWasteType
          tab={tab}
          selectedTimeframe={selectedTimeframe}
          selectedSensor={selectedSensor}
          reset={resetSensorSelection}
          selectSensor={selectSensor}
        />
      ) : (
        <BreakdownAllWasteTypes
          timeframeCategoryOptions={timeframeCategoryOptions}
          tab={tab}
          selectedTimeframeCategory={selectedTimeframeCategory}
          onTimeframeCategoryChange={onTimeframeCategoryChange}
          selectedTimeframe={selectedTimeframe}
          selectedTimeframeDescription={formatTimeframe(selectedTimeframeCategory, selectedTimeframe)}
          onTimeframeChange={onTimeframeChange}
          selectSensor={selectSensor}
        />
      )}
    </ErrorBoundary>
  );
};

BreakdownTab.propTypes = {
  t: PropTypes.func.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  syntheticSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  selectedTimeframe: PropTypes.shape({
    startTime: PropTypes.object.isRequired,
    endTime: PropTypes.object.isRequired,
  }).isRequired,
  loadAmountsBreakdownData: PropTypes.func.isRequired,
  loadAmountsBreakdownTotals: PropTypes.func.isRequired,
  loadAmountsBreakdownMonthlies: PropTypes.func.isRequired,
  loadAmountsBreakdownMonthliesTotals: PropTypes.func.isRequired,
  dataStatus: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.bool.isRequired,
        message: PropTypes.string,
      }).isRequired
    ).isRequired,
    needsReload: PropTypes.bool.isRequired,
    noData: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withRouter(translations(connect(BreakdownTab)));
