import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataV2 from 'services/masterData';
import groupBy from 'lodash/groupBy';

const initialState = {
  chartsByFL: {},
  editedChartsById: {},
  editId: null,
  newChartType: null,
  loading: false,
  saving: false,
};

export const OPEN_EDIT = 'CUSTOMER_PLATFORM/CustomCharts/OPEN_EDIT';
export const openEdit = (id, chartType) => ({ type: OPEN_EDIT, id, chartType });

export const CLOSE_EDIT = 'CUSTOMER_PLATFORM/CustomCharts/CLOSE_EDIT';
export const closeEdit = () => ({ type: CLOSE_EDIT });

export const LOAD_CHARTS = 'CUSTOMER_PLATFORM/CustomCharts/LOAD_CHARTS';
export const LOAD_CHARTS_FAIL = 'CUSTOMER_PLATFORM/CustomCharts/LOAD_CHARTS_FAIL';
export const LOAD_CHARTS_SUCCESS = 'CUSTOMER_PLATFORM/CustomCharts/LOAD_CHARTS_SUCCESS';

export const loadBuildingCharts = functionalLocation => async dispatch => {
  dispatch({ type: LOAD_CHARTS });
  try {
    const result = await MasterDataV2.loadBuildingCustomCharts({ functionalLocation });
    return dispatch({
      type: LOAD_CHARTS_SUCCESS,
      result,
    });
  } catch (e) {
    return dispatch({
      type: LOAD_CHARTS_FAIL,
    });
  }
};

export const loadFeatureCharts = (functionalLocation, feature) => async dispatch => {
  dispatch({ type: LOAD_CHARTS });
  try {
    const result = await MasterDataV2.loadFeatureCustomCharts({ functionalLocation, feature });
    return dispatch({
      type: LOAD_CHARTS_SUCCESS,
      result,
    });
  } catch (e) {
    return dispatch({
      type: LOAD_CHARTS_FAIL,
    });
  }
};

export const SAVE_CHART = 'CUSTOMER_PLATFORM/CustomCharts/UPSERT_CHART';
export const SAVE_CHART_FAIL = 'CUSTOMER_PLATFORM/CustomCharts/UPSERT_CHART_FAIL';
export const SAVE_CHART_SUCCESS = 'CUSTOMER_PLATFORM/CustomCharts/UPSERT_CHART_SUCCESS';
export const saveChart = (data, id, functionalLocation, feature) => async dispatch => {
  dispatch({ type: SAVE_CHART });
  try {
    let result = [];
    if (id != null) {
      result = await MasterDataV2.updateCustomChart(id, data);
    } else {
      result = await MasterDataV2.createCustomChart({ ...data, functionalLocation, feature });
    }
    return dispatch({
      type: SAVE_CHART_SUCCESS,
      result,
      id,
      functionalLocation,
    });
  } catch (error) {
    dispatch({
      type: SAVE_CHART_FAIL,
    });
    throw error;
  }
};

export const DELETE_CHART = 'CUSTOMER_PLATFORM/CustomCharts/DELETE_CHART';
export const DELETE_CHART_FAIL = 'CUSTOMER_PLATFORM/CustomCharts/DELETE_CHART_FAIL';
export const DELETE_CHART_SUCCESS = 'CUSTOMER_PLATFORM/CustomCharts/DELETE_CHART_SUCCESS';

export const deleteChart = (id, functionalLocation) => async dispatch => {
  dispatch({ type: DELETE_CHART });
  try {
    const result = await MasterDataV2.deleteCustomChart(id);
    return dispatch({
      type: DELETE_CHART_SUCCESS,
      result,
      id,
      functionalLocation,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CHART_FAIL,
    });
    throw error;
  }
};

const EDIT_CHART_WITHOUT_SAVING = 'CUSTOMER_PLATFORM/CustomCharts/EDIT_CHART_WITHOUT_SAVING';
export const editChartWithoutSaving = (newChart, id, functionalLocation) => dispatch => {
  return dispatch({
    type: EDIT_CHART_WITHOUT_SAVING,
    newChart,
    id,
    functionalLocation,
  });
};

export default createReducerFromMapping(
  {
    [EDIT_CHART_WITHOUT_SAVING]: (state, action) => {
      return {
        ...state,
        editedChartsById: {
          ...state.editedChartsById,
          [action.id]: action.newChart,
        },
      };
    },
    [OPEN_EDIT]: (state, action) => ({ ...state, editId: action.id, newChartType: action.chartType }),
    [CLOSE_EDIT]: (state, _) => ({ ...state, editId: null, newChartType: null }),
    [LOAD_CHARTS]: (state, _) => ({ ...state, loading: true }),
    [LOAD_CHARTS_FAIL]: (state, _) => ({ ...state, loading: false }),
    [LOAD_CHARTS_SUCCESS]: (state, action) => ({
      ...state,
      chartsByFL: {
        ...(action.result?.length ? groupBy(action.result, 'functionalLocation') : {}),
      },
      loading: false,
    }),
    [SAVE_CHART]: (state, _) => ({ ...state, saving: true }),
    [SAVE_CHART_FAIL]: (state, _) => ({ ...state, saving: false }),
    [SAVE_CHART_SUCCESS]: (state, action) => {
      if (action.id) {
        return {
          ...state,
          chartsByFL: {
            ...state.chartsByFL,
            [action.functionalLocation]: (state.chartsByFL[action.functionalLocation] ?? []).map(chart =>
              chart.id === action.id ? action.result : chart
            ),
          },
          editedChartsById: {
            ...state.editedChartsById,
            [action.id]: null,
          },
          saving: false,
        };
      }
      const flCharts = state.chartsByFL[action.functionalLocation] ?? [];
      return {
        ...state,
        chartsByFL: {
          ...state.chartsByFL,
          [action.functionalLocation]: [...flCharts, action.result],
        },
        saving: false,
      };
    },
    [DELETE_CHART]: (state, _) => ({ ...state }),
    [DELETE_CHART_FAIL]: (state, _) => ({ ...state }),
    [DELETE_CHART_SUCCESS]: (state, action) => ({
      ...state,
      chartsByFL: {
        ...state.chartsByFL,
        [action.functionalLocation]: (state.chartsByFL[action.functionalLocation] ?? []).filter(
          chart => action.id !== chart.id
        ),
      },
    }),
  },
  initialState
);
