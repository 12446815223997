import React from 'react';
import PropTypes from 'prop-types';
import SkeletonText from 'components/Skeletons/SkeletonText';
import Description from 'containers/Application/ServiceOrder/ServiceOrderInfo/ServiceOrderInfoTable/fields/Description';
import { SubSection } from '../ServiceOrder.styled';

const ServiceOrderDescription = ({ t, order, loading }) => {
  return (
    <SubSection>
      <h3>{t('Description')}</h3>
      {loading ? (
        <SkeletonText />
      ) : (
        <Description description={order?.description ?? ''} identifier={order?.id ?? 'description'} />
      )}
    </SubSection>
  );
};

ServiceOrderDescription.defaultProps = {
  loading: false,
};

ServiceOrderDescription.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.object,
  loading: PropTypes.bool,
};

export default ServiceOrderDescription;
