import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const getSensorLogFn = (request, filter, cursor, limit, order) =>
  request.get({
    apiPath: `${MASTER_DATA_PATH}/sensorLogs`,
    query: { filter: JSON.stringify(filter), cursor, limit, order },
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  getSensorLog: getSensorLogFn,
});
