import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';

import ContextualHelpArrow from './ContextualHelpArrow';
import ContextualHelpButton from './ContextualHelpButton';
import ContextualHelpMessage from './ContextualHelpMessage';
import { DialogOverlay } from 'components/Dialog/Dialog';
import { useTranslations } from 'decorators/Translations/translations';

const Container = styled.div`
  display: ${props => (props.block ? 'block' : 'inline-block')};
`;
Container.displayName = 'Container';

export const StyledOverlay = styled(DialogOverlay)`
  ${props => (props.active ? 'background-color: var(--black-040);' : '')}
  z-index: ${props => props.active && props.theme.zIndex('contextualHelp') - 1};
`;
StyledOverlay.displayName = 'StyledOverlay';

export const CTXHELP_PREFIX = 'ctxhelp';

const CTXHELP_LINKS = {
  'ctxhelp KPI Alarms': 'https://smartview.caverion.net/UserManual?page=remotecenter&title=Alarms',
  'ctxhelp KPI Observations': 'https://smartview.caverion.net/UserManual?page=remotecenter&title=Observations',
  'ctxhelp Observations OPI': 'https://smartview.caverion.net/UserManual?page=energy&title=Observations',
  'ctxhelp KPI Inspections': 'https://smartview.caverion.net/UserManual?page=remotecenter&title=Inspections',
  'ctxhelp KPI Energy Rating': 'https://smartview.caverion.net/UserManual?page=energy&title=Energy',
  'ctxhelp KPI Indoor Air Quality': 'https://smartview.caverion.net/UserManual?page=performance',
  'ctxhelp Savings potential OPI': 'https://smartview.caverion.net/UserManual?page=energy&title=SavingsPotential',
  'ctxhelp Energy energy consumption OPI':
    'https://smartview.caverion.net/UserManual?page=energy&title=EnergyConsumption',
  'ctxhelp Recovery rate OPI': 'https://smartview.caverion.net/UserManual?page=recycling&title=OPICards',
  'ctxhelp Carbon footprint OPI': 'https://smartview.caverion.net/UserManual?page=recycling&title=OPICards',
  'ctxhelp Recycling rate OPI': 'https://smartview.caverion.net/UserManual?page=recycling&title=OPICards',
  'ctxhelp Alarms OPI': 'https://smartview.caverion.net/UserManual?page=remotecenter&title=RemoteCenterforPortfolio',
  'ctxhelp Action breakdown':
    'https://smartview.caverion.net/UserManual?page=remotecenter&title=RemoteCenterforPortfolio',
  'ctxhelp Alarm handling time':
    'https://smartview.caverion.net/UserManual?page=remotecenter&title=RemoteCenterforPortfolio',
  'ctxhelp Time to action':
    'https://smartview.caverion.net/UserManual?page=remotecenter&title=RemoteCenterforPortfolio',
  'ctxhelp Energy breakdown electricity OPI':
    'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Energy breakdown districtHeating OPI':
    'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Energy breakdown waterConsumption OPI':
    'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Energy energy rating OPI': 'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Energy co2 OPI': 'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Energy breakdown': 'https://smartview.caverion.net/UserManual?page=energy&title=EnergyforBuilding',
  'ctxhelp Savings potential': 'https://smartview.caverion.net/UserManual?page=energy&title=SavingsPotential',
};

const ContextualHelp = ({ text, textParams = [], title, position = 'top', override = false, block = false, link }) => {
  const breakpoints = useBreakpoints();
  const [showHelp, setShowHelp] = React.useState(false);
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [caretElement, setCaretElement] = React.useState(null);
  const [t] = useTranslations();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [{ name: 'arrow', options: { element: caretElement } }],
  });

  const toggleShowHelp = React.useCallback(
    e => {
      if (!override) {
        e?.stopPropagation();
        setShowHelp(oldState => !oldState);
      }
    },
    [override]
  );

  // Don't render if we don't have proper content for the user's language
  // Hide on mobile
  if (t(text) === text || !breakpoints.landscape) {
    return null;
  }

  const showMessage = showHelp || override;

  if (process.env.NODE_ENV === 'development' && showMessage && !CTXHELP_LINKS[text]) {
    console.info(`"${text}"`);
  }

  return (
    <Container block={block}>
      <div ref={setReferenceElement}>
        <ContextualHelpButton onClick={toggleShowHelp} />
      </div>
      {showMessage &&
        ReactDOM.createPortal(
          <StyledOverlay active={!override}>
            <OutsideClickHandler onOutsideClick={toggleShowHelp}>
              <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                <ContextualHelpMessage
                  title={title}
                  text={t(text, ...textParams)}
                  link={
                    link || (CTXHELP_LINKS[text] ? { url: CTXHELP_LINKS[text], link: `${t('Read more')} →` } : null)
                  }
                  onClose={toggleShowHelp}
                />
                <ContextualHelpArrow
                  ref={setCaretElement}
                  position={attributes.popper?.['data-popper-placement']}
                  style={styles.arrow}
                />
              </div>
            </OutsideClickHandler>
          </StyledOverlay>,
          document.body
        )}
    </Container>
  );
};

ContextualHelp.propTypes = {
  text: PropTypes.string.isRequired,
  textParams: PropTypes.array,
  title: PropTypes.string,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  override: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.string,
};

export default ContextualHelp;
