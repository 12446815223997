
import React from 'react';

const withLoadingSkeleton = (TableComponent, SkeletonComponent) =>
  ({ loading, ...passThroughProps }) => {
    const Component = loading ? SkeletonComponent : TableComponent;
    return (
      <Component {...passThroughProps} />
    );
  };

export default withLoadingSkeleton;
