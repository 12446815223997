import { SERVICE_API_PATH } from '../masterData/index';
import ApiRequest from 'utils/Fetch/ApiRequest';

export const getFolders = ({ functionalLocation, partnerNumber, refreshCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/document/folders`,
    query: { functionalLocation, partnerNumber },
    refreshCache,
  });

export const createFolder = (path, folder) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/document/folders`,
    query: { path },
    data: { name: folder.name },
    noCache: true,
  });

export const updateFolder = (path, folder) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/document/folders`,
    query: { path },
    data: { path: folder.path, name: folder.name },
    noCache: true,
  });

export const deleteFolder = path =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/document/folders`,
    query: { path },
    noCache: true,
  });

export const downloadFolder = ({ functionalLocation, partnerNumber, folderId, folderName }) =>
  ApiRequest.download({
    method: 'GET',
    apiPath: `${SERVICE_API_PATH}/document/folders/download`,
    query: { functionalLocation, partnerNumber, id: folderId },
    noCache: true,
    fileName: folderName,
  });
