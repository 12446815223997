import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';

export const DataNotFoundText = styled.p`
  text-align: center;
`;

export type DataNotFoundProps = {
  text?: ReactNode;
};

/**
 * @implements {UIv3}
 */
export function DataNotFound({ text }: DataNotFoundProps) {
  const [t] = useTranslations();
  text ??= t('Data not found');

  return <DataNotFoundText>{text}</DataNotFoundText>;
}
