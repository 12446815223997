import formatFunc from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import formatDistanceToNowFunc from 'date-fns/formatDistanceToNowStrict';
import isValid from 'date-fns/isValid';
import { fi, sv, nb, da, lt, enGB, de, ru } from 'date-fns/locale';

export const locales = { fi, sv, nb, da, lt, 'en-gb': enGB, de, ru };
export const getCurrentDateLocale = () => locales[window.__localeId__] ?? locales['en-gb'];

export const DATE_FORMATS = {
  timeShortLocal: 'p', // 01:47 PM (localized)
  dateShort: 'dd.MM.yyyy', // 31.01.2020
  dateShortLocal: 'P', // 31/01/2020 (localized based on the user locale)
  dateMedium: 'do MMM yyyy', // 31st Jan 2020
  dateLong: 'do MMMM yyyy', // 31st January 2020
  dateTimeShort: 'dd.MM.yyyy, HH:mm', // 31.01.2020, 12:47
  dateTimeShortLocal: 'P, p', // 31/01/2020, 01:47 PM (localized)
  dateTimeMedium: 'do MMM yyyy, HH:mm', // 31st Jan 2020, 12:47
  dateTimeLong: 'do MMMM yyyy, HH:mm', // 31st January 2020, 12:47
  yearMonthShort: 'MM/yyyy', // 12/2020
};

// Localized format from date-fns with default format dd.MM.yyyy
export const format = (date, formatStr = 'dd.MM.yyyy', options = {}) =>
  isValid(date)
    ? formatFunc(options.timeZone ? utcToZonedTime(date, options.timeZone) : date, formatStr, {
        locale: getCurrentDateLocale(),
        ...options,
      })
    : '';

// Localized formatDistanceToNow from date-fns (with addSuffix defaulted true)
export const formatDistanceToNow = (date, options = {}) =>
  isValid(date)
    ? formatDistanceToNowFunc(date, {
        addSuffix: true,
        locale: getCurrentDateLocale(),
        ...options,
      })
    : '';

export const getMonthsShort = () => getMonths('abbreviated');

export const getMonths = (width = 'wide') => {
  const localeObject = getCurrentDateLocale();
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(localeObject.localize.month(i, { width }));
  }
  return months;
};

export const getWeekdaysShort = () => getWeekdays('short');

export const getWeekdays = (width = 'wide') => {
  const localeObject = getCurrentDateLocale();
  const weekdays = [];
  for (let i = 0; i < 7; i++) {
    weekdays.push(localeObject.localize.day(i, { width }));
  }
  return weekdays;
};

export const ordinalNumber = number => getCurrentDateLocale().localize.ordinalNumber(number);
