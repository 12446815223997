import React from 'react';
import styled from 'styled-components';
import { ObservationViewValue } from 'components/Observations/ObservationView';

export const Column = styled.div`
  display: flex;
  align-items: center;
`;

const SavingsColumn = ({ t, data = 0, unit }) => {
  if (data === 0) {
    return null;
  }
  return (
    <Column>
      <ObservationViewValue noMargin value={data}>
        <strong>
          {data} {unit}
        </strong>{' '}
        / {t('year')}
      </ObservationViewValue>
    </Column>
  );
};

export default SavingsColumn;
