import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import ServiceModuleForm from 'containers/Application/ServiceModule/ServiceModuleForm/ServiceModuleForm';
import DesktopForm from 'containers/Application/ServiceModule/ServiceModuleForm/DesktopForm';
import MobileForm from 'containers/Application/ServiceModule/ServiceModuleForm/MobileForm';
import { InfoForm, ContactForm, createInfoFormSchema } from './forms';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import useFormValidation from 'containers/Application/ServiceModule/ServiceModuleForm/hooks/useFormValidation';
import { createContactFormSchema } from 'containers/Application/ServiceModule/ServiceModuleForm/forms';
import SendButton from './forms/SendButton';

const ID_DESKTOP = 'PublicServiceRequestForm_Desktop';
const ID_MOBILE = 'PublicServiceRequestForm_Mobile';

const Article = styled.article`
  background-color: ${props => props.theme.colors.midnight};

  ${props => props.theme.media.landscape`
    padding: var(--size-xl);
    margin: 0 auto ${props => props.theme.navigation.spacing};
    width: 1200px;
    max-width: 95vw;
    background-color: ${props => props.theme.colors.white};
  `}
`;
Article.displayName = 'Article';

const MobileContainer = styled.div`
  border-radius: 25px 25px 0 0;
  background-color: ${props => props.theme.colors.white};
  padding: var(--size-lg) var(--size-md) var(--size-md);
`;
MobileContainer.displayName = 'MobileContainer';

const Header = styled.h2`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.normal};

  ${props => props.theme.media.landscape`
    font-size: ${props => props.theme.font.size.lg};
    font-weight: ${props => props.theme.font.weight.normal};
  `}
`;
Header.displayName = 'Header';

const Title = styled.h3`
  margin-bottom: var(--size-sm);
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.bold};

  ${props => props.theme.media.landscape`
    margin-bottom: var(--size-lg);
    font-size: ${props => props.theme.font.size.lg};
    font-weight: ${props => props.theme.font.weight.bold};
  `}
`;

const DesktopHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FixedButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: var(--size-xs);
  bottom: var(--size-lg);
  left: var(--size-xs);

  button {
    display: inline-block;
    margin: var(--size-xxs);
    height: 4em;
    flex: 1;
    min-width: auto;
  }
`;
FixedButtons.displayName = 'FixedButtons';

const PaddingForButton = styled.div`
  margin-bottom: calc(4em + var(--size-lg));
`;

const ContactDisclaimer = styled.p`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  margin-top: var(--size-md);
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
ContactDisclaimer.displayName = 'ContactDisclaimer';

const EMPTY_ARRAY = [];

export const PublicServiceRequestForm = ({
  t,
  locationTitle = '',
  division,
  onSubmit,
  submitting = false,
  functionalLocations,
  loadingFunctionalLocations,
}) => {
  const [model, setModel] = React.useState({});
  const [files, setFiles] = React.useState([]);

  const handleFormChange = (property, value) => setModel(oldModel => ({ ...oldModel, [property]: value }));

  const formSchemas = React.useMemo(
    () => ({
      infoForm: createInfoFormSchema(t, functionalLocations.length > 0),
      contactForm: createContactFormSchema(t),
    }),
    [t, functionalLocations]
  );
  const mobileFormTabs = [
    { title: t('Service request info'), validate: () => validateForms(['infoForm']) },
    { title: t('Informant'), validate: () => validateForms(['contactForm']) },
  ];

  const [validationErrors, validateForms] = useFormValidation(model, formSchemas);

  const handleMobileSubmit = async () => {
    if (await validateForms()) {
      onSubmit(model, files);
    }
  };

  const handleDesktopSubmit = async () => {
    if (await validateForms()) {
      onSubmit(model, files);
    }
  };

  const handleNewFile = file => setFiles(oldFiles => oldFiles.concat([file]));
  const handleRemoveFile = index =>
    setFiles(oldFiles => oldFiles.slice(0, index).concat(oldFiles.slice(index + 1, oldFiles.length)));

  const breakpoints = useBreakpoints();

  const mobileHeader = (
    <>
      <Header>{t('New service request')}: </Header>
      <Title>{locationTitle}</Title>
    </>
  );

  return (
    <Article>
      <ServiceModuleForm saving={submitting} showHeader={false}>
        {breakpoints.landscape && (
          <DesktopHeader>
            <div>
              <Header>{t('New service request')}: </Header>
              <Title>{locationTitle}</Title>
            </div>
            <SendButton t={t} onClick={handleDesktopSubmit} disabled={submitting} loading={submitting} />
          </DesktopHeader>
        )}
        <MobileForm
          tabs={mobileFormTabs}
          SendButton={SendButton}
          handleMobileSend={handleMobileSubmit}
          validationErrors={validationErrors}
          header={mobileHeader}
        >
          <InfoForm
            t={t}
            model={model}
            functionalLocations={functionalLocations}
            loadingFunctionalLocations={loadingFunctionalLocations}
            onFormChange={handleFormChange}
            files={files}
            erroredFiles={EMPTY_ARRAY}
            onFileUpload={handleNewFile}
            onFileRemoval={handleRemoveFile}
            validationErrors={validationErrors}
          />
          <PaddingForButton>
            <ContactForm
              t={t}
              model={model}
              onFormChange={handleFormChange}
              id={ID_MOBILE}
              division={division}
              validationErrors={validationErrors}
            />
            <ContactDisclaimer>{t('SERVICE_REQUEST_CONTACT_INFO_DISCLAIMER')}</ContactDisclaimer>
          </PaddingForButton>
        </MobileForm>
        <DesktopForm validationErrors={validationErrors}>
          <>
            <h4>{t('Service request info')}</h4>
            <InfoForm
              t={t}
              model={model}
              functionalLocations={functionalLocations}
              loadingFunctionalLocations={loadingFunctionalLocations}
              onFormChange={handleFormChange}
              files={files}
              erroredFiles={EMPTY_ARRAY}
              onFileUpload={handleNewFile}
              onFileRemoval={handleRemoveFile}
              validationErrors={validationErrors}
            />
          </>
          <>
            <h4>{t('Informant')}</h4>
            <ContactForm
              t={t}
              model={model}
              onFormChange={handleFormChange}
              id={ID_DESKTOP}
              division={division}
              validationErrors={validationErrors}
            />
            <ContactDisclaimer>{t('SERVICE_REQUEST_CONTACT_INFO_DISCLAIMER')}</ContactDisclaimer>
          </>
        </DesktopForm>
      </ServiceModuleForm>
    </Article>
  );
};

PublicServiceRequestForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  locationTitle: PropTypes.string,
  division: PropTypes.string.isRequired,
  submitting: PropTypes.bool,
  functionalLocations: PropTypes.arrayOf(
    PropTypes.shape({
      functionalLocation: PropTypes.string,
      address: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  loadingFunctionalLocations: PropTypes.bool.isRequired,
};

export default translations(PublicServiceRequestForm);
