import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const additionalSaleWithToken = token =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/additional-sales/with-token`,
    query: { token },
    noCache: true,
    useAuthorization: false,
  });

export const sendAdditionalSaleActionWithToken = (token, data) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/additional-sales/action/with-token`,
    query: { token },
    data,
    useAuthorization: false,
  });

export const updateAdditionalSaleManagerWithToken = (token, data) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/additional-sales/manager/with-token`,
    query: { token },
    data,
    useAuthorization: false,
  });
