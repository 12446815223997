import { useEffect, useState } from 'react';
import curry from 'lodash/fp/curry';
import compose from 'lodash/fp/compose';
import eq from 'lodash/fp/eq';
import { createScatterPlotData, getSensorIdForAxis } from './utils';
import { getSensorTitle } from 'containers/Application/SensorValues/SensorValuesUtils';
import { rgba } from 'polished';

const getAxisName = curry(getSensorTitle)(undefined);

const idForAxis = (axis, series) => getSensorIdForAxis(series, axis);
const findSensor = (sensorId, sensor) => eq(sensorId, sensor.id);
const findSensorForX = compose(curry(findSensor), curry(idForAxis)('x'));
const findSensorForY = compose(curry(findSensor), curry(idForAxis)('y'));
const typeUnit = sensor => sensor?.sensorType.unit;

const useScatterPlotCustomChart = ({ chart, buildingSensors, valuesBySensorId }) => {
  const [state, setState] = useState({ seriesData: [], xUnit: undefined, yUnit: undefined });

  useEffect(() => {
    const axisXUnit = compose(typeUnit, f => buildingSensors.find(f), findSensorForX);
    const axisYUnit = compose(typeUnit, f => buildingSensors.find(f), findSensorForY);

    setState({
      xUnit: axisXUnit(chart.series[0]),
      yUnit: axisYUnit(chart.series[0]),
      seriesData: chart.series.map((seriesObj, id) => ({
        id,
        name: seriesObj.name,
        xName: getAxisName(axisXUnit(seriesObj)),
        yName: getAxisName(axisYUnit(seriesObj)),
        color: seriesObj.color,
        data: createScatterPlotData(seriesObj, valuesBySensorId, seriesObj.aggregationFreq ?? chart.aggregationFreq),
        marker: {
          fillColor: rgba(seriesObj.color, 0.6),
          lineColor: seriesObj.color,
          symbol: 'circle',
        },
      })),
    });
  }, [valuesBySensorId, chart, buildingSensors]);

  return state;
};

export default useScatterPlotCustomChart;
