import React from 'react';
import PropTypes from 'prop-types';

import { Columns, Column } from 'components/Columns';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import useValidationEffects from './hooks/useValidationEffects';

const COLUMN_WIDTH = {
  landscape: 6,
  default: 12,
};

const DesktopForm = ({ children, validationErrors }) => {
  const breakpoints = useBreakpoints();
  const top = React.useRef(null);
  const errorMessage = useValidationEffects(validationErrors, top);

  if (!breakpoints.landscape) {
    return null;
  }

  const childArray = React.Children.toArray(children);

  return (
    <div>
      <div ref={top} />
      {errorMessage}
      <Columns noPadding>
        <Column columnWidth={COLUMN_WIDTH}>{childArray[0]}</Column>
        <Column columnWidth={COLUMN_WIDTH}>{childArray[1]}</Column>
      </Columns>
    </div>
  );
};

DesktopForm.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default DesktopForm;
