import Prismic from 'prismic-javascript/umd/prismic-javascript';
import scrollToElement from 'scroll-to-element';
import isEmpty from 'lodash/isEmpty';

// use dependencies wrapper coz of units
// to easily mock 3rd party exported function
export const dependencies = { scrollToElement };

export const SLICE_TYPE = {
  SECTION_SUBJECT: 'section_subject',
  REPEATABLE_SECTION_SUBJECT: 'section_subject1',
  SECTION_CONTENT: 'section_content',
  SECTION_WITH_CONTENT: 'section_with_content',
};

export const CUSTOM_TYPE = {
  USER_MANUAL: 'usermanual_v2',
  USER_MANUAL_PAGE: 'usermanual_v2_page',
};

const PRISMIC_OPTIONS = { lang: 'en-us', pageSize: 100 };

const getUserManualDocumentClient = () => {
  return Prismic.api(process.env.REACT_APP_PRISMIC_ENDPOINT, { accessToken: process.env.REACT_APP_PRISMIC_TOKEN });
};

export const searchUserManualDocuments = async (documentTypes, searchTerm, isAdmin = false) => {
  const client = await getUserManualDocumentClient();
  const conditions = [
    Prismic.Predicates.any('document.type', documentTypes),
    Prismic.Predicates.fulltext('document', searchTerm),
  ];
  !isAdmin && conditions.push(Prismic.Predicates.not('document.tags', ['admin']));
  const response = await client.query(conditions, PRISMIC_OPTIONS);
  const data = response.results
    .map(page => ({
      ...page,
      data: {
        ...page.data,
        body: page.data.body.filter(slice => slice.slice_label !== 'admin'),
      },
    }))
    .filter(page => page.data.body.length > 0)
    .filter(page => {
      const include = page.data.body
        .flatMap(slice => slice.primary.content)
        .reduce((prev, curr) => prev.concat(curr.text), '')
        .includes(searchTerm);

      page.data.body = page.data.body.flatMap(slice =>
        slice.primary.content.filter(item => item.type.startsWith('heading'))
      );
      return include;
    });
  return data;
};

export const getDocumentByCustomTypes = (documentTypes, isAdmin = false) => {
  const conditions = [Prismic.Predicates.any('document.type', documentTypes)];
  !isAdmin && conditions.push(Prismic.Predicates.not('document.tags', ['admin']));
  return getUserManualDocumentClient().then(client => client.query(conditions, PRISMIC_OPTIONS));
};

export const formatTitleForUrl = title => title?.replace(/[^A-Z0-9]/gi, '');

export const getSectionTitleText = (section, trimmed = true) => {
  let text = '';
  if (
    section.primary &&
    section.primary.contentTitle &&
    section.primary.contentTitle.length > 0 &&
    section.primary.contentTitle[0].text
  ) {
    text = trimmed ? formatTitleForUrl(section.primary.contentTitle[0].text) : section.primary.contentTitle[0].text;
  }
  return text;
};

export const getSectionSubjectText = (section, trimmed = true) => {
  let text = '';
  if (
    section.primary &&
    section.primary.contentSubject &&
    section.primary.contentSubject.length > 0 &&
    section.primary.contentSubject[0].text
  ) {
    text = trimmed ? formatTitleForUrl(section.primary.contentSubject[0].text) : section.primary.contentSubject[0].text;
  }
  return text;
};

export const scrollTo = (id, title, duration = 1000) => {
  const isMobile = window.innerWidth < 900;
  const offset = isMobile ? -200 : -84;
  let element = document.getElementById(id);
  if (!element) {
    element = Array.from(document.querySelectorAll('h1,h2,h3,h4,h5,h6')).find(el => el.textContent === title);
  }
  dependencies.scrollToElement(element, { offset, duration });
};

export const getSectionOptions = pages =>
  pages?.flatMap(page => {
    const options = [];
    if (page.type !== CUSTOM_TYPE.USER_MANUAL) {
      options.push({
        label: page.data.title[0]?.text || '',
        value: `${page.uid}/${formatTitleForUrl(page.data.title[0]?.text) || ''}`,
        disabled: true,
      });
    }
    page.data.body.forEach(singleSectionContent => {
      switch (singleSectionContent.slice_type) {
        case SLICE_TYPE.SECTION_SUBJECT:
        case SLICE_TYPE.REPEATABLE_SECTION_SUBJECT:
          options.push({
            label: getSectionSubjectText(singleSectionContent, false),
            value: `${page.uid}/${getSectionSubjectText(singleSectionContent) || ''}`,
            disabled: true,
          }); // use react-select disabled flag as a workaround to show group headings in mobile
          break;
        case SLICE_TYPE.SECTION_CONTENT:
        case SLICE_TYPE.SECTION_WITH_CONTENT:
          options.push({
            label: getSectionTitleText(singleSectionContent, false),
            value: `${page.uid}/${getSectionTitleText(singleSectionContent) || ''}`,
          });
          break;
        default:
          break;
      }
    });
    return options.filter(option => !isEmpty(option.value)).filter(option => !option.value.endsWith('/'));
  }) || [];

export const generateCopiedUrl = (page, title) =>
  `${window.location.origin}${window.location.pathname}?page=${page}&title=${title}`;
