import { useEffect, useMemo, useState } from 'react';

const useOnScreen = (ref, initialIsIntersecting) => {
  const [isIntersecting, setIntersecting] = useState(initialIsIntersecting || true);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  });

  return isIntersecting;
};

export default useOnScreen;
