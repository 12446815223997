import React from 'react';

import SkeletonAnimation from 'components/Skeletons/SkeletonAnimation';

import * as sharedColumnStyles from './SharedColumns.module.scss';

export function SkeletonCell() {
  return (
    <div className={sharedColumnStyles.skeletonCell}>
      <SkeletonAnimation />
    </div>
  );
}
