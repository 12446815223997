import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const createServiceRequestFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/serviceRequests`,
    data,
    noCache: true,
  });

export const sendSapServiceOrderMessageFn = (request, data) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/serviceRequests/message`,
    data,
  });

export default wrapEachWithThunkRequest({
  createServiceRequest: createServiceRequestFn,
  sendSapServiceOrderMessage: sendSapServiceOrderMessageFn,
});
