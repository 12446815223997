import styled from 'styled-components';

export const Info = styled.span`
  display: block;
  color: var(--grey-70);
  font: var(--font-xxs);
`;

export const SubSection = styled.div`
  width: 100%;
  min-width: fit-content;

  & > * + * {
    margin-top: var(--size-sm);
  }
  & > *:last-child {
    margin-bottom: var(--size-sm);
  }

  h3 {
    font: var(--font-sm-bold);
  }

  p {
    font: var(--font-xs);
    color: var(--black);
    line-height: var(--line-height-loose);
    overflow-wrap: break-word;
  }
`;
