import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import withQuery from 'decorators/Query/withQuery';
import * as JsSearch from 'js-search';
import translations from 'decorators/Translations/translations';
import styled from 'styled-components';
import get from 'lodash/get';
import includes from 'lodash/includes';
import find from 'lodash/find';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';

import StandardPage from 'components/StandardPage/StandardPage';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import Header from 'containers/Application/Header/Header';
import KPIModule from './KPIModule/KPIModule';
import { InputSearch } from 'components/index';
import Hero from 'components/Hero/Hero';
import FunctionalLocationTable from 'components/FunctionalLocationTable/FunctionalLocationTable';
import ErrorPage from 'containers/Application/ErrorPage/ErrorPage';
import FadeTransition from 'components/FadeTransition/FadeTransition';
import TabContent from 'components/TabContent/TabContent';
import Content from 'components/Content/Content';

import { getPartnerNumbers } from 'utils/profile';
import { getPortfolioLinks, getPortfolioImage, findPartnerNumberForFL } from 'utils/Data/partners';
import {
  addCustomerAdminLink,
  addNewServiceRequestCustomerLink,
  addContactCaverionCustomerLink,
  functionalLocationName,
} from 'utils/Data/functionalLocations';
import { FEATURE_TO_TAB } from 'utils/Data/features';
import { showContactCaverion } from 'containers/Application/Modules/ContactModule/utils';
import { connector } from './connectPartnerOverview';
import { isServiceRequestEnabled } from 'containers/Application/Navigation/NavigationItems';
import FunctionalLocationListConfiguration from './FunctionalLocationListConfiguration';
import { getBuildingPerformanceSourceOptions } from '../Admin/UserForm/Features/utils';

const getSortedFunctionalLocations = (t, functionalLocations, defaultFeature, performances, profile) => {
  let tab = FEATURE_TO_TAB[defaultFeature];
  if (isArray(tab)) {
    tab = tab[0];
  }

  return functionalLocations.map(functionalLocation => ({
    functionalLocation: functionalLocation.functionalLocation,
    description: functionalLocationName(t, functionalLocation),
    name: functionalLocation.name,
    key: functionalLocation.key,
    type: functionalLocation.type,
    address: `${functionalLocation.address || ''} ${functionalLocation.city || ''}`,
    tab,
    performances: performances?.length
      ? convertToFlPerformances(functionalLocation.functionalLocation, performances)
      : undefined,
    partnerNumbers: findPartnerNumberForFL(functionalLocation, profile, true),
  }));
};

const convertToFlPerformances = (functionalLocation, performances) =>
  performances.reduce(
    (result, performance) => ({
      ...result,
      [performance.source]: get(performance.data, functionalLocation, null),
    }),
    {}
  );

const InputSearchContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.landscape`
        width: auto;
        min-width: 18rem;
    `}

  & > div {
    width: 100%;
  }

  input {
    height: 32px;
  }
  svg {
    top: 0.45rem;
  }
`;

const HeaderInputsContainer = styled.div`
  display: flex;
  button {
    height: 34px;
    margin-left: var(--size-xs);
  }
`;

const NegativeMargin = styled.div`
  margin: 0 calc(-1 * var(--size-md));

  ${props => props.theme.media.portrait`
    margin: 0 -${props => props.theme.grid.gutter};
  `}
`;

const initSearch = () => {
  const flSearch = new JsSearch.Search('functionalLocation');
  flSearch.addIndex('functionalLocation');
  flSearch.addIndex('name');
  flSearch.addIndex('description');
  flSearch.addIndex('address');
  return flSearch;
};

const performanceOrder = {
  alarms: 1,
  observations: 2,
  energy: 3,
  energyConsumption: 4,
  indoorAirQuality: 5,
};

const performanceSorter = (itemA, itemB) => (performanceOrder[itemA] || 0) - (performanceOrder[itemB] || 0);

const PartnerOverview = props => {
  const {
    t,
    loading,
    loadingImage,
    loadPartnerOverview,
    error,
    match: {
      params: { partnerNumber },
    },
    profile,
    features,
    customers,
    topLevelFunctionalLocations,
    query,
    setQuery,
    customViewsByCustomer,
    disabledCustomViews,
    featureTeasers,
    defaultFeature,
    partnerMeta,
    performances,
    partnerImages,
    divisionConfig,
    serviceRequestCount,
  } = props;

  const [searchResults, setSearchResult] = React.useState(null);
  const flSearch = React.useRef(initSearch());

  React.useEffect(() => {
    loadPartnerOverview(partnerNumber);
    flSearch.current = initSearch();
    setQuery({ page: undefined });
  }, [partnerNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error || !partnerNumber || (partnerNumber !== 'all' && !includes(getPartnerNumbers(profile), partnerNumber))) {
    return <ErrorPage type="partner" />;
  }

  let sortedFunctionalLocations = getSortedFunctionalLocations(
    t,
    topLevelFunctionalLocations,
    defaultFeature,
    performances,
    profile
  );
  const performanceColumns = performances?.map(performance => performance.source).sort(performanceSorter) || [];

  if (flSearch.current) {
    flSearch.current.addDocuments(sortedFunctionalLocations);
  }
  sortedFunctionalLocations = searchResults || sortedFunctionalLocations;

  const partnerImage =
    getPortfolioImage(partnerImages, partnerNumber) || `${window.location.origin}/clientnet_background.jpg`;

  const partner = find(customers, { partnerNumber });

  const showContactCaverionLink =
    partnerNumber !== 'all' && showContactCaverion(partnerMeta[partnerNumber]?.meta, profile, divisionConfig);
  const showNewServiceRequestLink = isServiceRequestEnabled(profile.features);

  const links = getPortfolioLinks(
    partnerNumber,
    features,
    query,
    customViewsByCustomer[partnerNumber],
    disabledCustomViews,
    featureTeasers,
    serviceRequestCount
  );

  let linksMobile = clone(links);
  linksMobile = addCustomerAdminLink(linksMobile, partnerNumber, profile);
  linksMobile = addNewServiceRequestCustomerLink(linksMobile, partnerNumber, features);
  linksMobile = addContactCaverionCustomerLink(linksMobile, partnerNumber, showContactCaverionLink);

  // Show loading if partner is selected but not yet loaded (or images are not loaded yet)
  const loadingContext = loading || loadingImage || (partnerNumber && partnerNumber !== 'all' && !partner);
  return (
    <FadeTransition>
      <StandardPage key="overview" disableScrollToTop>
        <Helmet title={t('Overview')} />
        <Header t={t} selected="overview" showPartnerSelect links={links} linksMobile={linksMobile} />
        <Hero
          title={partner?.name ?? t('All Customers')}
          t={t}
          heroImage={partnerImage}
          isOverview
          profile={profile}
          partnerNumber={partnerNumber}
          loadingContext={loadingContext}
          type="OVERVIEW"
          showContactCaverion={showContactCaverionLink}
          showNewServiceRequest={showNewServiceRequestLink}
        />
        <TabContent>
          <Content>
            {partnerNumber !== 'all' && <KPIModule />}
            <Section>
              <Content>
                <SectionHeader noBorder title={t('Portfolio')} size="small">
                  <HeaderInputsContainer>
                    <InputSearchContainer>
                      <InputSearch
                        id="fl-search-input"
                        onChange={value => {
                          const result = flSearch.current.search(value);
                          setSearchResult(result);
                          setQuery({ page: undefined }); // Reset possible paging after search
                        }}
                        placeholder={t('Filter by Name or Address')}
                        onClear={() => setSearchResult(null)}
                      />
                    </InputSearchContainer>
                    <FunctionalLocationListConfiguration
                      performanceOptions={getBuildingPerformanceSourceOptions(t, features)}
                      selectedPerformances={performanceColumns}
                    />
                  </HeaderInputsContainer>
                </SectionHeader>
                <NegativeMargin>
                  <FunctionalLocationTable
                    loading={loading}
                    t={t}
                    data={sortedFunctionalLocations}
                    partnerNumber={partnerNumber}
                    paginate
                    initialSortBy={[{ id: 'description', desc: false }]}
                    performanceColumns={performanceColumns}
                    horizontalScroll={{ enabled: true, horizontalPadding: true }}
                  />
                </NegativeMargin>
              </Content>
            </Section>
          </Content>
        </TabContent>
      </StandardPage>
    </FadeTransition>
  );
};

PartnerOverview.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingImage: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  customers: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  topLevelFunctionalLocations: PropTypes.array.isRequired,
  customViewsByCustomer: PropTypes.object.isRequired,
  disabledCustomViews: PropTypes.array.isRequired,
  featureTeasers: PropTypes.array.isRequired,
  defaultFeature: PropTypes.string,
  partnerMeta: PropTypes.object.isRequired,
  performances: PropTypes.array,
  loadPartnerOverview: PropTypes.func.isRequired,
  partnerImages: PropTypes.object.isRequired,
  divisionConfig: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  serviceRequestCount: PropTypes.number,
};

export default connector(translations(withQuery(PartnerOverview)));
