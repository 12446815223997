import React from 'react';

import * as Styled from './InfoTable.styled';
import { SubSection } from 'containers/Application/ServiceOrder/ServiceOrder.styled';
import InfoTableSkeleton from 'components/InfoTable/InfoTableSkeleton';

type TableItem = {
  id: string;
  label: string;
  value: string | number;
};

interface TableProps {
  header: string;
  items: TableItem[];
  loading?: boolean;
}

const InfoTable = (props: TableProps) => {
  const { loading, items, header } = props;

  if (loading) {
    return (
      <InfoTableSkeleton
        loading={loading}
        borderTop={undefined}
        borders={undefined}
        labelColumnWidth={undefined}
        valueColumnWidth={undefined}
      />
    );
  }

  return (
    <SubSection>
      <h3>{header}</h3>
      <Styled.InfoTable>
        <ul>
          {items.map(({ label, value }, idx) => (
            <li key={idx}>
              <Styled.NameColumn>{label}</Styled.NameColumn>
              <Styled.ValueColumn>{value}</Styled.ValueColumn>
            </li>
          ))}
        </ul>
      </Styled.InfoTable>
    </SubSection>
  );
};

export default InfoTable;
