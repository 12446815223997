import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SectionTabs from 'components/SectionTabs/SectionTabs';
import SectionTab from 'components/SectionTabs/SectionTab';
import SectionTabSecondary from 'components/SectionTabs/SectionTabSecondary';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import { useTranslations } from 'decorators/Translations/translations';
import { Link } from 'react-router-dom';

const MobileSelectContainer = styled.div`
  width: 100%;

  ${props => props.theme.media.landscape`
        display: none;
    `}
`;
MobileSelectContainer.displayName = 'MobileSelectContainer';

const TAB_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const getTabComponent = type => (type === TAB_TYPES.SECONDARY ? SectionTabSecondary : SectionTab);

const SectionTabSelector = ({
  options,
  onTabChange,
  model,
  property,
  noMobilePadding,
  type = TAB_TYPES.PRIMARY,
  disableLinks = false,
}) => {
  const [t] = useTranslations();
  if (!options) {
    return null;
  }
  const selected = get(model, property);
  const TabComponent = getTabComponent(type);

  const mobileOptionRenderer = option =>
    disableLinks ? (
      option.label
    ) : (
      <Link to={String(option.value)} style={{ color: 'inherit', display: 'block' }} onClick={e => e.preventDefault()}>
        {option.label}
      </Link>
    );

  return (
    <Fragment>
      <SectionTabs hideOnMobile>
        {options.map(option => {
          const tabElement = (
            <TabComponent
              key={option.value}
              selected={option.value === selected}
              onClick={() => onTabChange(property, option.value)}
            >
              {option.label}
            </TabComponent>
          );
          return disableLinks ? (
            tabElement
          ) : (
            <Link
              key={option.value}
              to={String(option.value)}
              style={{ color: 'inherit' }}
              onClick={e => e.preventDefault()}
            >
              {tabElement}
            </Link>
          );
        })}
      </SectionTabs>
      <MobileSelectContainer noMobilePadding={noMobilePadding}>
        <InputSelectDropdown
          model={model}
          property={property}
          options={options}
          t={t}
          clearable={false}
          onChange={onTabChange}
          optionRenderer={mobileOptionRenderer}
        />
      </MobileSelectContainer>
    </Fragment>
  );
};

SectionTabSelector.defaultProps = {
  options: null,
  noMobilePadding: false,
};

SectionTabSelector.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  property: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  noMobilePadding: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TAB_TYPES)),
  disableLinks: PropTypes.bool,
};

export default SectionTabSelector;
