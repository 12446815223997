import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';

const initialState = {
  loading: false,
};

export const EXPORT_SENSOR_DATA = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA';
export const EXPORT_SENSOR_DATA_SUCCESS = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA_SUCCESS';
export const EXPORT_SENSOR_DATA_FAIL = 'CUSTOMER_PLATFORM/Export/EXPORT_SENSOR_DATA_FAIL';

export const exportSensorData = data => async dispatch => {
  dispatch({ type: EXPORT_SENSOR_DATA });
  try {
    await dispatch(IoTService.exportValues(data));
    return dispatch({ type: EXPORT_SENSOR_DATA_SUCCESS });
  } catch (error) {
    return dispatch({
      type: EXPORT_SENSOR_DATA_FAIL,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [EXPORT_SENSOR_DATA]: state => ({
      ...state,
      loading: true,
    }),
    [EXPORT_SENSOR_DATA_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [EXPORT_SENSOR_DATA_FAIL]: state => ({
      ...state,
      loading: false,
    }),
  },
  initialState
);
