import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';
import { ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import translations from 'decorators/Translations/translations';

const StyledCopyToClipboard = styled.span`
  display: inline-flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.font.size.xxxs};
  margin-left: var(--size-md);
`;

const StyledCopyLink = styled.span`
  background-color: transparent;
  color: var(--link-fg);
  border-radius: 25px;
  display: inline-flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: var(--size-xxs) var(--size-sm);
  text-transform: uppercase;
  font-weight: ${props => props.theme.font.weight.bold};

  &:hover {
    cursor: pointer;
    background-color: var(--link-fg);
    color: var(--white);
  }

  svg {
    margin-right: 4px;
  }
`;

StyledCopyLink.displayName = 'StyledCopyLink';

const CopyToClipboard = ({ textToCopy, t, label }) => {
  const [copiedText, setCopiedText] = useState();

  const onClickCopy = useCallback(async () => {
    await navigator.clipboard.writeText(textToCopy);
    setCopiedText(textToCopy);
  }, [textToCopy]);

  return (
    <StyledCopyToClipboard>
      {copiedText && copiedText === textToCopy && `${t('Copied')} `}
      <StyledCopyLink role="button" onClick={onClickCopy}>
        <Icon name={icons.LINK_SOLID} size={ICON_SIZES.SMALL} />
        {t(label || 'copy link')}
      </StyledCopyLink>
    </StyledCopyToClipboard>
  );
};

CopyToClipboard.propTypes = {
  textToCopy: PropTypes.string,
  label: PropTypes.string,
  t: PropTypes.func,
}

export default translations(CopyToClipboard);
