import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from '../masterData/index';
import qs from 'qs';

export const announcements = ({ expirationDate, functionalLocation, limit }) => {
  const query = qs.stringify({ expirationDate, functionalLocation, limit });
  return ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/announcements?${query}`,
    noCache: true,
  });
};

export const createAnnouncement = ({
  type,
  status,
  title,
  description,
  startDate,
  expirationDate,
  partnerNumber,
  functionalLocation,
}) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/announcements`,
    data: { type, status, title, description, startDate, expirationDate, partnerNumber, functionalLocation },
    noCache: true,
  });

export const updateAnnouncement = (id, { status, title, description, startDate, expirationDate }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/announcements/${id}`,
    data: { status, title, description, startDate, expirationDate },
    noCache: true,
  });

export const deleteAnnouncement = id =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/announcements/${id}`,
    noCache: true,
  });
