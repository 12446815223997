import { useQuery } from 'decorators/Query/useQuery';

import {
  PaginationRowCountOption,
  defaultPaginationRowCountOptions,
  PaginationRowCountValue,
} from 'components/Pagination/PaginationPageSizeSelector';

export type PaginationQueryArgs = {
  currentPageQueryParameter?: string;
  pageSizeQueryParameter?: string;
  paginationRowCountOptions?: PaginationRowCountOption[];
};

export type PaginationQuery = {
  currentPage: number;
  pageIndex: number;
  pageSizeOption: PaginationRowCountOption;
  pageSize: PaginationRowCountValue;
  offset: number;
  limit?: number;
};

/**
 * Note! You cannot use this directly in a Storybook story. You need to call it in a component.
 */
export function usePaginationQuery({
  currentPageQueryParameter = 'page',
  pageSizeQueryParameter = 'items',
  paginationRowCountOptions = defaultPaginationRowCountOptions,
}: PaginationQueryArgs = {}): PaginationQuery {
  const { query } = useQuery();

  const pageSizeOption =
    paginationRowCountOptions.find(option => option.label.toLowerCase() === query[pageSizeQueryParameter]) ??
    paginationRowCountOptions[0];

  const pageSize = pageSizeOption.value;

  const currentPage = Number(query[currentPageQueryParameter] ?? 1);
  const pageIndex = currentPage - 1;

  const offset = pageSize ? (currentPage - 1) * pageSize : 0;
  const limit = pageSize ?? undefined;

  return {
    currentPage,
    pageSizeOption,
    pageIndex,
    pageSize,
    offset,
    limit,
  };
}
