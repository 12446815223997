import React from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import { getPerformanceStatus, performanceColors } from 'utils/Data/performance';

import OPICardTrend from './OPICardTrend';
import GaugeChart from 'components/Charts/GaugeChart';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--size-xxs);
  /* if there is no gap, add same amount of padding */
  ${props => (!props.hasTrend ? 'padding-top: var(--size-xxs);' : '')}
  align-items: center;
  justify-content: center;
  min-height: 70px;
`;

const getValueColor = props => {
  if (props.invalid) {
    return 'var(--passive-color)';
  } else if (performanceColors(props)[props.status]) {
    return performanceColors(props)[props.status];
  } else if (props.isNegative) {
    return 'var(--alarm-color)';
  } else if (props.isPositive) {
    return 'var(--success-color)';
  }
  return 'var(--body-text-fg)';
};

const ValueWrapper = styled.div`
  display: flex;
  gap: var(--size-xxs);
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => getValueColor(props)};

  ${props => props.theme.media.desktop`
    font-size: ${props => props.theme.font.size.xl};
  `}
`;

const Value = styled.span``;

const Unit = styled.span`
  font-size: ${props => props.theme.fontSize.xxs};
  padding-top: 2px;
`;

const StringGaugeValue = styled.span`
  color: var(--supplemental-text-fg);
`;

const formatNumber = number => {
  if (number === 0) {
    return number.toFixed(0);
  }
  if (number < 10) {
    return number.toFixed(1);
  }
  if (number < 1000) {
    return number.toFixed(0);
  }
  return `${+(number / 1000).toFixed(1)}k`;
};

const OPICardContent = ({ content, contentWithCircle }) => {
  const theme = useTheme();

  if (content && content.value !== undefined) {
    const { value, unit, trend, status, isInvalid, isNegative, isPositive } = content;

    const performanceColorsStatus =
      typeof status === 'string' ? status : !isNil(status) && getPerformanceStatus(status);

    const hasTrend = Number.isFinite(trend);

    return (
      <Content hasTrend={hasTrend}>
        {hasTrend && <OPICardTrend trend={trend} unit="%" hasInvertedTrend showPlusSign />}
        <ValueWrapper
          status={performanceColorsStatus}
          invalid={isInvalid}
          isPositive={isPositive}
          isNegative={isNegative}
        >
          <Value>{Number.isFinite(value) ? formatNumber(value) : value}</Value>
          {unit && <Unit>{unit}</Unit>}
        </ValueWrapper>
      </Content>
    );
  }
  if (contentWithCircle && contentWithCircle.value !== undefined) {
    const { value, status, neutral, isAirQuality } = contentWithCircle;

    const performanceColorsStatus = status || (!isNil(value) && getPerformanceStatus(value, isAirQuality));
    const roundedValue = Math.round(value);
    let stops = neutral && [[0, 'var(--opi-card-gauge-fg)']];
    stops = isAirQuality || status ? [[0, performanceColors({ theme })[performanceColorsStatus]]] : stops;

    return (
      <Content>
        <GaugeChart
          size={70}
          value={roundedValue}
          min={0}
          max={100}
          unit="%"
          valueColor={(!neutral && performanceColors({ theme })[performanceColorsStatus]) || 'var(--body-text-fg)'}
          background="var(--opi-card-gauge-bg)"
          backgroundPadding={0}
          lineWidth={22}
          hidePlus
          stops={stops}
        >
          {isNaN(value) ? <StringGaugeValue>N/A</StringGaugeValue> : undefined}
        </GaugeChart>
      </Content>
    );
  }
  return null;
};

OPICardContent.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    trend: PropTypes.number,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isInvalid: PropTypes.bool,
    isNegative: PropTypes.bool,
    isPositive: PropTypes.bool,
  }),
  contentWithCircle: PropTypes.shape({
    value: PropTypes.number,
    status: PropTypes.string,
    neutral: PropTypes.bool,
    isAirQuality: PropTypes.bool,
  }),
};

export default OPICardContent;
