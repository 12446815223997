import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { StyledDialogBody } from '../Dialog/DialogBody';

export type StyledSensorProps = {
  id?: string;
  fullScreen?: boolean;
  large: boolean;
};

const StyledSensor = styled(StyledDialogBody)<StyledSensorProps>`
  padding: 0;
  background-color: var(--dialog-base-bg);
  transition: none;
`;
StyledSensor.displayName = 'StyledSensor';

export type SensorProps = PropsWithChildren<{
  fullScreen?: boolean;
  id?: string;
}>;

export function Sensor({ fullScreen, children, id }: SensorProps) {
  return (
    <StyledSensor id={id} large fullScreen={fullScreen}>
      {children}
    </StyledSensor>
  );
}
