import React, { lazy, Suspense } from 'react';
import { FullScreenLoader } from 'components/Loader/Loader';

const FunctionalLocation = lazy(() =>
  import(
    /* webpackChunkName: "functional-location" */
    'containers/Application/FunctionalLocation/FunctionalLocation'
  )
);

const LazyLoadedFunctionalLocation = props => (
  <Suspense fallback={<FullScreenLoader size="LARGE" />}>
    <FunctionalLocation {...props} />
  </Suspense>
);

export default LazyLoadedFunctionalLocation;
