import { combineReducers } from 'redux';
import airQuality from './values/air_quality';
import cleaning from './values/cleaning';
import conditions from './values/conditions';
import consumption from './values/consumption';
import energyRating from './values/energy_rating';
import flEnergyValues from './values/fl_energy_values';
import sensors from './values/sensor_values';
import recycling from './values/recycling';

export default combineReducers({
  airQuality,
  cleaning,
  conditions,
  consumption,
  energyRating,
  flEnergyValues,
  sensors,
  recycling,
});
