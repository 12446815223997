import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { getSortedRowModel, PartialKeys, Table, TableOptions } from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core/build/lib/types';
import { VirtualizerOptions } from '@tanstack/virtual-core';

import { GenericWindowVirtualizedTableProps } from 'components/VirtualizedTable/GenericWindowVirtualizedTable';
import { usePaginatedVirtualizedTable } from 'components/VirtualizedTable/usePaginatedVirtualizedTable';
import { RichWindowVirtualizedTable } from 'components/VirtualizedTable/RichWindowVirtualizedTable';
import { QueryParameterPaginationProps } from 'components/Pagination/QueryConnectedPagination';
import { TableWindowVirtualizer } from 'components/VirtualizedTable/useVirtualizedTable';
import { useVirtualizedTableScrollMargin } from 'components/VirtualizedTable/useVirtualizedTableScrollMargin';

export type PaginatedDataTableProps<TData extends RowData> = {
  tableOptions: PartialKeys<TableOptions<TData>, 'getCoreRowModel' | 'getPaginationRowModel'>;
  virtualizerOptions?: Partial<VirtualizerOptions<Window, HTMLTableRowElement>>;
  paginationProps?: Partial<QueryParameterPaginationProps>;

  estimatedRowSize?: number;

  enableColumnResizing?: boolean;
  enableRowSelection?: boolean;

  genericTableProps?: Omit<GenericWindowVirtualizedTableProps<TData>, 'table' | 'virtualizer'>;

  tableRef?: MutableRefObject<Table<TData> | null>;
  virtualizerRef?: MutableRefObject<TableWindowVirtualizer | null>;
};

/**
 * Simple table component to display data from an array. If you need to connect to remote data,
 * see {@link RemotePaginatedDataTable} instead.
 *
 * @implements {UIv3}
 *
 * @param tableOptions Table options.
 *   See {@link https://tanstack.com/table/v8/docs/adapters/react-table}.
 * @param virtualizerOptions Additional virtualizer options, e.g. `scrollPaddingStart`.
 * @param paginationProps Any props to pass to the {@link QueryConnectedPagination}, e.g. query parameter names.
 *   For controlled/fetching pagination add at least field `paginationProps.totalItems`.
 * @param estimatedRowSize Defaults to 40 (pixels).
 *
 * @param enableColumnResizing Allow column resizing. If you need to e.g. save the column
 *   widths, override `onColumnSizingChange` in `tableOptions` as instructed in
 *   {@link https://tanstack.com/table/v8/docs/api/features/column-sizing#oncolumnsizingchange}.
 * @param enableRowSelection Allow row selection.
 *   For the selecting to work, add a selection checkbox column (for example generated using
 *   {@link generateSelectColumn}) or some other mechanism to actually make the selections.
 *   State is kept internally, but if it is needed outside, override `tableOptions.onRowSelectionChange`.
 *   NOTE: "Select all" functionality selects, by default, all rows on all pages!
 *
 * @param genericTableProps Any props to pass to `<GenericWindowVirtualizedTable>`, e.g. cell or row renderers.
 *
 * @param tableRef Optional reference that will be set to the table instance.
 *   This is an escape hatch to access the React Table API. Recommended approach
 *   is to write a new component, similar to this, instead, but this can serve
 *   in simple cases.
 * @param virtualizerRef Optional reference that will be set to the virtualizer instance.
 *   This is an escape hatch to access the React Table API. Recommended approach
 *   is to write a new component, similar to this, instead, but this can serve
 *   in simple cases.
 */
export function PaginatedDataTable<TData extends RowData>({
  tableOptions,
  virtualizerOptions,
  paginationProps,
  estimatedRowSize = 40,

  enableColumnResizing = true,
  enableRowSelection = true,

  genericTableProps,

  tableRef,
  virtualizerRef,
}: PaginatedDataTableProps<TData>) {
  const scrollTargetRef = useRef<HTMLDivElement | null>(null);
  const { containerRef, scrollMargin } = useVirtualizedTableScrollMargin();
  const [scrollPaddingStart, setScrollPaddingStart] = useState(0);
  const userDefinedScrollPadding = virtualizerOptions?.scrollPaddingStart;
  useLayoutEffect(() => {
    const firstTableRow = scrollTargetRef.current?.querySelector<HTMLTableRowElement>('tbody tr:first-child');
    const builtinScrollPadding = firstTableRow?.offsetTop ?? 0;
    setScrollPaddingStart(userDefinedScrollPadding ?? 0 + builtinScrollPadding);
  }, [userDefinedScrollPadding]);

  const virtualizerOptionsWithDefaults = {
    estimateSize: () => estimatedRowSize,
    scrollMargin,
    ...virtualizerOptions,
    scrollPaddingStart,
  };

  const tableOptionsWithDefaults: PartialKeys<TableOptions<TData>, 'getCoreRowModel' | 'getPaginationRowModel'> = {
    enableColumnResizing,
    columnResizeMode: 'onChange' as const, // Resize columns while dragging

    enableRowSelection,

    enableSorting: true,
    getSortedRowModel: getSortedRowModel(),

    ...tableOptions,
  };

  const [table, virtualizer] = usePaginatedVirtualizedTable(
    tableOptionsWithDefaults,
    virtualizerOptionsWithDefaults,
    paginationProps
  );

  if (tableRef) {
    tableRef.current = table;
  }

  if (virtualizerRef) {
    virtualizerRef.current = virtualizer;
  }

  return (
    <RichWindowVirtualizedTable
      table={table}
      virtualizer={virtualizer}
      containerRef={containerRef}
      scrollTargetRef={scrollTargetRef}
      paginationProps={paginationProps}
      genericTableProps={genericTableProps}
    />
  );
}
