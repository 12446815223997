import React from 'react';

const PrintIcon = () => (
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      d="M29.9,6.4H27V2.1C27,1,26.1,0,24.9,0H7.1C5.9,0,5,1,5,2.1v4.3H2.1C1,6.4,0,7.4,0,8.5v16.4C0,26.1,1,27,2.1,27H5v2.8
    C5,31,5.9,32,7.1,32h17.8c1.2,0,2.1-1,2.1-2.1V27h2.8c1.2,0,2.1-1,2.1-2.1V8.5C32,7.4,31,6.4,29.9,6.4z M6.4,2.1
    c0-0.4,0.3-0.7,0.7-0.7h17.8c0.4,0,0.7,0.3,0.7,0.7V10H6.4V2.1z M1.4,8.5c0-0.4,0.3-0.7,0.7-0.7H5V10H1.4V8.5z M25.6,29.9
    c0,0.4-0.3,0.7-0.7,0.7H7.1c-0.4,0-0.7-0.3-0.7-0.7V14.9h19.2V29.9z M30.6,24.9c0,0.4-0.3,0.7-0.7,0.7H27V14.9h0.7
    c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7H4.3c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7H5v10.7H2.1c-0.4,0-0.7-0.3-0.7-0.7V11.4h29.2
    V24.9z M30.6,10H27V7.8h2.8c0.4,0,0.7,0.3,0.7,0.7V10z M9.6,19.2c0-0.4,0.3-0.7,0.7-0.7h11.4c0.4,0,0.7,0.3,0.7,0.7
    c0,0.4-0.3,0.7-0.7,0.7H10.3C9.9,19.9,9.6,19.6,9.6,19.2z M9.6,22.8c0-0.4,0.3-0.7,0.7-0.7h11.4c0.4,0,0.7,0.3,0.7,0.7
    c0,0.4-0.3,0.7-0.7,0.7H10.3C9.9,23.5,9.6,23.1,9.6,22.8z M9.6,26.3c0-0.4,0.3-0.7,0.7-0.7h11.4c0.4,0,0.7,0.3,0.7,0.7
    c0,0.4-0.3,0.7-0.7,0.7H10.3C9.9,27,9.6,26.7,9.6,26.3z"
    />
  </svg>
);

export default PrintIcon;
