import React from 'react';
import translations from 'decorators/Translations/translations';
import HeaderBuildingDescription from './ModalHeaderBuildingDescription';

import * as Styled from './ModalHeader.styled';
import ServiceOrderHeaderSkeleton from './ModalHeaderSkeleton';
import { TranslationFunction } from 'decorators/Translations/TranslationFunction';
import StatusBar from 'containers/Application/ServiceOrder/ServiceOrderHeader/StatusBar';

export type FunctionalLocation = {
  name: string;
  functionalLocation: string;
  description: string;
  address: string;
  postalCode: string;
  city: string;
};

export type Order = {
  externalType: string;
  status: string;
  priority: number;
};

export type StatusColorMap = {
  [key: string]: string;
};

interface ModalHeaderProps {
  title: string;
  children: React.ReactChild;
  loading: boolean;
  locationLink: string;
  functionalLocation: FunctionalLocation;
  order?: Order;
  t: TranslationFunction;
  statusColors: StatusColorMap;
}

export const ServiceOrderHeader = (props: ModalHeaderProps) => {
  const { title, children, loading } = props;

  if (loading) {
    return <ServiceOrderHeaderSkeleton />;
  }

  return (
    <Styled.TitleContainer>
      <HeaderBuildingDescription {...props} />
      <Styled.Title>
        <h2>{title}</h2>
        {children}
      </Styled.Title>
      <StatusBar {...props} loadingOrder={props.loading} />
    </Styled.TitleContainer>
  );
};

export default translations(ServiceOrderHeader);
