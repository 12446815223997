import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';
import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import RescuePlanService from 'services/rescuePlan';
import RescuePlanModule from 'containers/Application/Modules/RescuePlanModule/RescuePlanModule';

export const PublicView = ({ match }) => {
  const id = match?.params?.id;
  const { t, selectedLanguage, setSelectedLanguage } = useUnauthenticatedTranslations();
  const [loading, setLoading] = useState();
  const [info, setInfo] = useState([]);
  const [error, setError] = useState();

  const fetchInfo = async id => {
    try {
      setLoading(true);
      const rescueInfo = await RescuePlanService.infoById(id);
      setInfo([rescueInfo]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchInfo(id);
  }, [id]);

  if (!id || (loading === false && !info?.length)) {
    return (
      <Anonymous title={t('Page not found')}>
        <AnonymousHeader />
        <ErrorPageContent title={t('Page not found')} />
      </Anonymous>
    );
  }

  if (info.length) {
    return (
      <Anonymous title={t('Pelsu')}>
        <AnonymousHeader>
          <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
        </AnonymousHeader>
        <RescuePlanModule publicInfo={info} publicUserLanguage={selectedLanguage} />
      </Anonymous>
    );
  }

  return null;
};

PublicView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PublicView;
