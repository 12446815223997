export const percentage = (count: number, total: number) => (total ? (count / total) * 100 : 0);

export const weightedMean = <
  ValueFieldType extends string,
  WeightFieldType extends string,
  ItemType extends Record<ValueFieldType | WeightFieldType, number>
>(
  items: ItemType[],
  valueField: ValueFieldType,
  weightField: WeightFieldType
) => {
  let sum = 0;
  let count = 0;
  items.forEach(item => {
    sum += item[valueField] * item[weightField];
    count += item[weightField];
  });
  return sum / count;
};

export function roundToDecimals(value: number, decimals: number): number {
  const coefficient = +`1e${decimals}`;
  return +(Math.round(value * coefficient) / coefficient);
}
