const API_REQUESTS_CACHE_KEY = 'apiRequests';
const DEFAULT_CACHE_EXPIRATION_MS = 5 * 60 * 1000;

let allCachingDisabled;
try {
  allCachingDisabled = localStorage.getItem('disableApiRequestCaching') === 'true';
} catch (error) {
  allCachingDisabled = false;
}

export const isCachingDisabled = () => allCachingDisabled;

export const disableCaching = (value = true) => {
  allCachingDisabled = value;
  try {
    localStorage.setItem('disableApiRequestCaching', String(value));
  } catch (error) {
    console.error('Could not store disable caching state', error);
  }
};

/**
 * Call window.disableCaching(true/false) in dev tools console
 * to disable/enable request caching for development purposes.
 *
 * This was needed since Chrome devtools "disable cache" flag
 * did not seem to disable our usage of CacheStorage.
 */
window.disableCaching = disableCaching;

export const isCachingPossible = method => !isCachingDisabled() && typeof caches !== 'undefined' && method === 'GET';

export const isCachedResponseExpired = (response, cacheExpirationTimeMs = DEFAULT_CACHE_EXPIRATION_MS) => {
  // Api response must have Access-Control-Expose-Headers: Date for us to be able to read date header (having cors)
  const dateHeader = response?.headers.get('date');
  if (!dateHeader) {
    return true;
  }
  const responseTimestamp = new Date(dateHeader).getTime();
  return responseTimestamp < Date.now() - cacheExpirationTimeMs;
};

export const getCachedResponse = async (request, cacheExpirationTimeMs) => {
  if (!isCachingPossible(request.method)) {
    return undefined;
  }
  try {
    const cache = await caches.open(API_REQUESTS_CACHE_KEY);
    const cachedResponse = await cache.match(request);
    if (cachedResponse && !isCachedResponseExpired(cachedResponse, cacheExpirationTimeMs)) {
      console.debug('Using cached response', cachedResponse?.url);
      return cachedResponse;
    }
  } catch (error) {
    console.error('Could not get cached response', error);
  }
  return undefined;
};

export const cacheResponse = async (request, response) => {
  if (response?.ok && isCachingPossible(request.method)) {
    try {
      const cache = await caches.open(API_REQUESTS_CACHE_KEY);
      console.debug('Caching request', request?.url);
      return cache.put(request, response.clone());
    } catch (error) {
      console.error('Could not cache response', error);
    }
  }
  return undefined;
};

export const invalidateCache = async (requestOrUrl, options = {}) => {
  const cache = await caches.open(API_REQUESTS_CACHE_KEY);
  const foundAndDeletedCache = cache.delete(requestOrUrl, {
    ignoreSearch: false,
    ignoreMethod: false,
    ignoreVary: true,
    ...options,
  });
  return foundAndDeletedCache;
};

export const invalidateCachesWithUrlMatch = async urlSearchText => {
  try {
    const deleteOptions = {
      ignoreSearch: true,
      ignoreMethod: true,
      ignoreVary: true,
    };
    const cache = await caches.open(API_REQUESTS_CACHE_KEY);
    const keys = await cache.keys();
    const matchingRequests = keys.filter(request => request?.url?.includes(urlSearchText));
    const deletePromises = matchingRequests.map(request => cache.delete(request, deleteOptions));
    await Promise.allSettled(deletePromises);
  } catch (error) {
    // Silently catch errors in cache invalidation, better use caches than break the application
    console.error('Cache invalidation failed', error);
  }
};
