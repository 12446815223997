import React from 'react';
import PropTypes from 'prop-types';
import EnergyFilterInputs from './EnergyFilterInputs';
import EnergyFilterRow from './EnergyFilterRow';
import SideFilter from 'components/SideFilter/SideFilter';

const EnergyFilter = ({
  t,
  loading,
  filtersOpen,
  filter,
  selectedFilterValues,
  toggleFilterClick,
  handleFilterChange,
}) => (
  <React.Fragment>
    <EnergyFilterRow
      t={t}
      toggleFilterClick={toggleFilterClick}
      loading={loading}
      handleFilterRemove={handleFilterChange}
      selectedFilterValues={selectedFilterValues}
    />

    <SideFilter t={t} useSideToggle={false} isOpen={filtersOpen} toggleFilterClick={toggleFilterClick} filtersOn={true}>
      {!loading && (
        <EnergyFilterInputs
          t={t}
          filter={filter}
          selectedFilterValues={selectedFilterValues}
          handleSelectDropdown={handleFilterChange}
        />
      )}
    </SideFilter>
  </React.Fragment>
);

EnergyFilter.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  filter: PropTypes.array.isRequired,
  selectedFilterValues: PropTypes.object.isRequired,
  toggleFilterClick: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default React.memo(EnergyFilter);
