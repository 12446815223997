import React from 'react';
import { StylesConfig, GroupBase, components, ActionMeta, OnChangeValue, InputActionMeta } from 'react-select';
import { DefaultTheme, useTheme } from 'styled-components';

import { useTranslations } from 'decorators/Translations/translations';

import { SelectInputV5 } from 'components/Select/SelectInputV5';
import { ValueContainer } from './ValueContainer';
import { MultiSelectOption } from './MultiSelectOption';
import { MultiValue } from './MultiValue';

export function selectFilterStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  theme: DefaultTheme
): StylesConfig<Option, IsMulti, Group> {
  return {
    control: (baseStyles, { isFocused, isDisabled }) => ({
      ...baseStyles,
      width: '100%',
      flexWrap: 'nowrap',
      position: 'relative',
      ...(isDisabled && { backgroundColor: 'var(--input-bg-disabled)' }),
      height: 'var(--input-height)',
      ...(isFocused && {
        // this needs to be boder, borderColor doesn't work
        border: '1px solid var(--input-bc-active)',
        // this style can be moved to ValueContainer when it gets isFocused prop straight or through selectProps
        '.filter-label': {
          transform: 'translateY(0)',
        },
      }),
    }),
    valueContainer: (baseStyles, { isMulti }) => ({
      ...baseStyles,
      ...(isMulti && { display: 'grid' }),
      marginTop: `calc(${theme.font.lineHeight.md} * ${theme.font.size.xxs})`,
      width: '100%',
    }),
    input: (baseStyles, { isMulti, value }) => ({
      ...baseStyles,
      marginLeft: 0,
      margin: 0,
      padding: 0,
      ...(isMulti && !!value && { backgroundColor: 'var(--input-bg)' }),
    }),
    singleValue: baseStyles => ({
      ...baseStyles,
      fontSize: theme.font.size.xs,
      lineHeight: theme.font.lineHeight.md,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    multiValue: () => ({
      gridArea: '1/1/2/3',
      overflow: 'hidden',
    }),
    multiValueLabel: () => ({
      fontSize: theme.font.size.xs,
      lineHeight: theme.font.lineHeight.md,
      color: 'var(--input-option-fg)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    multiValueRemove: () => ({
      display: 'none',
    }),
    loadingIndicator: () => ({
      display: 'none',
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderTopColor: 'var(--input-bc-active)',
    }),
  };
}

export type SelectFilterOptionType = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type SelectFilterProps<OptionType, IsMulti extends boolean> = {
  label: string;
  options: OptionType[];
  value: IsMulti extends true ? OptionType[] : OptionType;
  isMulti: IsMulti;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (option: OnChangeValue<OptionType, IsMulti>, actionMeta: ActionMeta<SelectFilterOptionType>) => void;
  onInputChange: (newValue: string, actionMeta: InputActionMeta) => void;
  onMenuOpen: () => void;
  menuIsOpen?: boolean;
  defaultValue?: OptionType;
  isAllSelected: boolean;
  placeholder?: string;
};

/**
 * Single and multi select filter component
 *
 * @implements UIv3
 */
export function SelectFilter<OptionType extends SelectFilterOptionType, IsMulti extends boolean>(
  props: SelectFilterProps<OptionType, IsMulti>
): JSX.Element {
  const {
    label,
    options,
    value,
    isMulti,
    isLoading,
    isDisabled,
    onChange,
    onInputChange,
    onMenuOpen,
    menuIsOpen,
    defaultValue,
    isAllSelected,
    placeholder,
  } = props;
  const [t] = useTranslations();
  const theme = useTheme();

  const toggleAllOption = {
    value: 'toggle-select-all',
    label: isAllSelected ? t('Deselect All') : t('Select All'),
  } as OptionType;
  const filterOptions: OptionType[] = !isLoading && isMulti ? [toggleAllOption, ...options] : options;

  return (
    <SelectInputV5<OptionType, IsMulti>
      options={filterOptions}
      value={value}
      isMulti={isMulti}
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={onChange}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      menuIsOpen={menuIsOpen}
      defaultValue={defaultValue}
      closeMenuOnSelect={!isMulti}
      noOptionsMessage={() => t('No results found')}
      styles={selectFilterStyles(theme)}
      components={{
        ValueContainer,
        Option: isMulti ? MultiSelectOption : components.Option,
        MultiValue,
      }}
      isClearable
      maxMenuHeight={180}
      isSearchable
      placeholder={placeholder || null}
      hideSelectedOptions={false}
      tabSelectsValue={false}
      label={label}
    />
  );
}
