import React from 'react';

import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';
import LanguageSelector from '../Technician/LanguageSelector/LanguageSelector';
import AdditionalSaleContainer from './AdditionalSaleContainer';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';

const AdditionalSalePage = () => {
  const { t, selectedLanguage, setSelectedLanguage } = useUnauthenticatedTranslations();

  return (
    <Anonymous title={t('Additional Sale')}>
      <AnonymousHeader>
        <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
      </AnonymousHeader>
      <AdditionalSaleContainer />
    </Anonymous>
  );
};

export default AdditionalSalePage;
