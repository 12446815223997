const clearAnnotations = chart => {
  chart.annotations.forEach(annotation => annotation.destroy());
  chart.annotations.length = 0;
};

const addAnnotations = (chart, annotations) => annotations.forEach(annotation => chart.addAnnotation(annotation));

const updateChartAnnotations = (chart, annotations) => {
  if (annotations) {
    clearAnnotations(chart);
    addAnnotations(chart, annotations);
  }
};

export default updateChartAnnotations;
