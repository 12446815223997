import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import InputLabel from 'components/Form/InputLabel';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { formatOpeningHours } from 'utils/Data/performance';

const Header = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sensorValues.padding.mobile} ${props => props.theme.sensorValues.padding.mobile} 0;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;

  ${props => props.theme.media.portrait`
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: ${props => props.theme.sensorValues.padding.tablet} ${props =>
    props.theme.sensorValues.padding.tablet} 0;
    `}

  ${props => props.theme.media.desktop`
        padding: ${props => props.theme.sensorValues.padding.desktop} ${props =>
    props.theme.sensorValues.padding.desktop} 0;
    `}
`;
Header.displayName = 'Header';

const SensorHeaderValue = styled.span`
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.color};

  ${props => props.theme.media.desktop`
        white-space: nowrap;
    `};
`;
SensorHeaderValue.displayName = 'SensorHeaderValue';

const SensorTitleWrapper = styled.div``;
SensorTitleWrapper.displayName = 'SensorTitleWrapper';

const InlineWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const SensorTitle = styled.h3`
  word-wrap: break-word;
  color: ${props => props.theme.colors.black};
  ${props => (props.hasValue ? 'margin-right: var(--size-sm);' : '')};
  font-size: ${props => props.theme.font.size.lg};

  &::first-letter {
    text-transform: uppercase;
  }
`;
SensorTitle.displayName = 'SensorTitle';

const DropdownWrapper = styled.div`
  width: 300px;
`;
DropdownWrapper.displayName = 'DropdownWrapper';

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${props => props.theme.media.portrait`
        flex-wrap: nowrap;
    `};
`;
SelectWrapper.displayName = 'SelectWrapper';

const StyledInputLabel = styled(InputLabel)`
  margin-right: var(--size-md);
`;
StyledInputLabel.displayName = 'StyledInputLabel';

const SensorInfo = styled.p`
  color: var(--body-text-fg);
  font-size: ${props => props.theme.font.size.xs};
`;
SensorInfo.displayName = 'SensorInfo';

const SensorHead = props => {
  const {
    sensorTitle,
    sensorValue,
    sensorValueColor,
    sensorType,
    loading,
    t,
    sensor,
    sensorValueTime,
    category,
    utilizationHours,
    statisticsOptions,
    onStatisticsChange,
    model,
    contextualHelp,
    openingHours,
    timezone,
  } = props;

  const hasValue = !isNil(sensorValue);
  const hasCategory = category && (category.type === 'floor' || category.type === 'group');
  const hasUtilization = !!utilizationHours;

  return (
    <Header>
      <SensorTitleWrapper>
        <InlineWrapper>
          <SensorTitle hasValue={hasValue}>
            {`${sensorTitle}${hasValue ? ':' : ''}`}
            {hasValue && (
              <SensorHeaderValue color={sensorValueColor}>&nbsp;{!loading && sensorValue}</SensorHeaderValue>
            )}
          </SensorTitle>

          {(sensorType || contextualHelp) && (
            <ContextualHelp
              title={contextualHelp || sensorType.name}
              text={`${CTXHELP_PREFIX} ${contextualHelp || `sensorType:${sensorType.name}`}`}
              position="bottom"
            />
          )}
        </InlineWrapper>

        <SensorInfo>
          {sensor.id && (
            <React.Fragment>
              <b>{t('ID')}:</b> {sensor.id}
            </React.Fragment>
          )}

          {hasUtilization && (
            <React.Fragment>
              {sensor.id && <span>,&nbsp;</span>}
              <b>{t('Utilization')}:</b>&nbsp;
              {t('Mon-Fri')} {utilizationHours.join('-')}
            </React.Fragment>
          )}

          {hasCategory && (
            <React.Fragment>
              ,&nbsp;
              <b>{t(category.type === 'floor' ? 'Floor' : 'Group')}:</b>&nbsp;
              {category.shortName || category.name}
            </React.Fragment>
          )}

          {sensorValueTime && (
            <React.Fragment>
              ,&nbsp;
              <b>{t('Latest value')}:</b> {sensorValueTime}
            </React.Fragment>
          )}

          {openingHours && (
            <React.Fragment>
              , <b>{t('Opening hours')}:</b> {formatOpeningHours(openingHours)}
              {timezone && ` (${timezone})`}
            </React.Fragment>
          )}
        </SensorInfo>
      </SensorTitleWrapper>

      {!isEmpty(statisticsOptions) && (
        <SelectWrapper>
          <StyledInputLabel text={t('Statistics type')} />
          <DropdownWrapper>
            <InputSelectDropdown
              onChange={onStatisticsChange}
              options={statisticsOptions}
              property="statistics"
              model={model}
              t={t}
              clearable={false}
            />
          </DropdownWrapper>
        </SelectWrapper>
      )}
    </Header>
  );
};

export default SensorHead;

SensorHead.defaultProps = {
  sensorValue: null,
  sensorValueColor: undefined,
  loading: false,
  onSensorChange: undefined,
  sensorOptions: [],
  noSubsensors: false,
};

SensorHead.propTypes = {
  // required:
  sensorTitle: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  sensor: PropTypes.object.isRequired,
  // optional:
  sensorType: PropTypes.object,
  sensorValue: PropTypes.string,
  sensorValueColor: PropTypes.string,
  sensorValueTime: PropTypes.string,
  loading: PropTypes.bool,
  onStatisticsChange: PropTypes.func,
  statisticsOptions: PropTypes.array,
  category: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    shortName: PropTypes.string,
  }),
  utilizationHours: PropTypes.array,
  contextualHelp: PropTypes.string,
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  timezone: PropTypes.string,
  model: PropTypes.object,
};
