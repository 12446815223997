import filter from 'lodash/filter';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import { getFLsByFilterMatchedBuildingMeta } from 'containers/Application/Energy/EnergyUtil';
import { percentage } from 'utils/math';
import { functionalLocationName } from 'utils/Data/functionalLocations';

export const REMOTE_CENTER_TABS = {
  ALARMS: 'Alarms',
  OBSERVATIONS: 'Observations',
  INSPECTIONS: 'Inspections',
};

export const getTabOptions = (features, t) => {
  const options = [];
  if (features?.controlRoomAlarms) {
    options.push({ value: REMOTE_CENTER_TABS.ALARMS, label: t('Alarms') });
  }
  if (features?.controlRoomObservations) {
    options.push({ value: REMOTE_CENTER_TABS.OBSERVATIONS, label: t('Observations') });
  }
  if (features?.controlRoomInspections) {
    options.push({ value: REMOTE_CENTER_TABS.INSPECTIONS, label: t('Inspections') });
  }
  return options;
};

export const EMPTY_ARRAY = [];

export const mapFls = (t, functionalLocations, partnerNumber, items, path) => {
  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/${path}`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const count = filter(items, item => includes(item.path, fl.functionalLocation)).length;
    return { title: functionalLocationName(t, fl), subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

export const mapBenchmarkToFls = (t, functionalLocations, partnerNumber, counts, path) => {
  const flList = functionalLocations.map(fl => {
    const to = `/${partnerNumber}/FunctionalLocation/${fl.functionalLocation}/RemoteCenter/${path}`;
    const address = (fl.address && fl.city && `${fl.address}, ${fl.city}`) || '';
    const count = counts?.[fl.functionalLocation] ?? 0;
    return { title: functionalLocationName(t, fl), subtitle: address, value: count, to };
  });
  return orderBy(flList, 'value', 'desc');
};

export const getPerformanceOPI = observations => {
  const total = observations ? observations.length : null;
  const completed = observations ? filter(observations, ['status.status', 'completed']).length : null;
  return {
    value: observations ? percentage(completed, total) : undefined,
    total,
    completed,
  };
};

export const getObservationCountsByFl = (t, counts = {}, functionalLocations, partnerNumber) => {
  if (!functionalLocations) {
    return EMPTY_ARRAY;
  }
  return mapBenchmarkToFls(t, functionalLocations, partnerNumber, counts, 'Observations');
};

export const getObservationCountsByGroupsAndMonths = (selectedYearObservations = [], groups, onlyCompleted) => {
  if (onlyCompleted) {
    selectedYearObservations = selectedYearObservations.filter(
      observation => observation.status.status === 'completed'
    );
  }
  const groupedChartObservations = groupBy(selectedYearObservations, observation =>
    groups.includes(observation.deviceGroup) ? observation.deviceGroup : 'other'
  );
  const months = [...Array(12).keys()];
  const groupedMonthlyObservationCounts = {};
  Object.keys(groupedChartObservations).forEach(group => {
    groupedMonthlyObservationCounts[group] = months.map(
      monthIndex =>
        groupedChartObservations[group].filter(observation => new Date(observation.timestamp).getMonth() === monthIndex)
          .length
    );
  });
  return groupedMonthlyObservationCounts;
};

export const filterFunctionalLocations = (functionalLocations, selectedMetaFilterValues, buildingMeta) => {
  if (isEmpty(selectedMetaFilterValues)) {
    return functionalLocations;
  }
  const functionalLocationIdsWithMatchingMeta = getFLsByFilterMatchedBuildingMeta(
    buildingMeta.meta,
    selectedMetaFilterValues
  );
  return functionalLocations.filter(fl => functionalLocationIdsWithMatchingMeta.includes(fl.functionalLocation));
};

export const filterObservationsByFunctionalLocations = (observations, filteredFunctionalLocations) => {
  const observationMatchesFilteredFls = observation =>
    filteredFunctionalLocations.some(fl => observation.path.includes(fl.functionalLocation));
  return Object.keys(observations).reduce((result, key) => {
    if (observations[key].data) {
      result[key] = {
        ...observations[key],
        data: observations[key]?.data?.filter(observationMatchesFilteredFls),
      };
    } else {
      result[key] = observations[key]?.filter(observationMatchesFilteredFls);
    }
    return result;
  }, {});
};

export const getAlarmCountSeries = (t, data = { total: [], handled: [] }) => [
  {
    name: t('Total'),
    data: data.total.map(valueObject => valueObject.value || 0),
    _unit: '',
    _showTooltipForZeroValue: true,
  },
  {
    name: t('Handled'),
    data: data.handled.map(valueObject => valueObject.value || 0),
    _unit: '',
  },
];
