import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { SERVICE_API_PATH } from './index';

export const equipmentFn = (request, params) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/equipment/find`,
    data: { ...params },
  });

export const equipmentTextsFn = (request, equipmentIds) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/equipment/texts`,
    data: { equipmentNumber: equipmentIds },
  });


export default wrapEachWithThunkRequest({
  equipment: equipmentFn,
  equipmentTexts: equipmentTextsFn,
});
