import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SecondaryButton, { StyledButton } from 'components/Button/SecondaryButton/SecondaryButton';
import Collapse from 'components/Collapse/Collapse';

import { useTranslations } from 'decorators/Translations/translations';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';

const ToggleFilters = styled(Collapse)`
  display: ${props => (props.showAllFilters ? 'flex' : 'none')};
  flex-direction: column;
  transition: visibility 0s, opacity 0.5s linear;

  ${props =>
    props.onlyInMobile &&
    props.theme.media.tablet`
    display: block;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  ${props => (props.hasMarginTop ? 'margin-top: var(--size-sm);' : '')}

  ${StyledButton} {
    flex-grow: 1;
  }
`;

const FilterCollapse = ({ children, onlyInMobile = false, initialIsCollapsed = true, className }) => {
  const [t] = useTranslations();
  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed);
  const breakpoints = useBreakpoints();

  const apply = !breakpoints.tablet || !onlyInMobile;

  useEffect(() => {
    apply && !isCollapsed && setIsCollapsed(!isCollapsed);
  }, [apply]);

  return (
    <div className={className}>
      <ToggleFilters isCollapsed={isCollapsed && apply} onlyInMobile={onlyInMobile} mountExpanded={!initialIsCollapsed}>
        {children}
      </ToggleFilters>
      {apply && (
        <ButtonContainer hasMarginTop={!isCollapsed}>
          <SecondaryButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? t('Show Filters') : t('Hide Filters')}
          </SecondaryButton>
        </ButtonContainer>
      )}
    </div>
  );
};

FilterCollapse.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onlyInMobile: PropTypes.bool,
  initialIsCollapsed: PropTypes.bool,
};

export default FilterCollapse;
