import styled from 'styled-components';

/**
 * For internal and external layout, use `Layout/*Container` components.
 *
 * @implements {UIv3}
 */
export const Box = styled.div`
  background-color: var(--box-bg);
  border: var(--box-border);
`;
