import MasterDataV2 from 'services/masterData';
import { createReducerFromMapping } from 'redux/utils/index';

const initialState = {
  urls: {},
  errors: {},
};

export const LOAD_FULL_URL = 'CUSTOMER_PLATFORM/Common/LOAD_FULL_URL';
export const LOAD_FULL_URL_SUCCESS = 'CUSTOMER_PLATFORM/Common/LOAD_FULL_URL_SUCCESS';
export const LOAD_FULL_URL_FAIL = 'CUSTOMER_PLATFORM/Common/LOAD_FULL_URL_FAIL';

export const loadFullUrl = id => async dispatch => {
  try {
    dispatch({ type: LOAD_FULL_URL, id });
    const result = await MasterDataV2.shortUrl(id);

    return dispatch({
      type: LOAD_FULL_URL_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FULL_URL_FAIL,
      id,
      error,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_FULL_URL]: (state, action) => ({
      ...state,
      errors: { ...state.errors, [action.id]: false },
    }),
    [LOAD_FULL_URL_SUCCESS]: (state, action) => ({
      ...state,
      urls: { ...state.urls, [action.result.id]: action.result.url },
    }),
    [LOAD_FULL_URL_FAIL]: (state, action) => ({
      ...state,
      errors: { ...state.errors, [action.id]: true },
    }),
  },
  initialState
);
