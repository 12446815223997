import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import useConfirmationModal from 'components/ConfirmationModal/useConfirmationModal';
import { deleteConfirmationMessage } from 'components/ConfirmationModal/ConfirmationModal';
import FlexTable from 'components/FlexTable/FlexTable';
import { getColumns } from './columns';

import { isVirtualFolder, isInVirtualFolder, allowDeletionInVirtualFolder } from 'utils/Data/documents';
import { hasBuildingAndPortfolioAdminTools } from 'utils/Data/profileData';
import { useFilters } from './useFilters';

const InfoText = styled.p`
  font-family: ${props => props.theme.fontFamily.text};
  font-size: ${props => (props.superSmall ? props.theme.fontSize.xxxs : props.theme.fontSize.xs)};
  color: var(--supplemental-text-fg);
  margin: var(--size-md);
`;

// create function that checks user's right to edit/create file or folder and returns actions accordingly
const getActions = (profile, onEdit, onDelete, onUpload) => item => {
  const actions = {};
  if (!isVirtualFolder(item) && (!isInVirtualFolder(item) || allowDeletionInVirtualFolder(item))) {
    if (hasBuildingAndPortfolioAdminTools(profile) || item.createdBy === profile.id) {
      if (!isInVirtualFolder(item)) {
        actions.Edit = onEdit;
      }
      actions.Delete = onDelete;
    }
    if (!isInVirtualFolder(item) && onUpload) {
      actions['Upload files'] = onUpload;
    }
  }
  return actions;
};

export const DocumentTable = props => {
  const {
    t,
    folders, // array of folders with children (sub folders)
    files, // flat array of files with folderId
    download,
    loading = false,
    onEdit,
    onDelete,
    onFolderEdit,
    onFolderDelete,
    onFolderFileUpload,
    onFolderOpen,
    profile,
    openAllFolders = false,
    openFolders,
    loadingFolders,
    filterString,
  } = props;

  const [DeleteFileConfirmationModal, openDeleteFileModal] = useConfirmationModal({
    message: t(deleteConfirmationMessage),
    additionalInfo: file => file.name,
    onConfirm: React.useCallback(file => onDelete(file.id), [onDelete]),
    waitForSuccessfulCompletion: true,
  });

  const [DeleteFolderConfirmationModal, openDeleteFolderModal] = useConfirmationModal({
    message: t(deleteConfirmationMessage),
    additionalInfo: folder => t('Everything inside folder {0} will be deleted', folder.name),
    onConfirm: React.useCallback(folder => onFolderDelete(folder.path), [onFolderDelete]),
    waitForSuccessfulCompletion: true,
  });

  const virtualFolders = React.useMemo(() => folders.filter(isVirtualFolder).map(folder => folder.type), [folders]);

  const onDownloadFile = React.useCallback((file, inline) => download(file, inline), [download]);
  const onDownloadFolder = React.useCallback(folder => download(null, false, folder), [download]);
  const getActionsForFile = React.useMemo(
    () => getActions(profile, onEdit, openDeleteFileModal),
    [profile, onEdit, openDeleteFileModal]
  );
  const getActionsForFolder = React.useMemo(
    () => getActions(profile, onFolderEdit, openDeleteFolderModal, onFolderFileUpload),
    [profile, onFolderEdit, openDeleteFolderModal, onFolderFileUpload]
  );

  const getFileData = React.useCallback(
    file => ({
      ...file,
      actions: getActionsForFile(file),
      isFolder: false,
      onDownload: onDownloadFile,
      downloadText: t('Download'),
    }),
    [getActionsForFile, onDownloadFile, t]
  );

  // folder.children are handled in getSubRows
  const getFolderData = React.useCallback(
    folder => ({
      ...folder,
      name: isVirtualFolder(folder) ? t(folder.name) : folder.name,
      actions: getActionsForFolder(folder),
      isFolder: true,
      isVirtualFolder: isVirtualFolder(folder),
      onDownload:
        !isVirtualFolder(folder) &&
        !folder.buildingId /* Check if item is from document api v3 disable download for now.*/ &&
        onDownloadFolder,
      onFolderOpen,
      downloadText: t('Download'),
      isLoading: Array.isArray(loadingFolders) && loadingFolders.includes(folder.id),
    }),
    [getActionsForFolder, onDownloadFolder, t, loadingFolders]
  );

  const fileData = React.useMemo(() => files.map(getFileData), [files, getFileData]);

  const { filteredFiles, filteredFolders, isFiltered } = useFilters(fileData, folders, filterString);

  const fileDataByFolder = React.useMemo(
    () => groupBy(filteredFiles, file => file.folderType || file.folderId || 'root'),
    [filteredFiles]
  );

  /**
   * react-table uses this to get row id
   * use real ids
   */
  const getRowId = React.useCallback(row => (row.isVirtualFolder ? row.type : row.id), []);

  /**
   * react-table uses this to get sub rows
   *
   * folder.children contains all sub folders
   * fileDataByFolder helps with sub files
   */
  const getSubRows = React.useCallback(
    row => {
      if (row.isLoading) {
        return [{ renderSkeleton: true }];
      }
      if (row.isFolder) {
        const key = getRowId(row);
        const subFolders = row.children?.map(getFolderData) || [];
        const subFiles = fileDataByFolder[key] || [];
        return subFolders.concat(subFiles);
      }
      return [];
    },
    [fileDataByFolder, getFolderData, getRowId, loadingFolders]
  );

  const columns = React.useMemo(() => getColumns(t), [t]);

  const root = React.useMemo(() => {
    const filesOnRoot = fileDataByFolder.root || [];
    const foldersOnRoot = filteredFolders.map(getFolderData);
    return foldersOnRoot.concat(filesOnRoot);
  }, [fileDataByFolder, filteredFolders, getFolderData]);

  return (
    <>
      {DeleteFileConfirmationModal && <DeleteFileConfirmationModal />}
      {DeleteFolderConfirmationModal && <DeleteFolderConfirmationModal />}
      <FlexTable
        t={t}
        loading={loading}
        columns={columns}
        data={root}
        initialSortBy={[{ id: 'modified', desc: false }]}
        getSubRows={getSubRows}
        getRowId={getRowId}
        expandAllRows={openAllFolders || isFiltered}
        expandRows={openFolders}
        onExpandRow={onFolderOpen}
      />
      {!loading && root.length === 0 && <InfoText>{t('No results found')}</InfoText>}
      {!loading && virtualFolders.length > 0 && (
        <InfoText superSmall>* {t('Automatically collects documents from sublevels')}.</InfoText>
      )}
    </>
  );
};

DocumentTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      adminOnly: PropTypes.bool,
      folderId: PropTypes.number,
      functionalLocation: PropTypes.string,
      id: PropTypes.number,
      mimeTypes: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
    })
  ),
  folders: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onFolderFileUpload: PropTypes.func,
  download: PropTypes.func,
  openAllFolders: PropTypes.bool,
  openFolders: PropTypes.array,
  loadingFolders: PropTypes.array,
  onFolderEdit: PropTypes.func,
  onFolderDelete: PropTypes.func,
  onFolderOpen: PropTypes.func,
  profile: PropTypes.object,
  filterString: PropTypes.string,
};

export default translations(DocumentTable);
