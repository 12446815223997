import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const SaveChangesButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large confirm {...buttonProps}>
    {t('Save changes')}
  </PrimaryButton>
);

SaveChangesButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SaveChangesButton;
