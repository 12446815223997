import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinnerSpin = keyframes`
  from    { transform:rotate(0deg);   }
  to      { transform:rotate(360deg); }
`;

const dashLength = {
  large: 0.9,
  medium: 1,
  small: 1.05,
};

function dashAdjust(key: string) {
  if (key in dashLength) {
    return dashLength[key as keyof typeof dashLength];
  }
  console.log('Unknown `#{$key}` in dashLength.');
  return dashLength.medium;
}

const Spinner = styled.span<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: inline-block;
  animation: ${spinnerSpin} 1s linear infinite;
  transform: translateZ(0);
`;

const Circle = styled.circle<{ color?: string; size: number; sizeVariant: string }>`
  fill: transparent;
  stroke-linecap: butt;
  stroke: ${props =>
    (props.color === loaderColor.BLUE && 'var(--blue-01)') ||
    (props.color === loaderColor.WHITE && 'var(--white)') ||
    (props.color === loaderColor.BLACK && 'var(--black)') ||
    (props.color === loaderColor.CERULEAN && 'var(--blue-02)') ||
    (props.color === loaderColor.GRAY && 'var(--grey-50)') ||
    'var(--brand-color)'};
  stroke-width: ${props => props.size / 8};

  /* Dash length 3/4 of the circumference of the circle, adjusted to size. */
  stroke-dasharray: ${props => (21 / 32) * props.size * Math.PI * dashAdjust(props.sizeVariant)};
  transform-origin: ${props => 0.5 * props.size}px ${props => 0.5 * props.size}px 0;
`;

const loaderSize = {
  LARGE: 48,
  MEDIUM: 36,
  SMALL: 16,
};

export const loaderColor = {
  BLUE: 'blue',
  WHITE: 'white',
  BLACK: 'black',
  GRAY: 'gray',
  CERULEAN: 'cerulean',
};

const sizeMap = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const getSizeVariant = (size: number) => {
  if (size >= loaderSize.LARGE) {
    return sizeMap.LARGE;
  }
  if (size <= loaderSize.SMALL) {
    return sizeMap.SMALL;
  }
  return sizeMap.MEDIUM;
};

const FullScreenLoaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type LoaderProps = {
  pixelSize?: number;
  size: keyof typeof loaderSize;
  color?: keyof typeof loaderColor | null;
  alt?: string;
};

const Loader: React.FC<LoaderProps> = props => {
  const size = props.pixelSize || loaderSize[props.size] || loaderSize.MEDIUM;
  const color = props.color ? loaderColor[props.color] : undefined;
  const alt = props.alt || 'Loading';

  return (
    <div data-test-id="Loader">
      <Spinner size={size}>
        <svg viewBox={`0 0 ${size} ${size}`}>
          <title>{alt}</title>
          <Circle
            cx={`${size / 2}`}
            cy={`${size / 2}`}
            r={`${(7 * size) / 16}`}
            size={size}
            color={color}
            sizeVariant={getSizeVariant(size)}
          />
        </svg>
      </Spinner>
    </div>
  );
};

export const FullScreenLoader: React.FC<LoaderProps> = props => (
  <FullScreenLoaderContainer>
    <Loader {...props} />
  </FullScreenLoaderContainer>
);

export default Loader;
