import styled, { css } from 'styled-components';
import { AdditionalSaleStatus } from './utils';

const openStripe = css`
  border-top: 4px solid ${props => props.theme.brandColors.turquoise01};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Container = styled.div`
  min-width: 100%;
  max-width: ${props => (props.small ? '768px' : '1200px')};
  padding: var(--size-xl);
  border: 0;
  background-color: ${props => props.theme.colors.white};
  ${props => props.status !== AdditionalSaleStatus.CLOSED && openStripe}
  ${props => props.theme.media.desktop`
    border: 1px solid ${props => props.theme.colors.silver};
    border-radius: 2px;
  `};
`;
Container.displayName = 'Container';

export const Title = styled.h3`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => (props.semibold ? props.theme.font.weight.semibold : props.theme.font.weight.bold)};
  margin-bottom: var(--size-md);
`;
Title.displayName = 'Title';

export const Subheader = styled.h5``;
Subheader.displayName = 'Subheader';

export const ContentSection = styled.div`
  margin-bottom: var(--size-md);

  ${Subheader} {
    margin-bottom: var(--size-md);
  }
`;
ContentSection.displayName = 'ContentSection';

export const GraySection = styled.div`
  padding: var(--size-xl);
  border: 1px solid ${props => props.theme.colors.silver};
  background-color: ${props => props.theme.colors.alabaster};
`;
GraySection.displayName = 'GraySection';

export const Description = styled.p`
  line-height: ${props => props.theme.font.lineHeight.md};
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => (props.grayText ? props.theme.colors.darkGray : props.theme.colors.black)};
  padding: var(--size-xs) 0;
`;
Description.displayName = 'Description';
