import fill from 'lodash/fill';
import pick from 'lodash/pick';
import entries from 'lodash/entries';
import fromPairs from 'lodash/fromPairs';
import toInteger from 'lodash/toInteger';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import countBy from 'lodash/countBy';
import sum from 'lodash/sum';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import constant from 'lodash/constant';
import times from 'lodash/times';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import trim from 'lodash/trim';
import lowerCase from 'lodash/lowerCase';
import round from 'lodash/round';
import startsWith from 'lodash/startsWith';
import memoizeOne from 'memoize-one';
import queryString from 'query-string';
import { set, parseISO } from 'date-fns';
import { format, getMonths, getMonthsShort } from 'utils/Date/dateFormatter';

import { FILTERABLE_FIELDS, DEFAULT_PRIORITY, GROUP_UNKNOWN, OrderStatus } from 'constants/maintenance';
import { serviceStatusSortOrder } from 'utils/Data/resolveServiceOrderStatus';
import { translatePriority } from 'utils/Data/serviceOrders';
import { findPartnerNumberForFL } from 'utils/Data/partners';
import { isCaverionAdminOrUserRole } from 'utils/Data/profileData';

export const getLoadingKey = (...args) => args.join(':');

export const emptyNotification = { type: '', visible: false, message: '' };

/**
 * Find partner number
 */
export const findPartnerNumberForMaintenance = ({
  match: {
    params: { functionalLocationId, partnerNumber },
  },
  functionalLocations,
  profile,
}) => {
  if (partnerNumber && partnerNumber !== 'all') {
    return partnerNumber;
  }
  return findPartnerNumberForFL(functionalLocations[functionalLocationId], profile);
};

/**
 * Parse query (memoized)
 */
export const parseQuery = memoizeOne(search => {
  return queryString.parse(search);
});

/**
 * Get selected months from query.
 */
export const getSelectedMonths = memoizeOne(
  query => {
    const months = Array.isArray(query.months) ? query.months : query.months ? [query.months] : [];

    return sortBy(months.map(toInteger));
  },
  (a, b) => isEqual(a[0].months, b[0].months)
);

/**
 * Get active filter from query.
 */
export const getActiveFilter = memoizeOne(
  query => {
    return pick(query, FILTERABLE_FIELDS);
  },
  (a, b) => isEqual(pick(a[0], FILTERABLE_FIELDS), pick(b[0], FILTERABLE_FIELDS))
);

/**
 * Get service order / planned maintenance counts for each month.
 */
export const getMonthlyCounts = orders => {
  const months = fill(Array(12), 0);
  orders.forEach(order => {
    const date = order.meta.filterable.date;
    if (!isNaN(date)) {
      months[date.getMonth()] += 1;
    }
  });
  return months;
};

/**
 * Get service order / planned maintenance / long term plan counts by status.
 */
export const getStatusCounts = memoizeOne(
  (calendarItems, sortOrder = serviceStatusSortOrder, ignoreStatusesFromTotal = []) => {
    if (calendarItems) {
      const countsByStatus = countBy(calendarItems, 'status') || 0;
      const counts = sortOrder.map(status => countsByStatus[status] || 0);
      const totalStatusCounts = sum(counts);
      const total =
        calendarItems.length -
        ignoreStatusesFromTotal.reduce((sum, status) => {
          return sum + (countsByStatus[status] ?? 0);
        }, 0);
      return { counts, totalStatusCounts, total, countsByStatus };
    }
    return null;
  }
);

export const getOverdueStatusCount = memoizeOne(
  calendarItems => calendarItems.filter(item => item.meta.syntheticStatus === OrderStatus.OVERDUE).length
);

const divide = (a, b) => (b === 0 ? 0 : a / b);

const newPriorityStatistics = () => ({
  sampleSize: 0,
  monthlySampleSize: times(12, constant(0)),
  totalHours: 0,
  monthlyHours: times(12, constant(0)),

  slaSampleSize: 0,
  monthlySlaSampleSize: times(12, constant(0)),
  slaCount: 0,
  monthlySlaCounts: times(12, constant(0)),
});

const newStatistics = () => ({ all: newPriorityStatistics() });

// mutates stats
const addHourStatistics = (stats, priority, hours, month) => {
  stats[priority].sampleSize++;
  stats[priority].monthlySampleSize[month]++;
  stats[priority].totalHours += hours;
  stats[priority].monthlyHours[month] += hours;
};

// mutates stats
const addSlaStatistics = (stats, priority, isUnderSla, month) => {
  stats[priority].slaSampleSize++;
  stats[priority].monthlySlaSampleSize[month]++;
  isUnderSla && stats[priority].slaCount++;
  isUnderSla && stats[priority].monthlySlaCounts[month]++;
};

// mutates stats
// Calculates monthlyHourAverages and monthlySlaPercents for every priority
export const addAverages = stats => {
  forEach(stats, statsForPriority => {
    statsForPriority.monthlyHourAverages = map(statsForPriority.monthlyHours, (hours, index) =>
      divide(hours, statsForPriority.monthlySampleSize[index])
    );
    statsForPriority.monthlySlaPercents = map(statsForPriority.monthlySlaCounts, (count, index) =>
      divide(count, statsForPriority.monthlySlaSampleSize[index])
    );
  });
};

/**
 * Get reaction time, downtime, and lead time statistics by priority and 'all'.
 */
export const getResponseStatistics = memoizeOne((serviceOrders, responseSLAs) => {
  if (serviceOrders) {
    // gather hour and sla data by priority
    const statistics = serviceOrders.reduce(
      (accu, order) => {
        const priority = order.priority || DEFAULT_PRIORITY;
        const reactionTimeSLA = responseSLAs && responseSLAs.reactionTime && responseSLAs.reactionTime[priority];
        const downtimeSLA = responseSLAs && responseSLAs.downtime && responseSLAs.downtime[priority];
        const leadTimeSLA = responseSLAs && responseSLAs.leadTime && responseSLAs.leadTime[priority];
        const month = parseISO(order.createdDate).getMonth();

        // add new priority to statistics
        if (!accu.reactionTimeStatistics[priority]) {
          accu.reactionTimeStatistics[priority] = newPriorityStatistics();
          accu.downtimeStatistics[priority] = newPriorityStatistics();
          accu.leadTimeStatistics[priority] = newPriorityStatistics();
        }

        if (!isNil(order.reactionTime)) {
          addHourStatistics(accu.reactionTimeStatistics, priority, order.reactionTime, month);
          addHourStatistics(accu.reactionTimeStatistics, 'all', order.reactionTime, month);

          if (!isNil(reactionTimeSLA)) {
            addSlaStatistics(accu.reactionTimeStatistics, priority, order.reactionTime <= reactionTimeSLA, month);
            addSlaStatistics(accu.reactionTimeStatistics, 'all', order.reactionTime <= reactionTimeSLA, month);
          }
        }

        if (!isNil(order.downtime)) {
          addHourStatistics(accu.downtimeStatistics, priority, order.downtime, month);
          addHourStatistics(accu.downtimeStatistics, 'all', order.downtime, month);

          if (!isNil(downtimeSLA)) {
            addSlaStatistics(accu.downtimeStatistics, priority, order.downtime <= downtimeSLA, month);
            addSlaStatistics(accu.downtimeStatistics, 'all', order.downtime <= downtimeSLA, month);
          }
        }

        if (!isNil(order.leadTime)) {
          addHourStatistics(accu.leadTimeStatistics, priority, order.leadTime, month);
          addHourStatistics(accu.leadTimeStatistics, 'all', order.leadTime, month);

          if (!isNil(leadTimeSLA)) {
            addSlaStatistics(accu.leadTimeStatistics, priority, order.leadTime <= leadTimeSLA, month);
            addSlaStatistics(accu.leadTimeStatistics, 'all', order.leadTime <= leadTimeSLA, month);
          }
        }

        return accu;
      },
      {
        reactionTimeStatistics: newStatistics(),
        downtimeStatistics: newStatistics(),
        leadTimeStatistics: newStatistics(),
      }
    );

    // add averages
    addAverages(statistics.reactionTimeStatistics);
    addAverages(statistics.downtimeStatistics);
    addAverages(statistics.leadTimeStatistics);

    return statistics;
  }
  return {};
});

/**
 * Get planned maintenance counts by synthetic status. Synthetic status is added in the selector, and replaces statuses
 * from SAP with more detailed statuses when possible.
 */
export const getSyntheticStatusCounts = memoizeOne(serviceOrders => countBy(serviceOrders, 'meta.syntheticStatus'));

/**
 * Get label for OPI card based on current date range (year / month(s) / day)
 */
export const getDateRangeLabel = (t, year, months, day) => {
  if (day) {
    return format(set(new Date(), { year, month: months[0] - 1, date: day }), 'MMMM do, yyyy');
  }

  if (!months.length) {
    return year.toString();
  }

  if (months.length === 1) {
    return `${t(getMonths()[months[0] - 1])}, ${year}`;
  }

  if (months.length <= 3) {
    const monthNames = getMonthsShort();
    return months
      .map(month => t(monthNames[month - 1]))
      .concat([year])
      .join(', ');
  }

  return `${t('Selected months')}, ${year}`;
};

export const optionLabelOverrides = {
  priority: (value, { t }) => translatePriority(value, t),
  type: (value, { t }) => t(value),
  status: (value, { t, tab }) => (tab === 'Search' ? t(value) : ''),
  source: {
    sap: 'SAP',
    tampuuri: 'Tampuuri',
    planon: 'Planon',
    smartview: 'SmartView',
  },
  discipline: (value, { t }) => t(value),
  profile: (_, { t }) => t('Created by me'),
  removed: (value, { t, tab }) => {
    if (value === 'true') {
      if (tab === 'ServiceOrders') {
        return t('Show removed service orders only');
      }
      if (tab === 'PlannedMaintenance') {
        return t('Show removed planned maintenances only');
      }
      if (tab === 'LongTermPlanning') {
        return t('Show removed long term plans only');
      }
    }
    return '';
  },
  stopped: (value, { t }) => (value === 'true' ? t('Show stopped maintenance plans only') : ''),
  overdue: (value, { t, tab }) => {
    if (value === 'true') {
      if (tab === 'ServiceOrders') {
        return t('Show overdue service orders only');
      }
      if (tab === 'PlannedMaintenance') {
        return t('Show overdue planned maintenances only');
      }
    }
    return '';
  },
};

/**
 * Get options for filter dropdowns.
 *
 * Should return all distinct values for filter fields. If location is selected, addresses should be filtered by that.
 */
export const getFilterOptions = memoizeOne(
  (
    filteredOrders,
    allOrders,
    activeFilter,
    functionalLocations,
    t,
    tab = 'ServiceOrders',
    profile,
    fields = FILTERABLE_FIELDS
  ) => {
    const options = fields.reduce(
      (acc, filter) => ({
        ...acc,
        [filter]: new Set(),
      }),
      {}
    );

    fields.forEach(field => {
      if ((field === 'address' || field === 'discipline' || field === 'city') && activeFilter.location) {
        filteredOrders.forEach(order => {
          const value = order.meta?.filterable[field];
          if (value) {
            options[field].add(value);
          }
        });
      } else {
        allOrders.forEach(order => {
          const value = order.meta?.filterable[field];
          if (isArray(value)) {
            value.forEach(valueItem => options[field].add(valueItem));
          } else if (value && value !== GROUP_UNKNOWN) {
            options[field].add(value);
          }
        });
      }
    });

    // offer only own profile if available
    if (options.profile?.has(profile.id)) {
      options.profile = [profile.id];
    } else {
      options.profile = [];
    }

    // manually add a "show stopped maintenance plans"-option
    if (options.status?.has(OrderStatus.PLANNED)) {
      options.stopped = ['true', 'false'];
    }

    const params = {
      t,
      functionalLocations,
      tab,
    };

    return fromPairs(
      entries(options).map(([filter, options]) => [
        filter,
        Array.from(options).map(value => {
          let text = value;
          if (optionLabelOverrides[filter]) {
            if (typeof optionLabelOverrides[filter] === 'function') {
              text = optionLabelOverrides[filter](value, params);
            } else {
              text = optionLabelOverrides[filter][value] || value;
            }
          }

          return {
            value,
            text,
            hide: !text,
          };
        }),
      ])
    );
  }
);

export const getSectionKey = memoizeOne(match =>
  [match.params.partnerNumber, match.params.functionalLocationId].filter(x => !!x).join(':')
);

export const showServiceOrder = memoizeOne(setQuery => (orderId, orderType, functionalLocationId, partnerNumber) => {
  setQuery({ orderId, orderType, functionalLocationId, partnerNumber });
});

export const showMaintenancePlan = memoizeOne(
  (setQuery, partnerNumber) =>
    ({ code, functionalLocation: functionalLocationId }) => {
      setQuery({ code, functionalLocationId, partnerNumber });
    }
);

export const showServiceRequestEdit = memoizeOne(
  setQuery => (orderId, orderType, functionalLocationId, partnerNumber) => {
    setQuery({ orderId, orderType, functionalLocationId, partnerNumber, manage: true });
  }
);

const hideFromNorwegianCustomers = (functionalLocation, profile) => {
  if (startsWith(functionalLocation, 'NO')) {
    return !isCaverionAdminOrUserRole(profile.role);
  }
  return false;
};

export const shouldHideResponseTimes = (partnerMeta, functionalLocation = '', profile = {}) => {
  const row = find(partnerMeta, { key: 'hide_response_times' }) || {};
  if (hideFromNorwegianCustomers(functionalLocation, profile) || lowerCase(trim(row.value)) === 'true') {
    return true;
  }
  return false;
};

export const shouldHideLongOperationTexts = (partnerMeta, functionalLocation = '') => {
  const row = find(partnerMeta, { key: 'hide_operation_long_texts' }) || {};
  if (startsWith(functionalLocation, 'NO') || lowerCase(trim(row.value)) === 'true') {
    return true;
  }
  return false;
};

export const formatValue = value => {
  if (value === '-') {
    return value;
  }
  // If less than hour, show minutes
  else if (value < 1) {
    return `${round(value * 60)} min`;
  }
  // Show more precise value if less than 100 hours
  else if (value < 100) {
    return `${round(value, 1)} h`;
  }
  return `${round(value)} h`;
};
