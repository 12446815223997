import MasterDataService from 'services/masterData';

import { createReducerFromMapping } from 'redux/utils';
import { loadMaintenancePlanFiles } from 'redux/modules';
import { indexByDate } from '../../utils/indexing';
import {
  LOAD_SUCCESS as LOAD_PLANNED_MAINTENANCE_SUCCESS,
  deleteFromIndex as deletePlannedMaintenanceFromIndex,
} from './plannedMaintenance';
import { getMaintenancePlanForDelete } from 'containers/Application/ServiceModule/MaintenancePlanForm/utils';
import { formatOrderForCalendar } from 'utils/Data/serviceOrders';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import { parseToDate } from 'utils/Date/date';
import { invalidateAllPlannedMaintenanceCaches } from 'services/masterData/plannedMaintenance';

const initialState = {
  index: indexByDate.getInitialState(),
  maintenances: {},
  loading: {},
  bulkSelected: [],
  bulkToolsVisible: false,
};

export const LOAD = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_FAIL';

export const loadMaintenancePlan = (code, partnerNumber, functionalLocation, key) => async dispatch => {
  dispatch({ type: LOAD, key });

  try {
    const result = await dispatch(MasterDataService.maintenancePlans(code, partnerNumber, functionalLocation));

    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result,
      keepPrevious: !!code,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
  }
};

export const LOAD_CONTAINER = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_CONTAINER';
export const LOAD_CONTAINER_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_CONTAINER_SUCCESS';
export const LOAD_CONTAINER_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_CONTAINER_FAIL';

export const loadMaintenancePlanContainer = (t, code, partnerNumber, functionalLocation) => async dispatch => {
  dispatch({ type: LOAD_CONTAINER, code });

  try {
    const result = await dispatch(MasterDataService.maintenancePlans(code, partnerNumber, functionalLocation));
    const maintenancePlan = result[0];

    dispatch(loadMaintenancesForPlan(t, code, partnerNumber, functionalLocation));
    dispatch(loadMaintenancePlanFiles(code, maintenancePlan.id));

    dispatch({
      type: LOAD_CONTAINER_SUCCESS,
      code,
      result: maintenancePlan,
      keepPrevious: true,
    });
  } catch (error) {
    dispatch({
      type: LOAD_CONTAINER_FAIL,
      code,
    });
    throw error;
  }
};

export const UPSERT = 'CUSTOMER_PLATFORM/MaintenancePlan/UPSERT';
export const UPSERT_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/UPSERT_SUCCESS';
export const UPSERT_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/UPSERT_FAIL';

const chooseLater = (date1, date2) => {
  if (!date1) {
    return date2;
  } else if (!date2) {
    return date1;
  } else if (isAfter(parseToDate(date1), parseToDate(date2))) {
    return date1;
  }
  return date2;
};

export const upsertMaintenancePlan = maintenancePlan => async (dispatch, getState) => {
  dispatch({ type: UPSERT });

  const oldMaintenancePlan = getState().maintenancePlans.index.byId[maintenancePlan.code] || {};
  const shouldSyncPlannedMaintenances = maintenancePlan.schedule !== oldMaintenancePlan.schedule;

  try {
    const result = await dispatch(MasterDataService.upsertMaintenancePlan(maintenancePlan));

    // generate new planned maintenances, if schedule changed
    if (shouldSyncPlannedMaintenances) {
      await dispatch(
        syncPlannedMaintenances(
          result.code,
          new Date().toISOString(),
          chooseLater(maintenancePlan.endDate, oldMaintenancePlan.endDate),
          maintenancePlan.partnerNumber,
          maintenancePlan.functionalLocation
        )
      );
    }

    return dispatch({
      type: UPSERT_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: UPSERT_FAIL,
      error,
      code: maintenancePlan.code,
    });
  }
};

export const LOAD_MAINTENANCES = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_MAINTENANCES';
export const LOAD_MAINTENANCES_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_MAINTENANCES_SUCCESS';
export const LOAD_MAINTENANCES_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/LOAD_MAINTENANCES_FAIL';

export const loadMaintenancesForPlan = (t, code, partnerNumber, functionalLocation) => async (dispatch, getState) => {
  dispatch({ type: LOAD_MAINTENANCES, code });
  const profile = getState().profile.profile;

  try {
    const plannedMaintenances = await MasterDataService.plannedMaintenance({
      partnerNumber,
      functionalLocation,
      maintenancePlanNumber: code,
    });
    // format planned maintenances
    const planned = plannedMaintenances.map(pm =>
      formatOrderForCalendar({ t, order: pm, profile, addSyntheticStatus: true })
    );

    return dispatch({
      type: LOAD_MAINTENANCES_SUCCESS,
      result: planned,
      code,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_MAINTENANCES_FAIL,
      error,
      code,
    });
  }
};

export const DELETE = 'CUSTOMER_PLATFORM/MaintenancePlan/DELETE';
export const DELETE_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/DELETE_SUCCESS';
export const DELETE_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/DELETE_FAIL';

export const deleteMaintenancePlan = code => async (dispatch, getState) => {
  dispatch({ type: DELETE });

  try {
    const result = await dispatch(MasterDataService.deleteMaintenancePlan(code));
    const maintenancePlan = getState().maintenancePlans.index.byId[code];
    dispatch(deleteFromIndex(maintenancePlan));

    return dispatch({
      type: DELETE_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: DELETE_FAIL,
      error,
    });
  }
};

export const DELETE_FROM_INDEX = 'CUSTOMER_PLATFORM/MaintenancePlan/DELETE_FROM_INDEX';

const deleteFromIndex = maintenancePlan => ({
  type: DELETE_FROM_INDEX,
  maintenancePlan,
});

export const SYNC_MAINTENANCES = 'CUSTOMER_PLATFORM/MaintenancePlan/SYNC_MAINTENANCES';
export const SYNC_MAINTENANCES_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/SYNC_MAINTENANCES_SUCCESS';
export const SYNC_MAINTENANCES_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/SYNC_MAINTENANCES_FAIL';

export const syncPlannedMaintenances =
  (code, startDate, endDate, partnerNumber, functionalLocation) => async dispatch => {
    dispatch({ type: SYNC_MAINTENANCES });

    try {
      const result = await dispatch(MasterDataService.syncPlannedMaintenances(code, startDate, endDate));

      if (result.inserted?.length > 0) {
        const plannedMaintenances = await MasterDataService.plannedMaintenance({
          partnerNumber,
          functionalLocation,
          maintenancePlanNumber: code,
          refreshCache: true,
        });

        // add loaded planned maintenances to index
        if (plannedMaintenances?.length > 0) {
          dispatch({
            type: LOAD_PLANNED_MAINTENANCE_SUCCESS,
            result: plannedMaintenances,
            keepPrevious: true,
          });
        }
      }

      if (result.deleted?.length > 0) {
        result.deleted.forEach(item => {
          // delete from index
          dispatch(deletePlannedMaintenanceFromIndex(item));
        });
      }

      return dispatch({
        type: SYNC_MAINTENANCES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: SYNC_MAINTENANCES_FAIL,
        error,
      });
    }
  };

export const BULK_TOOLS_VISIBLE = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_TOOLS_VISIBLE';

export const setBulkToolsVisible = bulkToolsVisible => ({
  type: BULK_TOOLS_VISIBLE,
  bulkToolsVisible,
});

export const ADD_BULK_SELECTED = 'CUSTOMER_PLATFORM/MaintenancePlan/ADD_BULK_SELECTED';

export const addBulkSelected = selected => ({
  type: ADD_BULK_SELECTED,
  selected,
});

export const REMOVE_BULK_SELECTED = 'CUSTOMER_PLATFORM/MaintenancePlan/REMOVE_BULK_SELECTED';

export const removeBulkSelected = selected => ({
  type: REMOVE_BULK_SELECTED,
  selected,
});

export const CLEAR_BULK_SELECTED = 'CUSTOMER_PLATFORM/MaintenancePlan/CLEAR_BULK_SELECTED';

export const clearBulkSelected = () => ({
  type: CLEAR_BULK_SELECTED,
});

export const BULK_REMOVE = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_REMOVE';
export const BULK_REMOVE_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_REMOVE_SUCCESS';
export const BULK_REMOVE_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_REMOVE_FAIL';

export const bulkRemove = (maintenancePlans, partnerNumber) => async dispatch => {
  dispatch({ type: BULK_REMOVE });

  try {
    const maintenancePlansForDelete = maintenancePlans.map(plan => getMaintenancePlanForDelete(plan, partnerNumber));

    const statuses = [];

    for (const maintenancePlan of maintenancePlansForDelete) {
      const upsertThunkResult = await dispatch(upsertMaintenancePlan(maintenancePlan));

      if (upsertThunkResult.error) {
        statuses.push({ code: upsertThunkResult.code, success: false, error: 'Saving failed' });
      } else {
        statuses.push({ code: upsertThunkResult.result?.code, success: true, error: null });
      }
    }

    await invalidateAllPlannedMaintenanceCaches();

    return dispatch({
      type: BULK_REMOVE_SUCCESS,
      result: statuses,
    });
  } catch (error) {
    return dispatch({
      type: BULK_REMOVE_FAIL,
      error,
    });
  }
};

export const copyOneMaintenancePlan = (code, functionalLocations) => async dispatch => {
  try {
    await dispatch(MasterDataService.copyMaintenancePlan(code, functionalLocations));
    return { code, success: true, error: null };
  } catch (error) {
    const errorMessage = error?.responseBody?.error?.message || '';
    return { code, success: false, error: errorMessage };
  }
};

export const BULK_COPY = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_COPY';
export const BULK_COPY_SUCCESS = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_COPY_SUCCESS';
export const BULK_COPY_FAIL = 'CUSTOMER_PLATFORM/MaintenancePlan/BULK_COPY_FAIL';

export const bulkCopy = (maintenancePlanCodes, functionalLocations) => async dispatch => {
  dispatch({ type: BULK_COPY });

  try {
    const result = await Promise.all(
      maintenancePlanCodes.map(code => dispatch(copyOneMaintenancePlan(code, functionalLocations)))
    );

    return dispatch({
      type: BULK_COPY_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: BULK_COPY_FAIL,
      error,
    });
  }
};

const getPlanYear = plan => parseISO(plan.created || plan.modified).getFullYear();

export default createReducerFromMapping(
  {
    [LOAD]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, action.result, 'code', getPlanYear, action.keepPrevious),
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),

    [LOAD_CONTAINER]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.code]: true,
      },
    }),
    [LOAD_CONTAINER_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, [action.result], 'code', getPlanYear, action.keepPrevious),
      loading: {
        ...state.loading,
        [action.code]: false,
      },
    }),
    [LOAD_CONTAINER_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.code]: false,
      },
    }),

    [LOAD_MAINTENANCES]: (state, action) => ({
      ...state,
      maintenances: {
        ...state.maintenances,
        [action.code]: {
          loading: true,
          items: [],
        },
      },
    }),
    [LOAD_MAINTENANCES_SUCCESS]: (state, action) => ({
      ...state,
      maintenances: {
        ...state.maintenances,
        [action.code]: {
          loading: false,
          items: action.result,
        },
      },
    }),
    [LOAD_MAINTENANCES_FAIL]: (state, action) => ({
      ...state,
      maintenances: {
        ...state.maintenances,
        [action.code]: {
          loading: false,
          items: [],
        },
      },
    }),

    [UPSERT_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, [action.result], 'code', getPlanYear, true),
    }),

    [DELETE_FROM_INDEX]: (state, action) => ({
      ...state,
      index: indexByDate.delete(state.index, action.maintenancePlan, 'code', getPlanYear),
    }),

    [BULK_TOOLS_VISIBLE]: (state, action) => ({
      ...state,
      bulkToolsVisible: action.bulkToolsVisible,
      bulkSelected: action.bulkToolsVisible ? state.bulkSelected : [],
    }),

    [ADD_BULK_SELECTED]: (state, action) => ({
      ...state,
      bulkSelected: state.bulkSelected.concat(
        action.selected.filter(maintenancePlan => !state.bulkSelected.includes(maintenancePlan))
      ),
    }),
    [REMOVE_BULK_SELECTED]: (state, action) => ({
      ...state,
      bulkSelected: state.bulkSelected.filter(maintenancePlan => !action.selected.includes(maintenancePlan)),
    }),
    [CLEAR_BULK_SELECTED]: state => ({
      ...state,
      bulkSelected: [],
    }),
  },
  initialState
);
