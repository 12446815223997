import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';

/**
 * Get sensor hierarchies (usually 1) for a building.
 */
export const getBuildingSensorHierarchies = (state, props) =>
  state.sensorHierarchy.buildingHierarchy[props?.match?.params.functionalLocationId ?? props?.functionalLocationId];

/**
 * Get sensors recursively from a given sensor hierarchy.
 */
const getSensorsFromHierarchy = sensorHierarchy => [
  ...(sensorHierarchy.sensors ?? []),
  ...(sensorHierarchy.children ?? []).flatMap(getSensorsFromHierarchy),
];

/**
 * Get a flat list of sensors of a building. Includes all sensors mapped to any of the sensor hierarchies of the
 * building.
 */
export const getBuildingSensors = createSelector(getBuildingSensorHierarchies, sensorHierarchies => {
  if (!sensorHierarchies?.length) {
    return [];
  }

  const sensors = sensorHierarchies.flatMap(getSensorsFromHierarchy);
  return uniqBy(sensors, 'id');
});

/**
 * Detect if given sensor is a device (as opposed to a measuring point)
 */
const devicePrefixes = ['sigfox', 'cream', 'lorawan', 'telia'];
const isDevice = sensor => devicePrefixes.some(prefix => sensor?.sensorType?.name?.startsWith(prefix));

/**
 * Get a flat list of measuring points of a building.
 */
export const getBuildingMeasuringPoints = createSelector(getBuildingSensors, sensors =>
  uniqBy(
    sensors
      .flatMap(sensor => (sensor.children?.length ? [sensor, ...sensor.children] : sensor))
      .filter(sensor => !isDevice(sensor)),
    'id'
  )
);

/**
 * Get a flat list of devices of a building.
 */
export const getBuildingDevices = createSelector(getBuildingSensors, sensors => sensors.filter(isDevice));

/**
 * Get all sensor types as an array.
 */
const getSensorTypes = state => state.sensorHierarchy.sensorDataTypes;

/**
 * Get all sensor types as a map with sensor type ids as keys.
 */
export const getSensorTypesById = createSelector(getSensorTypes, sensorTypes => keyBy(sensorTypes, 'id'));

/**
 * Get a flat list of sensor hierarchies from a nested sensor hierarchy.
 */
const getFlatSensorHierarchies = sensorHierarchy => [
  sensorHierarchy,
  ...(sensorHierarchy.children ?? []).flatMap(getFlatSensorHierarchies),
];

/**
 * Get a flat list of sensor hierarchies for a building.
 */
export const getFlatBuildingSensorHierarchies = createSelector(
  getBuildingSensorHierarchies,
  sensorHierarchies => sensorHierarchies?.flatMap(getFlatSensorHierarchies) ?? []
);

/**
 * Get all areas of a building.
 */
export const getBuildingAreas = createSelector(getFlatBuildingSensorHierarchies, sensorHierarchies =>
  sensorHierarchies.filter(sensorHierarchy => sensorHierarchy.type === 'area')
);

/**
 * Get all groups of a building.
 */
export const getBuildingGroups = createSelector(getFlatBuildingSensorHierarchies, sensorHierarchies =>
  sensorHierarchies.filter(sensorHierarchy => sensorHierarchy.type?.startsWith('group'))
);
