import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import endsWith from 'lodash/endsWith';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import isNil from 'lodash/isNil';
import parseISO from 'date-fns/parseISO';

import { getAggregation } from 'containers/Application/SensorValues/SensorValuesUtils';
import { getAggregationByFrequency, getAggregationsForSensor } from 'utils/Data/values';
import { createOpeningHourBands } from 'utils/Data/performance';
import { DATE_RANGES, getDateRanges } from 'components/Form/DateTools';
import { startOfUTCYear, endOfUTCYear } from 'utils/Date/date';
import { TIMEFRAME_PRESETS } from 'containers/Application/Modules/CustomChartsModule/CustomChartConfigModal/CustomChartSettings/constants';
import { CHART_TYPES } from './constants';

export { CHART_TYPES };

const EMPTY_ARRAY = [];

const getSensorName = (sensorName, parentSensorName) => {
  if (!parentSensorName) {
    return sensorName;
  }
  return `${parentSensorName}: ${sensorName}`;
};

const getAggregationType = aggregation => {
  if (endsWith(aggregation, 'sum')) {
    return 'sum';
  }
  return 'average';
};

const getDataQueryFrequency = frequency => {
  if (frequency === 'weekly') {
    return 'daily';
  } else if (frequency === 'yearly') {
    return 'monthly';
  }
  return frequency;
};

export const getSensorInfo = (sensorId, buildingSensors) => {
  const sensor = buildingSensors.find(buildingSensor => buildingSensor.id === sensorId);
  if (sensor && !sensor.disabled && sensor.parentId && !sensor.parentDisable) {
    const parentSensor = buildingSensors.find(buildingSensor => buildingSensor.id === sensor.parentId);
    const sensorName = getSensorName(sensor.name, parentSensor?.name);
    return {
      ...sensor,
      name: sensorName,
    };
  }
  return sensor;
};

/**
 *
 * Maps custom chart series to include sensor data and additional information for visualization chart.
 * Todo: Better support for series with multiple sensor ids.
 *
 */
export const mapSensorData = ({ chart, valuesBySensorId, buildingSensors, openHours }) => {
  if (isEmpty(valuesBySensorId)) {
    return EMPTY_ARRAY;
  }

  const frequency = chart.aggregationFreq;

  return (
    chart.series?.reduce((accu, series) => {
      // Currently we have only one sensor in the series
      const firstSensorId = series.sensorIds[0];
      const firstSensor = firstSensorId && getSensorInfo(firstSensorId, buildingSensors);

      if (firstSensor && !firstSensor.disabled) {
        const sensorData = series.sensorIds.flatMap(sensorId => valuesBySensorId[sensorId] || []);

        const aggregation = getAggregationByFrequency(firstSensor, getDataQueryFrequency(frequency))[0];
        const aggregationType = getAggregationType(aggregation);

        const includeOpenHours = chart.openHours?.includes(firstSensor.id);

        return accu.concat([
          {
            sensor: {
              ...firstSensor,
              name: series.name || firstSensor.displayName || firstSensor.name,
              sensorType: {
                ...firstSensor.sensorType,
                graphType: firstSensor.sensorType.graphType || series.graphType,
                seriesGraphType: series.graphType,
              },
            },
            data: sensorData,
            color: series.color,
            ...(frequency ? { aggregationType } : {}),
            ...(includeOpenHours ? { openHours } : {}),
          },
        ]);
      }

      return accu;
    }, []) ?? []
  );
};

/**
 * Get sensors grouped by aggregations for sensors that are included in the chart
 */
export const getAggregations = (sensors, parameters) =>
  groupBy(
    compact(
      sensors.map(sensor => {
        if (!sensor) {
          return null;
        }
        const aggregation = getAggregation(sensor.sensorType, parameters, sensor);
        if (!aggregation) {
          return null;
        }
        return {
          aggregation,
          sensorId: sensor.id,
          availableAggregations: getAggregationsForSensor(sensor),
        };
      })
    ),
    'aggregation'
  );

/**
 * Get suitable aggregation for preferred granularity
 */
export const getSuitableAggregations = (sensors, frequency, serie) =>
  groupBy(
    compact(
      (sensors ?? []).map(sensor => {
        if (frequency === 'raw') {
          return {
            aggregation: 'raw',
            sensorId: sensor.id,
          };
        }
        const aggregations = getAggregationByFrequency(sensor, frequency);
        const aggregation = serie?.aggregation
          ? aggregations.find(aggregation => aggregation === serie.aggregation)
          : aggregations[0];
        if (!aggregation) {
          return null;
        }
        return {
          aggregation,
          sensorId: sensor.id,
          availableAggregations: getAggregationsForSensor(sensor),
        };
      })
    ),
    'aggregation'
  );

/**
 * @param {*} chartSeries Series configuration of the custom chart
 * @param {*} buildingSensors Sensors that belong to the current building (functional location)
 * @returns Array of sensors defined in series configuration if active and present in building sensors
 */
export const getChartSensors = (chartSeries, buildingSensors) => {
  const sensorIds = uniq(chartSeries?.flatMap(seriesObj => seriesObj.sensorIds) ?? []);
  return sensorIds
    .map(sensorId => buildingSensors.find(buildingSensor => buildingSensor.id === sensorId))
    .filter(Boolean)
    .filter(sensor => !sensor.disabled);
};

export const getDefaultRange = aggregationFreq => {
  switch (aggregationFreq) {
    case 'yearly':
      return DATE_RANGES.YEARS_5;
    case 'monthly':
      return DATE_RANGES.DAYS_365;
    case 'weekly':
      return DATE_RANGES.DAYS_365;
    case 'daily':
      return DATE_RANGES.DAYS_30;
    case 'hourly':
    default:
      return DATE_RANGES.DAYS_7;
  }
};

export const getOpenHourBands = (openHours, { startDatetime, endDatetime }) => {
  if (openHours && startDatetime && endDatetime) {
    return createOpeningHourBands({
      startDate: startDatetime,
      endDate: endDatetime,
      openingHours: openHours,
      maxDays: 7,
    });
  }
  return [];
};

export const getOldestSensorCreatedDate = (sensors = []) => minBy(sensors, sensor => sensor.created)?.created;

export const chartHasSort = chart => (chart.series || []).some(seriesObj => !isEmpty(seriesObj.sort));

export const getConfiguredTimeframe = (series, chart) => {
  if (!series) {
    return [];
  }

  const { comparisonYear, comparisonYearDiff, timeframeStart, timeframeEnd, timeframeStartDiffMs, aggregationFreq } =
    series;

  if (timeframeStartDiffMs === TIMEFRAME_PRESETS.ALL_DATA) {
    return [new Date(0), new Date()];
  }

  if (comparisonYear) {
    const yearDate = new Date().setFullYear(comparisonYear);
    return [startOfUTCYear(yearDate), endOfUTCYear(yearDate)];
  }

  if (!isNil(comparisonYearDiff)) {
    const yearDate = new Date().setFullYear(new Date().getFullYear() + comparisonYearDiff);
    return [startOfUTCYear(yearDate), endOfUTCYear(yearDate)];
  }

  if (!isNil(timeframeStartDiffMs) && timeframeStartDiffMs !== TIMEFRAME_PRESETS.AUTOMATIC) {
    if (timeframeStartDiffMs === TIMEFRAME_PRESETS.YEAR_TO_DATE) {
      return [startOfUTCYear(new Date()), new Date()];
    }
    return [new Date(new Date().getTime() + timeframeStartDiffMs), new Date()];
  }

  if (timeframeStart || timeframeEnd) {
    const start = timeframeStart ? new Date(parseISO(timeframeStart)) : new Date(0);
    const end = timeframeEnd ? new Date(parseISO(timeframeEnd)) : new Date();
    return [start, end];
  }

  const range = getDefaultRange(aggregationFreq ?? chart.aggregationFreq);
  const newDateRange = getDateRanges()[range];

  return [newDateRange.value[0], newDateRange.value[1]];
};
