import React, { useCallback } from 'react';
import useQuery from 'decorators/Query/useQuery';

const withQueryPaging = WrappedTableComponent => {
  const QueryComponent = props => {
    const { query, setQuery } = useQuery();
    const page = parseInt(query.page, 10) || 0;
    const onChangePageIndex = useCallback(
      pageIndex => {
        setQuery({
          ...query,
          page: pageIndex + 1,
        });
      },
      [setQuery, query]
    );
    return (
      <WrappedTableComponent
        {...props}
        initialPageIndex={page > 0 ? page - 1 : 0}
        onChangePageIndex={onChangePageIndex}
      />
    );
  };
  return QueryComponent;
};

export default withQueryPaging;
