import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import Infotip from 'components/Infotip/Infotip';

const Block = styled.span`
  display: inline-block;
  background: ${props => (props.gray ? 'var(--checklist-score-bg)' : 'var(--checklist-score-bg-alt)')};
  text-align: center;
  color: var(--checklist-score-fg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.font.lineHeight.lg};
  width: var(--size-xl-plus);
  border-radius: var(--radius-sm);
  user-select: none;
  cursor: default;
`;

const InfoText = styled.div`
  text-align: left;
  color: var(--body-text-fg);
  font-size: ${props => props.theme.font.size.xxs};
  line-height: ${props => props.theme.font.lineHeight.lg};
`;

const ChecklistScore = ({ score, distribution }) => {
  if (isNil(score)) {
    return <Block gray>-</Block>;
  }

  const infotipContent = (
    <InfoText>
      {distribution.map(data => (
        <p key={data.label}>
          <b>{data.label}</b>: {data.count}
        </p>
      ))}
    </InfoText>
  );

  return (
    <Infotip text={infotipContent} position="right">
      <Block>{Math.floor(score)}&#8287;%</Block>
    </Infotip>
  );
};

ChecklistScore.propTypes = {
  score: PropTypes.number,
  distribution: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      count: PropTypes.number,
      label: PropTypes.string,
      color: PropTypes.string,
      visible: PropTypes.bool,
    })
  ).isRequired,
};

export default ChecklistScore;
