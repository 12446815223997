import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import take from 'lodash/take';
import orderBy from 'lodash/orderBy';
import BenchmarkTabSkeleton from 'containers/Application/Recycling/RecyclingBenchmark/BenchmarkTab/BenchmarkTabSkeleton';
import PerformanceList from 'components/PerformanceList/PerformanceList';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import connect from 'containers/Application/Recycling/RecyclingBenchmark/ObservationsTab/connectObservationsTab';
import translations from 'decorators/Translations/translations';
import { withRouter } from 'react-router-dom';
import { ListWrapper, Performances, Filter, FilterInfo } from 'containers/Application/RemoteCenter/Benchmarking';

export const ObservationsTab = ({
  t,
  containerLoading,
  visible,
  partnerNumber,
  data,
}) => {
  const [limit, setLimit] = React.useState(10);

  // TODO: move to utils
  const generateFilterSelection = () => {
    if (benchmarkingData) {
      const options = map(range(5, benchmarkingData.length, 5), index => ({
        label: `${index} ${index > 1 ? t('buildings') : t('building')}`,
        value: index,
      }));
      options.push({
        label: `${benchmarkingData.length} ${benchmarkingData.length > 1 ? t('buildings') : t('building')}`,
        value: benchmarkingData.length,
      });
      return options;
    }
    return [];
  };
  if (!visible) {
    return null;
  }
  if (containerLoading || !data) {
    return <BenchmarkTabSkeleton />;
  }
  const benchmarkingData = data.map(entry => ({
    ...entry,
    subtitle: entry.address,
    to: `/${partnerNumber}/FunctionalLocation/${entry.functionalLocationId}/Recycling`,
  }));
  const items = take(benchmarkingData, limit);
  const topPerformers = orderBy(items, 'value', 'desc');
  const worstPerformers = orderBy(items, 'value', 'asc');
  return (
    <React.Fragment>
      <Performances>
        <ListWrapper>
          <PerformanceList
            t={t}
            valueWidth={80}
            title="Most open observations"
            items={topPerformers}
            clickEnabled
          />
        </ListWrapper>
        <ListWrapper>
          <PerformanceList
            t={t}
            valueWidth={80}
            title="Least open observations"
            items={worstPerformers}
            clickEnabled
          />
        </ListWrapper>
      </Performances>
      {benchmarkingData.length > 5 && (
        <Filter>
          <FilterInfo>{t('Show')}</FilterInfo>
          <InputSelectDropdown
            t={t}
            options={generateFilterSelection()}
            onChange={(property, value) => setLimit(value)}
            model={{ limit }}
            property="limit"
            clearable={false}
          />
        </Filter>
      )}
    </React.Fragment>
  );
};

ObservationsTab.propTypes = {
  t: PropTypes.func.isRequired,
  containerLoading: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  partnerNumber: PropTypes.string,
  data: PropTypes.array,
};

export default withRouter(translations(connect(ObservationsTab)));
