import { createReducerFromMapping } from 'redux/utils/index';
import { loadCounts } from 'redux/modules/service/serviceOrders';
import {
  loadPartnerAlarmCounts,
  loadHandlingTimeKPI,
  loadTimeToActionKPI,
  loadAlarmPerformance,
} from 'redux/modules/iot/alarm';
import { loadObservationKpi, loadInspectionKpi, loadObservationPerformance } from 'redux/modules/iot/notice';
import getServiceOrderStartDate from 'utils/Date/serviceOrderStartDate';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import endOfHour from 'date-fns/endOfHour';
import subDays from 'date-fns/subDays';
import { startOfUTCDay } from 'utils/Date/date';

import { loadEnergyRatingKPI } from '../iot/values/energy_rating';
import { loadConsumptionKPI } from '../iot/values/consumption';
import { loadAirQualityKPI } from '../iot/values/air_quality';

export const LOAD_KPI = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI';
export const LOAD_KPI_SUCCESS = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI_SUCCESS';
export const LOAD_KPI_FAIL = 'CUSTOMER_PLATFORM/KPIContainer/LOAD_KPI_FAIL';

const initialState = {
  loading: true,
  error: false,
};

const dateNow = new Date();
const kpiYearStart = startOfYear(dateNow);
const kpiYearEnd = endOfYear(dateNow);
const now = endOfHour(dateNow);
const yearAgo = subDays(startOfUTCDay(dateNow), 365);

export const loadKPIContainer =
  (partnerNumber, serviceOrderStartDate, profileKpis, buildingPerformanceSources) => async dispatch => {
    dispatch({ type: LOAD_KPI });
    const kpiServiceOrderStart = getServiceOrderStartDate(serviceOrderStartDate, kpiYearStart, kpiYearEnd);
    try {
      if (includes(profileKpis, 'energy') || buildingPerformanceSources?.includes('energy')) {
        dispatch(loadEnergyRatingKPI(partnerNumber));
      }

      !isEmpty(intersection(profileKpis, ['plannedMaintenance', 'serviceOrders'])) &&
        dispatch(loadCounts(kpiServiceOrderStart, kpiYearEnd, undefined, partnerNumber));
      includes(profileKpis, 'alarms') && dispatch(loadPartnerAlarmCounts(partnerNumber, yearAgo, now));
      includes(profileKpis, 'alarmHandlingTime') && dispatch(loadHandlingTimeKPI(partnerNumber));
      includes(profileKpis, 'alarmTimeToAction') && dispatch(loadTimeToActionKPI(partnerNumber));
      includes(profileKpis, 'observations') &&
        dispatch(loadObservationKpi(partnerNumber, { start: yearAgo, end: now }));
      includes(profileKpis, 'inspections') &&
        dispatch(loadInspectionKpi(partnerNumber, { start: kpiYearStart, end: kpiYearEnd }));

      if (includes(profileKpis, 'indoorAirQuality') || buildingPerformanceSources?.includes('indoorAirQuality')) {
        dispatch(loadAirQualityKPI(partnerNumber));
      }

      const energyConsumption =
        includes(profileKpis, 'energyConsumption') || buildingPerformanceSources?.includes('energyConsumption');

      if (energyConsumption || includes(profileKpis, 'electricity')) {
        dispatch(loadConsumptionKPI(partnerNumber, 'electricity_main'));
      }

      if (energyConsumption || includes(profileKpis, 'heating')) {
        dispatch(loadConsumptionKPI(partnerNumber, 'heating_main'));
      }

      if (energyConsumption || includes(profileKpis, 'water')) {
        dispatch(loadConsumptionKPI(partnerNumber, 'water_consumption'));
      }

      if (energyConsumption || includes(profileKpis, 'cooling')) {
        dispatch(loadConsumptionKPI(partnerNumber, 'cooling_main'));
      }

      if (buildingPerformanceSources?.includes('alarms')) {
        dispatch(loadAlarmPerformance(partnerNumber));
      }

      if (buildingPerformanceSources?.includes('observations')) {
        dispatch(loadObservationPerformance(partnerNumber, { start: yearAgo, end: now }));
      }

      dispatch({ type: LOAD_KPI_SUCCESS });
    } catch (error) {
      return dispatch({
        type: LOAD_KPI_FAIL,
        error,
      });
    }
  };

export default createReducerFromMapping(
  {
    [LOAD_KPI]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_KPI_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [LOAD_KPI_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
