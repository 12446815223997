import { css, DefaultTheme, ThemedCssFunction } from 'styled-components';
import { lighten, darken, rem, rgba } from 'polished';

import { CSSAttributeArray, CSSAttributeMap, NumericValueMap } from 'styles/ThemeTypes';

import {
  colors as colorOptions,
  chartColors as chartColorOptions,
  brandColors,
  font,
  size,
  time,
  breakpoints,
  shadows,
  grid as gridOptions,
  zIndex,
  rootSize,
  iconSize,
  BreakpointName,
} from './../definitions';

/**
 * BASICS
 */

const colors = {
  ...colorOptions,
};

/**
 * Font size
 */
const fontSize = {
  ...font.size,
  // Note
  note: font.size.xs,
  // Default text size
  text: font.size.sm,
  // Page title size
  title: font.size.xl,
  // Heading size
  heading: font.size.lg,
  // Sub heading size
  subHeading: font.size.md,
  // Huge text size
  huge: font.size.xxl,
};

/**
 * Font family
 */
const fontFamily = {
  // Default heading font
  heading: font.family.cairo,
  // Heading font for smaller font sizes
  subHeading: font.family.cairo,
  // Default text font
  text: font.family.cairo,
};

/**
 * Font weight
 */
const fontWeight: NumericValueMap = {
  // Heading font weight
  heading: font.weight.semibold,
  // Body text font weight
  normal: font.weight.normal,
  text: font.weight.normal,
  // Bold body text font weight
  bold: font.weight.bold,
};

/**
 * Line height
 */
const lineHeight: NumericValueMap = {
  // Default line height
  text: font.lineHeight.md,
  // Heading line height
  heading: font.lineHeight.lg,
  // Remove leading
  reset: font.lineHeight.sm,
};

/**
 * Text color
 */
const textColor: CSSAttributeMap = {
  // Default text color
  text: colors.black,
  // Heading text color
  heading: colors.blue,
  // Form inputs text color
  input: colors.black,
};

/**
 * Letter-spacing
 */
const letterSpacing: CSSAttributeMap = {
  // Default
  default: font.letterSpacing.default,
};

/**
 * Spacing
 */
const spacing: CSSAttributeMap = {
  // Default border width
  border: size.border.sm,
  // Available spacings
  xxxs: size.spacing.xxxs,
  xxs: size.spacing.xxs,
  xs: size.spacing.xs,
  sm: size.spacing.sm,
  md: size.spacing.md,
  mdPlus: size.spacing.mdPlus,
  lg: size.spacing.lg,
  xl: size.spacing.xl,
  xxl: size.spacing.xxl,
};

/**
 * Grid
 */
const grid: CSSAttributeMap = {
  gutter: gridOptions.gutter,
  maxWidth: '92rem',
};

const getColumnWidth = (count: number, maxColumns = 12): string => {
  return `calc(${(count / maxColumns) * 100 + '%'} - ${grid.gutter})`;
};

/**
 * Transitions
 */
const motion: CSSAttributeMap = {
  quick: time.quick,
  default: time.default,
  long: time.long,
  easing: 'ease-in-out',
  easeIn: 'ease-in',
  easeOut: 'ease-out',
};

/**
 * Media queries
 * https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md
 */

export type CssFunctionArguments = Parameters<ThemedCssFunction<DefaultTheme>>;

export type MediaQueryCssFunctions = Record<
  BreakpointName,
  (...args: CssFunctionArguments) => ReturnType<ThemedCssFunction<DefaultTheme>>
>;

export const media: MediaQueryCssFunctions = Object.keys(breakpoints).reduce(
  (accumulator: MediaQueryCssFunctions, label: BreakpointName) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = breakpoints[label] / 16;

    accumulator[label] = (...args: CssFunctionArguments) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `;

    return accumulator;
  },
  {}
);

/**
 * COMPONENTS
 */

/**
 * Menu
 */
const menu: CSSAttributeMap = {
  width: '20em',
  spacingTop: size.spacing.xxl,
  itemSpacing: size.spacing.md,
  shadow: `0 0 40px 5px ${rgba(0, 0, 0, 0.15)}`,
};

/**
 * Cards
 */

const cards: CSSAttributeMap = {
  backgroundColor: colorOptions.white,
  backgroundColorHover: darken(0.08, colorOptions.blue),
  padding: spacing.lg,
};

/**
 * Charts
 */

const charts = {
  colors: [colors.emerald, colors.rockBlue, colors.cerulean],
  colorsEnergy: [colors.cerulean, colors.blue, colors.rockBlue],
  colorsRecycling: {
    recyclableWaste: colors.blue,
    nonRecyclableWaste: colors.radicalRed,
    carbonFootprint: colors.cerulean,
    previousYear: colors.blue,
    currentYear: colors.cerulean,
  },
  colorsTemperature: [lighten(0.1, colors.radicalRed), colors.orange],
};

/**
 * Gauge chart
 */

const gaugeChart: CSSAttributeMap = {
  noteColor: colors.rockBlue,
};

/**
 * Section
 */

const section: CSSAttributeMap = {
  spacing: size.spacing.xl,
  verticalSpacing: size.spacing.mdPlus,
  spacingBetween: size.spacing.lg,
  borderRadius: '2px',
  shadow: `0 2px 4px 0 ${rgba(0, 0, 0, 0.05)}`,
};

/**
 * Sensor values modal
 */
const sensorValues: Record<'padding', CSSAttributeMap> = {
  padding: {
    mobile: size.spacing.md,
    tablet: size.spacing.lg,
    desktop: size.spacing.lg,
  },
};

/**
 * Statuses
 */
const status: CSSAttributeMap = {
  neutralColor: colors.midnight,
  neutralOkColor: colors.geyser,
  okColor: brandColors.caverionPrimaryGreen,
  warningColor: colors.orange,
  alarmColor: brandColors.magenta01,
  openColor: colors.cerulean,
  neutralLightColor: lighten(0.1, colors.geyser),
  warningLightColor: lighten(0.3, colors.orange),
  alarmLightColor: lighten(0.3, brandColors.magenta01),
};

const serviceOrder: CSSAttributeMap = {
  openColor: colors.cerulean,
  releasedColor: colors.cerulean,
  closedColor: brandColors.caverionPrimaryGreen,
  completedColor: brandColors.caverionPrimaryGreen,
  arrivedColor: colors.yellow,
  startedColor: colors.orange,
  partlyCompletedColor: colors.yellow,
  inProgressColor: colors.amethyst,
  postponedColor: colors.darkGray,
  plannedColor: colors.midnight,
  lateColor: colors.orange,
  overdueColor: brandColors.magenta01,
  draftColor: colors.rockBlue,
  cancelledColor: colors.mystic,
  rejectedColor: brandColors.magenta01,
  toBeMovedColor: colors.darkGray,
  delayedColor: brandColors.magenta01,
  requestColor: colors.concrete,
  undoneColor: colors.midnight,
  acceptedColor: colors.cerulean,
  removedColor: 'transparent',
};

/**
 * Hero
 */

const hero: Record<'height', CSSAttributeMap> = {
  height: {
    default: '150px',
    mobile: '122px',
    admin: '22rem',
    buildingAdmin: '18rem', // admin height minus navigation height
    customerAdmin: '18rem', // admin height minus navigation height
    overview: '480px',
  },
};

/**
 * Button
 */

export type ButtonTheme = {
  colors: CSSAttributeMap;
  textColors: CSSAttributeMap;
  borderColors: CSSAttributeMap;
  small: CSSAttributeMap;
  large: CSSAttributeMap;
};

const button: ButtonTheme = {
  colors: {
    submit: colors.emerald,
    cancel: colors.transparent,
    clear: colors.white,
    remove: colors.radicalRed,
    default: colors.cerulean,
  },
  textColors: {
    submit: colors.white,
    cancel: colors.darkGray,
    clear: colors.midnight,
    clearDisabled: colors.rockBlue,
    remove: colors.white,
    default: colors.white,
  },
  borderColors: {
    submit: colors.transparent,
    cancel: colors.transparent,
    clear: colors.midnight,
    clearDisabled: colors.rockBlue,
    remove: colors.transparent,
    default: colors.transparent,
  },
  small: {
    minWidth: '132px',
    height: '32px',
    lineHeight: '15px',
  },
  large: {
    minWidth: '152px',
    height: '42px',
    lineHeight: '20px',
  },
};

/**
 * Blueprint
 */

export type BlueprintTheme = {
  area: {
    colors: CSSAttributeMap;
    colorOptions: CSSAttributeArray;
  };
};

const blueprint: BlueprintTheme = {
  area: {
    colors: {
      default: colors.cerulean,
      available: colors.emerald,
      occupied: colors.radicalRed,
    },
    colorOptions: [colors.cerulean, colors.emerald, colors.blue],
  },
};

/**
 * Input
 */

export type InputTheme = {
  border: CSSAttributeMap<'static' | 'active' | 'error' | 'ok'>;
  shadow: CSSAttributeMap<'static' | 'active' | 'errorStatic' | 'errorActive' | 'searchStatic' | 'searchActive'>;
  font: {
    color: CSSAttributeMap<'default' | 'placeholder' | 'disabled'>;
    size: string;
    mobileSize: string;
    family: string;
  };
  padding: string;
  paddingVertical: string;
  paddingHorizontal: string;
  height: string;
  heightWithoutBorder: string;
  lineHeight: NumericValueMap<'default' | 'textarea'>;
  background: CSSAttributeMap<'default' | 'disabled'>;
  transition: string;
};

const input: InputTheme = {
  border: {
    static: `1px solid ${colors.lightGray}`,
    active: `1px solid ${colors.rockBlue}`,
    error: `1px solid ${colors.radicalRed}`,
    ok: `1px solid ${colors.emerald}`,
  },
  shadow: {
    static: `inset 0 2px 3px 0 ${rgba(0, 0, 0, 0.1)}`,
    active: `0 2px 20px 0 ${rgba(0, 0, 0, 0.05)}`,
    errorStatic: `inset 0 2px 3px 0 ${rgba(colors.radicalRed, 0.1)}`,
    errorActive: `0 2px 20px 0 ${rgba(colors.radicalRed, 0.05)}`,
    searchStatic: 'none',
    searchActive: `0 2px 20px 0 ${rgba(0, 0, 0, 0.05)}`,
  },
  font: {
    color: {
      default: colors.black,
      placeholder: colors.darkGray,
      disabled: colors.darkGray,
    },
    size: font.size.xs,
    mobileSize: font.size.sm,
    family: font.family.cairo,
  },
  padding: `${size.spacing.md} ${rem(0.875 * rootSize)}`,
  paddingVertical: size.spacing.md,
  paddingHorizontal: rem(0.875 * rootSize),
  height: `calc((2 * ${size.spacing.md}) + 1em + 2px)`, // top + bottom padding + font-height + borders
  heightWithoutBorder: `calc((2 * ${size.spacing.md}) + 1em)`, // top + bottom padding + font-height
  lineHeight: {
    default: font.lineHeight.sm,
    textarea: font.lineHeight.lg,
  },
  background: {
    default: colors.white,
    disabled: colors.alabaster,
  },
  transition: `border, box-shadow ${motion.default} ${motion.easing}`,
};

const navigation: CSSAttributeMap = {
  height: '4rem',
  sideWidth: '310px',
  sideTabletWidth: '255px',
  sideSmallWidth: '82px',
  transition: '0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
  spacing: '40px',
  tabletSpacing: '24px',
};

const skeleton: CSSAttributeMap = {
  backgroundColor: colors.lightGray,
  effectColor: colors.alabaster,
};

/**
 * Chart colors and correct ordering
 */
const chartColors: CSSAttributeArray = [
  chartColorOptions.chartTurqoise,
  chartColorOptions.chartDarkBlue,
  chartColorOptions.chartViolet,
  chartColorOptions.chartOrange,
  chartColorOptions.chartTeal,
  chartColorOptions.chartRock,
  chartColorOptions.chartMagenta,
  chartColorOptions.chartGreen,
];

const sriReportThumbnail: CSSAttributeMap = {
  padding: `${rem('12px', rootSize)} ${rem('14px', rootSize)}`,
  imagePadding: `${rem('13px', rootSize)} 0 ${rem('19px', rootSize)}`,
  statusFontSize: rem('12px', rootSize),
  nameFontSize: rem('14px', rootSize),
  dateFontSize: rem('11px', rootSize),
};

const sriReportStatusColors: CSSAttributeMap = {
  draft: colorOptions.orange,
  published: colorOptions.emerald,
};

const flowStateIndicatorColors: CSSAttributeMap = {
  missing: colorOptions.radicalRed,
  partiallyDone: colorOptions.radicalRed,
  done: colorOptions.emerald,
};

export type EditorFontTheme = {
  fontSize: string;
  fontWeight: number;
};

export type EditorTheme = {
  padding: string;
  mainTitle: EditorFontTheme;
  sectionTitle: EditorFontTheme;
  sectionSubTitle: EditorFontTheme;
  subSubTitle: EditorFontTheme;
};

const editor: EditorTheme = {
  padding: rem(2.5 * rootSize),
  mainTitle: {
    fontSize: font.size.xl,
    fontWeight: font.weight.semibold,
  },
  sectionTitle: {
    fontSize: font.size.lg,
    fontWeight: font.weight.semibold,
  },
  sectionSubTitle: {
    fontSize: font.size.md,
    fontWeight: font.weight.bold,
  },
  subSubTitle: {
    fontSize: font.size.sm,
    fontWeight: font.weight.bold,
  },
};

const layoutSpacing: CSSAttributeMap = {
  xs: size.spacing.xxs,
  sm: size.spacing.xs,
  md: size.spacing.md,
  lg: size.spacing.lg,
};

const theme = {
  fontSize,
  fontFamily,
  fontWeight,
  lineHeight,
  textColor,
  spacing,
  grid,
  getColumnWidth,
  menu,
  motion,
  media,
  shadows,
  status,
  serviceOrder,
  gaugeChart,
  section,
  cards,
  charts,
  sensorValues,
  hero,
  button,
  blueprint,
  zIndex,
  input,
  navigation,
  skeleton,
  iconSize,
  letterSpacing,
  chartColors,
  sriReportThumbnail,
  sriReportStatusColors,
  flowStateIndicatorColors,
  editor,
  layoutSpacing,
};

export default theme;
