import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';

const initialState = {
  meta: {},
};

export const LOAD_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META';
export const LOAD_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META_SUCCESS';
export const LOAD_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/LOAD_PARTNER_META_FAIL';

export const loadPartnerMeta =
  (partnerNumber, { refreshCache } = {}) =>
  async dispatch => {
    dispatch({
      type: LOAD_PARTNER_META,
      payload: { partnerNumber },
    });

    try {
      const result = await MasterDataService.getPartnerMeta(partnerNumber, { refreshCache });
      dispatch({
        type: LOAD_PARTNER_META_SUCCESS,
        payload: result,
        meta: { partnerNumber },
      });
      return result;
    } catch (error) {
      dispatch({
        type: LOAD_PARTNER_META_FAIL,
        meta: { partnerNumber },
        payload: error,
        error: true,
      });
      throw error;
    }
  };

export const UPDATE_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/UPDATE_PARTNER_META';
export const UPDATE_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/UPDATE_PARTNER_META_SUCCESS';
export const UPDATE_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/UPDATE_PARTNER_META_FAIL';

export const updatePartnerMeta = (partnerMetas, partnerNumber) => async dispatch => {
  dispatch({
    type: UPDATE_PARTNER_META,
    meta: { partnerNumber },
  });
  try {
    const result = await MasterDataService.upsertPartnerMeta(partnerMetas);
    dispatch({
      type: UPDATE_PARTNER_META_SUCCESS,
      payload: result,
      meta: { partnerNumber },
    });
    await dispatch(loadPartnerMeta(partnerNumber, { refreshCache: true }));
    return result;
  } catch (error) {
    dispatch({
      type: UPDATE_PARTNER_META_FAIL,
      payload: error,
      error: true,
      meta: { partnerNumber },
    });
    throw error;
  }
};

export const DELETE_PARTNER_META = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META';
export const DELETE_PARTNER_META_SUCCESS = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META_SUCCESS';
export const DELETE_PARTNER_META_FAIL = 'CUSTOMER_PLATFORM/PartnerMeta/DELETE_PARTNER_META_FAIL';

export const deletePartnerMeta = (partnerNumber, key) => async dispatch => {
  dispatch({
    type: DELETE_PARTNER_META,
    payload: {
      partnerNumber,
      key,
    },
  });

  try {
    await MasterDataService.deletePartnerMeta(partnerNumber, key);
    dispatch({
      type: DELETE_PARTNER_META_SUCCESS,
      meta: {
        partnerNumber,
        key,
      },
    });
    await dispatch(loadPartnerMeta(partnerNumber, { refreshCache: true }));
  } catch (error) {
    dispatch({
      type: DELETE_PARTNER_META_FAIL,
      payload: error,
      error: true,
      meta: {
        partnerNumber,
        key,
      },
    });
    throw error;
  }
};

export default createReducerFromMapping(
  {
    [LOAD_PARTNER_META]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.payload.partnerNumber]: {
          loading: true,
          error: undefined,
        },
      },
    }),
    [LOAD_PARTNER_META_SUCCESS]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.meta.partnerNumber]: {
          loading: false,
          meta: action.payload,
        },
      },
    }),
    [LOAD_PARTNER_META_FAIL]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.meta.partnerNumber]: {
          loading: false,
          error: action.payload,
        },
      },
    }),
    [UPDATE_PARTNER_META]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.meta.partnerNumber]: {
          ...state.meta[action.meta.partnerNumber],
          saving: true,
          error: undefined,
        },
      },
    }),
    [UPDATE_PARTNER_META_SUCCESS]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.meta.partnerNumber]: {
          ...state.meta[action.meta.partnerNumber],
          saving: false,
        },
      },
    }),
    [UPDATE_PARTNER_META_FAIL]: (state, action) => ({
      ...state,
      meta: {
        ...state.meta,
        [action.meta.partnerNumber]: {
          ...state.meta[action.meta.partnerNumber],
          saving: false,
          error: action.payload,
        },
      },
    }),
  },
  initialState
);
