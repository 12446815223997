import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { formatNumber } from 'utils/Number/decimalFormat';
import TableCell from 'components/SortableTable/SortableTableCell';

const Row = styled.tr`
  border-bottom: var(--border-thin) solid ${props => props.theme.colors.alabaster};
  font-family: ${props => props.theme.fontFamily.text};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${props => props.theme.colors.alabaster};
  }
`;

const Dot = styled.div`
  height: var(--size-sm);
  width: var(--size-sm);
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.layoutSpacing.sm};
`;

const Unit = styled.span`
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.darkGray};
`;

const Number = styled(TableCell)`
  text-align: right;
`;

const NumberCell = ({ number }) => {
  const profile = useSelector(state => state.profile.profile);
  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatedNumber = formatNumber(profile.language, number, options);
  return <Number>{formatedNumber}</Number>;
};

const KeyValueRow = ({ columns, rowData }) => {
  return (
    <Row>
      <TableCell>
        <Name>
          <Dot backgroundColor={rowData.color} />
          {rowData.label}
        </Name>
      </TableCell>
      <TableCell>
        <Unit>{rowData.unit}</Unit>
      </TableCell>
      <NumberCell number={rowData.value} />
      <NumberCell number={rowData.min} />
      <NumberCell number={rowData.max} />
    </Row>
  );
};

KeyValueRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rowData: PropTypes.object.isRequired,
};

export default KeyValueRow;
