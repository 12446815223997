import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ProfileService from 'services/profile';
import { InputBooleanCheckbox } from 'components/index';
import LocalizationContext from 'contexts/LocalizationContext';


const StyledTranslationKeysToggle = styled.p`
  color: ${props => props.theme.colors.darkGray};
  padding: 0 var(--size-xl) var(--size-xl) var(--size-xl);
  font-size: ${props => props.theme.font.size.xxs};
`;

StyledTranslationKeysToggle.displayName = 'StyledTranslationKeysToggle';

export const TranslationKeysToggle = ({ t }) => {
  const context = useContext(LocalizationContext);
  const { showTranslationKeys, updateTranslations } = context;
  return (
    <StyledTranslationKeysToggle>
      <InputBooleanCheckbox
        id="showTranslationKeys"
        property="showTranslationKeys"
        value={showTranslationKeys}
        onChange={async (property, value) => {
          const translationsChange = {
            showTranslationKeys: value === true,
          }
          if (value === true) {
            const translations = await ProfileService.translationRowNumbers();
            translationsChange.translationRowNumbers = translations;
          }
          updateTranslations({ ...context, ...translationsChange });
        }}
        label={t("Show translation keys")}
      />
    </StyledTranslationKeysToggle>
  );
}

TranslationKeysToggle.propTypes = {
  t: PropTypes.func.isRequired,
};
