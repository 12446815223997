import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, useTheme } from 'styled-components';
import range from 'lodash/range';
import sum from 'lodash/sum';
import xor from 'lodash/xor';
import omit from 'lodash/omit';
import { getMonthsShort } from 'utils/Date/dateFormatter';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { useTranslations } from 'decorators/Translations/translations';

import CalendarDaySelector from './CalendarDaySelector';
import MonthSelectorSkeleton from './CalendarMonthSelectorSkeleton';
import LinkArrow, { direction, LinkArrowWrapper, size } from 'components/LinkArrow/LinkArrow';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';

export const focustStyleForKeyboardUser = css`
  &:focus-visible {
    outline: ${props => props.selected && 'thin dotted'};
    outline-offset: ${props => (props.selected ? '-5px' : '-2px')};
  }
`;

export const Container = styled.div`
  position: relative;
  overflow-x: hidden;

  ${props => props.theme.media.tablet`
    overflow-x: visible;
  `};
`;

export const MonthSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(14, minmax(59px, 1fr));
  grid-auto-rows: 1fr;
  gap: var(--size-xs);

  ${props => props.theme.media.tablet`
    grid-template-columns: repeat(7, minmax(auto, 1fr));
  `}
  ${props => props.theme.media.desktop`
    grid-template-columns: repeat(14, minmax(auto, 1fr));
  `}
`;

export const Box = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 52px;
  padding: 0;

  border: var(--border-thin) solid ${props => (props.active ? 'var(--box-bc-active)' : 'var(--box-bc)')};
  background-color: ${props => (props.active ? 'var(--box-bg-active)' : 'var(--box-bg)')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:hover {
    border: var(--border-thin) solid var(--box-bc-active);
    background-color: var(--box-bg-active);
  }
  ${focustStyleForKeyboardUser}
  @media print {
    width: auto;
  }
`;

export const YearValue = styled.button`
  background-color: transparent;
  border-color: transparent;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-xs) 0;
  min-width: 50px;
  ${focustStyleForKeyboardUser}
`;

export const Year = styled(Box).attrs({
  as: 'div',
})`
  flex-direction: row;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  /** width in the grid will be two columns */
  grid-column: 1 / span 2;

  ${YearValue} {
    cursor: pointer;
    display: inline-block !important;
  }
`;

export const Month = styled(Box)`
  padding: var(--size-xs);

  @media print {
    display: ${props => props.active && 'inline-block !important'};
  }
`;

export const Value = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.semibold};
  color: ${props => (!props.children || props.disabled ? 'var(--supplemental-text-fg)' : 'var(--body-text-fg)')};
`;

export const Label = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  color: var(--supplemental-text-fg);
  text-align: center;
  white-space: nowrap;
  margin-top: var(--size-xxs);

  ${props => props.theme.media.desktop`
    white-space: normal;
  `}
`;

export const yearArrowHover = css`
  &:hover {
    background-color: var(--box-bg-active);
    fill: var(--blue-02);
  }
`;

const StyledLinkArrow = styled(LinkArrow)`
  padding: 0 var(--size-xs);
  align-self: stretch;
  ${yearArrowHover}
  ${focustStyleForKeyboardUser}

  ${LinkArrowWrapper} {
    width: auto;
  }

  @media print {
    display: none;
  }
`;

const CalendarMonthSelector = ({ year, months, day, counts, loading, disableMonths }) => {
  const [t] = useTranslations();
  const location = useLocation();
  const theme = useTheme();
  const history = useHistory();

  const monthNames = getMonthsShort();
  const { pathname, search } = location;
  const query = queryString.parse(search);

  const getYearLink = (year, reset) => {
    const nextQuery = queryString.stringify({
      ...omit(query, reset ? ['months', 'day'] : []),
      year,
      page: 1,
    });

    return `${pathname}?${nextQuery}`;
  };

  const getDayLink = date => {
    const nextQuery = queryString.stringify({
      ...query,
      year: date.getFullYear(),
      months: date.getMonth() + 1,
      day: date.getDate(),
    });

    return `${pathname}?${nextQuery}`;
  };

  const toggleMonth = month => {
    const nextQuery = queryString.stringify({
      ...query,
      months: xor(months, [month]),
      page: 1,
    });

    history.push(`${pathname}?${nextQuery}`);
  };

  const clearMonths = () => {
    const nextQuery = queryString.stringify({ ...omit(query, 'months'), page: 1 });
    history.push(`${pathname}?${nextQuery}`);
  };

  if (day) {
    const date = new Date(year, months[0] - 1, day);
    return (
      <CalendarDaySelector
        date={date}
        year={year}
        yearLink={getYearLink(year, true)}
        previousDayLink={getDayLink(subDays(date, 1))}
        nextDayLink={getDayLink(addDays(date, 1))}
      />
    );
  }

  if (loading) {
    return <MonthSelectorSkeleton lastMonth={12} />;
  }

  return (
    <Container>
      <HorizontalScroll>
        <MonthSelector>
          <Year active={!months.length} disabled={disableMonths}>
            <StyledLinkArrow
              to={getYearLink(year - 1)}
              direction={direction.LEFT}
              size={size.TINY}
              fill="var(--grey-100)"
              data-test-id="PrevYearLink"
            />
            <YearValue onClick={clearMonths}>
              <Value>{sum(counts)}</Value>
              <Label>
                {t('Year')} {year}
              </Label>
            </YearValue>
            <StyledLinkArrow
              to={getYearLink(year + 1)}
              direction={direction.RIGHT}
              size={size.TINY}
              fill="var(--grey-100)"
              data-test-id="NextYearLink"
            />
          </Year>
          {range(0, 12).map(index => {
            const active = months.includes(index + 1);
            return (
              <Month
                key={index}
                onClick={disableMonths ? undefined : () => toggleMonth(index + 1)}
                active={active}
                disabled={disableMonths}
              >
                <Value active={active} disabled={disableMonths}>
                  {counts[index]}
                </Value>
                <Label active={active} disabled={disableMonths}>
                  {monthNames[index]}
                </Label>
              </Month>
            );
          })}
        </MonthSelector>
      </HorizontalScroll>
    </Container>
  );
};

CalendarMonthSelector.propTypes = {
  year: PropTypes.number.isRequired,
  months: PropTypes.arrayOf(PropTypes.number).isRequired,
  day: PropTypes.number,
  counts: PropTypes.arrayOf(PropTypes.number).isRequired,
  loading: PropTypes.bool,
  disableMonths: PropTypes.bool,
};

export default CalendarMonthSelector;
