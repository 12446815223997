import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslations } from 'decorators/Translations/translations';

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.font.lineHeight.lg};
  color: ${props => props.theme.colors.black};
  margin-bottom: var(--size-xxs);
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: var(--size-xxs);
  background-color: ${props => props.backgroundColor};
`;

const Names = styled.div`
  display: flex;
  gap: var(--size-sm);
`;

const Name = styled.div`
  flex: 1;
  ${props => props.theme.media.desktop`
    flex: none;
  `}
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => props.theme.colors.darkGray};
`;

const Value = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.semibold};
  line-height: ${props => props.theme.font.lineHeight.md};
  color: ${props => props.theme.colors.black};
`;

const SensorsNames = ({ seriesName, xName, yName, color }) => {
  const [t] = useTranslations();

  return (
    <div>
      <Header>
        {seriesName}
        <Dot backgroundColor={color} />
      </Header>
      <Names>
        <Name>
          <Label>{`X-${t('Axis')}`}</Label>
          <Value>{xName}</Value>
        </Name>
        <Name>
          <Label>{`Y-${t('Axis')}`}</Label>
          <Value>{yName}</Value>
        </Name>
      </Names>
    </div>
  );
};

SensorsNames.propTypes = {
  seriesName: PropTypes.string,
  xName: PropTypes.string,
  yName: PropTypes.string,
  color: PropTypes.string,
};

export default SensorsNames;
