import { createReducerFromMapping } from 'redux/utils/index';
import first from 'lodash/first';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import IoT from 'services/iot';
import subYears from 'date-fns/subYears';
import startOfYear from 'date-fns/startOfYear';
import subDays from 'date-fns/subDays';
import toDate from 'date-fns/toDate';
import { isSameUTCDay } from 'utils/Date/date';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';

const initialState = {
  loaded: false,
  loading: [],
  kpi: {},
  byMonth: {},
  energyChartValues: {},
  energyChartLoading: false,
};

const dateNow = new Date();

export const LOAD_ENERGY = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY';
export const LOAD_ENERGY_SUCCESS = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_SUCCESS';
export const LOAD_ENERGY_FAIL = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_FAIL';

export const LOAD_ENERGY_DATA = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA';
export const LOAD_ENERGY_DATA_SUCCESS = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_SUCCESS';
export const LOAD_ENERGY_DATA_FAIL = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_FAIL';

export const LOAD_ENERGY_CHART_DATA = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_CHART_DATA';
export const LOAD_ENERGY_CHART_DATA_SUCCESS = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_CHART_SUCCESS';
export const LOAD_ENERGY_CHART_DATA_FAIL = 'CUSTOMER_PLATFORM/Energy/LOAD_ENERGY_DATA_CHART_FAIL';

export const loadEnergyChartValues = ({ functionalLocations }) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENERGY_CHART_DATA });
    try {
      const energyChartValues = await dispatch(IoT.getEnergyChartValues({ functionalLocations }));
      return dispatch({
        type: LOAD_ENERGY_CHART_DATA_SUCCESS,
        energyChartValues,
      });
    } catch {
      return dispatch({
        type: LOAD_ENERGY_CHART_DATA_FAIL,
      });
    }
  };
};

export function loadEnergyKPIToday(partnerNumber) {
  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      temperatureUnit: '°C',
    },
    limit: 1,
    order: 'windowEnd DESC',
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY, LOAD_ENERGY_SUCCESS, LOAD_ENERGY_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

export function loadEnergyKPI(partnerNumber, start, end) {
  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      windowStart: {
        between: [start.toISOString(), end.toISOString()],
      },
      temperatureUnit: '°C',
    },
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY, LOAD_ENERGY_SUCCESS, LOAD_ENERGY_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

export function loadEnergyData(partnerNumber) {
  const start = startOfYear(subYears(dateNow, 2));
  const end = dateNow;

  const filter = {
    where: {
      partnerNumber: partnerNumber,
      functionalLocation: null,
      windowStart: {
        between: [start.toISOString, end.toISOString()],
      },
      temperatureUnit: '°C',
    },
  };

  return {
    partnerNumber: partnerNumber,
    types: [LOAD_ENERGY_DATA, LOAD_ENERGY_DATA_SUCCESS, LOAD_ENERGY_DATA_FAIL],
    key: JSON.stringify([LOAD_ENERGY, filter]),
    filter,
    promise: (client, { filter }, accessToken) =>
      client.get('/iot/energies', {
        accessToken,
        params: {
          filter: JSON.stringify(filter),
        },
      }),
  };
}

function parsePartnerFLEnergyKPI(kpi, action) {
  const referenceDateStart = subDays(dateNow, 31);
  const referenceDateEnd = subDays(dateNow, 30);
  const partnerNumber = action.partnerNumber;

  const data = first(action.result);

  if (!kpi[partnerNumber]) {
    kpi[partnerNumber] = {};
  }

  if (data) {
    const resultStart = toDate(data.windowStart);

    if (isSameUTCDay(resultStart, referenceDateStart) || isSameUTCDay(resultStart, referenceDateEnd)) {
      kpi[partnerNumber].reference = data;
    } else {
      kpi[partnerNumber].current = data;
    }
  }

  return kpi;
}

function parseMonthlyFLEnergyData(data, action) {
  const partnerNumber = action.partnerNumber;
  const partnerData = [];

  forEach(action.result, myResult => {
    if (myResult.netArea == null || myResult.consumption == null) {
      return;
    }
    const resultStart = toDate(myResult.windowStart);
    const year = getYear(resultStart);
    const month = getMonth(resultStart);
    if (partnerData[year] == null) {
      partnerData[year] = [];
    }
    let monthConsumption = partnerData[year][month] || 0;
    monthConsumption += myResult.consumption;
    partnerData[year][month] = monthConsumption;
  });

  data[partnerNumber] = partnerData;
  return data;
}

export default createReducerFromMapping(
  {
    [LOAD_ENERGY]: state => ({ ...state, loading: true }),
    [LOAD_ENERGY_SUCCESS]: (state, action) => ({
      ...state,
      loaded: true,
      loading: false,
      kpi: (data => parsePartnerFLEnergyKPI(data, action))(cloneDeep(state.kpi)),
    }),
    [LOAD_ENERGY_FAIL]: (state, action) => ({ ...state, loaded: false, loading: false, error: action.error }),
    [LOAD_ENERGY_DATA]: state => ({ ...state, loading: true }),
    [LOAD_ENERGY_DATA_SUCCESS]: (state, action) => ({
      ...state,
      loaded: true,
      loading: false,
      byMonth: (data => parseMonthlyFLEnergyData(data, action))(cloneDeep(state.byMonth)),
    }),
    [LOAD_ENERGY_DATA_FAIL]: (state, action) => ({ ...state, loaded: false, loading: false, error: action.error }),
    [LOAD_ENERGY_CHART_DATA]: state => ({ ...state, energyChartLoading: true }),
    [LOAD_ENERGY_CHART_DATA_FAIL]: state => ({ ...state, chart: {}, energyChartLoading: false }),
    [LOAD_ENERGY_CHART_DATA_SUCCESS]: (state, { energyChartValues }) => ({
      ...state,
      energyChartLoading: false,
      energyChartValues,
    }),
  },
  initialState
);
