import React, { FC } from 'react';
import Icon from 'components/Icon/Icon';
import styled from 'styled-components';
import { useTranslations } from 'decorators/Translations/translations';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: var(--grey-100);
  font-size: 12px;
`;

type InfoProps = { className: string };

const DoubleClickAnnotationInfo: FC<InfoProps> = ({ className }) => {
  const [t] = useTranslations();
  return (
    <Container className={className}>
      <Icon size="SMALL" name="announcement-info" fill="var(--grey-100)" /> {t('Double click to add a note to chart')}
    </Container>
  );
};

export const AnnotationInfo = styled(DoubleClickAnnotationInfo)`
  width: 100%;
  justify-content: flex-end;
`;

export default DoubleClickAnnotationInfo;
