import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mapIconToSvg } from './utils';

const Legend = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
Legend.displayName = 'Legend';

const Sensor = styled.span`
  display: inline-flex;
  align-items: center;
  margin: var(--size-xxs) var(--size-xs);
  color: var(--blueprint-legend-text-fg);
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
`;
Sensor.displayName = 'Sensor';

const Icon = styled.span``;
Icon.displayName = 'Icon';

const IconWrapper = styled.div`
  display: inline-flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  margin-right: var(--size-xxs);
`;
IconWrapper.displayName = 'IconWrapper';

const BlueprintLegend = ({ t, icons }) => {
  const show = sensorType => icons && icons.indexOf(sensorType) !== -1;

  return (
    <Legend>
      {/* dangerouslySetInnerHTML is used because the icons are for openlayers in BlueprintMap */}
      {show('measuringPoint') && (
        <Sensor>
          <IconWrapper>
            <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.measuringPoint.data }} />
          </IconWrapper>
          {t('Measuring point')}
        </Sensor>
      )}
      {show('equipment') && (
        <Sensor>
          <IconWrapper>
            <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.equipment.data }} />
          </IconWrapper>
          {t('Equipment')}
        </Sensor>
      )}
      {show('device') && (
        <Sensor>
          <IconWrapper>
            <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.device.data }} />
          </IconWrapper>
          {t('Device')}
        </Sensor>
      )}
      {show('default') && (
        <Sensor>
          <IconWrapper>
            <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.default.data }} />
          </IconWrapper>
          {t('Other')}
        </Sensor>
      )}
      {(show('presenceUnavailable') ||
        show('presenceAvailable') ||
        show('seatUnavailable') ||
        show('seatAvailable')) && (
        <Fragment>
          <Sensor>
            <IconWrapper>
              <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.presenceAvailable.data }} />
            </IconWrapper>
            {t('Seat available')}
          </Sensor>
          <Sensor>
            <IconWrapper>
              <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.presenceUnavailable.data }} />
            </IconWrapper>
            {t('Seat occupied')}
          </Sensor>
        </Fragment>
      )}
      {(show('zoneUnavailable') || show('zoneAvailable')) && (
        <Fragment>
          <Sensor>
            <IconWrapper>
              <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.zoneAvailable.data }} />
            </IconWrapper>
            {t('Zone available')}
          </Sensor>
          <Sensor>
            <IconWrapper>
              <Icon dangerouslySetInnerHTML={{ __html: mapIconToSvg.zoneUnavailable.data }} />
            </IconWrapper>
            {t('Zone occupied')}
          </Sensor>
        </Fragment>
      )}
    </Legend>
  );
};

BlueprintLegend.propTypes = {
  t: PropTypes.func.isRequired,
  icons: PropTypes.array,
};

BlueprintLegend.defaultProps = {
  icons: [],
};

export default BlueprintLegend;
