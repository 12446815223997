import { parseISO, setYear } from 'date-fns';
import { useTranslations } from 'decorators/Translations/translations';
import { useCallback, useRef } from 'react';
import {
  getDrilldownIntervalDates,
  getMostGranularIntervalType,
  getNextIntervalType,
  getReferenceSeries,
  getTickInterval,
  getX,
  INTERVAL_TYPES,
} from './utils';
import isEmpty from 'lodash/isEmpty';
import { intervalTypeToGranularity } from 'components/Sensor/SensorAnnotations/utils';
import { activeBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import updateChartAnnotations from 'components/Sensor/SensorAnnotations/updateChartAnnotations';

const useComparisonDrilldown = (loadDrilldownData, reference, getAnnotationConfigs) => {
  // Translations
  const [t] = useTranslations();

  // Click handler for chart series
  const counter = useRef(0);
  const handledCategories = useRef([]);

  const onDrillup = useCallback(
    function (e) {
      updateChartAnnotations(
        this,
        getAnnotationConfigs(intervalTypeToGranularity(e?.seriesOptions?.intervalType, !activeBreakpoints().landscape))
      );
      const { pointInterval } = e.seriesOptions;
      this.xAxis[0].options.tickInterval = pointInterval;
    },
    [getAnnotationConfigs]
  );

  const onDrilldown = useCallback(
    async function (e, chart) {
      const date = new Date(e.category);
      const options = e?.point?.series?.options;

      const {
        intervalType,
        sensors,
        category,
        isOutdoorTemperature,
        _unit,
        name,
        index,
        type,
        legendIndex,
        yAxis,
        dashStyle,
        zIndex,
        lineWidth,
      } = options;

      // If there are for some reason more than one point in the same series, load the new data only once

      if (
        handledCategories.current.some(
          handledCategory => handledCategory.category === category && handledCategory.sensors === sensors
        )
      ) {
        counter.current++;
        return;
      }
      handledCategories.current.push({ category, sensors });
      const nextIntervalType = getNextIntervalType(intervalType);
      const mostGranularIntervalType = getMostGranularIntervalType(sensors[0]);
      if (nextIntervalType === intervalType) {
        return;
      }

      const currentYearDate = setYear(date, category);
      const [startDate, endDate] = getDrilldownIntervalDates(currentYearDate, nextIntervalType, category);
      const pointInterval = getTickInterval(nextIntervalType);

      // Get drilldown data per year: { { data: [...], category: 2020 }, { data: [...], category: 2021 } }
      chart.showLoading(t('Loading data'));
      const drilldowns = await loadDrilldownData({
        drilldown: { startDate, endDate, category, sensors, unit: _unit },
        intervalType: nextIntervalType,
        isOutdoorTemperature,
      });

      if (!isEmpty(drilldowns)) {
        const series = {
          name,
          data: drilldowns.data?.map(d => {
            const x = getX(parseISO(d.timestamp), nextIntervalType);
            return {
              useUTC: nextIntervalType !== INTERVAL_TYPES.HOUR,
              y: d.value,
              x,
              timestamp: d.timestamp,
              drilldown:
                nextIntervalType !== mostGranularIntervalType ? `${e.category}_${nextIntervalType}` : undefined,
            };
          }),
          pointInterval,
          pointStart: setYear(startDate, date.getFullYear()).getTime(), // All series use the same year as the datetime axis
          id: `${e.category}_${intervalType}`,
          sensors,
          intervalType: nextIntervalType,
          category,
          legendIndex,
          type,
          _unit,
          index,
          isOutdoorTemperature,
          yAxis,
          dashStyle,
          zIndex,
          lineWidth,
        };

        chart.addSingleSeriesAsDrilldown(e.point, series);
      }
      // Event is triggered for all the points. We'll load the data for all visible categories (in their own events) before we apply drilldown.
      const visiblePoints = e.points.filter(point => point?.series?.visible);
      if (counter.current === visiblePoints.length - 1) {
        // lastly add reference series to chart
        const referenceSeries = getReferenceSeries(
          reference,
          nextIntervalType,
          startDate.getTime(),
          sensors?.[0]?.sensorType?.unit
        );

        if (!isEmpty(referenceSeries)) {
          chart.addSingleSeriesAsDrilldown(e.point, referenceSeries);
        }

        updateChartAnnotations(
          chart,
          getAnnotationConfigs(intervalTypeToGranularity(nextIntervalType, !activeBreakpoints().landscape))
        );

        chart.hideLoading();
        chart.xAxis[0].options.tickInterval = pointInterval;
        chart.applyDrilldown();
        chart.redraw();

        counter.current = 0;
        handledCategories.current.length = 0;
      } else {
        counter.current++;
      }
    },
    [loadDrilldownData, t, reference, getAnnotationConfigs]
  );

  return { onDrilldown, onDrillup };
};

export default useComparisonDrilldown;
