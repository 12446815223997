import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { searchUserManualDocuments, CUSTOM_TYPE } from '../utils/UserManualUtils';

const debounceDelayMs = 300;

const StyledUserManualSearch = styled.div`
  width: 100%;
  padding: var(--size-md);
  ${props => props.theme.media.landscape`
    padding: var(--size-xl);
  `}
  line-height: 1.5;
`;
StyledUserManualSearch.displayName = 'UserManualSearch';

const StyledUserManualSearchInput = styled.input`
  height: 40px;
  width: 100%;
  padding: var(--size-xs);
`;
StyledUserManualSearchInput.displayName = 'UserManualSearchInput';

const StyledUserManualSearchResults = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: var(--size-xl);
  margin-top: 0;
  max-height: 50%;
  overflow: auto;
`;
StyledUserManualSearchResults.displayName = 'UserManualSearchResults';

const StyledUserManualSearchResult = styled.div`
  width: 100%;
  padding: var(--size-xs);
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
`;
StyledUserManualSearchResult.displayName = 'UserManualSearchResult';

const StyledUserManualSearchResultChild = styled(StyledUserManualSearchResult)`
  font-size: ${props => props.theme.font.size.xxs};
  padding: var(--size-xxs);
  padding-left: var(--size-xl);
`;

const UserManualSearch = ({ onResultClick, profile }) => {
  const [searchResults, setSearchResults] = React.useState([]);
  const [resultsVisible, setResultsVisible] = React.useState(false);

  const search = debounce(async searchTerm => {
    const pages = await searchUserManualDocuments(
      [CUSTOM_TYPE.USER_MANUAL_PAGE],
      searchTerm,
      profile.features.buildingAndPortfolioAdminTools
    );
    const hasResults = pages?.length > 0;
    setSearchResults(
      hasResults
        ? pages?.map(page => ({
            uid: page.uid,
            title: page.data?.title[0]?.text,
            sections: page.data?.body
              .filter(section => section.type.startsWith('heading'))
              .map(section => ({
                uid: section.text,
                title: section.text,
              })),
          }))
        : []
    );
    setResultsVisible(hasResults);
  }, debounceDelayMs);

  const doSearch = searchTerm => {
    if (!isEmpty(searchTerm)) {
      search(searchTerm);
    } else {
      setSearchResults([]);
      setResultsVisible(false);
    }
  };
  const onChange = event => doSearch(event.target.value);
  const onKeyUp = event => event.key === 'Enter' && doSearch(event.currentTarget.value);

  return (
    <StyledUserManualSearch>
      <StyledUserManualSearchInput
        type="search"
        placeholder="Search..."
        onChange={onChange}
        onKeyUp={onKeyUp}
        onFocus={() => setResultsVisible(true)}
        onBlur={() => setTimeout(() => setResultsVisible(false), 200)}
      />
      {resultsVisible && !isEmpty(searchResults) && (
        <StyledUserManualSearchResults>
          {searchResults.map((result, index) => (
            <>
              <StyledUserManualSearchResult key={index} onClick={() => onResultClick(result.uid)}>
                {result.title}
              </StyledUserManualSearchResult>
              {result.sections.map((section, index) => (
                <StyledUserManualSearchResultChild
                  key={`${result.title}-section-${index}`}
                  onClick={() => onResultClick(result.uid, section.title)}
                >
                  {`  ${section.title}`}
                </StyledUserManualSearchResultChild>
              ))}
            </>
          ))}
        </StyledUserManualSearchResults>
      )}
    </StyledUserManualSearch>
  );
};

UserManualSearch.propTypes = {
  profile: PropTypes.object.isRequired,
  onResultClick: PropTypes.func.isRequired,
};

export default UserManualSearch;
