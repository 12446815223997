export const findSystem = (disciplines, discipline) =>
  disciplines?.all?.find(fl => fl.functionalLocation === discipline);

export const findTopLevelFunctionalLocation = (functionalLocations, system) =>
  functionalLocations?.find(fl => system?.path?.includes(fl.functionalLocation));

export const findTopLevelFunctionalLocationForSystem = (functionalLocations, disciplines, discipline) => {
  const system = findSystem(disciplines, discipline);
  if (system) {
    const topLevelFunctionalLocation = findTopLevelFunctionalLocation(functionalLocations, system);
    return topLevelFunctionalLocation?.functionalLocation;
  }
  return null;
};

export const findCategoryById = (categories, categoryId) => categories?.find(category => category.id === categoryId);

export const findCategoryByName = (categories, categoryName) =>
  categories?.find(category => category.category === categoryName);
