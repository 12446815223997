export const legacyColorApproximation = {
  black: 'var(--black)',
  white: 'var(--white)',
  mystic: 'var(--grey-20)',
  geyser: 'var(--grey-30)',
  rockBlue: 'var(--grey-50)',
  // This is quite far from the legacy one
  kashmirBlue: 'var(--blue-02)',
  blue: 'var(--blue-01)',
  midnight: 'var(--blue-01)',
  alabaster: 'var(--grey-08)',
  concrete: 'var(--grey-10)',
  silver: 'var(--grey-40)',
  lightGray: 'var(--grey-20)',
  darkGray: 'var(--grey-100)',
  radicalRed: 'var(--red-01)',
  orange: 'var(--orange-01)',
  yellow: 'var(--orange-01)',
  emerald: 'var(--green-01)',
  cerulean: 'var(--blue-02)',
  amethyst: 'var(--violet-01)',
  serenade: 'var(--grey-08)',
  peachOrange: 'var(--orange-01-light)',
  violet: 'var(--violet-01)',
  transparent: 'transparent',
};
