import { connect } from 'react-redux';
import {
  loadServiceOrderWithToken,
  loadServiceOrderFilesWithToken,
  uploadServiceOrderFileWithToken,
  downloadServiceOrderFileWithToken,
} from 'redux/modules/index';

const LOADING_KEY = 'technician';
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const mapStateToProps = (state, props) => ({
  serviceOrder: state.serviceModule.tokens[props.token] || EMPTY_OBJECT,
  serviceOrderDocuments: state.file.tokens[props.token] || EMPTY_ARRAY,
  loadingServiceOrder: state.serviceModule.loading[LOADING_KEY] || false,
  loadingDocuments: state.file.loading[LOADING_KEY] || false,
});

const mapDispatchToProps = {
  loadServiceOrderWithToken: (token, noCache) => loadServiceOrderWithToken(token, LOADING_KEY, noCache),
  loadServiceOrderFilesWithToken: token => loadServiceOrderFilesWithToken(token, LOADING_KEY),
  uploadServiceOrderFileWithToken,
  downloadServiceOrderFileWithToken,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
