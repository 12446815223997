import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import { fi, sv, nb, da, lt, enGB, de, ru } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker-v3';
import { setLocale } from 'yup';

import { getMonths, getMonthsShort, getWeekdays } from './dateFormatter';
import { getChartTranslations } from 'components/Charts/utils';

registerLocale('fi', fi);
registerLocale('sv', sv);
registerLocale('nb', nb);
registerLocale('da', da);
registerLocale('lt', lt);
registerLocale('en-gb', enGB);
registerLocale('de', de);
registerLocale('ru', ru);

const defaultLocale = 'en-gb';
const conf = {
  globalLocale: defaultLocale,
};

const setGlobalLocale = (locale = defaultLocale, t) => {
  let localeToUse = locale || defaultLocale;
  localeToUse = localeToUse === 'en' ? 'en-gb' : localeToUse;

  conf.globalLocale = localeToUse;
  window.__localeId__ = localeToUse;
  setDefaultLocale(localeToUse);

  const highchartsOptions = {
    lang: {
      months: getMonths(),
      shortMonths: getMonthsShort(),
      weekdays: getWeekdays(),
      ...getChartTranslations(t),
    },
    // TODO: setting global time.getTimezoneOffset is deprecated, refactor to local chart settings
    time: {
      getTimezoneOffset: timestamp => {
        if (isNaN(timestamp)) {
          console.log('Note: timestamp was NaN on getTimezoneOffset');
          return 0;
        }
        const date = new Date(timestamp);
        return date.getTimezoneOffset();
      },
    },
  };

  // Configure Highcharts globally to use the correct language in month and weekday names.
  Highcharts.setOptions(highchartsOptions);
  Highstock.setOptions(highchartsOptions);

  // Configure yup to have unified error message data, that we can later translate
  // https://github.com/jquense/yup#using-a-custom-locale-dictionary

  // original validation errors: https://github.com/jquense/yup/blob/master/src/locale.ts

  setLocale({
    mixed: {
      default: ({ path }) => t('{0} is invalid', path),
      required: ({ path }) => t('{0} is a required field', path),
      oneOf: ({ path, values }) => t('{0} must be one of the following values: {1}', path, values),
      notOneOf: ({ path, values }) => t('{0} must not be one of the following values: {1}', path, values),
      notType: ({ path, type, value }) => t('{0} must be a {1} type, was: {2}', path, type, typeof value),
      defined: ({ path }) => t('{0} must be defined', path),
    },
    string: {
      length: ({ path, length }) => t('{0} must be exactly {1} characters', path, length),
      min: ({ path, min }) => t('{0} must be at least {1} characters', path, min),
      max: ({ path, max }) => t('{0} must be at most {1} characters', path, max),
      matches: ({ path, regex }) => t('{0} must match the following: "{1}"', path, regex),
      email: ({ path }) => t('{0} must be a valid email', path),
      url: ({ path }) => t('{0} must be a valid URL', path),
      uuid: ({ path }) => t('{0} must be a valid UUID', path),
      trim: ({ path }) => t('{0} must be a trimmed string', path),
      lowercase: ({ path }) => t('{0} must be a lowercase string', path),
      uppercase: ({ path }) => t('{0} must be a upper case string', path),
    },
    number: {
      min: ({ path, min }) => t('{0} must be greater than or equal to {1}', path, min),
      max: ({ path, max }) => t('{0} must be less than or equal to {1}', path, max),
      lessThan: ({ path, less }) => t('{0} must be less than {1}', path, less),
      moreThan: ({ path, more }) => t('{0} must be greater than {1}', path, more),
      positive: ({ path }) => t('{0} must be a positive number', path),
      negative: ({ path }) => t('{0} must be a negative number', path),
      integer: ({ path }) => t('{0} must be an integer', path),
    },
    date: {
      min: ({ path, min }) => t('{0} field must be later than {1}', path, min),
      max: ({ path, max }) => t('{0} field must be at earlier than {1}', path, max),
    },
    boolean: {
      isValue: ({ path, value }) => t('{0} field must be {1}', path, value),
    },
    object: {
      noUnknown: ({ path, unknown }) => t('{0} field has unspecified keys: {1}', path, unknown),
    },
    array: {
      min: ({ path, min }) => t('{0} field must have at least {1} items', path, min),
      max: ({ path, max }) => t('{0} field must have less than or equal to {1} items', path, max),
      length: ({ path, length }) => t('{0} must have {1} items', path, length),
    },
  });
};

export default setGlobalLocale;
