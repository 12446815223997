import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import queryString from 'query-string';
import memoizeOne from 'memoize-one';
import { History } from 'history';

const memoizedQuery = memoizeOne(search => queryString.parse(search));

const memoizedCreateSetQuery =
  (useHash: boolean, history: History) =>
  (newQuery: object, options = { replace: false, pathname: undefined, useReplace: false }) => {
    let newSearch = {};
    if (!options.replace) {
      // We use window.location.hash and window.location.search to prevent setQuery from changing when query changes
      newSearch = { ...queryString.parse(useHash ? window.location.hash : window.location.search) };
    }
    newSearch = { ...newSearch, ...newQuery };

    const newState = {
      pathname: options.pathname,
      search: queryString.stringify({ ...newSearch }),
    };

    if (options.useReplace) {
      history.replace(newState);
    } else {
      history.push(newState);
    }
  };

export const useQuery = ({ useHash = false } = {}) => {
  const location = useLocation();
  const history = useHistory();
  const query = memoizedQuery(useHash ? location.hash : location.search);
  const setQuery = useMemo(() => memoizedCreateSetQuery(useHash, history), [useHash, history]);
  return {
    query,
    setQuery,
  };
};

export default useQuery;
