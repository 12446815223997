import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslations } from 'decorators/Translations/translations';

import Svg from 'components/Svg/Svg';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';
import { StyledContextualHelpButton } from 'components/ContextualHelp/ContextualHelpButton';

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.hasCtxHelp ? 'space-between' : 'flex-end')};
`;

const IconWrapper = styled.div`
  padding: var(--size-xs);
  display: flex;
  align-items: center;
  max-height: ${props => props.theme.iconSize.xxl};

  ${StyledContextualHelpButton} {
    padding: 0;
    svg {
      font-size: ${props => props.theme.iconSize.sm};
    }
  }
`;

const Icon = styled(Svg)`
  font-size: ${props => props.theme.iconSize.sm};
  fill: var(--opi-card-icon-fg);
`;

const OPICardIcons = ({ clickEventType, contextualHelp, hasClick, title }) => {
  const [t] = useTranslations();

  if ((hasClick && !!clickEventType) || !!contextualHelp) {
    const hasCtxHelp = contextualHelp && t(contextualHelp.text) !== contextualHelp.text;

    return (
      <IconContainer hasCtxHelp={hasCtxHelp}>
        {hasCtxHelp && (
          <IconWrapper onClick={e => e.stopPropagation()}>
            <ContextualHelp
              title={title}
              text={contextualHelp.text}
              link={contextualHelp.link}
              override={contextualHelp.override}
            />
          </IconWrapper>
        )}
        {hasClick && clickEventType && (
          <IconWrapper>
            <Icon name="open-modal" />
          </IconWrapper>
        )}
      </IconContainer>
    );
  }
  return null;
};

OPICardIcons.propTypes = {
  clickEventType: PropTypes.oneOf(['openModal', 'redirect']),
  contextualHelp: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    override: PropTypes.bool,
  }),
  hasClick: PropTypes.bool,
  title: PropTypes.string,
};

export default OPICardIcons;
