import React from 'react';
import styled from 'styled-components';

import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import PageTemplate from './PageTemplate';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import MarkdownContent from 'components/Announcement/MarkdownContent';
import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';
import { Title } from './StyledElements';

const StyledContent = styled.div`
  text-align: left;
  margin-bottom: var(--size-lg);
`;

const AdditionalInfo = styled.p`
  margin-top: var(--size-lg);
`;

export const LoginHelp = () => {
  const { t, selectedLanguage, setSelectedLanguage, translationsReady } = useUnauthenticatedTranslations();

  const languageSelector = (
    <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
  );
  return (
    <PageTemplate title={t('Login Help')} languageSelector={languageSelector}>
      <Title>{t('Having trouble logging in?')}</Title>
      <StyledContent>
        {translationsReady && <MarkdownContent content={t('login_help_text')} />}
        <AdditionalInfo>{t('If the problem persists, please contact local support for further assistance.')}</AdditionalInfo>
      </StyledContent>
      <PrimaryButton large add reactLink to="/login">{t('Go to login')}</PrimaryButton>
    </PageTemplate>
  );
};

export default LoginHelp;
