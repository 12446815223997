import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const getFunctionalLocationOpenHours = (request, functionalLocation) =>
  request.get({
    apiPath: `${MASTER_DATA_PATH}/v2/functionalLocationOpenHours/find`,
    query: { functionalLocation },
    noCache: true,
  });

export const upsertFunctionalLocationOpenHours = (request, openHours) =>
  request.put({
    apiPath: `${MASTER_DATA_PATH}/v2/functionalLocationOpenHours/upsertWeeklyOpenHours`,
    data: openHours,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  getFunctionalLocationOpenHours,
  upsertFunctionalLocationOpenHours
});
