import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';
import PublicServiceRequest from 'containers/Application/ServiceRequest/public/PublicServiceRequest';
import LanguageSelector from 'containers/Technician/LanguageSelector/LanguageSelector';
import ErrorPageContent from 'components/ErrorPage/ErrorPageContent';
import BuildingFloors from './BuildingFloors/BuildingFloors';
import Loading from 'components/Loading/Loading';

import { VIEW_TYPES, VIEW_TYPE_LABELS } from 'components/PublicView/utils';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import { customerPlatformWithMultipliedFontSizes } from 'styles/themes';
import usePublicView from './usePublicView';

const VIEW_TYPE_COMPONENTS = {
  [VIEW_TYPES.SERVICE_REQUEST_FORM_VIEW]: PublicServiceRequest,
  [VIEW_TYPES.BUILDING_FLOORS_VIEW]: BuildingFloors,
};

const getCustomTheme = fontSizeMultiplier => {
  if (fontSizeMultiplier && fontSizeMultiplier !== 1) {
    return customerPlatformWithMultipliedFontSizes(fontSizeMultiplier);
  }
};

const Retry = styled.p`
  color: ${props => props.theme.colors.black};
  text-align: center;
  font-size: ${props => props.theme.font.size.xxxl};
  font-weight: ${props => props.theme.font.weight.normal};
`;

export const PublicView = ({ match }) => {
  const id = match?.params?.id;
  const { t, selectedLanguage, setSelectedLanguage } = useUnauthenticatedTranslations();
  const [view, loading, error] = usePublicView(id);

  if (!id || error?.statusCode === 404 || error?.statusCode === 400) {
    return (
      <Anonymous title={t('Page not found')}>
        <AnonymousHeader />
        <ErrorPageContent title={t('Page not found')} />
      </Anonymous>
    );
  }

  if (error?.statusCode === 403) {
    return (
      <Anonymous title={t('Disabled')}>
        <AnonymousHeader />
        <ErrorPageContent title={t('Public link has been disabled')} />
      </Anonymous>
    );
  }

  if (error) {
    return (
      <Anonymous title={t('Error')}>
        <AnonymousHeader />
        <Retry>{t('Retrying in one minute')}...</Retry>
      </Anonymous>
    );
  }

  if (loading) {
    return (
      <Anonymous>
        <AnonymousHeader />
        <Loading showLogo={false} />
      </Anonymous>
    );
  }

  const title = t(VIEW_TYPE_LABELS[view?.viewType]);
  const Component = VIEW_TYPE_COMPONENTS[view?.viewType];
  const isDefaultUsageType = view?.usageType === 'default';
  const customTheme = getCustomTheme(view?.fontSizeMultiplier);

  if (!Component) {
    return null;
  }

  return (
    <Anonymous title={title} noFooter={!isDefaultUsageType} noHeader={!isDefaultUsageType} customTheme={customTheme}>
      {isDefaultUsageType && (
        <AnonymousHeader>
          <LanguageSelector selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
        </AnonymousHeader>
      )}
      <Component publicView={view} language={selectedLanguage} />
    </Anonymous>
  );
};

PublicView.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PublicView;
