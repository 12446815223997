import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import ProfileService from 'services/profile';
import useQuery from 'decorators/Query/useQuery';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import { getWelcomeUrlForLanguage } from 'utils/Data/language';
import Loading from 'components/Loading/Loading';
import PageTemplate from './PageTemplate';
import themes from '../../../styles/themes';
import { InputRow, InputLabel, InputText, InputPassword, InputForm, ErrorText } from 'components/index';
import PrimaryButton from 'components/Button/PrimaryButton';
import { Title, Description, Note } from './StyledElements';
import Button from 'components/Button/PrimaryButton';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  ${Button} {
    margin: var(--size-xs);
  }
`;

const StyledFormContent = styled.div`
  ${props => props.theme.media.portrait`
    margin: 0 var(--size-xxl);
  `}
  ${ButtonContainer} {
    width: 100%;
    margin-top: var(--size-lg);
    margin-bottom: var(--size-sm);
  }
`;

const WelcomeNewUser = () => {
  const [welcomeProfile, setWelcomeProfile] = useState();
  const [loading, setLoading] = useState();
  const [submitting, setSubmitting] = useState();
  const [error, setError] = useState();
  const [model, setModel] = useState({});

  const { query } = useQuery();
  const { t, translationsReady } = useUnauthenticatedTranslations(welcomeProfile?.language);

  useEffect(() => {
    setLoading(true);
    setError(null);
    ProfileService.profileByVerificationCode(query.code)
      .then(setWelcomeProfile)
      .catch(error => {
        if (error.statusCode === 404) {
          setError('Your profile might be verified already.');
        } else {
          setError('If the problem persists, please contact local support for further assistance.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [query.code, t]);

  const onSubmit = useCallback(async () => {
    if (model.password !== model.passwordAgain) {
      setError('Password and password verification do not match.');
      return;
    }
    setError(null);
    setSubmitting(true);
    try {
      const result = await ProfileService.verifyProfile(query.code, model.password);
      setWelcomeProfile(result);
    } catch (error) {
      if (error.statusCode === 400) {
        setError('Setting password failed! Check that the password meets complexity requirements.');
      } else {
        setError('Something went wrong');
      }
    } finally {
      setSubmitting(false);
    }
  }, [query.code, model]);

  const setProperty = useCallback(
    (property, value) => setModel(currentModel => ({ ...currentModel, [property]: value })),
    []
  );

  if (loading !== false || !translationsReady) {
    return (
      <ThemeProvider theme={themes.customerPlatform}>
        <Loading showLogo={false} />
      </ThemeProvider>
    );
  }

  if (welcomeProfile?.verified) {
    const redirectUrl = `${getWelcomeUrlForLanguage(welcomeProfile.language)}?username=${welcomeProfile.username}`;
    setTimeout(() => {
      window.location = redirectUrl;
    }, 5000);
    return (
      <PageTemplate title={t('Your password has been saved')}>
        <Title>{t('Your password has been saved')}</Title>
        <Description>{t('You will be automatically redirected to the login page in 5 seconds')}</Description>
        <Note>{t('{0} if nothing happens', <a href={redirectUrl}>{t('Click here')}</a>)}</Note>
      </PageTemplate>
    );
  }

  const title = t('Welcome to SmartView');
  if (!welcomeProfile) {
    return (
      <PageTemplate title={title}>
        <Description>{t('There was a problem loading your profile.')}</Description>
        <Note>{t(error)}</Note>
        <ButtonContainer>
          <Button large reactLink to="/">
            {t('Go to frontpage')}
          </Button>
          <Button large add onClick={() => window.location.reload()}>
            {t('Try again')}
          </Button>
        </ButtonContainer>
      </PageTemplate>
    );
  }

  return (
    <PageTemplate title={title}>
      <Title>{t(title)}</Title>
      <Description>{t('Please begin by setting your password')}</Description>
      <StyledFormContent>
        <InputForm id="newUserForm" model={model} onPropertyChange={setProperty} onSubmit={onSubmit}>
          <Note>
            {t('Save your password for further usage. You will need it in the next step to login to SmartView.')}
          </Note>
          <InputRow fullRow dense>
            <InputLabel id="username" text={t('Username')} />
            <InputText id="username" value={welcomeProfile?.username} readonly />
          </InputRow>
          <InputRow required fullRow dense>
            <InputLabel id="password" text={t('Password')} />
            <InputPassword id="password" property="password" placeholder={t('Choose your password')} />
          </InputRow>
          <InputRow required fullRow dense>
            <InputLabel id="passwordAgain" text={t('Repeat password')} />
            <InputPassword id="passwordAgain" property="passwordAgain" placeholder={t('Repeat your password')} />
          </InputRow>
          <ButtonContainer>
            <PrimaryButton confirm large submit loading={submitting} disabled={loading}>
              {t('Save')}
            </PrimaryButton>
          </ButtonContainer>
        </InputForm>
        {error && <ErrorText>{t(error)}</ErrorText>}
      </StyledFormContent>
    </PageTemplate>
  );
};

export default WelcomeNewUser;
