import { TableWindowVirtualizer } from 'components/VirtualizedTable/useVirtualizedTable';

/**
 * Scroll table window virtualizer to the first item. If there are no items, do nothing.
 */
export function scrollWindowVirtualizerToTop(virtualizer?: TableWindowVirtualizer | null): void {
  if (!virtualizer) {
    return;
  }

  if (virtualizer.getVirtualItems().length > 0) {
    virtualizer.scrollToIndex(0, { align: 'start' });
  }
}
