import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SkeletonText from 'components/Skeletons/SkeletonText';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: var(--size-sm) var(--size-md);
  margin-bottom: var(--size-xxs);
  transition: ${props => props.theme.motion.quick} background-color ${props => props.theme.motion.easing};
  order: 2;
  height: 56px;

  svg {
    padding-bottom: 2px;
  }

  ${props => props.theme.media.landscape`
    border-bottom: 1px solid ${props => props.theme.colors.mystic};
    border-top: ${props => props.position === 'bottom' && `1px solid ${props.theme.colors.mystic}`};
    order: ${props => (props.position === 'top' ? 0 : 1)};
    justify-content: space-between;
    margin-bottom: 0;
    height: 42px;
  `}
`;
Header.displayName = 'Header';

const Heading = styled.h3`
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.fontWeight.heading};
  color: ${props => props.theme.colors.black};
  text-align: center;

  ${props => props.theme.media.landscape`
    text-align: left;
  `}
`;
Heading.displayName = 'Heading';

const StatusInfoHeader = props => {
  const { t, text, hasHover, loading, ctxHelpText, ctxHelpTitle, ctxHelpOverride, position, ctxHelpPosition } = props;

  if (loading) {
    return (
      <Header position={position}>
        <SkeletonText style={{ margin: 'auto' }} />
      </Header>
    );
  }

  return (
    <Header hasHover={hasHover} position={position}>
      <Heading data-test-id="StatusInfo-Header">{text}</Heading>
      {ctxHelpText && (
        <ContextualHelp
          title={ctxHelpTitle}
          text={ctxHelpText}
          override={ctxHelpOverride}
          position={ctxHelpPosition}
        />
      )}
    </Header>
  );
};

export default StatusInfoHeader;

StatusInfoHeader.defaultProps = {
  text: '',
  hasHover: false,
  loading: false,
  ctxHelpText: null,
  ctxHelpTitle: null,
  ctxHelpOverride: false,
  position: 'top',
  ctxHelpPosition: 'top',
};

StatusInfoHeader.propTypes = {
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  hasHover: PropTypes.bool,
  loading: PropTypes.bool,
  ctxHelpText: PropTypes.string,
  ctxHelpTitle: PropTypes.string,
  ctxHelpOverride: PropTypes.bool,
  position: PropTypes.string,
  ctxHelpPosition: PropTypes.string,
};
