import React, { PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

export const dialogAnimation = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/**
 * Dialog animatable UI body component
 */
const StyledDialogAnimate = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media print {
    position: static;
    width: auto;
    height: auto;
  }
`;

/**
 * We use pre-centered element for animatable content
 * to prevent glitches with rendering and better performance
 *
 * Mobile: children slide from down to up
 * Landscape: children emerge to view
 */
const Animation = styled.div`
  animation: ${dialogAnimation} ${props => props.theme.motion.default} ${props => props.theme.motion.easing} forwards;
  transform: translateY(100%);

  ${props => props.theme.media.landscape`
    transform: translateY(-1rem);
    opacity: 0;
  `}

  @media print {
    opacity: 1;
    transform: none;
  }
`;

export const DialogAnimate = ({ children }: PropsWithChildren<unknown>) => (
  <StyledDialogAnimate data-test-id="DialogAnimate">
    <Animation>{children}</Animation>
  </StyledDialogAnimate>
);

DialogAnimate.displayName = 'DialogAnimate';

export default DialogAnimate;
