import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const CancelAssignmentButton = ({ t, ...buttonProps }) => (
  <PrimaryButton large reveal {...buttonProps}>
    {t('Revert to draft')}
  </PrimaryButton>
);

CancelAssignmentButton.propTypes = {
  t: PropTypes.func.isRequired,
};

export default CancelAssignmentButton;
