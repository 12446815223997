import * as ICAL from 'ical.js';
import * as utils from './utils';

class Schedule extends ICAL.Component {
  constructor(data) {
    if (data) {
      super(utils.parseToJCAL(data));
    } else {
      super(utils.parseToJCAL(utils.initSchedule()));
    }
    this.event = new ICAL.Event(utils.getEventComponent(this));
  }

  getRecurrenceInterval() {
    return utils.getRecurData(this).interval;
  }

  setRecurrenceInterval(interval) {
    utils.getRecurData(this).fromData({ interval });
  }

  getRecurrenceFrequency() {
    return utils.getRecurData(this).freq;
  }

  setRecurrenceFrequency(frequency) {
    utils.getRecurData(this).fromData({ freq: frequency });
  }

  getRecurrenceStart() {
    return utils.getStart(this.event);
  }

  setRecurrenceStart(start) {
    const startTime = utils.dateToTime(start);
    this.event.startDate = startTime;
    utils.getEventComponent(this).updatePropertyWithValue('dtstamp', startTime);
  }

  getRecurrenceByParts() {
    return utils.parseParts(utils.getRecurData(this).parts);
  }

  setRecurrenceByParts(parts) {
    const serializedParts = utils.serializeParts(parts);
    utils.getRecurData(this).fromData({ parts: serializedParts });
  }

  getRecurrenceEnd() {
    return utils.getEnd(this);
  }

  setRecurrenceEnd(end) {
    utils.getRecurData(this).fromData({ until: utils.dateToTime(end) });
  }

  createModel() {
    return {
      [utils.Recurrence.INTERVAL]: this.getRecurrenceInterval(),
      [utils.Recurrence.FREQUENCY]: this.getRecurrenceFrequency(),
      [utils.Recurrence.PARTS]: this.getRecurrenceByParts(),
      [utils.Recurrence.START]: this.getRecurrenceStart(),
      [utils.Recurrence.END]: this.getRecurrenceEnd(),
    };
  }

  setFromModel(model) {
    if (model[utils.Recurrence.INTERVAL]) {
      this.setRecurrenceInterval(model[utils.Recurrence.INTERVAL]);
    }

    if (model[utils.Recurrence.FREQUENCY]) {
      this.setRecurrenceFrequency(model[utils.Recurrence.FREQUENCY]);
    }

    this.setRecurrenceByParts(model[utils.Recurrence.PARTS]);

    if (model[utils.Recurrence.START]) {
      this.setRecurrenceStart(model[utils.Recurrence.START]);
    }

    if (model[utils.Recurrence.END]) {
      this.setRecurrenceEnd(model[utils.Recurrence.END]);
    }
  }

  toString() {
    utils.update(this);
    return super.toString();
  }
}

export default Schedule;
