import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LocationColumn = ({ data }) => {
  if (data.name) {
    return (
      <Link
        to={`/${data.partnerNumber}/FunctionalLocation/${data.functionalLocation}/`}
        onClick={event => event.stopPropagation()}
      >
        {data.name}
      </Link>
    );
  }
  return null;
};

LocationColumn.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    partnerNumber: PropTypes.string,
    functionalLocation: PropTypes.string,
  }),
};

export default LocationColumn;
