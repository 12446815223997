import head from 'lodash/head';
import round from 'lodash/round';
import times from 'lodash/times';
import sum from 'lodash/sum';
import setWith from 'lodash/setWith';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import each from 'lodash/each';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import mean from 'lodash/mean';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import maxBy from 'lodash/maxBy';
import { getMonthsShort } from 'utils/Date/dateFormatter';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import parseISO from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import isSameDay from 'date-fns/isSameDay';
import subYears from 'date-fns/subYears';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import setMonth from 'date-fns/setMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import memoizeOne from 'memoize-one';
import subMonths from 'date-fns/subMonths';
import isValid from 'date-fns/isValid';

import { totalEnergyConsumptionTypes, getUnitBySensorType, isEnergyRatingSensor } from 'utils/Data/values';

const now = new Date();
const monthAgo = subMonths(now, 1);
const endOfLastMonth = endOfMonth(monthAgo);

export const co2EmissionMultiplier = 217; // Unit is g CO2/kWh
export const co2EmissionMultiplierMetaKey = 'energy/co2_emission_multiplier';
export const renewabilityFactorMetaKey = 'energy/renewability_factor';

const roundAndConvertEnergyValue = (value, unit) => round(unit === 'MWh' ? value / 1000 : value);

const isValueOverYearOld = value => {
  const date = parseISO(value?.timestamp);
  if (isValid(date) && date.getTime() < subYears(now, 1).getTime()) {
    return true;
  }
  return false;
};

// Get UI ready energy values
export const getEnergyValues = (values, theme, meta, energySensors) => {
  const monthlyData = getMonthlyConsumption(values);
  const categories = getMonthsShort();
  const series = [];
  const yearTotals = {};
  const years = keys(monthlyData);
  const colors = theme.charts.colorsEnergy.slice(0);
  let unit = getUnitBySensorType(head(values).type);
  // Convert kWh to MWh
  if (unit === 'kWh') {
    unit = 'MWh';
  }

  const granularityToZoomTypeMap = {
    month: 'year',
    day: 'month',
    hour: 'date',
  };

  let maxZoomLevel = ZOOM_TYPE.hour;
  forEach(values, value => {
    if (!value) {
      return;
    }
    const sensor = find(energySensors, { id: +value.sensorId });
    if (!sensor || !sensor.granularity) {
      return;
    }
    const sensorZoomType = granularityToZoomTypeMap[sensor.granularity];
    if (sensorZoomType && maxZoomLevel > ZOOM_TYPE[sensorZoomType]) {
      maxZoomLevel = ZOOM_TYPE[sensorZoomType];
    }
  });

  const energyValues = mapValues(
    {
      last12MonthsCO2Emissions: getLast12MonthsCO2Emissions(values, meta),
      last12MonthsRenewableEnergy: getLast12MonthsRenewableEnergy(values, meta, energySensors),
      last12MonthsConsumption: getLast12MonthsConsumption(values),
      last12MonthsConsumptionFrom1MonthAgo: getLast12MonthsConsumptionFrom1MonthAgo(values),
    },
    value => roundAndConvertEnergyValue(value, unit)
  );
  energyValues.dataHasConsumptionValuesFromOverYearAgo = values.some(isValueOverYearOld);

  if (monthlyData) {
    forEach(Object.keys(monthlyData), year => {
      // Map data to every month (0-11) of the year
      const currentYearData = times(12, month => {
        if (monthlyData[year][month]) {
          return monthlyData[year][month];
        }
        return 0;
      });
      // Sum months
      yearTotals[year] = roundAndConvertEnergyValue(sum(currentYearData), unit);
      // Create chart's series data
      series.push({
        name: year,
        data: currentYearData.map(data => {
          return unit === 'MWh'
            ? // Intentional change of kWh value to MWh
              // after rounding to show more precise values
              round(data) / 1000
            : round(data);
        }),
        color: colors[getYear(now) - year],
        _unit: unit,
        type: 'column',
      });
    });
  }

  return {
    series,
    years,
    yearTotals,
    categories,
    ...energyValues,
    unit,
    maxZoomLevel,
  };
};

export const getTotalRawValues = energyValues => {
  let totalRawValues = [];
  totalEnergyConsumptionTypes.forEach(type => {
    if (energyValues[type] && energyValues[type].length > 0) {
      totalRawValues = totalRawValues.concat(energyValues[type]);
    }
  });
  return totalRawValues;
};

/**
 * Calculate energy rating values by FL.
 * @param {Object[]} values - Values.
 * @returns {Object.<string, Object>} EnergyRatingValues - Description
 */
export const getEnergyRatingValues = values => {
  const result = values
    .map(value => {
      return {
        ...value,
        timestamp: parseISO(value.timestamp),
        unit: value.type ? getUnitBySensorType(value.type) : 'kWh/m2',
      };
    })
    .reduce((acc, value) => {
      acc[value.functionalLocation] = acc[value.functionalLocation] ?? [];
      acc[value.functionalLocation].push(value);
      return acc;
    }, {});
  Object.keys(result).map(
    fl =>
      (result[fl] = {
        values: result[fl],
        unit: result[fl][0]?.unit,
        ...getLatestAnd30DaysAgoEnergyRatingValues(result[fl]),
      })
  );
  return result;
};

export const getLatestAnd30DaysAgoEnergyRatingValues = values => {
  // timestamp is already parsed as a Date object in value object
  const latestValue = maxBy(values, value => value?.timestamp?.valueOf());
  const latestDate = latestValue.timestamp;

  // latest energy rating value is not relevant if older
  const latestValueMinDateLimit = endOfMonth(subMonths(new Date(), 3));
  if (latestDate < latestValueMinDateLimit) {
    return {
      latestValue: null,
      value30DaysBeforeLatestValue: null,
    };
  }

  const monthAgo = subDays(latestDate, 30);
  const monthAgoValue = find(values, value => isSameDay(monthAgo, value.timestamp));

  return {
    latestValue: latestValue?.value ?? null,
    value30DaysBeforeLatestValue: monthAgoValue?.value ?? null,
  };
};

/**
 * Calculate last 12 months consumption from raw data.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getLast12MonthsConsumptionFrom1MonthAgo = (values, options) => {
  const startDate = startOfMonth(subMonths(now, 13));
  const endDate = endOfMonth(subMonths(monthAgo, 1));
  return getConsumptionBetweenDates(values, startDate, endDate, options);
};

/**
 * Sum last 12 calendar months consumption from raw data.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getLast12MonthsConsumption = (values, options) => {
  const startDate = startOfMonth(subYears(now, 1));
  const endDate = endOfLastMonth;
  return getConsumptionBetweenDates(values, startDate, endDate, options);
};

export const getLast12MonthsCO2Emissions = (values, meta) => {
  const emissionsByFl = [];
  const groupedValues = groupBy(values, 'functionalLocation');
  each(groupedValues, (values, key) => {
    const startDate = startOfMonth(subYears(now, 1));
    const endDate = endOfLastMonth;
    // use building meta co2 multiplier if it exist else use constant
    const co2MultiplierMetaData = meta && find(meta[key], flMeta => flMeta.key === co2EmissionMultiplierMetaKey);
    const co2Multiplier = co2MultiplierMetaData ? parseInt(co2MultiplierMetaData.value, 10) : co2EmissionMultiplier;
    const emissionObject = {};
    emissionObject.totalEmissions = getConsumptionBetweenDates(values, startDate, endDate) * co2Multiplier;
    emissionsByFl.push(emissionObject);
  });
  return sumBy(emissionsByFl, 'totalEmissions');
};

export const getRenewableEnergy = ({ values, startDate, endDate, energySensors, buildingRenewabilityMultiplier }) => {
  const groupedValuesBySensorId = groupBy(values, 'sensorId');
  return Object.keys(groupedValuesBySensorId).map(sensorIdString => {
    const values = groupedValuesBySensorId[sensorIdString];
    const sensorId = parseInt(sensorIdString, 10);
    const sensor = energySensors?.find(sensor => sensor.id === sensorId);
    const sensorRenewabilityMultiplier = sensor?.sensorMeta?.find(
      ({ metaKey }) => metaKey === renewabilityFactorMetaKey
    )?.value;
    const renewabilityMultiplier = parseFloat(sensorRenewabilityMultiplier ?? buildingRenewabilityMultiplier, 10);
    if (!renewabilityMultiplier) {
      return 0;
    }
    return renewabilityMultiplier * getConsumptionBetweenDates(values, startDate, endDate);
  });
};

export const getLast12MonthsRenewableEnergy = (values, buildingMeta, energySensors) => {
  const startDate = startOfMonth(subYears(now, 1));
  const endDate = endOfLastMonth;
  const groupedValuesByFl = groupBy(values, 'functionalLocation');
  return sum(
    Object.keys(groupedValuesByFl).map(functionalLocation => {
      const buildingRenewabilityMultiplier = buildingMeta?.[renewabilityFactorMetaKey];
      return sum(
        getRenewableEnergy({
          values: groupedValuesByFl[functionalLocation],
          startDate,
          endDate,
          energySensors,
          buildingRenewabilityMultiplier,
        })
      );
    })
  );
};

/**
 * Get total consumption between given dates from values.
 * @param {Object[]} values - Values.
 * @param {{mean:boolean}} [options]- Options to return mean instead of sum
 */
export const getConsumptionBetweenDates = (values = [], startDate, endDate, options = {}) => {
  const filteredValues = values
    .filter(value => isWithinInterval(parseISO(value.timestamp), { start: startDate, end: endDate }))
    .map(value => value.value);
  if (options.mean) {
    return mean(filteredValues);
  }
  return sum(filteredValues);
};

// Get monthly consumption data by year and month from raw data
// Output format: { '2018': { '0': 123 }, { '1': 123 }, ... }
export const getMonthlyConsumption = values => {
  if (values && values.length > 0) {
    const monthlyData = {};
    values.forEach(value => {
      // Get year and month from timestamp
      const valueDate = parseISO(value.timestamp);
      const year = getYear(valueDate);
      const month = getMonth(valueDate);
      let newValue = value.value;
      // Add value to monthyData object
      if (monthlyData && monthlyData[year] && monthlyData[year][month]) {
        newValue += monthlyData[year][month];
      }
      if (newValue != null) {
        setWith(monthlyData, [year, month], newValue, Object);
      }
    });
    return monthlyData;
  }
  return {};
};

export const getConsumptionFromValues = memoizeOne(values => {
  return sum(values.map(value => value.value));
});

export const ZOOM_TYPE = {
  year: 0,
  month: 1,
  date: 2,
  hour: 3,
};

export const getReferenceSeries = (referenceValues, theme, seriesName) => {
  const series = [];
  let monthSeries = [];

  if (referenceValues && Array.isArray(referenceValues) && referenceValues.length > 0) {
    series.push({
      name: seriesName,
      data: referenceValues,
      color: theme ? theme.colors.orange : undefined,
      type: 'spline',
      lineWidth: 1,
      zIndex: 0,
      showInLegend: true,
      marker: { enabled: false },
      _unit: 'MWh',
    });

    monthSeries = map(referenceValues, (value, index) => {
      const days = getDaysInMonth(setMonth(now, index));
      const avg = round(value / days, 3);

      return {
        name: seriesName,
        data: times(days, () => avg), // same value for each day
        color: theme ? theme.colors.orange : undefined,
        type: 'spline',
        lineWidth: 1,
        zIndex: 0,
        showInLegend: true,
        marker: { enabled: false },
        _unit: 'MWh',
      };
    });
  }

  return {
    [ZOOM_TYPE.year]: series,
    [ZOOM_TYPE.month]: monthSeries,
  };
};

export const getEnergyRatingSensor = (energySensors = [], sensorTypes = []) => {
  const energyRatingType = sensorTypes.find(sensor => isEnergyRatingSensor(sensor.name));
  if (isEmpty(energyRatingType)) {
    return;
  }
  return energySensors.find(sensor => energyRatingType.id === sensor.sensorTypeId);
};
