import withLabelOptions from 'components/Charts/DefaultCustomChart/withLabelOptions';
import updateChartAnnotations from 'components/Sensor/SensorAnnotations/updateChartAnnotations';
import { useEffect } from 'react';
import customChartGranularity from './customChartGranularity';

const useUpdateAnnotations = (chart, getAnnotationConfigs) => {
  useEffect(() => {
    if (chart) {
      updateChartAnnotations(
        chart,
        withLabelOptions(getAnnotationConfigs(customChartGranularity(chart.xAxis?.[0].min, chart.xAxis?.[0].max)), true)
      );
    }
  }, [chart, getAnnotationConfigs]);
};

export default useUpdateAnnotations;
