import ApiRequest from 'utils/Fetch/ApiRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { SERVICE_API_PATH } from './index';

export const getDivisionConfig = ({ noCache, refreshCache, query } = {}) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/division-config`,
    query,
    noCache,
    refreshCache,
  });

export const upsertDivisionConfig = async data => {
  const result = await ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/division-config`,
    noCache: true,
    data,
  });
  await invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/division-config`);
  return result;
};
