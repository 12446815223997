import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH } from './index';

export const createContactMessageFn = (request, contactMessage) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/contactMessages`,
    data: contactMessage,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  createContactMessage: createContactMessageFn,
});
