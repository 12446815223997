import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataV2 from 'services/masterData';
import sortBy from 'lodash/sortBy';

const initialState = {
  byFunctionalLocation: {},
  loading: false,
  error: null,
};

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/AnalyticsFunctionLogs/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/AnalyticsFunctionLogs/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/AnalyticsFunctionLogs/LOAD_FOR_FL_FAIL';

export const loadAnalyticsFunctionLogs =
  ({ functionalLocation, analyticsFunctionIds, analyticsParameterIds, pageSize, page, timestampStart, timestampEnd }) =>
  async dispatch => {
    dispatch({ type: LOAD_FOR_FL });

    try {
      const logEntries = await dispatch(
        MasterDataV2.getAnalyticsFunctionLogs({
          functionalLocation,
          analyticsFunctionIds,
          analyticsParameterIds,
          pageSize,
          page,
          timestampStart,
          timestampEnd,
        })
      );
      return dispatch({
        type: LOAD_FOR_FL_SUCCESS,
        functionalLocation,
        logEntries,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FL_FAIL,
        error,
      });
    }
  };

export const loadLatestAnalyticsFunctionLogs = functionalLocation => async (dispatch, getState) => {
  const logEntries = getState().analyticsFunctionLogs.byFunctionalLocation[functionalLocation] || [];
  const latestTimestamp = logEntries[0]?.created;
  if (latestTimestamp) dispatch(loadAnalyticsFunctionLogs({ functionalLocation, timestampStart: latestTimestamp }));
};

export default createReducerFromMapping(
  {
    [LOAD_FOR_FL]: (state, action) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [LOAD_FOR_FL_SUCCESS]: (state, action) => {
      const existingLogEntries = state.byFunctionalLocation[action.functionalLocation] || [];
      const allLogEntries = [...existingLogEntries, ...action.logEntries];
      const sortedLogEntries = allLogEntries.sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
      const uniqueLogEntries = sortedLogEntries.filter(
        (logEntry, idx) => JSON.stringify(logEntry) !== JSON.stringify(sortedLogEntries[idx - 1])
      );

      return {
        ...state,
        byFunctionalLocation: {
          ...state.byFunctionalLocation,
          [action.functionalLocation]: sortBy(uniqueLogEntries, 'created').reverse(),
        },
        loading: false,
        error: null,
      };
    },
    [LOAD_FOR_FL_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
