import React from 'react';
import styled from 'styled-components';
import { CellContext } from '@tanstack/react-table';

import { TableLabelWrapper } from 'components/VirtualizedTable/GenericTable';

import { InsightTableMessage } from './InsightTable';

const StyledLocationCell = styled(TableLabelWrapper)`
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const InsightLocationCell = ({ getValue }: CellContext<InsightTableMessage, string>) => (
  <StyledLocationCell>{getValue()}</StyledLocationCell>
);
