import React from 'react';
import PropTypes from 'prop-types';

import NotificationPositioner from './NotificationPositioner';

const Notification = ({ visible, children }) => {
  if (!visible) {
    return null;
  }
  return <NotificationPositioner>{children}</NotificationPositioner>;
};

Notification.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.any,
};

export default Notification;
