import chunk from 'lodash/chunk';
import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import { SENSOR_DATA_CATEGORY } from 'utils/ValueImport/constants';

export const initialState = {
  importingSensorValues: false,
};

export const IMPORT_SENSOR_VALUES = 'CUSTOMER_PLATFORM/DataImport/IMPORT_SENSOR_VALUES';
export const IMPORT_SENSOR_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/DataImport/IMPORT_SENSOR_VALUES_SUCCESS';
export const IMPORT_SENSOR_VALUES_FAIL = 'CUSTOMER_PLATFORM/DataImport/IMPORT_SENSOR_VALUES_FAIL';

const BATCH_SIZE = 250;

export const importSensorValues =
  (type, partnerNumber, functionalLocation, values, aggregateInfoArg) => async dispatch => {
    dispatch({ type: IMPORT_SENSOR_VALUES });
    try {
      let result;
      let aggregateInfo = undefined;
      const chunks = chunk(values, BATCH_SIZE);
      const len = chunks.length;
      // move the possibly <250 batch to be first
      const [last] = chunks.slice(-1);
      const sortedChunks = [last, ...chunks.slice(0, len - 1)];
      for (const [i, part] of sortedChunks.entries()) {
        // last batch triggers aggregations
        if (i === len - 1) {
          aggregateInfo = aggregateInfoArg;
        }
        if (type === SENSOR_DATA_CATEGORY.GENERIC) {
          result = await IoTService.importGenericSensorValues({
            values: part,
            aggregateInfo,
          });
        } else {
          result = await IoTService.importWasteSensorValues({
            type,
            partnerNumber,
            functionalLocation,
            values: part,
            aggregateInfo,
          });
        }
      }
      dispatch({
        type: IMPORT_SENSOR_VALUES_SUCCESS,
        result,
      });
      return result;
    } catch (error) {
      dispatch({
        type: IMPORT_SENSOR_VALUES_FAIL,
        error,
      });
      throw error;
    }
  };

export default createReducerFromMapping(
  {
    [IMPORT_SENSOR_VALUES]: state => ({
      ...state,
      importingSensorValues: true,
    }),
    [IMPORT_SENSOR_VALUES_SUCCESS]: (state, action) => ({
      ...state,
      importingSensorValues: false,
    }),
    [IMPORT_SENSOR_VALUES_FAIL]: (state, action) => ({
      ...state,
      importingSensorValues: false,
    }),
  },
  initialState
);
