import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import { ChecklistReportStatus } from 'constants/maintenance';

export const ReportOptionColorsByType = {
  positive: 'var(--green-01-tinted)',
  alarming: 'var(--orange-01-tinted)',
  negative: 'var(--red-01)',
  neutral: 'var(--grey-70)',
};

export const getChecklistScore = (template, report, status) => {
  if (!template || !report || report.reportItems?.length === 0 || status !== ChecklistReportStatus.COMPLETED) {
    return { score: null, scoreDistribution: [] };
  }

  const optionsByIds = keyBy(template.options, option => option.id);
  const templateOptionsByType = keyBy(template.options, option => option.type);
  const reportItemsByType = groupBy(
    report.reportItems,
    reportItem => optionsByIds[reportItem.selectedChecklistTemplateOptionId]?.type
  );

  const significantItems =
    (reportItemsByType.positive?.length || 0) +
    (reportItemsByType.alarming?.length || 0) +
    (reportItemsByType.negative?.length || 0);

  const scoreValue = ((reportItemsByType.positive?.length || 0) / significantItems) * 100;

  return {
    score: scoreValue,
    scoreDistribution: Object.keys(ReportOptionColorsByType)
      .filter(type => templateOptionsByType[type]?.label)
      .map(type => ({
        type,
        count: reportItemsByType[type]?.length || 0,
        color: ReportOptionColorsByType[type],
        label: templateOptionsByType[type]?.label,
        visible: type !== 'neutral',
      })),
  };
};

export const getScoreForTableItem = ({ template, ...report }) => getChecklistScore(template, report, report.status);

export const convertChecklistReportsToTableData = reports => {
  if (!reports?.length) {
    return [];
  }
  return reports?.map(report => ({
    id: report.id,
    title: report.template.title,
    status: report.status,
    ...getScoreForTableItem(report),
    created: report.created,
    functionalLocation: report.serviceOrder?.functionalLocation,
    location: report.meta?.filterable?.location || report.serviceOrder?.functionalLocation,
    supplierName: report.serviceOrder?.supplierName,
    meta: report.meta,
  }));
};
