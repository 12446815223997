import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';

const Inputs = styled.div`
  display: flex;
  flex-flow: ${props => (props.row ? 'row' : 'column')} nowrap;

  > * {
    ${props => (props.row ? 'margin-right: var(--size-xs);' : 'margin-bottom: var(--size-xs);')}
  }
`;

const CheckboxGroup = ({ children, label, row }) => {
  return (
    <>
      {label && <InputLabel text={label} />}
      <Inputs row={row}>{children}</Inputs>
    </>
  );
};

CheckboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  row: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  label: '',
};

export default CheckboxGroup;
