import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';
import { statusToColor, statusToIcon, announcementShape, ANNOUNCEMENT_TYPE } from './utils';
import MarkdownContent from './MarkdownContent';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: var(--size-lg);
  line-height: ${props => props.theme.font.lineHeight.lg};
`;
Header.displayName = 'Header';

const Icon = styled(({ status, ...props }) => <Svg {...props} />)`
  font-size: ${props => props.theme.text.h1};
  fill: ${props => props.fill};
  margin-right: var(--size-sm);
`;
Icon.displayName = 'Icon';

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.fontWeight.heading};
`;
Title.displayName = 'Title';

const StyledDate = styled.p`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.sm};
  margin: var(--size-md) 0;
`;
StyledDate.displayName = 'StyledDate';

const AnnouncementModalContent = ({ announcement }) => {
  const startDate = parseISO(announcement.startDate);
  return (
    <section>
      <Header>
        <Icon name={statusToIcon[announcement.status]} fill={statusToColor[announcement.status]} />
        <Title>{announcement.title}</Title>
      </Header>
      <MarkdownContent content={announcement.description} />
      <StyledDate>{format(startDate, 'dd.MM.yyyy')}</StyledDate>
    </section>
  );
};

AnnouncementModalContent.propTypes = {
  announcement: PropTypes.oneOfType([
    announcementShape(ANNOUNCEMENT_TYPE.FL),
    announcementShape(ANNOUNCEMENT_TYPE.PARTNER),
    announcementShape(ANNOUNCEMENT_TYPE.SYSTEM),
  ]),
};

export default AnnouncementModalContent;
