import { useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';
import MasterDataService from 'services/masterData';

export const useAnonymousFunctionalLocations = publicView => {
  const [loading, setLoading] = useState(false);
  const [functionalLocations, setFunctionalLocations] = useState([]);

  useEffect(() => {
    if (publicView && !publicView.functionalLocation) {
      setLoading(true);
      MasterDataService.functionalLocationsOverviewAnonymous(publicView.id)
        .then(results => setFunctionalLocations(sortBy(results, 'name')))
        .finally(() => setLoading(false));
    }
  }, [setLoading, publicView]);

  return useMemo(
    () => ({
      functionalLocations,
      loading,
    }),
    [functionalLocations, loading]
  );
};
