export const toggleScroll = (toBe, isModal) => {
  const noScrollClass = 'noscroll';
  const classes = [noScrollClass];
  if (isModal) {
    classes.push('modal-open');
  }
  if (toBe) {
    window.document.body.classList.add(...classes);
    window.document.documentElement.classList.add(noScrollClass);
  } else {
    window.document.body.classList.remove(...classes);
    window.document.documentElement.classList.remove(noScrollClass);
  }
};
