import get from 'lodash/get';
import unset from 'lodash/unset';
import ApiRequest from 'utils/Fetch/ApiRequest';

const SERVICE_API_PATH = '/api';
const SRI_BASEPATH = `${SERVICE_API_PATH}/sri`;

export const getSriReportTemplate = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/report-template/${id}`,
    query: { functionalLocation },
    noCache: true,
  });

export const getSriReport = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/reports/${id}`,
    noCache: true,
    query: { functionalLocation, include: ['investmentProposals'] },
  });

export const getExcel = request =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/excel`,
  });

export const previewExcelData = excelFile =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/excel/preview`,
    noCache: true,
    data: excelFile,
  });

export const saveExcelData = (excelFile, functionalLocation, buildingType) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/excel`,
    noCache: true,
    data: {
      ...excelFile,
      functionalLocation,
      buildingType,
    },
  });

export const updateExcelFile = (functionalLocation, filename, file, reportId) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/excel/update`,
    noCache: true,
    data: {
      filename,
      file,
      functionalLocation,
      reportId,
    },
  });

export const getSriReports = (functionalLocation, filters = {}) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/reports`,
    noCache: true,
    query: { functionalLocation, ...filters },
  });

export const getSriReportAvailability = (functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/report-availability`,
    noCache: true,
    query: { functionalLocation },
  });

export const updateSriReport = report => {
  const conclusions = get(report, 'data.conclusions', null);

  // Remove empty recommendations.
  if (conclusions?.recommendationsForDigitalKpis) {
    conclusions.recommendationsForDigitalKpis = conclusions.recommendationsForDigitalKpis.filter(item => !!item);

    if (!conclusions.recommendationsForDigitalKpis.length) {
      unset(conclusions, 'recommendationsForDigitalKpis');
    }
  }

  const data = {
    id: report.id,
    functionalLocation: report.functionalLocation,
    status: report.status,
    companyInformation: get(report, 'data.companyInformation', null),
    reportComments: get(report, 'data.reportComments', null),
    findings: get(report, 'data.findings', null),
    serviceObjectiveAndReliability: get(report, 'data.serviceObjectiveAndReliability', null),
    investmentProposalIds: get(report, 'investmentProposalIds', []),
    frontPageImage: get(report, 'frontPageImage', null),
    consultancyWork: report.consultancyWork,
    feedbackSurvey: report.feedbackSurvey,
    divisionContactInformation: get(report, 'data.divisionContactInformation', null),
    caverionEnergyAdvisoryContactInformation: get(report, 'data.caverionEnergyAdvisoryContactInformation', null),
    conclusions,
  };

  return ApiRequest.patch({
    apiPath: `${SRI_BASEPATH}/reports`,
    noCache: true,
    data,
    query: { include: ['investmentProposals'] },
  });
};

export const deleteSriReport = (id, functionalLocation) =>
  ApiRequest.delete({
    apiPath: `${SRI_BASEPATH}/reports/${id}`,
    noCache: true,
    query: { functionalLocation },
  });

export const publishSriReport = (id, functionalLocation) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/reports/publish/${id}`,
    noCache: true,
    query: { functionalLocation },
  });

export const revertSriReportToDraft = (id, functionalLocation) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/reports/revert-to-draft/${id}`,
    noCache: true,
    query: { functionalLocation },
  });

export const getSriReportImage = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/${id}/image`,
    noCache: true,
    query: { functionalLocation },
  });

export const saveSriReportImage = (id, functionalLocation, file) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/${id}/image`,
    noCache: true,
    data: {
      ...file,
      functionalLocation,
    },
  });

export const createInvestmentProposal = (id, functionalLocation, investmentProposal) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/reports/investment-proposal`,
    noCache: true,
    data: {
      reportId: id,
      functionalLocation,
      investmentProposal,
    },
  });

export const updateInvestmentProposal = investmentProposal =>
  ApiRequest.put({
    apiPath: `${SRI_BASEPATH}/investment-proposal`,
    noCache: true,
    data: {
      ...investmentProposal,
    },
  });

export const updateSriReportInvestmentProposals = (id, functionalLocation, investmentProposals) =>
  ApiRequest.put({
    apiPath: `${SRI_BASEPATH}/reports/investment-proposals`,
    noCache: true,
    data: {
      id,
      functionalLocation,
      investmentProposals,
    },
  });

export const deleteInvestmentProposals = (reportId, functionalLocation, investmentProposalIds) =>
  ApiRequest.delete({
    apiPath: `${SRI_BASEPATH}/reports/${reportId}/investment-proposals`,
    noCache: true,
    data: {
      functionalLocation,
      investmentProposalIds,
    },
  });

export const getInvestmentProposal = id =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/investment-proposals/${id}`,
    noCache: true,
  });

export const getReportInvestmentProposals = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/${id}/investment-proposals`,
    noCache: true,
    query: { functionalLocation },
  });

export const getGlobalInvestmentProposals = () =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/global-investment-proposals`,
    useAuthorization: true,
  });

export const downloadSriReportPdf = (id, functionalLocation) =>
  ApiRequest.download({
    method: 'GET',
    apiPath: `${SRI_BASEPATH}/reports/${id}/pdf`,
    noCache: true,
    query: { functionalLocation },
  });

export const uploadSriReportPowerpoint = (id, functionalLocation, file) =>
  ApiRequest.post({
    apiPath: `${SRI_BASEPATH}/${id}/powerpoint`,
    noCache: true,
    data: { ...file, functionalLocation },
    useAuthorization: true,
  });

export const getSriReportPowerpoint = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/${id}/powerpoint`,
    noCache: true,
    query: { functionalLocation },
  });

export const deleteSriReportPowerpoint = (id, functionalLocation) =>
  ApiRequest.delete({
    apiPath: `${SRI_BASEPATH}/${id}/powerpoint`,
    noCache: true,
    query: { functionalLocation },
    useAuthorization: true,
  });

export const getSriTranslation = language =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/translations/sri/${language}`,
    useAuthorization: true,
  });

export const getExcelCoordinates = () =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/excel/coordinates`,
  });

export const getSriReportExcel = (id, functionalLocation) =>
  ApiRequest.get({
    apiPath: `${SRI_BASEPATH}/reports/${id}/excel`,
    noCache: true,
    query: { functionalLocation },
  });
