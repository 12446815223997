import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslations } from 'decorators/Translations/translations';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import { onClickPrint } from 'utils/Print/print';
import { downloadCsv } from 'components/Modules/ControlRoomModule/utils';
import { getTimes } from 'redux/modules/containers/remote-center';
import IoTService from 'services/iot';
import { mapAlarmsForSortedTable } from 'redux/modules/iot/alarm';
import useNotification from 'decorators/Notification/useNotification';
import { REMOTE_CENTER_TABS } from './utils';
import { getAuthorizationParams } from 'utils/profile';

const selectedTabHasContent = (selectedTab, { observations, alarmCount, inspections }) => {
  switch (selectedTab) {
    case REMOTE_CENTER_TABS.OBSERVATIONS:
      return observations?.length > 0;
    case REMOTE_CENTER_TABS.ALARMS:
      return alarmCount > 0;
    case REMOTE_CENTER_TABS.INSPECTIONS:
      return inspections?.length > 0;
    default:
      return false;
  }
};

export const LABELS = {
  [REMOTE_CENTER_TABS.ALARMS]: { PDF: 'Print view as PDF', CSV: 'Export all alarms in CSV' },
  [REMOTE_CENTER_TABS.INSPECTIONS]: { PDF: 'PDF', CSV: 'CSV' },
  [REMOTE_CENTER_TABS.OBSERVATIONS]: { PDF: 'PDF', CSV: 'CSV' },
};

const Export = ({ partnerNumber, selectedTab }) => {
  const [t] = useTranslations();
  const observations = useSelector(state => state.notice.observationsByPartner[partnerNumber]?.current);
  const inspections = useSelector(state => state.notice.inspectionsByPartner[partnerNumber]?.data);
  const alarmCount = useSelector(state => state.alarm.byPartner[partnerNumber]?.total);
  const functionalLocations = useSelector(state => state.functionalLocations.functionalLocations);
  const profile = useSelector(state => state.profile.profile);
  const [loadingAlarms, setLoadingAlarms] = React.useState(false);
  const { showNotification, renderedNotification } = useNotification();

  const handleCsvClick = async () => {
    let alarms = { alarms: [] };
    if (selectedTab === REMOTE_CENTER_TABS.ALARMS) {
      setLoadingAlarms(true);
      try {
        const authorizationParams = getAuthorizationParams({ profile, partnerNumber });
        const { yearAgo, now } = getTimes();
        const result = await IoTService.findAlarms({
          ...authorizationParams,
          start: yearAgo.toISOString(),
          end: now.toISOString(),
        });
        alarms = mapAlarmsForSortedTable(result);
      } catch (e) {
        showNotification({ type: 'error', message: t('EXPORT_FAILED'), autoHide: true });
      } finally {
        setLoadingAlarms(false);
      }
    }
    downloadCsv(selectedTab, { observations, alarms, inspections, functionalLocations });
  };

  const exportOptions = [
    {
      label: t(LABELS[selectedTab].PDF),
      onClick: onClickPrint,
    },
  ];
  if (selectedTabHasContent(selectedTab, { observations, alarmCount, inspections })) {
    exportOptions.push({
      label: t(LABELS[selectedTab].CSV),
      onClick: handleCsvClick,
    });
  }

  return (
    <>
      <ButtonDropdown
        secondary
        buttonLabel={t('Export')}
        buttonProps={{ iconName: 'export', loading: loadingAlarms }}
        items={exportOptions}
      />
      {renderedNotification}
    </>
  );
};

Export.propTypes = {
  partnerNumber: PropTypes.string.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default Export;
