import { SERVICE_API_PATH } from './index';
import ApiRequest from 'utils/Fetch/ApiRequest';

export const createAreaCoords = ({ functionalLocation, area, sensorHierarchyId }) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/coords/area`,
    data: { functionalLocation, area, sensorHierarchyId },
    noCache: true,
  });

// we don't pass sensorHierarchyId here.
// if we would like to update the floor information for an area (the sensorHierarchyId refers to floor),
// then we would have to update the corresponding sensor hierarchy (floor) as well.
// yes we could do that, but it would be a separate task.
// bottom line: right now it is not possible to update area's floor information
export const updateAreaCoords = ({ id, area }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/coords/area`,
    data: { id, area },
    noCache: true,
  });

export const createSensorCoords = ({ functionalLocation, point, sensorId, subSensors }) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/coords/sensor`,
    data: { functionalLocation, point, sensorId, subSensors: subSensors ? subSensors : [] },
    noCache: true,
  });
export const updateSensorCoords = ({ id, point, subSensors }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/coords/sensor`,
    data: { id, point, subSensors: subSensors ? subSensors : [] },
    noCache: true,
  });

export const deleteSensorCoords = ({ id }) => {
  return ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/coords/sensor/${id}`,
    noCache: true,
  });
};
