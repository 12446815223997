import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ItemView from './ItemView';
import DialogModal from 'components/Dialog/DialogModal';
import ChatBubble from 'components/ChatBubble/ChatBubble';
import { Ruler } from 'components/Ruler';
import ServiceOrderScore from 'containers/Application/ServiceOrder/ServiceOrderReply/ServiceOrderScore';
import { useTranslations } from 'decorators/Translations/translations';
import { sortTemplateItems, sortTemplateOptions } from './utils';
import ChecklistPrintHeader from './ChecklistPrintHeader';
import ButtonDropdown from 'components/Button/ButtonDropdown/ButtonDropdown';
import { onClickPrint } from 'utils/Print/print';

const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--size-xs);
`;

const TitleAndScore = styled.div`
  margin-bottom: var(--size-xs);
`;

const Title = styled.h4`
  padding-right: var(--size-md);
`;

const StyledRuler = styled(Ruler)`
  margin-bottom: var(--size-xs);
`;

export const ChecklistReportView = ({
  checklistTemplate,
  checklistReport,
  checklistReportFiles,
  onClose,
  onFileDownload,
  onAllFilesDownload,
  serviceOrder,
}) => {
  const [t] = useTranslations();

  const sortedTemplateItems = React.useMemo(() => sortTemplateItems(checklistTemplate.items), [checklistTemplate]);
  const sortedTemplateOptions = React.useMemo(() => sortTemplateOptions(checklistTemplate.options), [
    checklistTemplate,
  ]);

  const fullItems = React.useMemo(
    () =>
      sortedTemplateItems.map(templateItem => {
        const reportItem =
          checklistReport.reportItems.find(reportItem => reportItem.checklistTemplateItemId === templateItem.id) || {};
        const itemFiles = checklistReportFiles[reportItem.id] || [];
        return {
          ...templateItem,
          ...reportItem,
          itemFiles,
        };
      }),
    [sortedTemplateItems, checklistReport, checklistReportFiles]
  );

  const optionMenuItems = [
    {
      label: t('Print'),
      onClick: onClickPrint,
    }
  ];
  if (typeof onAllFilesDownload === 'function') {
    optionMenuItems.push({
      label: t('Download all files'),
      onClick: onAllFilesDownload,
    });
  }

  return (
    <DialogModal small animate isActive onOverlayClick={onClose} t={t}>
      <div>
        {serviceOrder && <ChecklistPrintHeader serviceOrder={serviceOrder} />}
        <TitleRow>
          <TitleAndScore>
            <Title>{checklistTemplate.title}</Title>
            <ServiceOrderScore template={checklistTemplate} report={checklistReport} />
          </TitleAndScore>

          <ButtonDropdown
            secondary
            buttonProps={{ iconName: 'fa-ellipsis-h' }}
            alignment="right"
            textAlign="left"
            items={optionMenuItems}
          />
        </TitleRow>
        {checklistReport.freeText && (
          <>
            <ChatBubble>{checklistReport.freeText}</ChatBubble>
            <StyledRuler />
          </>
        )}
        {fullItems.map(item => (
          <ItemView key={item.id} item={item} templateOptions={sortedTemplateOptions} onFileDownload={onFileDownload} />
        ))}
      </div>
    </DialogModal>
  );
};

ChecklistReportView.propTypes = {
  checklistTemplate: PropTypes.object.isRequired,
  checklistReport: PropTypes.object.isRequired,
  checklistReportFiles: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onAllFilesDownload: PropTypes.func,
  serviceOrder: PropTypes.object,
};

export default ChecklistReportView;
