import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputTextArea from 'components/Form/InputTextArea';
import InputForm from 'components/Form/InputForm';
import InfoBox from 'components/InfoBox/InfoBox';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 600px;
`;

// id is needed for the required field to work inside InputForm
const MARK_AS_READ_FORM_ID = 'rescue-plan-sign-as-read-form';

export const SignAsReadForm = ({ t, inputOptions, onSubmit }) => {
  const [model, setModel] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const formRef = useRef(null);

  const validate = form => {
    if (!form.checkValidity()) {
      const invalidInput = form.querySelector(':invalid');
      invalidInput.reportValidity();
      invalidInput.scrollIntoView({ block: 'center' });
      return false;
    }
    return true;
  };

  const handleFormChange = (property, value) => setModel(oldModel => ({ ...oldModel, [property]: value }));

  const handleSubmit = async () => {
    const form = formRef.current;
    if (validate(form)) {
      setLoading(true);
      await onSubmit(model);
      setLoading(false);
    }
  };

  const emailRequired = inputOptions.emailIsRequired;
  const additionalInfoVisible = inputOptions.additionalInfoField;
  const helpText = inputOptions.helpText;

  return (
    <Container>
      <InfoBox>{helpText}</InfoBox>
      <InputForm
        onPropertyChange={handleFormChange}
        model={model}
        onSubmit={handleSubmit}
        ref={formRef}
        id={MARK_AS_READ_FORM_ID}
      >
        <InputRow fullRow required>
          <InputLabel text={t('Name')} />
          <InputText type="text" property="name" />
        </InputRow>
        <InputRow fullRow required>
          <InputLabel text={t('Location')} />
          <InputText
            type="text"
            property="apartment"
            placeholder={t('Suite, company, department or some other space')}
          />
        </InputRow>
        <InputRow fullRow required={emailRequired}>
          <InputLabel text={t('Email')} />
          <InputText type="text" property="email" />
        </InputRow>
        <InputRow fullRow visible={additionalInfoVisible}>
          <InputLabel text={t('Additional information')} />
          <InputTextArea type="text" property="description" />
        </InputRow>

        <PrimaryButton confirm large type="submit" loading={loading}>
          {t('Sign off')}
        </PrimaryButton>
      </InputForm>
    </Container>
  );
};

SignAsReadForm.propTypes = {
  t: PropTypes.func.isRequired,
  inputOptions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default translations(SignAsReadForm);
