import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker-v3';
import Svg from 'components/Svg/Svg';

export const Form = styled.form`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => (props.heading ? props.theme.font.weight.semibold : props.theme.font.weight.normal)};

  h2 {
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.xs};
  }

  && {
    line-height: ${props => (props.heading ? '2.5em' : '1.75em')};
  }
`;

export const DatePicker = styled(ReactDatePicker)`
  display: flex;
  align-items: center;
`;

export const Time = styled.span`
  font-weight: normal;
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.xxs};
  cursor: pointer;
`;

export const EditIcon = styled(Svg)`
  fill: ${props => props.theme.colors.black};
  cursor: pointer;
  margin-left: var(--size-xxs);
`;

export const StyledDatePickerPopper = styled.div`
  .react-datepicker-popper {
    z-index: 1000;
    .react-datepicker {
      border-radius: 0;
    }
    .react-datepicker__triangle {
      left: 50%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  button {
    width: 100%;
  }
`;
