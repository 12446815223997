import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import throttle from 'lodash/throttle';

const useResponsive = componentRef => {
  const [state, setState] = useState({ width: 0, height: 0 });

  const updateDimensions = useCallback(() => {
    const nodeOrFallback = componentRef.current?.clientHeight
      ? componentRef.current
      : ReactDOM.findDOMNode(componentRef?.current);
    setState({ width: nodeOrFallback?.clientWidth || 0, height: nodeOrFallback?.clientHeight || 0 });
  }, [componentRef]);

  useEffect(() => {
    const throttledUpdate = throttle(updateDimensions, 600);
    window.addEventListener('resize', throttledUpdate);
    return () => window.removeEventListener('resize', throttledUpdate);
  }, [updateDimensions]);

  useLayoutEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  return state;
};

export default useResponsive;
