import pull from 'lodash/pull';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';

import { FeatureDefaults, FeatureKeys } from './profileData';
import { isCustomChartDeleted } from './customViews';

export const getFeaturesWithDefaults = features => {
  // This list should contain all possible non-synthetic feature flags and their default values.
  if (!features) {
    return {};
  }
  return { ...FeatureDefaults, ...features };
};

export const FEATURE_TO_TAB = {
  [FeatureKeys.BUILDING_AUTOMATION_TAB]: 'Building',
  [FeatureKeys.FILES]: 'Documents',
  [FeatureKeys.DOCUMENTS]: 'ExternalDocuments',
  [FeatureKeys.RESCUE_PLAN]: 'RescuePlans',
  [FeatureKeys.FLOORS_TAB]: 'Floors',
  [FeatureKeys.CONTROL_ROOM_ALARMS]: 'RemoteCenter',
  [FeatureKeys.CONTROL_ROOM_INSPECTIONS]: 'RemoteCenter',
  [FeatureKeys.CONTROL_ROOM_OBSERVATIONS]: 'RemoteCenter',
  [FeatureKeys.ENERGY_TAB]: 'Energy',
  [FeatureKeys.ANNOUNCEMENTS_TAB]: 'Building News',
  [FeatureKeys.CONDITIONS]: 'Conditions',
  [FeatureKeys.EQUIPMENT]: 'Equipment',
  [FeatureKeys.TECHNICAL_TAB]: 'Technical', // ['Technical', 'Systems'],
  [FeatureKeys.SERVICE_ORDERS]: 'Maintenance',
  [FeatureKeys.PLANNED_MAINTENANCE]: 'Maintenance',
  [FeatureKeys.LONG_TERM_PLANNING]: 'Maintenance',
  [FeatureKeys.CHECKLISTS]: 'Maintenance',
  [FeatureKeys.DEVIATIONS]: 'Maintenance',
  [FeatureKeys.CLEANING_TAB]: 'Cleaning',
  [FeatureKeys.RECYCLING]: 'Recycling',
  [FeatureKeys.CUSTOM_CHARTS]: 'CustomCharts',
  [FeatureKeys.BMS_ANALYTICS_TAB]: 'BMSAnalytics',
  [FeatureKeys.SRI]: 'SRI',
  [FeatureKeys.SUSTAINABILITY]: 'Sustainability',
};

export const getAllTabs = () => uniq(Object.values(FEATURE_TO_TAB));

export const getEnabledTabsForFLType = flType => {
  const tabs = getAllTabs();

  if (!flType) {
    return tabs;
  }

  if (flType !== 'BU' && flType !== 'UN' && flType !== 'IN') {
    pull(tabs, 'Building');
    pull(tabs, 'Floors');
    pull(tabs, 'Energy');
    pull(tabs, 'RemoteCenter');
    pull(tabs, 'Cleaning');
    pull(tabs, 'Building News');
    pull(tabs, 'Documents');
  }

  if (flType === 'TO') {
    pull(tabs, 'Technical');
    tabs.push('Systems');
    pull(tabs, 'Conditions');
  }

  return tabs;
};

/**
 * enabled tabs for a user:
 * 1) tab is enabled for functional location type AND in users features
 * 2) custom view tab
 */
export const getEnabledTabs = (features, flType, customViews) => {
  if (!features) {
    return getAllTabs();
  }

  const flTabs = getEnabledTabsForFLType(flType);

  const featureTabs = flatten(
    reduce(
      features,
      (accu, value, key) => {
        // if feature is enabled, push tab to accu
        if (value) {
          accu.push(FEATURE_TO_TAB[key]);
        }
        return accu;
      },
      []
    )
  );

  const customViewTabs = map(customViews, 'id');

  const enabledTabs = intersection(flTabs, featureTabs).concat(customViewTabs);

  return enabledTabs;
};

export const getEnabledCustomViews = (customViews, functionalLocation) => {
  if (!functionalLocation) {
    return [];
  }

  const profileCustomViews = filter(
    customViews,
    customView =>
      includes(customView.functionalLocations, functionalLocation.functionalLocation) &&
      customView.enabled === true &&
      customView.portfolio === false &&
      isCustomChartDeleted(customView)
  );

  return profileCustomViews;
};

export const cleanLegacyFeatures = features => {
  const mappedFeatures = { ...features };
  if (!isNil(features.buildingTab)) {
    mappedFeatures.buildingAutomationTab = features.buildingTab;
    mappedFeatures.announcementsTab = features.buildingTab;
    delete mappedFeatures.buildingTab;
  }
  if (!isNil(features.controlRoomTab) || !isNil(features.buildingTab)) {
    mappedFeatures.controlRoomAlarms = features.buildingTab || features.controlRoomTab;
    mappedFeatures.controlRoomObservations = features.buildingTab || features.controlRoomTab;
    mappedFeatures.controlRoomInspections = features.buildingTab || features.controlRoomTab;
  }
  delete mappedFeatures.controlRoomTab;

  return mappedFeatures;
};
