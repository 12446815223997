import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const SectionTabSecondary = styled.li.attrs({
  'data-test-id': 'SectionTabSecondary',
})`
  display: block;
  padding: 15px 30px;
  margin: 0;
  cursor: pointer;
  background: ${props => props.theme.colors.white};
  color: var(--black);
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.semibold};
  line-height: 0.86;
  box-shadow: ${props => !props.transparent && `0 2px 5px ${rgba(props.theme.colors.black, 0.1)}`};

  &:first-child {
    margin-left: 0;
  }

  ${props =>
    props.selected &&
    css`
      color: ${props => props.theme.colors.white};
      font-weight: ${props => props.theme.fontWeight.heading};
      background: ${props => props.theme.brandColors.caverionBlue};
    `}
`;
SectionTabSecondary.displayName = 'SectionTabSecondary';

export default SectionTabSecondary;
