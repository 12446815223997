import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';
import isValid from 'date-fns/isValid';
import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';
import translations from 'decorators/Translations/translations';
import prettyBytes from 'pretty-bytes';
import Loader from '../Loader/Loader';

const Row = styled.div`
  display: flex;
  color: var(--body-text-fg);
  font-size: ${props => props.theme.font.size.xs};
  padding-bottom: var(--size-md);
  position: relative;

  &::after {
    position: absolute;
    left: calc(1.5em - 1px);
    top: 0;
    content: ' ';
    display: block;
    width: 2px;
    height: 100%;
    background-color: var(--timeline-connector-color);
  }

  &:last-child::after {
    background-color: transparent;
  }
`;
Row.displayName = 'Row';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--size-xxl);
  min-width: var(--size-xxl);
  height: var(--size-xxl);

  border-radius: 50%;
  margin-right: var(--size-md);
  z-index: 1;

  ${props =>
    props.completed
      ? css`
          background-color: var(--success-color);
          color: var(--white);
        `
      : props.created
      ? css`
          background-color: var(--info-color);
          color: var(--white);
        `
      : css`
          border: var(--border-thin) solid var(--grey-50);
          background-color: var(--white);
          color: var(--blue-01);
        `}
`;

const Icon = styled(Svg)`
  font-size: ${props => props.theme.font.size.md};
`;
Icon.displayName = 'Icon';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
  line-height: ${props => props.theme.font.lineHeight.md};
`;
TextContainer.displayName = 'TextContainer';

const AttachmentContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  white-space: pre-line;
  align-self: flex-start;
  border: var(--box-border);
  background-color: var(--grey-box-bg);
  padding: var(--size-sm);
  line-height: ${props => props.theme.font.lineHeight.md};
`;
AttachmentContainer.displayName = 'AttachmentContainer';

const Attachment = styled.div`
  padding-left: var(--size-xs);
`;

const AttachmentName = styled.div`
  line-height: ${props => props.theme.font.lineHeight.md};
  color: var(--link-fg);
  &:hover {
    color: var(--link-fg-hover);
  }
`;
const AttachmentSize = styled.div`
  color: var(--supplemental-text-fg);
  line-height: ${props => props.theme.font.lineHeight.md};
`;

const AttachmentIcon = styled.div`
  padding: var(--size-xs);
  display: flex;
  color: var(--white);
  background-color: var(--link-fg);
  &:hover {
    background-color: var(--link-fg-hover);
  }
`;

const NoteText = styled.div`
  margin: var(--size-sm) 0;
  font-size: ${props => props.theme.fontSize.sm};
  white-space: pre-line;
`;

const Date = styled.div`
  color: var(--supplemental-text-fg);
`;

const Subject = styled.div`
  font-weight: ${props => props.theme.font.weight.bold};
`;
Subject.displayName = 'Subject';

const iconNames = {
  caverion: icons.CUSTOMER_SERVICE,
  customer: icons.CUSTOMER,
  completed: icons.OK,
  resolution: icons.OK,
  created: icons.TASK,
};

const getTitle = (isCustomer, isCreated, isCompleted, subject, t) => {
  if (isCustomer) {
    return t('Customer commented');
  }
  if (isCreated || isCompleted) {
    return subject;
  }
  return `${t('Caverion commented')}: ${subject ?? ''}`;
};

const AnnotationRow = ({ annotation, index, download, downloading, t }) => {
  const { timestamp, subject, notetext, type, iconName, customer } = annotation;
  const formattedDate =
    isValid(timestamp) &&
    `${format(timestamp, DATE_FORMATS.dateShortLocal)} - ${format(timestamp, DATE_FORMATS.timeShortLocal)}`;
  const isCompleted = type === 'completed' || type === 'resolution';
  const isCreated = type === 'created';
  const isFile = !!annotation.filename;
  const createdBy = !!customer ? 'customer' : 'caverion';
  const icon = iconName || iconNames[type] || iconNames[createdBy];

  return (
    <Row key={index}>
      <IconContainer completed={isCompleted} created={isCreated}>
        <Icon name={icon} fill="currentColor" />
      </IconContainer>
      <TextContainer>
        {formattedDate && <Date>{formattedDate}</Date>}
        <Subject>{getTitle(!!customer, isCreated, isCompleted, subject, t)}</Subject>
        <NoteText>{notetext}</NoteText>
        {isFile && (
          <AttachmentContainer onClick={downloading ? n => n : download}>
            <AttachmentIcon>
              <Icon name={icons.ATTACHMENT} fill="#ffffff" />
            </AttachmentIcon>
            <Attachment>
              <AttachmentName>
                {downloading && <Loader pixelSize={16}></Loader>}
                {annotation.filename}
              </AttachmentName>
              <AttachmentSize>
                {formattedDate}
                {annotation.filesize && ` (${prettyBytes(annotation.filesize)})`}
              </AttachmentSize>
            </Attachment>
          </AttachmentContainer>
        )}
      </TextContainer>
    </Row>
  );
};

AnnotationRow.propTypes = {
  t: PropTypes.func.isRequired,
  download: PropTypes.func,
  downloading: PropTypes.bool,
  annotation: PropTypes.shape({
    timestamp: PropTypes.object,
    subject: PropTypes.string,
    notetext: PropTypes.string,
    type: PropTypes.string,
    iconName: PropTypes.string,
    customer: PropTypes.bool,
    filename: PropTypes.string,
    filesize: PropTypes.number,
    mimetype: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default translations(AnnotationRow);
