import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

const Container = styled.div`
  height: ${props => (props.mountExpanded ? 'auto' : '0')};
  transition: height ${props => props.duration}ms ${props => props.theme.motion.easing};
  overflow: ${props => (props.state === 'entered' ? 'visible' : 'hidden')};
`;

const Collapse = props => {
  const { children, isCollapsed, duration, onCollapse, onExpand, mountExpanded } = props;
  const containerRef = useRef(null);

  useEffect(() => {
    if (isCollapsed === true) {
      onCollapse && onCollapse(props);
    } else if (isCollapsed === false) {
      onExpand && onExpand(props);
    }
  }, [isCollapsed]);

  const handleEnter = node => {
    node.style.height = 0;
  };

  const handleEntering = node => {
    const wrapperHeight = containerRef.current?.scrollHeight ?? 0;
    node.style.height = `${wrapperHeight}px`;
  };

  const handleEntered = node => {
    node.style.height = 'auto';
  };

  const handleExit = node => {
    const wrapperHeight = containerRef.current?.scrollHeight ?? 0;
    node.style.height = `${wrapperHeight}px`;

    // Force browser to recalculate
    node.offsetHeight; // eslint-disable-line no-unused-expressions
  };

  const handleExiting = node => {
    node.style.height = 0;
  };

  return (
    <Transition
      in={!isCollapsed}
      timeout={duration}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExiting={handleExiting}
    >
      {state => (
        <Container mountExpanded={mountExpanded} state={state} duration={duration} ref={containerRef}>
          {children}
        </Container>
      )}
    </Transition>
  );
};

Collapse.propTypes = {
  // Collapsable content
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  // Open/closed state of collapse element
  isCollapsed: PropTypes.bool,
  // Callbacks
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  // Transition duration
  duration: PropTypes.number,
  mountExpanded: PropTypes.bool,
};

Collapse.defaultProps = {
  duration: 350,
};

export default Collapse;
