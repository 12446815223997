import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import minBy from 'lodash/minBy';
import endOfToday from 'date-fns/endOfToday';
import parseISO from 'date-fns/parseISO';
import subYears from 'date-fns/subYears';
import { startOfUTCYear } from 'utils/Date/date';
import { HIGH_GRANULARITY_MAX_RANGE } from './constants';

import {
  getAggregations,
  getChartSensors,
  getSuitableAggregations,
  getDefaultRange,
  getOldestSensorCreatedDate,
  chartHasSort,
  CHART_TYPES,
  getConfiguredTimeframe,
} from '../utils';
import { getFilterForSensorsValues } from 'redux/modules/iot/values/sensor_values';
import IoTService from 'services/iot';
import useReferenceMeta from '../useReferenceMeta';
import useBuildingSensors from '../useBuildingSensors';
import { mapData } from 'components/Charts/DefaultCustomChart/utils';
import { useTranslations } from 'decorators/Translations/translations';
import { buildZonesForNulls } from 'components/Charts/utils';

const AUTOMATIC_FREQUENCY = 'automatic';

const initialValues = {
  startDatetime: null,
  endDatetime: null,
  isZoomed: false,
  isDailyDataAvailable: true,
  isHighGranularity: false,
  initialLoadDone: false,
};

// If there are no aggregations available for some sensor, we only have raw data available for that
const getIsSensorDataGranularityHigh = (availableAggregationsPerSensor, sensorCount) =>
  availableAggregationsPerSensor.length !== sensorCount ||
  availableAggregationsPerSensor.some(
    availableAggregations =>
      !availableAggregations?.some(
        aggregation => aggregation.frequency === 'daily' || aggregation.frequency === 'monthly'
      )
  );

const startDatetimeForAllData = oldestSensorCreatedDate =>
  startOfUTCYear(subYears(new Date(), 2)) < oldestSensorCreatedDate
    ? startOfUTCYear(subYears(oldestSensorCreatedDate, 1))
    : oldestSensorCreatedDate;

const availableAggregationsPerSensor = aggregations =>
  Object.keys(aggregations).flatMap(key => {
    return aggregations[key].map(aggregation => aggregation.availableAggregations);
  });

const getIsChartFrequencyHigh = frequency => frequency === 'raw' || frequency === 'hourly';

const getAggregationsForSeries = (sensors, aggregationFreq, startDatetime, endDatetime, serie) => {
  if (aggregationFreq != null) {
    return getSuitableAggregations(sensors, aggregationFreq, serie);
  }
  return getAggregations(sensors, { startDatetime, endDatetime });
};

const oldestSensorValue = sensorValues =>
  minBy(
    sensorValues.filter(value => value.value != null),
    'timestamp'
  );
const useCustomChart = ({ chart, functionalLocationId }) => {
  const [state, setState] = useState({
    valuesByComparisonSeries: [],
    dynamicValuesBySensorId: [],
  });

  const setValuesByComparisonSeries = valuesByComparisonSeries => setState({ ...state, valuesByComparisonSeries });

  // Initial values by sensor id
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState(initialValues);
  const handleParameterChange = (property, value) =>
    setParameters(parameters => ({ ...parameters, [property]: value }));
  const buildingSensors = useBuildingSensors(functionalLocationId);
  const referenceMeta = useReferenceMeta(functionalLocationId);
  const [t] = useTranslations();
  const theme = useTheme();
  const isMounted = useRef(true);

  const useDateTools = chartHasSort(chart);
  const isComparisonChart = chart.chartType === CHART_TYPES.COMPARISON;

  const loadInitialDataForChartWithNavigator = async () => {
    const sensors = getChartSensors(chart.series, buildingSensors);
    setParameters(prevParams => ({
      ...prevParams,
      sensorObjects: sensors,
    }));
    if (sensors.length) {
      setLoading(true);
      const startDatetime = startDatetimeForAllData(parseISO(getOldestSensorCreatedDate(sensors)));
      const aggregations = getAggregationsForSeries(
        sensors,
        chart.aggregationFreq !== 'raw' ? chart.aggregationFreq : null,
        startDatetime,
        endOfToday()
      );
      const isSensorDataGranularityHigh =
        isEmpty(aggregations) ||
        getIsSensorDataGranularityHigh(availableAggregationsPerSensor(aggregations), sensors.length);
      const isChartFrequencyHigh = getIsChartFrequencyHigh(chart.aggregationFreq);
      // If granularity is too high, we load a smaller dataset
      const sensorValues = await loadData(
        isSensorDataGranularityHigh ? new Date(endOfToday().getTime() - HIGH_GRANULARITY_MAX_RANGE + 1) : startDatetime,
        endOfToday(),
        sensors,
        isChartFrequencyHigh &&
          !isEmpty(aggregations) &&
          availableAggregationsPerSensor(aggregations).length === sensors.length
          ? AUTOMATIC_FREQUENCY
          : chart.aggregationFreq
      );

      if (isMounted.current) {
        setLoading(false);

        setParameters(prevParams => ({
          ...prevParams,
          startDatetime:
            isSensorDataGranularityHigh || !oldestSensorValue(sensorValues)
              ? startDatetime
              : parseISO(oldestSensorValue(sensorValues).timestamp),
          endDatetime: endOfToday(),
          isDailyDataAvailable: !isSensorDataGranularityHigh,
          isHighGranularity: isSensorDataGranularityHigh || isChartFrequencyHigh,
          initialLoadDone: true,
          ...(sensorValues?.length && {
            initialSensorValues: sensorValues,
            valuesBySensorId: groupBy(sensorValues, 'sensorId'),
          }),
        }));
      }
    } else {
      setParameters(prevParams => ({
        ...prevParams,
        initialLoadDone: true,
      }));
    }
  };

  const loadDataForChartWithDateTools = async () => {
    if (parameters.startDatetime !== null && parameters.endDatetime !== null) {
      const sensors = getChartSensors(chart.series, buildingSensors);
      if (sensors.length) {
        setLoading(true);
        const sensorValues = await loadData(parameters.startDatetime, parameters.endDatetime, sensors);
        if (isMounted.current) {
          setLoading(false);
          setParameters(prevParams => ({
            ...prevParams,
            valuesBySensorId: groupBy(sensorValues, 'sensorId'),
          }));
        }
      }
      if (isMounted.current) {
        setParameters(prevParams => ({
          ...prevParams,
          initialLoadDone: true,
        }));
      }
    } else {
      const [startDatetime, endDatetime] = getConfiguredTimeframe(chart.series?.[0], chart);
      setParameters(prevParams => ({
        ...prevParams,
        startDatetime,
        endDatetime,
      }));
    }
  };

  const loadDataForScatterPlot = async () => {
    let result = [];
    setLoading(true);

    for (const series of chart.series) {
      const sensors = getChartSensors([series], buildingSensors);
      // every scatter plot series should have two sensors (x-axis and y-axis)
      if (sensors.length === 2) {
        const [start, end] = getConfiguredTimeframe(series, chart);
        result = result.concat(await loadData(start, end, sensors, series.aggregationFreq));
      }
    }

    if (isMounted.current) {
      setLoading(false);
      setParameters(prevParams => ({
        ...prevParams,
        valuesBySensorId: groupBy(result, 'sensorId'),
        initialLoadDone: true,
      }));
    }
  };

  const loadDataForComparisonChart = async () => {
    let result = [];
    const sensors = getChartSensors(chart.series, buildingSensors);
    setLoading(true);

    for (const series of chart.series) {
      if (sensors.length) {
        const [start, end] = getConfiguredTimeframe(series, chart);
        const category = start?.getUTCFullYear();

        const valuesBySensorId = groupBy(
          await loadData(
            start,
            end,
            sensors.filter(sensor => series.sensorIds.includes(sensor.id)),
            'monthly',
            series
          ),
          'sensorId'
        );
        for (const sensorId in valuesBySensorId) {
          result = result.concat({
            sensorId,
            category,
            data: valuesBySensorId[sensorId],
          });
        }
      }
    }

    if (isMounted.current) {
      setLoading(false);
      setValuesByComparisonSeries(result);
      setParameters(prevParams => ({
        ...prevParams,
        initialLoadDone: true,
      }));
    }
  };

  const loadData = useCallback(
    async (start, end, sensors, customFrequency, serie) => {
      const aggregations = getAggregationsForSeries(
        sensors,
        customFrequency !== AUTOMATIC_FREQUENCY ? customFrequency ?? chart.aggregationFreq : undefined,
        start,
        end,
        serie
      );
      if (!isEmpty(aggregations)) {
        const filters = [];
        for (const [aggregation, value] of Object.entries(aggregations)) {
          const sensorIds = value.map(obj => obj.sensorId);
          filters.push(getFilterForSensorsValues(sensorIds, start, end, aggregation));
        }
        const responses = await Promise.allSettled(filters.map(filter => IoTService.sensorValuesFind(filter)));
        const sensorValues = responses
          .filter(({ status }) => status === 'fulfilled')
          .flatMap(({ value }) => value.data);

        return sensorValues;
      }
      return [];
    },
    [chart.aggregationFreq]
  );

  // Set sorted sensor id array as dependency
  const sensorIdDependency = JSON.stringify((chart.series?.flatMap(seriesObj => seriesObj.sensorIds) ?? []).sort());

  // Reset timeframe when aggregation changes
  useEffect(() => {
    if (useDateTools) {
      const [start, end] = getConfiguredTimeframe(chart.series?.[0], chart);
      if (start && end) {
        setParameters(prevParams => ({
          ...prevParams,
          startDatetime: start,
          endDatetime: end,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.aggregationFreq]);

  // Load initial data when aggregation changes or sensors change
  useEffect(() => {
    if (chart.chartType === CHART_TYPES.SCATTER_PLOT) {
      loadDataForScatterPlot();
    } else if (isComparisonChart) {
      loadDataForComparisonChart();
    } else if (useDateTools) {
      loadDataForChartWithDateTools();
    } else {
      loadInitialDataForChartWithNavigator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.aggregationFreq, buildingSensors, sensorIdDependency, useDateTools]);

  // Load updated data when parameters change
  useEffect(() => {
    if (useDateTools) {
      loadDataForChartWithDateTools();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters.startDatetime, parameters.endDatetime]);

  // Keep track of component being unmounted to handle async operation cleanup
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDynamicDataLoading = useCallback(
    async (chartInstance, series, min, max, trigger, originalTrigger) => {
      chartInstance.showLoading(`${t('Loading data')}...`);

      const endDatetimeMinusMaxRange = parameters.endDatetime?.getTime() - HIGH_GRANULARITY_MAX_RANGE + 1;

      let newData;

      if (min === parameters.startDatetime?.getTime() && max === parameters.endDatetime?.getTime() + 1) {
        // Don't load data if we are zoomed out
        newData = parameters.initialSensorValues;
      } else if (
        parameters.isHighGranularity &&
        !parameters.isDailyDataAvailable &&
        min === endDatetimeMinusMaxRange &&
        max === parameters.endDatetime?.getTime() + 1
      ) {
        // Don't load data if there is max range set and we are on initial state and there are no daily values available
        newData = parameters.initialSensorValues;
      } else if (
        parameters.isHighGranularity &&
        trigger === originalTrigger &&
        HIGH_GRANULARITY_MAX_RANGE < max + 1 - min
      ) {
        // Prevent loading more data than max range if we reset zoom
        newData = await loadData(
          new Date(max - HIGH_GRANULARITY_MAX_RANGE),
          new Date(max),
          parameters.sensorObjects,
          chart.aggregationFreq
        );
      } else {
        newData = await loadData(new Date(min), new Date(max), parameters.sensorObjects, chart.aggregationFreq);
      }
      // Navigator series are included in the series, let's filter them out
      const mainChartSeries = series.filter(serie => serie?.options.className !== 'highcharts-navigator-series');
      // Filter data for each series
      mainChartSeries.forEach((seriesObj, index) => {
        if (!seriesObj) {
          return;
        }
        const { options } = seriesObj;
        const data = newData.filter(data => +data.sensorId === +options.sensorId);
        const seriesData = mapData(data, options.aggregationType, options.aggregationFreq, options.hasSort, t);
        const zones = buildZonesForNulls(seriesData);
        const isLast = index === mainChartSeries.length - 1;
        seriesObj.update({ zones, gapSize: data?.[0]?.aggregation !== 'raw' ? 1.5 : 0 });
        seriesObj.setData(seriesData, isLast, isLast);
      });
      if (isMounted.current) {
        setState({ ...state, dynamicValuesBySensorId: groupBy(newData, 'sensorId') });
      }
      chartInstance.hideLoading();
    },
    [chart.aggregationFreq, loadData, parameters, t]
  );

  const onSelect = () =>
    setParameters(prevParams => ({
      ...prevParams,
      isZoomed: true,
    }));

  const onResetZoom = useCallback(() => {
    if (parameters.isZoomed) {
      setParameters(prevParams => ({
        ...prevParams,
        isZoomed: false,
      }));
    }
  }, [parameters.isZoomed]);

  const defaultRange = useMemo(() => getDefaultRange(chart.aggregationFreq), [chart.aggregationFreq]);
  const reference = useMemo(() => {
    if (chart.referenceMetaKey && !isEmpty(referenceMeta[chart.referenceMetaKey])) {
      return {
        name: t('Reference'),
        color: theme.colors.orange,
        data: referenceMeta[chart.referenceMetaKey],
      };
    }
    if (isComparisonChart && !isEmpty(chart.setpoint?.coordinates)) {
      return {
        name: chart.setpoint.name,
        color: chart.setpoint.color,
        data: chart.setpoint.coordinates.map(({ y }) => y),
      };
    }
    return null;
  }, [t, referenceMeta, theme, chart.setpoint, chart.referenceMetaKey, isComparisonChart]);

  return {
    valuesBySensorId: parameters.valuesBySensorId,
    onSelect,
    onResetZoom,
    parameters,
    handleParameterChange,
    defaultRange,
    loadingSensorValues: loading || !parameters.initialLoadDone,
    reference,
    useDateTools,
    dynamicValuesBySensorId: state.dynamicValuesBySensorId,
    valuesByComparisonSeries: state.valuesByComparisonSeries,
    handleDynamicDataLoading,
  };
};

export default useCustomChart;
