import React from 'react';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SkeletonText from 'components/Skeletons/SkeletonText';
import RecyclingRatesBreakdownTabSkeleton from 'containers/Application/Modules/RecyclingModule/RecyclingRatesBreakdown/RecyclingRatesBreakdownTab/RecyclingRatesBreakdownTabSkeleton';

const RecyclingRatesBreakdownSkeleton = () => (
  <Section>
    <SectionHeader>
      <SkeletonText style={{ height: '2em' }} />
    </SectionHeader>
    <RecyclingRatesBreakdownTabSkeleton />
  </Section>
);

export default RecyclingRatesBreakdownSkeleton;
