import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { toggleScroll } from 'utils/Modal/toggleScroll';
import withQuery from 'decorators/Query/withQuery';
import FloorTab from 'containers/Application/Modules/FloorModule/FloorPlan/FloorTab';
import { VALUE_STATUS } from 'constants/common';
import FloorTabLabels from './FloorTabLabels';
import FixedFloorTabLabels from './FixedFloorTabLabels';
import useScrollPosition from 'decorators/Scroll/useScrollPosition';

export const FloorTabsContainer = styled.div`
  margin: -${props => props.theme.layoutSpacing.md} auto 0; /* negative margin */
`;

export const FloorTabItem = styled.div`
  display: ${props => (props.selected ? 'block' : 'none')};
  margin-top: ${props => props.theme.section.spacingBetween};
`;

const getExpandedState = query => query?.expanded === 'true';

const FloorTabs = props => {
  const {
    openOpiDialog,
    openInsightDialog,
    naviExtended,
    loading,
    content,
    query,
    setQuery,
    insights,
    opiSectionPosition,
  } = props;

  const [scrollPosition] = useScrollPosition();

  const toggleExpand = () => {
    const isExpanded = getExpandedState(query);
    setQuery({ expanded: !isExpanded });
    toggleScroll(!isExpanded);
  };

  const isExpanded = getExpandedState(query);
  const selectedTabTitle = query.layer || content?.[0]?.title?.toLowerCase();
  const selectedTab = content.find(child => child.title?.toLowerCase() === selectedTabTitle);
  const noNavi = query.noNavi === 'true';

  if (!content) {
    return null;
  }

  const onSelectTab = item => {
    const value = item.title?.toLowerCase();
    setQuery({ ...query, layer: value || '', expanded: isExpanded });
  };

  const renderFloorSelector = (props = {}) => (
    <FloorTabLabels
      loading={loading}
      content={content}
      selectedTab={selectedTab}
      scrollTop={scrollPosition}
      naviExtended={naviExtended}
      isExpanded={isExpanded}
      onSelectTab={onSelectTab}
      {...props}
    />
  );

  const selectedTabContents = content.filter(child => selectedTab === child);
  return (
    <FloorTabsContainer>
      {!noNavi && (
        <FixedFloorTabLabels scrollTop={scrollPosition} naviExtended={naviExtended}>
          {renderFloorSelector()}
        </FixedFloorTabLabels>
      )}
      {selectedTabContents.map((child, index) => (
        <FloorTabItem selected={true} key={index}>
          <FloorTab
            floor={child.floorData}
            opiData={child.opiData}
            openOpiDialog={openOpiDialog}
            openInsightDialog={openInsightDialog}
            loading={child.loading}
            isSelectedStickyTab={true}
            selectedTab={selectedTab}
            renderFloorSelector={renderFloorSelector}
            toggleExpand={toggleExpand}
            isExpanded={isExpanded}
            content={content}
            onSelectTab={onSelectTab}
            insights={insights}
            opiSectionPosition={opiSectionPosition}
          />
        </FloorTabItem>
      ))}
    </FloorTabsContainer>
  );
};

FloorTabs.propTypes = {
  loading: PropTypes.bool,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      performance: PropTypes.oneOf([VALUE_STATUS.OK, VALUE_STATUS.WARNING, VALUE_STATUS.NEUTRAL]),
      content: PropTypes.element,
      tooltipContent: PropTypes.element,
    })
  ),
  insights: PropTypes.object,
  naviExtended: PropTypes.bool.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  openOpiDialog: PropTypes.func,
  openInsightDialog: PropTypes.func,
  opiSectionPosition: PropTypes.string,
};

const mapStateToProps = state => ({
  naviExtended: state.navigation.sideNaviExtended,
});

export default withQuery(withRouter(connect(mapStateToProps)(FloorTabs)));
