import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import round from 'lodash/round';
import uniqBy from 'lodash/uniqBy';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { parseISO } from 'date-fns';
import {
  getProfile,
  getPartnerNumber,
  wasteSensorsSelector,
  syntheticSensorsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { sensorTypeNameToWasteType } from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import { loadSummaryData } from 'redux/modules/iot/values/recycling';
import {
  TONNES_PRECISION,
  TONNES_UNIT,
  CO2E_TONNES_UNIT,
  PERCENTAGE_PRECISION,
  PERCENTAGE_UNIT,
  CO2E_TONNES_PRECISION,
} from 'constants/recycling';

const getError = state => state.values.recycling.summary.error;
const getIoTData = state => state.values.recycling.summary.data;
const getMeta = state => state.values.recycling.summary.meta;
const getYears = () => {
  let year = new Date().getFullYear();
  return [year, --year, --year, --year, --year];
};

const columnsSelector = createSelector(getYears, years => {
  const columns = [
    {
      title: 'Type',
      field: 'type',
      sortable: false,
      align: 'left',
    },
  ];
  years.forEach((year, index) =>
    columns.push({
      title: String(year),
      field: String(year),
      sortable: false,
      align: 'right',
      hideOnMobile: index > 1,
    })
  );
  return columns;
});

const needsReloadSelector = createSelector(
  getPartnerNumber,
  syntheticSensorsSelector,
  getMeta,
  (partnerNumber, syntheticSensors, meta) => !isEmpty(syntheticSensors) && meta.partnerNumber !== partnerNumber
);

const sensorIdsSelector = createSelector(
  wasteSensorsSelector,
  syntheticSensorsSelector,
  (wasteSensors, syntheticSensors) =>
    isEmpty(wasteSensors) && isEmpty(syntheticSensors)
      ? []
      : [...wasteSensors.map(sensor => sensor.id), ...syntheticSensors.map(sensor => sensor.id)]
);

const tableDataSelector = createSelector(
  getYears,
  wasteSensorsSelector,
  syntheticSensorsSelector,
  columnsSelector,
  getIoTData,
  (years, wasteSensors, syntheticSensors, columns, ioTData) => {
    if (isEmpty(wasteSensors) || isEmpty(syntheticSensors) || isEmpty(columns) || isEmpty(ioTData)) {
      return {};
    }
    const wasteSensorIds = wasteSensors.map(sensor => sensor.id);
    const wasteSensorTypes = wasteSensors.map(sensor => sensor.sensorType);
    const distinctWasteSensorTypes = uniqBy(wasteSensorTypes, 'id');
    const syntheticSensorIds = syntheticSensors.map(sensor => sensor.id);

    const findValuesForAYear = (sensorIds, aggregation, year) =>
      ioTData.filter(
        entry =>
          entry.aggregation === aggregation &&
          includes(sensorIds, entry.sensorId) &&
          parseISO(entry.timestamp).getUTCFullYear() === year
      );

    const findValueForAYear = (sensorIds, aggregation, year) =>
      findValuesForAYear(sensorIds, aggregation, year).reduce((prev, curr) => prev + curr.value, 0);

    const getYearlyValues = (sensorIds, aggregation, precision) =>
      years.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: round(findValueForAYear(sensorIds, aggregation, curr), precision),
        }),
        {}
      );

    const generateChildrenForAggregation = (aggregation, precision) =>
      distinctWasteSensorTypes.map(sensorType => {
        const sensorTypeSensorIds = wasteSensors
          .filter(sensor => sensor.sensorType.id === sensorType.id)
          .map(sensor => sensor.id);
        return {
          type: sensorTypeNameToWasteType(sensorType.name),
          ...getYearlyValues(sensorTypeSensorIds, aggregation, precision),
        };
      });

    const getAverageForAYear = (sensorIds, aggregation, year) => {
      const values = findValuesForAYear(sensorIds, aggregation, year);
      const sum = values.reduce((prev, curr) => prev + curr.value, 0);
      return sum / values.length || null;
    };

    const getYearlyAverages = (sensorIds, aggregation, precision) =>
      years.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: round(getAverageForAYear(sensorIds, aggregation, curr), precision),
        }),
        {}
      );

    return {
      columns,
      data: [
        {
          type: 'Waste amount',
          unit: TONNES_UNIT,
          children: generateChildrenForAggregation('yearlySum', TONNES_PRECISION),
          columns,
          ...getYearlyValues(wasteSensorIds, 'yearlySum', TONNES_PRECISION),
        },
        {
          type: 'Recycling rate',
          unit: PERCENTAGE_UNIT,
          columns,
          ...getYearlyAverages(syntheticSensorIds, 'recyclingYearlyRecyclingRate', PERCENTAGE_PRECISION),
        },
        {
          type: 'Recovery rate',
          unit: PERCENTAGE_UNIT,
          columns,
          ...getYearlyAverages(syntheticSensorIds, 'recyclingYearlyRecoveryRate', PERCENTAGE_PRECISION),
        },
        {
          type: 'Carbon footprint',
          unit: CO2E_TONNES_UNIT,
          children: generateChildrenForAggregation('recyclingYearlyCO2e', CO2E_TONNES_PRECISION),
          columns,
          ...getYearlyValues(wasteSensorIds, 'recyclingYearlyCO2e', CO2E_TONNES_PRECISION),
        },
      ],
    };
  }
);

const dataSelector = createSelector(
  getMeta,
  getError,
  needsReloadSelector,
  tableDataSelector,
  (meta, error, needsReload, tableData) => ({
    isLoading: meta.loading || isEmpty(tableData),
    error,
    needsReload,
    result: tableData || {},
  })
);

export const mapStateToProps = (state, props) => ({
  profile: getProfile(state),
  partnerNumber: getPartnerNumber(state, props),
  sensorIds: sensorIdsSelector(state, props),
  data: dataSelector(state, props),
});

export const mapDispatchToProps = {
  loadSummaryData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
