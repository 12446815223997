import { toDate, startOfMonth, endOfMonth, set } from 'date-fns';
import { defaultFilterValues, SUBSTATUS_FILTER_FIELD, DISCIPLINE_FILTER_FIELD } from 'components/Observations/utils';

export const baseDisciplines = ['cooling', 'heating', 'ventilation', 'electric', 'energyConsumption', 'alarms'];

export const INCIDENT_ORIGIN = {
  analytics: 'Analytics',
  manual: 'Manual',
};

const statusFilters = {
  open: 1,
  inProgress: 2,
  completed: 3,
};

const filterStatus = (filter, data) =>
  data.filter(observation => filter.some(filterValue => observation.status.value === statusFilters[filterValue]));

const filterDiscipline = (filter, data) =>
  data.filter(observation => {
    if (baseDisciplines.includes(observation.system.value)) {
      // filter by base disciplines
      return filter.includes(observation.system.value);
    } else if (filter.includes(DISCIPLINE_FILTER_FIELD.OTHER)) {
      // return everything else if "other" is selected
      return true;
    }
    // if "other" filter is not selected
    return false;
  });

const impactFilters = {
  energy: 'energyImpact',
  recycle: 'wasteObservation',
};

const filterImpact = (filter, data) =>
  data.filter(observation => filter.some(filterValue => observation[impactFilters[filterValue]] === true));

const filterChange = (filter, data) =>
  filter.includes('hideWithoutImprovement') ? data.filter(observation => observation.savingsPotentialSum > 0) : data;

const isDateBetween = (date, start, end) => {
  return date >= start && date <= end;
};

const filterDate = ({ year, month, dateFilterField }, data) => {
  const start = startOfMonth(set(new Date(), { year, month: Number.isInteger(month) ? month : 0 }));
  const end = endOfMonth(set(new Date(), { year, month: Number.isInteger(month) ? month : 11 }));

  if (Number.isInteger(year)) {
    return data.filter(item => item[dateFilterField] && isDateBetween(toDate(item[dateFilterField].value), start, end));
  }

  return data;
};

const filterCreatedBy = (filter, data) =>
  data.filter(observation => {
    if (observation.origin === INCIDENT_ORIGIN.analytics) {
      return filter.includes('analytics');
    }
    return filter.includes('caverionExpert');
  });

const filterFunctionalLocation = (filter, data) =>
  data.filter(observation => filter.some(filterValue => observation.functionalLocation === filterValue));

export const getUniqueSubStatuses = observations => {
  return [
    ...new Set(
      observations
        .map(observation => observation.subStatus)
        .filter(Boolean)
        .sort()
    ),
  ];
};

const filterSubStatus = (filter, data, observations) => {
  const uniqueSubStatuses = getUniqueSubStatuses(observations);
  return data.filter(observation => {
    if (uniqueSubStatuses.includes(observation.subStatus)) {
      // filter by listed substatuses
      return filter.includes(encodeURIComponent(observation.subStatus));
    } else if (filter.includes(SUBSTATUS_FILTER_FIELD.UNDEFINED)) {
      // return everything else if "undefined" is selected
      return true;
    }
    return false;
  });
};

export const filterObservationsWithoutDateFilter = (observations = [], activeFilters = {}) => {
  let filtered = [...observations];

  if (activeFilters.status?.length > 0) {
    filtered = filterStatus(activeFilters.status, filtered);
  }

  if (activeFilters.discipline?.length > 0) {
    filtered = filterDiscipline(activeFilters.discipline, filtered);
  }

  if (activeFilters.impact?.length > 0) {
    filtered = filterImpact(activeFilters.impact, filtered);
  }

  if (activeFilters.change?.length > 0) {
    filtered = filterChange(activeFilters.change, filtered);
  }

  if (activeFilters.createdBy?.length > 0) {
    filtered = filterCreatedBy(activeFilters.createdBy, filtered);
  }

  if (activeFilters.functionalLocation?.length > 0) {
    filtered = filterFunctionalLocation(activeFilters.functionalLocation, filtered);
  }

  if (activeFilters.subStatus?.length > 0) {
    filtered = filterSubStatus(activeFilters.subStatus, filtered, observations);
  }

  return filtered;
};

export const filterObservationsWithDateFilter = (observations = [], activeFilters = {}) => {
  const hasDateFilter = !!activeFilters.year;
  // return original array if no filtering needs to be done
  if (!hasDateFilter) {
    return observations;
  }

  let filtered = [...observations];

  if (hasDateFilter) {
    filtered = filterDate(
      {
        year: activeFilters.year ? Number(activeFilters.year) : defaultFilterValues.year,
        month: activeFilters.month !== undefined ? Number(activeFilters.month) : undefined,
        dateFilterField: activeFilters.dateFilterField || defaultFilterValues.dateFilterField,
      },
      filtered
    );
  }

  return filtered;
};

export const filterObservations = (observations = [], activeFilters = {}) => {
  return filterObservationsWithDateFilter(
    filterObservationsWithoutDateFilter(observations, activeFilters),
    activeFilters
  );
};

export const performanceColorNames = {
  observed: 'warningColor',
  warning: 'warningColor',
  offered: 'warningColor',
  processed: 'warningColor',
  started: 'warningColor',
  completed: 'okColor',
  ok: 'okColor',
  alert: 'alarmColor',
  open: 'openColor',
  'in progress': 'warningColor',
};

export const performanceColors = props => ({
  observed: props.theme.status[performanceColorNames.observed],
  warning: props.theme.status[performanceColorNames.warning],
  offered: props.theme.status[performanceColorNames.offered],
  processed: props.theme.status[performanceColorNames.processed],
  started: props.theme.status[performanceColorNames.started],
  completed: props.theme.status[performanceColorNames.completed],
  ok: props.theme.status[performanceColorNames.ok],
  alert: props.theme.status[performanceColorNames.alert],
  open: props.theme.status[performanceColorNames.open],
  'in progress': props.theme.status[performanceColorNames['in progress']],
});
