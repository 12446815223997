import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Svg from 'components/Svg/Svg';

const MessageContainer = styled.div`
  position: relative;
  background-color: var(--card-bg);
  box-shadow: 0 2px 20px var(--black-025);
  border-radius: 5px;
  padding: var(--size-sm);
  transition: all 0.15s ease-in;

  width: 400px;
  max-width: 90vw;
`;
MessageContainer.displayName = 'MessageContainer';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
Header.displayName = 'Header';

const Title = styled.h4`
  color: var(--contextual-help-header-fg);
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: ${props => props.theme.letterSpacing.default};
  line-height: ${props => props.theme.font.lineHeight.lg};
  padding-left: 1px;
`;
Title.displayName = 'Title';

const CloseIcon = styled(Svg)`
  fill: var(--popover-control-fg);
  font-size: ${props => props.theme.font.size.xxxs};
  cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const Text = styled.p`
  font-size: ${props => props.theme.font.size.xxs};
  line-height: ${props => props.theme.font.lineHeight.lg};
  font-weight: ${props => props.theme.font.weight.normal};
  white-space: pre-wrap;
  color: var(--body-text-fg);
`;
Text.displayName = 'Text';

const Link = styled.a`
  word-break: break-word;
`;
Link.displayName = 'Link';

const ContextualHelpMessage = ({ title, text, link, onClose, children }) => {
  const handleLinkClick = e => {
    e.stopPropagation();
  };
  return (
    <MessageContainer>
      <Header>
        <Title>{title}</Title>
        <CloseIcon name="remove" onClick={onClose} />
      </Header>
      <Text>
        {text}
        {link && (
          <Link href={link.url ? link.url : link} target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
            &nbsp;{link.link ? link.link : link}
          </Link>
        )}
      </Text>
      {children}
    </MessageContainer>
  );
};

ContextualHelpMessage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      link: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default ContextualHelpMessage;
