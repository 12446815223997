import { createReducerFromMapping } from 'redux/utils/index';
import {
  loadPartnerAlarmCounts,
  loadPartnerAlarmActionCounts,
  loadHandlingTimeByMonth,
  loadTimeToActionByMonth,
  loadHandlingTimeKPI,
  loadTimeToActionKPI,
  loadBenchmarking,
  loadAlarmCountsPerMonth,
} from 'redux/modules/iot/alarm';
import {
  loadPartnerObservations,
  loadPartnerInspections,
  loadMonthlyInspections,
  loadObservationBenchmark,
} from 'redux/modules/iot/notice';
import { loadFunctionalLocationsForOverview } from 'redux/modules';
import { loadEnergyObservations } from 'redux/modules/containers/energy';

import subDays from 'date-fns/subDays';
import { startOfUTCDay } from 'utils/Date/date';
import { endOfUTCDay } from 'utils/Date/date';
import endOfHour from 'date-fns/endOfHour';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import getYear from 'date-fns/getYear';
import parse from 'date-fns/parse';

export const LOAD_REMOTE_CENTER = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER';
export const LOAD_REMOTE_CENTER_SUCCESS = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER_SUCCESS';
export const LOAD_REMOTE_CENTER_FAIL = 'CUSTOMER_PLATFORM/RemoteCenterContainer/LOAD_REMOTE_CENTER_FAIL';

const initialState = {
  loading: true,
  error: false,
};

const nowDate = new Date();

export const getTimes = () => {
  const yearAgo = startOfUTCDay(subDays(nowDate, 365));
  const yearStart = startOfUTCDay(startOfYear(nowDate));
  const now = endOfHour(nowDate);
  const year = getYear(nowDate);
  return { yearAgo, yearStart, now, year };
};

export const loadAlarmsContainer = partnerNumber => async dispatch => {
  const { yearAgo, now, year } = getTimes();

  try {
    dispatch(loadPartnerAlarmCounts(partnerNumber, yearAgo, now));
    dispatch(loadHandlingTimeKPI(partnerNumber));
    dispatch(loadTimeToActionKPI(partnerNumber));
    dispatch(loadBenchmarking(partnerNumber));
    dispatch(loadPartnerAlarmActionCounts(partnerNumber, yearAgo, now));
    dispatch(loadFunctionalLocationsForOverview(partnerNumber));
    dispatch(loadAlarmCountsPerMonth(year, partnerNumber));
  } catch (error) {
    return dispatch({
      type: LOAD_REMOTE_CENTER_FAIL,
      error,
    });
  }
};

export const loadMoreAlarms =
  (partnerNumber, year, { handlingTime, timeToAction, countsPerMonth }) =>
  async dispatch => {
    try {
      handlingTime && dispatch(loadHandlingTimeByMonth(year, partnerNumber));
      timeToAction && dispatch(loadTimeToActionByMonth(year, partnerNumber));
      countsPerMonth && dispatch(loadAlarmCountsPerMonth(year, partnerNumber));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };

export const loadObservationsContainer = (partnerNumber, year) => {
  return async dispatch => {
    const start = startOfUTCDay(startOfYear(parse(year, 'yyyy', nowDate)));
    const end = endOfUTCDay(endOfYear(parse(year, 'yyyy', nowDate)));

    try {
      dispatch(loadPartnerObservations(partnerNumber, { start, end }));
      dispatch(loadFunctionalLocationsForOverview(partnerNumber));
      dispatch(loadObservationBenchmark(partnerNumber));
      dispatch(loadEnergyObservations(partnerNumber));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadMoreObservations = (partnerNumber, year) => {
  return async dispatch => {
    const start = startOfUTCDay(startOfYear(parse(year, 'yyyy', nowDate)));
    const end = endOfUTCDay(endOfYear(parse(year, 'yyyy', nowDate)));

    try {
      dispatch(loadPartnerObservations(partnerNumber, { start, end }));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadInspectionsContainer = partnerNumber => {
  return async dispatch => {
    const { yearStart, now } = getTimes();

    try {
      dispatch(loadPartnerInspections(partnerNumber, { start: yearStart, end: now }));
      dispatch(loadMonthlyInspections(partnerNumber, { start: yearStart, end: now }));
      dispatch(loadFunctionalLocationsForOverview(partnerNumber));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export const loadMoreInspections = (partnerNumber, year) => {
  return async dispatch => {
    const start = startOfUTCDay(startOfYear(parse(year, 'yyyy', nowDate)));
    const end = endOfUTCDay(endOfYear(parse(year, 'yyyy', nowDate)));

    try {
      dispatch(loadMonthlyInspections(partnerNumber, { start, end }));
    } catch (error) {
      return dispatch({
        type: LOAD_REMOTE_CENTER_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_REMOTE_CENTER]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_REMOTE_CENTER_SUCCESS]: state => ({
      ...state,
      loading: false,
    }),
    [LOAD_REMOTE_CENTER_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  },
  initialState
);
