import { useCallback } from 'react';
import { getLabelFormat, formatLabel } from './utils';
import { useSelector } from 'react-redux';

const useComparisonChartFormatting = ({ intervalType }) => {
  // User's language
  const language = useSelector(state => state.profile.profile.language);

  // X-axis date/time label formatter
  const dateTimeLabelFormatter = useCallback(
    function () {
      const labelIntervalType = this?.chart?.series?.[0]?.options?.intervalType ?? intervalType;
      return formatLabel(this.value, getLabelFormat(labelIntervalType));
    },
    [intervalType]
  );

  const tooltipHeaderFormatter = useCallback(
    function (x, y, points) {
      const labelIntervalType = points?.[0]?.series?.options?.intervalType ?? intervalType;
      return formatLabel(x, getLabelFormat(labelIntervalType));
    },
    [intervalType]
  );

  return {
    language,
    dateTimeLabelFormatter,
    tooltipHeaderFormatter,
  };
};

export default useComparisonChartFormatting;
