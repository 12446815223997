import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import MasterDataService from 'services/masterData';
import {
  isEnergySensor,
  isElectricitySensor,
  isHeatingSensor,
  isWaterConsumptionSensor,
  isEnergyRatingSensor,
  isCoolingSensor,
  isMainEnergyConsumptionSensor,
} from 'utils/Data/values';
import { getEnabledSensors } from 'utils/Data/sensorHierarchy';
import subYears from 'date-fns/subYears';
import startOfYear from 'date-fns/startOfYear';
import endOfDay from 'date-fns/endOfDay';
import startOfHour from 'date-fns/startOfHour';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import addMonths from 'date-fns/addMonths';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import uniqBy from 'lodash/uniqBy';
import { getFilterForSensorsValues } from './sensor_values';

const dateNow = new Date();

const isHourlyEnergySensor = sensor => {
  return (
    (sensor.granularity == null || sensor.granularity === 'hour') &&
    isMainEnergyConsumptionSensor(sensor?.sensorType?.name)
  );
};

export const initialState = {
  energyValues: {},
  energyValuesByPartner: {},
  energyValuesBySensor: {},
  energySensors: {},
  loadingEnergyValues: false,
  hourlyEnergyValues: {},
  hourlyEnergySensors: {},
};

export const LOAD_FL_ENERGY_VALUES = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES';
export const LOAD_FL_ENERGY_VALUES_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_SUCCESS';
export const LOAD_FL_ENERGY_VALUES_FAIL = 'CUSTOMER_PLATFORM/IoT_Values/LOAD_FL_ENERGY_VALUES_FAIL';

export function loadFunctionalLocationsEnergyValues(functionalLocationIds, startTime, endTime) {
  const start = startTime || startOfYear(subYears(dateNow, 2));
  const end = endTime || endOfDay(dateNow);
  const startOfCurrentHour = startOfHour(dateNow);

  return async (dispatch, getState) => {
    let locationFilter;
    if (!!functionalLocationIds.length > 0) {
      locationFilter = {
        functionalLocation: {
          inq: functionalLocationIds,
        },
      };
    }

    dispatch({ type: LOAD_FL_ENERGY_VALUES });
    try {
      if (!locationFilter) {
        return dispatch({
          type: LOAD_FL_ENERGY_VALUES_FAIL,
        });
      }
      let sensorValues = [];
      let hourlySensorValues = [];
      // Fetch sensor data types
      const sensorDataTypes = await MasterDataService.sensorTypes();
      // Get energy sensor types from fetched data
      const energySensorTypes = filter(sensorDataTypes, sensorType => {
        return isEnergySensor(sensorType.name);
      });
      if (!energySensorTypes || energySensorTypes.length === 0) {
        return dispatch({
          type: LOAD_FL_ENERGY_VALUES_FAIL,
        });
      }
      // Fetch energy sensors from targeted FLs sensorHierarchy
      // Energy sensors should always be in group_energy type of group in hierarchy
      const sensorsFilter = {
        where: {
          type: 'building',
          ...locationFilter,
        },
        timestamp: startOfCurrentHour.toISOString(),
        include: {
          relation: 'children',
          scope: {
            where: {
              type: {
                inq: ['group_energy', 'group_heating'],
              },
            },
            include: {
              relation: 'sensors',
              scope: {
                where: {
                  sensorTypeId: {
                    inq: energySensorTypes.map(sensor => sensor.id),
                  },
                },
                include: ['sensorMeta', 'sensorType'],
              },
            },
          },
        },
      };
      const energySensorToBuildingMap = {};
      const sensorHierarchy = await dispatch(MasterDataService.sensorHierarchies(sensorsFilter));
      let energySensors = [];
      // Fetch values for those sensors
      if (sensorHierarchy?.length > 0) {
        const building = sensorHierarchy[0];
        if (building?.children?.length > 0) {
          building.children.forEach(group => {
            if (group && group.sensors && group.sensors.length > 0) {
              // Filter sensors without type and disabled sensors
              const buildingEnergySensors = getEnabledSensors(
                filter(group.sensors, sensor => sensor.sensorTypeId !== null)
              );
              energySensors = energySensors.concat(buildingEnergySensors);

              // Energy sensor can be mapped to a different FL, so we'll make a mapping
              // from sensors to building to be able to tie values to building level FL
              buildingEnergySensors.forEach(sensor => {
                if (sensor && sensor.id) {
                  energySensorToBuildingMap[sensor.id] = building.functionalLocation;
                }
              });
            }
          });
        }
        if (energySensors.length > 0) {
          const filters = [];

          /**
           * Get aggregations based on the sensor's granularity value
           * Types:
           * - energy rating sensors
           * - all energy consumption sensors
           * - energy consumption sensors that have only monthly data
           * - energy consumption sensors that have hourly data for building open hours consumption
           */
          const sensorsByType = {
            energyRating: [],
            energyConsumption: [],
            energyConsumptionWithMonthlyData: [],
            energyConsumptionWithHourlyData: [],
          };
          const energyRatingSensorTypes = filter(sensorDataTypes, sensorType => {
            return isEnergyRatingSensor(sensorType.name);
          });
          energySensors.forEach(sensor => {
            if (find(energyRatingSensorTypes, { id: sensor.sensorTypeId })) {
              // Energy rating is handled differently, so we gather them here
              sensorsByType.energyRating.push(sensor.id);
            } else {
              // Get all monthly granularity consumption sensors
              if (sensor.granularity === 'month') {
                sensorsByType.energyConsumptionWithMonthlyData.push(sensor.id);
              }
              if (isHourlyEnergySensor(sensor)) {
                sensorsByType.energyConsumptionWithHourlyData.push(sensor.id);
              }
              // Add all consumption sensors to energy consumption type
              sensorsByType.energyConsumption.push(sensor.id);
            }
          });
          if (!isEmpty(sensorsByType.energyRating)) {
            filters.push(getFilterForSensorsValues(sensorsByType.energyRating, start, end, 'energyRating'));
          }

          const startOfPreviousMonth = startOfMonth(subMonths(dateNow, 1));
          const endOfMonthBeforePreviousMonth = endOfMonth(subMonths(dateNow, 2));
          const startOfPreviousMonthYearAgo = startOfMonth(subMonths(subYears(dateNow, 1), 1));
          const endOfCurrentMonthYearAgo = endOfMonth(subYears(dateNow, 1));
          const startofNextMonthYearAgo = startOfMonth(addMonths(subYears(dateNow, 1), 1));
          const endOfMonthBeforePreviousMonthYearAgo = endOfMonth(subMonths(subYears(dateNow, 1), 2));

          /**
           * If there are no sensors that have only monthly values, we can use daily values
           * to calculate for example last 365 days and compare values from month ago
           */
          if (
            !isEmpty(sensorsByType.energyConsumption) &&
            isEmpty(sensorsByType.energyConsumptionWithMonthlyData) &&
            isBefore(startOfPreviousMonthYearAgo, end) &&
            isAfter(endOfMonthBeforePreviousMonthYearAgo, start)
          ) {
            /**
             * Get aggregations as:
             * Daily:
             * - current month
             * - month before current month
             * - current month one year ago
             * - month before current month one year ago
             * Monthly:
             * - all the other months
             * This allows us to sum values from last 365 days and 30 days ago 365 days
             * in common case where start and end times are in the middle of the month.
             */

            // Get daily values
            filters.push(
              getFilterForSensorsValues(sensorsByType.energyConsumption, startOfPreviousMonth, end, 'dailySum')
            );
            filters.push(
              getFilterForSensorsValues(
                sensorsByType.energyConsumption,
                startOfPreviousMonthYearAgo,
                endOfCurrentMonthYearAgo,
                'dailySum'
              )
            );
            // Get rest of the values as monthlySum aggregation
            filters.push(
              getFilterForSensorsValues(
                sensorsByType.energyConsumption,
                start,
                endOfMonthBeforePreviousMonthYearAgo,
                'monthlySum'
              )
            );
            filters.push(
              getFilterForSensorsValues(
                sensorsByType.energyConsumption,
                startofNextMonthYearAgo,
                endOfMonthBeforePreviousMonth,
                'monthlySum'
              )
            );
          } else {
            // If there is at least one monthly granularity sensor,
            // or if timeframe is non-regular, we use monthly values
            filters.push(getFilterForSensorsValues(sensorsByType.energyConsumption, start, end, 'monthlySum'));
          }
          if (filters.length > 0) {
            const responses = await Promise.all(filters.map(filter => IoTService.sensorValuesFind(filter)));
            sensorValues = responses.flatMap(({ data }) => data);
          }
          if (!isEmpty(sensorsByType.energyConsumptionWithHourlyData)) {
            // Get 7 full days of data
            const start = startOfDay(subDays(dateNow, 7));
            const end = endOfDay(subDays(dateNow, 1));
            const hourlyValuesFilter = getFilterForSensorsValues(
              sensorsByType.energyConsumptionWithHourlyData,
              start,
              end,
              'hourlySum'
            );
            const response = await IoTService.sensorValuesFind(hourlyValuesFilter);
            hourlySensorValues = response.data;
          }
        }
      }

      return dispatch({
        type: LOAD_FL_ENERGY_VALUES_SUCCESS,
        result: sensorValues,
        hourlySensorValues,
        energySensors,
        energySensorToBuildingMap,
        sensorDataTypes,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FL_ENERGY_VALUES_FAIL,
        error,
      });
    }
  };
}

export default createReducerFromMapping(
  {
    [LOAD_FL_ENERGY_VALUES]: state => ({
      ...state,
      loadingEnergyValues: true,
    }),
    [LOAD_FL_ENERGY_VALUES_SUCCESS]: (state, action) => {
      if (!action.result || action.result.length === 0) {
        return {
          ...state,
          loadingEnergyValues: false,
        };
      }
      const newValues = {};
      const newHourlyValues = {};
      const newValuesBySensor = {};
      const newEnergySensors = {};
      const newHourlyEnergySensors = {};

      const getSensorTypeFromSensor = (sensors, sensorTypes, sensorId) => {
        if (sensors && sensors.length > 0 && sensorTypes && sensorTypes.length > 0) {
          const sensor = find(sensors, { id: sensorId });
          if (sensor) {
            const sensorType = find(sensorTypes, { id: sensor.sensorTypeId });
            if (sensorType) {
              return sensorType.name;
            }
          }
        }
        return null;
      };
      const getValueObject = (data, sensorType, fl) => ({
        aggregation: data.aggregation,
        timestamp: data.timestamp,
        functionalLocation: fl,
        sensorId: data.sensorId,
        type: sensorType,
        value: data.value,
      });
      action.result?.forEach(data => {
        const dataSensorId = +data.sensorId;
        const dataSensorType = getSensorTypeFromSensor(action.energySensors, action.sensorDataTypes, dataSensorId);
        let fl;
        // If energy sensor is found from mapping (it should always be), get building FL id from there
        if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[data.sensorId]) {
          fl = action.energySensorToBuildingMap[data.sensorId];
        } else {
          fl = data.functionalLocation;
        }
        const valueObject = getValueObject(data, dataSensorType, fl);

        if (fl) {
          newValues[fl] = newValues[fl] || {};
          // All sensors should be energy sensors but we might want to change this later
          if (isEnergySensor(dataSensorType)) {
            newValues[fl].allTypes = newValues[fl].allTypes || [];
            newValues[fl].allTypes.push(valueObject);
          }
          if (isElectricitySensor(dataSensorType)) {
            newValues[fl].electricity = newValues[fl].electricity || [];
            newValues[fl].electricity.push(valueObject);
          }
          if (isHeatingSensor(dataSensorType)) {
            newValues[fl].heating = newValues[fl].heating || [];
            newValues[fl].heating.push(valueObject);
          }
          if (isCoolingSensor(dataSensorType)) {
            newValues[fl].cooling = newValues[fl].cooling || [];
            newValues[fl].cooling.push(valueObject);
          }
          if (isWaterConsumptionSensor(dataSensorType)) {
            newValues[fl].waterConsumption = newValues[fl].waterConsumption || [];
            newValues[fl].waterConsumption.push(valueObject);
          }
          if (isEnergyRatingSensor(dataSensorType)) {
            newValues[fl].energyRating = newValues[fl].energyRating || [];
            newValues[fl].energyRating.push(valueObject);
          }
          if (dataSensorType === 'energy_norm') {
            newValues[fl].energyNorm = newValues[fl].energyNorm || [];
            newValues[fl].energyNorm.push(valueObject);
          }
        }
        newValuesBySensor[data.sensorId] = newValuesBySensor[data.sensorId] || [];
        newValuesBySensor[data.sensorId].push(valueObject);
      });
      if (action?.hourlySensorValues?.length > 0) {
        action.hourlySensorValues.forEach(data => {
          const dataSensorId = +data.sensorId;
          const dataSensorType = getSensorTypeFromSensor(action.energySensors, action.sensorDataTypes, dataSensorId);

          let fl;
          // If energy sensor is found from mapping (it should always be), get building FL id from there
          if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[data.sensorId]) {
            fl = action.energySensorToBuildingMap[data.sensorId];
          } else {
            fl = data.functionalLocation;
          }
          const valueObject = getValueObject(data, dataSensorType, fl);
          if (fl) {
            newHourlyValues[fl] = newHourlyValues[fl] || [];
            newHourlyValues[fl].push(valueObject);
          }
        });
      }
      if (action.energySensors && action.energySensors.length > 0) {
        (uniqBy(action.energySensors, 'id') || []).forEach(sensor => {
          let fl;
          if (action.energySensorToBuildingMap && action.energySensorToBuildingMap[sensor.id]) {
            fl = action.energySensorToBuildingMap[sensor.id];
          } else {
            fl = sensor.functionalLocation;
          }
          newEnergySensors[fl] = newEnergySensors[fl] || [];
          newEnergySensors[fl].push(sensor);
          if (isHourlyEnergySensor(sensor)) {
            newHourlyEnergySensors[fl] = newHourlyEnergySensors[fl] || [];
            newHourlyEnergySensors[fl].push(sensor);
          }
        });
      }

      return {
        ...state,
        energyValues: {
          ...state.energyValues,
          ...newValues,
        },
        energyValuesBySensor: {
          ...state.energyValuesBySensor,
          ...newValuesBySensor,
        },
        energySensors: {
          ...state.energySensors,
          ...newEnergySensors,
        },
        hourlyEnergyValues: {
          ...state.energyValues,
          ...newHourlyValues,
        },
        hourlyEnergySensors: {
          ...state.hourlyEnergySensors,
          ...newHourlyEnergySensors,
        },
        loadingEnergyValues: false,
      };
    },
    [LOAD_FL_ENERGY_VALUES_FAIL]: (state, action) => ({
      ...state,
      loadingEnergyValues: false,
    }),
  },
  initialState
);
