import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Collapse from '../Collapse/Collapse';

const rootClass = 'accordion';

export const AccordionItemWrapper = styled.div``;
export const AccordionTitle = styled.div`
  cursor: pointer;
`;

export default class Accordion extends Component {
  state = {
    isOpen: [],
  };

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount() {
    if (this.props.isOpen !== undefined) {
      const isOpen = Array.isArray(this.props.isOpen) ? this.props.isOpen : [this.props.isOpen];
      this.setState({
        isOpen: isOpen,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const isOpen = Array.isArray(this.props.isOpen) ? this.props.isOpen : [this.props.isOpen];
      this.setState({
        isOpen: isOpen,
      });
    }

    // Current implementation cannot track open accordion items reliably if items are added or deleted
    // Reset open state if the number of children changes
    if (
      Array.isArray(this.props.children) &&
      Array.isArray(prevProps.children) &&
      this.props.children.length !== prevProps.children.length
    ) {
      this.setState({ isOpen: [] });
    }
  }

  // Handle clicking of title
  // Opened state is stored as an array of element indexes in this.state.isOpen
  onClick = (idx, event) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const arrayIdx = this.state.isOpen.indexOf(idx);

    // Default behavior doesn't allow multiple tabs to be open
    let isOpenArray = [];

    if (!this.props.allowMultipleOpen) {
      if (arrayIdx === -1) {
        isOpenArray = [idx];
      }
    } else if (arrayIdx === -1) {
      const newArr = this.state.isOpen.slice();
      newArr.push(idx);
      isOpenArray = newArr;
    } else {
      const newArr = this.state.isOpen.slice();
      newArr.splice(arrayIdx, 1);
      isOpenArray = newArr;
    }

    // Set state with callback
    this.setState(
      {
        isOpen: isOpenArray,
      },
      () => {
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(this.state.isOpen);
        }
      }
    );
  };

  // Render single accordion item
  renderItem = (child, idx) => {
    const isClosed = this.state.isOpen.indexOf(idx) === -1;
    const {
      props: { title, titleStyle, titleComponent },
    } = child;
    const { handleClickOnTitleComponent, animate } = this.props;
    const getChild = () => {
      if (animate) {
        return (
          <Collapse
            isCollapsed={isClosed}
            onCollapse={child.props.onCollapse}
            onExpand={child.props.onExpand}
            index={idx}
          >
            {child}
          </Collapse>
        );
      } else if (!isClosed) {
        return <div>{child}</div>;
      }
      return null;
    };

    return (
      <AccordionItemWrapper key={'accordion' + idx}>
        <AccordionTitle
          style={titleStyle}
          onClick={handleClickOnTitleComponent ? undefined : event => this.onClick(idx, event)}
          className={`accordion--${isClosed ? 'closed' : 'open'}`}
        >
          {child.props.titleComponent
            ? React.cloneElement(titleComponent, {
                title,
                open: !isClosed,
                handleClick: handleClickOnTitleComponent ? () => this.onClick(idx) : undefined,
              })
            : title}
        </AccordionTitle>
        {getChild()}
      </AccordionItemWrapper>
    );
  };

  render() {
    const { animate, children, allowMultipleOpen, className, isOpen, handleClickOnTitleComponent, ...other } =
      this.props;
    const classes = className ? rootClass + ' ' + className : rootClass;

    return (
      children && (
        <div data-test-id="Accordion" className={classes} {...other}>
          {Array.isArray(children)
            ? children.map((child, idx) => {
                if (child) {
                  return this.renderItem(child, idx);
                }
                return null;
              })
            : this.renderItem(children, 0)}
        </div>
      )
    );
  }
}

Accordion.defaultProps = {
  allowMultipleOpen: false,
  handleClickOnTitleComponent: false,
  animate: true,
};

Accordion.propTypes = {
  // Custom className
  className: PropTypes.string,
  // Allow multiple elements to be open same time
  allowMultipleOpen: PropTypes.bool,
  // Initial open elements, default is closed
  isOpen: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
  onChange: PropTypes.func,
  // One or many AccordionItems
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
  // Allow handling clicks in the title component
  handleClickOnTitleComponent: PropTypes.bool,
  // Animate collapse (animating enables hidden rendering of all children)
  animate: PropTypes.bool,
};
