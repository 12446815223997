import customerPlatform from './tokens/customerPlatform';
import { multiplyStyles } from './utils/multiply';

import {
  colors,
  brandColors,
  chartColors as chartColorOptions,
  font,
  text,
  defaultColor,
  breakpoints,
} from './definitions';

const themes = {
  customerPlatform: {
    ...customerPlatform,
    colors,
    brandColors,
    chartColorOptions,
    font,
    text,
    defaultColor,
    breakpoints,
  },
};

export const customerPlatformWithMultipliedFontSizes = (fontSizeMultiplier: number) => ({
  ...themes.customerPlatform,
  fontSize: multiplyStyles(themes.customerPlatform.fontSize, fontSizeMultiplier),
  font: {
    ...themes.customerPlatform.font,
    size: multiplyStyles(themes.customerPlatform.font.size, fontSizeMultiplier),
  },
  text: multiplyStyles(themes.customerPlatform.text, fontSizeMultiplier),
});

export default themes;
