import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import InputLabel from 'components/Form/InputLabel';
import InputWithButton from 'components/Form/InputWithButton';
import Svg from 'components/Svg/Svg';
import DialogModal from 'components/Dialog/DialogModal';
import InfoBox from 'components/InfoBox/InfoBox';

import { useTranslations } from 'decorators/Translations/translations';
import { setProfileProperty, saveProfile } from 'redux/modules';

const Container = styled.div`
  padding-bottom: var(--size-md);
`;
Container.displayName = 'Container';

const EmailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-md) var(--size-xxs);
  border-top: 1px solid ${props => props.theme.colors.lightGray};

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  }
`;
EmailRow.displayName = 'EmailRow';

const RemoveIcon = styled(Svg)`
  fill: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.xxs};
  margin: 0 var(--size-xs);
  cursor: pointer;
`;
RemoveIcon.displayName = 'RemoveIcon';

const SensorAlarmEmailsForm = ({ editProfileId, onClose, showNotification }) => {
  const [t] = useTranslations();
  const editProfile = useSelector(state => state.profile.profiles[editProfileId]);
  const [list, setList] = useState(editProfile.ccAlarms || []);
  const dispatch = useDispatch();
  const [saving, setSaving] = React.useState(false);

  const save = async () => {
    setSaving(true);
    try {
      await dispatch(saveProfile(editProfileId, { ...editProfile, ccAlarms: list }));
      await dispatch(setProfileProperty(editProfileId, 'ccAlarms', list));
      showNotification({ type: 'success', message: t('Saved successfully'), autoHide: true });
      onClose();
    } catch (e) {
      showNotification({ type: 'error', message: t('Saving failed'), autoHide: true });
      setSaving(false);
    }
  };

  const addEmail = email => setList(oldList => oldList.concat([email]));
  const removeEmail = index =>
    setList(oldList => oldList.slice(0, index).concat(oldList.slice(index + 1, oldList.length)));

  return (
    <DialogModal
      small
      isActive
      onOverlayClick={onClose}
      t={t}
      actions={{
        cancel: {
          onClick: onClose,
        },
        confirm: {
          onClick: save,
          label: 'Save',
          loading: saving,
        },
      }}
    >
      <h4>{t('Alarm Mailing List')}</h4>
      <InfoBox>
        {t('With alarm mailing list you can add multiple recipients to all your personally configured sensor alarms.')}
      </InfoBox>
      <Container data-test-id="NewRecipientRow">
        <InputLabel text={t('New Recipient')} />
        <InputWithButton placeholder={t('Email address')} buttonText={t('Add')} onSubmit={addEmail} />
      </Container>
      <Container data-test-id="RecipientsRow">
        <InputLabel text={t('Recipients')} />
        {list.length === 0 && '-'}
        {list.map((email, index) => (
          <EmailRow data-test-id="EmailRow" key={email}>
            {email}
            <RemoveIcon data-test-id="RemoveIcon" name="remove" onClick={() => removeEmail(index)} />
          </EmailRow>
        ))}
      </Container>
    </DialogModal>
  );
};

SensorAlarmEmailsForm.propTypes = {
  editProfileId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default SensorAlarmEmailsForm;
