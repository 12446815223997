import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import withQuery from 'decorators/Query/withQuery';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import SectionHeader from 'components/Section/SectionHeader';
import styled from 'styled-components';
import { useBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import PrimaryButton from 'components/Button/PrimaryButton';

const LoginContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const StyledLoginButton = styled(PrimaryButton)`
  margin-bottom: var(--size-xs);
`;

const planSelect = (query, info) => {
  if (info.length === 0) {
    return null;
  }

  return query?.planId || info[0].rescuePlanIdUuid;
};

export const RescuePlanSelectTab = ({ t, info, query, setQuery }) => {
  const [selectedTab, selectTab] = useState(planSelect(query, info));
  const breakpoints = useBreakpoints();

  useEffect(() => {
    selectTab(planSelect(query, info));
  }, [query, info]);

  const handleTabClick = value => {
    selectTab(value);
    setQuery({ planId: value });
  };

  const tabOptions = info.map(info => ({ label: info.buildingName, value: info.rescuePlanIdUuid }));

  const button = (
    <LoginContainer>
      <StyledLoginButton medium reveal>
        {t('Login to Pelsu')}
      </StyledLoginButton>
    </LoginContainer>
  );

  return (
    <>
      <SectionHeader>
        <SectionTabSelector
          noMobilePadding
          options={tabOptions}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(_, value) => handleTabClick(value)}
        />
        {breakpoints.desktop && button}
      </SectionHeader>
      {!breakpoints.desktop && button}
    </>
  );
};

RescuePlanSelectTab.propTypes = {
  t: PropTypes.func.isRequired,
  info: PropTypes.array.isRequired,
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.object,
};

export default withRouter(withQuery(translations(RescuePlanSelectTab)));
