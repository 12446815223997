import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { loadObservationsContainer, loadMoreObservations } from 'redux/modules/containers/remote-center';
import { loadBuildingMeta } from 'redux/modules/buildingConfig/buildingMeta';
import { filterFLsBySelectedPartner } from '../Energy/EnergyUtil';

const EMPTY_OBJECT = {};

const getFunctionalLocationsForPartner = memoizeOne(filterFLsBySelectedPartner);

const mapStateToProps = (state, props) => ({
  observations: state.notice.observationsByPartner[props.partnerNumber] ?? EMPTY_OBJECT,
  functionalLocations: getFunctionalLocationsForPartner(
    state.functionalLocations.functionalLocations,
    props.partnerNumber
  ),
  loading: state.notice.loadingForPartner,
  loadingBenchmark: state.notice.observationBenchmark.loading,
  observationBenchmark: state.notice.observationBenchmark[props?.partnerNumber] ?? EMPTY_OBJECT,
  buildingMeta: state.buildingMeta,
});

const mapDispatchToProps = {
  loadData: loadObservationsContainer,
  loadMoreObservations,
  loadBuildingMeta,
};

export default connect(mapStateToProps, mapDispatchToProps);
