export const DATE_FILTER_FIELD = {
  CREATED: 'date',
  MODIFIED: 'modifiedOn',
};

export const SUBSTATUS_FILTER_FIELD = {
  UNDEFINED: 'undefined',
};

export const DISCIPLINE_FILTER_FIELD = {
  OTHER: 'other',
};

export const defaultFilterValues = {
  dateFilterField: DATE_FILTER_FIELD.CREATED,
  year: new Date().getFullYear(),
  investmentSumField: 'savingsCost',
};

export const OBSERVATION_TABS = {
  OBSERVATIONS: 'Observations',
  INVESTMENTS: 'Investment Calendar',
};

export const convertObservationsToInvestments = (observations, investmentSumField = 'savingsCost') =>
  observations.reduce(
    (result, observation) => {
      if (observation.savingsInvestment) {
        const year = observation.estimatedExecutionDate
          ? String(observation.estimatedExecutionDate?.value?.getFullYear())
          : 'noExecutionDate';
        if (!result.byFl[observation.functionalLocation]) {
          result.byFl[observation.functionalLocation] = {};
        }
        result.byFl[observation.functionalLocation][year] =
          (result.byFl[observation.functionalLocation][year] || 0) + Number(observation[investmentSumField]);
        result.byYear[year] = (result.byYear[year] || 0) + Number(observation[investmentSumField]);
        return result;
      }
      return result;
    },
    { byFl: {}, byYear: {} }
  );
