import meanBy from 'lodash/meanBy';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import filter from 'lodash/filter';
import find from 'lodash/find';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import parseISO from 'date-fns/parseISO';
import { format, DATE_FORMATS } from 'utils/Date/dateFormatter';

import { distinctConditionValue } from 'components/Conditions/distinctConditionValue';

export const CELSIUS = `${String.fromCharCode(176)}C`;

export const statisticsSensorPrefix = 'statistics/';
export const electricityMainSensorNames = ['electricity_main', 'energy/hour'];
export const electricitySubSensorNames = ['electricity_sub'];
export const electricitySensorNames = [...electricityMainSensorNames];
export const heatingMainSensorNames = ['heating_main'];
export const heatingSubSensorNames = ['heating'];
export const heatingSensorNames = [...heatingMainSensorNames];
export const districtHeatingMainSensorNames = ['district_heating'];
export const districtHeatingSubSensorNames = ['district_heating_sub'];
export const coolingSensorNames = ['cooling', 'cooling_main', 'cooling utilization'];
export const coolingMainSensorNames = ['cooling_main'];
export const ventilationSensorNames = ['ventilation', 'ventilation_main', 'ventilation utilization'];
export const waterConsumptionMainSensorNames = ['water_consumption'];
export const waterConsumptionSubSensorNames = ['water_consumption_sub'];
export const energyRatingSensorNames = ['energy/m2', 'energy_rating'];
export const energyNormSensorNames = ['energy_norm'];
export const energySensorNames = [
  ...electricityMainSensorNames,
  ...electricitySubSensorNames,
  ...districtHeatingMainSensorNames,
  ...districtHeatingSubSensorNames,
  ...waterConsumptionMainSensorNames,
  ...waterConsumptionSubSensorNames,
  ...energyRatingSensorNames,
  ...energyNormSensorNames,
  ...heatingMainSensorNames,
  ...coolingMainSensorNames,
];
export const energyConsumptionSensorNames = [
  ...electricityMainSensorNames,
  ...electricitySubSensorNames,
  ...energyNormSensorNames,
  ...heatingMainSensorNames,
  ...heatingSubSensorNames,
  ...districtHeatingMainSensorNames,
  ...districtHeatingSubSensorNames,
  ...coolingSensorNames,
  ...ventilationSensorNames,
  ...waterConsumptionMainSensorNames,
  ...waterConsumptionSubSensorNames,
];
export const isStatisticsSensor = type => {
  return type && type.startsWith(statisticsSensorPrefix);
};
export const isEnergySensor = type => {
  return energySensorNames.indexOf(type) !== -1;
};
export const isEnergyNormSensor = type => {
  return energyNormSensorNames.indexOf(type) !== -1;
};
export const isElectricitySensor = type => {
  return electricitySensorNames.indexOf(type) !== -1;
};
export const isElectricityMainSensor = type => {
  return electricityMainSensorNames.indexOf(type) !== -1;
};
export const isElectricitySubSensor = type => {
  return electricitySubSensorNames.indexOf(type) !== -1;
};
export const isHeatingMainSensor = type => {
  return heatingMainSensorNames.indexOf(type) !== -1;
};
export const isHeatingSubSensor = type => {
  return heatingMainSensorNames.indexOf(type) !== -1;
};
export const isHeatingSensor = type => {
  return heatingSensorNames.indexOf(type) !== -1;
};
export const isCoolingSensor = type => {
  return coolingSensorNames.indexOf(type) !== -1;
};
export const isCoolingMainSensor = type => {
  return coolingMainSensorNames.indexOf(type) !== -1;
};
export const isDistrictHeatingSensor = type => {
  return districtHeatingMainSensorNames.indexOf(type) !== -1;
};
export const isDistrictHeatingSubSensor = type => {
  return districtHeatingSubSensorNames.indexOf(type) !== -1;
};
export const isWaterConsumptionSensor = type => {
  return waterConsumptionMainSensorNames.indexOf(type) !== -1;
};
export const isWaterConsumptionSubSensor = type => {
  return waterConsumptionSubSensorNames.indexOf(type) !== -1;
};
export const isEnergyRatingSensor = type => {
  return energyRatingSensorNames.indexOf(type) !== -1;
};
export const isEnergyConsumptionSensor = type => {
  return energyConsumptionSensorNames.indexOf(type) !== -1;
};
export const getUnitBySensorType = type => {
  if (isWaterConsumptionSensor(type) || isWaterConsumptionSubSensor(type)) {
    return 'm³';
  } else if (
    isElectricityMainSensor(type) ||
    isElectricitySubSensor(type) ||
    isEnergyNormSensor(type) ||
    isHeatingMainSensor(type) ||
    isHeatingSubSensor(type) ||
    isCoolingSensor(type) ||
    isDistrictHeatingSubSensor(type) ||
    isEnergyNormSensor(type)
  ) {
    return 'kWh';
  } else if (isEnergyRatingSensor(type)) {
    return 'kWh/m²';
  }
  return '';
};
// Configuration for energy breakdown
export const energyBreakdownTypes = ['energy', 'electricity', 'heating', 'cooling', 'waterConsumption', 'energyNorm'];
// Configuration for total energy consumption
export const totalEnergyConsumptionTypes = ['electricity', 'heating', 'cooling'];
export const isMainEnergyConsumptionSensor = type => {
  return isElectricityMainSensor(type) || isHeatingMainSensor(type) || isCoolingMainSensor(type);
};
// Map energy type to reference key
export const metaReferenceKeys = {
  districtHeating: 'district_heating_reference',
  heating: 'heating_reference',
  cooling: 'cooling_reference',
  electricity: 'electricity_reference',
  energy: 'energy_reference',
};
export const defaultOutdoorsTemperatureKey = 'default_outdoor_temperature_id';

export const getLatestSensorValueProperties = (latestValue, sensorType, sensorData, sensorMeta, theme, t) => ({
  value: getLatestValueForType(latestValue, sensorType, sensorData, sensorMeta, t),
  time: getLatestValueTimeForType(latestValue, sensorType),
  color: getLatestValueColorForType(latestValue, sensorType, theme),
});

export const getLatestValueForType = (latestValue, sensorType, sensorData, sensorMeta, t) => {
  if (!latestValue || isNil(latestValue.value)) {
    return 'N/A';
  }

  const unit = (sensorType && sensorType.unit) || '';

  if (sensorType) {
    switch (sensorType.graphType) {
      case 'cleaning':
        // TODO: This returns 6.December, but should this be 6.12, as returned from distinctConditionValue() ?
        return format(parseISO(latestValue.timestamp), 'd.MMMM');
      case 's2':
        // TODO: Is this correct? See skipped tests in values.spec.js
        return `${Math.round(meanBy(sensorData, 'avg')) || ''} ${unit}`;
      default:
        break;
    }

    const { distinctValue } = distinctConditionValue(sensorType.graphType, sensorType.unit, latestValue, t, sensorMeta);
    if (distinctValue) {
      return distinctValue;
    }

    if (sensorType.name === 'area_count') {
      const capacity = sensorMeta && find(sensorMeta, { metaKey: 'capacity' });
      const usage = capacity && round((latestValue.value / capacity.value) * 100);
      if (!capacity || !isFinite(usage)) {
        if (!isNil(latestValue.value)) {
          return latestValue.value;
        }
        return 'N/A';
      }
      return `${usage} %`;
    }
  }

  return `${round(latestValue.value, 1)} ${unit}`;
};

export const getLatestValueTimeForType = (latestValue, sensorType) => {
  if (sensorType && sensorType.name !== 'energy_rating' && latestValue && !isNil(latestValue.value)) {
    return format(parseISO(latestValue.timestamp), DATE_FORMATS.dateTimeLong);
  }
};

export const getLatestValueColorForType = (latestValue, sensorType, theme) => {
  if (sensorType && sensorType.name === 'energy_rating') {
    return;
  }
  if (!latestValue || isNil(latestValue.value)) {
    return theme.colors.black;
  }

  if (sensorType) {
    switch (sensorType.graphType) {
      case 'presence':
        return latestValue.value > 0 ? theme.colors.radicalRed : theme.colors.emerald;
      case 's2':
        return latestValue.value && latestValue.value > 90 ? theme.colors.emerald : theme.colors.black;
      default:
        break;
    }
  }

  return theme.colors.black;
};

// Types for the building status sensors
export const HEATING_TYPE = 'heating utilization',
  COOLING_TYPE = 'cooling utilization',
  OUTDOOR_TYPE = 'outdoor temperature',
  WEATHER_TYPE = 'weather/azure/temperature';

export const utilizationTypes = [HEATING_TYPE, COOLING_TYPE, OUTDOOR_TYPE, WEATHER_TYPE];

export const filterBusinessDayValues = values => {
  const isMondayToFriday = timestamp => {
    const weekday = new Date(timestamp).getDay();
    return weekday >= 1 && weekday <= 5;
  };

  return filter(values, value => isMondayToFriday(value.timestamp));
};

export const getSensorMetaValue = (metaKey, sensorMeta = []) => {
  return metaKey && find(sensorMeta, { metaKey })?.value;
};

export const getMaximumSensorValue = sensorData => {
  return maxBy(sensorData, 'value')?.value;
};

export const UNITS = {
  POWER: {
    KW: 'kw',
    MW: 'MW',
    GW: 'GW',
    TW: 'TW',
  },
  ENERGY: {
    KWH: 'kWh',
    MWH: 'MWh',
    GWH: 'GWh',
    TWH: 'TWh',
  },
  MASS: {
    KG: 'kg',
    T: 't',
  },
};

export const ENERGY_UNIT_DIVIDERS = {
  [UNITS.ENERGY.KWH]: 1,
  [UNITS.ENERGY.MWH]: 1000,
  [UNITS.ENERGY.GWH]: 1000000,
  [UNITS.ENERGY.TWH]: 1000000000,
};

export const formatValue = (val = 0, measure = UNITS.ENERGY.KWH) => {
  let value = Number(val);
  let unit = measure;
  let precision = 1;
  let divider = 1;
  if (unit === UNITS.ENERGY.KWH) {
    if (value >= 1000) {
      divider = divider * 1000;
      unit = UNITS.ENERGY.MWH;
    }
  }
  if (unit === UNITS.ENERGY.MWH) {
    if (value >= divider * 1000) {
      divider = divider * 1000;
      unit = UNITS.ENERGY.GWH;
    }
  }
  if (unit === UNITS.ENERGY.GWH) {
    if (value >= divider * 1000) {
      divider = divider * 1000;
      unit = UNITS.ENERGY.TWH;
    }
  }
  value = round(value / divider, 2);
  if (value < 10) {
    precision = 1;
  } else {
    precision = 0;
  }
  return { value: value.toFixed(precision), unit };
};

export const formatEnergyValueToUnit = (value, unit = UNITS.ENERGY.KWH) => {
  if (!Number.isFinite(value)) {
    return 0;
  }
  return value / ENERGY_UNIT_DIVIDERS[unit];
};

export const getIoTDataTimeframeBoundaries = IoTData => {
  const orderedData = orderBy(IoTData, 'timestamp');
  const firstTimestamp = orderedData[0]?.timestamp;
  const lastTimestamp = orderedData[orderedData.length - 1]?.timestamp;
  return {
    firstTimestamp: parseISO(firstTimestamp.toISOString ? firstTimestamp.toISOString() : firstTimestamp),
    lastTimestamp: parseISO(lastTimestamp.toISOString ? lastTimestamp.toISOString() : lastTimestamp),
  };
};

export const getSensorIdsWithSensorTypeAggregation = (aggregation, sensors, sensorTypes) => {
  const sensorTypeIdsWithAggregation = sensorTypes
    .filter(sensorType => sensorType.aggregations.some(entry => entry.aggregation === aggregation))
    .map(sensorType => sensorType.id);
  return sensors
    .filter(sensor => includes(sensorTypeIdsWithAggregation, sensor.sensorType.id))
    .map(sensor => sensor.id);
};

export const getAggregationsForSensor = sensor => {
  let aggregations = [];
  if (sensor?.sensorType?.aggregations) {
    aggregations = [...aggregations, ...sensor?.sensorType?.aggregations];
  }
  if (sensor.sensorType?.name === 'recycling/rate') {
    aggregations = [
      ...aggregations,
      // Add aggregations which are not defined in the MySQL but have existing values in the PostgreSQL
      { aggregation: 'recyclingMonthlyRecoveryRate', frequency: 'monthly' },
      { aggregation: 'recyclingMonthlyRecyclingRate', frequency: 'monthly' },
    ];
  }
  return aggregations;
};

export const getAggregationByFrequency = (sensor, frequency) =>
  getAggregationsForSensor(sensor)
    .filter(aggregation => {
      if (frequency === 'weekly') {
        return aggregation.frequency === 'daily';
      }
      if (frequency === 'yearly') {
        return aggregation.frequency === 'monthly';
      }
      return aggregation.frequency === frequency;
    })
    .map(aggregation => aggregation.aggregation);

export const sensorDataAggregations = [
  {
    frequency: 'hourly',
    grouping: 'hour',
    aggregations: ['hourlySum', 'hourlyAverage'],
  },
  {
    frequency: 'daily',
    grouping: 'day',
    aggregations: ['dailySum', 'dailyAverage'],
  },
  {
    frequency: 'monthly',
    grouping: 'month',
    aggregations: ['monthlySum', 'monthlyAverage'],
  },
];
