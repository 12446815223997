import styled from 'styled-components';

export type GroupProps = ({ col?: true } | { row: true }) & {
  wrap?: true | 'wrap' | 'nowrap';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'first baseline' | 'last baseline';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'space-between' | 'space-around' | 'space-evenly';
};

/**
 * Base grouping component that separates the items with a gap. The following components
 * belong to this suite:
 * <ul>
 *   <li>{@link FlexViewGroup}, 24px
 *   <li>FlexGroup (this), 16px
 *   <li>{@link FlexSubGroup}, 8px
 *   <li>{@link FlexTightGroup}, 0
 * </ul>
 *
 * @implements {UIv3}
 */
export const FlexGroup = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${props => ('row' in props ? 'row' : 'column')};
  flex-wrap: ${props => (props.wrap === true ? 'wrap' : props.wrap || 'nowrap')};
  align-items: ${props => props.alignItems ?? 'inherit'};
  justify-content: ${props => props.justifyContent ?? 'inherit'};

  position: relative;

  gap: var(--size-md);
`;
