import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Description } from '../common';
import InputRow from 'components/Form/InputRow';
import { SendButtonWrapper } from './AdditionalSaleForm';
import PrimaryButton from 'components/Button/PrimaryButton';
import { default as FormInput } from './ManagerChangeFormInput';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';

const FIELD_CONFIGURATION = {
  correctManagerName: {
    label: 'New Name',
    property: 'managerName',
    defaultValue: '',
  },
  correctManagerEmail: {
    label: 'New Email Address',
    property: 'managerEmail',
    defaultValue: '',
  },
  email: {
    label: 'Your Email Address',
    property: 'email',
    defaultValue: '',
  },
};

const ManagerEmailChangeForm = ({ onSubmit, sending, showEmailConflictError }) => {
  const { t } = useUnauthenticatedTranslations();

  const schema = React.useMemo(
    () =>
      yup
        .object()
        .shape({
          [FIELD_CONFIGURATION.correctManagerName.property]: yup
            .string()
            .label(t(FIELD_CONFIGURATION.correctManagerName.label))
            .required(),
          [FIELD_CONFIGURATION.correctManagerEmail.property]: yup
            .string()
            .label(t(FIELD_CONFIGURATION.correctManagerEmail.label))
            .email()
            .required(),
          [FIELD_CONFIGURATION.email.property]: yup
            .string()
            .label(t(FIELD_CONFIGURATION.email.label))
            .email()
            .required(),
        })
        .required(),
    [t]
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    defaultValues: {
      [FIELD_CONFIGURATION.correctManagerName.property]: FIELD_CONFIGURATION.correctManagerName.defaultValue,
      [FIELD_CONFIGURATION.correctManagerEmail.property]: FIELD_CONFIGURATION.correctManagerEmail.defaultValue,
      [FIELD_CONFIGURATION.email.property]: FIELD_CONFIGURATION.email.defaultValue,
    },
  });

  return (
    <>
      <Description grayText>
        {t(
          'Provide new name and new email address to change the permission to answer to this ticket. Please note that only the latest defined email address will be able to reply.'
        )}
      </Description>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputRow fullRow dense>
          <FormInput
            property={FIELD_CONFIGURATION.correctManagerName.property}
            label={FIELD_CONFIGURATION.correctManagerName.label}
            register={register}
            required={true}
            error={errors?.[FIELD_CONFIGURATION.correctManagerName.property]?.message}
          />
        </InputRow>
        <InputRow fullRow dense>
          <FormInput
            property={FIELD_CONFIGURATION.correctManagerEmail.property}
            label={FIELD_CONFIGURATION.correctManagerEmail.label}
            register={register}
            required={true}
            error={
              showEmailConflictError
                ? t('This email address does not have permission to reply')
                : errors?.[FIELD_CONFIGURATION.correctManagerEmail.property]?.message
            }
          />
        </InputRow>
        <InputRow fullRow dense>
          <FormInput
            property={FIELD_CONFIGURATION.email.property}
            label={FIELD_CONFIGURATION.email.label}
            register={register}
            required={true}
            error={errors?.[FIELD_CONFIGURATION.email.property]?.message}
            extraText={t('Required for security validation')}
          />
        </InputRow>
        <SendButtonWrapper>
          <PrimaryButton type="submit" large confirm loading={sending} disabled={sending}>
            {t('Send')}
          </PrimaryButton>
        </SendButtonWrapper>
      </form>
    </>
  );
};

ManagerEmailChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired,
  showEmailConflictError: PropTypes.bool.isRequired,
};

export default ManagerEmailChangeForm;
