import React from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Accordion from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';
import AccordionTitle from 'components/Accordion/AccordionTitle';
import DocumentStyle from '../DocumentStyle';
import { markdownImageComponent } from '../RescuePlanMarkdown/RescuePlanMarkdown';
import withQuery from 'decorators/Query/withQuery';

const MarkdownComponents = {
  p: paragraph => {
    return markdownImageComponent(paragraph);
  },
};

// opens sections when linked to it
const OpenAccordionSectionQueried = (sections, query) => {
  const slug = query.slug;
  const index = sections.findIndex(section => section.sectionSlug === slug);
  return index === -1 ? [] : [index];
};

export const ActionSections = ({ t, actionSections, query, setQuery }) => {
  const actionSection = section => {
    const heading = section.section.match('# (.*)\n')[1];

    return (
      <AccordionItem
        id={section.sectionSlug}
        key={section.sectionSlug}
        title={heading}
        titleComponent={
          <AccordionTitle
            onClick={e => {
              if (query.slug === section.sectionSlug) {
                setQuery({ slug: '' });
              } else {
                setQuery({ slug: section.sectionSlug });
              }
            }}
          ></AccordionTitle>
        }
      >
        <DocumentStyle>
          <ReactMarkdown children={section.section} remarkPlugins={[remarkGfm]} components={MarkdownComponents} />
        </DocumentStyle>
      </AccordionItem>
    );
  };

  const guide = actionSections.map(section => actionSection(section));
  const open = OpenAccordionSectionQueried(actionSections, query);

  return <Accordion handleClickOnTitleComponent children={guide} isOpen={open}></Accordion>;
};

ActionSections.propTypes = {
  t: PropTypes.func.isRequired,
  actionSections: PropTypes.array.isRequired,
  query: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
};

export default withQuery(translations(ActionSections));
