import React from 'react';
import { Link } from 'react-router-dom';

import * as Styled from './ModalHeaderBuildingDescription.styled';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { FunctionalLocation } from './ModalHeader';

type Props = {
  locationLink: string;
  functionalLocation: FunctionalLocation;
  loading: boolean;
};

const BuildingDescription = (props: Props) => {
  const { functionalLocation, locationLink, loading } = props;
  const { name, description, address, postalCode, city } = functionalLocation || {};

  const locationName = name || description || functionalLocation?.functionalLocation;

  const buildingName = `${address}, ${postalCode ? `${postalCode} ` : ''}${city}`;
  if (loading) {
    <Styled.BuildingDescription>
      <SkeletonText />
    </Styled.BuildingDescription>;
  }

  return (
    <Styled.BuildingDescription>
      {locationLink ? <Link to={locationLink}>{locationName}</Link> : locationName}, <span>{buildingName}</span>
    </Styled.BuildingDescription>
  );
};

export default BuildingDescription;
