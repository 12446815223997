import React from 'react';
import styled from 'styled-components';

import Anonymous from 'containers/Application/Anonymous/Anonymous';
import AnonymousHeader from 'containers/Application/Anonymous/AnonymousHeader';
import Giosg from 'components/Giosg/Giosg';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';

const Lists = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.landscape`
        flex-direction: row;
    `}
`;

const ListWrapper = styled.div`
  flex: 1;
  margin-bottom: var(--size-sm);
`;

const ListHeader = styled.h3`
  margin-bottom: var(--size-sm);
`;

const Important = styled.h4`
  margin: var(--size-xl) 0;
  text-align: center;
`;

const List = styled.ul`
  list-style: disc;
  padding-left: var(--size-md);
`;

const ListItem = styled.li`
  padding: var(--size-xs) 0;
`;

/* eslint-disable max-len */

const textOverride =
  'Keskustelu ei ole juuri nyt käytettävissä.\n\nVikailmoitukset:\npuh. 0800 157 777\nkiinteistohelp@caverion.com';

const Helpdesk = () => (
  <Anonymous title="Helpdesk">
    <AnonymousHeader showCaverionLink />
    <Section>
      <SectionHeader title={'Caverion Kiinteistö-HelpDesk – Ongelmia kiinteistössä?'} />
      <Lists>
        <ListWrapper>
          <ListHeader>Yhteystiedot HelpDeskiin</ListHeader>
          <List>
            <ListItem>
              <a href="tel:0800 157 777">0800 157 777</a> (24/7)
            </ListItem>
            <ListItem>Chat (sivun oikea alareuna)</ListItem>
            <ListItem>
              <a href="mailto:kiinteistohelp@caverion.com">kiinteistohelp@caverion.com</a>
            </ListItem>
            <ListItem>
              <a
                href="https://www.caverion.fi/yhteystiedot/kiinteisto-helpdesk-24h/palveluilmoitus"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.caverion.fi/yhteystiedot/kiinteisto-helpdesk-24h/palveluilmoitus
              </a>
            </ListItem>
          </List>
        </ListWrapper>
        <ListWrapper>
          <ListHeader>Antaessasi vikailmoitusta Caverion HelpDeskiin</ListHeader>
          <List>
            <ListItem>Kerro tarkat yhteystietosi (nimi, puhelinnumero, sähköposti)</ListItem>
            <ListItem>Kerro mistä soitat; osoite, kaupunki</ListItem>
            <ListItem>Kerro tilaajan yritys, mikäli tilaajataho on eri kuin soittaja</ListItem>
            <ListItem>Kerro kerros ja huonenumero / tila sekä viallisen laitteen tiedot</ListItem>
            <ListItem>Kerro viankuvaus; esimerkiksi wc-tukos, lampun vaihto, muu kiinteistöön puute tai vika</ListItem>
            <ListItem>Kerro kohteessa olevan yhteyshenkilön tiedot, mikäli yhteyshenkilö on eri kuin soittaja</ListItem>
            <ListItem>
              Sähköpostiviestit käsitellään saapumisjärjestyksessä arkisin klo 7-21 ja lauantaisin, sunnuntaisin sekä
              arkipyhinä 8-20
            </ListItem>
          </List>
        </ListWrapper>
      </Lists>
      <Important>Kiireellisistä asioista on aina soitettava (esimerkiksi vesivuoto, sähkökatkos)</Important>
    </Section>
    <Giosg giosgId={4687} textOverride={textOverride} />
  </Anonymous>
);

export default Helpdesk;
