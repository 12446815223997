import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createRootReducer from './modules/reducer';

import { ApiSettingsProvider } from 'utils/Fetch/providers';

const create = history => {
  const middleware = [
    thunk.withExtraArgument({
      settings: ApiSettingsProvider,
    }),
    routerMiddleware(history),
  ];
  return createStore(createRootReducer(history), composeWithDevTools(applyMiddleware(...middleware)));
};

export default create;
