import { ANNOTATION_TARGET, ANNOTATION_TARGET_LABEL } from './utils';

const valueDefaults = { sensorId: null, functionalLocation: null, customChartId: null };

const getDefaultTargetOptions = (sensor, chart, functionalLocation, t) => {
  const options = [];

  if (sensor) {
    options.push({
      ...valueDefaults,
      label: t(ANNOTATION_TARGET_LABEL[ANNOTATION_TARGET.measuringPoint]),
      sensorId: sensor.id,
    });
  }
  if (sensor?.parentId) {
    options.push({
      ...valueDefaults,
      label: t(ANNOTATION_TARGET_LABEL[ANNOTATION_TARGET.device]),
      sensorId: sensor?.parentId ? String(sensor.parentId) : sensor.id,
    });
  }
  if (chart) {
    options.push({
      ...valueDefaults,
      label: t(ANNOTATION_TARGET_LABEL[ANNOTATION_TARGET.customChart]),
      customChartId: chart.id,
    });
  }
  if (functionalLocation) {
    options.push({
      ...valueDefaults,
      label: t(ANNOTATION_TARGET_LABEL[ANNOTATION_TARGET.building]),
      functionalLocation,
    });
  }
  return options;
};

export default getDefaultTargetOptions;
