import React from 'react';
import IoTService from 'services/iot';
import isValid from 'date-fns/isValid';
import groupBy from 'lodash/groupBy';
import endsWith from 'lodash/endsWith';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

import { getAggregationByFrequency } from 'utils/Data/values';
import useBuildingSensors from '../../useBuildingSensors';
import { mapComparisonData, dataMapper } from './utils';
import { getFilterForSensorsValues } from 'redux/modules/iot/values/sensor_values';
import useOutdoorTemperatureSensor from '../../CustomChartConfigModal/useOutdoorTemperatureSensor';
import isEqual from 'lodash/isEqual';

export const useComparisonCustomChart = ({ functionalLocationId, chart, valuesByComparisonSeries }) => {
  const [keyValuesData, setKeyValuesData] = React.useState([]);
  const buildingSensors = useBuildingSensors(functionalLocationId);
  const outdoorTemperatureSensor = useOutdoorTemperatureSensor(functionalLocationId);
  const values = React.useRef([]);

  const sensorData = React.useMemo(
    () => mapComparisonData(chart, valuesByComparisonSeries, buildingSensors, outdoorTemperatureSensor),
    [valuesByComparisonSeries, chart, buildingSensors, outdoorTemperatureSensor]
  );

  const handleDrilldownDataLoading = React.useCallback(
    async ({ drilldown: { startDate, endDate, category, sensors, unit }, intervalType }) => {
      if (!isValid(startDate) || !isValid(endDate)) {
        return;
      }

      let values = [];

      for (const sensor of sensors) {
        const aggregation = getAggregationByFrequency(sensor, intervalType)[0];
        if (!aggregation) {
          return;
        }

        const filter = getFilterForSensorsValues([sensor.id], startDate, endDate, aggregation);
        const response = await IoTService.sensorValuesFind(filter);
        values.push(...response.data);
      }

      if (sensors.length > 1) {
        const valueGroups = Object.values(groupBy(values, value => value.timestamp));
        const aggregationFunc = endsWith(values[0]?.aggregation, 'Sum') ? sumBy : meanBy;
        values = valueGroups.map(valueArray => ({ ...valueArray[0], value: aggregationFunc(valueArray, 'value') }));
      }

      return {
        data: dataMapper(values, unit),
        category,
      };
    },
    []
  );

  const handleSeriesChange = React.useCallback(function (event) {
    const series = event?.target?.series;
    if (!series) {
      return;
    }
    const dataSeries = series.filter(seriesObj => {
      const { options } = seriesObj;
      return (options?.sensors?.length > 0 || options?.category === 'reference') && !options.isOutdoorTemperature;
    });
    const referenceUnit = dataSeries?.[0]?.options?._unit;
    const mappedKeyValueData = dataSeries.map(seriesObj => {
      const { options } = seriesObj;
      return {
        data: seriesObj?.yData?.map(data => ({ value: data })),
        sensor: {
          ...(options?.sensors?.[0] ?? {}),
          ...(options?.category === 'reference' && { sensorType: { unit: referenceUnit } }),
          ...(seriesObj?.name && { name: seriesObj?.name }),
        },
        color: options?.color,
      };
    });
    if (!isEqual(mappedKeyValueData, values.current)) {
      // We use values.current to avoid having keyValuesData as a dependency to useCallback
      values.current = mappedKeyValueData;
      setKeyValuesData(mappedKeyValueData);
    }
  }, []);

  const getChartType = chart => {
    const graphType = chart.series?.[0]?.graphType;
    switch (graphType) {
      case 'bar':
        return 'column';
      case 'area':
        return 'area';
      default:
        return 'spline';
    }
  };

  return {
    handleDrilldownDataLoading,
    sensorData,
    getChartType,
    keyValuesData,
    handleSeriesChange,
  };
};

export default useComparisonCustomChart;
