import styled from 'styled-components';

const SubformComponent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SubformWithMargin = styled(SubformComponent)`
  margin-top: var(--size-sm);
`;

export default SubformComponent;
