import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font: var(--font-xs);
  line-height: 3em;
  color: var(--input-group-label-fg);
  display: ${props => (props.hideOnMobile ? 'none' : 'inline-block')};
  padding-left: 1px;

  &::first-letter {
    text-transform: uppercase;
  }

  ${props => props.theme.media.portrait`
    display: inline-block;
  `}
`;
Label.displayName = 'Label';

export const ExtraText = styled.span`
  font-weight: ${props => props.theme.font.weight.normal};
  padding-left: var(--size-xxs);
`;

export const ErrorText = styled.span`
  position: relative;
  float: right;
  padding-left: var(--size-xxs);
  color: var(--error-color);
  text-transform: none;
`;

const Required = styled.span`
  padding-left: var(--size-xxs);
  color: var(--input-required-indicator-fg);
`;

export const SecondaryText = styled.span`
  color: var(--supplemental-text-fg);
  font-weight: ${props => props.theme.font.weight.normal};
`;

const CtxWrapper = styled.div``;

export const InputLabel = props => {
  return (
    <Container className={props.className}>
      <Label htmlFor={props.id} hideOnMobile={props.hideOnMobile}>
        {props.text || '\u00A0' /* Non-breaking space */}
        {props.required && <Required className="required">*</Required>}
        {props.secondaryText && <SecondaryText>{props.secondaryText}</SecondaryText>}
        {props.extraText && <ExtraText>{props.extraText}</ExtraText>}
        {props.errorText && <ErrorText>{props.errorText}</ErrorText>}
      </Label>
      <CtxWrapper>
        {props.ctxHelp && (
          <ContextualHelp
            title={props.text}
            text={props.ctxHelp}
            textParams={props.ctxHelpParams}
            position={props.ctxHelpPosition}
          />
        )}
      </CtxWrapper>
    </Container>
  );
};

InputLabel.displayName = 'InputLabel';

InputLabel.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  ctxHelp: PropTypes.string,
  ctxHelpParams: PropTypes.array,
  ctxHelpPosition: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  extraText: PropTypes.string,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  secondaryText: PropTypes.string,
};

InputLabel.defaultProps = {
  id: undefined,
  className: '',
  ctxHelpPosition: 'top',
};

export default InputLabel;
