import styled from 'styled-components';
import { withErrorBoundary } from '../ErrorPage/ErrorBoundary';

export const TabContent = styled.div`
  width: 100%;
  max-width: calc(${props => props.theme.grid.maxWidth} + 2 * ${props => props.theme.grid.gutter});
  margin: 0 auto;

  padding-top: 1rem;
`;

export default withErrorBoundary(TabContent);
