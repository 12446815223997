import styled from 'styled-components';

export const BuildingDescription = styled.div`
  font-family: ${props => props.theme.fontFamily.subHeading};
  font-size: ${props => props.theme.fontSize.xs};
  font-weight: ${props => props.theme.fontWeight.heading};
  span {
    color: var(--grey-100);
  }
`;
