import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';

export const VIEW_TYPES = Object.freeze({
  SERVICE_REQUEST_FORM_VIEW: 'serviceRequestForm',
  BUILDING_FLOORS_VIEW: 'buildingFloors',
});

export const VIEW_TYPE_LABELS = Object.freeze({
  [VIEW_TYPES.SERVICE_REQUEST_FORM_VIEW]: 'Service request form',
  [VIEW_TYPES.BUILDING_FLOORS_VIEW]: 'Floors view',
});

export const FILTER_PROPERTIES = Object.freeze(['customer', 'functionalLocation', 'viewType']);

export const FILTER_PROPERTY_LABELS = Object.freeze({
  customer: 'Customer',
  functionalLocation: 'Target',
  viewType: 'View',
});

const filterContainsValue = (filter, value) => {
  if (Array.isArray(filter)) {
    return filter.includes(value);
  }
  return filter === value;
};

const viewMatchesFilter = (view, filters) => {
  if (filters.customer && !filterContainsValue(filters.customer, view.customer)) {
    return false;
  }
  if (filters.functionalLocation && !filterContainsValue(filters.functionalLocation, view.functionalLocation)) {
    return false;
  }
  if (filters.viewType && !filterContainsValue(filters.viewType, view.viewType)) {
    return false;
  }
  return true;
};

export const getFilteredPublicViewPortfolios = (publicViews, filters, partnerSearchOptions) => {
  if (!publicViews?.length) {
    return [];
  }
  const basePortfolios = keyBy(partnerSearchOptions, 'value');
  const portfoliosWithPublicViews = reduce(
    publicViews,
    (accumulator, view) => {
      if (viewMatchesFilter(view, filters) && accumulator[view.customer]) {
        if (!accumulator[view.customer].publicViews) {
          accumulator[view.customer].publicViews = [];
        }
        accumulator[view.customer].publicViews.push(view);
      }
      return accumulator;
    },
    cloneDeep(basePortfolios)
  );
  return filter(portfoliosWithPublicViews, 'publicViews');
};

export const getFilterOptions = (publicViews, partners, functionalLocations, t) => {
  if (!publicViews) {
    return {};
  }
  const customersOnRows = new Set(publicViews.map(view => view.customer));
  const functionalLocationsOnRows = new Set(publicViews.map(view => view.functionalLocation));
  const viewTypesOnRows = new Set(publicViews.map(view => view.viewType));
  return {
    customer: partners.filter(partnerOption => customersOnRows.has(partnerOption.value)),
    functionalLocation: functionalLocations
      .filter(fl => functionalLocationsOnRows.has(fl.functionalLocation))
      .map(fl => ({
        label: `${fl.name} (${fl.functionalLocation})`,
        value: fl.functionalLocation,
      })),
    viewType: Object.values(VIEW_TYPES)
      .filter(viewType => viewTypesOnRows.has(viewType))
      .map(value => ({ label: t(VIEW_TYPE_LABELS[value]), value })),
  };
};

export const formatViewTargetLabel = (item, partners, functionalLocations) => {
  if (item.functionalLocation) {
    const fl = functionalLocations.find(fl => fl.functionalLocation === item.functionalLocation);
    return fl ? `${fl.name} (${fl.functionalLocation})` : item.functionalLocation;
  }
  return partners.find(partner => partner.value === item.customer)?.label || item.customer;
};
