const b2c = {
  clientId: process.env.OAUTH_B2C_CLIENT_ID,
  authority: process.env.OAUTH_B2C_AUTHORITY,
  knownAuthorities: [process.env.OAUTH_B2C_KNOWN_AUTHORITIES],
  scopes: [process.env.OAUTH_B2C_CLIENT_ID],
};

export const loadAuthConfig = () => {
  const config = {
    auth: {
      clientId: process.env.OAUTH_CLIENT_ID,
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: '/',
      scopes: [process.env.OAUTH_SCOPE],
      ...(Boolean(sessionStorage.getItem('sso')) ? b2c : {}),
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback: (_level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          console.log(message);
        },
        logLevel: process.env.NODE_ENV === 'development' ? 1 : 0,
        piiLoggingEnabled: process.env.NODE_ENV === 'development',
      },
    },
  };
  return config;
};
