import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import includes from 'lodash/includes';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';

import DialogModal from 'components/Dialog/DialogModal';
import InfoBox from 'components/InfoBox/InfoBox';
import InputBooleanCheckbox from 'components/Form/InputBooleanCheckbox';
import translations from 'decorators/Translations/translations';

const List = styled.ul`
  margin: var(--size-md) 0 var(--size-sm);
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.mystic};
  padding: var(--size-sm);
`;

const TableConfigurationModal = props => {
  const { t, configurationOptions, configuration, onSubmit, onClose, hiddenColumns = [] } = props;
  const sortOrder = Object.fromEntries(configurationOptions.map(({ accessor }, index) => [accessor, index]));
  const [model, setModel] = React.useState(
    fromPairs(
      map(configurationOptions, option => [
        option.accessor,
        {
          label: option.label || option.Header || option.title,
          selected: includes(configuration, option.accessor),
          hide: !option.Header && !option.label && !option.title,
          disabled: option.accessor === 'title',
        },
      ])
    )
  );
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(model);
    setLoading(false);
    onClose();
  };

  const handleCheckbox = (property, value) => {
    setModel({ ...model, [property]: { ...model[property], selected: value } });
  };

  return (
    <DialogModal
      small
      isActive
      onOverlayClick={onClose}
      t={t}
      actions={{
        cancel: {
          onClick: onClose,
        },
        confirm: {
          onClick: handleSubmit,
          loading: loading,
        },
      }}
    >
      <section>
        <h3>{t('Select columns')}</h3>

        <List>
          {Object.entries(model)
            .sort(([a], [b]) => sortOrder[a] - sortOrder[b])
            .map(([field, config]) => {
              if (config.hide) {
                return null;
              }

              if (hiddenColumns.includes(field)) {
                return null;
              }

              return (
                <ListItem key={field}>
                  <InputBooleanCheckbox
                    id={field}
                    property={field}
                    onChange={handleCheckbox}
                    label={config.label}
                    value={config.selected}
                    disabled={config.disabled}
                  />
                </ListItem>
              );
            })}
        </List>

        <InfoBox>
          {t(
            'High number of columns might cause complexity or overlapping in the user interface. ' +
              'Recommended amount of columns is max 5.'
          )}
        </InfoBox>
      </section>
    </DialogModal>
  );
};

TableConfigurationModal.propTypes = {
  t: PropTypes.func.isRequired,
  configurationOptions: PropTypes.array.isRequired,
  configuration: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  // these columns will be hidden from configuration list
  // hiding won't remove column from the saved list configuration array
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

export default translations(TableConfigurationModal);
