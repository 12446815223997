import styled from 'styled-components';

/**
 * Padding for group level, and only that.
 *
 * Applies to e.g. snack bar notifications - anything that
 * is definitely tighter than a section/container.
 *
 * @see {@link ItemPadding}
 * @implements {UIv3}
 */
export const GroupPadding = styled.div`
  padding: var(--size-md);
`;
