import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  getFunctionalLocationId,
  wasteSensorsSelector,
  syntheticSensorsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { loadRatesBreakdownData, loadRatesBreakdownTotals } from 'redux/modules/iot/values/recycling';

const getRatesBreakdownError = state => state.values.recycling.ratesBreakdown.error;
const getRatesBreakdownMeta = state => state.values.recycling.ratesBreakdown.meta;
const getRatesBreakdownTotalsError = state => state.values.recycling.ratesBreakdownTotals.error;
const getRatesBreakdownTotalsMeta = state => state.values.recycling.ratesBreakdownTotals.meta;

const needsReloadSelector = createSelector(
  getFunctionalLocationId,
  getRatesBreakdownMeta,
  getRatesBreakdownTotalsMeta,
  (functionalLocationId, breakdownMeta, breakdownTotalsMeta) =>
    breakdownMeta.functionalLocationId !== functionalLocationId ||
    breakdownTotalsMeta.functionalLocationId !== functionalLocationId
);

const dataStatusSelector = createSelector(
  getRatesBreakdownMeta,
  getRatesBreakdownError,
  getRatesBreakdownTotalsMeta,
  getRatesBreakdownTotalsError,
  needsReloadSelector,
  (breakdownMeta, breakdownError, breakdownTotalsMeta, breakdownTotalsError, needsReload) => ({
    isLoading: breakdownMeta.loading || breakdownTotalsMeta.loading,
    errors: [
      breakdownError,
      breakdownTotalsError,
    ],
    needsReload,
  }));

export const mapStateToProps = (state, props) => ({
  functionalLocationId: getFunctionalLocationId(state, props),
  wasteSensors: wasteSensorsSelector(state, props),
  syntheticSensors: syntheticSensorsSelector(state, props),
  dataStatus: dataStatusSelector(state, props),
});

export const mapDispatchToProps = {
  loadRatesBreakdownData,
  loadRatesBreakdownTotals,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
