import withLabelOptions from 'components/Charts/DefaultCustomChart/withLabelOptions';
import updateChartAnnotations from 'components/Sensor/SensorAnnotations/updateChartAnnotations';
import { useCallback } from 'react';
import customChartGranularity from './customChartGranularity';

const triggers = {
  LOAD: 'load',
  USER: 'user',
};

const useEvents = (handleDynamicDataLoading, getAnnotationConfigs) => {
  const setExtremes = async event => {
    if (event.target.chart?.series && event.trigger) {
      await handleDynamicDataLoading(
        event.target.chart,
        event.target.chart.series,
        event.min,
        event.max,
        triggers.USER,
        event.trigger
      );

      updateChartAnnotations(
        event.target.chart,
        withLabelOptions(getAnnotationConfigs(customChartGranularity(event.min, event.max)), true)
      );
      event.target.chart.redraw();
    }
  };

  const onLoad = useCallback(
    chart => {
      handleDynamicDataLoading(chart, chart.series, chart.xAxis?.[0].min, chart.xAxis?.[0].max, triggers.LOAD);
    },
    [handleDynamicDataLoading]
  );

  return { onLoad, setExtremes };
};

export default useEvents;
