import styled from 'styled-components';
import withProgress from 'decorators/Progress/withProgress';

const Progress = styled.div.attrs(props => ({
  style: {
    transform: `translateX(${props.progress - 100}%)`,
  },
}))`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--file-progress-bg);
  transition: ${props => props.theme.motion.quick} transform linear;
`;

export default withProgress(Progress);
