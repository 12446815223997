import React from 'react';
import Svg from 'components/Svg/Svg';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import { flIcons } from 'utils/Data/functionalLocations';

export const ICON_TYPES = {
  DEFAULT: 'DEFAULT',
  PORTFOLIO: 'PORTFOLIO',
  SECONDARY: 'SECONDARY',
  EQUIPMENT: 'EQUIPMENT',
  MODAL: 'MODAL',
  MODAL_WARNING: 'MODAL_WARNING',
  TRASH_CAN: 'TRASH_CAN',
  DROPZONE: 'DROPZONE',
  TRANSPARENT: 'TRANSPARENT',
  OBSERVATION: 'OBSERVATION',
};

export const ICON_SIZES = {
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL_MINUS: 'SMALL_MINUS',
  SMALL: 'SMALL',
  SMALL_PLUS: 'SMALL_PLUS',
  MEDIUM_MINUS: 'MEDIUM_MINUS',
  MEDIUM: 'MEDIUM',
};

const getColorsByType = ({ iconType, theme, background }) => {
  switch (iconType) {
    case ICON_TYPES.DEFAULT: {
      return {
        backgroundColor: 'var(--brand-color)',
        border: 'var(--brand-color)',
      };
    }
    case ICON_TYPES.PORTFOLIO: {
      return {
        backgroundColor: 'var(--brand-color-secondary)',
        border: 'var(--brand-color-secondary)',
      };
    }
    case ICON_TYPES.SECONDARY: {
      return {
        backgroundColor: 'var(--grey-50)',
        border: 'var(--grey-50)',
      };
    }
    case ICON_TYPES.EQUIPMENT: {
      return {
        backgroundColor: 'var(--grey-08)',
      };
    }
    case ICON_TYPES.TRASH_CAN: {
      return {
        background: 'var(--danger-color)',
        border: 'var(--danger-color)',
      };
    }
    case ICON_TYPES.MODAL_WARNING: {
      return {
        background: 'var(--red-01)',
        border: `7px solid var(--red-01-tinted)`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.MODAL: {
      return {
        background: 'var(--green-01)',
        border: `7px solid var(--green-01-tinted)`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.DROPZONE: {
      return {
        background: 'var(--blue-02)',
        border: `7px solid var(--blue-02-tinted)`,
        backgroundClip: 'padding-box',
      };
    }
    case ICON_TYPES.TRANSPARENT: {
      return {
        backgroundColor: 'transparent',
        border: 'transparent',
      };
    }
    case ICON_TYPES.OBSERVATION: {
      return {
        backgroundColor: background,
        border: background,
      };
    }
    default:
      return {
        backgroundColor: 'var(--white)',
        border: 'var(--white)',
      };
  }
};

export const getIconContainerSizeByType = size => {
  switch (size) {
    case ICON_SIZES.EXTRA_SMALL:
      return '1em';
    case ICON_SIZES.SMALL_MINUS:
      return '1.5em';
    case ICON_SIZES.SMALL:
      return '2em';
    case ICON_SIZES.SMALL_PLUS:
      return '2.5em';
    case ICON_SIZES.MEDIUM_MINUS:
      return '3em';
    case ICON_SIZES.MEDIUM:
      return '5em';
    default:
      return '1em';
  }
};

export const IconContainer = styled.div`
  ${props => getColorsByType(props)};
  border-radius: 50%;
  float: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => getIconContainerSizeByType(props.size)};
  height: ${props => getIconContainerSizeByType(props.size)};
  margin-top: 0.5em;
  cursor: ${props => props.hasHover && 'pointer'};
`;
IconContainer.displayName = 'IconContainer';

export const getIconFontSizeByType = size => {
  switch (size) {
    case ICON_SIZES.EXTRA_SMALL:
      return '0.5em';
    case ICON_SIZES.SMALL_MINUS:
      return '0.75em';
    case ICON_SIZES.SMALL:
      return '1em';
    case ICON_SIZES.MEDIUM_MINUS:
      return '1.25em';
    case ICON_SIZES.MEDIUM:
      return '2.25em';
    default:
      return '1em';
  }
};

export const StyledIcon = styled(({ hasHover, stroke, strokeWidth, ...props }) => <Svg {...props} />)`
  font-size: ${props => getIconFontSizeByType(props.size)};
  fill: ${props => props.fill};
  margin: 0.4em 0;
  cursor: ${props => props.hasHover && 'pointer'};
  stroke: ${props => props.stroke};
  stroke-width: ${props => props.strokeWidth};
`;
StyledIcon.displayName = 'StyledIcon';

export const Icon = ({ name, fill, iconType, size, background, onClick, stroke, strokeWidth }) => {
  if (iconType) {
    return (
      <IconContainer iconType={iconType} size={size} onClick={onClick} hasHover={!!onClick} background={background}>
        <StyledIcon name={name} fill={fill} size={size} stroke={stroke} strokeWidth={strokeWidth} />
      </IconContainer>
    );
  }
  return <StyledIcon name={name} fill={fill} size={size} onClick={onClick} hasHover={!!onClick} />;
};

Icon.defaultProps = {
  name: flIcons.BE,
  fill: 'white',
  iconType: '',
  size: ICON_SIZES.MEDIUM,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
  iconType: PropTypes.string,
  size: PropTypes.oneOf(values(ICON_SIZES)),
  onClick: PropTypes.func,
  background: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default Icon;
