import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { useHistory } from 'react-router';

import QueryLink from 'components/QueryLink/QueryLink';
import Svg from 'components/Svg/Svg';
import NumberBadge from 'components/NumberBadge/NumberBadge';

const Tooltip = styled.div`
  position: fixed;
  display: ${props => props.display};
  left: ${props => (props.extended ? '150px' : '75px')};
  top: ${props => props.offsetTop + 'px'};
  background-color: var(--white);
  box-shadow: var(--box-shadow-03);
  border-radius: 2px;
  color: var(--black);
  padding: var(--size-xs);
  font-size: ${props => props.theme.fontSize.xxs};
  line-height: ${props => props.theme.fontSize.xs};
  max-width: 350px;
  white-space: pre-line;

  &::before {
    content: '';
    position: absolute;
    margin-left: -0.6em;
    z-index: 2;
    line-height: 0;
    border-left: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-top: 0.5em solid transparent;
    border-right: ${props => `0.5em solid ${rgba(props.theme.colors.black, 0.04)}`};
    left: -0.45em;
    top: calc(50% - 0.5em);
  }

  &::after {
    content: '';
    position: absolute;
    margin-left: -0.5em;
    z-index: 2;
    line-height: 0;
    border-left: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-top: 0.5em solid transparent;
    border-right: 0.5em solid var(--white);
    left: -0.45em;
    top: calc(50% - 0.5em);
  }
`;

const Bold = styled.span`
  font-weight: ${props => props.theme.font.weight.bold};
`;

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-xl-plus);
  min-width: var(--size-xl-plus);
  height: var(--size-xl-plus);
  border-radius: 50%;
  ${props =>
    props.selected &&
    !props.subItem &&
    css`
      background-color: var(--blue-02-008);
    `};
`;

const Label = styled.span`
  position: relative;
  font-size: ${props => (props.subItem ? props.theme.fontSize.xxxs : props.theme.fontSize.xxs)};

  ${props =>
    props.contextLink &&
    css`
      border-bottom: 1px solid var(--grey-08);
      margin-bottom: var(--size-md);
      margin-top: calc(-1 * var(--size-md));
    `}

  ${props => props.theme.media.landscape`
        font-size: ${props => (props.subItem ? props.theme.fontSize.xxs : props.theme.fontSize.xs)};
    `}

    &:hover > * > ${IconContainer} {
    ${props =>
      !props.disabled &&
      !props.subItem &&
      css`
        background-color: var(--blue-02-008);
      `};
  }
`;

const linkStyles = css`
  color: ${props => (props.disabled ? 'var(--grey-50)' : 'var(--black)')};
  font-weight: ${props => props.selected && props.theme.font.weight.bold};
  padding: var(--size-xxs) var(--size-md);
  cursor: ${props => (props.disabled || props.selected ? 'default' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`;

const Link = styled(QueryLink)`
  ${linkStyles}
`;
Link.displayName = 'Link';

const ExternalLink = styled.a`
  ${linkStyles}
`;
ExternalLink.displayName = 'ExternalLink';

const Icon = styled(({ small, subItem, disabled, ...props }) => <Svg {...props} />)`
  font-size: ${props => (props.subItem ? 'var(--size-sm)' : 'var(--size-lg)')};
  fill: ${props =>
    // TODO Start using functional color variables from colors.scss in the process of navigation renewal
    props.subItem ? 'var(--black)' : props.disabled ? 'var(--grey-50)' : 'var(--black)'};
`;

Icon.displayName = 'Icon';

const Text = styled.span`
  display: flex;
  align-items: center;
  opacity: ${props => (props.extended ? 1 : 0)};
  transition: opacity 0.3s;
  margin: 0 var(--size-sm);
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
  line-height: ${props => props.theme.lineHeight.text};
`;

Text.displayName = 'Text';

const Badge = styled(NumberBadge)`
  margin-left: var(--size-xs);
`;

const featureTeaserTexts = {
  'Energy Optimization':
    'allows users to view summed up Energy Rating and compare energy efficiency between ' +
    'buildings through benchmarking. Contact your Caverion contact person to activate the feature.',
  Documents:
    'provides users easy access to portfolio and building level documents. Users can manage folders ' +
    'and easily upload new documents. Contact your Caverion contact person to activate the feature.',
  Conditions:
    'shows a complete performance list of sensor and device groups attached to building or equipment. ' +
    'Users may set email alarms when defined thresholds are exceeded. Contact your Caverion contact person to ' +
    'activate the feature.',
  Energy:
    'view gives detailed information about building’s energy consumption and efficiency. Contact your ' +
    'Caverion contact person to activate the feature.',
  'Control Room':
    'consists of three views: Alarms, Observations and Inspections. Contact your Caverion contact ' +
    'person to activate the feature.',
  Building:
    'gives an overview of operational performance of the building. Contact your Caverion contact person ' +
    'to activate the feature.',
  Floors:
    'gives an easy-to-use display for sensor values per floor, area or room on top of a floor plan image. ' +
    'Contact your Caverion contact person to activate the feature.',
  Cleaning:
    'lets user monitor when a building, floor or area has been cleaned during the last 7 days. Contact ' +
    'your Caverion contact person to activate the feature.',
  Technical:
    'displays a complete architecture tree of a building and it’s disciplines and equipments. Contact ' +
    'your Caverion contact person to activate the feature.',
  Equipment:
    'shows all equipments attached to the current Location, Discipline or Equipment. Contact your ' +
    'Caverion contact person to activate the feature.',
  'External Documents':
    'lists all M-files documents attached to the current Location. Contact your Caverion ' +
    'contact person to activate the feature.',
  News:
    'is a platform for creating and managing news or announcements for a building. Contact your Caverion ' +
    'contact person to activate the feature.',
  Recycling:
    'view gives detailed information about recycling, recovery and carbon footprint rates and waste related observations.' +
    'Contact your Caverion contact person to activate the feature.',
  Sustainability: 'side-navigation-feature-teaser-sustainability',
  'Custom Charts': 'side-navigation-feature-teaser-custom-charts',
  'Building News': 'side-navigation-feature-teaser-building-news',
};

const tooltipInitialStyles = { display: 'none' };

const NaviItem = props => {
  const {
    t,
    id,
    title,
    icon,
    disabled,
    count,
    loading,
    to,
    extended,
    smallIcon,
    contextLink,
    teaser,
    external,
    pathname,
    location,
    items = [],
    subItem,
  } = props;

  const [toolTipStyles, setTooltipStyles] = useState(tooltipInitialStyles);
  let iconRef = null;
  const history = useHistory();

  /**
   * Gets icon bounding client rect
   */
  const getIconPosition = () => {
    if (iconRef) {
      const element = ReactDOM.findDOMNode(iconRef);
      if (element) {
        return element.getBoundingClientRect();
      }
    }
    return null;
  };

  /**
   * Sets infotip positioning styles to state
   */
  const setInfoTipPosition = bounds => {
    setTooltipStyles({
      top: bounds.top + (teaser ? -12 : 8),
      display: 'block',
    });
  };

  const handleInfoTipMouseOver = () => {
    const bounds = getIconPosition();
    if (bounds) {
      setInfoTipPosition(bounds);
    }
  };

  const handleClick = event => {
    if (disabled) {
      event.preventDefault();
    }
    if (to.back) {
      history.goBack();
    }
  };

  const pathArray = (location?.pathname || '').split('/');
  const linkArray = (pathname || to?.pathname || '').split('/');
  const selected =
    linkArray.length > 2
      ? pathArray[linkArray.length - 1] === linkArray[linkArray.length - 1]
      : pathArray[pathArray.length - 1] === linkArray[linkArray.length - 1];

  const LinkComponent = external || to.back ? ExternalLink : Link;
  const key = id ? id : title.toLowerCase();

  return (
    <Label
      data-test-id={`${title}${loading ? '-loading' : ''}${disabled ? '-disabled' : ''}`}
      key={`${key}-label`}
      selected={selected}
      disabled={disabled}
      extended={extended}
      contextLink={contextLink}
      subItem={subItem}
    >
      <LinkComponent
        selected={selected}
        disabled={disabled}
        to={to}
        href={external ? to.pathname : undefined}
        target={external ? '_blank' : undefined}
        rel={external ? 'noopener noreferrer' : undefined}
        onClick={handleClick}
        onMouseOver={() => handleInfoTipMouseOver()}
        onMouseOut={() => setTooltipStyles(tooltipInitialStyles)}
      >
        <IconContainer
          selected={selected}
          subItem={subItem}
          ref={el => {
            iconRef = el;
          }}
        >
          {icon && <Icon name={icon} selected={selected} disabled={disabled} small={smallIcon} subItem={subItem} />}
          {!extended && !teaser && (
            <Tooltip display={toolTipStyles.display} offsetTop={toolTipStyles.top}>
              {t(title)}
            </Tooltip>
          )}
          {teaser && (
            <Tooltip extended={extended} display={toolTipStyles.display} offsetTop={toolTipStyles.top}>
              <Bold>{t(title)} </Bold>
              {t(featureTeaserTexts[title])}
            </Tooltip>
          )}
        </IconContainer>
        <Text extended={extended}>
          {t(title)}
          <Badge number={count} />
        </Text>
      </LinkComponent>
      {selected &&
        items.map(item => (
          <NaviItem
            key={item.pathname}
            t={t}
            to={{ pathname: item.pathname }}
            extended={extended}
            location={location}
            smallIcon={true}
            subItem={true}
            {...item}
          />
        ))}
    </Label>
  );
};

NaviItem.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  to: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pathname: PropTypes.string,
  extended: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  count: PropTypes.number,
  loading: PropTypes.bool,
  smallIcon: PropTypes.bool,
  subItem: PropTypes.bool,
  contextLink: PropTypes.bool,
  teaser: PropTypes.bool,
  external: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
};

NaviItem.defaultProps = {
  disabled: false,
  loading: false,
  smallIcon: false,
  contextLink: false,
  teaser: false,
  external: false,
  subItem: false,
};

export default React.memo(NaviItem);
