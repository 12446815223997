import styled from 'styled-components';

const SectionTabs = styled.ul`
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: -2px;

  ${props => props.theme.media.landscape`
    display: flex;
  `}
`;
SectionTabs.displayName = 'SectionTabs';

export default SectionTabs;
