import { createReducerFromMapping } from 'redux/utils';
import MasterDataService from 'services/masterData';
import { LOAD_SUCCESS as LOAD_ORDER_SUCCESS } from './serviceOrders';
import { LOAD_SUCCESS as LOAD_PLANNED_SUCCESS } from './plannedMaintenance';
import { omitFormOnlyProperties } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/setters';
import { invalidateMostServiceOrderCaches } from 'services/masterData/serviceOrders';

const initialState = {
  loading: {},
  tokens: {},
  customerSuppliers: {},
  customerCategories: {},
};

export const LOAD_WITH_TOKEN = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_WITH_TOKEN';
export const LOAD_WITH_TOKEN_SUCCESS = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_WITH_TOKEN_SUCCESS';
export const LOAD_WITH_TOKEN_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_WITH_TOKEN_FAIL';

export const loadServiceOrderWithToken =
  (token, key, refreshCache = true) =>
  async dispatch => {
    dispatch({ type: LOAD_WITH_TOKEN, key });
    try {
      const result = await dispatch(MasterDataService.serviceOrderWithToken(token, refreshCache));
      return dispatch({
        type: LOAD_WITH_TOKEN_SUCCESS,
        key,
        result,
        token,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_WITH_TOKEN_FAIL,
        key,
        error,
        token,
      });
    }
  };

export const CANCEL_ASSIGNMENT_FOR_PLANNED = 'CUSTOMER_PLATFORM/ServiceModule/CANCEL_ASSIGNMENT_FOR_PLANNED';
export const CANCEL_ASSIGNMENT_FOR_PLANNED_SUCCESS =
  'CUSTOMER_PLATFORM/ServiceModule/CANCEL_ASSIGNMENT_FOR_PLANNED_SUCCESS';
export const CANCEL_ASSIGNMENT_FOR_PLANNED_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/CANCEL_ASSIGNMENT_FOR_PLANNED_FAIL';

export const cancelPlannedMaintenanceAssignment = plannedMaintenance => async dispatch => {
  try {
    const result = await MasterDataService.cancelAssignmentForPlannedMaintenance(plannedMaintenance.id);

    dispatch({
      type: LOAD_PLANNED_SUCCESS,
      keepPrevious: true,
      result: [result],
    });

    return dispatch({
      type: CANCEL_ASSIGNMENT_FOR_PLANNED_SUCCESS,
    });
  } catch (error) {
    return dispatch({
      type: CANCEL_ASSIGNMENT_FOR_PLANNED_FAIL,
      error,
    });
  }
};

export const UPSERT_SERVICE_ORDER = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_SERVICE_ORDER';
export const UPSERT_SERVICE_ORDER_SUCCESS = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_SERVICE_ORDER_SUCCESS';
export const UPSERT_SERVICE_ORDER_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_SERVICE_ORDER_FAIL';

export const upsertServiceOrder = (serviceOrder, partnerNumber) => async dispatch => {
  dispatch({ type: UPSERT_SERVICE_ORDER });
  try {
    const result = await MasterDataService.upsertServiceOrder({ ...serviceOrder, partnerNumber: [partnerNumber] });
    dispatch({
      type: LOAD_ORDER_SUCCESS,
      loadOne: true,
      result: [result],
    });
    return dispatch({
      type: UPSERT_SERVICE_ORDER_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: UPSERT_SERVICE_ORDER_FAIL,
      error,
    });
  }
};

export const UPSERT_PLANNED_MAINTENANCE = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_PLANNED_MAINTENANCE';
export const UPSERT_PLANNED_MAINTENANCE_SUCCESS = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_PLANNED_MAINTENANCE_SUCCESS';
export const UPSERT_PLANNED_MAINTENANCE_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/UPSERT_PLANNED_MAINTENANCE_FAIL';

export const upsertPlannedMaintenance = maintenance => async dispatch => {
  dispatch({ type: UPSERT_PLANNED_MAINTENANCE });
  try {
    const result = await MasterDataService.upsertPlannedMaintenance(maintenance);
    dispatch({
      type: LOAD_PLANNED_SUCCESS,
      keepPrevious: true,
      result: [result],
    });
    return dispatch({
      type: UPSERT_PLANNED_MAINTENANCE_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: UPSERT_PLANNED_MAINTENANCE_FAIL,
      error,
    });
  }
};

export const deleteServiceOrder = serviceOrder => async dispatch => {
  try {
    const result = await MasterDataService.updateServiceOrderStatus({
      serviceOrderNumber: serviceOrder.serviceOrderNumber,
      status: 'Cancelled',
      resend: true,
    });
    await invalidateMostServiceOrderCaches();
    dispatch({
      type: LOAD_ORDER_SUCCESS,
      loadOne: true,
      result: [result],
    });
    return false;
  } catch (error) {
    return error;
  }
};

export const deletePlannedMaintenance = plannedMaintenance => async (dispatch, getState) => {
  try {
    const rawPlannedMaintenance = getState().plannedMaintenance.index.byId[plannedMaintenance.id];
    if (rawPlannedMaintenance && !rawPlannedMaintenance.serviceOrderNumber) {
      const result = await MasterDataService.upsertPlannedMaintenance({
        ...omitFormOnlyProperties(rawPlannedMaintenance),
        status: 'Cancelled',
      });
      await MasterDataService.invalidateAllPlannedMaintenanceCaches();
      dispatch({
        type: LOAD_PLANNED_SUCCESS,
        keepPrevious: true,
        result: [result],
      });
    }
    return false;
  } catch (error) {
    return error;
  }
};

export const SEARCH_CUSTOMER_SUPPLIERS = 'CUSTOMER_PLATFORM/ServiceModule/SEARCH_CUSTOMER_SUPPLIERS';
export const SEARCH_CUSTOMER_SUPPLIERS_SUCCESS = 'CUSTOMER_PLATFORM/ServiceModule/SEARCH_CUSTOMER_SUPPLIERS_SUCCESS';
export const SEARCH_CUSTOMER_SUPPLIERS_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/SEARCH_CUSTOMER_SUPPLIERS_FAIL';

export const searchCustomerSuppliers = (partnerNumber, searchTerm) => async dispatch => {
  dispatch({ type: SEARCH_CUSTOMER_SUPPLIERS });
  try {
    const result = await MasterDataService.searchCustomerSuppliers(partnerNumber, searchTerm);
    return dispatch({
      type: SEARCH_CUSTOMER_SUPPLIERS_SUCCESS,
      result,
      partnerNumber,
    });
  } catch (error) {
    return dispatch({
      type: SEARCH_CUSTOMER_SUPPLIERS_FAIL,
      error,
      partnerNumber,
    });
  }
};

export const LOAD_CUSTOMER_CATEGORIES = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_CUSTOMER_CATEGORIES';
export const LOAD_CUSTOMER_CATEGORIES_SUCCESS = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_CUSTOMER_CATEGORIES_SUCCESS';
export const LOAD_CUSTOMER_CATEGORIES_FAIL = 'CUSTOMER_PLATFORM/ServiceModule/LOAD_CUSTOMER_CATEGORIES_FAIL';

export const loadCustomerCategories =
  (partnerNumber, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD_CUSTOMER_CATEGORIES });
    try {
      const result = await MasterDataService.customerCategories(partnerNumber, refreshCache);
      return dispatch({
        type: LOAD_CUSTOMER_CATEGORIES_SUCCESS,
        result,
        partnerNumber,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_CUSTOMER_CATEGORIES_FAIL,
        error,
        partnerNumber,
      });
    }
  };

export default createReducerFromMapping(
  {
    [LOAD_WITH_TOKEN]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_WITH_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      tokens: {
        ...state.tokens,
        [action.token]: action.result,
      },
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),
    [LOAD_WITH_TOKEN_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
      tokens: {
        ...state.tokens,
        [action.token]: { error: true },
      },
    }),

    [SEARCH_CUSTOMER_SUPPLIERS]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [SEARCH_CUSTOMER_SUPPLIERS_SUCCESS]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
      customerSuppliers: {
        ...state.customerSuppliers,
        [action.partnerNumber]: action.result,
      },
    }),
    [SEARCH_CUSTOMER_SUPPLIERS_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
      customerSuppliers: {
        ...state.tokens,
        [action.partnerNumber]: [],
      },
    }),

    [LOAD_CUSTOMER_CATEGORIES]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_CUSTOMER_CATEGORIES_SUCCESS]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
      customerCategories: {
        ...state.customerCategories,
        [action.partnerNumber]: action.result,
      },
    }),
    [LOAD_CUSTOMER_CATEGORIES_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
      customerCategories: {
        ...state.tokens,
        [action.partnerNumber]: [],
      },
    }),
  },
  initialState
);
