export const inspectionsForFL = (request, query) =>
  request.get({
    apiPath: `/api/inspections`,
    query,
  });

export const inspectionsForPartner = (request, query) =>
  request.get({
    apiPath: `/api/inspections`,
    query,
  });

export const inspectionCountsByMonth = (request, query) =>
  request.get({
    apiPath: `/api/inspections/stats/monthly`,
    query,
  });
