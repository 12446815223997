import React from 'react';
import isEmpty from 'lodash/isEmpty';

import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputText from 'components/Form/InputText';
import InputForm from 'components/Form/InputForm';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import PrimaryButton from 'components/Button/PrimaryButton';
import Loader from 'components/Loader/Loader';

import { FormContainer, InputRowButton } from './SensorMetaDataMapping';

const isParentIdSet = model => model.parentId && !isEmpty(model.parentId);

const isBuildingTypeSelected = model => model.type && model.type === 'building';

export const SensorHierarchyForm = ({ model, t, handleSubmit, handleOnChange, hierarchyTypeOptions, loading }) => (
  <FormContainer>
    <h3>Sensor Hierarchy</h3>
    <InputForm model={model} onPropertyChange={handleOnChange} onSubmit={handleSubmit}>
      <InputRow required={!isParentIdSet(model)}>
        <InputLabel text="Functional Location" />
        <InputText id="functionalLocation" type="text" property="functionalLocation" />
      </InputRow>
      <InputRow>
        <InputLabel text="Partner Number" />
        <InputText id="partnerNumber" type="text" property="partnerNumber" />
      </InputRow>
      <InputRow>
        <InputLabel text="Name" />
        <InputText id="name" type="text" property="name" />
      </InputRow>
      <InputRow required>
        <InputLabel text="type" />
        <InputSelectDropdown
          options={hierarchyTypeOptions}
          property={'type'}
          model={model}
          onChange={handleOnChange}
          t={t}
          clearable={false}
        />
      </InputRow>
      <InputRow required={!isBuildingTypeSelected(model)}>
        <InputLabel text="Parent Id" />
        <InputText id="parentId" type="text" property="parentId" />
      </InputRow>
      <InputRowButton>
        <PrimaryButton confirm large type="submit">
          {loading ? <Loader color="WHITE" size="SMALL" /> : 'Save'}
        </PrimaryButton>
      </InputRowButton>
    </InputForm>
  </FormContainer>
);
