import React from 'react';
import PropTypes from 'prop-types';
import DefaultCustomChartChart from 'components/Charts/DefaultCustomChart';
import DateTools, { DATE_RANGES } from 'components/Form/DateTools';
import SensorAnnotations from 'components/Sensor/SensorAnnotations/SensorAnnotations';
import CustomChartKeyValues from '../../CustomChartKeyValues/CustomChartKeyValues';
import { Footer, ChartContainer, ResetZoomButton } from '../common';

import { useTranslations } from 'decorators/Translations/translations';
import useCustomChart from '../useCustomChart';
import useChartAnnotations from 'components/Sensor/SensorAnnotations/useChartAnnotations';
import useDefaultCustomChart from './useDefaultCustomChart';
import { CustomChart as CustomChartPropType } from '../propTypes';
import isEmpty from 'lodash/isEmpty';
import { AnnotationInfo } from '../DoubleClickAnnotationInfo';
import useChartDoubleClick from 'components/Charts/useChartDoubleClick';
import customChartGranularity from './customChartGranularity';
import useAnnotations from 'components/Sensor/SensorAnnotations/useAnnotations';
import useEvents from './useEvents';
import useUpdateAnnotations from './useUpdateAnnotations';

const EMPTY_OBJECT = {};

const DefaultCustomChart = ({ chart = EMPTY_OBJECT, functionalLocationId, preview }) => {
  const [t] = useTranslations();
  const containerRef = React.useRef();
  const chartRef = React.useRef();
  const {
    valuesBySensorId,
    onSelect,
    onResetZoom,
    parameters,
    handleParameterChange,
    defaultRange,
    loadingSensorValues,
    reference,
    useDateTools,
    dynamicValuesBySensorId,
    handleDynamicDataLoading,
  } = useCustomChart({ chart, functionalLocationId });

  const { sensorData, chartOptions, sort } = useDefaultCustomChart({
    chart,
    functionalLocationId,
    parameters,
    valuesBySensorId,
  });

  const { sensorData: dynamicSensorData } = useDefaultCustomChart({
    chart,
    functionalLocationId,
    parameters,
    valuesBySensorId: dynamicValuesBySensorId,
  });

  const { annotations, annotationsLoaded } = useAnnotations({
    functionalLocation: functionalLocationId,
    chart: preview ? null : chart,
    startDate: parameters.startDatetime,
    endDate: parameters.endDatetime,
  });

  const { onClickChart, onCloseAnnotations, annotationPopover, createAnnotationAt, getAnnotationConfigs } =
    useChartAnnotations(
      preview ? null : chart,
      customChartGranularity(parameters.startDatetime, parameters.endDatetime),
      annotations
    );

  useUpdateAnnotations(chartRef.current?.chart, getAnnotationConfigs);
  const { setExtremes, onLoad } = useEvents(handleDynamicDataLoading, getAnnotationConfigs);

  const { onClick } = useChartDoubleClick(onClickChart, null);

  if (isEmpty(chart)) {
    return null;
  }

  return (
    <>
      <ChartContainer ref={containerRef}>
        <DefaultCustomChartChart
          chartRef={chartRef}
          sensorData={sensorData}
          onSelect={onSelect}
          options={chartOptions}
          loading={loadingSensorValues || (!annotationsLoaded && !preview)}
          aggregationFreq={chart.aggregationFreq}
          verticalZoom={chart.verticalZoom}
          sort={sort}
          onClick={onClick}
          onPointClick={onClick}
          setExtremes={setExtremes}
          onLoad={onLoad}
          isPreview={preview}
          noNavigator={useDateTools}
          noNavigatorSeries={!parameters.isDailyDataAvailable}
          useBoostModule={!parameters.isDailyDataAvailable}
        />
        {parameters.isZoomed && <ResetZoomButton onClick={onResetZoom}>{t('Reset zoom')}</ResetZoomButton>}
        {!preview && <AnnotationInfo />}
      </ChartContainer>
      <>
        {useDateTools && parameters.startDatetime && parameters.endDatetime && (
          <Footer>
            <DateTools
              t={t}
              model={parameters}
              onChange={handleParameterChange}
              defaultRange={defaultRange ?? DATE_RANGES.DAYS_7}
              showArrows
              updateFromModel
              aggregationFreq={chart.aggregationFreq}
            />
          </Footer>
        )}

        {!preview && (
          <SensorAnnotations
            annotationPopover={annotationPopover}
            createAnnotationAt={createAnnotationAt}
            chart={chart}
            functionalLocationKey={functionalLocationId}
            onClose={onCloseAnnotations}
            containerRef={containerRef}
          />
        )}
      </>
      <CustomChartKeyValues
        chart={chart}
        sensorData={!isEmpty(dynamicValuesBySensorId) ? dynamicSensorData : sensorData}
        reference={reference}
        loading={loadingSensorValues}
      />
    </>
  );
};

DefaultCustomChart.propTypes = {
  chart: CustomChartPropType.isRequired,
  functionalLocationId: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

export default DefaultCustomChart;
