import ApiRequest from 'utils/Fetch/ApiRequest';
import { MASTER_DATA_PATH } from './index';

export const serviceOrderLogs = (serviceOrderNumber, refreshCache) =>
  ApiRequest.get({
    apiPath: `${MASTER_DATA_PATH}/serviceOrderLogs/`,
    query: { serviceOrderNumber },
    refreshCache,
  });

export const createServiceOrderLogComment = comment =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_PATH}/serviceOrderLogs/`,
    data: comment,
  });

export const createServiceOrderLogCommentWithToken = (token, comment) =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_PATH}/serviceOrderLogs/withToken`,
    query: { token },
    data: comment,
    useAuthorization: false,
  });

export const editServiceOrderLogComment = (serviceOrderLogId, comment) =>
  ApiRequest.post({
    apiPath: `${MASTER_DATA_PATH}/serviceOrderLogs/${serviceOrderLogId}/editComment`,
    data: { comment },
  });
