import React from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Section/Section';
import ScrollToComponent from 'components/ScrollToComponent/ScrollToComponent';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import ButtonDropdown from 'components/Button/ButtonDropdown';
import ExportData from 'components/ExportData/ExportData';
import SummaryTab from 'containers/Application/Recycling/RecyclingBenchmark/SummaryTab/SummaryTab';
import BenchmarkTab from 'containers/Application/Recycling/RecyclingBenchmark/BenchmarkTab/BenchmarkTab';
import ObservationsTab from 'containers/Application/Recycling/RecyclingBenchmark/ObservationsTab/ObservationsTab';
import SnackBar from 'components/SnackBar/SnackBar';
import PrimaryButton from 'components/Button/PrimaryButton';
import withQuery from 'decorators/Query/withQuery';
import translations from 'decorators/Translations/translations';
import benchmarkTabs from 'containers/Application/Recycling/tabs';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { onClickPrint } from 'utils/Print/print';
import { DATE_RANGES } from 'components/Form/DateTools';
import { GRANULARITY } from 'components/ExportData/ExportSensorDataForm';

export const RECYCLING_BENCHMARK_ELEMENT_ID = 'recycling-benchmark';
export const isWasteSensor = sensor => /^waste\//.test(sensor.sensorType?.name);

export const ButtonDropdownWrapper = styled.div`
  ${props => props.theme.media.portrait`
    margin-top: -1em;
    margin-left: 1em;
    margin-bottom: 0;
  `}
  margin-top: 1em;
  margin-bottom: -1em;
`;

export const RecyclingBenchmark = ({ t, containerLoading, query, setQuery, features }) => {
  const [showExportModal, setShowExportModal] = React.useState(false);
  const [notification, setNotification] = React.useState({
    visible: false,
  });
  const selectedTab = query?.benchmark || 'summary';
  const tabOptions = React.useMemo(
    () =>
      [
        {
          value: 'summary',
          label: t('Summary'),
        },
        ...Object.keys(benchmarkTabs).map(key => ({
          value: benchmarkTabs[key].id,
          label: t(benchmarkTabs[key].label),
        })),
        features?.controlRoomObservations && {
          value: 'observations',
          label: t('Observations'),
        },
      ].filter(Boolean),
    [benchmarkTabs]
  );
  const showNotification = (message, type) => {
    setNotification({
      visible: true,
      message,
      type,
      showCloseButton: true,
    });
  };
  const hideNotification = () => {
    setNotification({
      visible: false,
    });
  };
  return (
    <Section>
      <ScrollToComponent id={RECYCLING_BENCHMARK_ELEMENT_ID} />
      <SectionHeader>
        <SectionTabSelector
          options={tabOptions}
          model={{ selectedTab }}
          property="selectedTab"
          onTabChange={(property, value) =>
            setQuery({
              benchmark: value,
              scrollTo: RECYCLING_BENCHMARK_ELEMENT_ID,
            })
          }
          noMobilePadding
        />
        <ButtonDropdownWrapper>
          <ButtonDropdown
            secondary
            buttonLabel={t('Export')}
            buttonProps={{ iconName: 'export' }}
            alignment="right"
            items={[
              { label: t('CSV'), onClick: () => setShowExportModal(true) },
              { label: t('PDF'), onClick: onClickPrint },
            ]}
          />
        </ButtonDropdownWrapper>
      </SectionHeader>
      <SummaryTab containerLoading={containerLoading} visible={selectedTab === 'summary'} />
      {Object.keys(benchmarkTabs).map(key => (
        <BenchmarkTab
          key={key}
          containerLoading={containerLoading}
          tab={benchmarkTabs[key]}
          visible={selectedTab === benchmarkTabs[key].id}
        />
      ))}
      <ObservationsTab containerLoading={containerLoading} visible={selectedTab === 'observations'} />
      {showExportModal && (
        <ExportData
          onClose={() => setShowExportModal(false)}
          onSuccess={message => showNotification(message, 'success')}
          onError={message => showNotification(message, 'error')}
          sensorFilterFn={isWasteSensor}
          defaultDateRange={DATE_RANGES.DAYS_365}
          defaultGranularity={GRANULARITY.monthly}
          showDateRangeAllOption={true}
        />
      )}
      <SnackBar
        variant={notification.type}
        visible={notification.visible}
        secondaryContent={
          notification.showCloseButton && (
            <PrimaryButton
              confirm={notification.type === 'success'}
              warning={notification.type === 'error'}
              invertColor
              transparentBorder
              large
              onClick={hideNotification}
            >
              {t('Close')}
            </PrimaryButton>
          )
        }
      >
        {notification.message}
      </SnackBar>
    </Section>
  );
};

RecyclingBenchmark.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  containerLoading: PropTypes.bool,
  query: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
  features: PropTypes.object,
};

export default withQuery(withTheme(translations(RecyclingBenchmark)));
