import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { parseISO } from 'date-fns';
import startsWith from 'lodash/startsWith';

import Svg from 'components/Svg/Svg';
import { getHumanreadableSize } from 'utils/Data/documents';
import { icons } from 'components/Icon/IconNames';
import SkeletonText from 'components/Skeletons/SkeletonText';
import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';

const Container = styled.div`
  @media print {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: var(--size-xxs);
    grid-column-gap: var(--size-xs);
  }
`;

const Row = styled.div`
  display: flex;
  padding: ${props => (props.simple ? `var(--size-xxs) 0` : 'var(--size-xs)')};
  background-color: var(--table-row-bg-hl);
  margin: 1px 0;
  &:not(:first-child) {
    border-top: none;
  }
  align-items: center;
`;

const FileInfoRow = styled.div``;

const FileDateRow = styled.div`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.xxs};
`;

const Grow = styled.div`
  flex-grow: 1;
`;

const Name = styled.span`
  color: ${props => (props.onClick ? 'var(--link-fg)' : 'var(--body-text-fg)')};
  font-size: ${props => props.theme.font.size.xs};
  word-break: break-word;
  cursor: ${props => props.onClick && 'pointer'};
`;

const Size = styled.span`
  color: var(--supplemental-text-fg);
  font-size: ${props => props.theme.font.size.xxs};
`;

const IconContainer = styled.div`
  padding-left: var(--size-xs);
`;

const DownloadIcon = styled(Svg)`
  fill: var(--download-icon-fg);
  font-size: ${props => props.theme.font.size.xxs};
  cursor: pointer;
`;

const TrashIcon = styled(DownloadIcon)`
  fill: var(--danger-color);
`;

const thumbnailStyles = css`
  width: 56px;
  height: 56px;
  box-sizing: content-box;
  margin-right: var(--size-xs);
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  border: var(--border-thin) solid var(--thumbnail-bc);
  border-radius: 2px;
`;
const Thumbnail = styled.img`
  ${thumbnailStyles}
`;

const ThumbnailPlaceholder = styled.span`
  ${thumbnailStyles}

  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.font.size.md};
  color: var(--thumbnail-fg);
  fill: var(--thumbnail-fg);
  background-color: var(--thumbnail-bg);
  display: inline-flex;
`;

const ThumbnailAsBackground = styled.div`
  ${thumbnailStyles}

  background-image: ${props => `url(data:${props.type};base64,${props.content})`};
  background-size: cover;
  background-position: center center;
`;

const FileList = ({
  files = [],
  simple = false,
  showThumbnails = false,
  showPreview = false,
  loading = false,
  onDownload,
  onDelete,
  getDeleteFunctionForFile,
}) => {
  const hasDownload = typeof onDownload === 'function';
  const hasDelete = typeof onDelete === 'function';
  const hasFileSpecificDelete = typeof getDeleteFunctionForFile === 'function';
  if (loading) {
    return <SkeletonText style={{ marginLeft: 0 }} />;
  }
  return (
    <Container>
      {files.map(file => {
        let fileDeleteFn;
        if (hasFileSpecificDelete) {
          fileDeleteFn = getDeleteFunctionForFile(file);
        } else if (hasDelete) {
          fileDeleteFn = onDelete;
        }
        let thumbnail;
        if (showThumbnails) {
          if (file.thumbnailUrl) {
            thumbnail = (
              <Thumbnail src={file.thumbnailUrl} onClick={hasDownload ? () => onDownload(file, true) : undefined} />
            );
          } else if (showPreview && file.mimeType && startsWith(file.mimeType, 'image/')) {
            thumbnail = (
              <ThumbnailAsBackground
                content={file.file}
                type={file.mimeType}
                onClick={hasDownload ? () => onDownload(file, true) : undefined}
              />
            );
          } else {
            thumbnail = (
              <ThumbnailPlaceholder>
                <Svg name={icons.ATTACHMENT} />
              </ThumbnailPlaceholder>
            );
          }
        }
        return (
          <Row key={file.id} simple={simple}>
            {thumbnail}
            <Grow>
              <FileInfoRow>
                <Name onClick={hasDownload ? () => onDownload(file, true) : undefined}>
                  {file.name || file.filename}
                </Name>
                &nbsp;&nbsp;
                <Size>({getHumanreadableSize(file.size)})</Size>
              </FileInfoRow>
              <FileDateRow>{format(parseISO(file.modified), DATE_FORMATS.dateTimeLong)}</FileDateRow>
            </Grow>
            {hasDownload && (
              <IconContainer onClick={() => onDownload(file, false)}>
                <DownloadIcon name="download" />
              </IconContainer>
            )}
            {fileDeleteFn && (
              <IconContainer onClick={() => fileDeleteFn(file)}>
                <TrashIcon name="trash-can" />
              </IconContainer>
            )}
          </Row>
        );
      })}
    </Container>
  );
};

FileList.propTypes = {
  files: PropTypes.array,
  simple: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  showPreview: PropTypes.bool,
  loading: PropTypes.bool,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  getDeleteFunctionForFile: PropTypes.func,
};

export default FileList;
