import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledErrorDisplay = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorDisplay = ({ message }) => <StyledErrorDisplay>{message}</StyledErrorDisplay>;

ErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};

export const ErrorBoundaryDisplay = ({ error }) => <StyledErrorDisplay>{error.message}</StyledErrorDisplay>;

ErrorBoundaryDisplay.propTypes = {
  error: PropTypes.object.isRequired,
};
