import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  getPartnerNumber,
  getPartnerSelected,
  getPartnerMeta,
  getBuildingMeta,
  getSensorHierarchy,
  hasAccessRightsSelector,
  wasteSensorsSelector,
  createObservationsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import { loadBuildingLevelSensorHierarchiesByFLIds } from 'redux/modules/customer/sensorHierarchy';
import { loadFunctionalLocationsForOverview, loadPartnerMeta, loadBuildingMeta } from 'redux/modules';
import { loadRecyclingObservations } from 'redux/modules/containers/recycling';

const EMPTY_ARRAY = [];

const getCustomers = state => state.customer.customers;
const getObservationsForPartner = (state, props) =>
  state.profile?.profile?.features?.controlRoomObservations
    ? state.notice.observationsByPartner[props.match?.params?.partnerNumber]?.recycling ?? EMPTY_ARRAY
    : EMPTY_ARRAY;

const loadingSelector = createSelector(
  getPartnerNumber,
  getPartnerMeta,
  getBuildingMeta,
  getSensorHierarchy,
  (partnerNumber, partnerMeta, buildingMeta, sensorHierarchy) =>
    partnerMeta[partnerNumber]?.loading || buildingMeta?.loading || sensorHierarchy?.loading
);

const noDataAvailableSelector = createSelector(
  wasteSensorsSelector,
  loadingSelector,
  (wasteSensors, loading) => isEmpty(wasteSensors) && !loading
);

const observationsSelector = createObservationsSelector(getObservationsForPartner);

export const mapStateToProps = (state, props) => ({
  partnerSelected: getPartnerSelected(state, props),
  partnerNumber: getPartnerNumber(state, props),
  hasAccessRights: hasAccessRightsSelector(state, props),
  customers: getCustomers(state),
  noDataAvailable: noDataAvailableSelector(state, props),
  observations: observationsSelector(state, props),
});

const mapDispatchToProps = dispatch => ({
  loadData: async partnerNumber => {
    await dispatch(loadPartnerMeta(partnerNumber));
    const overviewResponse = await dispatch(loadFunctionalLocationsForOverview(partnerNumber));
    const functionalLocationIds = overviewResponse.result.map(entry => entry.functionalLocation);
    await dispatch(loadBuildingMeta(functionalLocationIds));
    await dispatch(loadBuildingLevelSensorHierarchiesByFLIds(functionalLocationIds));
    await dispatch(loadRecyclingObservations(partnerNumber));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
