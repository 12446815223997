import React from 'react';

import { mapSensorData, getOpenHourBands } from '../../utils';
import useBuildingOpenHours from '../../useBuildingOpenHours';
import useBuildingSensors from '../../useBuildingSensors';
import { HIGH_GRANULARITY_MAX_RANGE } from '../constants';
import { TIMEFRAME_PRESETS } from '../../CustomChartConfigModal/CustomChartSettings/constants';
import { startOfUTCYear } from 'utils/Date/date';
import parseISO from 'date-fns/parseISO';
import endOfToday from 'date-fns/endOfToday';

const useDefaultCustomChart = ({ chart = {}, functionalLocationId, valuesBySensorId, parameters }) => {
  const buildingSensors = useBuildingSensors(functionalLocationId);
  const [openHours, buildingTimezone] = useBuildingOpenHours(functionalLocationId);

  const sensorData = React.useMemo(
    () =>
      mapSensorData({
        chart,
        valuesBySensorId,
        buildingSensors,
        openHours,
      }),
    [valuesBySensorId, chart, buildingSensors, openHours]
  );

  const showOpenHourBands = chart.openHours?.length > 0;

  // Set max range when we have data with high granularity
  const maxRange = parameters => (parameters.isHighGranularity ? HIGH_GRANULARITY_MAX_RANGE : undefined);

  // Range from the end of chart
  const range = (parameters, series) => {
    let range;
    if (series?.[0]?.timeframeStartDiffMs) {
      if (
        series[0].timeframeStartDiffMs === TIMEFRAME_PRESETS.ALL_DATA ||
        series[0].timeframeStartDiffMs === TIMEFRAME_PRESETS.AUTOMATIC
      ) {
        range = undefined;
      } else if (series[0].timeframeStartDiffMs === TIMEFRAME_PRESETS.YEAR_TO_DATE) {
        range = endOfToday().getTime() - startOfUTCYear(new Date()).getTime();
      } else {
        range = -series[0].timeframeStartDiffMs;
      }
    } else if (parameters.isHighGranularity) {
      range = HIGH_GRANULARITY_MAX_RANGE;
    }
    if (parameters.isHighGranularity && (range > HIGH_GRANULARITY_MAX_RANGE || range == null)) {
      return HIGH_GRANULARITY_MAX_RANGE;
    }
    return range;
  };

  // Custom timeframe range
  const customRange = series => {
    return series?.[0]?.timeframeStart != null || series?.[0]?.timeframeEnd != null
      ? {
          min: parseISO(series[0].timeframeStart).getTime(),
          max: parseISO(series[0].timeframeEnd).getTime(),
        }
      : undefined;
  };

  const chartOptions = React.useMemo(() => {
    return {
      graphType: chart.series[0]?.graphType,
      openHourBands: showOpenHourBands ? getOpenHourBands(openHours, parameters) : [],
      buildingTimezone,
      title: chart.title,
      flipAxes: chart.flipAxes,
      chartColors: chart.series?.map(serie => serie.color),
      xMin: parameters.startDatetime?.getTime(),
      xMax: parameters.endDatetime?.getTime() + 1,
      xMaxRange: maxRange(parameters, chart.series),
      xRange: range(parameters, chart.series),
      xCustomRange: customRange(chart.series),
    };
  }, [showOpenHourBands, openHours, parameters, buildingTimezone, chart.title, chart.series, chart.flipAxes]);

  const sort = React.useMemo(
    () =>
      chart.series
        .filter(seriesObj => seriesObj.sort)
        .map(seriesObj => ({ sensorId: seriesObj.sensorIds[0], sort: seriesObj.sort })),
    [chart.series]
  );

  return {
    sensorData,
    chartOptions,
    sort,
  };
};

export default useDefaultCustomChart;
