// extracted by mini-css-extract-plugin
export var addressCell = "sv__SharedColumns-module__address-cell";
export var checkboxContainer = "sv__SharedColumns-module__checkbox-container";
export var chevronContainer = "sv__SharedColumns-module__chevron-container";
export var dataHeaderCell = "sv__SharedColumns-module__data-header-cell";
export var dateCell = "sv__SharedColumns-module__date-cell";
export var innerCell = "sv__SharedColumns-module__inner-cell";
export var negative = "sv__SharedColumns-module__negative";
export var numericCell = "sv__SharedColumns-module__numeric-cell";
export var operationsCell = "sv__SharedColumns-module__operations-cell";
export var positive = "sv__SharedColumns-module__positive";
export var relativeChangeTagCell = "sv__SharedColumns-module__relative-change-tag-cell";
export var skeletonCell = "sv__SharedColumns-module__skeleton-cell";
export var svgCell = "sv__SharedColumns-module__svg-cell";
export var toggleContainer = "sv__SharedColumns-module__toggle-container";