import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import ServiceOrderHeader from 'containers/Application/ServiceOrder/ServiceOrderHeader/ServiceOrderHeader';
import SpinnerOverlay from './SpinnerOverlay/SpinnerOverlay';
import Content from 'components/Content/Content';

const Section = styled.section`
  position: relative;
  h4 {
    :not(:first-child) {
      margin-top: var(--size-xl);
    }
  }
`;
Section.displayName = 'Section';

export const ServiceModuleForm = ({ t, saving, headerText, headerButtons, children, showHeader = true }) => (
  <Section>
    {saving && <SpinnerOverlay>{t('Saving')}...</SpinnerOverlay>}
    <Content>
      {showHeader && <ServiceOrderHeader hideOnMobile title={headerText} buttons={headerButtons} />}
      {children}
    </Content>
  </Section>
);

ServiceModuleForm.propTypes = {
  t: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  headerButtons: PropTypes.node,
  saving: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool,
};

export default translations(ServiceModuleForm);
