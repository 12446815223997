import styled from 'styled-components';

const SuccessPageButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: var(--size-xs);
  bottom: var(--size-lg);
  left: var(--size-xs);

  ${props => props.theme.media.landscape`
    justify-content: center;
    bottom: 100px;
  `}
`;
SuccessPageButtons.displayName = 'SuccessPageButtons';

export default SuccessPageButtons;
