import styled, { css } from 'styled-components';
import { opiCardType } from './OPICards';

export const Card = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--size-xs);
  align-items: center;
  justify-content: center;

  background-color: ${props => (props.isLoading ? 'var(--card-bg-loading)' : 'var(--card-bg)')};
  text-align: center;
  min-width: ${props => (props.type === opiCardType.COMPACT ? '150px' : '170px')};
  padding: var(--size-md) var(--size-xs);
  border: ${props => (props.type === opiCardType.DEFAULT ? 'var(--card-border)' : '0')};
  ${props => (props.type === opiCardType.COMPACT ? 'outline: var(--card-border);' : '')}

  &:not(:first-child) {
    border-left: 0;
  }

  ${props => props.theme.media.tablet`
    width: 100%;
    padding: ${props => (props.type === opiCardType.COMPACT ? 'var(--size-lg)' : 'var(--size-md)')};
    min-width: ${props => (props.type === opiCardType.COMPACT ? '150px' : '200px')};
    ${props => (props.type === opiCardType.DEFAULT ? 'box-shadow: var(--card-shadow);' : '')}
    border-radius: ${props => props.theme.section.borderRadius};
    border: 0;
  `};

  ${props =>
    props.hasClick &&
    css`
      cursor: pointer;

      ${props => props.theme.media.landscape`
            &:hover {
                transform: translateY(calc(-1 * var(--size-xxs)));
                box-shadow: var(--card-shadow);
            }
        `}
    `};
  transition: ${props => props.theme.motion.easeIn} ${props => props.theme.motion.quick};
`;
Card.displayName = 'Card';
