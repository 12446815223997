import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import { connect } from 'react-redux';
import * as JsSearch from 'js-search';
import styled from 'styled-components';
import withQuery from 'decorators/Query/withQuery';

import { loadPartnerOverview } from 'redux/modules/containers/partner-overview';

import SectionHeader from 'components/Section/SectionHeader';
import FunctionalLocationTable from 'components/FunctionalLocationTable/FunctionalLocationTable';
import Section from 'components/Section/Section';
import { InputSearch } from 'components/index';

const InputSearchContainer = styled.div`
  width: 100%;
  ${props => props.theme.media.landscape`
        width: auto;
        min-width: 18rem;
    `}

  & > div {
    width: 100%;
  }
`;

const mapFuntionalLocationData = functionalLocation => ({
  functionalLocation: functionalLocation.functionalLocation,
  description: functionalLocation.description,
  name: functionalLocation.name,
  key: functionalLocation.key,
  type: functionalLocation.type,
  addressInfo: `${functionalLocation.address || ''} ${functionalLocation.city || ''}`,
  tab: 'RescuePlans',
});

const initSearch = () => {
  const flSearch = new JsSearch.Search('functionalLocation');
  flSearch.addIndex('functionalLocation');
  flSearch.addIndex('name');
  flSearch.addIndex('description');
  flSearch.addIndex('addressInfo');
  return flSearch;
};

const RescuePlanTable = props => {
  const {
    match: {
      params: { partnerNumber },
    },
    t,
    loadPartnerOverview,
    loading,
    topLevelFunctionalLocations,
    setQuery,
    rescueFunctionalLocations,
  } = props;

  const [searchResults, setSearchResults] = useState(null);
  const flSearch = React.useRef(null);

  useEffect(() => {
    loadPartnerOverview(partnerNumber);
    flSearch.current = initSearch();
  }, [partnerNumber]);

  const functionalLocationData = topLevelFunctionalLocations.reduce((result, current) => {
    if (rescueFunctionalLocations[current.functionalLocation]) {
      return result.concat(mapFuntionalLocationData(current));
    }
    return result;
  }, []);

  if (flSearch.current) {
    flSearch.current.addDocuments(functionalLocationData);
  }

  return (
    <Section>
      <SectionHeader noBorder size="small">
        <InputSearchContainer>
          <InputSearch
            id="fl-search-input"
            onChange={value => {
              const result = flSearch.current?.search(value) || [];
              setSearchResults(result);
              setQuery({ page: 1 });
            }}
            placeholder={t('Filter by Name or Address')}
            onClear={() => setSearchResults(null)}
          />
        </InputSearchContainer>
      </SectionHeader>
      <FunctionalLocationTable
        loading={loading}
        t={t}
        data={searchResults || functionalLocationData}
        useQueryPaging
        paginate
        hideAddress
        removeHead
        showCount
        initialSortBy={[{ id: 'name', desc: true }]}
      />
    </Section>
  );
};

RescuePlanTable.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loadPartnerOverview: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  topLevelFunctionalLocations: PropTypes.array.isRequired,
  setQuery: PropTypes.func.isRequired,
  rescueFunctionalLocations: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loading: state.partnerOverview.loading || state.file.loadingCounts,
  topLevelFunctionalLocations: state.functionalLocations.topLevelFunctionalLocations,
});

const mapDispatchToProps = dispatch => ({
  loadPartnerOverview: partnerNumber => dispatch(loadPartnerOverview(partnerNumber, true)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(translations(withQuery(RescuePlanTable)));
