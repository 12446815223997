import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import ExternalDocumentTable from 'containers/Application/Modules/ExternalDocumentModule/ExternalDocumentTable';
import FileList from 'components/Documents/FileList/FileList';

const Content = styled.div`
  margin: var(--size-sm) 0;
`;

export const ServiceModuleDocuments = ({ t, documents, files, downloadFile, loading = false, deleteFile, getDeleteFunction }) => {
  return (
    <Content>
      {documents && (
        <ExternalDocumentTable t={t} loading={loading} documents={documents} download={downloadFile} minimalUI />
      )}
      {files && (
        <FileList
          showThumbnails
          loading={loading}
          files={files}
          onDownload={downloadFile}
          onDelete={deleteFile}
          getDeleteFunctionForFile={getDeleteFunction}
        />
      )}
    </Content>
  );
};

ServiceModuleDocuments.propTypes = {
  t: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  downloadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  getDeleteFunction: PropTypes.func,
  loading: PropTypes.bool,
};

export default translations(ServiceModuleDocuments);
