import { OrderType } from 'constants/maintenance';
import { parseISO } from 'date-fns';
import { format } from 'utils/Date/dateFormatter';

export const formatDate = (value, options = { isPlannedDate: false }) => {
  if (options.isPlannedDate) {
    return format(parseISO(value), 'do MMMM, yyyy');
  }
  return format(parseISO(value), 'do MMMM, yyyy, HH:mm');
};

const getOrderYear = order => {
  if (order.plannedDate) {
    return parseISO(order.plannedDate).getFullYear();
  }
  if (order.createdDate) {
    return parseISO(order.createdDate).getFullYear();
  }
  return new Date().getFullYear();
};

const getLinkParts = (order, partnerNumber, orderType) => ({
  partnerPart: !partnerNumber ? '' : `/${partnerNumber}`,
  year: getOrderYear(order),
  section: orderType === OrderType.PLANNED ? 'PlannedMaintenance' : 'ServiceOrders',
  viewModePart: orderType === OrderType.PLANNED ? '&mode=calendarPlan' : '',
});

export const createFunctionalLocationLink = (order, partnerNumber, functionalLocationId) => {
  if (!order || !functionalLocationId) {
    return '';
  }
  const { partnerPart, year, section, viewModePart } = getLinkParts(order, partnerNumber, order.orderType);

  return `${partnerPart}/FunctionalLocation/${functionalLocationId}/Maintenance/${section}?year=${year}${viewModePart}`;
};

export const getCreateEquipmentLink = (order, partnerNumber, functionalLocationId) => equipment => {
  if (!order || !functionalLocationId) {
    return '';
  }
  const { partnerPart, year, section, viewModePart } = getLinkParts(order, partnerNumber, order.orderType);

  return `${partnerPart}/Equipment/${functionalLocationId}//${equipment.equipmentNumber}/Maintenance/${section}?year=${year}${viewModePart}`;
};

export const createMailTo = (order, t, email) => {
  const subject = `${order.orderType === OrderType.PLANNED ? t('Planned Maintenance') : t('Service Order')}: ${
    order.title
  }`;
  return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(window.location.href)}`;
};

export const createSupplierFilterLink = (order, partnerNumber = 'all', functionalLocationId) => {
  if (!order?.supplierName || !functionalLocationId) {
    return '';
  }
  const orderType =
    order.externalType === 'smartview' && !!order.maintenancePlanNumber ? OrderType.PLANNED : order.orderType;

  const { partnerPart, year, section, viewModePart } = getLinkParts(order, partnerNumber, orderType);

  return `${partnerPart}/FunctionalLocation/${functionalLocationId}/Maintenance/${section}?year=${year}&supplier=${encodeURIComponent(
    order.supplierName
  )}${viewModePart}`;
};

export const createMaintenancePlanLink = (order, partnerNumber, functionalLocationId) => {
  if (
    !order ||
    order.externalType !== 'smartview' ||
    order.orderType !== OrderType.PLANNED ||
    !order.maintenancePlanNumber
  ) {
    return '';
  }
  const { partnerPart, year, section, viewModePart } = getLinkParts(order, partnerNumber, OrderType.PLANNED);

  return `${partnerPart}/Maintenance/${section}?year=${year}&code=${order.maintenancePlanNumber}&functionalLocationId=${functionalLocationId}&partnerNumber=${partnerNumber}${viewModePart}`;
};
