import { transparentize } from 'polished';

const simpleMultiValueTooltip = ({ theme, headerText, points }) => {
  const tooltipStyle = `
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.lightGray};
    box-shadow: 0 2px 10px ${transparentize(0.9, theme.colors.black)};
    border-radius: 2px;
    padding: var(--size-xs) var(--size-sm);
  `;
  const headerStyle = `
    padding-bottom: var(--size-xxs);
  `;
  const headerTextStyle = `
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.xxs};
  `;

  let html = `
      <div style="${tooltipStyle}">
        <div style="${headerStyle}">
          <span style="${headerTextStyle}">${headerText}</span>
        </div>
    <table>
  `;

  points.forEach(({ color, symbol, label, value, warning }) => {
    const symbolStyle = `
      font-family: ${theme.fontFamily.text};
      color: ${color || theme.colors.black};
      padding-right: ${symbol ? 'var(--size-xxs)' : 0};
      font-size: ${theme.font.size.sm};
      vertical-align: center;
      line-height: 0;
    `;
    const valueStyle = `
      padding-left: ${!symbol || !label ? 0 : '1em'};
    `;
    const valueTextStyle = `
      font-family: ${theme.fontFamily.text};
      color: ${theme.colors.black};
      font-size: ${theme.font.size.xxs};
      font-weight: ${theme.font.weight.normal};
      line-height: ${theme.font.lineHeight.sm};
    `;
    const labelTextStyle = `
      color: ${theme.colors.black};
      font-weight: ${theme.font.weight.normal};
      font-size: ${theme.font.size.xxs};
      text-align: left;
      font-family: ${theme.fontFamily.text};
      line-height: ${theme.font.lineHeight.sm};
    `;

    html += `
    <tr>
      <td style="${symbolStyle}">${symbol}</td>
      <td>
        <span style="${labelTextStyle}">
          ${label}
        </span>
      </td>
      <td style="${valueStyle}">
        <span style="${valueTextStyle}">
          ${value}
        </span>
      </td>
    </tr>
  `;
    if (warning) {
      html += `
      <tr>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>
          <em>${warning}</em>
        </td>
      </tr>
    `;
    }
  });

  html += `
    </table>
  </div>
  `;
  return html;
};

export default simpleMultiValueTooltip;
