import groupBy from 'lodash/groupBy';
import entries from 'lodash/entries';
import fromPairs from 'lodash/fromPairs';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';

export const indexByDate = {
  getInitialState: () => ({
    byId: {},
    byYear: {},
  }),
  index: (index, items, idField, getYear, keepPrevious = false) => {
    // keep always previous items in byId-index
    const byIdIndex = {
      ...index.byId,
      ...keyBy(items, idField),
    };

    const itemsGroupedByYear = groupBy(items, getYear);

    // keep previous items only when keepPrevious is true (should be used only when loading single items)
    const byYearIndex = fromPairs(
      keepPrevious
        ? entries(index.byYear).map(([year, previousItemsForYear]) => [
            year,
            { ...previousItemsForYear, ...keyBy(itemsGroupedByYear[year], idField) },
          ])
        : entries(itemsGroupedByYear).map(([year, newItemsForYear]) => [year, keyBy(newItemsForYear, idField)])
    );

    return {
      ...index,
      byId: byIdIndex,
      byYear: byYearIndex,
    };
  },
  delete: (index, order, idField, getYear) => {
    const id = order[idField];
    const year = getYear(order);

    let newById = index.byId;
    let newByYear = index.byYear;

    if (index.byId[id]) {
      newById = cloneDeep(index.byId);
      delete newById[id];
    }

    if (index.byYear[year]?.[id]) {
      newByYear = cloneDeep(index.byYear);
      delete newByYear[year][id];
    }

    return {
      ...index,
      byId: newById,
      byYear: newByYear,
    };
  },
};
