import startsWith from 'lodash/startsWith';
import { readFile, readFileAsync } from './readFile';

const getFileDataErrors = (t, fileData, { maxSize, mimeTypes }) => {
  const errors = [];

  if (maxSize && fileData.size > maxSize) {
    errors.push(`${t('File is too big')}: ${fileData.name}`);
  }
  if (!fileData.type) {
    errors.push(`${t('File extension missing')}: ${fileData.name}`);
  }
  if (mimeTypes?.length > 0 && !mimeTypes.some(type => startsWith(fileData.type, type))) {
    errors.push(`${t('Allowed file types are')} ${mimeTypes.join(', ')}: ${fileData.name}`);
  }

  return errors;
};

const checkErrors = (t, fileDataArray, options) => {
  const errors = fileDataArray.flatMap(fileData => getFileDataErrors(t, fileData, options));
  if (errors.length > 0) {
    throw new Error(errors.join('\n'));
  }
};

export const uploadUserFiles = (t, fileDataArray, addFile, options = {}) => {
  checkErrors(t, fileDataArray, options);
  fileDataArray.forEach(fileData => readFile(fileData, addFile));
};

export const uploadUserFilesAsync = (t, fileDataArray, options = {}) => {
  checkErrors(t, fileDataArray, options);
  return Promise.all(fileDataArray.map(fileData => readFileAsync(fileData)));
};
