import ApiRequest from 'utils/Fetch/ApiRequest';
import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { MASTER_DATA_PATH, SERVICE_API_PATH } from './index';

export const sensorHierarchiesFn = (request, filter, noCache) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/sensorHierarchies`,
    data: { filter },
    noCache,
  });

export const createSensorHierarchy = ({ name, shortName, type, subtype, order, parentId, functionalLocation }) =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies`,
    data: { name, shortName, type, subtype, order, parentId, functionalLocation },
    noCache: true,
  });

export const updateSensorHierarchy = ({ id, name, shortName, subtype, order }) =>
  ApiRequest.patch({
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies/${id}`,
    data: { name, shortName, subtype, order },
    noCache: true,
  });

export const deleteSensorHierarchy = sensorHierarchyId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies/${sensorHierarchyId}`,
    noCache: true,
  });

export default wrapEachWithThunkRequest({
  sensorHierarchies: sensorHierarchiesFn,
});
