import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/Button/PrimaryButton';

const NextButton = ({ t, onClick }) => (
  <PrimaryButton large add onClick={onClick}>
    {t('Next phase')}
  </PrimaryButton>
);

NextButton.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NextButton;
