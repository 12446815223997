import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';
import { MASTER_DATA_PATH, SERVICE_API_PATH } from './index';

export const invalidateCustomerCache = () =>
  Promise.allSettled([
    invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/customers`),
    invalidateCachesWithUrlMatch(`${MASTER_DATA_PATH}/customers`), // For backwards compatibility, also clear old master data customers
  ]);

export const customersByKeywordFn = (request, keyword) =>
  request.get({
    apiPath: `${SERVICE_API_PATH}/customers/search/${keyword}`,
  });

export const customersByPartnerNumbersFn = (request, partnerNumbers) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/customers/find`,
    data: { partnerNumbers },
  });

export default wrapEachWithThunkRequest({
  customersByKeyword: customersByKeywordFn,
  customersByPartnerNumbers: customersByPartnerNumbersFn,
});
