export const SLAsForFL = (request, query) =>
  request.get({
    apiPath: `/api/slas`,
    query,
  });

export const SLAsForPartner = (request, query) =>
  request.get({
    apiPath: `/api/slas`,
    query,
  });

export const inspectionKPIForPartner = (request, query) =>
  request.get({
    apiPath: `/api/slas/inspections/kpi`,
    query,
  });
