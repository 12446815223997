import { createReducerFromMapping } from 'redux/utils/index';
import keyBy from 'lodash/keyBy';
import MasterDataService from 'services/masterData';

import { filterPathByPermissions, hasPartnerPermission } from 'utils/profile';

const initialState = {
  loading: [],
  functionalLocations: {},
  topLevelFunctionalLocations: [],
};

export const resolveFunctionalLocationType = functionalLocation => functionalLocation?.type ?? '';

export const LOAD = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/FunctionalLocations/LOAD_FAIL';

export const loadFunctionalLocations = functionalLocations => async dispatch => {
  const key = JSON.stringify([LOAD, functionalLocations]);
  if (!functionalLocations?.length) {
    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result: [],
    });
  }

  dispatch({ type: LOAD, key });
  try {
    const result = await MasterDataService.functionalLocations(functionalLocations);

    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result,
    });
  } catch (error) {
     dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
    throw error;
  }
};

export const loadFunctionalLocationsForOverview = partnerNumber => async dispatch => {
  const fixedPartnerNumber = !partnerNumber || partnerNumber === 'all' ? undefined : partnerNumber;

  const key = JSON.stringify([LOAD, fixedPartnerNumber]);
  dispatch({ type: LOAD, key });
  try {
    const result = await MasterDataService.functionalLocationsOverview(fixedPartnerNumber);

    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result,
      topLevelResult: result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
  }
};

export const loadFunctionalLocationsForParent = functionalLocation => async dispatch => {
  const functionalLocationParent = functionalLocation.path[functionalLocation.path.length - 1];
  const key = JSON.stringify([LOAD, functionalLocation]);

  dispatch({ type: LOAD, key });
  try {
    const result = await MasterDataService.functionalLocationsByParent([functionalLocationParent]);

    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
  }
};

// TODO: Could this be removed now that loadFunctionalLocations uses hierarchiesByFunctionalLocations?
export const loadFunctionalLocationsByPath = (partnerNumber, path) => async (dispatch, getState) => {
  const key = JSON.stringify([LOAD, path]);
  const profile = getState().profile.profile;

  const partnerPermission = hasPartnerPermission(profile, partnerNumber);
  const authorizedPath = filterPathByPermissions(path, partnerNumber, profile);

  dispatch({ type: LOAD, key });

  if (!partnerPermission && !authorizedPath?.length) {
    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result: [],
    });
  }

  try {
    // API has special handling for hierarchies endpoint if 'path' parameter is provided to allow fetching
    // hierarchies with partner permissions properly. Using this special parameter if partnerNumber is provided.
    let result;
    if (partnerPermission) {
      result = await MasterDataService.functionalLocationsByPartnerNumbers([partnerNumber], path);
    } else {
      result = await MasterDataService.functionalLocations(authorizedPath);
    }

    return dispatch({
      type: LOAD_SUCCESS,
      key,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FAIL,
      key,
      error,
    });
  }
};

const loadFunctionalLocationSuccessReducer = (state, action) => ({
  ...state,
  loading: state.loading.filter(x => x !== action.key),
  functionalLocations: {
    ...state.functionalLocations,
    ...keyBy(action.result, 'functionalLocation'),
  },
  topLevelFunctionalLocations: action.topLevelResult || state.topLevelFunctionalLocations,
});

export default createReducerFromMapping(
  {
    [LOAD]: (state, action) => ({
      ...state,
      loading: state.loading.concat([action.key]),
    }),
    [LOAD_SUCCESS]: loadFunctionalLocationSuccessReducer,
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: state.loading.filter(x => x !== action.key),
    }),
  },
  initialState
);
