import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import { formatNumber } from 'utils/Number/decimalFormat';
import ValuesBox from 'components/ValuesBox/ValuesBox';
import ValuesBoxComparisonValues from 'components/ValuesBox/ValuesBoxComparisonValues';
import ValuesBoxComparisonValue from 'components/ValuesBox/ValuesBoxComparisonValue';
import BreakdownTotalsSkeleton from './BreakdownTotalsSkeleton';

const MAX_NUMBER_OF_CATEGORIES = 2;

export const BreakdownTotals = ({
  unit,
  categories,
  header,
  value,
  label,
  icon,
  theme,
  children,
  footer,
  positiveValueColor,
  language,
}) => {
  if (isNil(value)) {
    return <BreakdownTotalsSkeleton />;
  }
  const sortedCategories = sortBy([...categories], 'categoryName');
  const categoriesToRender = sortedCategories.slice(0, MAX_NUMBER_OF_CATEGORIES);
  return (
    <ValuesBox
      heading={header}
      value={`${formatNumber(language, Math.round(value))} ${unit}`}
      label={label}
      icon={icon}
      positiveValueColor={positiveValueColor || 'var(--positive-color)'}
      footer={footer}
    >
      {categoriesToRender?.length > 0 && (
        <ValuesBoxComparisonValues>
          {categoriesToRender.map(category => (
            <ValuesBoxComparisonValue
              key={`breakdown-total-${category.categoryId}`}
              value={`${formatNumber(language, Math.round(category.total))} ${category.unit ?? ''}`}
              label={category.categoryName}
              valueColor={category.color}
            />
          ))}
        </ValuesBoxComparisonValues>
      )}
      {children}
    </ValuesBox>
  );
};

BreakdownTotals.defaultProps = {
  unit: '',
  categories: [],
  header: '',
  label: '',
  value: '',
};

BreakdownTotals.propTypes = {
  unit: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      categoryName: PropTypes.string.isRequired,
      color: PropTypes.string,
      unit: PropTypes.string,
      total: PropTypes.number.isRequired,
    })
  ),
  header: PropTypes.string,
  footer: PropTypes.shape({
    header: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  value: PropTypes.number,
  icon: PropTypes.string,
  theme: PropTypes.object,
  children: PropTypes.node,
  positiveValueColor: PropTypes.string,
  language: PropTypes.string,
};

export default withTheme(BreakdownTotals);
