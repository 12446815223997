import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as yup from 'yup';
import omit from 'lodash/omit';

import { Title, GraySection, Description } from '../common';
import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputTextArea from 'components/Form/InputTextArea';
import InputText from 'components/Form/InputText';
import InputLabel, { Label, ExtraText, ErrorText } from 'components/Form/InputLabel';
import PrimaryButton from 'components/Button/PrimaryButton';
import ErrorSnackBar from 'containers/Application/ServiceModule/BulkTools/ErrorSnackBar/ErrorSnackBar';
import FileUploadForm from 'components/FileUploadForm/FileUploadForm';
import Svg from 'components/Svg/Svg';

import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import useFormValidation from 'containers/Application/ServiceModule/ServiceModuleForm/hooks/useFormValidation';
import {
  additionalSalePropType,
  getAnswerOptionsByValue,
  getWrongPersonAnswerOptions,
  isActionForClosing,
  isActionWrongPerson,
  isActionNow,
  isCorrectPersonEmailKnown,
  isCustomerTokenType,
  isManagerTokenType,
  AdditionalSaleStatus,
  WRONG_PERSON,
  openGeneralTermsAndConditions,
} from '../utils';
import ManagerChangeForm from './ManagerChangeForm';
import SecondaryButton from 'components/Button/SecondaryButton/SecondaryButton';
import InfoBox from 'components/InfoBox/InfoBox';
import { Division } from 'types/Division';

const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  margin-bottom: var(--size-md);

  ${Title} {
    margin-bottom: 0;
  }
`;

export const StyledInputText = styled(InputText)`
  ${props =>
    props.indent &&
    props.theme.media.portrait`
      padding-left: var(--size-xl);
  `};
`;
StyledInputText.displayName = 'StyledInputText';

export const StyledLabel = styled(InputLabel)`
  ${props =>
    props.indent &&
    props.theme.media.portrait`
      padding-left: var(--size-xl);
  `};

  ${Label} {
    line-height: 1.5;
    font-weight: ${props => props.theme.font.weight.semibold};
    font-size: ${props => (props.hideText ? 0 : props.theme.font.size.xxs)};
    color: ${props => props.theme.colors.black};
  }

  ${ExtraText} {
    display: block;
    padding-left: 0;
    padding-bottom: 2px;
    font-weight: ${props => props.theme.font.weight.normal};
    color: ${props => props.theme.colors.darkGray};
  }

  ${ErrorText} {
    display: block;
    position: static;
    float: unset;
    padding-left: 0;
    padding-bottom: 2px;
    font-weight: ${props => props.theme.font.weight.normal};
    font-size: ${props => props.theme.font.size.xxs};
  }
`;
StyledLabel.displayName = 'StyledLabel';

const StyledRadioButton = styled(RadioButton)`
  ${props =>
    props.indent &&
    props.theme.media.portrait`
      padding-left: var(--size-xl);
  `};
  label {
    white-space: normal;
    ${props => props.theme.media.landscape`
      white-space: nowrap;
    `}
  }
  label > span {
    min-width: var(--size-xl);
    max-width: var(--size-xl);
    min-height: var(--size-xl);
    max-height: var(--size-xl);
    ${props => props.theme.media.landscape`
      min-width: var(--size-lg);
      max-width: var(--size-lg);
      min-height: var(--size-lg);
      max-height: var(--size-lg);
  `}
  }
`;
StyledRadioButton.displayName = 'StyledRadioButton';

export const SendButtonWrapper = styled.div`
  margin-top: var(--size-sm);
`;
SendButtonWrapper.displayName = 'SendButtonWrapper';

const GeneralTermsAndConditionsButton = styled.button`
  // override button styles
  appearance: none;
  background: none;
  border: none;
  font-family: ${props => props.theme.font.family.cairo};
  // link styles
  color: ${props => props.theme.colors.cerulean};
  font-size: ${props => props.theme.font.size.xs};
  line-height: ${props => props.theme.font.lineHeight.md};
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--size-xs);
`;

const GeneralTermsAndConditionsIcon = styled(Svg)`
  font-size: ${props => props.theme.font.size.xxs};
  fill: ${props => props.theme.colors.cerulean};
`;

const EMPTY_OBJECT = {};
const HIDDEN_SNACKBAR_ERROR = { visible: false, message: '', errored: [] };
const FORM = {
  default: 'DEFAULT',
  managerChange: 'MANAGER_CHANGE',
};

const shouldShowInfobox = (action, division) =>
  isActionNow(action) && (division === Division.Finland || division === Division.Sweden);

const AdditionalSaleForm = ({ additionalSale = EMPTY_OBJECT, loading, onSubmitAction, onSubmitManagerChange }) => {
  const { t, selectedLanguage } = useUnauthenticatedTranslations();
  const [model, setModel] = React.useState({});
  const [sending, setSending] = React.useState(false);
  const [requireComment, setRequireComment] = React.useState(false);
  const [showAction, setShowAction] = React.useState(true);
  const [showName, setShowName] = React.useState(true);
  const [showPhoneNumber, setShowPhoneNumber] = React.useState(true);
  const [showPurchaseOrderNumber, setShowPurchaseOrderNumber] = React.useState(true);
  const [showComment, setShowComment] = React.useState(true);
  const [showWrongPersonAction, setShowWrongPersonAction] = React.useState(false);
  const [showCorrectPersonEmail, setShowCorrectPersonEmail] = React.useState(false);
  const [showCorrectPersonEmailForManager, setShowCorrectPersonEmailForManager] = React.useState(false);
  const [showEmailError, setShowEmailError] = React.useState(false);
  const [snackBarError, setSnackBarError] = React.useState(HIDDEN_SNACKBAR_ERROR);
  const [currentForm, setCurrentForm] = React.useState(FORM.default);

  React.useEffect(() => {
    const isCustomerToken = isCustomerTokenType(additionalSale?.token?.type);
    const isManagerAfterWrongPerson = !isCustomerToken && additionalSale.status === AdditionalSaleStatus.PENDING;
    setShowAction(isCustomerToken);
    setShowPhoneNumber(isCustomerToken);
    setShowPurchaseOrderNumber(isCustomerToken);
    setRequireComment(!isActionForClosing(additionalSale?.action) || !isCustomerToken);
    setShowComment(isCustomerToken || additionalSale.status !== AdditionalSaleStatus.PENDING);
    setShowCorrectPersonEmailForManager(isManagerAfterWrongPerson);
    handleDefaultValues(additionalSale, isManagerAfterWrongPerson);
  }, [additionalSale]);

  const formSchemas = React.useMemo(
    () => ({
      default: yup.object().shape({
        action: yup.string().label(t('Answer')).required(),
        name: yup
          .string()
          .label(t('Name'))
          .when('action', (action, schema) => (isActionWrongPerson(action) ? schema.optional() : schema.required())),
        email: yup.string().label(t('Email')).email().required(),
        phoneNumber: yup.string().label(t('Phone number')).optional(),
        purchaseOrderNumber: yup.string().label(t('Purchase Order Number')).optional(),
        comment: yup
          .string()
          .label(t('Comment'))
          .when('action', (action, schema) =>
            isActionForClosing(action) || isActionWrongPerson(action) || showCorrectPersonEmailForManager
              ? schema.optional()
              : schema.required()
          ),
        actionWrongPerson: yup
          .string()
          .label(t('Answer'))
          .when('action', (action, schema) =>
            isActionWrongPerson(action) && !showCorrectPersonEmailForManager ? schema.required() : schema.optional()
          ),
        correctPersonEmail: yup
          .string()
          .label(t('Email'))
          .email()
          .when('actionWrongPerson', (action, schema) =>
            isCorrectPersonEmailKnown(action) || showCorrectPersonEmailForManager
              ? schema.required()
              : schema.optional()
          ),
      }),
    }),
    [t, showCorrectPersonEmailForManager]
  );
  const [validationErrors, validateForms] = useFormValidation(model, formSchemas);

  const handleDefaultValues = ({ action, purchaseOrderNumber }, showCorrectPersonEmailForManager) => {
    const defaultValues = {};
    if (action) {
      defaultValues.action = action;
    } else if (showCorrectPersonEmailForManager) {
      defaultValues.action = WRONG_PERSON;
    }
    if (purchaseOrderNumber) {
      defaultValues.purchaseOrderNumber = purchaseOrderNumber;
    }
    setModel(defaultValues);
  };

  const handleFormChange = (property, value) => {
    if (property === 'action') {
      const isCustomerToken = isCustomerTokenType(additionalSale?.token?.type);
      const isWrongPerson = isActionWrongPerson(value);
      setModel(oldModel => ({ ...omit(oldModel, ['actionWrongPerson', 'correctPersonEmail']) }));
      setShowName(!isWrongPerson);
      setShowPhoneNumber(isCustomerToken && !isWrongPerson);
      setShowPurchaseOrderNumber(isCustomerToken && !isWrongPerson);
      setShowComment(!isWrongPerson);
      setShowWrongPersonAction(isWrongPerson);
      setShowCorrectPersonEmail(false);
      setRequireComment(!isActionForClosing(value));
    }
    if (property === 'actionWrongPerson') {
      setModel(oldModel => ({ ...omit(oldModel, ['correctPersonEmail']) }));
      setShowCorrectPersonEmail(isCorrectPersonEmailKnown(value));
    }
    setModel(oldModel => ({ ...oldModel, [property]: value }));
  };

  const handleCommonSubmit = async (submitFn, data) => {
    try {
      setSending(true);
      setShowEmailError(false);
      await submitFn(data);
      setSending(false);
    } catch (error) {
      setSending(false);
      if (error.statusCode === 409) {
        setShowEmailError(true);
        setSnackBarError({ errored: [t('Your email address does not have permissions to reply')], visible: true });
      } else {
        setSnackBarError({ errored: [t('Sending failed')], visible: true });
      }
    }
  };

  const handleDefaultFormSubmit = async () => {
    if (await validateForms()) {
      handleCommonSubmit(onSubmitAction, model);
    }
  };

  const handleManagerChangeFormSubmit = data => {
    handleCommonSubmit(onSubmitManagerChange, data);
  };

  const handleFormChangeClick = () => {
    setCurrentForm(currentForm === FORM.default ? FORM.managerChange : FORM.default);
  };

  const answerOptions = getAnswerOptionsByValue(additionalSale.value).map(option => ({
    label: t(`additional-sale/${option}`),
    value: option,
  }));

  const wrongPersonAnswerOptions = getWrongPersonAnswerOptions().map(option => ({
    label: t(`additional-sale/${option}`),
    value: option,
  }));

  return (
    <GraySection>
      <TitleRow>
        <Title semibold>{currentForm === FORM.default ? t('Your Reply') : t('Change Manager')}</Title>
        {isManagerTokenType(additionalSale?.token?.type) && (
          <SecondaryButton iconName={currentForm === FORM.default ? 'pen' : undefined} onClick={handleFormChangeClick}>
            {currentForm === FORM.default ? t('Change Manager') : t('Go Back')}
          </SecondaryButton>
        )}
      </TitleRow>
      {currentForm === FORM.default && (
        <>
          {showCorrectPersonEmailForManager && (
            <Description grayText>
              {t(
                "If you noticed a typo or otherwise need to change customer's recipient, please do it using the form below. Please note that only the latest defined customer email address will be able to reply."
              )}
            </Description>
          )}
          <InputForm onPropertyChange={handleFormChange} model={model} validationErrors={validationErrors}>
            {showAction && (
              <InputRow fullRow dense>
                <StyledLabel text={t('Select One Option')} required errorText={validationErrors.action} />
                <RadioButtonGroup name="action" value={model.action} onChange={handleFormChange}>
                  {answerOptions.map(({ label, value }) => (
                    <StyledRadioButton key={value} label={label} value={value} disabled={loading} />
                  ))}
                </RadioButtonGroup>
              </InputRow>
            )}
            {showName && (
              <InputRow fullRow dense>
                <StyledLabel text={t('Your Name')} required errorText={validationErrors.name} />
                <StyledInputText simple property="name" disabled={loading} required />
              </InputRow>
            )}
            <InputRow fullRow dense>
              <StyledLabel
                email
                text={t('Your Email Address')}
                extraText={t('Required for security validation')}
                errorText={
                  showEmailError
                    ? t('This email address does not have permission to reply')
                    : validationErrors.email ?? undefined
                }
                required
              />
              <StyledInputText simple property="email" disabled={loading} required />
            </InputRow>
            {showPhoneNumber && (
              <InputRow fullRow dense>
                <StyledLabel
                  text={t('Your Phone Number')}
                  extraText={t('Optional')}
                  errorText={validationErrors.phoneNumber}
                />
                <StyledInputText simple property="phoneNumber" disabled={loading} />
              </InputRow>
            )}
            {showPurchaseOrderNumber && (
              <InputRow fullRow dense>
                <StyledLabel
                  text={t('Purchase Order Number')}
                  extraText={t('Optional')}
                  errorText={validationErrors.purchaseOrderNumber}
                />
                <StyledInputText simple property="purchaseOrderNumber" disabled={loading} />
              </InputRow>
            )}
            {showComment && (
              <InputRow fullRow dense>
                <StyledLabel
                  text={t('Comment')}
                  extraText={requireComment ? '' : t('Optional')}
                  errorText={requireComment ? validationErrors.comment : ''}
                  required={requireComment}
                />
                <InputTextArea simple property="comment" disabled={loading} height="150px" required={requireComment} />
              </InputRow>
            )}
            {showWrongPersonAction && (
              <InputRow fullRow dense>
                <StyledLabel text="" errorText={validationErrors.actionWrongPerson} hideText indent />
                <RadioButtonGroup name="actionWrongPerson" value={model.actionWrongPerson} onChange={handleFormChange}>
                  {wrongPersonAnswerOptions.map(({ label, value }) => (
                    <StyledRadioButton key={value} label={label} value={value} disabled={loading} indent />
                  ))}
                </RadioButtonGroup>
              </InputRow>
            )}
            {((showWrongPersonAction && showCorrectPersonEmail) || showCorrectPersonEmailForManager) && (
              <InputRow fullRow dense>
                <StyledLabel
                  text={showCorrectPersonEmailForManager ? t('New Customer Email Address') : ''}
                  errorText={validationErrors.correctPersonEmail}
                  hideText={!showCorrectPersonEmailForManager}
                  indent={!showCorrectPersonEmailForManager}
                  required={showCorrectPersonEmailForManager}
                />
                <StyledInputText
                  simple
                  property="correctPersonEmail"
                  disabled={loading}
                  indent={!showCorrectPersonEmailForManager}
                />
              </InputRow>
            )}
            {!showWrongPersonAction && (
              <InputRow fullRow dense>
                <StyledLabel text={t('Attachments')} extraText={t('Optional')} />
                <FileUploadForm files={model.attachments} onChange={files => handleFormChange('attachments', files)} />
              </InputRow>
            )}
            {shouldShowInfobox(model.action, additionalSale.division) && (
              <InfoBox hide={loading}>
                {t('additional_sales.conditions_info')}
                <GeneralTermsAndConditionsButton onClick={() => openGeneralTermsAndConditions(selectedLanguage)}>
                  {t('General Terms And Conditions')}
                  <GeneralTermsAndConditionsIcon name="open-modal" />
                </GeneralTermsAndConditionsButton>
              </InfoBox>
            )}
          </InputForm>
          <SendButtonWrapper>
            <PrimaryButton
              large
              confirm
              loading={sending}
              disabled={loading || sending}
              onClick={handleDefaultFormSubmit}
            >
              {t('Send')}
            </PrimaryButton>
          </SendButtonWrapper>
        </>
      )}
      {currentForm === FORM.managerChange && (
        <ManagerChangeForm
          onSubmit={handleManagerChangeFormSubmit}
          sending={sending}
          showEmailConflictError={showEmailError}
        />
      )}
      <ErrorSnackBar error={snackBarError} onClose={() => setSnackBarError(HIDDEN_SNACKBAR_ERROR)} />
    </GraySection>
  );
};

AdditionalSaleForm.propTypes = {
  additionalSale: additionalSalePropType,
  loading: PropTypes.bool,
  onSubmitAction: PropTypes.func.isRequired,
  onSubmitManagerChange: PropTypes.func.isRequired,
};

export default AdditionalSaleForm;
