import parseISO from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

import { DATE_FORMATS, format } from 'utils/Date/dateFormatter';

export const getSensorIdForAxis = (seriesObj, axis) => seriesObj?.axes?.[axis];

export const formatTimestamp = (timestamp, dateFormat) => `${format(parseISO(timestamp), dateFormat)}`;

export const getGroupedValuesForAxis = (axis, seriesObj, valuesBySensorId, dateFormat) => {
  const sensorId = getSensorIdForAxis(seriesObj, axis);
  const sensorValues = valuesBySensorId[sensorId] ?? [];
  return groupBy(sensorValues, value => formatTimestamp(value.timestamp, dateFormat));
};

export const calculateGroupValues = (groupedValues, aggregationFunc) => {
  const calculate = aggregationFunc === 'sum' ? sumBy : meanBy;

  return Object.entries(groupedValues).reduce((accu, [key, value]) => {
    accu[key] = {
      value: calculate(groupedValues[key], 'value'),
      sensorId: value[0].sensorId,
      timestamp: value[0].timestamp,
    };
    return accu;
  }, {});
};

const getDateFormat = aggregationFreq => {
  switch (aggregationFreq) {
    case 'monthly':
      return DATE_FORMATS.yearMonthShort;
    case 'daily':
      return DATE_FORMATS.dateShortLocal;
    case 'hourly':
      return DATE_FORMATS.dateTimeShortLocal;
    default:
      return DATE_FORMATS.dateShortLocal;
  }
};

/**
 * Group both sensor values with relevant date formatter.
 * Aggregate group values with sum or mean.
 * Combine values that have same group key (formatted date).
 */
export const createScatterPlotData = (seriesObj = {}, valuesBySensorId = {}, aggregationFreq = 'hourly') => {
  const dateFormat = getDateFormat(aggregationFreq);

  const aggregationFunc = seriesObj.aggregationFunc ?? 'average';

  const xValues = calculateGroupValues(
    getGroupedValuesForAxis('x', seriesObj, valuesBySensorId, dateFormat),
    aggregationFunc
  );

  const yValues = calculateGroupValues(
    getGroupedValuesForAxis('y', seriesObj, valuesBySensorId, dateFormat),
    aggregationFunc
  );

  const groups = Object.entries(xValues);

  const scatterPlotData = groups.reduce((acc, [group, x]) => {
    const y = yValues[group];
    if (x === undefined || y === undefined) {
      return acc;
    }

    return acc.concat({
      id: `${x.value}${y.value}`,
      sensorIds: [x.sensorId, y.sensorId],
      timestamp: x.timestamp,
      x: x.value,
      y: y.value,
    });
  }, []);

  return scatterPlotData;
};
