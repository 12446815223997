import React from 'react';
import styled from 'styled-components';

import { components, GroupBase, OptionProps } from 'react-select';
import { SmallDummyCheckbox } from 'components/Form/SmallDummyCheckbox';
import { SelectFilterOptionType } from './SelectFilter';

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.layoutSpacing.sm};
  pointer-events: none;
`;

type CheckboxProps = {
  id: string;
  label: string;
  isSelected: boolean;
  isDisabled: boolean;
};

const Checkbox = ({ id, label, isSelected, isDisabled }: CheckboxProps) => {
  return (
    <CheckboxLabel>
      <CheckboxInput
        type="checkbox"
        id={id}
        data-test-id={id}
        checked={isSelected}
        onChange={() => null}
        disabled={isDisabled}
      />
      <SmallDummyCheckbox checked={isSelected} disabled={isDisabled} />
      {label}
    </CheckboxLabel>
  );
};

const SelectAllOption = styled.span`
  color: var(--filter-option-select-all-fg);
  font-size: ${props => props.theme.font.size.xxs};
  line-height: ${props => props.theme.font.lineHeight.md};
  font-weight: ${props => props.theme.font.weight.semibold};
`;

export function MultiSelectOption<
  Option extends SelectFilterOptionType,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(props: OptionProps<Option, IsMulti, Group>) {
  if (props.data.value === 'toggle-select-all') {
    return (
      <components.Option {...props}>
        <SelectAllOption>{props.data.label}</SelectAllOption>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <Checkbox
        id={`checkbox-${props.data.value}`}
        isSelected={props.isSelected}
        isDisabled={props.isDisabled}
        label={props.data.label}
      />
    </components.Option>
  );
}
