import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import Svg from 'components/Svg/Svg';
import { icons } from 'components/Icon/IconNames';
import { useTranslations } from 'decorators/Translations/translations';
import { rootSize } from 'styles/definitions';

const Container = styled.div``;

const Icon = styled(Svg)`
  font-size: ${rem('50px', rootSize)};
  margin-right: var(--size-md);
`;

const Buttons = styled.div`
  columns: 2;
  column-gap: var(--size-xs);
`;

const Button = styled.button`
  border: var(--border-thin) solid
    ${props => (props['aria-pressed'] ? 'var(--checkbox-bc-active)' : 'var(--checkbox-bc-inactive)')};
  background-color: ${props => (props['aria-pressed'] ? 'var(--checkbox-bg-active)' : 'var(--checkbox-bg-inactive)')};
  color: ${props => (props['aria-pressed'] ? 'var(--checkbox-fg-active)' : 'var(--checkbox-fg-inactive)')};
  font-size: ${props => props.theme.font.size.xs};
  border-radius: 1px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  line-height: 1.29;
  margin: 0 0 var(--size-xs);
  height: ${rem('80px', rootSize)};
  padding: 0 var(--size-md);
  display: inline-flex;
  align-items: center;

  ${Icon} {
    fill: ${props => (props['aria-pressed'] ? 'var(--checkbox-fg-active)' : 'var(--checkbox-fg-inactive)')};
  }
`;

export const USAGE_TYPES = {
  GENERAL: 'default',
  HORIZONTAL_INFOSCREEN: 'horizontal-info-screen',
  VERTICAL_INFOSCREEN: 'vertical-info-screen',
};

const USAGE_TYPE_LABELS = {
  [USAGE_TYPES.GENERAL]: 'General',
  [USAGE_TYPES.HORIZONTAL_INFOSCREEN]: 'Horizontal Infoscreen',
  [USAGE_TYPES.VERTICAL_INFOSCREEN]: 'Vertical Infoscreen',
};

const USAGE_TYPE_ICONS = {
  [USAGE_TYPES.GENERAL]: icons.USAGE_TYPE_GENERAL,
  [USAGE_TYPES.HORIZONTAL_INFOSCREEN]: icons.USAGE_TYPE_HORIZONTAL_INFOSCREEN,
  [USAGE_TYPES.VERTICAL_INFOSCREEN]: icons.USAGE_TYPE_VERTICAL_INFOSCREEN,
};

const BuildingFloorUsageType = ({ value, onChange }) => {
  const [t] = useTranslations();

  return (
    <Container>
      <Buttons>
        {Object.entries(USAGE_TYPES).map(([key, usageType]) => {
          return (
            <Button
              key={usageType}
              type="button"
              aria-pressed={value === usageType}
              onClick={() => onChange(usageType)}
            >
              <Icon name={USAGE_TYPE_ICONS[usageType]} />
              {t(USAGE_TYPE_LABELS[usageType])}
            </Button>
          );
        })}
      </Buttons>
    </Container>
  );
};

BuildingFloorUsageType.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default BuildingFloorUsageType;
