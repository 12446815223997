import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { rgba } from 'polished';
import throttle from 'lodash/throttle';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import IoTChart from 'components/Charts/IoTChart';
import AirQualityChart from 'components/Charts/AirQualityChart';
import CleaningChart from 'components/Charts/CleaningChart';
import BarChart from 'components/Charts/BarChart';
import PerformanceChart from 'components/Charts/PerformanceChart';
import StatisticsBarChart from 'components/Charts/StatisticsBarChart';
import { StyledButton as SecondaryButton } from 'components/Button/SecondaryButton/SecondaryButton';
import { getSensorTitle, STATISTICS_TYPES } from 'containers/Application/SensorValues/SensorValuesUtils';
import { useTranslations } from 'decorators/Translations/translations';
import { isStatisticsSensor } from 'utils/Data/values';

import useChartAnnotations from '../SensorAnnotations/useChartAnnotations';
import SensorAnnotations from '../SensorAnnotations/SensorAnnotations';

import {
  getLabelFormatter,
  getTooltipHeaderFormatter,
  getTickInterval,
  getPerformanceChartConfig,
  getParameterChangesFromHighchartsZoom,
  getGraphTypeBooleanValueText,
  getDataGrouping,
} from './utils';
import { AnnotationInfo } from 'containers/Application/Modules/CustomChartsModule/CustomChart/DoubleClickAnnotationInfo';
import { hasBuildingAndPortfolioAdminTools } from 'utils/Data/profileData';
import useChartDoubleClick from 'components/Charts/useChartDoubleClick';
import { getAnnotationGranularity } from '../SensorAnnotations/utils';
import { activeBreakpoints } from 'utils/Breakpoints/useBreakpoints';
import useAnnotations from '../SensorAnnotations/useAnnotations';

export const ChartContainer = styled.div`
  position: relative;
  min-height: fit-content;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.sensorValues.padding.mobile};

  ${props => props.theme.media.portrait`
    padding: var(--size-sm) ${props => props.theme.sensorValues.padding.tablet};
  `}

  ${props => props.theme.media.desktop`
    padding: var(--size-sm) ${props => props.theme.sensorValues.padding.desktop};
  `}
`;
ChartContainer.displayName = 'ChartContainer';

const StyledNoDataInfo = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
`;
StyledNoDataInfo.displayName = 'StyledNoDataInfo';

const ResetZoomButton = styled(SecondaryButton)`
  position: absolute;
  z-index: 0;
  top: 15px;
  margin-right: 40px;
  right: ${props => props.theme.sensorValues.padding.mobile};
  ${props => props.theme.media.portrait`
    right: ${props => props.theme.sensorValues.padding.tablet};
  `}
  ${props => props.theme.media.desktop`
    right: ${props => props.theme.sensorValues.padding.desktop};
  `}
`;

const errorTexts = [
  'No data available',
  'Try data tools for more data',
  'You can only select three days time range',
  'You can choose sensor from dropdown above',
];

const EMPTY_ARRAY = [];

export const SensorBody = props => {
  const {
    sensor,
    sensorData,
    sensorType,
    sensorMeta,
    sensorTitle,
    hasSensorOptions,
    parameterModel,
    loading,
    aggregation,
    smallHeight,
    theme,
    utilizationHours,
    statistics,
    getThreshold,
    height,
    openingHours,
    buildingMeta,
    timezone,
    functionalLocation,
    updateParameters,
    publicViewId,
    booleanLabels,
  } = props;
  const [t] = useTranslations();
  const allowAnnotationsAdminTools = useSelector(state => hasBuildingAndPortfolioAdminTools(state.profile.profile));

  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const throttledSetHeight = throttle(() => setWindowHeight(window.innerHeight), 100);

  const containerRef = React.useRef();

  React.useEffect(() => {
    window.addEventListener('resize', throttledSetHeight);

    return () => {
      window.removeEventListener('resize', throttledSetHeight);
    };
  }, [throttledSetHeight]);

  const start = parameterModel?.startDatetime;
  const end = parameterModel?.endDatetime;
  const isZoomed = parameterModel?.isZoomed;

  const { annotations } = useAnnotations({
    functionalLocation: functionalLocation.functionalLocation,
    sensor,
    startDate: start,
    endDate: end,
    publicViewId,
  });

  const { chartAnnotations, onClickChart, onCloseAnnotations, annotationPopover, createAnnotationAt } =
    useChartAnnotations(null, getAnnotationGranularity(start, end, !activeBreakpoints().landscape), annotations);

  const { onClick } = useChartDoubleClick(onClickChart);

  const onSelectChartTimespan = React.useCallback(
    event => {
      const parameterChanges = getParameterChangesFromHighchartsZoom(event, parameterModel);
      if (parameterChanges) {
        updateParameters(parameterChanges);
      }
      return false; // prevent Highcharts zooming
    },
    [updateParameters, start, end, isZoomed] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onResetZoom = () => {
    if (isZoomed && !isEmpty(parameterModel?.resetZoomDatetimes)) {
      updateParameters({
        isZoomed: false,
        startDatetime: parameterModel.resetZoomDatetimes.start,
        endDatetime: parameterModel.resetZoomDatetimes.end,
        resetZoomDatetimes: {},
      });
    }
  };

  const errorText = [t(errorTexts[0]), t(errorTexts[1]), hasSensorOptions ? t(errorTexts[3]) : ''].join('\n');

  if (!end || !start) {
    return null;
  }

  const timespan = differenceInMilliseconds(end, start); // graph shows only the selected timespan
  const maxX = end.getTime(); // use maxX to control graph timespan

  const sharedProps = {
    disableTooltip: Boolean(annotationPopover),
    loading,
    t,
    error: errorText,
    noZoom: true,
    onClick,
    onSelection: onSelectChartTimespan,
    annotations: chartAnnotations,
    title: `${functionalLocation?.name ?? ''} - ${uniq([sensorTitle, getSensorTitle(null, sensor)]).join('/')} ID:${
      sensor.id ?? ''
    }`,
  };

  const booleanProps = {
    ...sharedProps,
    noZoom: false,
  };

  const tickInterval = getTickInterval(timespan);
  const hideTime = aggregation === 'monthlySum' || aggregation === 'dailySum' || aggregation === 'energyRating';

  // define colors for utilization hours
  const utilizationZones = color => {
    if (statistics === STATISTICS_TYPES.perHour) {
      const [startHour, endHour] = utilizationHours;
      return {
        zones: [{ value: startHour, color: rgba(color, 0.3) }, { value: endHour, color }, { color: rgba(color, 0.3) }],
        zoneAxis: 'x',
      };
    }
    return { zones: [{ color }], zoneAxis: 'x' };
  };

  const utilizationSeries = () => {
    const series = [];

    if (sensorData.AVERAGE) {
      series.push({
        data: sensorData.AVERAGE,
        name: t('Average'),
        color: theme.colors.midnight,
        _unit: '%',
        _showTooltipForZeroValue: true,
        ...utilizationZones(theme.colors.midnight),
      });
    }

    if (sensorData.PEAK_DAY) {
      series.push({
        data: sensorData.PEAK_DAY,
        name: t('Peak Day'),
        color: theme.colors.orange,
        _unit: '%',
        ...utilizationZones(theme.colors.orange),
      });
    }

    if (sensorData.PEAK_HOUR) {
      series.push({
        data: sensorData.PEAK_HOUR,
        name: t('Peak Hour'),
        color: theme.colors.amethyst,
        _unit: '%',
        ...utilizationZones(theme.colors.amethyst),
      });
    }

    return series;
  };

  let chart = null;
  if (sensorType) {
    switch (sensorType.graphType) {
      case 'air_quality':
      case 'technical_performance':
        chart = (
          <AirQualityChart
            {...sharedProps}
            histories={sensorData}
            minThreshold={90}
            startDate={start}
            endDate={end}
            minXRange={timespan}
            maxX={maxX}
            unit={sensorType.unit}
            aggregation={aggregation}
            openingHours={openingHours}
            buildingMeta={buildingMeta}
            timezone={timezone}
            functionalLocation={functionalLocation.functionalLocation}
          />
        );
        break;

      case 'iot':
      case 'performance':
        chart = (
          <PerformanceChart
            {...sharedProps}
            {...getPerformanceChartConfig({
              sensorTypeName: sensorType.name,
              sensorTypeUnit: sensorType.unit,
              graphType: sensorType.graphType,
              sensorData,
              sensorMeta,
              timespan,
              maxX,
              getThreshold,
            })}
          />
        );
        break;

      case 'cleaning':
        chart = <CleaningChart {...sharedProps} data={sensorData} />;
        break;

      case 'boolean':
        chart = (
          <IoTChart
            {...booleanProps}
            histories={sensorData}
            activeTimes={null}
            alarms={EMPTY_ARRAY}
            minXRange={timespan}
            maxX={maxX}
            yMax={1}
            type="area"
            hideDecimals
            trueValue={booleanLabels?.[0] ?? getGraphTypeBooleanValueText(true, sensorType.unit, t)}
            falseValue={booleanLabels?.[1] ?? getGraphTypeBooleanValueText(false, sensorType.unit, t)}
            tooltipDateFormatter={getTooltipHeaderFormatter(aggregation, 'boolean')}
            keepOriginalTimestamps={true}
          />
        );
        break;

      case 'presence':
        if (aggregation === 'raw') {
          chart = (
            <IoTChart
              {...sharedProps}
              histories={sensorData}
              activeTimes={null}
              alarms={EMPTY_ARRAY}
              minXRange={timespan}
              maxX={maxX}
              yMax={1}
              type="area"
              hideDecimals
              trueValue={t('Occupied')}
              falseValue={t('Available')}
            />
          );
        } else if (statistics === STATISTICS_TYPES.perSensor) {
          chart = (
            <StatisticsBarChart
              {...sharedProps}
              type="bar"
              hideLegend
              simpleTooltip
              series={[
                {
                  data: sensorData,
                  name: '',
                  _unit: '%',
                  _showTooltipForZeroValue: true,
                },
              ]}
              xAxis={{
                type: 'category',
                ...(sensorData &&
                  sensorData.length > 10 && {
                    scrollbar: { enabled: true },
                    max: Math.min(sensorData.length, 10) - 1,
                  }),
              }}
              yTickInterval={25}
              yMax={100}
              colors={[theme.colors.midnight]}
              plotBorderWidth="0"
            />
          );
        } else {
          chart = (
            <BarChart
              {...sharedProps}
              legendAlign="left"
              series={utilizationSeries()}
              xAxisType="category"
              yTickInterval={25}
              yMax={100}
              yTitle={t('Utilization Percentage')}
              noRounding
            />
          );
        }
        break;

      case 'bar':
        chart = (
          <BarChart
            {...sharedProps}
            hideLegend
            series={[
              {
                data: sensorData,
                _unit: sensorType.unit,
                _showTooltipForZeroValue: true,
                dataGrouping: getDataGrouping(aggregation),
              },
            ]}
            tickInterval={tickInterval}
            hideTime={hideTime}
            labelFormatter={getLabelFormatter(tickInterval)}
            getTooltipHeader={getTooltipHeaderFormatter(aggregation)}
            minXRange={timespan}
            maxX={maxX}
            noRounding={isStatisticsSensor(sensorType.name)}
          />
        );
        break;

      default:
        chart = (
          <IoTChart
            {...sharedProps}
            histories={sensorData}
            activeTimes={null}
            alarms={EMPTY_ARRAY}
            minXRange={timespan}
            maxX={maxX}
            unit={sensorType.unit}
          />
        );
        break;
    }
  }

  return (
    <ChartContainer smallHeight={smallHeight} windowHeight={windowHeight} height={height} ref={containerRef}>
      {!loading && !sensorData ? <StyledNoDataInfo>{t('No data available.')}</StyledNoDataInfo> : chart}

      {!loading && !isEmpty(sensorData) && allowAnnotationsAdminTools && <AnnotationInfo />}

      {isZoomed && <ResetZoomButton onClick={onResetZoom}>{t('Reset zoom')}</ResetZoomButton>}

      <SensorAnnotations
        annotationPopover={annotationPopover}
        createAnnotationAt={createAnnotationAt}
        sensor={sensor}
        functionalLocationKey={functionalLocation.functionalLocation}
        onClose={onCloseAnnotations}
        containerRef={containerRef}
      />
    </ChartContainer>
  );
};

export default withTheme(SensorBody);

SensorBody.defaultProps = {
  loading: false,
  hasSensorOptions: false,
  maxData: undefined,
  minData: undefined,
  smallHeight: false,
};

SensorBody.propTypes = {
  sensor: PropTypes.object.isRequired,
  sensorData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sensorType: PropTypes.object.isRequired,
  sensorTitle: PropTypes.string,
  parameterModel: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  annotations: PropTypes.array,
  loading: PropTypes.bool,
  hasSensorOptions: PropTypes.bool,
  aggregation: PropTypes.string,
  sensorMeta: PropTypes.arrayOf(PropTypes.object),
  smallHeight: PropTypes.bool,
  statistics: PropTypes.oneOf(values(STATISTICS_TYPES)),
  getThreshold: PropTypes.func,
  openingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  buildingMeta: PropTypes.array,
  functionalLocation: PropTypes.object,
  utilizationHours: PropTypes.array,
  height: PropTypes.string,
  timezone: PropTypes.string,
  allowAnnotationsAdminTools: PropTypes.bool,
  updateParameters: PropTypes.func,
  publicViewId: PropTypes.string,
  booleanLabels: PropTypes.array,
};
