const withLabelOptions = (annotations, hasNavigator) =>
  annotations?.map(annotation => ({
    ...annotation,
    labelOptions: {
      ...annotation.labelOptions,
      y: hasNavigator ? -88 : -16, // position labels over the Navigator.
    },
  }));

export default withLabelOptions;
