import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import parseISO from 'date-fns/parseISO';
import { format, getMonthsShort } from 'utils/Date/dateFormatter';

import BarChart from 'components/Charts/BarChart';
import Section from 'components/Section/Section';
import SectionHeader from 'components/Section/SectionHeader';
import SectionTabSelector from 'components/SectionTabs/SectionTabSelector';
import { Footer, ZoomLink } from 'components/Charts/ZoomableChart';
import IoTChart from 'components/Charts/IoTChart';
import { useTranslations } from 'decorators/Translations/translations';

const Year = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.bold};
  padding: var(--size-md) 0;
`;

const StyledSectionTabSelector = styled(SectionTabSelector)`
  padding-top: 0;
  margin-bottom: var(--size-md);
`;

const ChartSection = ({
  title,
  series,
  year,
  changeYear,
  loading,
  tabOptions,
  selectedTab,
  onTabChange,
  lineChart,
  stacked = true,
  hideTooltipTotal = false,
}) => {
  const [t] = useTranslations();
  const theme = useTheme();

  const colors =
    series.length === 1
      ? [theme.colors.midnight]
      : [theme.colors.orange, theme.colors.cerulean, theme.colors.midnight, theme.colors.amethyst];

  return (
    <Section data-test-id="chart-container">
      {title && <SectionHeader noBorder title={title} size="small" />}
      {tabOptions && (
        <SectionHeader>
          <StyledSectionTabSelector
            left
            large
            t={t}
            options={tabOptions}
            model={{ selectedTab }}
            property="selectedTab"
            onTabChange={onTabChange}
          />
        </SectionHeader>
      )}
      {!lineChart && (
        <BarChart
          t={t}
          type="column"
          categories={getMonthsShort()}
          hideDecimals
          valueDecimals={0}
          noRounding
          showTooltipTotal={!hideTooltipTotal && series.length > 1}
          colors={colors}
          plotBorderWidth="0"
          legendAlign="left"
          hideLegend={series.length === 1}
          loading={loading}
          series={series}
          stacked={stacked}
          labelStyle={{
            color: theme.colors.darkGray,
            fontWeight: theme.font.weight.bold,
            fontFamily: theme.font.family.cairo,
            letterSpacing: '1px',
            textTransform: 'uppercase',
          }}
        />
      )}
      {lineChart && (
        <IoTChart
          t={t}
          type="spline"
          histories={series}
          loading={loading}
          alarms={[]}
          unit={t('min')}
          yAxisTitle={t('Minutes')}
          yAxisLabelFormat={`{value:.0f}`}
          yAxisVerticalTitle
          tooltipDateFormatter={timestamp => format(parseISO(timestamp), 'MMM yyyy')}
          xAxisLabelFormat={`{value:%b}`}
        />
      )}
      {!loading && (
        <Footer>
          <ZoomLink left onClick={() => changeYear(year - 1)}>
            {`← ${t('Previous year')}`}
          </ZoomLink>
          <Year>{year}</Year>
          <ZoomLink onClick={() => changeYear(year + 1)}>{`${t('Next year')} →`}</ZoomLink>
        </Footer>
      )}
    </Section>
  );
};

ChartSection.propTypes = {
  title: PropTypes.string,
  year: PropTypes.number.isRequired,
  changeYear: PropTypes.func.isRequired,
  series: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  tabOptions: PropTypes.array,
  selectedTab: PropTypes.string,
  onTabChange: PropTypes.func,
  lineChart: PropTypes.bool,
  stacked: PropTypes.bool,
  hideTooltipTotal: PropTypes.bool,
};

export default ChartSection;
