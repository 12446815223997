import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import * as yup from 'yup';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputTextArea from 'components/Form/InputTextArea';
import InputText from 'components/Form/InputText';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';
import SubformComponent from './SubformComponent';

import { InputType } from 'containers/Application/ServiceModule/ServiceModuleForm/utils/constants';
import {
  getFunctionalLocationText,
  getFunctionalLocationOptions,
  getSystemOptions,
  getCategoryOptions,
} from 'containers/Application/ServiceModule/ServiceModuleForm/utils/options';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { SmartViewPriority } from 'constants/maintenance';

const CategoryButtonGroup = styled.div`
  margin: calc(-1 * var(--size-xxs)) 0 var(--size-xs);
`;

const combineCategories = (systemOptions, categoryOptions) =>
  sortBy([].concat(systemOptions).concat(categoryOptions), ['label']);

export const createSchema = t =>
  yup.object().shape({
    functionalLocation: yup.string().nullable().label(t('Location')).required(),
    categoryInput: yup.string().nullable().oneOf([InputType.DROPDOWN, InputType.TEXT]),
    system: yup
      .string()
      .nullable()
      .label(t('Category'))
      .when('categoryInput', (categoryInput, schema) =>
        categoryInput === InputType.DROPDOWN ? schema.required() : schema
      ),
    discipline: yup
      .string()
      .nullable()
      .label(t('Category'))
      .when('categoryInput', (categoryInput, schema) =>
        categoryInput === InputType.TEXT ? schema.required() : schema
      ),
    title: yup.string().nullable().label(t('Title')).required(),
    description: yup.string().nullable().label(t('Description')),
    locationDetails: yup.string().nullable().label(t('Detailed location')),
    partOfFixedPrice: yup
      .mixed()
      .nullable()
      .oneOf([true, false, 'null', null])
      .label(t('Is this order part of the fixed price?')),
    priority: yup
      .mixed()
      .nullable()
      .label(t('Priority'))
      .oneOf([null, ...Object.values(SmartViewPriority)]),
  });

const OrderForm = ({
  t,
  id,
  model,
  onFormChange,
  functionalLocations,
  systems,
  categories,
  isCreationMode,
  isPortfolioLevel,
  isMaintenancePlan = false,
  validationErrors,
}) => (
  <InputForm
    onPropertyChange={onFormChange}
    model={model}
    FormComponent={SubformComponent}
    validationErrors={validationErrors}
  >
    <InputRow dense fullRow>
      <InputLabel text={t('Location')} required />
      {isCreationMode && isPortfolioLevel && (
        <InputSelectDropdown
          property="functionalLocation"
          options={getFunctionalLocationOptions(t, functionalLocations)}
          t={t}
          required
        />
      )}
      {(!isCreationMode || !isPortfolioLevel) && (
        <InputText
          value={getFunctionalLocationText(t, functionalLocations, model.functionalLocation)}
          readonly
          disabled
        />
      )}
    </InputRow>
    <InputRow dense fullRow>
      <InputLabel
        text={t('Category')}
        t={t}
        ctxHelp={`${CTXHELP_PREFIX} Order Category`}
        ctxHelpPosition="right"
        required
      />
      <CategoryButtonGroup>
        <RadioButtonGroup id={id} row name="categoryInput" value={model.categoryInput} onChange={onFormChange}>
          <RadioButton label={t('Select from list')} value={InputType.DROPDOWN} disabled={!isCreationMode} />
          <RadioButton label={t('Write category')} value={InputType.TEXT} disabled={!isCreationMode} />
        </RadioButtonGroup>
      </CategoryButtonGroup>
      {model.categoryInput === InputType.DROPDOWN && (
        <InputSelectDropdown
          property="system"
          options={
            model.functionalLocation
              ? combineCategories(
                  getSystemOptions(t, systems, model.functionalLocation),
                  getCategoryOptions(categories)
                )
              : []
          }
          placeholder={
            !isCreationMode ? '-' : model.functionalLocation ? undefined : t('Select functional location first')
          }
          t={t}
          disabled={!isCreationMode}
          required
        />
      )}
      {model.categoryInput === InputType.TEXT && (
        <InputText property="discipline" placeholder={t('Category')} required />
      )}
    </InputRow>
    <InputRow dense fullRow>
      <InputLabel text={t('Title')} required />
      <InputText property="title" placeholder={t('Title')} required />
    </InputRow>
    <InputRow dense fullRow>
      <InputLabel text={t('Description')} />
      <InputTextArea property="description" placeholder={`${t('Give a short outline of the issue')}...`} />
    </InputRow>
    {!isMaintenancePlan && (
      <InputRow dense fullRow>
        <InputLabel text={t('Detailed location')} />
        <InputText property="locationDetails" placeholder={`${t('For example floor or space')}...`} />
      </InputRow>
    )}
    <InputRow dense fullRow>
      <InputLabel text={t('Is this order part of the fixed price?')} />
      <RadioButtonGroup id={id} name="partOfFixedPrice" value={model.partOfFixedPrice} onChange={onFormChange}>
        <RadioButton label={t('Yes')} value={true} />
        <RadioButton label={t('No')} value={false} />
      </RadioButtonGroup>
    </InputRow>
    {!isMaintenancePlan && (
      <InputRow dense fullRow>
        <InputLabel text={t('Priority')} />
        <RadioButtonGroup id={id} name="priority" value={model.priority} onChange={onFormChange}>
          <RadioButton label={t('Low')} value={SmartViewPriority.LOW} />
          <RadioButton label={t('Medium')} value={SmartViewPriority.MEDIUM} />
          <RadioButton label={t('High')} value={SmartViewPriority.HIGH} />
          <RadioButton label={t('Urgent')} value={SmartViewPriority.URGENT} />
        </RadioButtonGroup>
      </InputRow>
    )}
  </InputForm>
);

OrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  functionalLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  systems: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCreationMode: PropTypes.bool.isRequired,
  isPortfolioLevel: PropTypes.bool.isRequired,
  isMaintenancePlan: PropTypes.bool,
  validationErrors: PropTypes.object.isRequired,
};

export default OrderForm;
