import omit from 'lodash/omit';

import { InputType, FORM_ONLY_FIELDS, UI_ONLY_FIELDS } from './constants';
import { findSystem, findCategoryByName, findTopLevelFunctionalLocation, findCategoryById } from './finders';
import { getDisciplineLabel } from './options';

/**
 * Omit FORM_ONLY_FIELDS from model.
 * Omit also fields that the service API doesn't want.
 */
export const omitFormOnlyProperties = model => omit(model, [...Object.keys(FORM_ONLY_FIELDS), ...UI_ONLY_FIELDS]);

/**
 * Set from original object to form model
 */

export const setSupplierFields = (original, model, options) => {
  // hide supplier fields if supplier is same as contact person
  if (
    original.supplierName &&
    original.supplierName === original.requestedByPerson &&
    original.supplierPhoneNumber === original.requestedByPhoneNumber &&
    original.supplierEmail === original.requestedByEmail
  ) {
    model.supplierInput = InputType.EMPTY;
  } else if (original.supplierName && options.suppliers) {
    const supplierFromOptions = options.suppliers.find(
      supplier =>
        supplier.name === original.supplierName &&
        supplier.email === original.supplierEmail &&
        supplier.phoneNumber === original.supplierPhoneNumber
    );
    if (supplierFromOptions) {
      model.supplierId = supplierFromOptions.id;
      model.supplierInput = InputType.DROPDOWN;
    }
  }

  // uncheck sending methods
  if (original.supplierName && !original.supplierPhoneNumber) {
    model.sendSMSInput = false;
  }
  if (original.supplierName && !original.supplierEmail) {
    model.sendEmailInput = false;
  }
};

export const setCategoryFields = (original, model, options) => {
  const systemLevelFunctionalLocation =
    original.functionalLocation && findSystem(options.systems, original.functionalLocation);
  const customerCategory = original.discipline && findCategoryByName(options.categories, original.discipline);

  if (systemLevelFunctionalLocation) {
    model.system = systemLevelFunctionalLocation.functionalLocation;
    const topLevelFunctionalLocation = findTopLevelFunctionalLocation(
      options.functionalLocations,
      systemLevelFunctionalLocation
    );
    if (topLevelFunctionalLocation) {
      model.functionalLocation = topLevelFunctionalLocation.functionalLocation;
    }
  } else if (customerCategory) {
    model.system = customerCategory.id;
  } else if (original.discipline) {
    model.categoryInput = InputType.TEXT;
  }
};

export const setAllowRejectionField = (original, model) => {
  model.allowRejection = original.allowRejection || false;
};

export const setStatusField = (original, model) => {
  if (!original.status || original.status === 'Request') {
    model.status = 'Draft';
  }
};

export const setContactFields = (original, model, profile) => {
  // fill in contact person fields from profile
  if (!original.requestedByPerson) {
    model.requestedByPerson = profile.fullName;
    model.requestedByEmail = profile.email;
    model.requestedByPhoneNumber = profile.phoneNumber;
  }
};

export const setFunctionalLocationField = (original, model, functionalLocationId) => {
  if (!original.functionalLocation && functionalLocationId) {
    model.functionalLocation = functionalLocationId;
  }
};

export const setPartOfFixedPriceField = (original, model) => {
  if (original.partOfFixedPrice === null) {
    model.partOfFixedPrice = 'null';
  }
};

/**
 * Set from form model to upsertable object
 */

export const setFunctionalLocationAndDisciplineForUpsert = (model, upsertable, systems, categories) => {
  const systemLevelFunctionalLocation =
    model.categoryInput === InputType.DROPDOWN && model.system && findSystem(systems, model.system);
  const customerCategory =
    model.categoryInput === InputType.DROPDOWN && model.system && findCategoryById(categories, model.system);

  // set functional location to be discipline, if discipline is system level FL, and make discipline more human readable
  if (systemLevelFunctionalLocation) {
    upsertable.functionalLocation = model.system;
    upsertable.discipline = getDisciplineLabel(t => t, systemLevelFunctionalLocation);
  } else if (customerCategory) {
    upsertable.discipline = customerCategory.category;
  }
};

export const setContactForUpsert = (model, upsertable) => {
  upsertable.requestedByEmail = model.requestedByEmail?.trim();
};

export const setSupplierForUpsert = (model, upsertable) => {
  // copy author fields to supplier, if supplier is "Same as author"
  if (model.supplierInput === InputType.EMPTY) {
    upsertable.supplierName = model.requestedByPerson;
    upsertable.supplierPhoneNumber = model.requestedByPhoneNumber;
    upsertable.supplierEmail = model.requestedByEmail;
  }

  // apply the selected sending methods
  upsertable.supplierPhoneNumber = (model.sendSMSInput && upsertable.supplierPhoneNumber) || undefined;
  upsertable.supplierEmail = (model.sendEmailInput && upsertable.supplierEmail) || undefined;
};

export const setPartOfFixedPriceForUpsert = (model, upsertable) => {
  if (model.partOfFixedPrice === undefined || model.partOfFixedPrice === 'null') {
    upsertable.partOfFixedPrice = null;
  }
};

export const setAllowRejectionForUpsert = (model, upsertable) => {
  upsertable.allowRejection = true;
};

export const fixPhoneNumbers = upsertable => {
  if (upsertable.supplierPhoneNumber) {
    upsertable.supplierPhoneNumber = upsertable.supplierPhoneNumber.replace(/[^+\d]+/g, '');
  }

  if (upsertable.requestedByPhoneNumber) {
    upsertable.requestedByPhoneNumber = upsertable.requestedByPhoneNumber.replace(/[^+\d]+/g, '');
  }
};
