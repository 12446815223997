import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseButton from '../BaseButton/BaseButton';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

export const StyledButton = styled(BaseButton)`
  background: var(--btn-default-bg);
  border-color: var(--btn-default-bc);
  ${props => (props.noPadding ? 'padding: 0;' : '')}
  width: ${props =>
    props.noPadding && (props.large ? props.theme.button.large.height : props.theme.button.small.height)};
  min-width: max-content;
  font-weight: ${props => props.theme.font.weight.normal};
  color: var(--btn-default-fg);

  &:hover {
    background: var(--btn-default-bg-hl);
    border-color: var(--btn-default-bc-hl);
  }
`;

const SecondaryButton = ({ iconName, loading, children, ...buttonProps }) => (
  <StyledButton secondary noPadding={!children} {...buttonProps}>
    {iconName && (
      <ButtonIcon
        iconName={iconName}
        loading={loading}
        marginRight={!!children}
        large={buttonProps.large}
        loaderColor="BLACK"
      />
    )}
    {children}
  </StyledButton>
);
SecondaryButton.displayName = 'SecondaryButton';

SecondaryButton.propTypes = {
  iconName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
};

export default SecondaryButton;
