import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import styled, { withTheme } from 'styled-components';
import memoizeOne from 'memoize-one';
import { parseISO, format } from 'date-fns';

import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import Section from 'components/Section/Section';
import { searchUsername, resendVerification } from 'redux/modules/index';
import { Icon } from 'components/Icon/Icon';
import { isExtenalADUser, isCaverionNetOrComUser } from 'utils/profile';
import { InputRow, InputLabel, InputText, Infotip, InputSelectDropdown, InputBooleanCheckbox } from 'components/index';
import { Label } from 'components/Form/InputLabel';
import InputDate from 'components/Form/InputDate/InputDate';
import { Textarea } from 'components/Form/InputTextArea';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import SensorAlarmEmailsForm from './SensorAlarmEmailsForm';
import { Division } from 'types/Division';
import { divisionName } from 'containers/Application/Modules/ContactModule/utils';
import PrimaryButton from 'components/Button/PrimaryButton';
import SecondaryButton from 'components/Button/SecondaryButton';
import useNotification from 'decorators/Notification/useNotification';
import CheckboxGroup from 'components/Form/CheckboxGroup';

export const languageOptions = memoizeOne(t => [
  { value: 'en', label: t('English') },
  { value: 'fi', label: t('Finnish') },
  { value: 'sv', label: t('Swedish') },
  { value: 'nb', label: t('Norwegian') },
  { value: 'da', label: t('Danish') },
  { value: 'lt', label: t('Lithuanian') },
  { value: 'de', label: t('German') },
  { value: 'et', label: t('Estonian') },
]);

export const divisionOptions = memoizeOne(t =>
  Object.values(Division).map(division => ({
    value: division,
    label: divisionName(division, t),
  }))
);

export const roleOptions = (t, role) =>
  [
    { value: 'user', label: t('Normal user') },
    { value: 'customer-admin', label: t('Customer admin') },
    { value: 'caverion-user', label: t('Caverion user') },
    { value: 'caverion-admin', label: t('Caverion admin') },
  ]
    // For customer-admin, filter out global Caverion roles.
    .filter(x => role !== 'customer-admin' || (x.value !== 'caverion-admin' && x.value !== 'caverion-user'));

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h2`
  padding: var(--size-md) 0;
  font-size: ${props => props.theme.font.size.lg};
`;
Title.displayName = 'Title';

const FieldErrorInfoTip = styled(Infotip)`
  display: block;
`;

const NonVerified = styled.div`
  padding: 0.5em;
`;

const Success = styled.span`
  color: ${props => props.theme.colors.emerald};
`;

const Failure = styled.span`
  color: ${props => props.theme.colors.radicalRed};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

const ProfileActionButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  ${props => props.theme.media.portrait`
    top: -44px;
  `};
`;
ProfileActionButtons.displayName = 'ProfileActionButtons';

const ProfileActionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-size: ${props => props.theme.font.size.xs};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.input.font.color.default};
  padding-left: var(--size-md);
  cursor: pointer;
`;
ProfileActionButton.displayName = 'ProfileActionButton';

const IconWrapper = styled.div`
  margin-right: var(--size-sm);
`;

const ResendVerificationButtonContainer = styled.div`
  float: right;
`;

const SSOWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSOLabelInfo = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  color: ${props => props.theme.colors.darkGray};
  margin-bottom: var(--size-sm);
`;

export const Settings = props => {
  const {
    theme,
    t,
    model = {},
    isAdmin,
    isCaverionAdmin,
    isCaverionAdminOrUser,
    newProfile = false,
    onChange,
    searchUsername,
    usernameExists,
    resentVerification = {},
    resendVerification,
    resendingVerification,
    profile,
    handleDeleteClick,
    handleDuplicateClick,
    isAdminOrCaverionUser,
  } = props;

  const [sensorAlarmsDialogOpen, setSensorAlarmsDialogOpen] = useState(false);
  const { showNotification, renderedNotification } = useNotification();

  const toggleSensorAlarmsDialog = event => {
    if (event?.preventDefault && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    setSensorAlarmsDialogOpen(!sensorAlarmsDialogOpen);
  };

  const checkExistingUsername = model => {
    const username = model.username;
    if (!newProfile || !username) {
      return;
    }
    searchUsername(username);
  };

  const renderResendVerification = () => {
    if (!isCaverionAdmin && !isAdmin) {
      return <NonVerified>{t('No')}</NonVerified>;
    }

    let content = null;
    if (isEmpty(Object.keys(resentVerification)) || resentVerification[model.id] === undefined) {
      content = (
        <ResendVerificationButtonContainer>
          <SecondaryButton loading={resendingVerification} onClick={() => resendVerification(model.id)}>
            {t('Resend verification email')}
          </SecondaryButton>
        </ResendVerificationButtonContainer>
      );
    } else if (resentVerification[model.id]) {
      content = (
        <ResendVerificationButtonContainer>
          <Success>{t('Resent verification email!')}</Success>
        </ResendVerificationButtonContainer>
      );
    } else if (resentVerification[model.id] === false) {
      content = <Failure>{t('Sending verification email failed!')}</Failure>;
    }
    return (
      <NonVerified>
        {t('No')} {content}
      </NonVerified>
    );
  };

  const renderVerificationLink = () => {
    if ((isCaverionAdmin || isAdmin) && resentVerification?.[model.id]?.verificationLink) {
      return (
        <div>
          <ResendVerificationButtonContainer>
            <CopyToClipboard textToCopy={resentVerification[model.id].verificationLink} label="copy link" />
          </ResendVerificationButtonContainer>
          <Label htmlFor="verificationLink">{t('Verification link')}</Label>
          <Textarea id="verificationLink" disabled value={resentVerification[model.id].verificationLink} />
        </div>
      );
    }
  };

  const hasExternalADEmail = model && isExtenalADUser(model.email);
  const allowEmailEdit = newProfile || !hasExternalADEmail;
  const allowDelete =
    model && model.id && (isCaverionAdmin || model.id === profile.id || model.creatorId === profile.id);
  const allowDuplicate = model && model.id && isAdmin;
  const invalidUsername = (newProfile && model && model.username && usernameExists[model.username]) || false;

  return (
    <Fragment>
      <Section>
        <Title>{t('Profile Information')}</Title>
        <ProfileActionButtons>
          {allowDuplicate && (
            <ProfileActionButton onClick={handleDuplicateClick} type="button">
              <IconWrapper>
                <Icon name="plus" fill={theme.colors.emerald} size="SMALL" />
              </IconWrapper>
              {t('Duplicate Account')}
            </ProfileActionButton>
          )}
          {allowDelete && (
            <ProfileActionButton onClick={handleDeleteClick} type="button">
              <IconWrapper>
                <Icon name="trash-can" fill={theme.colors.radicalRed} size="SMALL" />
              </IconWrapper>
              {t('Delete Account')}
            </ProfileActionButton>
          )}
        </ProfileActionButtons>
        <Container>
          <InputRow required>
            <InputLabel id="firstName" text={t('First name')} />
            <InputText
              model={model}
              onChange={onChange}
              id="firstName"
              property="firstName"
              placeholder={t("User's first name")}
              readonly={!newProfile}
              onBlur={() => checkExistingUsername(model)}
            />
          </InputRow>
          <InputRow required>
            <InputLabel id="lastName" text={t('Last name')} />
            <InputText
              model={model}
              onChange={onChange}
              id="lastName"
              property="lastName"
              placeholder={t("User's last name")}
              readonly={!newProfile}
              onBlur={() => checkExistingUsername(model)}
            />
          </InputRow>
          <InputRow required>
            <InputLabel id="email" text={t('Email')} />
            <InputText
              model={model}
              onChange={onChange}
              id="email"
              property="email"
              type="email"
              placeholder={t("User's private email address")}
              readonly={!isAdmin || !allowEmailEdit}
              onBlur={() => checkExistingUsername(model)}
            />
          </InputRow>
          <InputRow>
            <InputLabel id="phoneNumber" text={t('Phone number')} extraText={t('use country code')} />
            <InputText
              model={model}
              onChange={onChange}
              id="phoneNumber"
              property="phoneNumber"
              autoComplete="section-contact tel"
              type="tel"
              inputProps={{ pattern: '^[+][0-9 ]*$' }}
              placeholder={t("User's phone number")}
            />
          </InputRow>
          <InputRow>
            <InputLabel id="username" text={t('Username')} />
            {invalidUsername ? (
              <FieldErrorInfoTip
                text={
                  hasExternalADEmail
                    ? t('User already exists')
                    : [t('Username already exists.'), t('Please enter a different first or last name.')]
                }
                alwaysVisible
              >
                <InputText
                  model={model}
                  id="username"
                  property="username"
                  readonly
                  placeholder={t("User's login name gets generated here")}
                  invalid
                />
              </FieldErrorInfoTip>
            ) : (
              <InputText
                model={model}
                id="username"
                property="username"
                readonly
                placeholder={t("User's login name gets generated here")}
              />
            )}
          </InputRow>
          {model?.verified && (
            <InputRow>
              <InputLabel id="verified" text={t('Verified on')} />
              <InputText id="verified" value={format(parseISO(model.verified), 'yyyy-MM-dd HH:mm:ss:SSS z')} readonly />
            </InputRow>
          )}
          {!model?.verified && model?.id && !hasExternalADEmail && (
            <InputRow>
              <InputLabel id="verified" text={t('Verified on')} />
              {renderResendVerification()}
              {renderVerificationLink()}
            </InputRow>
          )}
          <InputRow>
            <InputLabel id="role" text={t('Role')} />
            <InputSelectDropdown
              model={model}
              onChange={onChange}
              property="role"
              options={roleOptions(t, profile.role)}
              t={t}
              disabled={!isAdmin}
              clearable={false}
            />
          </InputRow>
          <InputRow>
            <InputLabel id="division" text={t('Division')} />
            <InputSelectDropdown
              model={model}
              onChange={onChange}
              property="division"
              options={divisionOptions(t)}
              t={t}
              disabled={!isAdminOrCaverionUser}
              clearable={false}
              required
            />
          </InputRow>
          {model?.createdBy && (
            <InputRow>
              <InputLabel id="createdBy" text={t('Created by')} />
              <InputText model={model} property="createdBy" readonly />
            </InputRow>
          )}
        </Container>
      </Section>
      <Section>
        <Title>{t('Settings')}</Title>
        <Container>
          <InputRow>
            <InputLabel id="language" text={t('Language')} />
            <InputSelectDropdown
              model={model}
              onChange={onChange}
              property="language"
              options={languageOptions(t)}
              t={t}
              clearable={false}
            />
          </InputRow>
          {isCaverionAdmin && (
            <Fragment>
              <InputRow>
                <InputLabel id="giosgId" text={t('Giosg id')} />
                <InputText
                  model={model}
                  onChange={onChange}
                  id="giosgId"
                  property="giosgId"
                  placeholder={t('Giosg chat id')}
                />
              </InputRow>
              <InputRow>
                <InputLabel id="serviceOrderStartDate" text={t('Show service orders created after')} />
                <InputDate
                  model={model}
                  onChange={onChange}
                  id="serviceOrderStartDate"
                  property="serviceOrderStartDate"
                  clearable
                />
              </InputRow>
              <InputRow>
                <InputLabel id="documentStartDate" text={t('Show documents created after')} />
                <InputDate
                  model={model}
                  onChange={onChange}
                  id="documentStartDate"
                  property="documentStartDate"
                  clearable
                />
              </InputRow>
            </Fragment>
          )}
          {model?.id && (
            <InputRow>
              <InputLabel
                id="ccAlarms"
                text={t('Alarm Mailing List')}
                ctxHelp={`${CTXHELP_PREFIX} Alarm mailing list`}
                t={t}
              />
              <ButtonContainer>
                <PrimaryButton add large onClick={toggleSensorAlarmsDialog}>
                  {`${t('Recipients')} (${model.ccAlarms?.length || 0})`}
                </PrimaryButton>
              </ButtonContainer>
            </InputRow>
          )}
          {isCaverionAdminOrUser && (
            <InputRow>
              <InputLabel
                id="usedForInfoscreen"
                text={t('Info screen profile')}
                ctxHelp={`${CTXHELP_PREFIX} Info screen profile`}
              />
              <InputBooleanCheckbox
                id="usedForInfoscreen"
                property="usedForInfoscreen"
                model={model}
                label={t('This user profile is used for info screen')}
                onChange={onChange}
              />
            </InputRow>
          )}
          {isCaverionAdmin && isCaverionNetOrComUser(model.email) && (
            <InputRow>
              <SSOWrapper>
                <InputLabel id="sso" text={t('Single Sign-On')} ctxHelp={`${CTXHELP_PREFIX} profile SSO`} />
                <SSOLabelInfo>{t('Enables SSO links for users if available.')}</SSOLabelInfo>
                <CheckboxGroup row>
                  <InputBooleanCheckbox
                    id="driveSSO"
                    property="driveSSO"
                    model={model}
                    label="Drive"
                    onChange={onChange}
                  />
                </CheckboxGroup>
              </SSOWrapper>
            </InputRow>
          )}
          {sensorAlarmsDialogOpen && (
            <SensorAlarmEmailsForm
              editProfileId={model.id}
              onClose={toggleSensorAlarmsDialog}
              showNotification={showNotification}
            />
          )}
          {renderedNotification}
        </Container>
      </Section>
    </Fragment>
  );
};

Settings.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.object,
  usernameExists: PropTypes.object,
  theme: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isCaverionAdmin: PropTypes.bool.isRequired,
  isCaverionAdminOrUser: PropTypes.bool,
  newProfile: PropTypes.bool,
  searchUsername: PropTypes.func.isRequired,
  resendVerification: PropTypes.func.isRequired,
  resentVerification: PropTypes.object,
  resendingVerification: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleDuplicateClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isAdminOrCaverionUser: PropTypes.bool,
};

Settings.defaultProps = {
  newProfile: false,
  model: {},
  resentVerification: {},
};

const mapStateToProps = state => ({
  resentVerification: state.profile.resentVerification,
  resendingVerification: state.profile.resendingVerification,
  profile: state.profile.profile,
});

const mapDispatchToProps = {
  searchUsername,
  resendVerification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withTheme(Settings));
