import { differenceInDays } from 'date-fns';

enum LabelGranularity {
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

const granularity = (dayDifference: number): LabelGranularity => {
  if (dayDifference > 365) return LabelGranularity.Month;
  if (dayDifference > 90) return LabelGranularity.Week;
  if (dayDifference > 1) return LabelGranularity.Day;
  return LabelGranularity.Hour;
};

const customChartGranularity = (start: Date, end: Date): LabelGranularity => granularity(differenceInDays(end, start));

export default customChartGranularity;
