import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import meanBy from 'lodash/meanBy';
import round from 'lodash/round';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import fromPairs from 'lodash/fromPairs';
import find from 'lodash/find';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import last from 'lodash/last';
import memoizeOne from 'memoize-one';
import {
  setSeconds,
  parseISO,
  addHours,
  differenceInDays,
  differenceInYears,
  differenceInWeeks,
  getISODay,
  differenceInHours,
  isValid,
  endOfDay,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'utils/Date/dateFormatter';

import { isStatisticsSensor, isEnergyRatingSensor, isEnergyConsumptionSensor } from 'utils/Data/values';
import { technicalPerformanceOrder } from 'utils/Data/performance';
import { getConditionValue } from 'components/Conditions/ConditionUtils';
import { getLocaleCompare } from 'utils/String/sort';

export const STATISTICS_TYPES = {
  perHour: 'PER_HOUR',
  perDay: 'PER_DAY',
  perSensor: 'PER_SENSOR',
};

export const DEFAULT_GROUP_NAME = 'default';

export const getSensorData = memoizeOne((sensor, sensorType, valuesBySensorId, latestValue, parameters) => {
  if (!sensor || !sensorType || !valuesBySensorId) {
    return [];
  }

  let data;
  const graphType = sensorType.graphType;

  switch (graphType) {
    case 'boolean':
    case 'presence':
      data = getPresenceDataset(sensor, valuesBySensorId);
      break;
    case 'bar':
      data = getBarDataset(sensor, valuesBySensorId);
      break;
    case 'cleaning':
      data = getCleaningDataset(sensor, valuesBySensorId);
      break;
    case 'iot':
    case 'air_quality':
    case 'technical_performance':
    case 'performance':
    default:
      data = getIoTDataset(sensor, valuesBySensorId);
  }

  if (shouldPushLatestValueToDataset(sensorType, latestValue, data, parameters)) {
    data.push(latestValue);
  }

  return data;
});

export const getIoTDataset = (sensor, valuesBySensorId) => {
  return getIoTSensorValues(sensor, valuesBySensorId) || [];
};

const getMeanValues = (values = []) => {
  if (values.every(value => value?.value === null)) {
    return null;
  }
  const filteredValues = values.filter(value => value?.value !== null);
  return meanBy(filteredValues, 'value');
};

export const getIoTSensorValues = (sensor, valuesBySensorId) => {
  if (sensor.id) {
    return valuesBySensorId && valuesBySensorId[sensor.id];
  }
  if (sensor.sensors && sensor.sensors.length === 1) {
    return valuesBySensorId && valuesBySensorId[sensor.sensors[0].id];
  }
  if (sensor.sensorIds) {
    const sensorValues = flatMap(sensor.sensorIds, sensorId => valuesBySensorId[sensorId]);
    const valuesByDate = groupBy(sensorValues, 'timestamp');
    const averageSensorValues = flatMap(valuesByDate, (values, timestamp) => ({
      value: getMeanValues(values),
      timestamp,
    }));
    return averageSensorValues;
  }
};

export const getAreaUtilizationDataset = (sensorValues, capacity) => {
  if (!isEmpty(sensorValues)) {
    return map(sensorValues, point => ({ ...point, value: round((point.value / capacity) * 100) }));
  }
  return [];
};

export const getPresenceDataset = (sensor, valuesBySensorId) => {
  const sensorValues =
    valuesBySensorId && map(valuesBySensorId[sensor.id], row => ({ ...row, value: row.value > 0 ? 1 : 0 }));
  return sensorValues || [];
};

export const getUtilizationStatisticsForSensor = (
  utilizationRateChartValues,
  sensor,
  sensors,
  statistics,
  isSummary = false
) => {
  const sensorsGrouped = sensors[0]?.isGroup;
  const groupName = sensorsGrouped ? sensor.name : DEFAULT_GROUP_NAME;
  const sensorId = isSummary ? 'summary' : sensor.isGroup ? 'all' : sensor.id;
  return utilizationRateChartValues[groupName]?.[sensorId]?.[statistics] || [];
};

export const getUtilizationDataset = (sensor, sensors, utilizationRateChartValues, statistics, areas) => {
  if (statistics === STATISTICS_TYPES.perSensor) {
    const isArea = sensor.sensorType && sensor.sensorType.name === 'presence_area';
    const hasSensorGroups = !sensors[0].id;

    const data = getUtilizationStatisticsForSensor(utilizationRateChartValues, sensor, sensors, statistics, true);

    const allSensors = hasSensorGroups && isArea ? flatMap(sensors, 'sensors') : sensors;

    /**
     * Data is per sensor id, so we need to map sensor ids to names.
     * Area sensors are mapped to area names.
     * In case of duplicate names, ids are added to the name string.
     */

    const nameMap = fromPairs(
      map(allSensors, sensor => {
        let newName = sensor.name;
        if (isArea) {
          const parentArea = find(areas, area => some(area.sensors, { id: sensor.id })) || { name: sensor.name };
          newName = parentArea.name;
        }
        return [sensor.id || 'all', newName];
      })
    );

    const isDuplicateNames = uniq(values(nameMap)).length < allSensors.length;

    return sortBy(
      map(data, ([id, value]) => {
        if (isDuplicateNames) {
          return [`${nameMap[id]} (${id})`, value];
        }
        return [nameMap[id], value];
      }),
      sensor => sensor && -sensor[1]
    );
  }

  return getUtilizationStatisticsForSensor(utilizationRateChartValues, sensor, sensors, statistics);
};

export const getBarDataset = (sensor, valuesBySensorId) => {
  const sensorValues = getSensorValues(sensor, valuesBySensorId);
  return map(sensorValues, point => [setSeconds(parseISO(point.timestamp), 0).valueOf(), point.value]);
};

export const getCleaningDataset = (sensor, valuesBySensorId) => {
  const sensorValues = getSensorValues(sensor, valuesBySensorId);
  return map(values(groupBy(sensorValues, point => format(parseISO(point.timestamp), 'yyyy-MM-dd'))), dayValues => [
    setSeconds(parseISO(dayValues[0].timestamp), 0).valueOf(),
    dayValues.length,
  ]);
};

export const getSensorValues = (sensor, valuesBySensorId) => {
  const sensorValues =
    valuesBySensorId &&
    filter(valuesBySensorId[sensor.id], value => value.aggregation !== 'latest' && !isNil(value.value));
  return sensorValues || [];
};

export const getSensor = memoizeOne((sensorId, buildingSensors) => {
  const sensorsWithChildren = reduce(
    buildingSensors,
    (buildingSensors, sensor) => buildingSensors.concat([sensor], sensor.children),
    []
  );
  return find(sensorsWithChildren, { id: sensorId });
});

export const getSensorTitle = (title, sensor) => {
  if (title) {
    return title;
  }

  if (sensor?.displayName) {
    return sensor.displayName;
  }

  if (sensor?.name) {
    return sensor.name;
  }
  return '-';
};

export const getSensorType = (sensor, sensorTypes) => {
  if (sensor) {
    if (sensor.sensorType && sensor.sensorType.aggregations) {
      return sensor.sensorType;
    }
    if (sensor.sensorTypeId) {
      return find(sensorTypes, { id: sensor.sensorTypeId });
    }
  }
  return {};
};

export const getUtilizationRateSelectorOptions = memoizeOne(
  (sensors, combinedSensor, utilizationRateChartValues, areas) => {
    if (!sensors || sensors.length === 0) {
      return [];
    }

    const sensorOptions = sensors.map(sensor => ({
      sensor,
      performance: getUtilizationStatisticsForSensor(utilizationRateChartValues, sensor, sensors, 'performance'),
    }));

    const compare = getLocaleCompare();
    const sortValue = sensorOption => {
      const sensor = sensorOption.sensor;
      if (sensor.sensorType?.name === 'presence_area') {
        const parentArea = areas.find(area => area.sensors?.some(areaSensor => areaSensor.id === sensor.id));
        if (parentArea) {
          return parentArea.name;
        }
      }
      return sensor.name;
    };

    const alphabeticallyOrdered = sensorOptions.sort((a, b) => compare(sortValue(a), sortValue(b)));

    if (combinedSensor) {
      const combinedSensorOption = {
        sensor: combinedSensor,
        performance: getUtilizationStatisticsForSensor(
          utilizationRateChartValues,
          combinedSensor,
          sensors,
          'performance'
        ),
      };
      return [combinedSensorOption, ...alphabeticallyOrdered];
    }

    return alphabeticallyOrdered;
  }
);

export const getPerformanceSelectorOptions = memoizeOne(
  (sensors, combinedSensor, valuesBySensorId, openingHours, timezone) => {
    if (!sensors || sensors.length === 0 || !valuesBySensorId) {
      return [];
    }

    const sensorOptions = sensors.map(sensor => ({
      sensor,
      performance: getAirQuality([sensor.id || get(sensor, 'sensors[0].id')], valuesBySensorId, openingHours, timezone),
    }));

    const sortedOptions = sortBy(sensorOptions, option => {
      const index = technicalPerformanceOrder.indexOf(get(option, 'sensor.sensorType.name'));
      return index !== -1 ? index : get(option, 'sensor.id');
    });

    if (combinedSensor) {
      const combinedSensorOption = {
        sensor: combinedSensor,
        performance: getAirQuality(combinedSensor.sensorIds, valuesBySensorId, openingHours, timezone),
      };
      return [combinedSensorOption, ...sortedOptions];
    }

    return sortedOptions;
  }
);

export const getLocalOpeningTime = (date, timeString, timezone) => {
  // Because of daylight saving time, opening hours are date dependant
  const dateTimeString = `${format(date, 'yyyy-MM-dd')}T${timeString}`;
  let timeObject;
  if (timezone) {
    timeObject = zonedTimeToUtc(dateTimeString, timezone);
  } else {
    timeObject = parseISO(dateTimeString);
  }
  if (timeObject && isValid(timeObject)) {
    return timeObject;
  }
  return null;
};

export const isBetweenOpeningHours = (value, openingHours, timezone, acceptAllAggregations = false) => {
  if (!acceptAllAggregations && value?.aggregation !== 'hourlyAverage' && value?.aggregation !== 'hourlySum') {
    return true;
  }
  const timestampDate = parseISO(value.timestamp);
  const day = getISODay(timestampDate) - 1;
  const startTime =
    openingHours?.[day]?.[0] != null ? getLocalOpeningTime(timestampDate, openingHours[day][0], timezone) : null;
  const endTime =
    openingHours?.[day]?.[1] != null ? getLocalOpeningTime(timestampDate, openingHours[day][1], timezone) : null;
  if (startTime === null && endTime === null) {
    return false;
  }
  // Include values that are equal or after the start time because values are timestamped to the beginning of the hour
  if ((startTime === null || startTime <= timestampDate) && (endTime === null || endTime > timestampDate)) {
    return true;
  }
  return false;
};

/**
 * Filter values that are timestamped outside of opening hours
 */
export const filterOpeningHourValues = memoizeOne((values, openingHours, timezone) => {
  if (!values || !values.length || !openingHours || !openingHours.length) {
    return values;
  }

  return values?.filter(value => isBetweenOpeningHours(value, openingHours, timezone));
});

export const getSensorSelectorOptions = memoizeOne(
  (sensors, latestValuesBySensorId, buildingMeta, theme, t, sensorAlarmsById) => {
    if (!sensors || sensors.length === 0 || !latestValuesBySensorId) {
      return [];
    }

    const sensorOptions = sensors.map(sensor => {
      const { value, color } = getConditionValue({
        sensor,
        parent: null,
        latestValueObject: latestValuesBySensorId[sensor.id],
        buildingMeta,
        theme,
        t,
        noDefaultSensor: false,
        isPerformance: false,
        alarm: sensorAlarmsById[sensor.id],
      });

      return {
        sensor,
        performance: value,
        backgroundColor: color,
      };
    });

    const compare = getLocaleCompare();

    return sensorOptions.sort((a, b) => compare(a.sensor.name, b.sensor.name));
  }
);

export const getAirQuality = (sensorIds, latestValues, openingHours, timezone) => {
  const airQualityValues = filterOpeningHourValues(
    filter(values(pick(latestValues, sensorIds)).flat(), value => value.value !== null),
    openingHours,
    timezone
  );
  return airQualityValues.length ? Math.round(meanBy(airQualityValues, 'value')) : undefined;
};

export const getUtilization = (values, utilizationHours) => {
  if (!isEmpty(values) && values[0].aggregation === 'hourlyUtilizationRate') {
    const [start, end] = map(utilizationHours.split('-'), time => +time.split(':')[0]) || [];

    const utilizationValues = filter(values, value => isBetweenUtilizationHours(value.timestamp, start, end));

    return isEmpty(utilizationValues) ? null : round(100 * meanBy(utilizationValues, 'value'));
  }
  return null;
};

export const isBetweenUtilizationHours = (timestamp, start, end) => {
  const dateObj = new Date(timestamp);
  const weekday = dateObj.getDay();
  const hour = dateObj.getHours();
  return weekday >= 1 && weekday <= 5 && hour >= start && hour < end;
};

// key values for a single presence sensor, which has raw values
export const getRawUtilization = memoizeOne((values, parameters, utilizationHours) => {
  const { startDatetime, endDatetime } = parameters;

  if (!isEmpty(values) && startDatetime && endDatetime) {
    const [startHour, endHour] = utilizationHours;
    const hourData = groupBy(values, value => format(parseISO(value.timestamp), 'yyyy-dd-HH'));

    let hours = 0;
    let utilizedHours = 0;

    let it = new Date(startDatetime.valueOf());
    const itEnd = new Date(endDatetime.valueOf());

    /**
     * Iterate from startDatetime to endDatetime by hour.
     * Increase hours only between opening hours on business days, and increase utilizedHours
     * if there is also an occupied data point.
     */
    while (it <= itEnd) {
      if (isBetweenUtilizationHours(it, startHour, endHour)) {
        hours++;
        if (some(hourData[format(it, 'yyyy-dd-HH')], value => value.value > 0)) {
          utilizedHours++;
        }
      }
      it = addHours(it, 1);
    }

    return {
      utilizationRate: (100 * utilizedHours) / hours,
      unusedHours: hours - utilizedHours,
    };
  }
  return {};
});

export const getAirQualityAggregation = (start, end) => {
  if (differenceInYears(end, start) > 0) {
    return 'monthlyAverage';
  } else if (differenceInWeeks(end, start) > 10) {
    return 'weeklyAverage';
  } else if (differenceInDays(end, start) > 3) {
    return 'dailyAverage';
  }
  return 'hourlyAverage';
};

export const getSuitableAggregation = (preferred, available) => {
  if (!available || !available.length) {
    return 'raw';
  }

  const precedence = ['monthly', 'daily', 'hourly'];
  const preferredIndex = precedence.indexOf(preferred);
  if (preferredIndex !== -1) {
    const byFrequency = groupBy(available, 'frequency');
    const suitableFrequencies = precedence.slice(preferredIndex);
    const suitableFrequency = suitableFrequencies.find(frequency => frequency in byFrequency);
    if (suitableFrequency) {
      return byFrequency[suitableFrequency][0].aggregation;
    }
  }

  return 'raw';
};

export const getAggregation = memoizeOne((sensorType, parameters, sensor, isUtilizationRate) => {
  if (!sensorType || !parameters) {
    return 'raw';
  }

  const startDatetime = parameters.startDatetime && new Date(parameters.startDatetime.valueOf());
  const endDatetime = parameters.endDatetime && new Date(parameters.endDatetime.valueOf());

  const timeDifferenceInDays = endDatetime && differenceInDays(endDatetime, startDatetime);
  const timeDifferenceInHours = endDatetime && differenceInHours(endDatetime, startDatetime);

  // Handle all statistics sensors (user and sensor type stats)
  if (isStatisticsSensor(sensorType.name)) {
    return 'dailySum';
  }

  if (isEnergyRatingSensor(sensorType.name)) {
    return 'energyRating';
  }

  // Handle energy consumption sensors
  if (isEnergyConsumptionSensor(sensorType.name)) {
    if (sensor?.granularity === 'month' || timeDifferenceInDays > 365) {
      return getSuitableAggregation('monthly', sensorType.aggregations);
    }

    if (sensor?.granularity === 'day' || timeDifferenceInDays > 31) {
      return getSuitableAggregation('daily', sensorType.aggregations);
    }
    // At least hourlySums should be always available so we never use raw data
    return getSuitableAggregation('hourly', sensorType.aggregations);
  }

  if (isUtilizationRate) {
    return 'hourlyUtilizationRate';
  }

  if (sensorType.graphType === 'presence') {
    return 'raw';
  }

  if (!parameters.endDatetime) {
    return 'raw';
  }

  if (sensorType.graphType === 'air_quality' || sensorType.graphType === 'technical_performance') {
    return getAirQualityAggregation(startDatetime, endDatetime);
  }

  if (sensor?.granularity === 'month') {
    return getSuitableAggregation('monthly', sensorType.aggregations);
  }

  if (timeDifferenceInDays > 31) {
    return getSuitableAggregation('daily', sensorType.aggregations);
  }

  const hourlyAggregationThreshold = 24 * 3;

  if (timeDifferenceInHours > hourlyAggregationThreshold) {
    return getSuitableAggregation('hourly', sensorType.aggregations);
  }

  // default
  return 'raw';
});

export const getInitialParameters = (parameters, graphType, isSensorChange) => {
  if (!parameters || isEmpty(parameters) || !isObject(parameters)) {
    return {};
  }

  return {
    ...parameters,
    // date formatter used in grouping the values
    aggregation: graphType === 'cleaning' || graphType === 'bar' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH',
    // supports multiple ranges if needed
    presence: isSensorChange ? parameters.presence : [9, 15],
  };
};

export const getStatisticsOptions = memoizeOne((t, sensorType, hasSensorGroups) => {
  const presenceType = sensorType && sensorType.name && sensorType.name.split('_')[1];
  const type =
    hasSensorGroups && presenceType !== 'area'
      ? 'floor'
      : presenceType
      ? presenceType === 'area'
        ? 'room'
        : presenceType
      : 'sensor';

  return [
    { label: t('Utilization per day'), value: STATISTICS_TYPES.perDay },
    { label: t('Utilization per hour'), value: STATISTICS_TYPES.perHour },
    { label: t(`Utilization per ${type}`), value: STATISTICS_TYPES.perSensor },
  ];
});

export const getUtilizationHours = memoizeOne(buildingMeta => {
  const utilizationHoursMeta = (find(buildingMeta, { key: 'utilization_calculation_hours' }) || { value: '9:00-15:00' })
    .value;
  return map(utilizationHoursMeta.split('-'), time => +time.split(':')[0]);
});

export const shouldPushLatestValueToDataset = (sensorType, latestValue, data, parameters) => {
  /**
   * Push latest value to dataset:
   * 1) Sensor type rule:
   * - sensorType.name is not air_quality
   * - sensorType.name is not starting with technical_performance
   * - sensorType.graphType is not bar
   * 2) Dataset is not empty and latest value exists
   * 3) Timerange rule:
   * - latest value is newer than last data point
   * - latest value is not (too much) outside timeframe
   */

  const latestValueDate = parseISO(latestValue?.timestamp);
  const lastDataPointDate = parseISO(last(data)?.timestamp);

  const sensorTypeRule =
    sensorType &&
    sensorType.name !== 'air_quality' &&
    !sensorType.name?.startsWith('technical_performance') &&
    sensorType.graphType !== 'bar';
  const hasData = latestValue && data?.length > 0;
  const datesAreValid = isValid(latestValueDate) && isValid(lastDataPointDate) && isValid(parameters.endDatetime);
  const timerangeRule =
    datesAreValid && latestValueDate > lastDataPointDate && latestValueDate <= endOfDay(parameters.endDatetime);

  if (sensorTypeRule && hasData && timerangeRule) {
    return true;
  }
  return false;
};
