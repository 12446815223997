import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';
import { invalidateCachesWithUrlMatch } from 'utils/Fetch/cache';

export const invalidateAllLongTermPlanningCaches = () =>
  invalidateCachesWithUrlMatch(`${SERVICE_API_PATH}/long-term-plans`);

export const longTermPlans = ({ partnerNumber, functionalLocation, refreshCache, onlyInvalidateCache }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/long-term-plans`,
    query: { partnerNumber, functionalLocation },
    refreshCache,
    onlyInvalidateCache,
    useAuthorization: true,
  });

export const longTermPlan = ({ longTermPlanId, refreshCache = false, onlyInvalidateCache = false }) =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/${longTermPlanId}`,
    refreshCache,
    onlyInvalidateCache,
    useAuthorization: true,
  });

export const createLongTermPlan = longTermPlan =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/long-term-plans`,
    data: longTermPlan,
    useAuthorization: true,
  });

export const updateLongTermPlan = longTermPlan =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/${longTermPlan.id}`,
    data: longTermPlan,
    useAuthorization: true,
  });

export const deleteLongTermPlan = longTermPlanId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/${longTermPlanId}`,
    useAuthorization: true,
  });

export const createLongTermPlanBudget = budget =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/${budget.longTermPlanId}/budgets`,
    data: budget,
    useAuthorization: true,
  });

export const updateLongTermPlanBudget = budget =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/budgets/${budget.id}`,
    data: budget,
    useAuthorization: true,
  });

export const deleteLongTermPlanBudget = budgetId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/budgets/${budgetId}`,
    useAuthorization: true,
  });

export const createLongTermPlanCategory = category =>
  ApiRequest.post({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/${category.longTermPlanId}/categories`,
    data: category,
    useAuthorization: true,
  });

export const updateLongTermPlanCategory = category =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/categories/${category.id}`,
    data: category,
    useAuthorization: true,
  });

export const deleteLongTermPlanCategory = categoryId =>
  ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/long-term-plans/categories/${categoryId}`,
    useAuthorization: true,
  });
