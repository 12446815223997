import { createReducerFromMapping } from 'redux/utils/index';
import PowerBIService from 'services/powerbi';

const initialState = {
  loading: false,
  powerbireport: {},
  error: false,
};

export const LOAD_REPORT = 'CUSTOMER_PLATFORM/PowerBI/LOAD_REPORT';
export const LOAD_REPORT_SUCCESS = 'CUSTOMER_PLATFORM/PowerBI/LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAIL = 'CUSTOMER_PLATFORM/PowerBI/LOAD_REPORT_FAIL';

export const loadReport = (id, filterVariables) => {
  return async dispatch => {
    dispatch({ type: LOAD_REPORT });
    try {
      const result = await dispatch(PowerBIService.loadReport(id, filterVariables));
      return dispatch({
        type: LOAD_REPORT_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_REPORT_FAIL,
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [LOAD_REPORT]: (state, action) => ({ ...state, loading: true, powerbireport: {} }),
    [LOAD_REPORT_FAIL]: (state, action) => ({ ...state, loading: false, error: action.error }),
    [LOAD_REPORT_SUCCESS]: (state, action) => ({
      ...state,
      powerbireport: action.result,
      loading: false,
    }),
  },
  initialState
);
