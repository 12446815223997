const SENSITIVE_QUERY_PARAMS = ['username', 'email'];
const EMAIL_REGEXP = /^[^@]+@[^@]+\.[^@]+$/g;

export const stripSensitiveInformationFromUrl = url => {
  try {
    const [urlWithoutQuery, query] = url.split('?');
    if (!query) {
      return url;
    }
    const nonSensitiveQueryParams = query.split('&').filter(param => {
      const [key, value] = param.split('=');
      return !isParamSensitive(key, value);
    });
    return `${urlWithoutQuery}?${nonSensitiveQueryParams.join('&')}`;
  } catch (error) {
    console.error('Error parsing url for GTM', error);
    return url;
  }
};

const isParamSensitive = (key, value) => {
  return SENSITIVE_QUERY_PARAMS.includes(key) || value?.match(EMAIL_REGEXP);
};

export const pushToDataLayer = (data = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

let timer = 0;

export const pushToDataLayerWithTimer = (data = {}, delay = 2000) => {
  if (timer !== 0) {
    clearTimeout(timer);
    timer = 0;
  }
  timer = setTimeout(() => {
    pushToDataLayer(data);
  }, delay);
};

export const createElementIdForGTM = value => {
  return value
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const formatFilterDataForGTM = filterObj => {
  Object.keys(filterObj).forEach(key => {
    if (Array.isArray(filterObj[key])) {
      filterObj[key] = filterObj[key].filter(item => item != null).join(',');
    }
    if (filterObj[key].length === 0) {
      delete filterObj[key];
    }
  });
  return Object.keys(filterObj)
    .map(k => `${k}: ${filterObj[k]}`)
    .join(',');
};
