import React from 'react';
import PropTypes from 'prop-types';
import InputDropdown from './InputDropdown';
import times from 'lodash/times';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import parse from 'date-fns/parse';
import { startOfUTCDay, endOfUTCDay } from 'utils/Date/date';
import isValid from 'date-fns/isValid';

const dateNow = new Date();

const DEFAULT_START_YEAR = 2010;
const DEFAULT_END_YEAR = dateNow.getUTCFullYear();
const DEFAULT_YEAR_OPTIONS = {
  startYear: DEFAULT_START_YEAR,
  endYear: DEFAULT_END_YEAR,
};

const InputYear = ({ useStartOfYear, useEndOfYear, model, property, yearOptions = DEFAULT_YEAR_OPTIONS, onChange }) => {
  if (!yearOptions?.startYear || !yearOptions.endYear || yearOptions?.startYear > yearOptions.endYear) {
    return null;
  }
  // Build options for dropdown for all years between yearOptions.startYear and yearOptions.endYear
  const options = times(yearOptions.endYear - yearOptions.startYear + 1, index => {
    const year = index + yearOptions.startYear;
    return { label: year, value: year };
  });
  const handleChange = (property, value) => {
    const selectedDate = parse(value, 'y', dateNow);
    let newValue = selectedDate;
    if (useStartOfYear) {
      newValue = startOfUTCDay(startOfYear(selectedDate));
    } else if (useEndOfYear) {
      newValue = endOfUTCDay(endOfYear(selectedDate));
    }
    if (typeof onChange === 'function') {
      onChange(property, newValue);
    }
  };
  const mappedModel = {
    [property]: isValid(model[property]) && model[property].getUTCFullYear(),
  };
  return <InputDropdown options={options} property={property} model={mappedModel} onChange={handleChange} />;
};

InputYear.propTypes = {
  useStartOfYear: PropTypes.bool,
  useEndOfYear: PropTypes.bool,
  model: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
  property: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  yearOptions: PropTypes.shape({
    startYear: PropTypes.number,
    endYear: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

export default InputYear;
