import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  tr {
    &:first-child {
      border-top: ${({ borderTop, borders }) => (borderTop && borders ? 'var(--table-row-border)' : 'none')};
    }
  }
`;
Table.displayName = 'Table';

export const Row = styled.tr`
  border-bottom: ${({ borders }) => (borders ? 'var(--table-row-border)' : 'none')};
`;
Row.displayName = 'Row';

export const LabelCell = styled.td`
  padding: var(--size-xs) 0;
  color: var(--table-row-label-fg);
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  letter-spacing: 0.3px;
  line-height: ${props => props.theme.font.size.sm};
  width: ${props => props.columnWidth};

  ${props =>
    !props.columnWidth &&
    props.theme.media.portrait`
    width: 100px;
  `}

  ${props =>
    !props.columnWidth &&
    props.theme.media.landscape`
    width: 140px;
  `}
`;
LabelCell.displayName = 'LabelCell';

export const ValueCell = styled.td`
  padding: var(--size-xs) 0;
  padding-left: var(--size-sm);
  color: var(--body-text-fg);
  font-size: ${props => props.theme.font.size.xs};
  width: ${props => (props.isLoading ? '60%' : props.columnWidth)};

  ${props =>
    !props.columnWidth &&
    props.theme.media.portrait`
    width: ${props => props.isLoading && 'calc(100% - 100px)'};
  `}

  ${props =>
    !props.columnWidth &&
    props.theme.media.landscape`
    width: ${props => props.isLoading && 'calc(100% - 140px)'};
  `}
`;
ValueCell.displayName = 'ValueCell';
