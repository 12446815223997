import { useState, useEffect } from 'react';
import uniq from 'lodash/uniq';
import isValid from 'date-fns/isValid';

import IoT from 'services/iot';
import { useAnnotationDataValidity } from './annotationDataValidity.context';

const useAnnotations = ({
  functionalLocation,
  sensor = null,
  chart = null,
  startDate,
  endDate,
  publicViewId = null,
}) => {
  const [state, setState] = useState({ annotations: [], annotationsLoaded: false });
  const { invalidationTime } = useAnnotationDataValidity();

  const getSensorIds = ({ sensor, chart }) => {
    if (sensor) {
      if (sensor.parentId) {
        return [sensor.id, sensor.parentId];
      }
      return [sensor.id];
    }
    if (chart) {
      return uniq(chart.series.flatMap(seriesObj => seriesObj.sensorIds));
    }
  };

  const load = async () => {
    if ((functionalLocation || sensor || (chart && chart.id >= 0)) && isValid(startDate) && isValid(endDate)) {
      const sensorIds = getSensorIds({ sensor, chart });

      try {
        const getAnnotations = publicViewId ? IoT.annotationsWithPublicView : IoT.annotations;
        const result = await getAnnotations({
          functionalLocation,
          sensorIds,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          publicViewId,
          customChartId: chart?.id >= 0 ? chart?.id : undefined,
        });
        setState({ annotations: result, annotationsLoaded: true });
      } catch (e) {
        setState({ annotations: [], annotationsLoaded: true });
      }
    }
  };

  // linter will crash if these are in hook deps
  const startDateValue = startDate?.valueOf();
  const endDateValue = endDate?.valueOf();

  useEffect(() => {
    load();
  }, [functionalLocation, sensor, chart, startDateValue, endDateValue, publicViewId, invalidationTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};

export default useAnnotations;
