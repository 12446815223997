import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

const Warning = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  border-top: 1px solid var(--negative-color);
  background: rgb(255, 230, 230);
  padding: 1em;
  color: var(--body-text-fg);
  font-size: ${props => props.theme.font.size.xs};
  font-weight: normal;
  line-height: 1.25;
`;

const Close = styled.a`
  display: inline-block;
  margin-left: var(--size-xs);
  cursor: pointer;
`;

export const IENotification = ({ t }) => {
  const [isVisible, setVisible] = useState(true);
  const isIE = window.navigator.userAgent.match(/(MSIE|Trident)/);
  if (!isIE || !isVisible) {
    return null;
  }
  return (
    <Warning>
      {t(
        'Hello, we noticed that you are using Internet Explorer. Please note that using Internet Explorer to access SmartView is no longer supported. We recommend using latest version of Chrome, Edge, Firefox or Safari.'
      )}
      <Close onClick={() => setVisible(false)}>{t('Close')}</Close>
    </Warning>
  );
};

IENotification.propTypes = {
  t: PropTypes.func.isRequired
};

export default translations(IENotification);
