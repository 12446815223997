import parseISO from 'date-fns/parseISO';
import { format } from 'utils/Date/dateFormatter';
import { getPerformanceStatus } from 'utils/Data/performance';
import { VALUE_STATUS } from 'constants/common';
import { getGraphTypeBooleanValueText } from '../Sensor/SensorBody/utils';

// value is the sorting order in SortableTable
export const conditionStatus = {
  positive: { name: 'POSITIVE', value: 1 },
  neutral: { name: 'NEUTRAL', value: 2 },
  empty: { name: 'EMPTY', value: 3 },
  negative: { name: 'NEGATIVE', value: 4 },
  warning: { name: 'WARNING', value: 5 },
};

export const distinctConditionValue = (graphType, sensorUnit, latestValueObject, t, sensorMeta) => {
  let distinctValue, distinctColorStatus, perfStatus;
  switch (graphType) {
    case 'air_quality':
    case 'technical_performance':
      perfStatus = getPerformanceStatus(latestValueObject.value, true);
      distinctColorStatus = perfStatus === VALUE_STATUS.OK ? conditionStatus.positive : conditionStatus.negative;
      break;

    case 'boolean':
      distinctValue = getGraphTypeBooleanValueText(latestValueObject.value, sensorUnit, t, sensorMeta);
      distinctColorStatus = latestValueObject.value > 0 ? conditionStatus.negative : conditionStatus.positive;
      break;

    case 'presence':
      distinctValue = latestValueObject.value > 0 ? t('Occupied') : t('Available');
      distinctColorStatus = latestValueObject.value > 0 ? conditionStatus.negative : conditionStatus.positive;
      break;

    case 'cleaning':
      distinctValue = format(parseISO(latestValueObject.timestamp), 'd.M.');
      break;

    case 's2':
      distinctValue = latestValueObject.value === 100 ? t('Yes') : t('No');
      distinctColorStatus = latestValueObject.value === 100 ? conditionStatus.positive : conditionStatus.negative;
      break;

    default:
      break;
  }

  return { distinctValue, distinctColorStatus };
};
