import React from 'react';
import { useTranslations } from 'decorators/Translations/translations';
import PropTypes from 'prop-types';

import ObservationView from './ObservationView';
import DialogModal from 'components/Dialog/DialogModal';
import FunctionalLocationHeader from 'components/FunctionalLocationHeader/FunctionalLocationHeader';
import SecondaryButton from 'components/Button/SecondaryButton';
import Tag from 'components/Tag/Tag';
import ObservationModalTop from './ObservationModalTop';

import { INCIDENT_ORIGIN, performanceColorNames } from 'utils/Data/notice';
import { icons } from 'components/Icon/IconNames';
import { ICON_SIZES, getIconFontSizeByType } from 'components/Icon/Icon';
import Svg from 'components/Svg/Svg';
import styled from 'styled-components';
import Content from 'components/Content/Content';

const TagIcon = styled(Svg)`
  margin: -2px 2px;
  font-size: ${props => getIconFontSizeByType(props.size)};
`;

const TagWrap = styled.div`
  display: inline-block;
`;

const defaultColor = Symbol('default');

const statusColorMap = {
  [defaultColor]: 'var(--default-tag-bg)',
  okColor: 'var(--green-01)',
  warningColor: 'var(--orange-01)',
  alarmColor: 'var(--violet-01)',
  openColor: 'var(--blue-02)',
};

const getStatusColor = observation => {
  const status = observation.status?.status || '';
  return statusColorMap[performanceColorNames[status.toLowerCase()]] ?? statusColorMap[defaultColor];
};

const getObservationIcon = observation => {
  if (observation?.origin === INCIDENT_ORIGIN.analytics) {
    return icons.OBSERVATION_ANALYTICS;
  }
  return icons.OBSERVATION_EXPERT;
};

const ObservationsModalSingleObservation = ({ onClose, selectedObservation, functionalLocations, showBackButton }) => {
  const [t] = useTranslations();
  const createdByText = `Observation created by ${
    selectedObservation?.origin === INCIDENT_ORIGIN.analytics ? 'Analytics' : 'Caverion Expert'
  }`;
  return (
    <DialogModal
      large
      isActive
      onOverlayClick={onClose}
      title={t('Observations')}
      statusColor={getStatusColor(selectedObservation)}
    >
      <Content>
        <FunctionalLocationHeader
          t={t}
          functionalLocation={functionalLocations[selectedObservation.functionalLocation]}
          title={selectedObservation.location?.value}
          statusTag={
            <Tag backgroundColor={getStatusColor(selectedObservation)} textColor="var(--white)" showFullText>
              {t(selectedObservation.status.status)}
            </Tag>
          }
          statusTag2={
            <>
              <TagWrap>
                <TagIcon name={getObservationIcon(selectedObservation)} size={ICON_SIZES.SMALL} />
              </TagWrap>
              <span>{t(createdByText)}</span>
            </>
          }
          buttons={
            showBackButton && (
              <SecondaryButton large iconName="caret-left" onClick={onClose}>
                {t('Back to observations list')}
              </SecondaryButton>
            )
          }
        />
        <ObservationModalTop observation={selectedObservation} t={t} loading={false} />
        <ObservationView observation={selectedObservation} functionalLocations={functionalLocations} />
      </Content>
    </DialogModal>
  );
};

ObservationsModalSingleObservation.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedObservation: PropTypes.object.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  showBackButton: PropTypes.bool,
};
export default ObservationsModalSingleObservation;
