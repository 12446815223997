import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import {
  add,
  addDays,
  getISODay,
  setISODay,
  differenceInDays,
  differenceInSeconds,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'utils/Date/dateFormatter';
import { getBrowserTimeZone } from 'utils/Date/date';

import { defaults, getUseCase, parseOpeningHours } from '@caverion/loopback-shared/utility/performance';
import { VALUE_STATUS } from 'constants/common';

const CAPACITY_MAX_COEFFICIENT = 1.0;
const CAPACITY_OK_COEFFICIENT = 0.5;

export const getPerformanceStatus = (value, isPerformance = false, isPresence = false) => {
  if (isPerformance) {
    return value >= 90 ? VALUE_STATUS.OK : VALUE_STATUS.ALERT;
  }
  if (isPresence) {
    return value === 0 ? VALUE_STATUS.OK : VALUE_STATUS.ALERT;
  }

  if (value >= 95) {
    return VALUE_STATUS.OK;
  }

  if (value >= 80) {
    return VALUE_STATUS.WARNING;
  }

  if (value === -1) {
    return VALUE_STATUS.NEUTRAL;
  }

  return VALUE_STATUS.ALERT;
};

export const performanceColors = () => ({
  ok: 'var(--success-color)',
  warning: 'var(--warning-color)',
  alert: 'var(--alarm-color)',
  loading: 'var(--inactive-color)',
  neutral: 'var(--neutral-color)',
});

const getDefaultPerformanceLimit = (sensorType, useCase) => {
  if (useCase) {
    const limit = get(defaults.limits.byUseCase, `${useCase}.${sensorType}`);
    if (limit) {
      return typeof limit === 'function' ? limit : () => limit;
    }
  }

  const limit = defaults.limits.common[sensorType];
  if (typeof limit === 'undefined') {
    return () => [undefined, undefined];
  }

  return typeof limit === 'function' ? limit : () => limit;
};

export const getLimitFromMeta = (meta, key) => {
  const value = get(meta && meta.find(meta => meta.key === key || meta.metaKey === key), 'value');
  if (value || value === '0') {
    return Number.parseFloat(value);
  }

  return undefined;
};

const formatLimits = (min, max) => [
  typeof min === 'number' ? min : Number.NEGATIVE_INFINITY,
  typeof max === 'number' ? max : Number.POSITIVE_INFINITY,
];

/**
 * Determine performance limits for a sensor.
 *
 * 1. Sensor alarm
 * 2. Sensor min/max meta (h:min, h:max)
 * 3. Sensor capacity meta
 * 4. Use case specific building meta
 * 5. Global defaults for use case
 * 6. Building meta
 * 7. Global defaults
 */
export const getPerformanceLimit = (sensor, parent, buildingMeta, sensorAlarm) => {
  if (!sensor) {
    return () => [undefined, undefined];
  }

  if (sensorAlarm) {
    return () => [sensorAlarm.minValue, sensorAlarm.maxValue];
  }

  const sensorMin = getLimitFromMeta(sensor.sensorMeta, 'h:min');
  const sensorMax = getLimitFromMeta(sensor.sensorMeta, 'h:max');
  if (!isNil(sensorMin) || !isNil(sensorMax)) {
    return () => formatLimits(sensorMin, sensorMax);
  }

  const capacity = getLimitFromMeta(sensor.sensorMeta, 'capacity');
  if (capacity) {
    return () => formatLimits(undefined, capacity * CAPACITY_MAX_COEFFICIENT);
  }

  const useCase = getUseCase(get(parent, 'sensorType.name'));
  const type = sensor.sensorType?.name?.replace('indoor temperature', 'temperature');
  if (useCase) {
    if (useCase === 'outdoor') {
      return () => [undefined, undefined];
    }

    const useCaseMax = getLimitFromMeta(buildingMeta, `performance/${useCase}/${type}/max`);
    const useCaseMin = getLimitFromMeta(buildingMeta, `performance/${useCase}/${type}/min`);
    if (!isNil(useCaseMin) || !isNil(useCaseMax)) {
      return () => formatLimits(useCaseMin, useCaseMax);
    }

    return getDefaultPerformanceLimit(type, useCase);
  }

  const buildingMin = getLimitFromMeta(buildingMeta, `performance/${type}/min`);
  const buildingMax = getLimitFromMeta(buildingMeta, `performance/${type}/max`);
  if (!isNil(buildingMin) || !isNil(buildingMax)) {
    return () => formatLimits(buildingMin, buildingMax);
  }
  return getDefaultPerformanceLimit(type);
};

export const mapThresholdToStatusValue = (value, thresholds) => {
  if (
    isNil(value) ||
    !thresholds ||
    isEmpty(thresholds) ||
    (Array.isArray(thresholds) && thresholds.every(threshold => typeof threshold === 'undefined'))
  ) {
    return -1;
  }

  return value >= thresholds[0] && value <= thresholds[1] ? 100 : 10;
};

export const generateTresholds = memoizeOne((start, end, getThreshold, granularity = 'day') => {
  const below = [];
  const within = [];
  const above = [];

  let infiniteMin = false;
  let infiniteMax = false;

  if (getThreshold) {
    let previous = [];
    let iterator = new Date(start.valueOf());
    const iteratorEnds = new Date(end.valueOf());

    while (iterator <= iteratorEnds) {
      const threshold = getThreshold(iterator);
      const [min, max] = threshold;
      const timestamp = iterator.valueOf();

      if (threshold[0] !== previous[0] || threshold[1] !== previous[1]) {
        if (typeof previous[0] !== 'undefined' && typeof previous[1] !== 'undefined') {
          // Add previous value just before to produce vertical steps in thresholds
          below.push([timestamp - 1, previous[0]]);
          within.push([timestamp - 1, previous[0], previous[1]]);
          above.push([timestamp - 1, previous[1]]);
        }

        below.push([timestamp, min]);
        within.push([timestamp, min, max]);
        above.push([timestamp, max]);

        if (!isFinite(min)) {
          infiniteMin = true;
        }

        if (!isFinite(max)) {
          infiniteMax = true;
        }
      }

      switch (granularity) {
        case 'year':
        case 'years':
          iterator = add(iterator, { years: 1 });
          break;
        case 'month':
        case 'months':
          iterator = add(iterator, { months: 1 });
          break;
        case 'week':
        case 'weeks':
          iterator = add(iterator, { weeks: 1 });
          break;

        case 'day':
        case 'days':
          iterator = add(iterator, { days: 1 });
          break;

        case 'hour':
        case 'hours':
          iterator = add(iterator, { hours: 1 });
          break;
        case 'minute':
        case 'minutes':
          iterator = add(iterator, { minutes: 1 });
          break;
        default:
          break;
      }

      previous = threshold;
    }

    // Add one point to the end.
    const timestamp = iteratorEnds.valueOf();
    const [min, max] = getThreshold(iteratorEnds);
    below.push([timestamp, min]);
    within.push([timestamp, min, max]);
    above.push([timestamp, max]);
  }

  return {
    below,
    within,
    above,
    infiniteMin,
    infiniteMax,
  };
});

const getDefaultOpeningHours = hours => {
  if (!hours) {
    return undefined;
  }

  // Default opening hours have Sunday as the first day of week, while UI expects Monday
  return [...hours.slice(1, hours.length), hours[0]];
};

export const getOpeningHours = (sensorHierarchy, buildingMeta) => {
  if (!sensorHierarchy || !sensorHierarchy.sensors) {
    return undefined;
  }

  const useCases = uniq(
    sensorHierarchy.sensors
      .filter(sensor => !isNil(sensor.sensorType))
      .filter(sensor => sensor.sensorType.name !== 'technical_performance')
      .map(sensor => getUseCase(get(sensor, 'sensorType.name')))
  );

  if (useCases.length > 1) {
    return undefined;
  }

  const useCase = useCases[0];
  const metaKey = ['performance', useCase, 'hours'].filter(part => part).join('/');
  const meta = buildingMeta && buildingMeta.find(meta => meta.key === metaKey);
  const openingHours = parseOpeningHours(meta);
  if (openingHours) {
    return openingHours;
  }

  if (useCase) {
    return getDefaultOpeningHours(defaults.openingHours.hours.byUseCase[useCase]);
  }

  return getDefaultOpeningHours(defaults.openingHours.hours.common);
};

function formatHours(hours) {
  return hours.map(hour => hour.split(':').splice(0, 2).join(':')).join('-');
}

export function formatOpeningHours(openingHours) {
  if (!openingHours) {
    return undefined;
  }

  if (typeof openingHours === 'string') {
    return openingHours;
  }

  return openingHours
    .reduce((acc, hours, index) => {
      if (!hours || hours.some(hour => hour === null)) {
        return acc;
      }

      const day = format(setISODay(new Date(), index + 1), 'iii');
      if (isEqual(hours, openingHours[index + 1]) && !isEqual(hours, openingHours[index - 1])) {
        return [...acc, day];
      }

      if (isEqual(hours, openingHours[index - 1]) && !isEqual(hours, openingHours[index + 1])) {
        acc[acc.length - 1] += `-${day} ${formatHours(hours)}`;
        return acc;
      }

      if (isEqual(hours, openingHours[index - 1]) && isEqual(hours, openingHours[index + 1])) {
        return acc;
      }

      return [...acc, `${day} ${formatHours(hours)}`];
    }, [])
    .join(', ');
}

export const createOpeningHourBands = ({
  startDate,
  endDate,
  openingHours,
  maxDays = 3,
  timezone = getBrowserTimeZone(),
}) => {
  if (!openingHours || typeof openingHours === 'string' || differenceInDays(endDate, startDate) > maxDays) {
    return [];
  }

  const bands = [];

  let iterator = startDate; // iterate over the days in user's timezone
  while (iterator < endDate) {
    const start = iterator;
    let end = endOfDay(start);
    if (end > endDate) {
      end = endDate;
    }

    const hours = openingHours[getISODay(start) - 1];
    if (!hours || hours.some(hour => hour === null)) {
      bands.push([start, end]);
    } else {
      // opening hours are in building timezone, so the hours need to be converted to user's timezone
      const opens = zonedTimeToUtc(`${format(start, 'yyyy-MM-dd')}T${hours[0]}`, timezone);
      const closes = zonedTimeToUtc(`${format(start, 'yyyy-MM-dd')}T${hours[1]}`, timezone);
      bands.push([start, opens]);
      bands.push([closes, end]);
    }

    iterator = addDays(startOfDay(iterator), 1);
  }

  // Combine immediately consecutive bands to avoid thin lines between the bands
  return bands.reduce((acc, band) => {
    const previous = acc[acc.length - 1];
    if (previous && differenceInSeconds(band[0], previous[1]) <= 1) {
      acc[acc.length - 1][1] = band[1];
      return acc;
    }

    return [...acc, band];
  }, []);
};

export function generateOpeningHourBands(openingHours, timezone, startDate, endDate, theme) {
  const bands = createOpeningHourBands({
    startDate: new Date(startDate.valueOf()),
    endDate: new Date(endDate.valueOf()),
    openingHours,
    timezone,
  });
  return bands.map(([from, to]) => ({
    from,
    to,
    color: theme.colors.lightGray,
  }));
}

export const technicalPerformanceOrder = [
  'technical_performance',
  'technical_performance/temperature',
  'technical_performance/humidity',
  'technical_performance/carbondioxide',
  'technical_performance/organic_gas',
  'technical_performance/particle/PM2.5',
  'technical_performance/particle/PM10',
  'technical_performance/pressure',
  'technical_performance/radon',
];

export const areaUtilizationStatus = utilizationRate => {
  if (utilizationRate <= CAPACITY_OK_COEFFICIENT * 100) {
    return VALUE_STATUS.OK;
  } else if (utilizationRate <= CAPACITY_MAX_COEFFICIENT * 100) {
    return VALUE_STATUS.WARNING;
  }
  return VALUE_STATUS.ALERT;
};
