import styled from 'styled-components';
import * as Styled from 'components/Tabs/Tabs.styled';
import { Tab as TabListTab } from 'components/Tabs/TabList';

export const Container = styled(Styled.Container)`
  width: 100%;
`;

export const TabContainer = styled.div`
  display: flex;
  border-bottom: var(--border-thin) solid var(--grey-20);
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.breakpoints.mobile}px) {
    border-bottom: none;
    gap: 0.25em;
  }
`;

export const TabPanels = styled(Styled.TabPanels)`
  grid-area: tabs;
  border-top: var(--border-thin) solid var(--grey-20);
  @media (max-width: ${props => props.theme.breakpoints.mobile}px) {
    border: none;
  }
`;

export const TabPanel = styled(Styled.TabPanel)`
  grid-area: tabs;
  //padding-top: 0.75em;
  box-shadow: none;
`;

interface TabProps {
  selected: boolean;
}

export const Tab = styled(TabListTab)<TabProps>`
  border: var(--border-thin) solid (--tab-bg);
  border-bottom: none;

  &[data-selected='false'] + &[data-selected='false'] {
    span {
      @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
        border-left: var(--border-thin) solid (--tab-bg);
      }
    }
  }
  &[data-selected='true'] {
    border: var(--border-thin) solid var(--grey-20);
    border-bottom: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}px) {
    padding: var(--size-xs) var(--size-xs);
    font-size: ${props => props.theme.fontSize.xxs};
    &[data-selected='true'] {
      border: var(--border-thin) solid var(--grey-20);
    }
  }
`;
