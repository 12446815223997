import { useCallback, useRef } from 'react';
import useTimeout from './useTimeout';

const useChartDoubleClick = (doubleClickAction, delayedAction) => {
  const clickedPointRef = useRef(null);
  const { push: actionTimeout, clear: clearActionTimeout } = useTimeout(500);
  const { push: dblClickTimeout, clear: clearDblClickTimeout, timeoutRef: dblClickTimeoutRef } = useTimeout(500);

  const onDelayedAction = useCallback(
    function (e) {
      actionTimeout(() => delayedAction?.(e, this));
    },
    [delayedAction, actionTimeout]
  );

  const onClick = useCallback(
    e => {
      const isPoint = !!e.point;
      const isDoubleClick = dblClickTimeoutRef.current.length > 0;

      if (isDoubleClick) {
        clearActionTimeout();
        doubleClickAction?.(clickedPointRef?.current || e);
        clickedPointRef.current = null;
      } else {
        dblClickTimeout(() => {
          clickedPointRef.current = null;
          clearDblClickTimeout();
        });
      }
      if (isPoint) {
        clickedPointRef.current = e;
      }
      return false;
    },
    [doubleClickAction, clearActionTimeout, dblClickTimeout, clearDblClickTimeout, dblClickTimeoutRef]
  );

  return { onDelayedAction, onClick };
};

export default useChartDoubleClick;
