import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Container = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  color: var(--body-text-fg);

  p,
  ul,
  ol {
    margin: var(--size-xs) 0;
  }

  p {
    line-height: var(--line-height-default);
    white-space: pre-line;
  }

  li {
    line-height: var(--line-height-airy);
  }

  ul {
    list-style: inside disc;
  }

  ol {
    list-style: inside decimal;
  }
`;
Container.displayName = 'Container';

export const MarkdownContent = ({ content, className }) => (
  <Container className={className}>
    <ReactMarkdown children={content} linkTarget="_blank" />
  </Container>
);

MarkdownContent.displayName = 'MarkdownContent';

MarkdownContent.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MarkdownContent;
