import { OrderType } from 'constants/maintenance';

export const getChecklistNumbers = (template, report) => ({
  questions: template?.items?.length || 0,
  answers: report?.reportItems?.filter(reportItem => !!reportItem.selectedChecklistTemplateOptionId).length || 0,
});

export const serviceOrderHasChecklist = serviceOrder =>
  Boolean(
    serviceOrder &&
      serviceOrder.orderType === OrderType.ORDER &&
      serviceOrder.serviceOrderNumber &&
      serviceOrder.checklistTemplateId &&
      serviceOrder.status !== 'Cancelled'
  );
