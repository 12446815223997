import keyBy from 'lodash/keyBy';
import { TYPES } from '../common';

export const createReportItemsModel = (templateItems, reportItems) => {
  const modelItems = templateItems.map(templateItem => {
    const previousAnswer = reportItems.find(reportItem => reportItem.checklistTemplateItemId === templateItem.id) || {};

    return {
      checklistTemplateItemId: templateItem.id,
      selectedChecklistTemplateOptionId: undefined,
      freeText: '',
      ...previousAnswer,
    };
  });

  return keyBy(modelItems, 'checklistTemplateItemId');
};

export const isPreviouslyAnswered = reportItemModel => {
  return reportItemModel && reportItemModel.id && reportItemModel.selectedChecklistTemplateOptionId;
};

export const sortTemplateOptions = templateOptions => {
  if (templateOptions) {
    return [...templateOptions].sort((a, b) => TYPES.indexOf(a.type) - TYPES.indexOf(b.type));
  }
  return [];
};

export const sortTemplateItems = templateItems => {
  if (templateItems) {
    return [...templateItems]
      .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
      .map((item, index) => ({ ...item, ordinal: index + 1 }));
  }
  return [];
};
