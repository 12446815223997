import find from 'lodash/find';
import first from 'lodash/first';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import { parseISO, subDays, isAfter, formatDistance, addHours } from 'date-fns';
import { format } from 'utils/Date/dateFormatter';
import { startOfUTCDay } from 'utils/Date/date';

const getAreaForSensorId = (floor, sensorId) => {
  const sensor = find(floor.sensorList, { id: parseInt(sensorId, 10) });
  if (!sensor) {
    return null;
  }
  const floorAreas = find(floor.children, { type: 'area ' });
  const area = find(floorAreas, area => area.id === parseInt(sensor.sensorHierarchyId, 10));
  return area ? area.name : null;
};

export const mapCleaningData = (t, value, floor) => ({
  area: {
    value: getAreaForSensorId(value.sensorId) || (floor && `${t('Floor')} ${floor.shortName || floor.name}`) || '',
    id: parseInt(value.sensorId, 10),
  },
  date: {
    date: value.timestamp,
    value: parseISO(value.timestamp).valueOf(),
  },
  value: value.value,
});

export const getLatestCleaningData = cleaningData => {
  const latestCleaning = first(orderBy(cleaningData, 'date.date', 'desc'));
  let latestCleaningTime;
  let isCleanedToday = false;
  if (latestCleaning) {
    const latestCleaningDate = parseISO(latestCleaning.date.date);
    const dayAgo = subDays(new Date(), 1);
    isCleanedToday = isAfter(latestCleaningDate, dayAgo);
    latestCleaningTime = isCleanedToday
      ? capitalize(formatDistance(latestCleaningDate, new Date()))
      : format(latestCleaningDate, 'd.M.yyyy - HH:mm');
  }

  return { latestCleaning, latestCleaningTime, isCleanedToday };
};

// Use UTC noon to minimise the possibility of timezone-related issues when comparing dates.
// Must be a function to use an explicit locale instead of the default (english).
export const cleaningDates = () =>
  map(Array(7), (v, i) => addHours(startOfUTCDay(subDays(new Date(), i)), 12)).reverse();
