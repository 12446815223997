import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';

import Infotip from 'components/Infotip/Infotip';
import SkeletonText from 'components/Skeletons/SkeletonText';

import { Link } from 'react-router-dom';

const Cards = styled.div`
  display: ${props => (props.hideOnMobile ? 'none' : 'flex')};
  border: var(--box-border);
  border-right-color: transparent;
  ${props => (props.margin ? 'margin-bottom: var(--size-xsl);' : '')}

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${props => props.theme.media.portrait`
    overflow-x: initial;
    border-right-color: var(--box-bc);
  `}

  ${props => props.theme.media.desktop`
    display: flex;
  `}
`;
Cards.displayName = 'Cards';
Cards.propTypes = {
  margin: PropTypes.bool,
};

export const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--card-bg);
  padding: var(--size-md);
  border-right: var(--border-thin) solid var(--card-bc);

  ${props => props.theme.media.landscape`
    &:last-child {
      border-right-color: transparent;
    }
  `}
`;
Card.displayName = 'Card';

const Value = styled.span`
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.color || 'var(--body-text-fg)'};
  text-transform: ${props => props.capitalize && 'capitalize'};
`;
Value.displayName = 'Value';

const LabelWrapper = styled.div`
  display: flex;
  ${props =>
    !props.dropFlexGrow ??
    `
    flex: 1
  `}
  align-items: center;
  padding-bottom: var(--size-xs);

  ${props => props.theme.media.landscape`
    align-items: flex-end;
  `}
`;
LabelWrapper.displayName = 'LabelWrapper';

const SubValueWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: var(--size-xs);

  ${props => props.theme.media.landscape`
    align-items: flex-end;
  `}
`;
SubValueWrapper.displayName = 'SubValueWrapper';

const Label = styled.span`
  color: ${props => (props.invalid ? 'var(--error-color)' : 'var(--supplemental-text-fg)')};
  font-size: ${props => props.theme.font.size.xxs};
  font-weight: ${props => props.theme.font.weight.normal};
  letter-spacing: 0.3px;
  white-space: pre-line;
  text-align: center;
`;
Label.displayName = 'Label';

const ValueSkeleton = styled(SkeletonText)`
  width: 80px;
  height: ${props => props.theme.font.size.sm};
  margin: 0;
`;
ValueSkeleton.displayName = 'ValueSkeleton';

const LabelSkeleton = styled(SkeletonText)`
  max-width: 170px;
  height: ${props => props.theme.font.size.xxs};
  margin-bottom: var(--size-xs);
`;
LabelSkeleton.displayName = 'LabelSkeleton';

const ReferenceWrapper = styled.span`
  display: inline-block;
  margin-left: var(--size-sm);
`;

const Reference = styled.span`
  border-radius: 2px;
  text-align: center;
  font-size: ${props => props.theme.font.size.xxs};
  color: var(--body-text-fg);
  font-weight: ${props => props.theme.font.weight.bold};
  background-color: ${props => lighten(0.3, props.color)};
  border: var(--border-thin) solid transparent;
  padding: 0 var(--size-xxs);
`;

const KeyCards = ({ items, hideOnMobile, margin = true }) => {
  const isSubValue = items.some(i => i.subvalue);
  return (
    <Cards hideOnMobile={hideOnMobile} margin={margin}>
      {items.map(
        ({
          value,
          color,
          label,
          loading,
          contextualHelp,
          hide,
          capitalize,
          reference,
          subvalue,
          isLink,
          linkAddress,
        }) => {
          if (hide) {
            return null;
          }
          return (
            <Card key={label}>
              {loading && (
                <>
                  <LabelSkeleton />
                  <ValueSkeleton />
                </>
              )}
              {!loading && label && (
                <LabelWrapper dropFlexGrow={isSubValue}>
                  <Label hasContextualHelp={contextualHelp}>{label}</Label>
                  {contextualHelp}
                </LabelWrapper>
              )}
              {!loading && (
                <div>
                  {isLink && <Link to={linkAddress}>{value}</Link>}
                  {!isLink && (
                    <Value color={color} capitalize={capitalize}>
                      {value}
                    </Value>
                  )}

                  {reference && (
                    <ReferenceWrapper>
                      <Infotip text={reference.tooltip}>
                        <Reference color={reference.color}>{reference.value}</Reference>
                      </Infotip>
                    </ReferenceWrapper>
                  )}
                </div>
              )}
              {!loading && subvalue && (
                <SubValueWrapper>
                  <Label>{subvalue}</Label>
                </SubValueWrapper>
              )}
            </Card>
          );
        }
      )}
    </Cards>
  );
};

KeyCards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
      color: PropTypes.string,
      label: PropTypes.string,
      loading: PropTypes.bool,
      contextualHelp: PropTypes.node,
      capitalize: PropTypes.bool,
      reference: PropTypes.object,
      subvalue: PropTypes.node,
      isLink: PropTypes.bool,
      linkAddress: PropTypes.string,
    })
  ),
  hideOnMobile: PropTypes.bool,
  margin: PropTypes.bool,
};

export default KeyCards;
