// how long (in milliseconds) a snackbar notification is visible
export const NOTIFICATION_TIMEOUT = 2000;

export const VALUE_STATUS = {
  OK: 'ok',
  WARNING: 'warning',
  NEUTRAL: 'neutral',
  ALERT: 'alert',
};

export const SUPPORTED_TIMEZONES = {
  FI: 'Europe/Helsinki',
  SE: 'Europe/Stockholm',
  NO: 'Europe/Oslo',
  DK: 'Europe/Copenhagen',
  LT: 'Europe/Vilnius',
  DE: 'Europe/Berlin',
  RU: 'Europe/Moscow',
  AT: 'Europe/Vienna',
  UTC: 'UTC',
};
