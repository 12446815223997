import { ValidationError } from 'yup';

export const validateModel = async (model, schemas) => {
  const validationPromises = schemas.map(schema => schema.validate(model, { abortEarly: false }));
  const results = await Promise.allSettled(validationPromises);
  const errors = results.filter(({ status }) => status === 'rejected').map(({ reason }) => reason);

  return Object.fromEntries(
    errors.flatMap(error => {
      if (error instanceof ValidationError) {
        return error.inner.map(({ path, message }) => [path, message]);
      }
      console.error('Unknown validation error', error);
      return [['', 'Something went wrong']];
    })
  );
};
