import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import { POWER_BI_PATH } from './index';

export const loadReportFn = (request, id, filterVariables) =>
  request.get({
    apiPath: `${POWER_BI_PATH}/report`,
    query: {
      id,
      filterVariables: filterVariables && JSON.stringify(filterVariables),
    },
  });

export default wrapEachWithThunkRequest({
  loadReport: loadReportFn,
});
