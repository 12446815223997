import { wrapEachWithThunkRequest } from 'utils/Fetch/thunkRequest';
import ApiRequest from 'utils/Fetch/ApiRequest';

import { MASTER_DATA_PATH, SERVICE_API_PATH } from './index';

export const searchSensorsFn = (request, buildingId, searchTerm, noCache) =>
  request.post({
    apiPath: `${SERVICE_API_PATH}/sensors/search`,
    data: { buildingId, searchTerm },
    noCache,
  });

export const sensorsFn = (request, filter) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/sensors`,
    data: { filter },
  });

export const upsertSensorFn = (request, sensor) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/sensors/upsert`,
    data: { sensor },
    noCache: true,
  });

export const moveSensorFn = (request, sensorId, targetBuildingId) =>
  request.post({
    apiPath: `${MASTER_DATA_PATH}/sensors/${sensorId}/move/${targetBuildingId}`,
    noCache: true,
  });

export const deleteSensorFn = (request, sensorId) =>
  request.delete({
    apiPath: `${MASTER_DATA_PATH}/sensors/${sensorId}`,
    noCache: true,
  });

export const saveTagsForSensors = ({ tags, overrideExisting }) =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/sensor-meta/tags`,
    data: tags,
    query: { overrideExisting },
    noCache: true,
    useAuthorization: true,
  });

/**
 * Call the service api to attach sensor hierarchies to sensors
 * @param sensorIds {number[]}
 * @param sensorHierarchyIds {number[]}
 * @returns {Promise<void>}
 */
export async function attachHierarchiesToSensors(sensorIds, sensorHierarchyIds) {
  await ApiRequest.request({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies/attach`,
    data: {
      sensorIds,
      sensorHierarchyIds,
    },
    noCache: true,
  });
}
/**
 * Call the service api to detach sensor hierarchies from sensors
 * @param sensorIds {number[]}
 * @param sensorHierarchyIds {number[]}
 * @returns {Promise<void>}
 */
export async function detachHierarchiesToSensors(sensorIds, sensorHierarchyIds) {
  await ApiRequest.request({
    method: 'POST',
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies/detach`,
    data: {
      sensorIds,
      sensorHierarchyIds,
    },
    noCache: true,
  });
}

export default wrapEachWithThunkRequest({
  searchSensors: searchSensorsFn,
  upsertSensor: upsertSensorFn,
  deleteSensor: deleteSensorFn,
  moveSensor: moveSensorFn,
});
