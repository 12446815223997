import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { startOfYear, startOfMonth, endOfYear, endOfMonth, set } from 'date-fns';
import range from 'lodash/range';
import isNil from 'lodash/isNil';

import { getMonthsShort } from 'utils/Date/dateFormatter';
import { useTranslations } from 'decorators/Translations/translations';
import withQuery from 'decorators/Query/withQuery';

import Svg from 'components/Svg/Svg';
import HorizontalScroll from 'components/HorizontalScroll/HorizontalScroll';
import MonthSelectorSkeleton from 'components/Calendar/CalendarMonthSelectorSkeleton';

import {
  Container,
  MonthSelector,
  Year,
  YearValue,
  Month,
  Value,
  Label,
  yearArrowHover,
  focustStyleForKeyboardUser,
} from 'components/Calendar/CalendarMonthSelector';

const ArrowIcon = styled(({ tiny, fill, ...props }) => <Svg {...props} />)`
  font-size: ${props => props.theme.font.size.xxs};
`;

export const YearSelector = styled.button`
  background-color: transparent;
  border-color: transparent;
  padding: 0 var(--size-xs);
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  height: 100%;
  display: flex;
  align-items: center;
  ${yearArrowHover}
  ${focustStyleForKeyboardUser}

  ${ArrowIcon} {
    fill: ${props => (props.disabled ? 'var(--time-selector-control-fg-disabled)' : 'var(--time-selector-control-fg)')};
  }
`;

const isDateBetween = (date, start, end) => {
  return date >= start && date <= end;
};

const calculateItemsWithin = ({ year, month, items = [], dateField }) => {
  const start = isFinite(month)
    ? startOfMonth(set(new Date(), { year, month }))
    : startOfYear(set(new Date(), { year }));
  const end = isFinite(month) ? endOfMonth(set(new Date(), { year, month })) : endOfYear(set(new Date(), { year }));
  return items.filter(item => item[dateField] && isDateBetween(item[dateField].value, start, end)).length;
};

export const ObservationsMonthSelector = ({
  items = [],
  onMonthClick,
  toggleActiveMonth = true,
  callMonthClickOnYearChange = true,
  dateField = 'date',
  query = {},
  setQuery,
  loading = false,
  className,
}) => {
  const [t] = useTranslations();

  const year = query.year ? Number(query.year) : undefined;
  const month = query.month ? Number(query.month) : undefined;
  const now = Date.now();
  const currentYear = new Date(now).getFullYear();
  const monthNames = getMonthsShort();
  const lastMonth = year === currentYear ? new Date(now).getMonth() + 1 : 12;
  const disableNextYear = year >= currentYear;
  const handleMonthClick = ({ year, month }) => {
    onMonthClick ? onMonthClick({ year, month }) : setQuery({ year, month });
  };
  const changeYear = year => {
    callMonthClickOnYearChange && onMonthClick ? onMonthClick({ year, month }) : setQuery({ year });
  };

  if (loading) {
    return <MonthSelectorSkeleton lastMonth={lastMonth} />;
  }

  return (
    <Container className={className}>
      <HorizontalScroll>
        <MonthSelector>
          <Year active={toggleActiveMonth && isNil(month) && year}>
            <YearSelector onClick={() => changeYear(year - 1)} data-test-id="previous-year-button">
              <ArrowIcon name="caret-left" />
            </YearSelector>
            <YearValue onClick={() => handleMonthClick({ year })}>
              <Value>{calculateItemsWithin({ items, year, dateField })}</Value>
              <Label>{`${t('Year')} ${year ?? ''}`}</Label>
            </YearValue>
            <YearSelector
              onClick={!disableNextYear ? () => changeYear(year + 1) : null}
              disabled={disableNextYear}
              data-test-id="next-year-button"
            >
              <ArrowIcon name="caret-right" />
            </YearSelector>
          </Year>
          {range(0, lastMonth).map(currentMonth => (
            <Month
              key={currentMonth}
              onClick={() => handleMonthClick({ year, month: currentMonth })}
              active={toggleActiveMonth && month === currentMonth}
            >
              <Value>{calculateItemsWithin({ items, year, month: currentMonth, dateField })}</Value>
              <Label>{monthNames[currentMonth]}</Label>
            </Month>
          ))}
        </MonthSelector>
      </HorizontalScroll>
    </Container>
  );
};

ObservationsMonthSelector.propTypes = {
  onMonthClick: PropTypes.func,
  callMonthClickOnYearChange: PropTypes.bool,
  items: PropTypes.array,
  query: PropTypes.shape({
    month: PropTypes.string,
    year: PropTypes.string,
  }),
  toggleActiveMonth: PropTypes.bool,
  dateField: PropTypes.string,
  setQuery: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default withQuery(ObservationsMonthSelector);
