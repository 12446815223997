import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const floorByIdWithPublicView = (publicViewId, floorId) => {
  return ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/sensor-hierarchies/${floorId}/with-public-view`,
    query: { publicViewId },
    useAuthorization: false,
    noCache: true,
  });
};
