import styled from 'styled-components';

export const InfoTable = styled.div`
  font-size: ${props => props.theme.font.size.xs};
  ul {
    display: grid;
    grid-auto-rows: repeat(auto-fit, minmax(0, 1fr));
    grid-auto-flow: row;
    @media (max-width: ${props => props.theme.breakpoints.landscape}px) and (min-width: ${props =>
        props.theme.breakpoints.mobile}px),
      print {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  li {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: 0.25em;
    border-bottom: var(--table-row-border);
    padding: 0.25em 0.25em 0.25em 0;

    @media (max-width: ${props => props.theme.breakpoints.landscape}px) and (min-width: ${props =>
        props.theme.breakpoints.mobile}px),
      print {
      grid-column: 1;
      &:nth-child(even) {
        grid-column: 2;
      }
    }
  }
`;

export const NameColumn = styled.div`
  vertical-align: middle;
  flex: 1 2 40%;
  font-weight: ${props => props.theme.fontWeight.semibold};
  font-size: ${props => props.theme.font.size.xxs};
  color: ${props => props.theme.colors.darkGray};
  padding-right: 0.5em;
  @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
    width: 100%;
    padding-right: 0;
  }
`;

export const ValueColumn = styled.div`
  vertical-align: middle;
  flex: 1 1 60%;
  font-size: ${props => props.theme.font.size.xxs};
  @media (max-width: ${props => props.theme.breakpoints.tablet}px), print {
    width: 100%;
  }
`;
