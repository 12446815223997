import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import Filter, { StyledFilter } from 'components/Filters/Filter';
import RadioButton from 'components/Form/RadioButton';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';

const StyledWasteTimeframeCategorySelector = styled.div`
  width: 300px;
  margin-top: var(--size-xs);
  margin-bottom: var(--size-md);
  text-transform: capitalize;

  ${StyledFilter} {
    margin: 0;
    max-width: 100%;
  }

  ${props => props.theme.media.desktop`
    width: 100%;
    margin-top: 0;
  `}
`;

export const WasteTimeframeCategorySelector = ({
  t,
  timeframeCategoryOptions,
  selectedTimeframeCategory,
  onTimeframeCategoryChange,
}) => {
  const currentValueLabel = timeframeCategoryOptions.find(option => option.value === selectedTimeframeCategory).label;
  return (
    <StyledWasteTimeframeCategorySelector>
      <Filter label={t('Timeframe')} currentValue={currentValueLabel}>
        <RadioButtonGroup
          name="timeframeCategory"
          value={selectedTimeframeCategory}
          onChange={(_, value) => onTimeframeCategoryChange(value)}
        >
          {timeframeCategoryOptions.map(option => (
            <RadioButton key={option.value} value={option.value} label={option.label} />
          ))}
        </RadioButtonGroup>
      </Filter>
    </StyledWasteTimeframeCategorySelector>
  );
};

WasteTimeframeCategorySelector.propTypes = {
  t: PropTypes.func.isRequired,
  timeframeCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedTimeframeCategory: PropTypes.string.isRequired,
  onTimeframeCategoryChange: PropTypes.func.isRequired,
};

export default translations(WasteTimeframeCategorySelector);
