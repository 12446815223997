import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: var(--size-xxs);
  }
`;

const Description = ({ description, identifier }) =>
  description.split('\n').map((row, index) => {
    if (row.trim().length > 0) {
      return <Paragraph key={`${identifier}-${index}`}>{row}</Paragraph>;
    }
    return null;
  });

Description.propTypes = {
  description: PropTypes.string.isRequired,
  identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Description;
