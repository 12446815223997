import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Subheader, Description } from '../common';
import useUnauthenticatedTranslations from 'decorators/Translations/useUnauthenticatedTranslations';
import { getActionSubheaderText, getActionDescriptionText, getManagerChangeSubheaderText } from './utils';

const AdditionalSaleConfirmation = ({ action, type, submitData }) => {
  const { t } = useUnauthenticatedTranslations();
  const isManagerChange = Boolean(submitData.managerEmail);
  return (
    <Container small>
      <Title>{t('Thank You')}!</Title>
      <Subheader>
        {isManagerChange
          ? getManagerChangeSubheaderText(t, submitData)
          : getActionSubheaderText(t, action, type, submitData)}
      </Subheader>
      <Description>{!isManagerChange && getActionDescriptionText(t, action, type, submitData)}</Description>
    </Container>
  );
};

AdditionalSaleConfirmation.propTypes = {
  action: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  submitData: PropTypes.object.isRequired,
};

export default AdditionalSaleConfirmation;
