import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { LOAD_TYPES_SUCCESS } from 'redux/modules/customer/sensorHierarchy';
import { LOAD_PUBLIC_LATEST_SENSORS_VALUES_SUCCESS } from 'redux/modules/iot/values/sensor_values';

const useStoreHelpers = () => {
  const dispatch = useDispatch();

  const setSensorTypes = sensors => {
    const sensorTypes = uniqBy(
      sensors.map(sensor => sensor.sensorType),
      'id'
    );
    dispatch({
      type: LOAD_TYPES_SUCCESS,
      result: sensorTypes,
    });
  };

  const setLatestSensorValues = latestValuesBySensorId => {
    dispatch({
      type: LOAD_PUBLIC_LATEST_SENSORS_VALUES_SUCCESS,
      result: latestValuesBySensorId,
    });
  };

  return {
    setSensorTypes,
    setLatestSensorValues,
  };
};

export default useStoreHelpers;
