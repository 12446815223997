import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const publicViews = ({ refreshCache = false } = {}) => {
  return ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/public-views`,
    useAuthorization: true,
    refreshCache,
  });
};

export const publicView = id => {
  return ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/public-views/${id}`,
    useAuthorization: false,
    noCache: true,
  });
};

export const upsertPublicView = data => {
  return ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/public-views/upsert`,
    data,
    useAuthorization: true,
    noCache: true,
  });
};

export const deletePublicView = id => {
  return ApiRequest.delete({
    apiPath: `${SERVICE_API_PATH}/public-views/${id}`,
    useAuthorization: true,
    noCache: true,
  });
};
