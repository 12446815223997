import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslations } from 'decorators/Translations/translations';

import FileUpload from 'components/FileUpload/FileUpload';
import FileList from 'components/Documents/FileList/FileList';
import { uploadUserFilesAsync } from 'utils/FileReader/utils';
import { MAX_SIZE } from 'components/Documents/DocumentManager/DocumentManager';
import InfoBox from 'components/InfoBox/InfoBox';

const Files = styled.div`
  margin-top: var(--size-xs);
`;

const FileUploadForm = ({ files = [], onChange }) => {
  const [t] = useTranslations();
  const [error, setError] = React.useState(null);

  const handleRemove = file => onChange(files.filter(oldFile => oldFile !== file));

  const handleUpload = async fileDataArray => {
    try {
      const newFiles = await uploadUserFilesAsync(t, fileDataArray, {
        maxSize: MAX_SIZE,
        mimeTypes: ['image/', 'application/pdf'],
      });
      // clear old errors
      if (error) {
        setError(null);
      }
      onChange(files.concat(newFiles));
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <FileUpload
        t={t}
        upload={handleUpload}
        accept="image/*,application/pdf"
        noMimeTypes
        header={t('Click and select file or drag and drop')}
        noIcon
        height="100px"
        multiple
      />
      {error && (
        <InfoBox error preWrap>
          {error}
        </InfoBox>
      )}
      <Files>
        <FileList showThumbnails showPreview files={files} onDelete={handleRemove} />
      </Files>
    </>
  );
};

FileUploadForm.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
};

export default FileUploadForm;
