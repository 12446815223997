import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BadgeDot = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  line-height: normal;
  padding: 0 6px;
  border-radius: 10px;
  font-size: ${props => props.theme.font.size.xxxs};
  font-weight: ${props => props.theme.font.weight.bold};
  text-align: center;
  color: var(--badge-fg);
  background-color: ${props => (props.isZero ? 'var(--badge-bg-zero)' : 'var(--badge-bg)')};
`;

const NumberBadge = ({ number, showZero, max, ...other }) => {
  const isZero = number === 0;
  const hidden = isZero && !showZero;
  const maxNumber = max ?? 99;
  const badgeContent = number > maxNumber ? `${maxNumber}+` : number;
  if (typeof number !== 'number' || hidden) return null;
  return (
    <BadgeDot isZero={isZero} {...other}>
      {badgeContent}
    </BadgeDot>
  );
};

NumberBadge.propTypes = {
  number: PropTypes.number,
  showZero: PropTypes.bool,
  max: PropTypes.number,
};

export default NumberBadge;
