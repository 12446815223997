import { ALLOW_EXPERIMENTAL_FEATURES } from 'utils/profile';

export const FeatureKeys = {
  SERVICE_ORDERS: 'serviceOrders',
  PLANNED_MAINTENANCE: 'plannedMaintenance',
  MAINTENANCE_MODULE: 'serviceModule',
  LONG_TERM_PLANNING: 'longTermPlanning',
  CHECKLISTS: 'checklists',
  TECHNICAL_TAB: 'technicalTab',
  EQUIPMENT: 'equipment',
  CONDITIONS: 'conditions',
  ENERGY_TAB: 'energyTab',
  ENERGY_OBSERVATIONS: 'energyObservations',
  RECYCLING: 'recycling',
  DOCUMENTS: 'documents',
  PORTFOLIO_DOCUMENTS: 'portfolioDocuments',
  BUILDING_AND_PORTFOLIO_ADMIN_TOOLS: 'buildingAndPortfolioAdminTools',
  CUSTOM_VIEWS: 'customViews',
  UPDATE_TRANSLATIONS: 'updateTranslations',
  PUBLIC_VIEWS: 'publicViews',
  CONTROL_ROOM_ALARMS: 'controlRoomAlarms',
  CONTROL_ROOM_OBSERVATIONS: 'controlRoomObservations',
  CONTROL_ROOM_INSPECTIONS: 'controlRoomInspections',
  FILES: 'files',
  FLOORS_TAB: 'floorsTab',
  BUILDING_AUTOMATION_TAB: 'buildingAutomationTab',
  CLEANING_TAB: 'cleaningTab',
  CUSTOM_CHARTS: 'customCharts',
  ANNOUNCEMENTS_TAB: 'announcementsTab',
  INSIGHTS: 'insights',
  BMS_ANALYTICS_TAB: 'bmsAnalyticsTab',
  EXPERIMENTS: 'experiments',
  SERVICE_REQUEST: 'sr',
  API: 'api',
  SRI: 'sri',
  SRI_TOOLS: 'sriTools',
  RESCUE_PLAN: 'rescuePlan',
  SUSTAINABILITY: 'sustainabilityTab',
  DEVIATIONS: 'deviations',
  DEVIATION_TOOLS: 'deviationTools',
};

/**
 * Do not add new features as true unless you have a confirmation from business
 * that they are needed to be set on by default. Never add beta features as true.
 */
export const NewProfileFeatureDefaults = {
  [FeatureKeys.SERVICE_ORDERS]: true,
  [FeatureKeys.PLANNED_MAINTENANCE]: true,
  [FeatureKeys.EQUIPMENT]: false,
  [FeatureKeys.TECHNICAL_TAB]: false,
  [FeatureKeys.CONDITIONS]: false,
};

export const FeatureDefaults = {
  [FeatureKeys.SERVICE_ORDERS]: true,
  [FeatureKeys.PLANNED_MAINTENANCE]: true,
  [FeatureKeys.EQUIPMENT]: true,
  [FeatureKeys.TECHNICAL_TAB]: true,
  [FeatureKeys.CONDITIONS]: true,
  [FeatureKeys.DOCUMENTS]: false,
  [FeatureKeys.FILES]: false,
  [FeatureKeys.ENERGY_TAB]: false,
  [FeatureKeys.FLOORS_TAB]: false,
  [FeatureKeys.SERVICE_REQUEST]: false,
  [FeatureKeys.MAINTENANCE_MODULE]: false,
  [FeatureKeys.LONG_TERM_PLANNING]: false,
  [FeatureKeys.BUILDING_AUTOMATION_TAB]: false,
  [FeatureKeys.CLEANING_TAB]: false,
  [FeatureKeys.ANNOUNCEMENTS_TAB]: false,
  [FeatureKeys.CONTROL_ROOM_ALARMS]: false,
  [FeatureKeys.CONTROL_ROOM_OBSERVATIONS]: false,
  [FeatureKeys.CONTROL_ROOM_INSPECTIONS]: false,
  [FeatureKeys.EXPERIMENTS]: false,
  [FeatureKeys.RESCUE_PLAN]: false,
  [FeatureKeys.DEVIATIONS]: false,
  [FeatureKeys.DEVIATION_TOOLS]: false,
};

export const userHasFeature = (featureKey, userFeatures) => {
  if (!userFeatures) {
    return false;
  }
  if (userFeatures[featureKey] === undefined && FeatureDefaults[featureKey] !== undefined) {
    return FeatureDefaults[featureKey];
  }
  return Boolean(userFeatures[featureKey]);
};

export const isAdminRole = role => {
  return ['caverion-admin', 'customer-admin'].indexOf(role) > -1;
};

export const isCaverionAdminRole = role => {
  return ['caverion-admin'].indexOf(role) > -1;
};

export const isCaverionAdminOrUserRole = role => {
  return ['caverion-admin', 'caverion-user'].indexOf(role) > -1;
};

export const isAdminOrCaverionUserRole = role => {
  return ['caverion-admin', 'customer-admin', 'caverion-user'].indexOf(role) > -1;
};

export const isCustomerAdminRole = role => {
  return ['customer-admin'].indexOf(role) > -1;
};

export const isMaintenanceEnabled = features =>
  isServiceOrdersEnabled(features) || isPlannedMaintenanceEnabled(features) || isLongTermPlanningEnabled(features);

export const isServiceOrdersEnabled = features => userHasFeature(FeatureKeys.SERVICE_ORDERS, features);

export const isPlannedMaintenanceEnabled = features => userHasFeature(FeatureKeys.PLANNED_MAINTENANCE, features);

export const isMaintenanceModuleEnabled = features => userHasFeature(FeatureKeys.MAINTENANCE_MODULE, features);

export const isServiceRequestToolsEnabled = features =>
  isServiceOrdersEnabled(features) && isMaintenanceModuleEnabled(features);

export const isLongTermPlanningEnabled = features => userHasFeature(FeatureKeys.LONG_TERM_PLANNING, features);

export const isChecklistsEnabled = features => userHasFeature(FeatureKeys.CHECKLISTS, features);

export const isDeviationsEnabled = features => userHasFeature(FeatureKeys.DEVIATIONS, features);

export const isDeviationToolsEnabled = features => userHasFeature(FeatureKeys.DEVIATION_TOOLS, features);

export const isTechnicalTabEnabled = features => userHasFeature(FeatureKeys.TECHNICAL_TAB, features);

export const isEquipmentEnabled = features => userHasFeature(FeatureKeys.EQUIPMENT, features);

export const isConditionsEnabled = features => userHasFeature(FeatureKeys.CONDITIONS, features);

export const isPartnerEnergyEnabled = features => userHasFeature(FeatureKeys.ENERGY_TAB, features);

export const isEnergyEnabled = features => userHasFeature(FeatureKeys.ENERGY_TAB, features);

export const isEnergyObservationsEnabled = features => userHasFeature(FeatureKeys.ENERGY_OBSERVATIONS, features);

export const isPartnerRecyclingEnabled = features => userHasFeature(FeatureKeys.RECYCLING, features);

export const isRemoteCenterAlarmsEnabled = features => userHasFeature(FeatureKeys.CONTROL_ROOM_ALARMS, features);

export const isRemoteCenterObservationsEnabled = features =>
  userHasFeature(FeatureKeys.CONTROL_ROOM_OBSERVATIONS, features);

export const isRemoteCenterInspectionsEnabled = features =>
  userHasFeature(FeatureKeys.CONTROL_ROOM_INSPECTIONS, features);

export const isPortfolioDocumentsEnabled = features => userHasFeature(FeatureKeys.PORTFOLIO_DOCUMENTS, features);

export const isSriEnabled = features => userHasFeature(FeatureKeys.SRI, features);

export const isSriToolsEnabled = features => userHasFeature(FeatureKeys.SRI_TOOLS, features);

export const isSustainabilityEnabled = features => userHasFeature(FeatureKeys.SUSTAINABILITY, features);

export const hasBuildingAndPortfolioAdminTools = profile =>
  userHasFeature(FeatureKeys.BUILDING_AND_PORTFOLIO_ADMIN_TOOLS, profile.features);

export const canEditBuilding = profile =>
  userHasFeature(FeatureKeys.BUILDING_AND_PORTFOLIO_ADMIN_TOOLS, profile.features);

export const canEditPartner = profile =>
  userHasFeature(FeatureKeys.BUILDING_AND_PORTFOLIO_ADMIN_TOOLS, profile.features);

export const canManageTags = profile =>
  userHasFeature(FeatureKeys.BUILDING_AND_PORTFOLIO_ADMIN_TOOLS, profile.features);

export const canEditGeneralAnnouncements = role => isCaverionAdminRole(role);

export const hasAccessToAdminPanel = profile =>
  isAdminRole(profile.role) || canConfigureCustomViews(profile) || canUpdateTranslations(profile);

export const hasAccessToAdminRoute = profile =>
  isAdminRole(profile.role) ||
  hasBuildingAndPortfolioAdminTools(profile) ||
  canConfigureCustomViews(profile) ||
  canUpdateTranslations(profile);

export const canConfigureCustomViews = profile => userHasFeature(FeatureKeys.CUSTOM_VIEWS, profile.features);

export const canUpdateTranslations = profile => userHasFeature(FeatureKeys.UPDATE_TRANSLATIONS, profile.features);

export const canConfigurePublicViews = profile => userHasFeature(FeatureKeys.PUBLIC_VIEWS, profile.features);

export const isControlRoomTabEnabled = features =>
  userHasFeature(FeatureKeys.CONTROL_ROOM_ALARMS, features) ||
  userHasFeature(FeatureKeys.CONTROL_ROOM_OBSERVATIONS, features) ||
  userHasFeature(FeatureKeys.CONTROL_ROOM_INSPECTIONS, features);

export const isBuildingManagementEnabled = features =>
  isControlRoomTabEnabled(features) ||
  userHasFeature(FeatureKeys.FLOORS_TAB, features) ||
  userHasFeature(FeatureKeys.CONDITIONS, features) ||
  userHasFeature(FeatureKeys.ENERGY_TAB, features) ||
  userHasFeature(FeatureKeys.BUILDING_AUTOMATION_TAB, features) ||
  userHasFeature(FeatureKeys.CLEANING_TAB, features) ||
  userHasFeature(FeatureKeys.ANNOUNCEMENTS_TAB, features);

export const isRescuePlanEnabled = features => userHasFeature(FeatureKeys.RESCUE_PLAN, features);

export const isExperimentalFeaturesEnabled = features =>
  ALLOW_EXPERIMENTAL_FEATURES && userHasFeature(FeatureKeys.EXPERIMENTS, features);
