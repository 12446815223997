import React from 'react';
import PropTypes from 'prop-types';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
import accessibility from 'highcharts/modules/accessibility';
import styled, { useTheme } from 'styled-components';

import SkeletonChart from 'components/Skeletons/SkeletonChart';
import Loader from 'components/Loader/Loader';

import useBarChartConfigs from './useBarChartConfigs';
import { useTranslations } from 'decorators/Translations/translations';
import useComparisonChartFormatting from './ComparisonChart/useComparisonChart';

drilldown(Highstock);
accessibility(Highstock);

const StyledBarChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .bar-chart {
    z-index: 1 !important;
  }
  .highcharts-breadcrumbs-button.highcharts-button-hover {
    rect {
      fill: ${props => props.theme.colors.lightGray} !important;
    }
  }
`;

const noData = props => {
  if (!props.series || props.series.length === 0) {
    return true;
  }
  if (!props.series.some(series => series?.data?.length)) {
    return true;
  }
  return false;
};

const ComparisonBarChart = props => {
  const { loading, error, immutable } = props;

  const [t] = useTranslations();
  const componentRef = React.useRef();
  const chartRef = React.useRef();
  const theme = useTheme();
  const { dateTimeLabelFormatter } = useComparisonChartFormatting({ intervalType: props.intervalType });
  const options = useBarChartConfigs({ ...props, t, theme, labelFormatter: dateTimeLabelFormatter });

  const hasNoData = noData(props);

  let skeletonContent;
  if (loading) {
    skeletonContent = <Loader color="BLUE" size="LARGE" />;
  } else if (hasNoData) {
    skeletonContent = error || t('No data available');
  }

  if (skeletonContent) {
    return (
      <SkeletonChart width="100%" height="100%">
        {skeletonContent}
      </SkeletonChart>
    );
  }

  return (
    <StyledBarChart ref={componentRef}>
      <HighchartsReact
        ref={chartRef}
        containerProps={{ style: { width: '100%', height: '100%' } }}
        highcharts={Highstock}
        options={options}
        immutable={immutable}
      />
    </StyledBarChart>
  );
};

ComparisonBarChart.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  immutable: PropTypes.bool,
  intervalType: PropTypes.string,
};

ComparisonBarChart.defaultProps = {
  labelStyle: {},
  immutable: false,
  disableAnimation: false,
};

export default ComparisonBarChart;
