import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Svg from 'components/Svg/Svg';
import Loader from 'components/Loader/Loader';

const StyledStatusInfoTrend = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fontFamily.heading};
  text-align: center;
  height: 46px; /* Fix height to be same as the Gauge chart svg height */

  ${props => props.theme.media.portrait`
    margin: var(--size-xxs);
  `}

  ${props => props.theme.media.landscape`
    margin: var(--size-md);
  `}
`;

const Icon = styled(({ positive, up, noValue, isPerformance, ...props }) => <Svg {...props} />)`
  transform: ${props => !props.isPerformance && (props.up ? 'rotate(-45deg)' : 'rotate(45deg)')};
  width: 90%;
  height: 90%;
  fill: ${props =>
    props.noValue
      ? 'var(--neutral-color)'
      : props.positive
      ? 'var(--success-color)'
      : props.isPerformance
      ? 'var(--warning-color)'
      : 'var(--alarm-color)'};
`;

const StatusInfoTrend = props => {
  const { loading, positiveValue, positiveValueDirection, value, isPerformance } = props;
  const direction = positiveValue ? positiveValueDirection : positiveValueDirection === 'up' ? 'down' : 'up';

  if (loading) {
    return (
      <StyledStatusInfoTrend>
        <Loader color="GRAY" />
      </StyledStatusInfoTrend>
    );
  }

  return (
    <StyledStatusInfoTrend>
      <Icon
        name={isPerformance ? 'star' : 'slim-arrow'}
        positive={positiveValue}
        up={direction === 'up'}
        noValue={!value}
        isPerformance={isPerformance}
      />
    </StyledStatusInfoTrend>
  );
};

StatusInfoTrend.propTypes = {
  loading: PropTypes.bool,
  positiveValue: PropTypes.bool,
  positiveValueDirection: PropTypes.string,
  value: PropTypes.number,
  isPerformance: PropTypes.bool,
};

export default StatusInfoTrend;
