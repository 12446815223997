import memoizeOne from 'memoize-one';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import first from 'lodash/first';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';

import { getAreaPresenceFeature, getIconFeature, getInfotipContent } from './blueprint';
import { SENSOR_GROUP } from 'containers/Application/Admin/Building/BuildingSensors/EditOrCreateGroup';

// Get enabled sensors from parent sensors and their children
export const getEnabledSensors = sensors => {
  // Get enabled parent sensors and their filtered children
  const enabledParents = sensors
    .filter(sensor => !sensor.disabled)
    .map(sensor =>
      sensor?.children?.length
        ? {
            ...sensor,
            children: getEnabledSensors(sensor.children),
          }
        : sensor
    );
  // Get enabled children that have their parent disabled and mark those as independent
  const enabledChildrenWithDisabledParent = sensors
    .filter(sensor => sensor.disabled && sensor?.children?.length)
    .flatMap(sensor =>
      getEnabledSensors(sensor.children).map(child => ({
        ...child,
        parentDisabled: true, // Add new property to identify independent children
        ...(child?.children?.length && { children: getEnabledSensors(child.children) }),
      }))
    );
  // Filter duplicates from parents (if child was already there for some reason)
  const filtered = enabledParents.filter(parent => {
    if (enabledChildrenWithDisabledParent.some(child => parent.id === child.id)) {
      return false;
    }
    return true;
  });
  return filtered.concat(enabledChildrenWithDisabledParent);
};

export const getBuildingSensorsFromHierarchy = memoizeOne((buildingHierarchy, includeDisabled) => {
  if (!buildingHierarchy) {
    return [];
  }
  const categories = buildingHierarchy.children || [];
  /*
    Iterate tree or building -hierarchy,with children hierarchies related to that building. Sensors can be on any level.
    Currently is agreed that no more than one level of subhierarchies is allowed.
  */
  let sensorsResult = [];
  if (buildingHierarchy.sensors) {
    sensorsResult = sensorsResult.concat(buildingHierarchy.sensors);
  }
  categories.forEach(child => {
    if (child && child.sensors) {
      sensorsResult = sensorsResult.concat(child.sensors);
    }
  });
  // Get sensors that are subsensors
  sensorsResult.forEach(sensor => {
    if (sensor && sensor.children && sensor.children.length > 0) {
      sensorsResult = sensorsResult.concat(sensor.children);
    }
  });
  sensorsResult = uniqBy(sensorsResult, 'id');
  if (includeDisabled) {
    return sensorsResult;
  }
  return getEnabledSensors(sensorsResult);
});

export const getSensorFromHierarchy = (buildingHierarchy, sensorId) => {
  if (!buildingHierarchy) {
    return;
  }

  // Search tree recursively to find node with given id
  const searchNode = node => {
    if (node.id === sensorId) {
      return node;
    }

    if (node.sensors) {
      // Search from sensors and their children
      const sensors = node.sensors.reduce((sensors, sensor) => {
        sensors.push(sensor);
        sensors = sensor.children ? sensors.concat(sensor.children) : sensors;
        return sensors;
      }, []);

      const sensor = sensors.find(sensor => sensor.id === sensorId);
      if (sensor) {
        return sensor;
      }
    }

    if (node.children) {
      for (const index in node.children) {
        const sensor = searchNode(node.children[index]);
        if (sensor) {
          return sensor;
        }
      }
    }
  };

  return searchNode(buildingHierarchy);
};

export const getHierarchies = parentHierarchy => {
  const categories = parentHierarchy.children || [];
  return [parentHierarchy].concat(categories);
};

export const findHierarchy = (buildingHierarchy, hierarchyId) => {
  return find(getHierarchies(buildingHierarchy), { id: hierarchyId });
};

export const getBuildingFloorsFromHierarchy = buildingHierarchy => {
  return sortBy(filter(getHierarchies(buildingHierarchy), { type: 'floor' }), ['order']);
};

export const getBuildingGroupsFromHierarchy = buildingHierarchy => {
  return sortBy(
    filter(getHierarchies(buildingHierarchy), hierarchy => {
      return Object.values(SENSOR_GROUP).includes(hierarchy.type);
    }),
    ['name']
  );
};

export const getBuildingAreasFromHierarchy = buildingHierarchy => {
  let areas = [];
  const hierarchies = getHierarchies(buildingHierarchy);
  if (hierarchies && hierarchies.length > 0) {
    hierarchies.forEach(child => {
      if (child && child.children && child.children.length > 0) {
        areas = areas.concat(filter(child.children, { type: 'area' }));
      }
    });
  }
  return areas;
};

export const getBuildingCoordsFromHierarchy = buildingHierarchy => {
  let areas = [];
  buildingHierarchy.children.forEach(child => {
    if (child && child.children && child.children.length > 0) {
      areas = areas.concat(filter(child.children, { type: 'area' }));
    }
  });
  const sensorsResult = getBuildingSensorsFromHierarchy(buildingHierarchy);

  // Combine coords of both sensors and areas in to same array.
  const mappedCoords = concat(flatten(compact(map(areas, 'coords'))), flatten(compact(map(sensorsResult, 'coords'))));

  return mappedCoords;
};

export const getFloorFeatures = (
  floor,
  latestValuesBySensorId,
  t,
  showInfotips,
  editId,
  buildingMeta,
  sensorAlarmsById,
  sensorValueIndicatorTitle
) => {
  const floorImage = find(floor.images, { type: 'floor' });
  const floorImagePath = floorImage ? floorImage.path : null;
  const floorAreas = filter(floor.children, { type: 'area' });
  const floorSensors = !!floor.sensors ? getEnabledSensors(floor.sensors) : [];
  // Use compact to remove empty objects from array
  const areaFeatures = compact(
    floorAreas.map(area => {
      const areaCoords = find(area.coords, { type: 'area' });
      if (!areaCoords || !first(areaCoords.area)) {
        return null;
      }
      return createFeature(
        [first(areaCoords.area).map(coord => [coord.x, coord.y])],
        area,
        'area',
        area.name,
        latestValuesBySensorId,
        t,
        false,
        editId,
        buildingMeta,
        sensorAlarmsById,
        sensorValueIndicatorTitle
      );
    })
  );

  const sensorFeatures = compact(
    floorSensors.map(sensor => {
      const sensorCoords = find(sensor.coords, { type: 'sensor' });
      if (!sensorCoords || !sensorCoords.point) {
        return null;
      }
      return createFeature(
        [sensorCoords.point.x, sensorCoords.point.y],
        sensor,
        sensor.sensorType && sensor.sensorType.name,
        sensor.displayName || sensor.name,
        latestValuesBySensorId,
        t,
        showInfotips,
        editId,
        buildingMeta,
        sensorAlarmsById,
        sensorValueIndicatorTitle
      );
    })
  );

  const floorIcons = uniq(sensorFeatures.map(feature => feature.properties && feature.properties.icon));

  return {
    floorAreas,
    floorSensors,
    areaFeatures,
    sensorFeatures,
    floorImage,
    floorImagePath,
    floorIcons,
  };
};

const notPresence = object => !(object && object.sensorType && object.sensorType.graphType === 'presence');

// Create OpenLayers feature from area or measuring point
export const createFeature = (
  coords,
  object,
  type,
  name,
  latestValuesBySensorId,
  t,
  showInfotips,
  editId,
  buildingMeta,
  sensorAlarmsById,
  sensorValueIndicatorTitle
) => ({
  type: 'Feature',
  geometry: {
    type: type === 'area' ? 'Polygon' : 'Point',
    coordinates: coords,
  },
  properties: {
    title: notPresence(object) && name,
    areaId: type === 'area' && object.id,
    sensorId: type !== 'area' && object.id,
    type,
    icon: type !== 'area' && getIconFeature(type, object.id, latestValuesBySensorId, editId),
    ...(type === 'area' && getAreaPresenceFeature(object, latestValuesBySensorId)),
    infotip:
      type !== 'area' &&
      notPresence(object) &&
      showInfotips &&
      getInfotipContent(object, latestValuesBySensorId, t, buildingMeta, sensorAlarmsById, sensorValueIndicatorTitle),
    editable: object.id === editId,
  },
});

export const getSensorBuildingOrFloorParentFromHierarchy = (sensorId, hierarchy, buildingId) => {
  const hierarchies = getHierarchies(hierarchy);
  let parent = null;
  if (hierarchies && hierarchies.length > 0) {
    hierarchies.forEach(child => {
      if (child && (child.type === 'floor' || child.type === 'building') && child.sensors && child.sensors.length > 0) {
        const sensor = find(child.sensors, { id: sensorId });
        if (sensor && sensor.id) {
          parent = child;
        }
      }
    });
  }
  // Default to building if building ID given
  if (!parent && buildingId) {
    parent = find(hierarchies, { id: buildingId });
  }
  return parent;
};

export const getAssignedGroupsFromHierarchy = (hierarchy, sensorId) => {
  let sensorHierarchies = [];
  if (!hierarchy) {
    return [];
  }
  if (hierarchy.sensors && hierarchy.sensors.length > 0) {
    if (some(hierarchy.sensors, { id: sensorId })) {
      sensorHierarchies.push({
        id: hierarchy.id,
        type: hierarchy.type,
        name: hierarchy.name,
        shortName: hierarchy.shortName,
      });
    }
  }
  if (hierarchy.children && hierarchy.children.length > 0) {
    hierarchy.children.forEach(child => {
      sensorHierarchies = sensorHierarchies.concat(getAssignedGroupsFromHierarchy(child, sensorId));
    });
  }
  return sensorHierarchies;
};

export const getSensorCategories = memoizeOne(buildingHierarchy =>
  buildingHierarchy && buildingHierarchy.length ? buildingHierarchy[0].children.filter(child => !!child.sensors) : []
);
