import React from 'react';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';
import { ObservationViewValue } from 'components/Observations/ObservationView';

export const StyledImprovementColumn = styled.div`
  max-width: 800px;
  display: flex;
  align-items: center;
`;

const ImprovementColumn = ({ t, data = 0 }) => {
  if (!data || data === 0) {
    return null;
  }
  return (
    <StyledImprovementColumn>
      <ObservationViewValue noMargin value={data}>
        <strong>{`${data / 1000} MWh`}</strong>
        {` / ${t('year')}`}
      </ObservationViewValue>
    </StyledImprovementColumn>
  );
};

export default translations(ImprovementColumn);
