import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import memoizeOne from 'memoize-one';
import subHours from 'date-fns/subHours';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import startOfHour from 'date-fns/startOfHour';
import endOfHour from 'date-fns/endOfHour';
import subYears from 'date-fns/subYears';
import toDate from 'date-fns/toDate';
import isSameDay from 'date-fns/isSameDay';
import isSameHour from 'date-fns/isSameHour';
import isValid from 'date-fns/isValid';
import { startOfUTCYear, endOfUTCYear, startOfUTCDay, endOfUTCDay } from 'utils/Date/date';
import differenceInDays from 'date-fns/differenceInDays';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import endOfDay from 'date-fns/endOfDay';

import InputDate from 'components/Form/InputDate/InputDate';
import InputYear from 'components/Form/InputYear';
import InputLabel, { Label } from 'components/Form/InputLabel';
import Svg from 'components/Svg/Svg';
import ButtonGroup, { ButtonGroupButton } from './ButtonGroup';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  align-items: end;
  ${props => props.theme.media.portrait`
    justify-content: ${({ alignment }) => {
      if (alignment === 'left') {
        return 'flex-start';
      } else if (alignment === 'right') {
        return 'flex-end';
      }
      return 'center';
    }};
  `}
`;
Container.displayName = 'Container';

const QuickRangeWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  ${props => (props.arrows ? 'margin-top: var(--size-md);' : 'margin-bottom: var(--size-md);')}

  ${props => props.theme.media.portrait`
    margin-top: 0;
    ${props => (props.arrows ? 'margin-left: var(--size-md);' : 'margin-right: var(--size-md);')}
    margin-bottom: var(--size-xs);
  `}

  & > ${Label} {
    line-height: 2.5em;
  }
`;
QuickRangeWrapper.displayName = 'QuickRangeWrapper';

const RowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  ${props => props.theme.media.portrait`
    justify-content: center
  `}
`;
RowWrapper.displayName = 'RowWrapper';

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: var(--size-md);
  &:last-child {
    margin-bottom: var(--size-xs);
  }

  ${props => props.theme.media.portrait`
    margin-bottom: var(--size-xs);
    width: 200px;
  `}

  ${props => props.theme.media.desktop`
    width: 250px;
  `}

    & > label {
    width: 100%;
    display: inline-block;
  }

  & > ${Label} {
    line-height: 2.5em;
  }

  .Select-control {
    width: 100%;
    height: 2.5em;
  }
  .Select-value {
    display: flex;
    align-items: center;
  }
`;
InputWrapper.displayName = 'InputWrapper';

const Icon = styled(Svg)`
  fill: var(--black);
  font-size: ${props => props.theme.font.size.xxs};
`;
Icon.displayName = 'Icon';

export const YEARLY_AGGREGATION_FREQUENCY = 'yearly';

export const DATE_RANGES = {
  HOURS_24: '24 h',
  DAYS_7: '7 d',
  DAYS_30: '30 d',
  DAYS_365: '365 d',
  YEARS_5: '5 y',
};

/**
 * Get date ranges
 * @param timestamp
 */
export const getDateRanges = memoizeOne(
  (timestamp = new Date(), showAllOption, useUTC = false) => {
    const date = toDate(timestamp);
    const startOfDayFunc = useUTC ? startOfUTCDay : startOfDay;
    const dateRanges = {
      [DATE_RANGES.HOURS_24]: {
        value: [startOfHour(subHours(date, 24)), endOfHour(date)],
        label: DATE_RANGES.HOURS_24,
        window: [24, 'hours'],
      },
      [DATE_RANGES.DAYS_7]: {
        value: [startOfDayFunc(subDays(date, 6)), endOfHour(date)],
        label: DATE_RANGES.DAYS_7,
        window: [6, 'days'],
      },
      [DATE_RANGES.DAYS_30]: {
        value: [startOfDayFunc(subDays(date, 29)), endOfHour(date)],
        label: DATE_RANGES.DAYS_30,
        window: [29, 'days'],
      },
      [DATE_RANGES.DAYS_365]: {
        value: [startOfDayFunc(subDays(date, 364)), endOfHour(date)],
        label: DATE_RANGES.DAYS_365,
        window: [364, 'days'],
      },
      [DATE_RANGES.YEARS_5]: {
        value: [startOfUTCYear(subYears(date, 4)), endOfHour(date)],
        label: DATE_RANGES.YEARS_5,
        window: [4, 'years'],
        shortcutDisabled: true,
      },
    };
    if (showAllOption) {
      dateRanges.All = {
        value: [toDate(0), endOfHour(date)],
        label: 'All',
      };
    }
    return dateRanges;
  },
  (currArgs, prevArgs) => currArgs.length === 1 && prevArgs.length === 1 && isSameHour(currArgs[0], prevArgs[0])
);

const isSameRange = (range1, range2) =>
  !!(range1 && range2 && isSameDay(range1[0], range2[0]) && isSameDay(range1[1], range2[1]));

class DateTools extends PureComponent {
  state = {};
  startInputId = 'DateTools-start-input';
  endInputId = 'DateTools-end-input';

  componentDidMount() {
    const {
      model: { startDatetime, endDatetime },
      defaultRange,
      showAllOption,
    } = this.props;
    let quickRangeOption;
    const dateRanges = getDateRanges(new Date(), showAllOption);

    if (startDatetime && endDatetime) {
      quickRangeOption = find(dateRanges, option => isSameRange(option.value, [startDatetime, endDatetime]));
    } else {
      quickRangeOption = find(dateRanges, { label: defaultRange });
    }

    const quickRange = quickRangeOption && quickRangeOption.value;

    const window = quickRangeOption ? quickRangeOption.window : [differenceInDays(endDatetime, startDatetime), 'days'];

    this.setState({
      start: startDatetime || quickRange[0],
      end: endDatetime || quickRange[1],
      quickRange,
      window,
    });
  }

  validateModelDates(...dates) {
    return dates.every(date => isValid(date));
  }

  hasModelDataChanged(prevModel = {}) {
    const { start, end } = this.state;
    const { startDatetime, endDatetime } = this.props.model;
    const { startDatetime: prevStartDatetime, endDatetime: prevEndDatetime } = prevModel;

    if (!this.validateModelDates(startDatetime, endDatetime)) {
      return false;
    }
    if (startDatetime?.getTime() !== prevStartDatetime?.getTime() && startDatetime?.getTime() !== start?.getTime()) {
      return true;
    }
    if (endDatetime?.getTime() !== prevEndDatetime?.getTime() && endDatetime?.getTime() !== end?.getTime()) {
      return true;
    }
  }

  componentDidUpdate(prevProps, { start: prevStart, end: prevEnd }) {
    const { start, end, quickRange, wasUpdatedFromParent } = this.state;
    const { onChange, updateFromModel, model, aggregationFreq, useUTC } = this.props;
    const now = new Date();

    if (updateFromModel && this.hasModelDataChanged(prevProps.model)) {
      this.handleModelChange(model);
      return;
    }

    if (!start || !end || wasUpdatedFromParent) {
      return;
    }

    if (aggregationFreq === YEARLY_AGGREGATION_FREQUENCY) {
      if (start?.getTime() !== prevStart?.getTime()) {
        // We want year value to start from UTC start of year
        const newValue = startOfUTCYear(start);
        onChange('startDatetime', newValue);
      }

      if (end?.getTime() !== prevEnd?.getTime()) {
        // We want year value to end to UTC end of year
        const newValue = endOfUTCYear(end);
        onChange('endDatetime', newValue);
      }
    } else {
      if (start?.getTime() !== prevStart?.getTime()) {
        const startOfDayFunc = useUTC ? startOfUTCDay : startOfDay;
        // Start time should always be beginning of the day, unless user has selected 24h
        const newValue = isSameDay(subDays(now, 1), start) && !isNil(quickRange) ? start : startOfDayFunc(start);
        onChange('startDatetime', newValue);
      }

      if (end?.getTime() !== prevEnd?.getTime()) {
        const endOfDayFunc = useUTC ? endOfUTCDay : endOfDay;
        // End time should be end of the day, unless it is current day
        const newValue = isSameDay(now, end) ? endOfHour(now) : endOfDayFunc(end);
        onChange('endDatetime', newValue);
      }
    }
  }

  handleQuickRange = (value, window) =>
    this.setState({
      start: value[0],
      end: value[1],
      quickRange: value,
      window,
      wasUpdatedFromParent: false,
    });

  handleModelChange = ({ startDatetime, endDatetime }) =>
    this.setState({
      start: startDatetime,
      end: endDatetime,
      quickRange: null,
      wasUpdatedFromParent: true,
      window: [differenceInDays(endDatetime, startDatetime), 'days'],
    });

  handleDateChange = (property, value) => {
    return this.setState({ [property]: value, quickRange: null, wasUpdatedFromParent: false }, () =>
      this.setState(oldState => ({ window: [differenceInDays(oldState.end, oldState.start), 'days'] }))
    );
  };

  handleYearChange = (property, value) => this.handleDateChange(property, toDate(value));

  handleLeftArrow = () =>
    this.setState(oldState => ({
      start: sub(oldState.start, { [oldState.window[1]]: oldState.window[0] }),
      end: sub(oldState.end, { [oldState.window[1]]: oldState.window[0] }),
      wasUpdatedFromParent: false,
    }));

  handleRightArrow = () =>
    this.setState(oldState => ({
      start: add(oldState.start, { [oldState.window[1]]: oldState.window[0] }),
      end: add(oldState.end, { [oldState.window[1]]: oldState.window[0] }),
      wasUpdatedFromParent: false,
    }));

  renderDatePickers = (t, placement) => (
    <RowWrapper>
      <InputWrapper>
        <InputLabel text={t('From')} />
        <InputDate
          fixedHeight
          iconName="calendar-date"
          property="start"
          id={this.startInputId}
          model={this.state}
          onChange={this.handleDateChange}
          clearable={false}
          selectsStart
          startDateProperty="start"
          endDateProperty="end"
          placement={placement}
          disabled={this.state.window === undefined}
          showMonthDropdown
          showYearDropdown
        />
      </InputWrapper>
      <InputWrapper>
        <InputLabel text={t('To')} />
        <InputDate
          fixedHeight
          iconName="calendar-date"
          property="end"
          id={this.endInputId}
          model={this.state}
          onChange={this.handleDateChange}
          clearable={false}
          selectsEnd
          startDateProperty="start"
          endDateProperty="end"
          placement={placement}
          showMonthDropdown
          showYearDropdown
        />
      </InputWrapper>
    </RowWrapper>
  );

  renderYearSelectors = t => (
    <RowWrapper>
      <InputWrapper>
        <InputLabel text={t('From')} />
        <InputYear useStartOfYear model={this.state} property="start" onChange={this.handleYearChange} />
      </InputWrapper>
      <InputWrapper>
        <InputLabel text={t('To')} />
        <InputYear useEndOfYear model={this.state} property="end" onChange={this.handleYearChange} />
      </InputWrapper>
    </RowWrapper>
  );

  render() {
    const { t, children, alignment, placement, showArrows, disabledButtons, showAllOption, aggregationFreq } =
      this.props;
    const { quickRange } = this.state;

    return (
      <Container alignment={alignment}>
        {aggregationFreq !== 'yearly' && (
          <QuickRangeWrapper>
            <InputLabel text={t('Time scale')} />
            <ButtonGroup>
              {map(getDateRanges(new Date(), showAllOption), ({ value, label, window, shortcutDisabled }, key) => {
                if ((disabledButtons && includes(disabledButtons, key)) || shortcutDisabled === true) {
                  return null;
                }
                return (
                  <ButtonGroupButton
                    onClick={event => {
                      event.preventDefault();
                      this.handleQuickRange(value, window);
                    }}
                    selected={isSameRange(value, quickRange)}
                    key={label}
                  >
                    {label}
                  </ButtonGroupButton>
                );
              })}
            </ButtonGroup>
          </QuickRangeWrapper>
        )}
        {aggregationFreq === 'yearly' ? this.renderYearSelectors(t) : this.renderDatePickers(t, placement)}
        {showArrows && (
          <QuickRangeWrapper arrows>
            <ButtonGroup>
              <ButtonGroupButton thin onClick={this.handleLeftArrow} selected={false}>
                <Icon name="caret-left" />
              </ButtonGroupButton>
              <ButtonGroupButton thin onClick={this.handleRightArrow} selected={false}>
                <Icon name="caret-right" />
              </ButtonGroupButton>
            </ButtonGroup>
          </QuickRangeWrapper>
        )}
        {children}
      </Container>
    );
  }
}

export default DateTools;

DateTools.defaultProps = {
  alignment: 'center',
  placement: 'top',
  showArrows: false,
  showAllOption: false,
  useUTC: false,
};

DateTools.propTypes = {
  t: PropTypes.func.isRequired,
  model: PropTypes.shape({
    startDatetime: PropTypes.instanceOf(Date),
    endDatetime: PropTypes.instanceOf(Date),
  }),
  onChange: PropTypes.func.isRequired,
  defaultRange: PropTypes.string.isRequired,
  showAllOption: PropTypes.bool,
  alignment: PropTypes.string,
  placement: PropTypes.string,
  showArrows: PropTypes.bool,
  disabledButtons: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  updateFromModel: PropTypes.bool,
  aggregationFreq: PropTypes.string,
  useUTC: PropTypes.bool,
};
