import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContextualHelp from 'components/ContextualHelp/ContextualHelp';

export const StyledSectionHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--size-xs);
  margin-bottom: ${props => (props.noBorder || props.title ? '0' : props.theme.layoutSpacing.md)};

  ${props => props.theme.media.portrait`
    flex-direction: row;
    justify-content: space-between;
  `}

  ${props => props.theme.media.landscape`
    border-bottom: ${props => (props.noBorder ? 'none' : `var(--border-thin) solid ${props.theme.colors.lightGray}`)};
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${props => (props.noBorder || props.title ? '0' : props.theme.layoutSpacing.lg)};
  `}
`;
StyledSectionHeader.displayName = 'StyledSectionHeader';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SectionTitle = styled.h2`
  font-size: ${props =>
    props.theme.fontSize[props.size === 'small' ? 'subHeading' : props.size === 'medium' ? 'heading' : 'title']};
  font-weight: ${props => props.theme.fontWeight.heading};
  line-height: ${props => props.theme.lineHeight.heading};
`;
SectionTitle.displayName = 'SectionTitle';

const StyledSectionDescription = styled.div`
  display: flex;
`;

const SectionDescriptionText = styled(SectionTitle)`
  font-size: ${props => props.theme.font.size.md};
  color: ${props => props.theme.colors.darkGray};
  font-weight: ${props => props.theme.font.weight.normal};
`;

SectionDescriptionText.displayName = 'SectionDescriptionText';

const SectionDescriptionValue = styled(SectionDescriptionText)`
  color: ${props => props.theme.colors.black};
  margin-left: var(--size-xs);
`;

SectionDescriptionValue.displayName = 'SectionDescriptionValue';

const SectionHeader = props => {
  const { title, children, ctxHelp, ctxHelpOverride, descriptionText, descriptionValue, badge } = props;

  const sectionDescription = (descriptionText, descriptionValue) => (
    <StyledSectionDescription>
      {descriptionText && <SectionDescriptionText>{descriptionText}:</SectionDescriptionText>}
      {descriptionValue && <SectionDescriptionValue>{descriptionValue}</SectionDescriptionValue>}
    </StyledSectionDescription>
  );

  return (
    <StyledSectionHeader title={title} {...props}>
      {title && (
        <TitleContainer>
          <SectionTitle data-test-id={`SectionHeader ${title}`} size={props.size}>
            {title}
            {ctxHelp && ' '}
            {ctxHelp && <ContextualHelp title={title} text={ctxHelp} override={!!ctxHelpOverride} position="right" />}
          </SectionTitle>
          {badge}
        </TitleContainer>
      )}
      {(descriptionText || descriptionValue) && sectionDescription(descriptionText, descriptionValue)}
      {children}
    </StyledSectionHeader>
  );
};

SectionHeader.defaultProps = {
  title: '',
  descriptionText: undefined,
  descriptionValue: undefined,
  noBorder: false,
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  ctxHelp: PropTypes.string,
  ctxHelpOverride: PropTypes.bool,
  children: PropTypes.node,
  descriptionText: PropTypes.string,
  descriptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noBorder: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'medium']),
  badge: PropTypes.node,
};

export default SectionHeader;
