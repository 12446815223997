import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import round from 'lodash/round';
import max from 'lodash/max';
import isEmpty from 'lodash/isEmpty';
import getISODay from 'date-fns/getISODay';
import { ICON_TYPES, ICON_SIZES } from 'components/Icon/Icon';
import { icons } from 'components/Icon/IconNames';
import { flIcons } from 'utils/Data/functionalLocations';
import {
  getPerformanceLimit,
  getPerformanceStatus,
  performanceColors,
  technicalPerformanceOrder,
  getOpeningHours,
  getLimitFromMeta,
} from 'utils/Data/performance';
import { getDefaultSubsensor } from 'utils/Data/sensor';
import { distinctConditionValue, conditionStatus } from './distinctConditionValue';

export const isSapEquipment = sensor =>
  sensor.sensorType &&
  sensor.sensorType.name === 'sap-equipment' &&
  sensor.equipmentNumber &&
  !isNaN(sensor.equipmentNumber);

export const CATEGORY_SORT = ['type', 'order'];
export const TYPE_SORT = ['label'];
export const SENSOR_SORT = [!isSapEquipment, 'name'];

export const conditionStatusToColor = () => ({
  [conditionStatus.positive.name]: 'var(--condition-status-positive-bg)',
  [conditionStatus.neutral.name]: 'var(--condition-status-neutral-bg)',
  [conditionStatus.negative.name]: 'var(--condition-status-negative-bg)',
  [conditionStatus.warning.name]: 'var(--condition-status-warning-bg)',
  [conditionStatus.empty.name]: null,
});

const temperatureSensorTypes = ['indoor temperature', 'outdoor temperature', 'temperature'];

export const conditionIcon = (isSapEquipment, isMultiSensor, flType, theme, isAirQuality, isSynthetic) => {
  if (isSapEquipment) {
    return {
      iconName: flIcons[flType],
      iconType: ICON_TYPES.DEFAULT,
      iconFill: 'var(--white)',
      iconSize: ICON_SIZES.SMALL,
    };
  } else if (isAirQuality && isMultiSensor) {
    return {
      iconName: icons.AIR_QUALITY,
      iconType: ICON_TYPES.PORTFOLIO,
      iconFill: 'var(--white)',
      iconSize: ICON_SIZES.SMALL_MINUS,
    };
  } else if (isMultiSensor) {
    return {
      iconName: icons.DEVICE,
      iconType: ICON_TYPES.EQUIPMENT,
      iconFill: 'var(--blue-01)',
      iconSize: ICON_SIZES.SMALL,
    };
  } else if (isSynthetic) {
    return {
      iconName: icons.SYNTHETIC_SENSOR,
      iconType: ICON_TYPES.TRANSPARENT,
      iconFill: 'var(--violet-01)',
      iconSize: ICON_SIZES.SMALL,
    };
  }
  return {
    iconName: icons.SENSOR,
    iconType: ICON_TYPES.TRANSPARENT,
    iconFill: 'var(--grey-100)',
    iconSize: ICON_SIZES.SMALL,
  };
};

export const getColorFromThreshold = (sensor, parent, buildingMeta, value, alarm) => {
  const now = new Date();
  let limits;
  if (alarm && !isEmpty(alarm.customRules)) {
    const isoWeekday = getISODay(now);
    const todayRules = JSON.parse(alarm.customRules)[isoWeekday];
    if (todayRules) {
      const { minValue, maxValue } = todayRules;
      limits = [minValue, maxValue];
    } else {
      return conditionStatus.positive;
    }
  } else {
    const getLimits = getPerformanceLimit(sensor, parent, buildingMeta, alarm);
    limits = getLimits && getLimits(now);
  }
  if (!limits) {
    return conditionStatus.empty;
  }
  const [min, max] = limits;
  return value < min || value > max ? conditionStatus.negative : conditionStatus.positive;
};

/**
 * Determine if a sensor is active based sensor next_value_timestamp.
 */
export const isSensorActive = (sensor, latestValue = {}) => {
  if (latestValue.next_value_timestamp) {
    const nextTimestamp = new Date(latestValue.next_value_timestamp).getTime();
    return nextTimestamp > Date.now() || new Date(latestValue.timestamp).getTime() > nextTimestamp;
  }

  return false;
};

export const getConditionValue = ({
  sensor,
  parent,
  latestValueObject,
  buildingMeta,
  theme,
  t,
  noDefaultSensor,
  isPerformance,
  alarm,
}) => {
  let value,
    status,
    colorStatus = {};
  const noValue = !latestValueObject || isNil(latestValueObject.value);

  if (noDefaultSensor) {
    value = '';
    status = conditionStatus.empty;
  } else if (noValue) {
    value = 'N/A';
    status = conditionStatus.negative;
    colorStatus = conditionStatus.empty;
  } else {
    value = round(latestValueObject.value, 0);
    status = conditionStatus.positive;

    if (sensor.sensorType) {
      const { distinctValue, distinctColorStatus } = distinctConditionValue(
        sensor.sensorType.graphType,
        sensor.sensorType.unit,
        latestValueObject,
        t,
        sensor.sensorMeta
      );

      // Temperature sensors are shown with one decimal
      if (includes(temperatureSensorTypes, sensor.sensorType.name)) {
        value = round(latestValueObject.value, 1);
      }

      // some sensor values are presented differently
      value = distinctValue || value;
      colorStatus =
        distinctColorStatus || getColorFromThreshold(sensor, parent, buildingMeta, +value, alarm) || colorStatus;
    }

    if (!isSensorActive(sensor, latestValueObject)) {
      status = conditionStatus.neutral;
    }
  }

  // value color (some types use colorStatus to define color apart from the data status)
  let color = conditionStatusToColor()[colorStatus.name || status.name];
  if (isPerformance && !noValue) {
    color = performanceColors({ theme })[getPerformanceStatus(value)];
  }
  const timestamp = latestValueObject?.timestamp ? new Date(latestValueObject?.timestamp).getTime() : null;
  return {
    value,
    color,
    status,
    timestamp,
  };
};

const getSensorTypeName = (t, sensorType, hasChildren, isSapEquipment, isAirQuality) => {
  if (isAirQuality) {
    if (sensorType.name.startsWith('technical_performance')) {
      return sensorType.name === 'technical_performance'
        ? t(sensorType.name)
        : `${t('Technical performance')} (${t(sensorType.name)})`;
    }

    return `${t('Air Quality')} (${t(sensorType.name)})`;
  }

  if (sensorType.name) {
    return t(sensorType.name);
  }

  if (isSapEquipment) {
    return t('Equipment');
  }

  if (hasChildren) {
    return t('Multisensor');
  }

  return '';
};

export const getSensorNameWithTechnicalName = sensor => {
  const technicalName = sensor.sensorMeta?.find(meta => meta.metaKey === 'hs:saptechnicalObjectname')?.value;
  return `${sensor.displayName || sensor.name} ${technicalName ?? ''}`.trim();
};

export const conditionData = ({
  parent,
  sensor,
  latestValues,
  buildingMeta,
  theme,
  t,
  openSensorModal,
  flType,
  link,
  alarmData,
  category,
}) => {
  if (!sensor || !latestValues || !theme || !t) {
    return {};
  }

  const hasChildren = sensor.children?.length > 0;
  const isAirQuality =
    sensor.sensorType?.name?.startsWith('air_quality') || sensor.sensorType?.name?.startsWith('technical_performance');
  const isPerformance = sensor.sensorType?.name === 'performance';
  const isSynthetic = sensor.type === 'synthetic';

  const sensorForLatestValue = !hasChildren || isAirQuality ? sensor : undefined;
  const latestValueObject = sensorForLatestValue ? latestValues[sensorForLatestValue.id] : undefined;

  const sensorType = sensor.sensorType || {};
  const sapEquipment = isSapEquipment(sensor);
  const sensorTypeName = getSensorTypeName(t, sensorType, hasChildren, sapEquipment, isAirQuality);

  const conditionValue = getConditionValue({
    sensor: sensorForLatestValue,
    parent,
    latestValueObject,
    buildingMeta,
    theme,
    t,
    noDefaultSensor: !sensorForLatestValue,
    isPerformance,
    alarm: sensorForLatestValue && alarmData && alarmData.alarms[sensorForLatestValue.id],
  });
  let { value } = conditionValue;
  const { status, color, timestamp } = conditionValue;
  let unit = isNaN(value) ? '' : sensorType.unit || latestValueObject?.unit || '';

  const capacity = getLimitFromMeta(sensor.sensorMeta, 'capacity');
  if (capacity && unit !== '%') {
    // Don't calculate capacity utilization if sensor values are already percentual
    unit = '%';
    value = Math.round((value / capacity) * 100);
  } else if (sensorType.name === 'humidity' && unit) {
    unit = '%RH';
  }

  const alarmsSupported = !hasChildren || isAirQuality;
  const sensorAlarm = alarmsSupported && alarmData?.alarms[sensor.id];
  const alarm =
    alarmData && alarmsSupported
      ? {
          active: !!sensorAlarm,
          onClick: () => alarmData.openAlarmModal(sensor),
          onDelete: sensorAlarm
            ? () => alarmData.deleteSensorAlarm(sensorAlarm.id, sensor.name ?? sensor.id)
            : undefined,
        }
      : undefined;

  if (alarm?.active && latestValueObject && !isNil(latestValueObject.value)) {
    const value = latestValueObject.value;
    const latestAlarmData = alarmData?.alarms[sensorForLatestValue.id];
    let min, max;
    if (latestAlarmData && !isEmpty(latestAlarmData.customRules)) {
      const isoWeekday = getISODay(new Date());
      const todayRules = JSON.parse(latestAlarmData.customRules)[isoWeekday];
      if (todayRules) {
        const { minValue, maxValue } = todayRules;
        min = minValue ?? Number.NEGATIVE_INFINITY;
        max = maxValue ?? Number.POSITIVE_INFINITY;
      } else {
        min = Number.NEGATIVE_INFINITY;
        max = Number.POSITIVE_INFINITY;
      }
    } else {
      min = sensorAlarm.minValue ?? Number.NEGATIVE_INFINITY;
      max = sensorAlarm.maxValue ?? Number.POSITIVE_INFINITY;
    }
    alarm.alert = value < min || value > max;
  }

  let openingHours;
  if (isAirQuality) {
    openingHours = getOpeningHours(category, buildingMeta);
  }

  const measuringPoint = getDefaultSubsensor(sensor);
  const children =
    hasChildren && isAirQuality
      ? sortBy(sensor.children, child => {
          const index = technicalPerformanceOrder.indexOf(get(child, 'sensorType.name'));
          return index !== -1 ? index : child.id;
        })
      : sensor.children;

  const mappedChildren =
    hasChildren &&
    children.map(child =>
      conditionData({
        parent: sensor,
        sensor: child,
        latestValues,
        buildingMeta,
        theme,
        t,
        openSensorModal,
        flType,
        link,
        alarmData,
        category,
      })
    );

  const sensorName = getSensorNameWithTechnicalName(sensor);
  return {
    id: sensor.id,
    key: `${parent.id}-${sensor && sensor.id}`,
    name: {
      // Use empty string for indoor air quality to make it first
      value:
        sensorType.graphType === 'air_quality' || sensorType.graphType === 'technical_performance' ? '' : sensorName,
      name: sensorName,
      icon: conditionIcon(sapEquipment, hasChildren, flType, theme, isAirQuality, isSynthetic),
      status: sensor.status,
    },
    status,
    timestamp: timestamp || max(mappedChildren, 'timestamp')?.timestamp,
    type: sensorTypeName,
    value: {
      value,
      color,
      unit,
    },
    addon: {
      showArrow: sapEquipment,
      alarm,
    },
    parent,
    measuringPoint,
    category,
    title: isAirQuality ? parent.name : undefined,
    openingHours,
    link: sapEquipment ? link : undefined,
    children: mappedChildren,
  };
};
