import { useEffect, useState } from 'react';
import IoT from 'services/iot';
import ApiError from 'utils/Fetch/ApiError';

type AnnotationId = string | number;

interface ScatterPlotAnnotation {
  id: AnnotationId;
  comment: string;
  timestamp: number;
}

type PointData = {
  x: number;
  y: number;
  id: AnnotationId;
  timestamp: number;
};

interface Annotation {
  id: AnnotationId;
}

const DATA_END_TAG = '[/point]';

const encodeDataToComment = (data: AnnotationId, comment: string) => `${data}${DATA_END_TAG}${comment}`;

const parseDataFromComment = (commentWithPoint: string): [string, string] => {
  const [pointString, comment] = commentWithPoint.split(DATA_END_TAG);
  return [pointString, comment];
};

const toAnnotation = (currentAnnotation: ScatterPlotAnnotation) => {
  const [point, comment] = parseDataFromComment(currentAnnotation.comment);
  return {
    id: currentAnnotation.id,
    point,
    comment,
    timestamp: currentAnnotation.timestamp,
  };
};

const useScatterPlotAnnotations = (chartId: number, functionalLocationId: number, sensorIds: number[]) => {
  const [annotations, setAnnotations] = useState<ScatterPlotAnnotation[]>([]);

  useEffect(() => {
    const fetchData = async (chartId: string, functionalLocation: number) => {
      const annotations: { id: number; comment: string; timestamp: number }[] = await IoT.annotations({
        startDate: new Date(0).toISOString(),
        endDate: new Date(Date.now()).toISOString(),
        customChartId: chartId,
        functionalLocation,
        sensorIds,
      }).catch((e: ApiError) => {
        return [];
      });

      const annotationsToSet = annotations.map<ScatterPlotAnnotation>(toAnnotation, []);

      setAnnotations(annotationsToSet);
    };

    fetchData(chartId?.toString(), functionalLocationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartId, functionalLocationId, JSON.stringify(sensorIds)]);

  const addAnnotation = async (pointData: PointData, comment: string) => {
    const commentString = encodeDataToComment(pointData.id, comment);

    try {
      const res = await IoT.upsertAnnotation({
        customChartId: chartId.toString(),
        comment: commentString,
        timestamp: pointData.timestamp,
      });

      setAnnotations(annotations.concat(toAnnotation(res)));
    } catch (e) {
      return e;
    }

    return null;
  };

  const removeAnnotation = (annotation: Annotation) => setAnnotations(annotations.filter(a => a !== annotation));

  return { annotations, addAnnotation, removeAnnotation };
};

export default useScatterPlotAnnotations;
