import styled from 'styled-components';

const DefaultInputWrapper = styled.div`
  margin: 0 calc(var(--size-sm) / 2);
  padding-right: var(--size-md);
  flex: 1 1 100%;

  ${props => props.theme.media.portrait`
    margin-top:  calc(-1 * var(--size-md));
    flex: 0 1 auto;
  `}
`;

export default DefaultInputWrapper;
