import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import translations from 'decorators/Translations/translations';

import InputForm from 'components/Form/InputForm';
import InputRow from 'components/Form/InputRow';
import RadioButtonGroup from 'components/Form/RadioButtonGroup';
import RadioButton from 'components/Form/RadioButton';
import InputTextArea from 'components/Form/InputTextArea';
import InputText from 'components/Form/InputText';
import PrimaryButton from 'components/Button/PrimaryButton';

import { TECHNICIAN_STATUSES, NON_TECHNICIAN_STATUSES, createEventType, statusLabels } from './utils';
import { SubSection } from 'containers/Application/ServiceOrder/ServiceOrder.styled';
import SkeletonText from 'components/Skeletons/SkeletonText';

const Label = styled.label`
  display: block;
  font: var(--font-xxs);
  color: var(--black);

  &::after {
    content: '*';
    color: var(--red-01);
  }
`;

const CommentInputRow = styled(InputRow)`
  margin: 0;
  button {
    margin-top: var(--size-lg);
  }

  textarea {
    box-shadow: none;
  }

  & > label {
    line-height: var(--line-height-airy);
    display: inline-block;
    margin-bottom: var(--size-xs);
  }

  &:not(:first-child) {
    margin-top: var(--size-xs);
  }
`;

export const TechniciansComments = ({
  t,
  serviceOrder,
  onSubmit,
  loading,
  closeModal,
  isTechnician = true,
  isCompletionDisabled = false,
  isDraft = false,
  id,
  model,
  onModelChange,
  text,
}) => {
  const [saving, setSaving] = React.useState(false);

  const handleFormSubmit = React.useCallback(async () => {
    setSaving(true);
    try {
      await onSubmit({
        ...model,
        serviceOrderNumber: serviceOrder.serviceOrderNumber,
        eventType: createEventType(serviceOrder, model),
      });
    } finally {
      setSaving(false);
      onModelChange('comment', '');

      if (typeof closeModal === 'function') {
        closeModal();
      }
    }
  }, [serviceOrder, model, onSubmit, closeModal, setSaving, onModelChange]);

  return (
    <SubSection>
      {text?.heading && <h3>{loading ? <SkeletonText /> : text.heading}</h3>}
      <InputForm onPropertyChange={onModelChange} onSubmit={handleFormSubmit} model={model}>
        {!isDraft && (
          <CommentInputRow>
            {text?.orderStatus && <Label htmlFor={id}>{text.orderStatus}</Label>}
            <RadioButtonGroup id={id} name="status" value={model.status} onChange={onModelChange}>
              {(isTechnician ? TECHNICIAN_STATUSES : NON_TECHNICIAN_STATUSES)
                .filter(status => status !== 'Draft' || serviceOrder.allowRejection)
                .map(status => (
                  <RadioButton
                    label={t(statusLabels[status] || status)}
                    value={status}
                    key={status}
                    disabled={loading || (status === 'Completed' && isCompletionDisabled)}
                  />
                ))}
            </RadioButtonGroup>
          </CommentInputRow>
        )}
        <CommentInputRow fullRow>
          {isTechnician && (
            <>
              <Label>{t('Your Name')}</Label>
              <InputText property="createdBy" disabled={loading} required />
            </>
          )}
          <Label>{t('Comment')}</Label>
          <InputTextArea property="comment" placeholder="" disabled={loading} height="150px" required />
          <PrimaryButton confirm type="submit" loading={saving} disabled={loading || saving}>
            {t('Save')}
          </PrimaryButton>
        </CommentInputRow>
      </InputForm>
    </SubSection>
  );
};

TechniciansComments.propTypes = {
  t: PropTypes.func.isRequired,
  serviceOrder: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
  isTechnician: PropTypes.bool,
  isCompletionDisabled: PropTypes.bool,
  isDraft: PropTypes.bool,
  id: PropTypes.string,
  model: PropTypes.object.isRequired,
  onModelChange: PropTypes.func.isRequired,
  text: PropTypes.shape({ heading: PropTypes.string, orderStatus: PropTypes.string }),
};

export default translations(TechniciansComments);
