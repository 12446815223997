import { isAdminRole, hasAccessToAdminPanel } from 'utils/Data/profileData';
import { isValidPartner } from 'utils/Data/partners';
import { icons } from 'components/Icon/IconNames';

export const isServiceRequestEnabled = features => (features && features.sr) || false;

export const getLink = (partnerNumber, link, extra = '') =>
  isValidPartner(partnerNumber) ? `/${partnerNumber}/${link}` : `${extra}/${link}`;

const currentYear = new Date().getFullYear();

export const globalCalendarTo = activePartner => ({
  pathname: getLink(activePartner, 'Maintenance'),
  search: `?year=${currentYear}`,
});

export const getNavigationItems = (activePartner, profile, t) => [
  {
    dataTestId: 'NavigationLinkToOverview',
    linkTo: '/',
    iconName: icons.PORTFOLIO,
    text: t('Portfolio'),
    show: true,
  },
  {
    dataTestId: 'NavigationLinkToMyProfile',
    linkTo: '/Profile',
    iconName: icons.ADMIN_MANAGE_USERS,
    text: t('My Profile'),
    show: true,
  },
  {
    dataTestId: 'NavigationLinkToAdmin',
    linkTo: '/Admin',
    iconName: icons.ADMIN_PANEL,
    text: t('Admin Panel'),
    show: hasAccessToAdminPanel(profile),
  },
  {
    dataTestId: 'NavigationLinkToNewServiceRequest',
    linkTo: getLink(activePartner, 'ServiceRequest'),
    iconName: icons.NEW_SERVICE_REQUEST,
    text: t('New Service Request'),
    show: isServiceRequestEnabled(profile.features),
  },
  {
    dataTestId: 'NavigationLinkToUserManual',
    linkTo: '/UserManual',
    iconName: icons.DOCUMENTS,
    text: t('User Manual'),
    show: true,
  },
  /* Currently hidden. Will be possibly added back later with new content. */
  /*
  {
    dataTestId: 'NavigationLinkToRoadmap',
    linkTo: '/Roadmap',
    iconName: icons.ROADMAP,
    text: t('Roadmap'),
    show: isAdminRole(profile.role),
  },
  */
  {
    dataTestId: 'NavigationLinkToLogout',
    linkTo: '/Logout',
    iconName: icons.LOGOUT,
    text: t('Logout'),
    show: true,
  },
];
