import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import map from 'lodash/map';
import compact from 'lodash/compact';
import mean from 'lodash/mean';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import pickBy from 'lodash/pickBy';

import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import withQuery from 'decorators/Query/withQuery';
import EnergySavingsPotential from '../Modules/EnergyModule/EnergySavingsPotential';
import ObservationsModal from 'components/Observations/ObservationsModal';
import { defaultFilterValues } from 'components/Observations/utils';
import {
  calculateTotalRenewableEnergyPercentage,
  calculateTotalConsumption,
  calculateTotalCo2Emission,
  convertCo2ToTonnes,
} from './EnergyUtil';
import connect from './connectEnergyOpiCards';
import { VALUE_STATUS } from 'constants/common';
import { formatValue } from 'utils/Data/values';
import { useTranslations } from 'decorators/Translations/translations';

import EnergyModal from './EnergyModal';

export const energyRatingPrecision = 1;

const calculateEnergyRatingValuesByFL = (energyRatingValuesByFL, filterableFLs) => {
  const { previous, latest } = energyRatingValuesByFL.averages || {};
  // filter is not on, use already calculated values
  if (isEmpty(filterableFLs)) {
    return {
      previous,
      latest,
      unit: 'kWh/m²',
    };
  }
  // filter is on, pick matching FLs and calculates avg for those
  const filteredFLs = pickBy(energyRatingValuesByFL, (fl, key) => includes(filterableFLs, key));
  if (!isEmpty(filteredFLs)) {
    return {
      latest: mean(compact(map(filteredFLs, ({ latest }) => latest))),
      previous: mean(compact(map(filteredFLs, ({ previous }) => previous))),
      unit: 'kWh/m²',
    };
  }
  return {};
};

const getDifference = (current, previous) => {
  if (!current || !previous) {
    return 0;
  }
  return round(((current - previous) / previous) * 100);
};

export const EnergyOpiCards = ({
  energyRatingValuesByFL,
  filterableFLs,
  functionalLocations,
  notices,
  observations,
  setQuery,
  query,
  consumptionKPIs,
}) => {
  const [t] = useTranslations();

  const [savingsModalVisible, setSavingsModalVisible] = useState(false);

  const calculatedEnergyRatingValuesByFL = useMemo(
    () => calculateEnergyRatingValuesByFL(energyRatingValuesByFL, filterableFLs),
    [energyRatingValuesByFL, filterableFLs]
  );
  const energyRatingDifference = getDifference(
    calculatedEnergyRatingValuesByFL.latest,
    calculatedEnergyRatingValuesByFL.previous
  );
  const renewableEnergyPercentage = useMemo(
    () => calculateTotalRenewableEnergyPercentage(consumptionKPIs, filterableFLs),
    [consumptionKPIs, filterableFLs]
  );
  const co2Emission = useMemo(
    () => calculateTotalCo2Emission(consumptionKPIs, filterableFLs),
    [consumptionKPIs, filterableFLs]
  );

  const consumption = useMemo(
    () => calculateTotalConsumption(consumptionKPIs, filterableFLs),
    [consumptionKPIs, filterableFLs]
  );
  const currentFormattedConsumption = formatValue(consumption, 'kWh');
  const currentYear = new Date().getFullYear();
  return (
    <>
      <OPICards>
        {consumption > 0 && (
          <OPICard
            key="cards-consumption"
            title={t('Energy consumption')}
            subtitle={t('Last 12 Months')}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Energy energy consumption OPI` }}
            content={{
              value: currentFormattedConsumption.value,
              unit: currentFormattedConsumption.unit,
            }}
            clickEventType="openModal"
            onClick={() => setQuery({ energyConsumptionModalVisible: true })}
          />
        )}
        {calculatedEnergyRatingValuesByFL.latest && (
          <OPICard
            key="cards-rating"
            title={t('Energy rating')}
            subtitle={t('Yearly consumption')}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Energy energy rating OPI` }}
            content={{
              value: round(calculatedEnergyRatingValuesByFL.latest, energyRatingPrecision),
              unit: 'kWh/m²',
              trend: energyRatingDifference,
            }}
            onClick={() => setQuery({ tab: 'energy-rating', scrollTo: 'energy-benchmark' })}
          />
        )}
        {notices?.totalCountCurrentYear > 0 && (
          <OPICard
            key="cards-notices"
            title={t('Observations')}
            subtitle={`${notices.completedCountCurrentYear} / ${notices.totalCountCurrentYear} ${t('Completed')}
          (${currentYear})`}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Observations OPI` }}
            contentWithCircle={{
              value: notices.completedRatioCurrentYear,
              neutral: true,
            }}
            clickEventType="openModal"
            onClick={() =>
              setQuery({ observationsModalVisible: true, dateFilterField: 'modifiedOn', year: currentYear })
            }
          />
        )}
        {notices.totalSavingsPotential * -1 > 0 && (
          <OPICard
            key="cards-savings"
            title={t('Savings Potential')}
            subtitle={t('Yearly consumption')}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Savings potential OPI` }}
            content={{
              value: (notices.totalSavingsPotential * -1) / 1000,
              unit: 'MWh',
              status: VALUE_STATUS.OK,
            }}
            clickEventType="openModal"
            onClick={() => setSavingsModalVisible(true)}
          />
        )}
        {renewableEnergyPercentage > 0 && (
          <OPICard
            key="cards-renewable"
            title={t('Renewable energy')}
            subtitle={t('Last 12 Months')}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Energy renewable energy OPI` }}
            content={{
              value: round(renewableEnergyPercentage, energyRatingPrecision),
              unit: '%',
            }}
            onClick={() => setQuery({ tab: 'renewable-energy', scrollTo: 'energy-benchmark' })}
          />
        )}
        {co2Emission > 0 && (
          <OPICard
            key="cards-emissions"
            title={t(`CO₂ ${t('Emissions')}`)}
            subtitle={t('Last 12 Months')}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Energy CO2 emissions OPI` }}
            content={{
              value: round(convertCo2ToTonnes(co2Emission), energyRatingPrecision),
              unit: `CO₂ ${t('tonnes')}`,
            }}
            onClick={() => setQuery({ tab: 'co2-emissions', scrollTo: 'energy-benchmark' })}
          />
        )}
      </OPICards>

      {savingsModalVisible && (
        <EnergySavingsPotential notices={notices} modal={true} onModalClose={() => setSavingsModalVisible(false)} />
      )}
      {query?.observationsModalVisible && (
        <ObservationsModal
          observations={observations}
          filter={{ ...defaultFilterValues, ...query }}
          onClose={() =>
            setQuery({ ...defaultFilterValues, selectedModalTab: undefined, observationsModalVisible: undefined })
          }
        />
      )}
      {query?.energyConsumptionModalVisible && (
        <EnergyModal
          functionalLocations={filterableFLs.length > 0 ? filterableFLs : Object.keys(functionalLocations)}
          onClose={() => setQuery({ energyConsumptionModalVisible: undefined, breakdown: undefined })}
        />
      )}
    </>
  );
};

export default withQuery(connect(EnergyOpiCards));

EnergyOpiCards.defaultProps = {
  totalValues: {},
  valuesByType: {},
};

EnergyOpiCards.propTypes = {
  energyRatingValuesByFL: PropTypes.object.isRequired,
  functionalLocations: PropTypes.object.isRequired,
  notices: PropTypes.object,
  observations: PropTypes.array,
  consumptionKPIs: PropTypes.object,
  filterableFLs: PropTypes.array,
  setQuery: PropTypes.func,
  query: PropTypes.object,
};
