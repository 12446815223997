import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';

import { useTranslations } from 'decorators/Translations/translations';
import { DEFAULT_FL_COLUMNS } from 'constants/observations';
import { getColumns, filterConfiguredColumns } from './observationsTableColumns';
import { getTypeTitle } from 'components/BuildingEvents/ColumnTypes/TypeColumn';

import SortableTable from 'components/SortableTable/SortableTable';
import TableCell from 'components/BuildingEvents/EventsRow/EventsCell';
import SkeletonText from 'components/Skeletons/SkeletonText';
import ObservationsTableConfiguration from './ObservationsTableConfiguration';
import ObservationsModalContent from './ObservationsModalContent';
import DialogModal from 'components/Dialog/DialogModal';
import ObservationsRow from './ObservationsRow';

const UNSPECIFIED_DEVICE_VALUE = 'Unspecified device';

const TableHead = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SkeletonBodyComponent = () => (
  <tbody data-test-id="SkeletonObservationsTable">
    <tr>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell grow hideOnMobile>
        <SkeletonText width="40%" style={{ marginLeft: '0' }} />
        <SkeletonText width="40%" style={{ marginLeft: '0' }} />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell hideOnMobile>
        <SkeletonText />
      </TableCell>
      <TableCell grow hideOnDesktop>
        <SkeletonText />
        <SkeletonText />
      </TableCell>
    </tr>
  </tbody>
);

const NoDataAvailable = styled.p`
  text-align: center;
  margin-top: var(--size-md);
`;

const ObservationsTable = props => {
  const {
    data: rawData = [],
    columns,
    loading,
    onRowClick,
    functionalLocations = {},
    match: {
      params: { partnerNumber },
    },
    defaultColumns,
    columnConfig,
    hideConfiguration,
    paginate,
    initialRows,
    hideMobileComponent,
  } = props;

  const [t] = useTranslations();
  const [openModalContent, setOpenModalContent] = React.useState();

  // Use session or props config if it is set
  const configuration = columnConfig || defaultColumns || DEFAULT_FL_COLUMNS;
  const tableColumns = columns ?? getColumns(t, isEmpty(functionalLocations));

  const data = rawData.map(d => {
    // status sorting for both status value and title: 1Open, 3Completed etc
    const newData = {
      ...d,
      statusValue: `${d.status.value}${t(d.status.status)}`,
      disciplineValue: getTypeTitle(d.deviceGroup, t),
    };
    if (!isEmpty(functionalLocations)) {
      newData.functionalLocationName = functionalLocations?.[d.functionalLocation]?.name ?? '';
      newData.currentPartnerNumber = partnerNumber;
    }
    if (newData.location?.value === UNSPECIFIED_DEVICE_VALUE) {
      newData.location.value = t(UNSPECIFIED_DEVICE_VALUE);
    }
    return newData;
  });

  const handleRowClick = data => (onRowClick ? onRowClick(data) : setOpenModalContent(data));

  if (data.length === 0 && !loading) {
    return <NoDataAvailable>{t('No data available')}</NoDataAvailable>;
  }

  const selectedColumns = filterConfiguredColumns(tableColumns, configuration);

  return (
    <>
      {!hideConfiguration && (
        <TableHead>
          <ObservationsTableConfiguration
            t={t}
            noLocations={isEmpty(functionalLocations)}
            defaultColumns={defaultColumns}
          />
        </TableHead>
      )}
      <SortableTable
        columns={selectedColumns}
        data={data}
        RowComponent={rowProps => (
          <ObservationsRow columns={selectedColumns} hideMobileComponent={hideMobileComponent} {...rowProps} />
        )}
        SkeletonBodyComponent={SkeletonBodyComponent}
        headerBackgroundColor="transparent"
        t={t}
        showControls
        orderBy="date"
        order="desc"
        loading={loading}
        onClick={handleRowClick}
        allowScrollX={true}
        paginate={paginate}
        initialRows={initialRows}
      />
      {openModalContent && (
        <DialogModal animate isActive onOverlayClick={() => setOpenModalContent(null)} t={t}>
          <ObservationsModalContent t={t} data={openModalContent} />
        </DialogModal>
      )}
    </>
  );
};

ObservationsTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  hideMobileComponent: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  functionalLocations: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerNumber: PropTypes.string,
    }),
  }),
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  columnConfig: PropTypes.array,
  hideConfiguration: PropTypes.bool,
  paginate: PropTypes.bool,
  initialRows: PropTypes.number,
};

const mapStateToProps = state => ({
  columnConfig: state.notice.columnConfig,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(ObservationsTable));
