import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { StandardPage } from 'components/index';
import themes from 'styles/themes';

const Anonymous = ({ children, title, isAnonymousArticle, noFooter, noMargin, noHeader, customTheme }) => (
  <ThemeProvider theme={customTheme || themes.customerPlatform}>
    <StandardPage
      isAnonymous
      isAnonymousArticle={isAnonymousArticle}
      noMargin={noMargin}
      noFooter={noFooter}
      noHeader={noHeader}
    >
      <Helmet titleTemplate={title} />
      {children}
    </StandardPage>
  </ThemeProvider>
);

Anonymous.defaultProps = {
  noMargin: true,
  isAnonymousArticle: true,
};

Anonymous.propTypes = {
  noMargin: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  isAnonymousArticle: PropTypes.bool,
  customTheme: PropTypes.object,
};

export default Anonymous;
