import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslations } from 'decorators/Translations/translations';
import withQuery from 'decorators/Query/withQuery';
import connect from 'containers/Application/Modules/RecyclingModule/RecyclingOPICards/connectRecyclingOPICards';
import OPICards from 'components/OPICard/OPICards';
import OPICard from 'components/OPICard/OPICard';
import ErrorBoundaryFallback from 'components/ErrorPage/ErrorBoundaryFallback';
import { CTXHELP_PREFIX } from 'components/ContextualHelp/ContextualHelp';
import { withRouter } from 'react-router-dom';
import { CO2E_TONNES_UNIT, TONNES_UNIT } from 'constants/recycling';
import OPICardsSkeleton from 'components/OPICard/OPICardsSkeleton';

export const RecyclingOPICards = ({
  partnerNumber,
  functionalLocationId,
  syntheticSensors,
  targetQueries,
  containerLoading,
  loadOpiData,
  data,
  observations,
  setQuery,
}) => {
  const [t] = useTranslations();

  React.useEffect(() => {
    if (!containerLoading && data.needsReload) {
      loadOpiData({
        partnerNumber,
        functionalLocationId,
        sensorIds: syntheticSensors.map(syntheticSensor => syntheticSensor.id),
      });
    }
  }, [containerLoading, data.needsReload]); // eslint-disable-line react-hooks/exhaustive-deps

  if (data.error.status) {
    return <ErrorBoundaryFallback />;
  }
  if (containerLoading || data.isLoading) {
    return <OPICardsSkeleton numberOfOPICards={4} />;
  }
  const timeframeDescription = 'Past 12 months';
  const { recyclingRate, recoveryRate, carbonFootprint, wasteAmount } = data.result;
  const columnWidth = observations?.opi.totalCount ? undefined : 4;

  return (
    <OPICards>
      {!isEmpty(data.result) && (
        <React.Fragment>
          <OPICard
            title={t('Recycling rate')}
            subtitle={t(timeframeDescription)}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} Recycling rate OPI`,
            }}
            contentWithCircle={{
              value: recyclingRate,
            }}
            onClick={targetQueries?.recyclingRate ? () => setQuery(targetQueries.recyclingRate) : undefined}
          />
          <OPICard
            title={t('Recovery rate')}
            subtitle={t(timeframeDescription)}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} Recovery rate OPI`,
            }}
            contentWithCircle={{
              value: recoveryRate,
            }}
            onClick={targetQueries?.recoveryRate ? () => setQuery(targetQueries.recoveryRate) : undefined}
          />
          <OPICard
            title={t('Waste amount')}
            subtitle={t(timeframeDescription)}
            content={{
              value: `${wasteAmount}`,
              unit: t(TONNES_UNIT),
            }}
            contextualHelp={{ text: `${CTXHELP_PREFIX} Waste amount OPI` }}
            onClick={targetQueries?.wasteAmount ? () => setQuery(targetQueries.wasteAmount) : undefined}
            overrideColumnWidth={columnWidth}
          />
          <OPICard
            title={t('Carbon footprint')}
            subtitle={t(timeframeDescription)}
            contextualHelp={{
              text: `${CTXHELP_PREFIX} Carbon footprint OPI`,
            }}
            content={{
              value: carbonFootprint,
              unit: t(CO2E_TONNES_UNIT),
            }}
            onClick={targetQueries?.carbonFootprint ? () => setQuery(targetQueries.carbonFootprint) : undefined}
          />
        </React.Fragment>
      )}
      {observations?.opi.totalCount ? (
        <OPICard
          title={t('Observations')}
          subtitle={`${observations.opi.completedCount} / ${observations.opi.totalCount} ${t('Completed')} (${t(
            'Past 12 months'
          )})`}
          contextualHelp={{
            text: `${CTXHELP_PREFIX} Observations OPI`,
          }}
          contentWithCircle={{
            value: observations.opi.completedRatio,
            neutral: true,
          }}
          clickEventType="openModal"
          onClick={() => setQuery({ observationsModalVisible: true, year: new Date().getFullYear() })}
        />
      ) : null}
    </OPICards>
  );
};

RecyclingOPICards.propTypes = {
  partnerNumber: PropTypes.string,
  functionalLocationId: PropTypes.string,
  syntheticSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  targetQueries: PropTypes.object, // TODO: make stricter
  containerLoading: PropTypes.bool,
  loadOpiData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      status: PropTypes.bool.isRequired,
      message: PropTypes.string,
    }).isRequired,
    needsReload: PropTypes.bool.isRequired,
    result: PropTypes.object.isRequired,
  }).isRequired,
  observations: PropTypes.object,
  setQuery: PropTypes.func.isRequired,
};

export default withRouter(withQuery(connect(RecyclingOPICards)));
