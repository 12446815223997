import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Highcharts from 'highcharts';
import { transparentize } from 'polished';
import { formatNumber } from 'utils/Number/decimalFormat';
import { getCommonExportingOptions, getCommonNavigationOptions } from 'components/Charts/utils';

const textStyle = theme => ({
  fontSize: '12px',
  fontFamily: theme.font.family.cairo,
  color: theme.colors.darkGray,
});

const calculateNullSeries = series =>
  series.map(entry => (entry.y > 0 ? 0 : Math.max(...series.map(entry => entry.y))));

export const createWasteChartConfig = ({
  theme,
  language,
  categories,
  series,
  seriesName,
  customLegend,
  onValueBarClick,
}) => ({
  chart: {
    type: 'bar',
    style: {
      ...textStyle(theme),
    },
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      grouping: false,
      data: calculateNullSeries(series),
      color: 'transparent',
      borderWidth: 0,
      showInLegend: false,
      cursor: 'pointer',
      includeInDataExport: false,
    },
    {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      name: seriesName,
      data: series,
      cursor: 'pointer',
    },
  ],
  plotOptions: {
    series: {
      events: {
        legendItemClick: function () {
          return false;
        },
        click: function (event) {
          onValueBarClick(event);
        },
      },
    },
    column: {
      point: {
        events: {
          mouseOver: function () {
            const series = this.series.chart.series;
            const point = this;
            Highcharts.each(series, function (ob) {
              Highcharts.each(ob.data, function (p) {
                if (p.x === point.x) {
                  p.setState('hover');
                }
              });
            });
          },
          mouseOut: function () {
            const series = this.series.chart.series;
            const point = this;
            Highcharts.each(series, function (ob) {
              Highcharts.each(ob.data, function (p) {
                if (p.x === point.x) {
                  p.setState('');
                }
              });
            });
          },
        },
      },
      useHTML: true,
      formatter: function () {
        return ReactDOMServer.renderToStaticMarkup(<span style={{ color: this.point.color }} />);
      },
    },
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
    useHTML: true,
    itemStyle: {
      cursor: 'default',
    },
    labelFormatter: function () {
      return ReactDOMServer.renderToStaticMarkup(
        <div style={{ display: 'flex' }}>
          {customLegend.map(function (entry) {
            return (
              <div key={entry.name} style={{ display: 'flex', marginRight: '1em', alignItems: 'center' }}>
                <svg height="14" width="16" style={{ marginRight: '0.5em' }}>
                  <circle cx="8" cy="7" r="4" stroke={entry.color} strokeWidth="3" fill={entry.color} />
                </svg>
                <div>{entry.name}</div>
              </div>
            );
          })}
        </div>
      );
    },
  },
  tooltip: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    shadow: false,
    padding: 0,
    useHTML: true,
    formatter: function () {
      const series = this.series.chart.series;
      const entry = series[1].data[this.point.x];
      return ReactDOMServer.renderToStaticMarkup(
        <div
          style={{
            color: theme.colors.black,
            fontSize: theme.font.size.xxs,
            border: `1px solid ${theme.colors.lightGray}`,
            boxShadow: `0 2px 10px ${transparentize(0.9, theme.colors.black)}`,
            borderRadius: 0,
          }}
        >
          <div
            className="header"
            style={{ fontWeight: theme.font.weight.bold, padding: '0.5em', backgroundColor: theme.colors.lightGray }}
          >
            {entry.name}
          </div>
          <div
            className="content"
            style={{ fontWeight: theme.font.weight.bold, padding: '0.5em', backgroundColor: theme.colors.white }}
          >{`${formatNumber(language, entry.y)} ${entry._unit}`}</div>
        </div>
      );
    },
  },
  noData: {
    style: textStyle(theme),
  },
  xAxis: {
    categories,
    labels: {
      style: {
        ...textStyle(theme),
      },
    },
  },
  yAxis: {
    allowDecimals: false,
    title: {
      text: null,
    },
    labels: {
      style: textStyle(theme),
    },
  },
  exporting: getCommonExportingOptions({ title: 'Recycling', categories }),
  navigation: getCommonNavigationOptions(),
});
