import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProfileService from 'services/profile';
import { useTranslations } from 'decorators/Translations/translations';

import { getPartnerNumbers } from 'utils/profile';
import { isAdminOrCaverionUserRole, canConfigureCustomViews } from 'utils/Data/profileData';
import {
  setProfileProperty,
  loadProfile,
  saveProfile,
  setEditProfile,
  loadCustomers,
  loadPermissionsHierarchy,
  loadFunctionalLocations,
  getPortfolioCounts,
  loadMyCustomViews,
  loadUserCustomViews,
} from 'redux/modules/index';

const useEditUser = (id, showNotification) => {
  const [loading, setLoading] = React.useState();
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const dispatch = useDispatch();
  const [t, updateTranslations] = useTranslations();

  const editProfiles = useSelector(state => state.profile.editProfiles);
  const profile = useSelector(state => state.profile.profile);
  const functionalLocations = useSelector(state => state.functionalLocations.functionalLocations);

  const isOwnProfile = profile.id === id;

  const loadCustomViews = async () => {
    try {
      if (isOwnProfile) {
        await dispatch(loadMyCustomViews());
      } else if (canConfigureCustomViews(profile)) {
        await dispatch(loadUserCustomViews(id));
      }
    } catch (e) {
      showNotification({ message: t('Loading custom views failed'), type: 'error', autoHide: true });
    }
  };

  const loadProfileData = async () => {
    let editProfile;

    if (isOwnProfile) {
      editProfile = profile;
    } else {
      const { result } = await dispatch(loadProfile(id));
      editProfile = result;
    }

    const promises = [dispatch(setEditProfile(id))];

    if (isAdminOrCaverionUserRole(profile.role)) {
      const functionalLocations = Object.values(editProfile.permissions).flat();
      const partnerNumbers = getPartnerNumbers(editProfile);

      if (partnerNumbers?.length > 0) {
        promises.push(dispatch(loadCustomers(partnerNumbers)));
      }
      if (functionalLocations?.length > 0) {
        promises.push(dispatch(loadPermissionsHierarchy(functionalLocations)));
      }
      if (editProfile.partnerPermissions?.length > 0) {
        dispatch(getPortfolioCounts(editProfile.partnerPermissions));
      }
    }

    return Promise.all(promises);
  };

  const load = async () => {
    setLoading(true);
    try {
      loadCustomViews();
      await loadProfileData();
    } catch (e) {
      showNotification({ message: t('Loading profile failed'), type: 'error', autoHide: true });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    load();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = React.useCallback(
    async data => {
      const oldLanguage = profile.language;
      setSaving(true);
      setSaved(false);

      try {
        const savedProfile = await dispatch(saveProfile(id, data));

        // Edited own profile -> switch language and load permissions if needed
        if (isOwnProfile) {
          const newLanguage = savedProfile.language;
          const addedPermissions = Object.values(savedProfile.permissions)
            .flat()
            .filter(y => functionalLocations[y] === undefined);

          if (oldLanguage !== newLanguage) {
            ProfileService.translations(newLanguage).then(translations =>
              updateTranslations({ language: newLanguage, translations })
            );
          }

          // Load new functional locations
          if (addedPermissions.length > 0) {
            dispatch(loadFunctionalLocations(addedPermissions));
          }
        }

        setSaved(true);

        setTimeout(() => {
          dispatch(setProfileProperty(id, 'edited', false));
        }, 3000);

        setTimeout(() => setSaved(false), 4000);
      } catch (error) {
        showNotification({ message: `${t('Save Failed')} ${error.message ?? ''}`, type: 'error', autoHide: true });
      }
      setSaving(false);
    },
    [dispatch, id, showNotification, t, functionalLocations, profile, updateTranslations, isOwnProfile]
  );

  const setProperty = React.useCallback(
    (property, value) => dispatch(setProfileProperty(id, property, value)),
    [dispatch, id]
  );

  return [saving, saved, loading, setProperty, editProfiles[id], save];
};

export default useEditUser;
