import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';
import assign from 'lodash/assign';
import groupBy from 'lodash/groupBy';
import { isAdminRole } from 'utils/Data/profileData';

const initialState = {
  loading: false,
  loadingFLImage: false,
  updating: false,
  updated: false,
  byPartner: {},
  byFL: {},
};

export const UPSERT = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT';
export const UPSERT_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT_SUCCESS';
export const UPSERT_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/UPSERT_FAIL';

export const upsertImage = image => {
  return async dispatch => {
    dispatch({ type: UPSERT });
    try {
      const result = await dispatch(MasterDataService.upsertImage(image));
      return dispatch({
        type: UPSERT_SUCCESS,
        result: result.data,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_FAIL,
        error,
      });
    }
  };
};

export const DELETE = 'CUSTMOER_PLATFORM/PartnerImage/DELETE';
export const DELETE_SUCCESS = 'CUSTMOER_PLATFORM/PartnerImage/DELETE_SUCCESS';
export const DELETE_FAIL = 'CUSTMOER_PLATFORM/PartnerImage/DELETE_FAIL';

export const deleteImage = id => {
  return async dispatch => {
    dispatch({ type: DELETE });
    try {
      const result = await dispatch(MasterDataService.deleteImage(id));
      return dispatch({
        type: DELETE_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_FAIL,
        error,
      });
    }
  };
};

export const LOAD_FOR_PARTNER = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER';
export const LOAD_FOR_PARTNER_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER_SUCCESS';
export const LOAD_FOR_PARTNER_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_PARTNER_FAIL';

export const loadPartnerImages = partnerNumber => async (dispatch, getState) => {
  // Invalidate cache for admin users
  const state = getState();
  const profile = state.profile?.profile;
  const noCache = profile && isAdminRole(profile.role);

  dispatch({ type: LOAD_FOR_PARTNER });
  try {
    const result = await dispatch(MasterDataService.images({ partnerNumber }, noCache));
    return dispatch({
      type: LOAD_FOR_PARTNER_SUCCESS,
      key: 'partner_images_' + partnerNumber,
      partnerNumber,
      result: result.data,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FOR_PARTNER_FAIL,
      key: 'partner_images_' + partnerNumber,
      error,
    });
  }
};

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationImages = functionalLocation => {
  const params = {
    functionalLocations: [functionalLocation],
  };
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && isAdminRole(profile.role);

    dispatch({ type: LOAD_FOR_FL });
    try {
      const result = await dispatch(MasterDataService.images(params, noCache));
      return dispatch({
        type: LOAD_FOR_FL_SUCCESS,
        key: 'fl_image_' + functionalLocation,
        functionalLocation,
        result: result.data,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FL_FAIL,
        key: 'fl_image_' + functionalLocation,
        error,
      });
    }
  };
};

export const LOAD_FOR_FLS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FLS';
export const LOAD_FOR_FLS_SUCCESS = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FLS_SUCCESS';
export const LOAD_FOR_FLS_FAIL = 'CUSTOMER_PLATFORM/PartnerImage/LOAD_FOR_FLS_FAIL';

export const loadFunctionalLocationsImages = functionalLocations => {
  const params = {
    functionalLocations: functionalLocations,
    type: 'building',
  };
  return async dispatch => {
    dispatch({ type: LOAD_FOR_FLS });
    try {
      const result = await dispatch(MasterDataService.images(params));
      return dispatch({
        type: LOAD_FOR_FLS_SUCCESS,
        key: 'fl_image_' + JSON.stringify(functionalLocations),
        functionalLocations,
        result: result.data,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FOR_FLS_FAIL,
        key: 'fl_image_' + JSON.stringify(functionalLocations),
        error,
      });
    }
  };
};

export default createReducerFromMapping(
  {
    [UPSERT]: state => ({
      ...state,
      updating: true,
    }),
    [UPSERT_SUCCESS]: state => ({
      ...state,
      updating: false,
      updated: true,
    }),
    [UPSERT_FAIL]: (state, action) => ({
      ...state,
      updating: false,
      updated: false,
      error: action.error,
    }),
    [DELETE]: state => ({
      ...state,
      deleting: true,
    }),
    [DELETE_SUCCESS]: state => ({
      ...state,
      deleting: false,
      deleted: true,
    }),
    [DELETE_FAIL]: (state, action) => ({
      ...state,
      deleting: false,
      deleted: false,
      error: action.error,
    }),

    [LOAD_FOR_PARTNER]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_FOR_PARTNER_SUCCESS]: (state, action) => {
      // If result is an empty array, let's clear that partner state
      let result = {};
      if (action.result && action.result.length === 0) {
        result = {
          [action.partnerNumber]: [],
        };
      } else {
        result = groupBy(action.result, 'partnerNumber');
      }

      return {
        ...state,
        loading: false,
        byPartner: assign({}, state.byPartner, result),
      };
    },
    [LOAD_FOR_PARTNER_FAIL]: state => ({
      ...state,
      loading: false,
    }),
    [LOAD_FOR_FL]: state => ({
      ...state,
      loadingFLImage: true,
    }),
    [LOAD_FOR_FL_SUCCESS]: (state, action) => {
      let result = {};
      if (action.result && action.result.length === 0) {
        result = {
          [action.functionalLocation]: [],
        };
      } else {
        result = groupBy(action.result, 'functionalLocation');
      }

      return {
        ...state,
        loadingFLImage: false,
        byFL: assign({}, state.byFL, result),
      };
    },
    [LOAD_FOR_FL_FAIL]: state => ({
      ...state,
      loadingFLImage: false,
    }),
    [LOAD_FOR_FLS]: state => ({
      ...state,
      loadingFLImage: true,
    }),
    [LOAD_FOR_FLS_SUCCESS]: (state, action) => {
      const imagesByFl = groupBy(action.result, 'functionalLocation');
      const result = Object.fromEntries(
        action.functionalLocations.map(key => {
          const oldImages = state.byFL[key] || [];
          const newImages = imagesByFl[key] || [];
          return [key, [...oldImages, ...newImages]];
        })
      );

      return {
        ...state,
        loadingFLImage: false,
        byFL: assign({}, state.byFL, result),
      };
    },
    [LOAD_FOR_FLS_FAIL]: state => ({
      ...state,
      loadingFLImage: false,
    }),
  },
  initialState
);
