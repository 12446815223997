import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Infotip } from 'components';
import translations from 'decorators/Translations/translations';
import ProgressBar from 'components/ProgressBar/ProgressBar';

const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
Column.displayName = 'Column';

const Value = styled.span`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2px;
  margin-left: 5px;
  white-space: nowrap;
`;
Value.displayName = 'Value';

const ValueIndicator = styled.span`
  display: inline-block;
  width: 50px;
`;
ValueIndicator.displayName = 'ValueIndicator';

const StyledInfotip = styled(Infotip)`
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  ${props => (props.align === 'left' ? 'right: var(--size-md);' : '')}
  ${props => (props.align === 'right' ? 'left: var(--size-md);' : '')}
  width: calc(100% - var(--size-md));
  font-size: ${props => props.theme.fontSize.xxs};
`;

export const ValueBarIndicatorColumn = ({
  t,
  data: { value = 0, formattedValue, unit, infotip, color },
  align = 'right',
}) => {
  const isPercentageValue = unit === '%';
  const content = (
    <>
      {isPercentageValue && (
        <ValueIndicator>
          <ProgressBar color={color} value={value || 0} height={8} />
        </ValueIndicator>
      )}
      <Value>
        {isPercentageValue ? Math.round(value) : formattedValue || value}&nbsp;{unit}
      </Value>
    </>
  );
  return (
    <Column>
      {infotip ? (
        <StyledInfotip absolute text={t(infotip)} align={align}>
          {content}
        </StyledInfotip>
      ) : (
        content
      )}
    </Column>
  );
};
ValueBarIndicatorColumn.displayName = 'ValueBarIndicatorColumn';

ValueBarIndicatorColumn.defaultProps = {
  data: {},
};

ValueBarIndicatorColumn.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  align: PropTypes.string,
};

export default translations(ValueBarIndicatorColumn);
