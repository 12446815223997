export const readFile = (fileData, callback) => {
  const reader = new FileReader();
  reader.onload = event => {
    if (event.target.result) {
      let fileString = event.target.result;
      if (fileString.indexOf(',') !== -1) {
        fileString = fileString.split(',')[1];
      }

      return callback({
        file: fileString,
        filename: fileData.name,
        mimeType: fileData.type,
        size: fileData.size,
      });
    }
  };

  reader.readAsDataURL(fileData);
};

export const readFileAsync = fileData => new Promise(resolve => readFile(fileData, resolve));
