import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const CheckIconMark = styled.span`
  display: block;
  width: 10px;
  height: 15px;
  border: 0;
  border-bottom: 4px solid ${props => props.colorCss};
  border-right: 4px solid ${props => props.colorCss};
  border-radius: 20%;
  transform: translate(2.5px, -1.5px) rotate(40deg);
`;

CheckIconMark.propTypes = {
  colorCss: PropTypes.string,
};

export const CheckIconContainer = styled.span`
  display: block;
  width: 15px;
  height: 15px;
  transform: scale(${props => props.pixelSize / 15});
`;

CheckIconContainer.propTypes = {
  pixelSize: PropTypes.number,
};

export function CheckIcon({ colorCss, style, pixelSize = 15 }) {
  return (
    <CheckIconContainer pixelSize={pixelSize} style={style}>
      <CheckIconMark colorCss={colorCss} />
    </CheckIconContainer>
  );
}

CheckIcon.propTypes = {
  style: PropTypes.object,
  colorCss: PropTypes.string,
  pixelSize: PropTypes.number,
};
