import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { SERVICE_API_PATH } from 'services/masterData';
import ApiRequest from 'utils/Fetch/ApiRequest';
import { OnOffData } from 'types/Charts';

export function useOnOffSensorChartData(
  sensorIds: number[],
  startDate: string | Date | number | null,
  endDate: string | Date | number | null,
  include: string[]
): UseQueryResult<OnOffData> {
  return useQuery(
    ['onOffSensorChartData', { sensorIds, startDate, endDate, include }],
    async () => {
      return (await ApiRequest.request({
        method: 'GET',
        apiPath: `${SERVICE_API_PATH}/sensor-values/charts/on-off-data`,
        query: {
          sensorIds,
          startDate: startDate && new Date(startDate).toISOString(),
          endDate: endDate && new Date(endDate).toISOString(),
          include: include.join(','),
        },
        useAuthorization: true,
        noCache: true,
      })) as OnOffData;
    },
    {
      enabled: Boolean(sensorIds.length && startDate && endDate),
      cacheTime: 5 * 60 * 1000,
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
}
