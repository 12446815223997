import { createReducerFromMapping } from 'redux/utils/index';
import IoTService from 'services/iot';
import filter from 'lodash/filter';
import mean from 'lodash/mean';
import forEach from 'lodash/forEach';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import parseISO from 'date-fns/parseISO';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { getAuthorizationParams } from 'utils/profile/index';

const initialState = {
  alarms: {},
  byPartner: {
    loading: false,
  },
  byFL: {},
  loading: false,
  handlingTimeKPI: {
    loading: false,
  },
  timeToActionKPI: {
    loading: false,
  },
  performance: {},
  actionCounts: {},
  handlingTime: {},
  timeToAction: {},
  benchmarking: {},
  countsPerMonth: {},
};

export const LOAD_FOR_FL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL';
export const LOAD_FOR_FL_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL_SUCCESS';
export const LOAD_FOR_FL_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_FOR_FL_FAIL';

export const loadFunctionalLocationAlarms = (functionalLocation, start, end) => async dispatch => {
  dispatch({ type: LOAD_FOR_FL });
  try {
    const result = await IoTService.findAlarms({
      functionalLocations: [functionalLocation.functionalLocation],
      start: start.toISOString(),
      end: end.toISOString(),
    });

    return dispatch({
      type: LOAD_FOR_FL_SUCCESS,
      functionalLocation: functionalLocation.functionalLocation,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_FOR_FL_FAIL,
      error,
    });
  }
};

export const LOAD_PARTNER_COUNTS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS';
export const LOAD_PARTNER_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS_SUCCESS';
export const LOAD_PARTNER_COUNTS_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_COUNTS_FAIL';

export const loadPartnerAlarmCounts = (partnerNumber, start, end) => async dispatch => {
  dispatch({ type: LOAD_PARTNER_COUNTS });
  try {
    const result = await IoTService.alarmCounts({
      partnerNumbers: [partnerNumber],
      start: start.toISOString(),
      end: end.toISOString(),
    });

    return dispatch({
      type: LOAD_PARTNER_COUNTS_SUCCESS,
      partnerNumber,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_PARTNER_COUNTS_FAIL,
      error,
    });
  }
};

export const LOAD_COUNTS_PER_MONTH = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_COUNTS_PER_MONTH';
export const LOAD_COUNTS_PER_MONTH_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_COUNTS_PER_MONTH_SUCCESS';
export const LOAD_COUNTS_PER_MONTH_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_COUNTS_PER_MONTH_FAIL';

export const loadAlarmCountsPerMonth = (year, partnerNumber, functionalLocation) => async (dispatch, getState) => {
  dispatch({ type: LOAD_COUNTS_PER_MONTH });
  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({
    profile,
    partnerNumber,
    functionalLocationId: functionalLocation,
  });
  try {
    const result = await IoTService.alarmCountsPerMonth({ ...authorizationParams, year });

    return dispatch({
      type: LOAD_COUNTS_PER_MONTH_SUCCESS,
      year,
      partnerNumber,
      functionalLocation,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_COUNTS_PER_MONTH_FAIL,
      year,
      partnerNumber,
      functionalLocation,
    });
  }
};

export const LOAD_HANDLING_TIME_KPI = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI';
export const LOAD_HANDLING_TIME_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI_SUCCESS';
export const LOAD_HANDLING_TIME_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_KPI_FAIL';

export const loadHandlingTimeKPI = partnerNumber => {
  return async dispatch => {
    dispatch({ type: LOAD_HANDLING_TIME_KPI });
    try {
      const result = await IoTService.alarmKpi({ partnerNumbers: [partnerNumber], kpi: 'handlingTime' });

      return dispatch({
        type: LOAD_HANDLING_TIME_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_HANDLING_TIME_KPI_FAIL,
        error,
      });
    }
  };
};

export const LOAD_TIME_TO_ACTION_KPI = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI';
export const LOAD_TIME_TO_ACTION_KPI_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI_SUCCESS';
export const LOAD_TIME_TO_ACTION_KPI_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_KPI_FAIL';

export const loadTimeToActionKPI = partnerNumber => {
  return async dispatch => {
    dispatch({ type: LOAD_TIME_TO_ACTION_KPI });
    try {
      const result = await IoTService.alarmKpi({ partnerNumbers: [partnerNumber], kpi: 'timeToAction' });

      return dispatch({
        type: LOAD_TIME_TO_ACTION_KPI_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_TIME_TO_ACTION_KPI_FAIL,
        error,
      });
    }
  };
};

export const LOAD_ALARM_PERFORMANCE = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE';
export const LOAD_ALARM_PERFORMANCE_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE_SUCCESS';
export const LOAD_ALARM_PERFORMANCE_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_ALARM_PERFORMANCE_FAIL';

export const loadAlarmPerformance = partnerNumber => async dispatch => {
  dispatch({ type: LOAD_ALARM_PERFORMANCE, partnerNumber });
  try {
    const result = await IoTService.alarmPerformance({ partnerNumbers: [partnerNumber] });
    dispatch({
      type: LOAD_ALARM_PERFORMANCE_SUCCESS,
      partnerNumber,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_ALARM_PERFORMANCE_FAIL,
      partnerNumber,
      error,
    });
  }
};

export const LOAD_BENCHMARKING = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING';
export const LOAD_BENCHMARKING_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING_SUCCESS';
export const LOAD_BENCHMARKING_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_BENCHMARKING_FAIL';

export const loadBenchmarking = partnerNumber => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_BENCHMARKING });
    const profile = getState().profile.profile;
    const authorizationParams = getAuthorizationParams({ profile, partnerNumber });
    try {
      const result = await IoTService.alarmBenchmarking(authorizationParams);

      return dispatch({
        type: LOAD_BENCHMARKING_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_BENCHMARKING_FAIL,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_ACTION_COUNTS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS';
export const LOAD_PARTNER_ACTION_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS_SUCCESS';
export const LOAD_PARTNER_ACTION_COUNTS_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_PARTNER_ACTION_COUNTS_FAIL';

export const loadPartnerAlarmActionCounts = (partnerNumber, start, end) => async (dispatch, getState) => {
  dispatch({ type: LOAD_PARTNER_ACTION_COUNTS });
  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({ profile, partnerNumber });
  try {
    const result = await IoTService.alarmActionCount({
      ...authorizationParams,
      start: start.toISOString(),
      end: end.toISOString(),
    });

    return dispatch({
      type: LOAD_PARTNER_ACTION_COUNTS_SUCCESS,
      partnerNumber,
      result,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_PARTNER_ACTION_COUNTS_FAIL,
      error,
    });
  }
};

export const LOAD_HANDLING_TIME_BY_MONTH = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH';
export const LOAD_HANDLING_TIME_BY_MONTH_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH_SUCCESS';
export const LOAD_HANDLING_TIME_BY_MONTH_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_HANDLING_TIME_BY_MONTH_FAIL';

export const loadHandlingTimeByMonth = (year, partnerNumber, functionalLocation) => async (dispatch, getState) => {
  const container = functionalLocation || partnerNumber;
  dispatch({ type: LOAD_HANDLING_TIME_BY_MONTH, container, year });
  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({
    profile,
    partnerNumber,
    functionalLocationId: functionalLocation,
  });
  try {
    const result = await IoTService.alarmHandlingTimeByMonth({ ...authorizationParams, year });

    return dispatch({
      type: LOAD_HANDLING_TIME_BY_MONTH_SUCCESS,
      container,
      result,
      year,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_HANDLING_TIME_BY_MONTH_FAIL,
      container,
      error,
      year,
    });
  }
};

export const LOAD_TIME_TO_ACTION_BY_MONTH = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH';
export const LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS';
export const LOAD_TIME_TO_ACTION_BY_MONTH_FAIL = 'CUSTOMER_PLATFORM/IoT_Alarm/LOAD_TIME_TO_ACTION_BY_MONTH_FAIL';

export const loadTimeToActionByMonth = (year, partnerNumber, functionalLocation) => async (dispatch, getState) => {
  const container = functionalLocation || partnerNumber;
  dispatch({ type: LOAD_TIME_TO_ACTION_BY_MONTH, container, year });
  const profile = getState().profile.profile;
  const authorizationParams = getAuthorizationParams({
    profile,
    partnerNumber,
    functionalLocationId: functionalLocation,
  });
  try {
    const result = await IoTService.alarmTimeToActionByMonth({ ...authorizationParams, year });

    return dispatch({
      type: LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS,
      container,
      result,
      year,
    });
  } catch (error) {
    return dispatch({
      type: LOAD_TIME_TO_ACTION_BY_MONTH_FAIL,
      container,
      error,
      year,
    });
  }
};

export default createReducerFromMapping(
  {
    [LOAD_FOR_FL]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_FOR_FL_SUCCESS]: (state, action) => ({
      ...state,
      byFL: {
        ...state.byFL,
        [action.functionalLocation]: mapAlarmsForSortedTable(action.result),
      },
      loading: false,
    }),
    [LOAD_FOR_FL_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      loading: false,
    }),
    [LOAD_PARTNER_COUNTS]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        loading: true,
      },
    }),
    [LOAD_PARTNER_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),
    [LOAD_PARTNER_COUNTS_FAIL]: (state, action) => ({
      ...state,
      byPartner: {
        ...state.byPartner,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_COUNTS_PER_MONTH]: (state, action) => {
      const change = {};
      if (action.functionalLocation) {
        change[action.functionalLocation] = {
          ...state.countsPerMonth[action.functionalLocation],
          [action.year]: {
            loading: true,
          },
        };
      } else if (action.partnerNumber) {
        change[action.partnerNumber] = {
          ...state.countsPerMonth[action.partnerNumber],
          [action.year]: {
            loading: true,
          },
        };
      }

      return {
        ...state,
        countsPerMonth: {
          ...state.countsPerMonth,
          ...change,
        },
      };
    },
    [LOAD_COUNTS_PER_MONTH_SUCCESS]: (state, action) => {
      const change = {};
      if (action.functionalLocation) {
        change[action.functionalLocation] = {
          ...state.countsPerMonth[action.functionalLocation],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        };
      } else if (action.partnerNumber) {
        change[action.partnerNumber] = {
          ...state.countsPerMonth[action.partnerNumber],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        };
      }

      return {
        ...state,
        countsPerMonth: {
          ...state.countsPerMonth,
          ...change,
        },
      };
    },
    [LOAD_COUNTS_PER_MONTH_FAIL]: (state, action) => {
      const change = {};
      if (action.functionalLocation) {
        change[action.functionalLocation] = {
          ...state.countsPerMonth[action.functionalLocation],
          [action.year]: {
            loading: false,
            error: true,
          },
        };
      } else if (action.partnerNumber) {
        change[action.partnerNumber] = {
          ...state.countsPerMonth[action.partnerNumber],
          [action.year]: {
            loading: false,
            error: true,
          },
        };
      }

      return {
        ...state,
        countsPerMonth: {
          ...state.countsPerMonth,
          ...change,
        },
      };
    },

    [LOAD_HANDLING_TIME_KPI]: state => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        loading: true,
      },
    }),

    [LOAD_HANDLING_TIME_KPI_SUCCESS]: (state, action) => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_HANDLING_TIME_KPI_FAIL]: (state, action) => ({
      ...state,
      handlingTimeKPI: {
        ...state.handlingTimeKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_TIME_TO_ACTION_KPI]: state => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        loading: true,
      },
    }),

    [LOAD_TIME_TO_ACTION_KPI_SUCCESS]: (state, action) => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_TIME_TO_ACTION_KPI_FAIL]: (state, action) => ({
      ...state,
      timeToActionKPI: {
        ...state.timeToActionKPI,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_BENCHMARKING]: state => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        loading: true,
      },
    }),

    [LOAD_BENCHMARKING_SUCCESS]: (state, action) => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        [action.partnerNumber]: action.result,
        loading: false,
      },
    }),

    [LOAD_BENCHMARKING_FAIL]: (state, action) => ({
      ...state,
      benchmarking: {
        ...state.benchmarking,
        loading: false,
      },
      error: action.error,
    }),

    [LOAD_ALARM_PERFORMANCE]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: true,
        },
      },
    }),
    [LOAD_ALARM_PERFORMANCE_SUCCESS]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: false,
          data: action.result,
        },
      },
    }),
    [LOAD_ALARM_PERFORMANCE_FAIL]: (state, action) => ({
      ...state,
      performance: {
        ...state.performance,
        [action.partnerNumber]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_PARTNER_ACTION_COUNTS]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: true,
        },
      },
    }),
    [LOAD_PARTNER_ACTION_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: false,
          data: action.result,
        },
      },
    }),
    [LOAD_PARTNER_ACTION_COUNTS_FAIL]: (state, action) => ({
      ...state,
      actionCounts: {
        ...state.actionCounts,
        [action.partnerNumber]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_HANDLING_TIME_BY_MONTH]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.container]: {
          ...state.handlingTime[action.container],
          [action.year]: {
            loading: true,
          },
        },
      },
    }),
    [LOAD_HANDLING_TIME_BY_MONTH_SUCCESS]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.container]: {
          ...state.handlingTime[action.container],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        },
      },
    }),
    [LOAD_HANDLING_TIME_BY_MONTH_FAIL]: (state, action) => ({
      ...state,
      handlingTime: {
        ...state.handlingTime,
        [action.container]: {
          ...state.handlingTime[action.container],
          [action.year]: {
            loading: false,
            error: action.error,
          },
        },
      },
    }),

    [LOAD_TIME_TO_ACTION_BY_MONTH]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.container]: {
          ...state.timeToAction[action.container],
          [action.year]: {
            loading: true,
          },
        },
      },
    }),
    [LOAD_TIME_TO_ACTION_BY_MONTH_SUCCESS]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.container]: {
          ...state.timeToAction[action.container],
          [action.year]: {
            loading: false,
            data: action.result,
          },
        },
      },
    }),
    [LOAD_TIME_TO_ACTION_BY_MONTH_FAIL]: (state, action) => ({
      ...state,
      timeToAction: {
        ...state.timeToAction,
        [action.container]: {
          ...state.timeToAction[action.container],
          [action.year]: {
            loading: false,
            error: action.error,
          },
        },
      },
    }),
  },
  initialState
);

export const mapAlarmsForSortedTable = result => {
  const grouped = groupBy(result, 'source');
  const alarms = [];
  const actions = [];
  forEach(grouped, (rows, alarmClass) => {
    // Pick latest alarm from the list. Data might have actionTimestamps or they are nulls...
    let row = first(
      orderBy(
        filter(rows, row => row.actionTimestamp !== null),
        'actionTimestamp',
        'desc'
      )
    );
    // Fallback if all actionTimestamps are null, take first.
    if (isEmpty(row)) {
      row = first(rows);
    }
    const fixedSource = alarmClass.replace(/ {2}/g, ' ').replace('Source: ', '');
    const allActions = filter(orderBy(rows, 'actionTimestamp', 'desc'), 'actionCategoryId');
    const mappedActions = allActions.map(action => ({
      alarm: {
        value: fixedSource,
      },
      action,
      alerts: rows.length,
      date: {
        date: parseISO(action.actionTimestamp),
        value: parseISO(action.actionTimestamp),
      },
      status: {
        value: '1',
        status: row.type,
        title: row.type,
      },
      event: {
        type: 'notice',
        description: action.actionText,
      },
      actionCategoryIds: allActions.map(action => action.actionCategoryId),
      message: row.message,
    }));
    // Add only alarms with action(s)
    if (mappedActions[0]) {
      alarms.push(mappedActions[0]);
    }
    actions.push(...mappedActions);
  });

  const actionTimes = filter(result, alarm => alarm.acknowledgedTimestamp).map(alarm =>
    differenceInMinutes(parseISO(alarm.acknowledgedTimestamp), parseISO(alarm.timestamp))
  );
  const timeToAction = filter(result, alarm => alarm.actionTimestamp).map(alarm =>
    differenceInMinutes(parseISO(alarm.actionTimestamp), parseISO(alarm.timestamp))
  );
  const actionGroups = groupBy(
    filter(result, alarm => alarm.actionCategoryId),
    'actionCategoryId'
  );

  const byAction = [];
  const byActionUnknown = { title: `alarmActionGroup_unknown`, value: 0 };
  forEach(actionGroups, (rows, key) => {
    const country = rows[0].country;
    const countryPart = country ? `${country}_` : '';
    // Keys 1-8 have defined translations in all countries (FI has 1-10). All others are unknown.
    const activeKeyMax = country && country === 'FI' ? 10 : 8;
    if (key <= activeKeyMax) {
      byAction.push({
        title: `alarmActionGroup_${countryPart}${key}`,
        id: key,
        value: rows.length,
      });
    } else {
      Object.assign(byActionUnknown, { value: byActionUnknown.value + rows.length });
    }
  });

  if (byActionUnknown.value > 0) {
    byAction.push(byActionUnknown);
  }

  return {
    alarms,
    actions,
    totals: {
      all: result.length,
      open: filter(result, { status: 0 }).length,
      acknowledged: filter(result, { status: 1 }).length,
      withAction: filter(result, alarm => alarm.actionTimestamp).length,
      averageActionTime: mean(actionTimes).toFixed(0),
      averageTimeToAction: mean(timeToAction).toFixed(0),
      byAction,
    },
  };
};
