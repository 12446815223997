import { connect } from 'react-redux';

import { loadEnergyChartValues } from 'redux/modules/iot/energy'

const mapStateToProps = (state) => ({
  loading: state.energy.energyChartLoading,
  energyChartValues: state.energy.energyChartValues
});

const mapDispatchToProps = {
  loadEnergyChartValues
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
