import { FORM_ONLY_FIELDS } from '../ServiceModuleForm/utils/constants';
import {
  setSupplierFields,
  setContactFields,
  setFunctionalLocationField,
  setCategoryFields,
  setAllowRejectionField,
  setPartOfFixedPriceField,
  setFunctionalLocationAndDisciplineForUpsert,
  setContactForUpsert,
  setSupplierForUpsert,
  omitFormOnlyProperties,
  setAllowRejectionForUpsert,
  setPartOfFixedPriceForUpsert,
  fixPhoneNumbers,
} from '../ServiceModuleForm/utils/setters';
import Schedule from 'utils/Schedule/Schedule';
import { convertUtcMinutesAfterMidnight, convertLocalHoursAndMinutes } from 'utils/Schedule/utils';
import { startOfUTCDay } from 'utils/Date/date';

export const getHeaderText = (t, isCreationMode, isPlannedMaintenance) => {
  if (isPlannedMaintenance) {
    return isCreationMode ? t('Create new planned maintenance') : t('Edit planned maintenance');
  }
  return isCreationMode ? t('Create new maintenance plan') : t('Edit maintenance plan');
};

export const createDefaultValues = (
  maintenancePlan,
  isPlannedMaintenance,
  profile,
  functionalLocationId,
  options = {}
) => {
  const defaultValues = Object.assign({}, FORM_ONLY_FIELDS);

  setSupplierFields(maintenancePlan, defaultValues, options);
  setContactFields(maintenancePlan, defaultValues, profile);
  setPartOfFixedPriceField(maintenancePlan, defaultValues);
  setFunctionalLocationField(maintenancePlan, defaultValues, functionalLocationId);
  setCategoryFields(maintenancePlan, defaultValues, options);
  setSchedule(maintenancePlan, defaultValues, isPlannedMaintenance);
  setServiceOrderDays(maintenancePlan, defaultValues);
  setServiceOrderTime(maintenancePlan, defaultValues);
  setAllowRejectionField(maintenancePlan, defaultValues);

  return defaultValues;
};

export const setSchedule = (maintenance, defaultValues, isPlannedMaintenance) => {
  if (isPlannedMaintenance) {
    defaultValues.schedule = { recurrence: false, start: maintenance.plannedDate || new Date() };
  } else {
    // use Schedule to parse iCalendar data
    const scheduleObject = maintenance.schedule ? new Schedule(maintenance.schedule) : new Schedule();
    const scheduleModel = scheduleObject.createModel();

    defaultValues.schedule = { recurrence: true, start: new Date(), ...scheduleModel, scheduleObject };
  }
};

export const setServiceOrderDays = (maintenancePlan, defaultValues) => {
  if (!maintenancePlan.createServiceOrderDays && maintenancePlan.createServiceOrderDays !== 0) {
    defaultValues.createServiceOrderDays = 7;
  }
};

export const setServiceOrderTime = (maintenancePlan, defaultValues) => {
  // default time is 12:00
  if (!maintenancePlan.createServiceOrderAt && maintenancePlan.createServiceOrderAt !== 0) {
    defaultValues.hours = 12;
    defaultValues.minutes = Number(0);
  } else {
    // convert (utc-)minutes to time fields
    const { hours, minutes } = convertUtcMinutesAfterMidnight(maintenancePlan.createServiceOrderAt);

    defaultValues.hours = hours;
    defaultValues.minutes = minutes;
  }
};

const setServiceOrderTimeForUpsert = (model, newPlan) => {
  if (model.assignmentSettings) {
    // convert to utc minutes after midnight
    newPlan.createServiceOrderAt = convertLocalHoursAndMinutes(model.hours, model.minutes);
  } else {
    // planned maintenance is assigned at form submit
    delete newPlan.createServiceOrderAt;
    delete newPlan.createServiceOrderDays;
  }

  delete newPlan.hours;
  delete newPlan.minutes;
};

const setScheduleForUpsert = (model, newPlan) => {
  if (model.schedule?.scheduleObject) {
    const scheduleModel = Object.assign({}, model.schedule);

    // set new schedule values to scheduleObject and call its toString-function to get iCalendar-string
    model.schedule.scheduleObject.setFromModel(scheduleModel);
    newPlan.schedule = model.schedule.scheduleObject.toString();
    newPlan.startDate = startOfUTCDay(model.schedule.start);
    newPlan.endDate = startOfUTCDay(model.schedule.end);
  } else {
    // set plannedDate for planned maintenance and delete schedule
    newPlan.plannedDate = model.schedule.start;
    delete newPlan.schedule;
  }
};

// revert soft delete when saving
const revertSoftDelete = (model, newPlan) => {
  if (model.schedule.recurrence) {
    // maintenance plan
    newPlan.deleted = false;
  } else {
    // planned maintenance
    newPlan.status = 'Planned';
  }
};

export const getMaintenanceForUpsert = (model, systems, categories) => {
  const newPlan = Object.assign({}, model);

  setFunctionalLocationAndDisciplineForUpsert(model, newPlan, systems, categories);
  setContactForUpsert(model, newPlan);
  setSupplierForUpsert(model, newPlan);
  setPartOfFixedPriceForUpsert(model, newPlan);
  setAllowRejectionForUpsert(model, newPlan);
  setScheduleForUpsert(model, newPlan);
  setServiceOrderTimeForUpsert(model, newPlan);

  fixPhoneNumbers(newPlan);
  revertSoftDelete(model, newPlan);

  return omitFormOnlyProperties(newPlan);
};

export const resetScheduleModel = (maintenancePlan, recurrence) => {
  let scheduleObject;
  if (recurrence) {
    scheduleObject = maintenancePlan.schedule ? new Schedule(maintenancePlan.schedule) : new Schedule();
  } else {
    return { recurrence, start: new Date() };
  }
  const scheduleModel = scheduleObject.createModel();

  return { recurrence, start: new Date(), ...scheduleModel, scheduleObject };
};

export const getMaintenancePlanForDelete = (maintenancePlan, partnerNumber) => {
  const now = startOfUTCDay(new Date());
  const scheduleObject = new Schedule(maintenancePlan.schedule);
  scheduleObject.setRecurrenceEnd(now);
  const newSchedule = scheduleObject.toString();

  return {
    ...maintenancePlan,
    partnerNumber,
    deleted: true,
    endDate: now,
    schedule: newSchedule,
  };
};
