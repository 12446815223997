import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import Columns from 'components/Columns/Columns';
import Column from 'components/Columns/Column';
import BreakdownTabSkeleton from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownTab/BreakdownTabSkeleton';
import ZoomableChart, { ChartWrapper, ResetButton } from 'components/Charts/ZoomableChart';
import WasteTypeSelector from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteTypeSelector/WasteTypeSelector';
import translations from 'decorators/Translations/translations';
import connect from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/BreakdownOneWasteType/connectBreakdownOneWasteType';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { sensorTypeNameToWasteType } from 'containers/Application/Modules/RecyclingModule/RecyclingDataUtils';
import BreakdownTotals from 'components/BreakdownTotals/BreakdownTotals';
import { getMonthsShort } from 'utils/Date/dateFormatter';

const createSeries = (t, theme, selectedTimeframe, tab, monthliesData) => {
  const series = tab.createYearlySeries(monthliesData, [
    selectedTimeframe.startTime.getFullYear() - 1,
    selectedTimeframe.startTime.getFullYear(),
  ]);
  return series.map(entry => ({
    ...entry,
    type: 'column',
    color: theme.charts.colorsRecycling[entry.colorName],
    _unit: t(entry.unit),
    _showTooltipForZeroValue: true,
  }));
};

const createTotals = (t, selectedSensor, selectedTimeframe, tab, totalsData) => {
  const totals = tab.createYearlyTotals(totalsData, [
    selectedTimeframe.startTime.getFullYear() - 1,
    selectedTimeframe.startTime.getFullYear(),
  ]);
  return {
    ...totals,
    title: t(sensorTypeNameToWasteType(selectedSensor.sensorType?.name)),
    unit: t(totals.unit),
  };
};

const getWasteTypeOptions = (t, wasteSensors, tab) =>
  orderBy(
    tab.filterSensors(wasteSensors).map(wasteSensor => ({
      value: wasteSensor.id,
      label: t(sensorTypeNameToWasteType(wasteSensor.sensorType?.name)),
    })),
    'label',
    'asc'
  );

export const BreakdownOneWasteType = ({
  t,
  theme,
  profile,
  wasteSensors,
  tab,
  selectedTimeframe,
  selectedSensor,
  monthliesData,
  totalsData,
  isLoading,
  reset,
  selectSensor,
}) => {
  if (isLoading) {
    return <BreakdownTabSkeleton />;
  }
  const series = createSeries(t, theme, selectedTimeframe, tab, monthliesData);
  const totals = createTotals(t, selectedSensor, selectedTimeframe, tab, totalsData);
  const wasteTypeOptions = getWasteTypeOptions(t, wasteSensors, tab);
  return (
    <Columns justifyContent="space-between">
      <Column columnWidth={{ landscape: 9, default: 12 }}>
        <ChartWrapper>
          <ZoomableChart
            t={t}
            language={profile.language}
            categories={getMonthsShort().map(capitalize)}
            series={series}
            xTitle={null}
          />
          <ResetButton onClick={reset}>{t('Back')}</ResetButton>
        </ChartWrapper>
      </Column>
      <Column columnWidth={{ landscape: 3, default: 12 }}>
        <WasteTypeSelector
          wasteTypeOptions={wasteTypeOptions}
          selectedWasteType={selectedSensor.id}
          onWasteTypeChange={selectSensor}
        />
        {totals?.total ? (
          <BreakdownTotals
            header={totals?.title}
            icon={tab.totalsIconName}
            value={totals?.total}
            unit={totals?.unit}
            language={profile.language}
            positiveValueColor={theme.colors?.black}
            categories={totals?.perCategory.map(category => ({
              ...category,
              color: theme.charts.colorsRecycling[category.colorName],
            }))}
          />
        ) : null}
      </Column>
    </Columns>
  );
};

BreakdownOneWasteType.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  wasteSensors: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  tab: PropTypes.object.isRequired,
  selectedTimeframe: PropTypes.object.isRequired,
  selectedSensor: PropTypes.object.isRequired,
  monthliesData: PropTypes.arrayOf(PropTypes.object.isRequired),
  totalsData: PropTypes.arrayOf(PropTypes.object.isRequired),
  isLoading: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  selectSensor: PropTypes.func.isRequired,
};

export default withRouter(withTheme(translations(connect(BreakdownOneWasteType))));
