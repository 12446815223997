import { createReducerFromMapping } from 'redux/utils/index';
import MasterDataService from 'services/masterData';
import { isAdminRole } from 'utils/Data/profileData';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  loading: false,
  loadingSensors: [],
  buildingHierarchy: {},
  sensors: {},
  floors: {},
  coords: {},
  images: {},
  areas: {},
  sensorDataTypes: [],
  loadingDataTypes: false,
  loadingDataTypesError: null,
  error: null,
  sensorSearchWord: '',
  searchError: null,
  searchResult: [],
  searchingSensors: false,
  sensorSearchResult: [],
  editableSensorId: null,
};

export const LOAD_TYPES = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES';
export const LOAD_TYPES_SUCCESS = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES_SUCCESS';
export const LOAD_TYPES_FAIL = 'CUSTOMER_PLATFORM/Building/LOAD_TYPES_FAIL';

export const loadSensorDataTypes = () => {
  return async dispatch => {
    dispatch({ type: LOAD_TYPES });
    try {
      const result = await MasterDataService.sensorTypes();
      return dispatch({
        type: LOAD_TYPES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_TYPES_FAIL,
        error,
      });
    }
  };
};
export const SET_SENSOR_SEARCH_WORD = 'CUSTOMER_PLATFORM/Building/SENSOR_SEARCH_WORD';

export const setSensorSearchWord = searchWord => {
  return {
    type: SET_SENSOR_SEARCH_WORD,
    searchWord,
  };
};

export const loadBuildingLevelSensorHierarchiesByFL = functionalLocation => {
  const filter = {
    where: {
      functionalLocation,
      type: 'building',
    },
  };
  return async dispatch => {
    const result = await dispatch(MasterDataService.sensorHierarchies(filter));
    return result;
  };
};

export const LOAD = 'CUSTOMER_PLATFORM/Building/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/Building/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/Building/LOAD_FAIL';

const getIncludes = functionalLocation => {
  const coordsIncludes = [
    {
      relation: 'coords',
      scope: {
        include: {
          relation: 'subsensors',
        },
        where: { functionalLocation },
      },
    },
  ];

  const sensorIncludes = [
    coordsIncludes,
    'sensorMeta',
    { sensorType: ['latestValueAggregation', 'aggregations'] },
    {
      children: [
        coordsIncludes,
        'sensorMeta',
        { parent: ['sensorType'] },
        { sensorType: ['latestValueAggregation', 'aggregations'] },
      ],
    },
  ];
  const hierarchyIncludes = [coordsIncludes, 'images', { sensors: sensorIncludes }];
  return { sensorIncludes, coordsIncludes, hierarchyIncludes };
};

export const getSensorHierachyFilter = functionalLocation => {
  const { sensorIncludes, coordsIncludes, hierarchyIncludes } = getIncludes(functionalLocation);
  return {
    where: {
      functionalLocation,
      type: 'building',
    },
    include: [
      {
        children: [
          {
            children: hierarchyIncludes,
          },
          {
            sensors: sensorIncludes,
          },
          coordsIncludes,
          'images',
        ],
      },
      {
        sensors: sensorIncludes,
      },
      coordsIncludes,
      'images',
    ],
  };
};

export const loadSensorHierarchies = functionalLocation => {
  const filter = getSensorHierachyFilter(functionalLocation);
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && isAdminRole(profile.role);

    dispatch({ type: LOAD, key: functionalLocation + '_sensorHierarchies' });
    try {
      const result = await dispatch(MasterDataService.sensorHierarchies(filter, noCache));
      return dispatch({
        type: LOAD_SUCCESS,
        key: functionalLocation + '_sensorHierarchies',
        functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key: functionalLocation + '_sensorHierarchies',
        error,
      });
    }
  };
};

export const LOAD_MULTIPLE = 'CUSTOMER_PLATFORM/Building/LOAD_MULTIPLE';
export const LOAD_MULTIPLE_SUCCESS = 'CUSTOMER_PLATFORM/Building/LOAD_MULTIPLE_SUCCESS';
export const LOAD_MULTIPLE_FAIL = 'CUSTOMER_PLATFORM/Building/LOAD_MULTIPLE_FAIL';

export const loadBuildingLevelSensorHierarchiesByFLIds = functionalLocationIds => {
  const sensorIncludes = [
    'sensorMeta',
    { sensorType: ['latestValueAggregation'] },
    {
      children: ['sensorMeta', { parent: ['sensorType'] }, { sensorType: ['latestValueAggregation'] }],
    },
  ];
  const filter = {
    where: {
      functionalLocation: {
        inq: functionalLocationIds,
      },
      type: 'building',
    },
    include: [
      {
        children: [
          {
            sensors: sensorIncludes,
          },
        ],
      },
      {
        sensors: sensorIncludes,
      },
    ],
  };
  return async dispatch => {
    dispatch({ type: LOAD_MULTIPLE });
    if (!isArray(functionalLocationIds) || isEmpty(functionalLocationIds)) {
      return dispatch({
        type: LOAD_MULTIPLE_FAIL,
        error: "Couldn't fetch sensors with no location id's",
      });
    }
    try {
      const result = await dispatch(MasterDataService.sensorHierarchies(filter));
      return dispatch({
        type: LOAD_MULTIPLE_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MULTIPLE_FAIL,
        error,
      });
    }
  };
};

export const SEARCH_SENSORS = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS';
export const SEARCH_SENSORS_SUCCESS = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS_SUCCESS';
export const SEARCH_SENSORS_FAIL = 'CUSTOMER_PLATFORM/Building/SEARCH_SENSORS_FAIL';

export const searchSensors = ({buildingId, searchTerm}) => {
  return async (dispatch, getState) => {
    // Invalidate cache for admin users
    const state = getState();
    const profile = state.profile?.profile;
    const noCache = profile && isAdminRole(profile.role);

    dispatch({ type: SEARCH_SENSORS, key: 'searchSensors' });
    try {
      const result = await dispatch(MasterDataService.searchSensors(buildingId, searchTerm, noCache));
      return dispatch({
        type: SEARCH_SENSORS_SUCCESS,
        key: 'searchSensors',
        result,
      });
    } catch (error) {
      return dispatch({
        type: SEARCH_SENSORS_FAIL,
        key: 'searchSensors',
        error,
      });
    }
  };
};

export const UPSERT_COORDS = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS';
export const UPSERT_COORDS_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS_SUCCESS';
export const UPSERT_COORDS_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_COORDS_FAIL';

export const createAreaCoords = data => {
  return async dispatch => {
    dispatch({ type: UPSERT_COORDS });
    try {
      const result = await MasterDataService.createAreaCoords(data);
      return dispatch({
        type: UPSERT_COORDS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_COORDS_FAIL,
        error,
      });
    }
  };
};
export const updateAreaCoords = data => {
  return async dispatch => {
    dispatch({ type: UPSERT_COORDS });
    try {
      const result = await MasterDataService.updateAreaCoords(data);
      return dispatch({
        type: UPSERT_COORDS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_COORDS_FAIL,
        error,
      });
    }
  };
};
export const createSensorCoords = data => {
  return async dispatch => {
    dispatch({ type: UPSERT_COORDS });
    try {
      const result = await MasterDataService.createSensorCoords(data);
      return dispatch({
        type: UPSERT_COORDS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_COORDS_FAIL,
        error,
      });
    }
  };
};
export const updateSensorCoords = data => {
  return async dispatch => {
    dispatch({ type: UPSERT_COORDS });
    try {
      const result = await MasterDataService.updateSensorCoords(data);
      return dispatch({
        type: UPSERT_COORDS_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_COORDS_FAIL,
        error,
      });
    }
  };
};

export const UPSERT_SENSORHIERARCHY = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY';
export const UPSERT_SENSORHIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY_SUCCESS';
export const UPSERT_SENSORHIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSORHIERARCHY_FAIL';

export const upsertSensorHierarchy = sensorHierarchy => {
  return async dispatch => {
    dispatch({ type: UPSERT_SENSORHIERARCHY });
    try {
      let result;
      if (sensorHierarchy.id) {
        const response = await MasterDataService.updateSensorHierarchy(sensorHierarchy);
        result = response.data;
      } else {
        const response = await MasterDataService.createSensorHierarchy(sensorHierarchy);
        result = response.data;
      }
      return dispatch({
        type: UPSERT_SENSORHIERARCHY_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_SENSORHIERARCHY_FAIL,
        error,
      });
    }
  };
};

export const DELETE_SENSORHIERARCHY = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY';
export const DELETE_SENSORHIERARCHY_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY_SUCCESS';
export const DELETE_SENSORHIERARCHY_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSORHIERARCHY_FAIL';

export const deleteSensorHierarchy = (id, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DELETE_SENSORHIERARCHY });
    try {
      const result = await MasterDataService.deleteSensorHierarchy(id);
      return dispatch({
        type: DELETE_SENSORHIERARCHY_SUCCESS,
        id,
        functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_SENSORHIERARCHY_FAIL,
        error,
      });
    }
  };
};

export const UPSERT_SENSOR = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR';
export const UPSERT_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR_SUCCESS';
export const UPSERT_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/UPSERT_SENSOR_FAIL';

export const upsertSensor = sensor => {
  return async dispatch => {
    dispatch({ type: UPSERT_SENSOR });
    try {
      const result = await dispatch(MasterDataService.upsertSensor(sensor));
      return dispatch({
        type: UPSERT_SENSOR_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: UPSERT_SENSOR_FAIL,
        error,
      });
    }
  };
};

export const MOVE_SENSOR = 'CUSTOMER_PLATFORM/Building/MOVE_SENSOR';
export const MOVE_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/MOVE_SENSOR_SUCCESS';
export const MOVE_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/MOVE_SENSOR_FAIL';

export const moveSensor = (sensorId, buildingId) => {
  return async dispatch => {
    dispatch({ type: MOVE_SENSOR });
    try {
      const result = await dispatch(MasterDataService.moveSensor(sensorId, buildingId));
      return dispatch({
        type: MOVE_SENSOR_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: MOVE_SENSOR_FAIL,
        error,
      });
    }
  };
};

export const DELETE_SENSOR = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR';
export const DELETE_SENSOR_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_SUCCESS';
export const DELETE_SENSOR_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_SENSOR_FAIL';

export const deleteSensor = (sensorId, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DELETE_SENSOR });
    try {
      const result = await dispatch(MasterDataService.deleteSensor(sensorId));
      return dispatch({
        type: DELETE_SENSOR_SUCCESS,
        id: sensorId,
        functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_SENSOR_FAIL,
        error,
      });
    }
  };
};

export const DELETE_COORDS = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS';
export const DELETE_COORDS_SUCCESS = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS_SUCCESS';
export const DELETE_COORDS_FAIL = 'CUSTOMER_PLATFORM/Building/DELETE_COORDS_FAIL';

export const deleteSensorCoords = (coordsId, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: DELETE_COORDS });
    try {
      const result = await MasterDataService.deleteSensorCoords({ id: coordsId, functionalLocation });
      return dispatch({
        type: DELETE_COORDS_SUCCESS,
        id: coordsId,
        functionalLocation,
        result,
      });
    } catch (error) {
      return dispatch({
        type: DELETE_COORDS_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_EDITABLE_SENSOR_ID = 'CUSTOMER_PLATFORM/Building/UPDATE_EDITABLE_SENSOR_ID';
export const updateEditableSensorId = id => ({ type: UPDATE_EDITABLE_SENSOR_ID, id });

export default createReducerFromMapping(
  {
    [LOAD]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [LOAD_SUCCESS]: (state, action) => {
      const buildingLevel = action.result[0];
      if (!buildingLevel) {
        return {
          ...state,
        };
      }
      const buildingHierarchy = { ...state.buildingHierarchy, [action.functionalLocation]: action.result };

      return {
        ...state,
        buildingHierarchy,
        loading: false,
      };
    },
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
    [LOAD_MULTIPLE]: state => ({
      ...state,
      loading: true,
    }),
    [LOAD_MULTIPLE_SUCCESS]: (state, action) => ({
      ...state,
      buildingHierarchy: {
        ...state.buildingHierarchy,
        ...action.result.reduce((prev, curr) => ({ ...prev, [curr.functionalLocation]: [curr] }), {}),
      },
      loading: false,
    }),
    [LOAD_MULTIPLE_FAIL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
    [UPSERT_COORDS_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_COORDS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DELETE_SENSORHIERARCHY_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DELETE_SENSORHIERARCHY_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DELETE_SENSOR_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DELETE_SENSOR_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [DELETE_COORDS_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [DELETE_COORDS_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_SENSOR_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_SENSOR_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [MOVE_SENSOR_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [MOVE_SENSOR_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPSERT_SENSORHIERARCHY_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [UPSERT_SENSORHIERARCHY_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_TYPES]: state => ({ ...state, loadingDataTypes: true }),
    [LOAD_TYPES_SUCCESS]: (state, action) => ({
      ...state,
      sensorDataTypes: action.result,
      loadingDataTypes: false,
    }),
    [LOAD_TYPES_FAIL]: (state, action) => ({
      ...state,
      sensorDataTypes: [],
      loadingDataTypes: false,
      loadingDataTypesError: action.error,
    }),
    [SET_SENSOR_SEARCH_WORD]: (state, action) => ({
      sensorSearchWord: action.searchWord,
    }),
    [SEARCH_SENSORS]: state => ({
      ...state,
      searchingSensors: true,
      searchFail: null,
    }),
    [SEARCH_SENSORS_SUCCESS]: (state, action) => ({
      ...state,
      searchingSensors: false,
      sensorSearchResult: action.result.data,
    }),
    [SEARCH_SENSORS_FAIL]: (state, action) => ({
      ...state,
      searchingSensors: false,
      searchError: action.error,
    }),
    [UPDATE_EDITABLE_SENSOR_ID]: (state, action) => ({
      ...state,
      editableSensorId: action.id,
    }),
  },
  initialState
);
