import MasterDataService from 'services/masterData';
import { parseISO, startOfYear, endOfYear } from 'date-fns';

import { createReducerFromMapping } from 'redux/utils';
import { indexByDate } from '../../utils/indexing';
import { SEARCH_SUCCESS } from './serviceOrders';
import { loadMaintenancePlan } from './maintenancePlans';
import { loadFunctionalLocations } from 'redux/modules';
import { getUniqueFunctionalLocations } from 'utils/Data/serviceOrders';
import sortBy from 'lodash/sortBy';
import { invalidateAllPlannedMaintenanceCaches } from 'services/masterData/plannedMaintenance';

const initialState = {
  calendar: {
    loadingOrders: false,
    loadingFLs: false,
  },
  index: indexByDate.getInitialState(),
  loading: {},
  logs: {},
};

export const LOAD_PLANNED_MAINTENANCE_TAB = 'CUSTOMER_PLATFORM/ServiceOrders/LOAD_PLANNED_MAINTENANCE_TAB_TAB';
export const LOAD_PLANNED_MAINTENANCE_TAB_ORDERS_SUCCESS =
  'CUSTOMER_PLATFORM/ServiceOrders/LOAD_PLANNED_MAINTENANCE_TAB_ORDERS_SUCCESS';
export const LOAD_PLANNED_MAINTENANCE_TAB_LOCATIONS_SUCCESS =
  'CUSTOMER_PLATFORM/ServiceOrders/LOAD_PLANNED_MAINTENANCE_TAB_LOCATIONS_SUCCESS';
export const LOAD_PLANNED_MAINTENANCE_TAB_FAIL =
  'CUSTOMER_PLATFORM/ServiceOrders/LOAD_PLANNED_MAINTENANCE_TAB_TAB_FAIL';

export const LOAD_LOGS = 'CUSTOMER_PLATFORM/PlannedMaintenances/LOAD_LOGS';
export const LOAD_LOGS_SUCCESS = 'CUSTOMER_PLATFORM/PlannedMaintenances/LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAIL = 'CUSTOMER_PLATFORM/PlannedMaintenances/LOAD_LOGS_FAIL';

export const loadPlannedMaintenanceTab =
  (year, partnerNumber, functionalLocationId, equipmentNumber, reload = false) =>
  async dispatch => {
    dispatch({ type: LOAD_PLANNED_MAINTENANCE_TAB });

    try {
      if (reload) {
        await invalidateAllPlannedMaintenanceCaches();
      }
      const start = startOfYear(new Date().setFullYear(year));
      const end = endOfYear(new Date().setFullYear(year));

      // load maintenance plans
      let maintenancePlans = [];

      if (partnerNumber) {
        maintenancePlans =
          (await dispatch(loadMaintenancePlan(undefined, partnerNumber, functionalLocationId, 'PlannedMaintenance')))
            .result || [];
      }

      // load planned maintenances for year
      let planned;

      if (functionalLocationId) {
        planned =
          (
            await dispatch(
              loadPlannedMaintenances(
                partnerNumber,
                functionalLocationId,
                equipmentNumber,
                start,
                end,
                'PlannedMaintenance'
              )
            )
          ).result || [];
      } else {
        planned =
          (await dispatch(loadAllPlannedMaintenances(start, end, partnerNumber, 'PlannedMaintenance'))).result || [];
      }

      dispatch({ type: LOAD_PLANNED_MAINTENANCE_TAB_ORDERS_SUCCESS });

      // load functional locations for planned maintenances and maintenance plans
      const uniqueFLs = getUniqueFunctionalLocations([...planned, ...maintenancePlans]);

      if (uniqueFLs.length > 0) {
        await dispatch(loadFunctionalLocations(uniqueFLs));
      }

      dispatch({ type: LOAD_PLANNED_MAINTENANCE_TAB_LOCATIONS_SUCCESS });
    } catch (error) {
      dispatch({ type: LOAD_PLANNED_MAINTENANCE_TAB_FAIL });
    }
  };

export const LOAD = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD';
export const LOAD_SUCCESS = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD_SUCCESS';
export const LOAD_FAIL = 'CUSTOMER_PLATFORM/PlannedMaintenance/LOAD_FAIL';

export const loadPlannedMaintenance =
  (maintenanceId, partnerNumber, functionalLocation, key, refreshCache) => async dispatch => {
    dispatch({ type: LOAD, key });
    try {
      const result = await MasterDataService.plannedMaintenance({
        partnerNumber,
        functionalLocation,
        id: maintenanceId,
        refreshCache,
      });
      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
        keepPrevious: true,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key,
        error,
      });
    }
  };

export const loadPlannedMaintenances =
  (partnerNumber, functionalLocation, equipmentNumber, startDate, endDate, key, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD, key });
    try {
      const result = await MasterDataService.plannedMaintenance({
        partnerNumber,
        functionalLocation,
        equipmentNumber,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        refreshCache,
      });
      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key,
        error,
      });
    }
  };

export const loadAllPlannedMaintenances =
  (startDate, endDate, partnerNumber, key, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD, key });
    try {
      const result = await MasterDataService.plannedMaintenancesForUser(
        startDate.toISOString(),
        endDate.toISOString(),
        partnerNumber && partnerNumber !== 'all' ? partnerNumber : undefined,
        undefined,
        refreshCache
      );
      return dispatch({
        type: LOAD_SUCCESS,
        key,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FAIL,
        key,
        error,
      });
    }
  };

export const loadPlannedMaintenanceLogs =
  (plannedMainenanceId, serviceOrderNumber, refreshCache = false) =>
  async dispatch => {
    dispatch({ type: LOAD_LOGS, plannedMainenanceId });
    try {
      const promises = [MasterDataService.plannedMaintenanceLogs(plannedMainenanceId, refreshCache)];
      // If service order number linked to planned maintenance then combine logs
      if (serviceOrderNumber) {
        promises.push(MasterDataService.serviceOrderLogs(serviceOrderNumber, refreshCache));
      }
      const results = await Promise.all(promises);
      const result = sortBy(results.flat(), 'created');
      return dispatch({
        type: LOAD_LOGS_SUCCESS,
        result,
        plannedMainenanceId,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_LOGS_FAIL,
        error,
        plannedMainenanceId,
      });
    }
  };

export const exportPlannedMaintenances = (
  partnerNumber,
  functionalLocation,
  equipmentNumber,
  startDate,
  endDate,
  ids
) => {
  return MasterDataService.plannedMaintenancesExport({
    partnerNumber,
    functionalLocation,
    ids,
    equipmentNumber,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });
};

export const exportPlannedMaintenancesForUser = (startDate, endDate, partnerNumber, ids) =>
  MasterDataService.plannedMaintenancesExportForUser({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    partnerNumber,
    ids,
  });

export const DELETE_FROM_INDEX = 'CUSTOMER_PLATFORM/PlannedMaintenance/DELETE_FROM_INDEX';

export const deleteFromIndex = plannedMaintenance => ({
  type: DELETE_FROM_INDEX,
  plannedMaintenance,
});

const getMaintenanceYear = order => parseISO(order.plannedDate || order.createdDate).getFullYear();

export default createReducerFromMapping(
  {
    [LOAD_PLANNED_MAINTENANCE_TAB]: state => ({
      ...state,
      calendar: {
        loadingOrders: true,
        loadingFLs: true,
      },
    }),
    [LOAD_PLANNED_MAINTENANCE_TAB_ORDERS_SUCCESS]: state => ({
      ...state,
      calendar: {
        ...state.calendar,
        loadingOrders: false,
      },
    }),
    [LOAD_PLANNED_MAINTENANCE_TAB_LOCATIONS_SUCCESS]: state => ({
      ...state,
      calendar: {
        ...state.calendar,
        loadingFLs: false,
      },
    }),
    [LOAD_PLANNED_MAINTENANCE_TAB_FAIL]: state => ({
      ...state,
      calendar: {
        loadingOrders: false,
        loadingFLs: false,
      },
    }),

    [LOAD]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, action.result, 'id', getMaintenanceYear, action.keepPrevious),
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),
    [LOAD_FAIL]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),

    [LOAD_LOGS]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.plannedMainenanceId]: {
          loading: true,
        },
      },
    }),
    [LOAD_LOGS_SUCCESS]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.plannedMainenanceId]: {
          items: action.result,
          loading: false,
        },
      },
    }),
    [LOAD_LOGS_FAIL]: (state, action) => ({
      ...state,
      logs: {
        ...state.logs,
        [action.plannedMainenanceId]: {
          error: action.error,
          loading: false,
        },
      },
    }),

    [SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      index: indexByDate.index(state.index, action.result, 'id', getMaintenanceYear),
    }),

    [DELETE_FROM_INDEX]: (state, action) => ({
      ...state,
      index: indexByDate.delete(state.index, action.plannedMaintenance, 'id', getMaintenanceYear),
    }),
  },
  initialState
);
