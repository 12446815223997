import { useCallback, useState } from 'react';

type Coordinate = {
  x: number;
  y: number;
};

interface CreateAnnotationContext {
  markerColor: string;
  unit: string;
  pointData: {
    id: string;
    x: number;
    y: number;
  };
  position: Coordinate;
}

interface ClickEvent extends Highcharts.PointClickEventObject {
  target: HTMLElement;
}

const getyAdjustmentToTimeline = (event: ClickEvent) => {
  try {
    const highchartsRoot = event?.target?.closest<SVGElement>('.highcharts-root');

    if (!highchartsRoot) {
      return 0;
    }
    const chartArea = highchartsRoot.getBoundingClientRect();
    const plotBackground = highchartsRoot.querySelector('.highcharts-plot-background');
    if (!plotBackground) {
      return 0;
    }
    return chartArea.height - event.chartY || 0;
  } catch (e) {
    return 0;
  }
};

const getTimelineClickPosition = (event: ClickEvent): Coordinate => {
  const x = event.clientX || 0;
  const y = event.clientY || 0;
  const yAdjustmentToTimeline = getyAdjustmentToTimeline(event);
  return {
    x,
    y: y + yAdjustmentToTimeline || 0,
  };
};

const useAnnotationForm = () => {
  const [annotationFormContext, setCreateAnnotationContext] = useState<CreateAnnotationContext | null>();
  const [annotationPopover, setAnnotationPopover] = useState(null);

  const onOpenAnnotationForm = useCallback(event => {
    setCreateAnnotationContext({
      markerColor: event.point.color,
      pointData: event.point.options,
      position: getTimelineClickPosition(event),
      unit: event.point.unit,
    });
  }, []);

  const onCloseAnnotations = useCallback(() => {
    setCreateAnnotationContext(null);
    setAnnotationPopover(null);
  }, []);

  return {
    annotationFormContext,
    onOpenAnnotationForm,
    onCloseAnnotations,
    annotationPopover,
    setAnnotationPopover,
  };
};

export default useAnnotationForm;
