import styled from 'styled-components';

const TitleWrapper = styled.div`
  margin: calc(-1 * var(--size-md)) var(--size-xxs-plus) 0;
  flex-basis: calc(100% - var(--size-sm));

  ${props => props.theme.media.portrait`
    flex-basis: calc(50% - var(--size-sm));
  `}
  ${props => props.theme.media.desktop`
    flex-basis: calc(100% / 3 - var(--size-sm));
  `}
  ${props => props.theme.media.bigDesktop`
    flex-basis: calc(40% - var(--size-sm));
  `}
`;

export default TitleWrapper;
