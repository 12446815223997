import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getFunctionalLocationId,
  wasteSensorsSelector,
  syntheticSensorsSelector,
} from 'containers/Application/Modules/RecyclingModule/common/selectors';
import {
  loadAmountsBreakdownData,
  loadAmountsBreakdownTotals,
  loadAmountsBreakdownMonthlies,
  loadAmountsBreakdownMonthliesTotals,
} from 'redux/modules/iot/values/recycling';
import { createTimeframeByCategory } from '../WasteBreakdownUtils';

const DEFAULT_TIMEFRAME_CATEGORY = 'year';

const getBreakdownData = state => state.values.recycling.amountsBreakdown.data;
const getBreakdownDataError = state => state.values.recycling.amountsBreakdown.error;
const getBreakdownDataMeta = state => state.values.recycling.amountsBreakdown.meta;
const getBreakdownTotalsError = state => state.values.recycling.amountsBreakdownTotals.error;
const getBreakdownTotalsMeta = state => state.values.recycling.amountsBreakdownTotals.meta;
const getBreakdownMonthliesError = state => state.values.recycling.amountsBreakdownMonthlies.error;
const getBreakdownMonthliesMeta = state => state.values.recycling.amountsBreakdownMonthlies.meta;
const getBreakdownMonthliesTotalsError = state => state.values.recycling.amountsBreakdownMonthliesTotals.error;
const getBreakdownMonthliesTotalsMeta = state => state.values.recycling.amountsBreakdownMonthliesTotals.meta;

const getSelectedTimeframeCategory = state =>
  getBreakdownDataMeta(state)?.selectedTimeframeCategory || DEFAULT_TIMEFRAME_CATEGORY;
const getSelectedTimeframe = state => getBreakdownDataMeta(state)?.selectedTimeframe;

const selectedTimeframeSelector = createSelector(getSelectedTimeframe, selectedTimeframe => {
  if (selectedTimeframe) {
    return selectedTimeframe;
  }
  return createTimeframeByCategory(DEFAULT_TIMEFRAME_CATEGORY);
});

const needsReloadSelector = createSelector(
  getFunctionalLocationId,
  getBreakdownDataMeta,
  (functionalLocationId, breakdownDataMeta) => breakdownDataMeta.functionalLocationId !== functionalLocationId
);

const dataStatusSelector = createSelector(
  getBreakdownData,
  getBreakdownDataMeta,
  getBreakdownTotalsMeta,
  getBreakdownMonthliesMeta,
  getBreakdownMonthliesTotalsMeta,
  getBreakdownDataError,
  getBreakdownTotalsError,
  getBreakdownMonthliesError,
  getBreakdownMonthliesTotalsError,
  needsReloadSelector,
  (
    breakdownData,
    breakdownDataMeta,
    breakdownTotalsMeta,
    breakdownMonthliesMeta,
    breakdownMonthliesTotalsMeta,
    breakdownError,
    totalsError,
    breakdownMonthliesError,
    breakdownMonthliesTotalsError,
    needsReload
  ) => {
    const isLoading =
      breakdownDataMeta.loading ||
      breakdownTotalsMeta.loading ||
      breakdownMonthliesMeta.loading ||
      breakdownMonthliesTotalsMeta.loading;
    return {
      isLoading,
      errors: [breakdownError, totalsError, breakdownMonthliesError, breakdownMonthliesTotalsError],
      needsReload,
      noData: !isLoading && isEmpty(breakdownData),
    };
  }
);

export const mapStateToProps = (state, props) => ({
  functionalLocationId: getFunctionalLocationId(state, props),
  wasteSensors: wasteSensorsSelector(state, props),
  loadingWasteSensors: state.sensorHierarchy.loading,
  syntheticSensors: syntheticSensorsSelector(state, props),
  selectedTimeframeCategory: getSelectedTimeframeCategory(state),
  selectedTimeframe: selectedTimeframeSelector(state),
  dataStatus: dataStatusSelector(state, props),
});

export const mapDispatchToProps = {
  loadAmountsBreakdownData,
  loadAmountsBreakdownTotals,
  loadAmountsBreakdownMonthlies,
  loadAmountsBreakdownMonthliesTotals,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector;
