import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/Translations/translations';
import PrimaryButton from 'components/Button/PrimaryButton/PrimaryButton';
import InputRow from 'components/Form/InputRow';
import InputLabel from 'components/Form/InputLabel';
import InputTextArea from 'components/Form/InputTextArea';
import InputForm from 'components/Form/InputForm';
import InputSelectDropdown from 'components/Form/InputSelectDropdown';

// id is needed for the required field to work inside InputForm
const SAFETY_NOTICE_FORM_ID = 'rescue-plan-safety_notice-form';

const calculateDropdownOptions = inputOptions => {
  if (!inputOptions.choices) {
    return null;
  }

  return inputOptions.choices.reduce((result, current) => {
    const category = current[0];
    const values = current[1];
    if (category !== '') {
      const categoryValues = values.map(pair => ({ label: category + ' : ' + pair[1], value: pair[0] }));
      return result.concat(categoryValues);
    }
    return result;
  }, []);
};

export const SafetyNoticeForm = ({ t, inputOptions, onSubmit }) => {
  const [model, setModel] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const formRef = useRef(null);

  const validate = form => {
    if (!form.checkValidity()) {
      const invalidInput = form.querySelector(':invalid');
      invalidInput.reportValidity();
      invalidInput.scrollIntoView({ block: 'center' });
      return false;
    }
    return true;
  };

  const handleFormChange = (property, value) => setModel(oldModel => ({ ...oldModel, [property]: value }));

  const handleSubmit = async () => {
    const form = formRef.current;
    if (validate(form)) {
      setLoading(true);
      await onSubmit(model);
      setLoading(false);
    }
  };

  const options = useMemo(() => {
    return calculateDropdownOptions(inputOptions);
  }, [inputOptions]);

  return (
    <InputForm
      onPropertyChange={handleFormChange}
      model={model}
      onSubmit={handleSubmit}
      ref={formRef}
      id={SAFETY_NOTICE_FORM_ID}
    >
      <InputRow required>
        <InputLabel text={t('Note')} />
        <InputSelectDropdown
          options={options}
          property="safetyRemarkId"
          onChange={() => void 0}
          label="Dropdown"
          loading={false}
          showAllText="Show all"
          placeholder={t('Select notice')}
          t={t}
        />
      </InputRow>
      <InputRow fullRow>
        <InputLabel text={t('Comment')} />
        <InputTextArea type="text" property="comment" />
      </InputRow>

      <PrimaryButton add large type="submit" loading={loading}>
        {t('Save')}
      </PrimaryButton>
    </InputForm>
  );
};

SafetyNoticeForm.propTypes = {
  t: PropTypes.func.isRequired,
  inputOptions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default translations(SafetyNoticeForm);
