import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: var(--error-color);
`;

const ErrorText = ({ style, children }) => <Error style={style}>{children}</Error>;

export default ErrorText;
