import { createReducerFromMapping } from 'redux/utils/index';
import DocumentService from 'services/document';
import isNil from 'lodash/isNil';

const initialState = {
  functionalLocations: {},
  filteredFunctionalLocations: {},
  partners: {},
  filteredPartners: {},
  filterKeyword: null,
  error: null,
  fileCountsByFL: {},
  loadingCounts: false,
  customViewUrls: {},
  tokens: {},
  loading: {},
  maintenancePlans: {},
};

export const LOAD_FILES = 'CUSTOMER_PLATFORM/File/LOAD_FILES';
export const LOAD_FILES_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_FILES_SUCCESS';
export const LOAD_FILES_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_FILES_FAIL';

export const loadFiles = (functionalLocationId, partnerNumber, refreshCache = false) => {
  return async dispatch => {
    dispatch({ type: LOAD_FILES, functionalLocation: functionalLocationId });
    try {
      const result = await DocumentService.filesForFunctionalLocation({
        functionalLocation: functionalLocationId,
        partnerNumber,
        refreshCache,
      });

      return dispatch({
        type: LOAD_FILES_SUCCESS,
        functionalLocation: functionalLocationId,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_FILES_FAIL,
        functionalLocation: functionalLocationId,
        error,
      });
    }
  };
};

export const LOAD_PARTNER_FILES = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES';
export const LOAD_PARTNER_FILES_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES_SUCCESS';
export const LOAD_PARTNER_FILES_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_PARTNER_FILES_FAIL';

export const loadPartnerFiles = (partnerNumber, refreshCache) => {
  return async dispatch => {
    dispatch({ type: LOAD_PARTNER_FILES, partnerNumber });
    try {
      const result = await DocumentService.filesForPartner({ partnerNumber, refreshCache });

      return dispatch({
        type: LOAD_PARTNER_FILES_SUCCESS,
        partnerNumber,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_PARTNER_FILES_FAIL,
        partnerNumber,
        error,
      });
    }
  };
};

export const LOAD_MAINTENANCE_PLAN_FILES = 'CUSTOMER_PLATFORM/File/LOAD_MAINTENANCE_PLAN_FILES';
export const LOAD_MAINTENANCE_PLAN_FILES_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_MAINTENANCE_PLAN_FILES_SUCCESS';
export const LOAD_MAINTENANCE_PLAN_FILES_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_MAINTENANCE_PLAN_FILES_FAIL';

export const loadMaintenancePlanFiles = (code, maintenancePlanId, refreshCache = false) => {
  return async dispatch => {
    dispatch({ type: LOAD_MAINTENANCE_PLAN_FILES, code });

    if (!code || !maintenancePlanId) {
      return dispatch({
        type: LOAD_MAINTENANCE_PLAN_FILES_FAIL,
        code,
        error: 'Missing code or id',
      });
    }

    try {
      const result = await dispatch(DocumentService.findForMaintenancePlan({ maintenancePlanId, refreshCache }));

      return dispatch({
        type: LOAD_MAINTENANCE_PLAN_FILES_SUCCESS,
        code,
        result,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_MAINTENANCE_PLAN_FILES_FAIL,
        code,
        error,
      });
    }
  };
};

export const LOAD_SO_FILES_WITH_TOKEN = 'CUSTOMER_PLATFORM/File/LOAD_SO_FILES_WITH_TOKEN';
export const LOAD_SO_FILES_WITH_TOKEN_SUCCESS = 'CUSTOMER_PLATFORM/File/LOAD_SO_FILES_WITH_TOKEN_SUCCESS';
export const LOAD_SO_FILES_WITH_TOKEN_FAIL = 'CUSTOMER_PLATFORM/File/LOAD_SO_FILES_WITH_TOKEN_FAIL';

export const loadServiceOrderFilesWithToken = (token, key) => {
  return async dispatch => {
    dispatch({ type: LOAD_SO_FILES_WITH_TOKEN, key });
    try {
      const result = await dispatch(DocumentService.filesForServiceOrderWithToken({ token }));

      return dispatch({
        type: LOAD_SO_FILES_WITH_TOKEN_SUCCESS,
        key,
        result,
        token,
      });
    } catch (error) {
      return dispatch({
        type: LOAD_SO_FILES_WITH_TOKEN_FAIL,
        key,
        error,
      });
    }
  };
};

export const UPLOAD_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_FILE_FAIL';

export const uploadFile = (fileData, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: UPLOAD_FILE });
    try {
      await DocumentService.uploadFile({
        ...fileData,
        functionalLocation: functionalLocation.functionalLocation,
      });
      return dispatch({ type: UPLOAD_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPLOAD_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE';
export const UPLOAD_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE_SUCCESS';
export const UPLOAD_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_PARTNER_FILE_FAIL';

export const uploadPartnerFile = (data, partnerNumber) => {
  return async dispatch => {
    dispatch({ type: UPLOAD_PARTNER_FILE });
    try {
      await DocumentService.uploadFile({ ...data, partnerNumber });
      return dispatch({ type: UPLOAD_PARTNER_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPLOAD_CUSTOM_VIEW_FILE = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE';
export const UPLOAD_CUSTOM_VIEW_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE_SUCCESS';
export const UPLOAD_CUSTOM_VIEW_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_CUSTOM_VIEW_FILE_FAIL';

export const uploadCustomViewFile = data => {
  return async dispatch => {
    dispatch({ type: UPLOAD_CUSTOM_VIEW_FILE });
    try {
      await DocumentService.uploadCustomViewFile({ file: data });
      return dispatch({ type: UPLOAD_CUSTOM_VIEW_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPLOAD_CUSTOM_VIEW_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPLOAD_SO_FILE_WITH_TOKEN = 'CUSTOMER_PLATFORM/File/UPLOAD_SO_FILE_WITH_TOKEN';
export const UPLOAD_SO_FILE_WITH_TOKEN_SUCCESS = 'CUSTOMER_PLATFORM/File/UPLOAD_SO_FILE_WITH_TOKEN_SUCCESS';
export const UPLOAD_SO_FILE_WITH_TOKEN_FAIL = 'CUSTOMER_PLATFORM/File/UPLOAD_SO_FILE_WITH_TOKEN_FAIL';

export const uploadServiceOrderFileWithToken = (data, token) => async dispatch => {
  dispatch({ type: UPLOAD_FILE });
  try {
    await DocumentService.uploadFileWithToken({ file: { ...data, createThumbnail: true }, token });
    return dispatch({ type: UPLOAD_FILE_SUCCESS });
  } catch (error) {
    return dispatch({
      type: UPLOAD_FILE_FAIL,
      error,
    });
  }
};

export const UPDATE_FILE = 'CUSTOMER_PLATFORM/File/UPDATE_FILE';
export const UPDATE_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPDATE_FILE_FAIL';

export const updateFile = (fileId, file, functionalLocation) => {
  return async dispatch => {
    dispatch({ type: UPDATE_FILE });
    try {
      await dispatch(
        DocumentService.updateFile({
          file: { ...file, fileId },
          functionalLocation: functionalLocation.functionalLocation,
        })
      );
      return dispatch({ type: UPDATE_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_FILE_FAIL,
        error,
      });
    }
  };
};

export const UPDATE_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE';
export const UPDATE_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE_SUCCESS';
export const UPDATE_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/UPDATE_PARTNER_FILE_FAIL';

export const updatePartnerFile = (fileId, file, partnerNumber) => {
  return async dispatch => {
    dispatch({ type: UPDATE_PARTNER_FILE });
    try {
      await dispatch(DocumentService.updatePartnerFile({ file: { ...file, fileId }, partnerNumber }));
      return dispatch({ type: UPDATE_PARTNER_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: UPDATE_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_FILE = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE';
export const DOWNLOAD_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_FAIL';

export const downloadFile = (file, functionalLocation, inline) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_FILE });
    try {
      // v3 files contain blob url which can be used for downloading the file.
      if (file.blobUrl) {
        window.open(file.blobUrl, 'name');
        return;
      }

      if (file.externalType) {
        return await downloadExternalFile(file, inline, dispatch);
      }

      const result = await DocumentService.downloadUrl({
        fileId: file.id,
        functionalLocation: functionalLocation.functionalLocation,
        inline,
      });

      if (inline) {
        window.open(result.sasUrl, 'name');
      } else {
        window.location.href = result.sasUrl;
      }
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_PARTNER_FILE = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE';
export const DOWNLOAD_PARTNER_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE_SUCCESS';
export const DOWNLOAD_PARTNER_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_PARTNER_FILE_FAIL';

export const downloadPartnerFile = (fileId, inline) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_PARTNER_FILE });
    inline && window.open('about:blank', 'name');

    try {
      const result = await dispatch(DocumentService.downloadUrlForPartner({ fileId, inline }));
      if (inline) {
        window.open(result.sasUrl, 'name');
      } else {
        window.location.href = result.sasUrl;
      }
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_PARTNER_FILE_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_FILE_FOR_OBJECT = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_FOR_OBJECT';
export const DOWNLOAD_FILE_FOR_OBJECT_SUCCESS = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_FOR_OBJECT_SUCCESS';
export const DOWNLOAD_FILE_FOR_OBJECT_FAIL = 'CUSTOMER_PLATFORM/File/DOWNLOAD_FILE_FOR_OBJECT_FAIL';

export const downloadFileForObject = (file, inline) => {
  return async dispatch => {
    dispatch({ type: DOWNLOAD_FILE_FOR_OBJECT });
    try {
      if (file.externalType) {
        return await downloadExternalFile(file, inline, dispatch);
      }

      const result = await dispatch(DocumentService.downloadUrlForObject({ fileId: file.id, inline }));
      if (inline) {
        window.open(result.sasUrl, 'name');
      } else {
        window.location.href = result.sasUrl;
      }
    } catch (error) {
      return dispatch({
        type: DOWNLOAD_FILE_FOR_OBJECT_FAIL,
        error,
      });
    }
  };
};

export const DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN = 'CUSTOMER_PLATFORM/File/DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN';
export const DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN_FAIL =
  'CUSTOMER_PLATFORM/File/DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN_FAIL';

export const downloadServiceOrderFileWithToken = (file, token) => async dispatch => {
  dispatch({ type: DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN });
  try {
    const result = await DocumentService.downloadUrlForServiceOrderWithToken({ fileId: file.id, token });
    window.location.href = result.sasUrl;
  } catch (error) {
    return dispatch({
      type: DOWNLOAD_SERVICE_ORDER_FILE_WITH_TOKEN_FAIL,
      error,
    });
  }
};

export const GET_CUSTOM_VIEW_FILE_URL = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL';
export const GET_CUSTOM_VIEW_FILE_URL_SUCCESS = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL_SUCCESS';
export const GET_CUSTOM_VIEW_FILE_URL_FAIL = 'CUSTOMER_PLATFORM/File/GET_CUSTOM_VIEW_FILE_URL_FAIL';

export const getCustomViewFileUrl = customViewId => {
  return async dispatch => {
    dispatch({ type: GET_CUSTOM_VIEW_FILE_URL });
    try {
      const result = await dispatch(DocumentService.downloadUrlForCustomView({ customViewId }));
      dispatch({
        type: GET_CUSTOM_VIEW_FILE_URL_SUCCESS,
        customViewId,
        url: result.sasUrl,
      });
    } catch (error) {
      return dispatch({
        type: GET_CUSTOM_VIEW_FILE_URL_FAIL,
        error,
      });
    }
  };
};

export const DELETE_FILE = 'CUSTOMER_PLATFORM/File/DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DELETE_FILE_FAIL';

export const deleteFile = fileId => {
  return async dispatch => {
    dispatch({ type: DELETE_FILE });
    try {
      await DocumentService.deleteFile({ fileId });
      return dispatch({ type: DELETE_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_FILE_FAIL,
        error,
      });
    }
  };
};

export const DELETE_CUSTOM_VIEW_FILE = 'CUSTOMER_PLATFORM/File/DELETE_CUSTOM_VIEW_FILE';
export const DELETE_CUSTOM_VIEW_FILE_SUCCESS = 'CUSTOMER_PLATFORM/File/DELETE_CUSTOM_VIEW_FILE_SUCCESS';
export const DELETE_CUSTOM_VIEW_FILE_FAIL = 'CUSTOMER_PLATFORM/File/DELETE_CUSTOM_VIEW_FILE_FAIL';

export const deleteCustomViewFile = customViewId => {
  return async dispatch => {
    dispatch({ type: DELETE_CUSTOM_VIEW_FILE });
    try {
      await DocumentService.deleteCustomViewFile({ customViewId });
      return dispatch({ type: DELETE_CUSTOM_VIEW_FILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: DELETE_CUSTOM_VIEW_FILE_FAIL,
        error,
      });
    }
  };
};

export const GET_FILE_COUNTS = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS';
export const GET_FILE_COUNTS_SUCCESS = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS_SUCCESS';
export const GET_FILE_COUNTS_FAIL = 'CUSTOMER_PLATFORM/File/GET_FILE_COUNTS_FAIL';

export const getFileCounts = partnerNumber => {
  return async dispatch => {
    dispatch({ type: GET_FILE_COUNTS });
    try {
      const result = await DocumentService.fileCounts({ partnerNumber });
      return dispatch({ type: GET_FILE_COUNTS_SUCCESS, result });
    } catch (error) {
      return dispatch({
        type: GET_FILE_COUNTS_FAIL,
        error,
      });
    }
  };
};

export const FILTER_FILES_BY_KEYWORD = 'CUSTOMER_PLATFORM/File/FILTER_FILES_BY_KEYWORD';
export const filterFilesByKeyword = keyword => {
  return {
    type: [FILTER_FILES_BY_KEYWORD],
    keyword,
  };
};

export const RESET_FILTER_FILES_BY_KEYWORD = 'CUSTOMER_PLATFORM/File/RESET_FILTER_FILES_BY_KEYWORD';
export const resetFilterFilesByKeyword = () => {
  return {
    type: [RESET_FILTER_FILES_BY_KEYWORD],
  };
};

const EXTERNAL_FILE_WINDOW_NAME = 'external-file';
let openedWindow;

async function downloadExternalFile(file, inline, dispatch) {
  const isIE = window.navigator && window.navigator.msSaveOrOpenBlob;

  if (inline && !isIE) {
    openedWindow = window.open('about:blank', EXTERNAL_FILE_WINDOW_NAME);
  }

  try {
    await dispatch(
      DocumentService.downloadExternalFile({
        fileId: file.id,
        functionalLocation: file.functionalLocation,
        fileName: file.name,
        windowName: inline && EXTERNAL_FILE_WINDOW_NAME,
      })
    );
  } catch (error) {
    if (openedWindow) {
      openedWindow.close();
    }
    throw error;
  }
}

export default createReducerFromMapping(
  {
    [LOAD_FILES_SUCCESS]: (state, action) => ({
      ...state,
      functionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
      filteredFunctionalLocations: {
        ...state.functionalLocations,
        [action.functionalLocation]: action.result,
      },
    }),

    [LOAD_FILES_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_PARTNER_FILES_SUCCESS]: (state, action) => ({
      ...state,
      partners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
      filteredPartners: {
        ...state.partners,
        [action.partnerNumber]: action.result,
      },
    }),
    [LOAD_PARTNER_FILES_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [LOAD_MAINTENANCE_PLAN_FILES]: (state, action) => ({
      ...state,
      maintenancePlans: {
        ...state.maintenancePlans,
        [action.code]: {
          loading: true,
        },
      },
    }),
    [LOAD_MAINTENANCE_PLAN_FILES_SUCCESS]: (state, action) => ({
      ...state,
      maintenancePlans: {
        ...state.maintenancePlans,
        [action.code]: {
          loading: false,
          files: action.result,
        },
      },
    }),
    [LOAD_MAINTENANCE_PLAN_FILES_FAIL]: (state, action) => ({
      ...state,
      maintenancePlans: {
        ...state.maintenancePlans,
        [action.code]: {
          loading: false,
          error: action.error,
        },
      },
    }),

    [LOAD_SO_FILES_WITH_TOKEN]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.key]: true,
      },
    }),
    [LOAD_SO_FILES_WITH_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      tokens: {
        ...state.tokens,
        [action.token]: action.result,
      },
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),
    [LOAD_SO_FILES_WITH_TOKEN_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
      loading: {
        ...state.loading,
        [action.key]: false,
      },
    }),

    [UPLOAD_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPLOAD_PARTNER_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPLOAD_CUSTOM_VIEW_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPDATE_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [UPDATE_PARTNER_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [GET_CUSTOM_VIEW_FILE_URL_SUCCESS]: (state, action) => ({
      ...state,
      customViewUrls: { ...state.customViewUrls, [action.customViewId]: action.url },
    }),

    [DELETE_FILE_FAIL]: (state, action) => ({
      ...state,
      error: action.error,
    }),

    [FILTER_FILES_BY_KEYWORD]: (state, action) => ({
      ...state,
      filteredFunctionalLocations: applyFilter(action.keyword, state.functionalLocations),
      filteredPartners: applyFilter(action.keyword, state.partners),
      filterKeyword: action.keyword,
    }),
    [RESET_FILTER_FILES_BY_KEYWORD]: state => ({
      ...state,
      filteredFunctionalLocations: state.functionalLocations,
      filteredPartners: state.partners,
      filterKeyword: null,
    }),

    [GET_FILE_COUNTS]: state => ({
      ...state,
      loadingCounts: true,
    }),
    [GET_FILE_COUNTS_SUCCESS]: (state, action) => ({
      ...state,
      fileCountsByFL: { ...state.fileCountsByFL, ...action.result },
      loadingCounts: false,
    }),
    [GET_FILE_COUNTS_FAIL]: state => ({
      ...state,
      loadingCounts: false,
    }),
  },
  initialState
);

const applyFilter = (keyword, object) => {
  if (isNil(object)) {
    return object;
  }

  const filtered = {};

  keyword = keyword.toLowerCase();

  for (const k in object) {
    if (!object.hasOwnProperty(k)) {
      continue;
    }

    filtered[k] = object[k].filter(x => (x.name || '').toLowerCase().indexOf(keyword) !== -1);
  }

  return filtered;
};
