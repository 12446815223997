import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startsWith from 'lodash/startsWith';
import translations from 'decorators/Translations/translations';

import FileUpload from 'components/FileUpload/FileUpload';
import DocumentFile from 'components/Documents/DocumentManager/DocumentFile';
import InfoBox from 'components/InfoBox/InfoBox';
import PrimaryButton from 'components/Button/PrimaryButton';

import { MAX_SIZE } from 'components/Documents/DocumentManager/DocumentManager';

const UploadContainer = styled.div`
  margin-top: var(--size-sm);
`;

const FileContainer = styled.div`
  margin: var(--size-sm) 0;
  border: 1px solid ${props => props.theme.colors.mystic};
  border-width: ${props => props.noBorder && '0'};
  padding: 0 var(--size-xs);
`;

const ButtonWrapper = styled.div`
  margin: var(--size-xs) 0;

  button: {
    width: 100%;
    ${props => props.theme.media.landscape`
      width: auto;
    `}
  }
`;

export const ServiceModuleDocumentUpload = ({
  t,
  files,
  erroredFiles,
  onFileUpload,
  onFileRemoval,
  children,
  onButtonClick,
  submitting,
}) => {
  const [errorMessages, setErrorMessages] = React.useState([]);

  const handleFileUpload = React.useCallback(
    fileArray => {
      setErrorMessages([]);

      fileArray.forEach(file => {
        const filename = file.name;
        const mimeType = file.type;
        const size = file.size;

        const fileTooBig = size > MAX_SIZE;
        const wrongMimeType = mimeType && !startsWith(mimeType, 'image') && mimeType !== 'application/pdf';
        const newErrorMessages = [];

        if (fileTooBig) {
          newErrorMessages.push(`${t('File is too big')}: ${filename}`);
        }
        if (!mimeType) {
          newErrorMessages.push(`${t('File extension missing')}: ${filename}`);
        }
        if (wrongMimeType) {
          newErrorMessages.push(`${t('Allowed file types are {0} and {1}', 'image', 'application/pdf')}: ${filename}`);
        }

        if (!fileTooBig && mimeType && !wrongMimeType) {
          const reader = new FileReader();
          reader.onload = event => {
            if (event.target.result) {
              let fileString = event.target.result;
              if (fileString.indexOf(',') !== -1) {
                fileString = fileString.split(',')[1];
              }
              const data = {
                file: fileString,
                filename,
                mimeType,
                size,
              };

              onFileUpload(data);
            }
          };

          reader.readAsDataURL(file);
        } else {
          setErrorMessages(oldMessages => oldMessages.concat(newErrorMessages));
        }
      });
    },
    [setErrorMessages, onFileUpload, t]
  );

  return (
    <UploadContainer>
      <FileUpload
        upload={handleFileUpload}
        t={t}
        header={t('Click and select file or drag and drop')}
        noIcon
        noMimeTypes
        height="100px"
        multiple
      />
      {errorMessages.length > 0 && <InfoBox error>{errorMessages}</InfoBox>}
      {erroredFiles.length > 0 && (
        <InfoBox error>
          <b>{t('There was a problem when uploading.')} </b>
          {t('Please try again or one file at a time.')}
        </InfoBox>
      )}
      <FileContainer noBorder={files.length === 0 && !children}>
        <ul>
          {children}
          {files.map((file, index) => (
            <DocumentFile
              key={index}
              file={file}
              index={index}
              onFileRemoval={onFileRemoval}
              isCreateMode
              loading={false}
              hasErrored={erroredFiles.includes(index)}
              allLoading={false}
              full={erroredFiles.length > 0 && !erroredFiles.includes(index)}
              noBorder={index === files.length - 1 && !onButtonClick}
            />
          ))}
        </ul>
        {typeof onButtonClick === 'function' && files.length > 0 && (
          <ButtonWrapper>
            <PrimaryButton large add onClick={onButtonClick} loading={submitting} disabled={submitting}>
              {t('Upload')}
            </PrimaryButton>
          </ButtonWrapper>
        )}
      </FileContainer>
    </UploadContainer>
  );
};

ServiceModuleDocumentUpload.propTypes = {
  t: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  erroredFiles: PropTypes.array.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileRemoval: PropTypes.func.isRequired,
  children: PropTypes.any,
  onButtonClick: PropTypes.func,
  submitting: PropTypes.bool,
};

export default translations(ServiceModuleDocumentUpload);
