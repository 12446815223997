import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import accessibility from 'highcharts/modules/accessibility';
import translations from 'decorators/Translations/translations';
import styled, { withTheme } from 'styled-components';
import { createWasteChartConfig } from 'containers/Application/Modules/RecyclingModule/WasteBreakdown/WasteChart/WasteChartConfig';

addNoDataModule(Highcharts);
accessibility(Highcharts);

const StyledWasteAmountsChart = styled.div`
  .highcharts-legend-item rect {
    visibility: hidden;
  }
  .highcharts-legend-item {
    margin-left: -25px;
  }
  .highcharts-axis-labels {
    letter-spacing: ${props => props.theme.letterSpacing.default};
  }
`;

const createChartConfig = (t, theme, language, data, onValueBarClick) => {
  // map series to Highcharts compatible form and set colors from theme
  const transformedSeries = data.series.map(entry => ({
    name: entry.wasteType,
    y: entry.totalValue,
    _unit: entry.unit,
    color: theme.charts.colorsRecycling[entry.colorName],
    sensorId: entry.sensorId,
  }));
  const categories = transformedSeries.map(entry => entry.name);
  const customLegend = data.categories.map(category => ({
    name: category.name,
    color: theme.charts.colorsRecycling[category.colorName],
  }));
  const barChartConfig = createWasteChartConfig({
    theme,
    language,
    categories,
    series: transformedSeries,
    customLegend,
    onValueBarClick,
  });
  barChartConfig.lang = {
    ...barChartConfig.lang,
    noData: t('No data to display'),
  };
  return barChartConfig;
};

export const WasteChart = ({ t, theme, profile, data, onValueBarClick }) => {
  const chartConfig = useMemo(
    () => createChartConfig(t, theme, profile.language, data, onValueBarClick),
    [t, theme, profile.language, data, onValueBarClick]
  );
  return (
    <StyledWasteAmountsChart>
      <HighchartsReact highcharts={Highcharts} options={chartConfig} />
    </StyledWasteAmountsChart>
  );
};

WasteChart.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        colorName: PropTypes.string.isRequired,
      })
    ).isRequired,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        wasteType: PropTypes.string.isRequired,
        colorName: PropTypes.string.isRequired,
        totalValue: PropTypes.number.isRequired,
        unit: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  onValueBarClick: PropTypes.func.isRequired,
};

export default withTheme(translations(WasteChart));
